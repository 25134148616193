import React from 'react';

const Button =(props)=>{
    return <button 
                disabled= {props.isDisabled !== undefined &&  props.isDisabled !== false ? true : false}
                onClick={()=> props.handleButtonClick()}
                style={{cursor : props.isDisabled !== undefined  &&  props.isDisabled !== false ? 'not-allowed' : 'pointer'}}
                className= { `button ${props.className}`  }
                active={ props.active ? true.toString() : false.toString() }
                >
                    {props.btnText}
            </button>
}

export default Button
import React, { useContext } from 'react';
import VirtualDeviceActionComponent from '../components/virtualDeviceActionsComponent';
import VirtualDeviceSearchComponent from '../components/virtualDeviceSearchComponent';
import VirtualDeviceTableComponent from '../components/virtualDeviceMainTable';
import {VirtualDeviceContext} from '../provider/virtualDeviceProvider';
import AddVirtualDevice from '../components/addEditDevice/addVirtualDevice';

const VirtualDeviceMngmntContainer=()=>{
    const contextData = useContext(VirtualDeviceContext);

    return (
        <div className='virtual-device-mngmnt'>
              {contextData.state.isOpenAddDevicePage ? <AddVirtualDevice />
                  : 
                    <React.Fragment>
                        <div className='action-and-search-section'>
                            <div className='search-section'>
                                <VirtualDeviceSearchComponent />
                            </div>
                            <div>
                                <VirtualDeviceActionComponent />
                            </div>
                        </div>
                        <div>
                            <VirtualDeviceTableComponent />
                        </div>                        
                    </React.Fragment>
                }
            </div>
    )
}

export default VirtualDeviceMngmntContainer;
import React, { Component, Fragment } from 'react';
import { Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { tableOptions } from '../../../../utility/deviceManagementUtility';
import { getClassName } from '../../../../utility/treeViewUtility';
import { applyState } from '../services/deviceService';
import { DeviceContext } from '../provider/deviceProvider';
import { groupingSelectedRows } from '../../../../services/utilityService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import SingleSelect from '../../../../hoc/singleSelect';
import ModalWindow from '../../../../hoc/modelWindow';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../../../../hoc/loader';

class DeviceStateChange extends Component {
    static contextType = DeviceContext;
    state = {
        successMessage: "",
        errorMessage: "",
        newStatePopupErrorMessage: "",
        selectedItems: {},
        selectedNewStatePopup: false,
        updateConfirmationPopup: false,
        newStateSelectedData: {},
        groupedData: {},
        newStateRowsCount: 0,
        isBtnDisabled: false,
        finalSelectedData: {}
    }

    ddlValueUpdate = (e, type, state) => {
        let modifiedData = this.state.selectedItems;
        if (e.id !== "") {
            if (modifiedData[type]) {
                if (modifiedData[type][state]) {
                    modifiedData[type][state] = e
                } else {
                    modifiedData[type] = Object.assign({}, this.state.selectedItems[type], {
                        [state]: e
                    });
                }
            } else {
                modifiedData = Object.assign({}, this.state.selectedItems, {
                    [type]: {
                        [state]: e
                    }
                });
            }
        } else {
            delete modifiedData[type][state];
        }
        this.setState({ selectedItems: modifiedData });
    }

    onNextBtnClickHandler = () => {
        let finalData = this.state.finalSelectedData;
        if (Object.keys(this.state.selectedItems).length > 0) {
            let rowsCount = this.state.newStateRowsCount;
            Object.entries(this.state.selectedItems).forEach(([key, value]) => {
                Object.entries(value).forEach(([item, res]) => {
                    rowsCount += this.state.groupedData[key.toUpperCase()][item].length;
                    if (finalData[res]) {
                        const dataToBeMerge = this.state.groupedData[key.toUpperCase()][item].map(dt => dt.id);
                        finalData[res] = finalData[res].concat(dataToBeMerge);
                    } else {
                        finalData = Object.assign({}, finalData, {
                            [res]: this.state.groupedData[key.toUpperCase()][item].map(dt => dt.id)
                        })
                    }
                })
            });
            this.setState({ selectedNewStatePopup: true, newStateRowsCount: rowsCount, finalSelectedData: finalData, errorMessage: "" });
        } else {
            this.setState({ errorMessage: <FormattedMessage id='deviceStateChange.popup.newStateSelection.errorMessage' /> })
        }
    }

    cancelClickHandler = () => {
        this.setState({ selectedNewStatePopup: false, newStateRowsCount: 0, finalSelectedData: {} })
    }

    confirmClickHandler = () => {
        this.setState({ updateConfirmationPopup: true })
    }

    updateConfCancelClickHandler = () => {
        this.setState({ updateConfirmationPopup: false, selectedNewStatePopup: true });
    }

    updateConfOkClickHandler = async () => {
        this.setState({ updateConfirmationPopup: false, selectedNewStatePopup: true, isBtnDisabled: true });
        const breadCrumb = getClassName({ node: this.props.selectedNode });
        const popupTitle = <span><FormattedMessage id={`deviceStateChange.popup.title`} /> [ {this.state.newStateRowsCount} Device(s)] {breadCrumb} </span>;
        const res = await applyState(this.state.finalSelectedData, this.props.orgId, popupTitle);
        if (res) {
            if (res.data.status !== "Success") {
                this.setState({ newStatePopupErrorMessage: ReactHtmlParser(res.data.message) });
            } else {
                this.setState({ successMessage: <FormattedMessage id='deviceStateChange.popup.selectedNewStatePopup.successMessage' /> });
            }
        } else {
            this.props.closeModal("isDeviceStateModalOpen")
        }
        if (Object.keys(this.props.selectedNode).length > 0) {
            await this.context.loadNextlevel({ "node": this.props.selectedNode }, this.props.selectedNode.routeParams ? this.props.selectedNode.routeParams : null, null, this.props.selectedNode.name);
            await this.context.getDeviceList()
        }
    }

    componentDidMount() {
        const groupedData = groupingSelectedRows(tableOptions.selectedRows, ['deviceType', 'deviceState']);
        //All the device type are uppercase except 'MM3ai', Making to all upppercase and assigned to state groupedData.
        const upCaseGroupdData = Object.fromEntries(Object.entries(groupedData).map(([k, v]) => [k.toUpperCase(), v]));
        this.setState({ groupedData: upCaseGroupdData });
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
        return btnName === "next" ? this.onNextBtnClickHandler() :
            btnName === "cancel" ? this.cancelClickHandler() :
                btnName === "confirm" ? this.confirmClickHandler() :
                    btnName === "close" ? this.props.closeModal("isDeviceStateModalOpen") :
                        btnName === "updateCancel" ? this.updateConfCancelClickHandler() :
                            btnName === "ok" ? this.updateConfOkClickHandler() : null
    }

    render() {
        const selectedRowsCount = tableOptions.selectedRows && tableOptions.selectedRows.length;
        const breadCrumb = getClassName({ node: this.props.selectedNode });
        const selectedRowsGroupBy = this.state.groupedData;
        let modalTitle, modalBody, modalFooter;
        if (!this.state.updateConfirmationPopup) {
            modalTitle = <div className="popup-title"><FormattedMessage id={`deviceStateChange.popup.title`} /> [ {!this.state.selectedNewStatePopup ? selectedRowsCount : this.state.newStateRowsCount} Device(s)] {breadCrumb} </div>;
            modalBody = <div className="min-vh-25 single-select-dropdown">
                {this.props.isLoadingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                {!this.state.selectedNewStatePopup ?
                    <Fragment>
                        {this.state.errorMessage ? <span className="text-danger float-right">{this.state.errorMessage}</span> : ""}
                        {Object.keys(selectedRowsGroupBy).sort().map((label, i) => {
                            return (
                                <Form key={label + '-' + i}>
                                    <Row className="col-12"><strong><u>{label}</u></strong></Row>
                                    <div className="col-12 mt-2 m-0 p-0">
                                        <Row className="m-0 p-0 mb-3">
                                            <div className="col-4 m-0 p-0"><strong><FormattedMessage id='deviceStateChange.popup.label.deviceCount' /></strong></div>
                                            <div className="col-4 m-0 p-0"><strong><FormattedMessage id='deviceStateChange.popup.label.currentState' /></strong></div>
                                            <div className="col-4 m-0 p-0"><strong><FormattedMessage id='deviceStateChange.popup.label.newState' /></strong></div>
                                        </Row>
                                        {Object.keys(selectedRowsGroupBy[label]).map((item, i) => {
                                            let deviceData = [];
                                            this.props.deviceStateData && this.props.deviceStateData[label.toLowerCase()][item].map(field => deviceData.push({ label: field, value: field }))
                                            return (
                                                <Row key={label + '-' + item + '-' + i} className="m-0 p-0 mb-3 deviceStatePopup">
                                                    <div className="col-4 m-0 p-0">{selectedRowsGroupBy[label][item].length}</div>
                                                    <div className="col-4 m-0 p-0">{item}</div>
                                                    <div className="col-4 m-0 p-0">
                                                        <Fragment>
                                                            <SingleSelect data={deviceData} setType={(e) => this.ddlValueUpdate(e, label.toLocaleLowerCase(), item)} value={this.state.selectedItems[label.toLowerCase()] ? this.state.selectedItems[label.toLowerCase()][item] ? this.state.selectedItems[label.toLowerCase()][item] : "--select--" : "--select--"} icon='ok' />
                                                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                                        </Fragment>
                                                    </div>
                                                </Row>)
                                        })}
                                    </div>
                                </Form>
                            )
                        })}
                    </Fragment> :
                    <Fragment>
                        {!this.state.newStatePopupErrorMessage && !this.state.successMessage ?
                            Object.entries(this.state.selectedItems).map(([key, value], i) => {
                                return (
                                    <Form key={key + '-' + value + '-' + i}>
                                        <Row className="col-12"><strong><u>{key.toUpperCase()}</u></strong></Row>
                                        <div className="col-12 mt-2 m-0 p-0">
                                            <Row className="m-0 p-0 mb-3">
                                                <div className="col-6 m-0 p-0"><strong><FormattedMessage id='deviceStateChange.popup.label.devices' /></strong></div>
                                                <div className="col-6 m-0 p-0 pl-2"><strong><FormattedMessage id='deviceStateChange.popup.label.deviceState' /></strong></div>
                                            </Row>
                                            {Object.entries(value).map(([item, data], j) => {
                                                return (
                                                    <Row key={item + '-' + data + '-' + j} className="m-0 p-0 mb-4">
                                                        <div className="col-6 m-0 p-0 newStateDevices">{selectedRowsGroupBy[key.toUpperCase()][item].map(property => <div key={property.id}>{property.serialNumber}</div>)}</div>
                                                        <div className="col-6 m-0 p-0 pl-2">{data}</div>
                                                    </Row>)
                                            })}
                                        </div>
                                    </Form>)
                            }) :
                            <Fragment>
                                {this.state.newStatePopupErrorMessage ?
                                    <span className="text-danger">{this.state.newStatePopupErrorMessage}</span> :
                                    <span className="text-success">{this.state.successMessage}</span>}
                            </Fragment>
                        }
                    </Fragment>
                }
            </div>
            modalFooter = !this.state.selectedNewStatePopup ? [{ className: "modalSaveBtn", name: <FormattedMessage id='deviceStateChange.popup.button.next' />, value: "next" }] : !this.state.newStatePopupErrorMessage && !this.state.successMessage ? [{ className: "modalCancelBtn", name: <FormattedMessage id='deviceStateChange.popup.button.cancel' />, value: "cancel", disabled: this.state.isBtnDisabled }, { className: "modalSaveBtn", name: <FormattedMessage id='deviceStateChange.popup.button.confirm' />, value: "confirm", disabled: this.state.isBtnDisabled }] : [{ className: "modalSaveBtn", name: <FormattedMessage id='deviceStateChange.popup.button.close' />, value: "close" }];
        } else {
            modalTitle = <div className="popup-title"><FormattedMessage id={`deviceStateChange.updateConfirmation.popup.title`} /></div>
            modalBody = <div className="min-vh-25">
                {this.props.isLoadingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                <div className="mb-2"><FormattedMessage id='deviceStateChange.popup.updateConfirmation.message' /></div>
                <div className="mb-2"><FormattedMessage id='deviceStateChange.popup.updateConfirmation.configInprocess' /></div>
                <div className="mb-2"><FormattedMessage id='deviceStateChange.popup.updateConfirmation.fwUpgradeInprocess' /></div>
                <div className="mb-2"><FormattedMessage id='deviceStateChange.popup.updateConfirmation.wanttoContinue' /></div>
            </div>
            modalFooter = [{ className: "modalCancelBtn", name: <FormattedMessage id='deviceStateChange.popup.button.cancel' />, value: "updateCancel" }, { className: "modalSaveBtn", name: <FormattedMessage id='deviceStateChange.popup.button.ok' />, value: "ok" }]
        }
        return (
            <Fragment>
                {this.props.isDeviceStateModalOpen ?
                    <ModalWindow
                        show={this.props.isDeviceStateModalOpen}
                        onHide={() => this.props.closeModal("isDeviceStateModalOpen")}
                        size={"md"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        )
    }
}
export default DeviceStateChange;

import React, { Fragment } from 'react';
import { formFields } from '../../../../utility/addDeviceUtility';
import { FormControl, FormGroup, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import SingleSelect from '../../../../hoc/singleSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import ToggleSwitch from '../../../../hoc/toggleSwitch';

const ZM1DeviceComponent = (props) => {
    let zm1Fields = formFields;
    if (props.nodeType === "SITE") {
        zm1Fields = formFields.filter(field => field.key !== "lateral")
    }
    return (
        <div>
            {
                zm1Fields.map(field => {
                    let label, template;
                    if (props.state.deviceType) {
                        if ((props.state.deviceType === field.value || field.value === "common" || field.value === "ZM1") && (props.state.deviceType === "ZM1") && (props.state.deviceType !== "UM3+" || field.displayType === "common")) {
                            label = <FormattedMessage id={`groupmanagement.label.${field.key}`} />;
                            if (field.type === 'input' && (props.state.deviceType === field.value || field.value === "common" || field.value === "ZM1")) {
                                template = <FormControl type="text" label="Text" className="inpFlSz" onChange={(e) => props.inputChangeHandler(e, field.key)} value={props.state[field.key] ? props.state[field.key] : ""}/>
                            } else if (field.type === 'select' && (props.state.deviceType === field.value || field.value === "common" || field.value === "ZM1")) {
                                template = <Fragment>
                                    <SingleSelect disabled={field.isDisabled} data={field.options.length > 0 ? field.options : [{ label: "--select--", value: "--select--" }]} setType={(e) => props.ddlValueUpdate(e, field.key)} value={props.state[field.key] ? props.state[field.key] : "--select--"} icon='ok' />
                                    <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                </Fragment>
                            }
                            else if (field.type === "textarea" && (props.state.deviceType === field.value || field.value === "common" || field.value === "ZM1")) {
                                template = <textarea rows="3" className="inpFlSz form-control" onChange={(e) => props.inputChangeHandler(e, field.key)} value={props.state[field.key] ? props.state[field.key] : ""}/>
                            }
                            else if (field.type === "toggle" && (props.state.deviceType === field.value || field.value === "common" || field.value === "ZM1")) {
                                template = <ToggleSwitch value={props.switchSecuredState} toggleSwitchValue={() => {props.onToggleSecured()}} leftValue="On" rightValue="Off" />
                            }
                        }
                    }

                    let data = label && template ?
                        <FormGroup key={field.key} className="clearfix">
                            <Col sm={4} xs={4} className="float-left mb-2 m-0 p-0">
                                {label}{field.isMandatory ? "*" : ""}
                            </Col>
                            <Col sm={8} xs={8} className="float-left mb-2">
                                {template}
                            </Col>
                        </FormGroup> : ""
                    return (data)
                })
            }
        </div>
    )
}

export default ZM1DeviceComponent;
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as homeActionBuilder from "../../home/actions/homeActionsBuilder";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';

const loginForm = (props) => {
    return (
        <Form action={props.loginURL} method="POST">
            {
                props.samlLogout || window.location.pathname.includes('samlLogout') ?
                    <Fragment>
                        <div>
                            <h4>You signed out of your Ample account.</h4>
                            <strong>It's a good idea to close all browser windows.</strong>
                        </div>
                    </Fragment> :
                    props.samlFailed ?
                        <p className="text-danger">Request on Ample could not be performed due to an error on the part of the SAML responder or SAML authority. You can clear the browser cache or close the browser and try logging in again.</p> :
                        <Fragment>
                            <FormGroup controlId="formHorizontalAccountId" >
                                <FormControl type="input" name="accountId" value={props.accountId} className='dspnone' onChange={() => props.updateFormLogin(false, "", false)} />
                            </FormGroup>
                            
                            <FormGroup controlId="formHorizontalEmail" className='text-center' onClick={() => props.updateFormLogin(false, "", false)}>
                                <FormLabel className='roundedLabel'><FontAwesomeIcon icon={faUserEdit} size="sm" /> {props.username} </FormLabel> 
                                {/* below lines are mandatory to send username and account id to j_spring_security_check */}
                                <FormControl type="input" name="username" value={props.username} className='dspnone' onChange={() => props.updateFormLogin(false, "", false)} />
                            </FormGroup>

                            <FormGroup controlId="formHorizontalPassword">
                                <FormattedMessage id={'db.common.password'}>
                                    {placeholder=>  
                                        <FormControl
                                            autoFocus={true}
                                            type="password"
                                            name="password"
                                            onChange={(evt) => props.changePassword(evt)}
                                            placeholder={placeholder} 
                                        />
                                    }
                                </FormattedMessage>
                            </FormGroup>

                            <FormGroup>
                                <Button
                                    type="submit"
                                    disabled={(props.username && props.password) ? false : true}
                                    block
                                    variant="warning">
                                    <FormattedMessage id='login.form.login' />
                                </Button>
                            </FormGroup>
                            {!props.formData.hideForgotPassword && <Button variant="link" ><Link to="/forgotPassword"><FormattedMessage id='login.forgotlink' /></Link></Button>}
                        </Fragment>
            }
        </Form>
    )
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission,
        samlLogout: state.headerData.samlLogout,
        formData: state.loginData,
    }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateFormLogin: (bool, errorMsg, hideForgotPassword) => dispatch(homeActionBuilder.updateLogin(bool, errorMsg, hideForgotPassword))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(loginForm);
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import DragSiteItemComponent from './dragSiteItemComponent';
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../../hoc/modelWindow';
import { editSiteOrder, updateSiteOrder } from '../services/treeService';
import { TreeContext } from '../provider/treeProvider';
import { getNodeRouteParams, updateTreeOnEditNode } from '../containers/treeMethods';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopOverMessage from '../../hoc/popOver';
import { faQuestionCircle, faCheckCircle, faTimesCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import BlankPage from '../../hoc/blankPage';
import { getProfileData } from '../../mainContent/equipmentProfile/services/eProfileService';
import { columnData, editableFields } from '../../utility/treeViewUtility';
import ampleStore from '../../store';
import { showAlertToaster } from '../../login/actions/loginActionDispatch';
import Loader from '../../hoc/loader';
import AlertMessage from '../../hoc/alert';
import * as treeBuilderActions from './../actions/index';
import { withRouter } from 'react-router-dom';
import TextField from '../../hoc/form/textField';
import { nodeOperations } from '../services/treeService';
import Tooltip from '../../hoc/tooltip';

class EditSiteOrderComponent extends PureComponent {
    static contextType = TreeContext;
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            normalOpen: false,
            cableProfileOptions: [{'label': 'select', 'value': ''}],
            list : [],
            masterList : [],
            orgId: this.props.tree[0].id,
            errorFields:[],
            message: {
                showAlertToaster: false,
                msg: "",
                type: ""
            },
            noSiteData: '',
            newLateralName: this.props.clickedNode.node.name,
            savedLateralName: this.props.clickedNode.node.name,
            editLateralName: false,
            enableNameSave: false
        }
    }

    componentDidMount = () => {
        this.getSites();
    }

    getSites = () => {
        this.setState({ loader: true }, async () => {
            const data = getNodeRouteParams(this.props.clickedNode);
            const params = {'apiType':'LATERAL','ROOTNODE': this.state.orgId,'REGION': data[3].name, 'SUBSTATION': data[5].name, 'FEEDER': data[7].name, 'LATERAL': data[9].name}
            const res = await editSiteOrder(params);
            const arr = ["cableLengthToNextSite","sectionId","resistanceImpedance","noOfDownstreamCustomers"];
            if (res && res.data && res.data.status !== "FAIL") {
                let siteList = res.data.data?.length ? res.data.data.map((item, index) => {
                     item.siteOrder = index + 1
                     item.errorFields = []
                     arr.forEach(e => item[e] = item[e] ? item[e].toString() : item[e]);
                     return item;
                }) : []
                this.setState({ list: siteList, masterList: JSON.parse(JSON.stringify(siteList)), loader: false, noSiteData: !siteList.length ? res.data.message : '' }, () => {
                    this.hasNormalOpen()
                    this.getCableProfileData();
                    this.state.list.length && !this.hasNormalOpen() && this.addNormalOpen();
                });
            } else {
                this.setState({ loader: false })      
            }
        });
    }

    handleSave = () => {
        let obj = this.state.list;
        if(!this.nullCheckFields(obj)) {
            obj.map(item => {
                delete item.errorFields
                return item.orgId = this.state.orgId
            })
            this.setState({ loader: true }, () => {
                updateSiteOrder(obj).then(resp => {
                    if (resp && resp?.data?.status !== "FAIL") {
                        this.setState({ loader: false });
                        ampleStore.dispatch(showAlertToaster(<><strong>{this.props.clickedNode.node.name}</strong> : {resp?.data?.message ? resp.data.message : <FormattedMessage id={`groupmanagement.context.siteOrderSaved`} />}</>, 'success'))
                        this.props.closeModal()
                    } else {
                        this.setState({ loader: false, message: { showAlertToaster: true, msg: resp.data.message, type: 'danger'} });
                    }
                })
            })
        }
    }

    nullCheckFields = (list) => {
        let tempList = list.map(site => {
            editableFields.map(column => {
                if(site.siteName !== 'Normally Open' && (site[column] === '' || site[column] === null)) {
                    site.errorFields.push(column)
                }
            })
            return site
        })
        this.setState({list : tempList})
        return tempList.some(item => item.errorFields?.length > 0)
    }

    onBtnClickHandlera = (value) => {
        if (value === 'cancel') this.props.closeModal();
        else if (value === 'save') this.handleSave();
    }
    
    addNormalOpen = () => {
        let newList = {
            "siteOrder": this.state.list.length + 1,
            "siteName": "Normally Open",
            "cableLengthToNextSite": '',
            "orgId": this.state.orgId,
            "feederId": this.state.list[0].feederId,
            "lateralId": this.state.list[0].lateralId
        };
        this.setState({ 
            list: [...this.state.list, newList],
            normalOpen: true
        },()=>{this.hasNormalOpen()})
    }
    hasNormalOpen = () => {
        return this.state.list.findIndex((item)=> item.siteName === "Normally Open") < 0 ? false : true
    }

    getPopOver(key, label) {
        return (<PopOverMessage trigger="focus" keyVal={`${key}-${label}`} placement="top" popoverBody={
            <div className="m-2"><FormattedMessage id={`groupmanagement.context.${label}-popOverContent`} /></div>
        } displayPopOver={
            <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
        } />)
    }

    getModalTitle = () => {
        return (<Fragment>
            <div className="popup-title">
                <FormattedMessage id={`groupmanagement.context.editLateral`} /> 
                <div className='edit-lateral-name' >{!this.state.editLateralName ? 
                    <span>{this.state.savedLateralName} &nbsp;<Tooltip baseContent={<FontAwesomeIcon icon={faPencilAlt} size="sm" onClick={() => this.editLateral()}/>} message={"Edit Lateral Name"} placement={'top'} /></span> : 
                        <><span className='lateral-name-text-wrapper'><TextField
                            type="text"
                            key={`edit_lateral_name`}
                            value={this.state.newLateralName}
                            className={`${this.state.messageDisplay ? 'err-border-red' : ''}`}
                            handleOnchange={value => this.validateLateralName(value)}
                        /></span>
                        <Tooltip baseContent={<FontAwesomeIcon icon={faTimesCircle} size="sm" onClick={() => this.closeEditLateral()}/>} message={"Cancel"} placement={'top'} />
                        <Tooltip baseContent={<FontAwesomeIcon icon={faCheckCircle} size="sm" className={`${!this.state.enableNameSave || this.state.messageDisplay ? 'disable' : ''}`} onClick={(e)=>this.onLateralNameChange(this.state.newLateralName, e)}/>} message={"Save Lateral Name"} placement={'top'} />
                        </>
                    }
                    <div className='lateral-name-error'>{this.state.messageDisplay}</div>
                </div>
                <span>{this.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" />: null}</span>
            </div>
        </Fragment>)
    }

    editLateral = () => {
        this.setState({editLateralName : true, newLateralName: this.state.savedLateralName})
    }

    closeEditLateral = () => {
        this.setState({messageDisplay: "", newLateralName: this.state.savedLateralName, editLateralName: false, enableNameSave: false})
    }

    validateLateralName = (value) => {
        if (value.length > 0) {
            if (!(/^(?!.*[\\\\/;$(#?%).].*)/).test(value)) {
                this.setState({ disabled: true, newLateralName: value, messageDisplay: <FormattedMessage id='groupmanagement.context.message.name.invalid' /> });
            } else {
                value !== this.props.clickedNode.node.name ? this.setState({ disabled: false, newLateralName: value, messageDisplay: "", enableNameSave: true }) : this.setState({enableNameSave: false, newLateralName: value, messageDisplay: <FormattedMessage id='groupmanagement.sameName.error.label' />});
            }
        } else {
            this.setState({ disabled: true, newLateralName: "", messageDisplay: <FormattedMessage id='groupmanagement.addNode.error.label' /> });
        }
    }
    
    getModalBody = (list) => {
        return (
            <>{this.state.loader ? <Loader elementClass="tree-ajax-loader center-element" />: null}
                {this.state.message.showAlertToaster ? <div className='d-flex justify-content-center'><AlertMessage type={this.state.message.type} timer={10000} message={this.state.message.msg} setVisible={() => this.closeAlertModal()} /></div> : ""}
            {this.state.list.length ? 
            <Row className={`site-order-wrapper`}>
                {columnData.map((column, index)=> <div className={`col-item ${column.className}`} key={index}>{column.displayName} {this.getPopOver(index, column.fieldName)}</div>)}
                <DragSiteItemComponent
                    newList={list}
                    masterList={this.state.masterList}
                    hasNormalOpen={this.hasNormalOpen()}
                    cableProfileOptions={this.state.cableProfileOptions}
                    onChangeHandler={(value, item, column, field, cableProfileId)=>this.onChangeHandler(value, item, column, field, cableProfileId)}
                    reorderList={(list)=>this.reorderList(list)}
                    errorFields={this.state.errorFields}
                />
            </Row>:!this.state.loader && <BlankPage blankText={this.state.noSiteData} /> }</>
        )
    }

    setCableProfileOptions = (cableProfilesList) => {
        let tempList = cableProfilesList.map((item)=> {
            return {'label': item.cableProfileName, 'value': item.id }
        })
        this.setState({ cableProfileOptions: [...this.state.cableProfileOptions, ...tempList]})
    }

    onChangeHandler = (value, item, column, field, cableProfileId) => {
        let tempList = this.state.list;
        tempList[tempList.findIndex(data => data.siteOrder === item.siteOrder)][field] = value;
        field === "cableProfile" ? tempList[tempList.findIndex(data => data.siteOrder === item.siteOrder)].cableProfileId = cableProfileId : ''
        this.validateFields(value, item, column, field)
        if(JSON.stringify(tempList.find(data => data.siteId === item.siteId)) !== JSON.stringify(this.state.masterList.find(data => data.siteId === item.siteId)) ){
            tempList.find(data => data.siteId === item.siteId).checkSiteUpdated = true
        } else {
            tempList.find(data => data.siteId === item.siteId).checkSiteUpdated = false
        }
        this.setState({list: tempList});
        this.forceUpdate()
    }
    
    validateFields =(value, item, column, field) => {
        let tempArray = [...this.state.list];
        if(value && (column.type === 'text' && column.regExp.test(value))) {
            if(item.errorFields.includes(field)) {
                item.errorFields.splice(item.errorFields.indexOf(field),1) 
            }
        } else if(value && (column.type === 'dropdown')){
            if(item.errorFields.includes(field)) {
                item.errorFields.splice(item.errorFields.indexOf(field),1) 
            }
        } else if(!item.errorFields.includes(field)) {
            item.errorFields.push(field);
        }
        tempArray.map((list) => { 
            if(list.siteOrder === item.siteOrder){
                return list.errorFields = item.errorFields
            }
        })
        this.setState({list: tempArray})
    }

    reorderList = (list) => {
        this.setState({list: list})
    }

    getCableProfileData = () => {
        getProfileData("getCableProfileData", this.state.orgId)
            .then((resp) => {
                resp && resp.length && this.setCableProfileOptions(resp);
            }).catch((err) => { })
    }

    onLateralNameChange = (value, e) => {
        let nodeDetails = {};
        nodeDetails.type = this.props.clickedNode.node.type;
        nodeDetails.name = value;

        this.setState({ isLoading: true}, async () => {
            const routeParams = getNodeRouteParams(this.props.clickedNode);
            const res = await nodeOperations(routeParams, 'editNode', nodeDetails);
            if (res) {
                if (res.status !== "FAIL") {
                    this.setState({ isLoading: false, editLateralName: false, enableNameSave: false, savedLateralName: res.name, newLateralName: res.name});
                    let data = updateTreeOnEditNode(this.context.state, this.props.clickedNode, nodeDetails);
                    this.props.updateSelectedTree(data);
                    this.context.loadNextLevel({ "node": this.context.state.highlightedNode.node }, this.context.state.highlightedNode.node.routeParams, null, 'editNode');
                    this.context.setLoadDevicesList(true);
                } else {
                    this.setState({ isLoading: false, editLateralName: true, messageDisplay: res.message });
                }
            } else {
                this.setState({ isLoading: false, editLateralName: false, enableNameSave: false }); 
            }
        })
    }

    closeAlertModal =() => {
        this.setState({message: {
            showAlertToaster: false,
            msg: '',
            type: ''
        }})
    }

    render() {
        return (
            <Fragment>
                {(this.props.isModalOpen ?
                <ModalWindow
                    show={this.props.isModalOpen}
                    onHide={() => this.props.closeModal()}
                    size={"xl"}
                    title={this.getModalTitle()}
                    closeButton={false}
                    dialogClassName={"site-order-modal"}
                    modeldata={{ content: this.getModalBody(this.state.list) }}
                    onBtnClickHandler={this.onBtnClickHandlera}
                    footer={
                        [{ className: "modalSaveBtn", name: <FormattedMessage id='dm.column.save' />, value: "save", disabled: this.state.list.some(item => item.errorFields?.length > 0) || !this.state.list.length }, { className: "modalCancelBtn", name: <FormattedMessage id='dm.column.cancel' />, value: "cancel"}]
                    }
                /> : "")}
            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        tree: state.treeviewData.tree
    };
}

const mapDispatchToProps = dispatch => {
    return {
      updateSelectedTree: (tree) => dispatch(treeBuilderActions.updateSelectedTree(tree))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditSiteOrderComponent));

import React, { Component} from 'react';
import { Row, Col } from 'react-bootstrap';
import DisturvanceFilter from '../components/disturbancesFilter';
import ExportDetails from '../components/exportDetails';
import CountersView from '../components/countersView';
import { DisturbanceAnalyticsContext } from '../provider/disturbanceAnalyticsProvider';

class DisturbanceAnalyticsContainer extends Component {
  static contextType = DisturbanceAnalyticsContext;
  render() {
    return (
      <React.Fragment>
        <Col className="content-right pad-rgt-lef mt-3 m-0 p-0 pt-3" xs={12} md={12}>
          <Row className='m-0 p-3 border-1px'>
            <Col>
                <DisturvanceFilter />
            </Col>
          </Row>
          <Row className='m-0 pt-3 pb-1'>
            <Col>
                <ExportDetails />
            </Col>
          </Row>
          <Row className='m-0 pb-3 p-0'>
            <Col>
                <CountersView />
            </Col>
          </Row>
        </Col>
      </React.Fragment>
    );
  }
}

export default DisturbanceAnalyticsContainer;
import * as actionDispatch from './dashboardActionDispatch';

export const updateSelectedTreeFromDashboard = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateSelectedTreeFromDashboard(data));
    };
};

export const updateHeatmapDateRangeData = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateHeatmapDateRangeData(data));
    };
};

export const updateHeatmapDefaultType = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateHeatmapDefaultType(data));
    };
};

export const updateHeatmapPayload = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateHeatmapPayload(data));
    };
};

export const updateBubbleDateRangeData = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateBubbleDateRangeData(data));
    };
};

export const updateBubblePayload = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateBubblePayload(data));
    };
};

export const updateWatchlistDateRangeData = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateWatchlistDateRangeData(data));
    };
};
export const updateElogDateRangeData = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateElogDateRangeData(data));
    };
};

export const updateElogStateFilterData = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateElogStateFilterData(data));
    };
};
export const updateElogTypeFilterData = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateElogTypeFilterData(data));
    };
};
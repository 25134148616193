import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import Table from '../../../../hoc/table';
import { faultDetailsData } from '../../../../utility/faultDetailsUtility';
import BlankPage from '../../../../hoc/blankPage';
import Loader from '../../../../hoc/loader';
import { FormattedMessage } from 'react-intl';
import { DetailsContext } from '../provider/detailsProvider';
import Tooltip from '../../../../hoc/tooltip';
import TableMultiSortNumber from '../../../../hoc/tableMultiSortNumber';
import FaultChartModal from './faultChartModal';

class DetailsTable extends PureComponent {
    static contextType = DetailsContext;
    constructor(props) {
        super(props);
        this.state = {
            columnsData: [
                ...faultDetailsData.columnsData,
                { accessor: 'waveformStatus', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.faultevents.column.waveformStatus"} />} message={<FormattedMessage id={"events.faultevents.column.tooltip.waveformStatus"} />} placement={'top'} />} />, Cell: ({ original }) => <span disabled={this.context.state.userRole === 4 && original.waveformStatus === 'Download' ? true : false} className={this.context.state.userRole === 4 && original.waveformStatus === 'Download' ? "wave-form-status-disabled" : "wave-form-status-enable cursor-pointer"} onClick={() => this.context.state.userRole === 4 && original.waveformStatus === 'Download' ? (e) => e.preventDefault() : this.handleClickWaveformStatus(original)}>{original.waveformStatus ? original.waveformStatus : "-"}</span> },
            ]
        }
    }

    handleClickWaveformStatus = (original) => {
        if(original.waveformStatus !== 'Download'){
            this.context.handleOpenOrCloseChartModal(true, original)
        } else this.context.handleWaveformDownload(original)
    }

    updateTableColumn = (column) => {
        let finalcolumn = [];
        if (this.context.state.treeNode.type === "FEEDER") {
            finalcolumn = column.filter(item => item.accessor !== "feeder")
        } else finalcolumn = column.map(item => item)
        return finalcolumn
    }

    rowSelection = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => {
                    rowInfo.original.feederFaultId && this.context.handleRowOnClick(rowInfo.original, state);
                },
                style: {
                    background: rowInfo.original.feederFaultId === this.context.state.feederFaultId ? '#F1D7AD' : '',
                }
            }
        } else {
            return {}
        }
    }

    render() {
        return (
            <Row className={`details-table border-1px ${!this.context.state.faultDataLineDisplay.length ? 'efl' : ''}`}>
                <Col className='p-0'>
                    {this.context.state.isLoadingMainTableData ? <Loader elementClass="tree-ajax-loader center-element" /> : this.context.state.mainTableData.length !== 0 ?
                        <span className={`table_${this.context.state.treeNode.type}`}>
                            <Table
                                tableColumn={this.updateTableColumn(this.state.columnsData)}
                                tableRowData={this.context.state.mainTableData}
                                showPagination={false}
                                rowSelectionCallback={this.rowSelection}
                                getTableSortedData={this.context.updateFirstAsRowSelected}
                                resizable={false}
                                pageSize={100}
                                isManualTable={true}
                                pageNo={1}
                            />
                            {this.context.state.showLoadMore && <button className="load-more" onClick={() => {this.context.loadMoreTableData()}}><FormattedMessage id={"events.register.loadMore"} /></button>}
                        </span>
                        :
                        <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                    }
                    {this.context.state.isOpenChartModal && <FaultChartModal />}
                </Col>
            </Row>
        )
    }
}

export default DetailsTable;
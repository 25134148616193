import axios from "axios";
import { getURL } from "../../providers/configProvider"
import { Buffer } from "buffer";


export const authorizeApi=(params)=>{
    const url = getURL('login', 'authorize', {'RESPONSETYPE':params.response_type,'CLIENTID':params.client_id,'SCOPE':params.scope,'REDIRECTURI':params.redirect_uri,'CODECHALLENGE':params.code_challenge,'CCMETHOD':params.code_challenge_method}, true);
    return url;
}

export const getTokenService = (params,client,secret)=>{
    const url = getURL('login', 'tokenApi', {}, true);
    return axios.post(url, params, {
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${Buffer.from(`${client}:${secret}`).toString('base64')}`
        }
    });
}
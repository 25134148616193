import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import HighCharts from "highcharts/highstock";
import HC_more from 'highcharts/highcharts-more';
import { injectIntl } from 'react-intl';
import Moment from 'moment';
import mTZ from 'moment-timezone';
import { dateConvertion } from '../../../services/utilityService';

HC_more(HighCharts)
window.moment = Moment;
mTZ();

class ScaleChart extends React.PureComponent {
    constructor(props) {
        super(props)
        HighCharts.setOptions({
            colors: ['#FF7474', '#5CD65C', '#7495BE'], // controls which colors are used.
            global: {
                useUTC: true,
                timezone: props.timezone
            }
        })
        this.afterChartCreated = this.afterChartCreated.bind(this)
    }
    componentDidMount() {
    }


    setExtremes = (min,max) => {	
		if( this.scrollCharrt ) this.scrollCharrt.xAxis[0].setExtremes(min, max, true, false);
	}

    afterChartCreated = (chart) => {
        this.scrollCharrt = chart;
        this.forceUpdate();
    }

    componentDidUpdate(prevProps) {
        this.setExtremes(this.props.startDateTimestamp,this.props.endDateTimestamp);
        this.scrollCharrt.reflow();
        setTimeout( () => {
            window.dispatchEvent(new Event('resize'));
        }, 100);
    }

    render() {
        const { startDate, endDate, startDateTimestamp, endDateTimestamp, maintainZoom, timezone } = this.props;

        return (
            <React.Fragment>
                <HighchartsReact
                    highcharts={HighCharts}
                    constructorType={"stockChart"}
                    options={{ //with format message
                        ...this.props.options.highcharts,
                        series: [
                            { data: [[startDateTimestamp], [endDateTimestamp]]}
                        ],
                        ...this.props.options.highcharts.navigator.xAxis.max = (maintainZoom) ? dateConvertion(endDate+' 23:59:59:999', 'offsetTimestamp', timezone) : endDateTimestamp,
                        ...this.props.options.highcharts.xAxis.max = endDateTimestamp,
                        ...this.props.options.highcharts.navigator.xAxis.min = (maintainZoom) ? dateConvertion(startDate+' 00:00:00:000', 'offsetTimestamp', timezone) : startDateTimestamp,
                        ...this.props.options.highcharts.xAxis.min = startDateTimestamp,
                        ...this.props.options.highcharts.xAxis.events.setExtremes = (e) => {
                            if (e.trigger === 'navigator' && (e.DOMEvent.type === 'mouseup' || e.DOMEvent.type === 'touchend') && this.props) {
                                this.props.afterSetExtremes(this.scrollCharrt, e)
                            }
                        },
                        ...this.props.options.highcharts.xAxis.events.afterSetExtremes = (e) => {
                            if (!e.hasOwnProperty('DOMEvent') && this.props) {
                                this.props.afterSetExtremes(this.scrollCharrt, e);
                            }
                        }
                    }}
                    callback={this.afterChartCreated}
                />
            </React.Fragment>
        )
    }
}

export default injectIntl(ScaleChart);
import React, { Component } from 'react'
import 'react-table-6/react-table.css';
import { EProfileContext } from '../provider/eProfileProvider'
import ModalWindow from '../../../hoc/modelWindow';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../hoc/loader';
import AlertMessage from '../../../hoc/alert';
import { equipmentProfileData } from '../../../utility/equimentprofileUtility';

class DeleteEProfilesModal extends Component {
    static contextType = EProfileContext;
    getBodyContent = () => {
        if (this.context.state.selectedProfile === equipmentProfileData.profileList[0]) {
            return this.context.state.equipmentProfileAssignedtoDevice.length ? <FormattedMessage id={"db.equipmentprofile.modalMessage"} /> : <FormattedMessage id={"db.equipmentprofile.sureModalMessage"} />
        } else {
            return this.context.state.equipmentProfileAssignedtoDevice.length ? <FormattedMessage id={"db.cableprofile.modalMessage"} /> : <FormattedMessage id={"db.cableprofile.sureModalMessage"} />
        }
    }
    render() {
        const { modalLoader, errmsg, openDeleteModal, equipmentProfileAssignedtoDevice, selectedProfile } = this.context.state;

        let modalTitle = <><div className="popup-title" id="contained-modal-title-sm"><FormattedMessage id={"db.equipmentprofile.deleteProfile"} /></div></>
        let modalBody = <>
            {modalLoader && <Loader elementClass="tree-ajax-loader center-element" />}
            {errmsg && <AlertMessage position="w-100" message={errmsg} type="danger" setVisible={this.context.closeErrorMessages} />}
            <div>{this.getBodyContent()}</div>
            <br />
            {equipmentProfileAssignedtoDevice.length ?
                <>
                    <div>{selectedProfile === equipmentProfileData.profileList[0] ? <>Transformer Profile <strong>{equipmentProfileAssignedtoDevice[0].transformerProfileName}</strong></> : <>Cable Profile <strong>{equipmentProfileAssignedtoDevice[0].cableProfileName}</strong></>} is currently assigned to <strong>{equipmentProfileAssignedtoDevice.length}</strong> {selectedProfile === equipmentProfileData.profileList[0] ? "UM1 and/or VC10 device(s) " : "site(s) "}</div>
                    <br />
                </> : ""}
        </>
        let modalFooter = [{ className: "modalCancelBtn", name: <FormattedMessage id='configure.popup.button.cancel' />, value: "cancel" }, { className: errmsg || equipmentProfileAssignedtoDevice.length ? 'modalSaveBtn disabled' : 'modalSaveBtn', name: <FormattedMessage id='configure.popup.button.ok' />, value: "continue" }]

        return (
            <>
                {openDeleteModal &&
                    <ModalWindow
                        show={openDeleteModal}
                        onHide={() => this.context.closeModal()}
                        size={"lg"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.context.onClickDeleteBtn}
                    />}
            </>
        );
    }
}

export default DeleteEProfilesModal;

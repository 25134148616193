import React, { Fragment, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faGripLines, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SingleSelect from '../../hoc/singleSelect';
import { columnData } from '../../utility/treeViewUtility';
import TextField from '../../hoc/form/textField';
import ReactSVG from 'react-svg';
import Mdark from '../../resources/images/m-dark.svg'
import Zdark from '../../resources/images/z-dark.svg'
import Udark from '../../resources/images/u-dark.svg'
import Vdark from '../../resources/images/v-dark.svg'

const DragSiteItemComponent = (props) => {

  const [list, updateList] = useState([...props.newList]);
  const [masterList, updateMasterList] = useState([...props.masterList]);

  const onDragEnd=(result)=> {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      list,
      result.source.index,
      result.destination.index
    );
    items.forEach((item,index)=>{
        item.siteOrder = index + 1
        item.currentDirection = props.hasNormalOpen && (item.siteOrder > normalOpenPosition(items))
    })
    let tempMasterList = masterList;
    tempMasterList.forEach(data => {
      data.siteOrder = items.find(item => item.siteId === data.siteId).siteOrder
    })
    props.reorderList(items)
    updateList(items)
    updateMasterList(tempMasterList)
  }

  useEffect(()=>{
    updateList(props.newList)
  },[props]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const isNormalOpen = (item) => {
    return item.siteName === 'Normally Open'
  }

  const normalOpenPosition = (siteList) =>{
    let pos = false;
    siteList.map((item, index)=>{
      if(item.siteName === 'Normally Open') pos = index + 1
    })
    return pos
  }

  const getCurrentDirection = (item) => {
    return props.hasNormalOpen && (item.siteOrder > normalOpenPosition(list)) ? <FontAwesomeIcon icon={faArrowUp} size="sm" /> : <FontAwesomeIcon icon={faArrowDown} size="sm" />;
  }

  const selectedCableProfile = (item) => {
    return list[list.findIndex(data => data.siteOrder === item.siteOrder)].cableProfile ? list[list.findIndex(data => data.siteOrder === item.siteOrder)].cableProfile : (props.cableProfileOptions.length && props.cableProfileOptions[0].label)
  }

  const getCableProfileLabel = (id) => {
    return id ? props.cableProfileOptions.find(item => item.value === id).label : ''
  }

  const getSvgSource = (item)=>{
    return item.deviceType === "MM3" ? Mdark : item.deviceType === "ZM1" ? Zdark : item.deviceType === "UM3+" || item.deviceType === "UM1" ? Udark : item.deviceType === "VC10" ? Vdark : item.siteName==="Normally Open" ? "" : Zdark;
  }

  return (
    <Fragment>
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className='site-list' >
              {list.map((item, index) => (
                <Draggable key={item?.siteOrder?.toString()} draggableId={item?.siteOrder?.toString()} index={index}>
                  {(provided) => (
                    <Form.Row ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={`site-row ${(isNormalOpen(item) ? 'normal-open' : '')}`} >
                        <div className='drag-icon-wrapper'>
                          <FontAwesomeIcon icon={faCaretUp} className='drag-icon' size="sm"/>
                          <FontAwesomeIcon icon={faGripLines} className='drag-icon' size="lg"/>
                          <FontAwesomeIcon icon={faCaretDown} className='drag-icon' size="sm"/>
                        </div>
                        <ReactSVG className="svg-node" src={getSvgSource(item)} />
                      {columnData.map((column, index)=> {
                            return <div className={`row-item ${column.className} ${(isNormalOpen(item) && column.fieldName !== 'siteName' ? 'disabled' : '')} ${column.type === 'dropdown' ? 'single-select-dropdown': ''} ${item.errorFields?.includes(column.fieldName) ? "err-border-red" : ""}`} key={index}>
                              {(()=>{
                                switch (column.type) {
                                case 'text':
                                  return <React.Fragment>
                                    <TextField
                                    type="text"
                                    key={`edit_${item.siteOrder}_${index}`}
                                    value={item[column.fieldName]}
                                    handleOnchange={value => props.onChangeHandler(value, item, column, column.fieldName)}
                                    />
                                  </React.Fragment>
                                case 'dropdown':
                                  return <SingleSelect data={props.cableProfileOptions} setType={id =>props.onChangeHandler(getCableProfileLabel(id), item, column, column.fieldName, id)} value={selectedCableProfile(item)} icon='ok'/>
                                case 'icon':
                                  return getCurrentDirection(item)
                                default :
                                  return item.siteName
                              }})()}
                              {item.errorFields?.includes(column.fieldName) && <span className='form-err-color-red site-error-message'>{column.errorMessage}</span>}
                            </div>
                        })
                      }
                    </Form.Row>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
}

export default DragSiteItemComponent;

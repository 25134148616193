import React from 'react';
import { Button } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import CustomImgIcon from '../../hoc/customImgIcon';
import TextField from '../../hoc/form/textField';
import AlertMessage from '../../hoc/alert';
import Loader from '../../hoc/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from "../../hoc/tooltip";

const ForgotPasswordForm = (props) => {
    const redirectToLogin = () =>{
        window.location.href = "/amplemanage";
    }
    return (
        <React.Fragment>
            {props.stateObj.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
            <div className="text-right logo"><CustomImgIcon icon="login_logo.png" width='12rem' height='8.5rem' /></div>
            <h3 className="login-title"><FormattedMessage id='login.gridanalytics' /></h3>
            <p className="h4"><FormattedMessage id='login.forgotpassword' /></p>
            {props.stateObj.statusMessage.message ? <p><AlertMessage type={props.stateObj.statusMessage.type} message={props.stateObj.statusMessage.message} /></p> : ""}
            <p><FormattedMessage id='login.forgotpassword.emailtxt' /></p>
            <p>
                <div className="input-with-icon">
                    <TextField
                        type="text"
                        value={props.stateObj.accountId}
                        placeholder={'Account Id'}
                        handleOnchange={(evt) => props.onChangeAccountID(evt)}
                        autoFocus={true}
                        className={props.stateObj.submited && props.stateObj.isAccountIdInvalid ? "login-error-bordercolor" : ""}
                    />
                    <span className="account-help">
                        <Tooltip
                            placement='top'
                            message={<FormattedMessage id={`login.form.help`} />}
                            baseContent={<FontAwesomeIcon icon={faQuestionCircle} size="sm" />} />
                    </span>
                </div>
            </p>
            {props.stateObj.submited && props.stateObj.isAccountIdInvalid ? <p className="login-error-color text-right"><FormattedMessage id='login.forgotpassword.accountId.invalid' /></p> : ""}
            <p><TextField
                type={'email'}
                value={props.stateObj.email}
                placeholder={'Email'}
                handleOnchange={(evt) => props.onChangeEmail(evt)}
                className={props.stateObj.submited && props.stateObj.isEmailInvalid ? "login-error-bordercolor" : ""}
            /></p>
            {props.stateObj.submited && props.stateObj.isEmailInvalid ? <p className="login-error-color text-right"><FormattedMessage id='login.forgotpassword.email.invalid' /></p> : ""}
            <p><Button onClick={props.sendResetEmail} block variant="warning"><FormattedMessage id='common.button.send' /></Button></p>
            <p><Button variant="link" onClick={()=>{redirectToLogin()}}><FormattedMessage id='login.form.login' /></Button></p>
        </React.Fragment>
    )
}

export default ForgotPasswordForm;
import React, { PureComponent } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { DetailsContext } from '../provider/detailsProvider';
import Tabs from '../../../../hoc/tabs';
import { FormattedMessage } from 'react-intl';

class ModalPhaseDetailsTable extends PureComponent {
    static contextType = DetailsContext;
    constructor(props) {
        super(props);
        this.state = {
            currentTab: ''
        }
    }

    handleTabSelect = (val) => {
        this.setState({ currentTab: val })
    }

    generateAllTabs = (data) => {
        let tmpArr = [];
        data.map(item => tmpArr.push({ label: item.label, value: item.id }));
        //data.map(item => tmpArr.push({ label: (item.id).toUpperCase(), value: item.id }));
        this.setState({ currentTab: tmpArr.length !== 0 && !this.state.currentTab ? tmpArr[0].value : this.state.currentTab })
        return tmpArr
    }

    getStratumValue = (value) => {
        var range = '';
        if (parseInt(value, 10) >= 16) {
            range = '16andmore';
        } else if ((parseInt(value, 10) >= 13) && (parseInt(value, 10) <= 15)) {
            range = '13_15';
        } else if ((parseInt(value, 10) >= 2) && (parseInt(value, 10) <= 12)) {
            range = '2_12';
        } else if (parseInt(value, 10) === 1) {
            range = '1';
        }
        return range !== '' ? <FormattedMessage id={'events.details.waveforms.eventproperties.time_stratum.' + range} /> : (value !== null) ? parseInt(value, 10) : '-';
    };

    handleOnclickDownload = (data) => this.context.downloadWaveform(data)

    render() {
        return (
            <Row>
                <Col>
                    <Tabs
                        currentTab={this.state.currentTab}
                        handleTabSelect={this.handleTabSelect}
                        allTabs={this.generateAllTabs(this.props.tabData)}
                        tabBody={
                            <div class="tableParentDiv">
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th className="text-left"> <FormattedMessage id={"events.details.waveform.chart.table.column.property"} /> </th>
                                        <th className="text-left"> <FormattedMessage id={"db.sysAdmin.status"} /> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.tableData.length !== 0 ?
                                        this.props.tableData.filter(data => (data.phase).toLowerCase().toString() === this.state.currentTab.toString()).map((item,key) => {
                                            return <React.Fragment key = {key}>
                                                <tr>
                                                    <td> <b>{item.deviceTime}</b></td> 
                                                    <td>
                                                        {item.status === 'INPROGRESS' ? <span> <FormattedMessage id={"events.details.status.downloading"} /></span> : ''}
                                                        {(item.status !== 'COMPLETE') && (item.status !== 'INPROGRESS') ?
                                                            <span className={this.context.state.userRole === 4 ? 'phase-download-disabled' : 'phase-download-active'} onClick={this.context.state.userRole === 4 ? (e) => e.preventDefault() : this.handleOnclickDownload.bind(this, item)}> <FormattedMessage id={"db.sysAdmin.download"} /></span>
                                                            : ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> <FormattedMessage id={'events.details.waveform.chart.table.row.irpt_cause_code'} /> </td>
                                                    <td> {item.irptCauseCode !== null && item.irptCauseCode !== "" && item.irptCauseCode} </td>
                                                </tr>
                                                <tr>
                                                    <td><FormattedMessage id={'events.details.waveform.chart.table.row.stratum'} /></td>
                                                    <td> {this.getStratumValue(item.stratum)}</td>
                                                </tr>
                                            </React.Fragment>
                                        })
                                        : ""}
                                </tbody>
                            </Table>
                            </div>
                        }
                    />
                </Col>
            </Row>
        )
    }
}

export default ModalPhaseDetailsTable;
import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import TableTopSection from '../components/TableTopSection'
import { systemAdminData } from '../../../utility/systemAdminUtility';
import { SystemAdminContext } from '../provider/systemAdminProvider';
import UploadSystemAdminFile from '../components/uploadSystemAdminFile';
import { FormattedMessage } from 'react-intl';
import BlankPage from '../../../hoc/blankPage';
import Loader from '../../../hoc/loader';
import Alert from '../../../hoc/alert';
import Table from '../../../hoc/table';

const CertificateFile =()=>{
    const contextData = useContext(SystemAdminContext);
    const [columnsData] = useState(systemAdminData.certificateFile);
    const {loadingCertificateUpload, certificateFileRes, loadingCertificateData, certificateFile, OSName } = contextData.state;
        return (
            <Row className="menufacturingFile">
                <Col>
                {loadingCertificateUpload && <Loader elementClass="tree-ajax-loader center-element" />}
                    <Row >
                        <Col>
                            <TableTopSection
                                title={<FormattedMessage id='db.sysAdmin.importCertificate' />}
                                displayBtn={
                                    <UploadSystemAdminFile
                                        tooltipTitle={<FormattedMessage id='db.sysAdmin.uploadCertificateFile' />}
                                        uploadFrom="certificateFile"
                                        fileAccept=".zip"
                                    />
                                }
                            />
                            {certificateFileRes.msg !== "" ?
                                <Row className="mt-3 mb-3">
                                    <Col>
                                        <Alert type={certificateFileRes.type}
                                            message={certificateFileRes.msg}
                                            setVisible={() => contextData.handleRemoveAlert('certificateFile')}/>
                                    </Col>
                                </Row>
                                : ""}
                        </Col>
                    </Row>
                    {loadingCertificateData ? <Loader elementClass="tree-ajax-loader center-element" /> : <Row>
                        {certificateFile.length !== 0 ?
                            <Col className={`${OSName === 'MacOS' ? 'react-table-firmware-full padding-0' : 'react-table-firmware padding-0'}`+' m-2'}>
                                <Table 
                                     tableColumn={columnsData}
                                     tableRowData={certificateFile}
                                     isDisplayPageRows={false}
                                    />
                            </Col>
                            :
                            <div><BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /></div>
                        }
                    </Row>}
                    
                </Col>
            </Row>
        );
}

export default CertificateFile;
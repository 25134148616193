import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { tableOptions } from '../../../../utility/deviceManagementUtility';
import { deleteDevices } from '../services/deviceService';
import { DeviceContext } from '../provider/deviceProvider';
import ModalWindow from '../../../../hoc/modelWindow';

class DeleteDeviceComponent extends Component {
    static contextType = DeviceContext;
    state = {
        errorMessage: "",
        disableButton: false
    }

    deleteDevice = async () => {
        const deviceIds = [];
        const unRegDeviceIds = [];
        tableOptions.selectedRows.forEach(device => {
            if (device.notProcessed) {
                unRegDeviceIds.push(device.id);
            } else {
                deviceIds.push(device.id);
            }
        });
        const deleteDevicesObj = {
            deviceIds: deviceIds,
            manuIds: unRegDeviceIds
        }
        this.setState({ disableButton: true }, async () => {
            const data = await deleteDevices(deleteDevicesObj);
            if (data.status !== "FAIL") {
                this.setState({ disableButton: false });
                this.props.closeModal("isDeleteDeviceModalOpen");
                tableOptions.selectedRows = [];
                if (Object.keys(this.props.selectedNode).length > 0) {
                    await this.context.loadNextlevel({ "node": this.props.selectedNode }, this.props.selectedNode.routeParams ? this.props.selectedNode.routeParams : null, null, this.props.selectedNode.name);
                    await this.context.getDeviceList()
                }
            } else {
                this.setState({ errorMessage: data.message, disableButton: false });
            }
        });
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
        return btnName === "ok" ? this.deleteDevice() :
            btnName === "cancel" ? this.props.closeModal("isDeleteDeviceModalOpen") : null
    }

    render() {
        const footerClassname = this.state.disableButton ? "modalSaveBtn disabled" : "modalSaveBtn"
        const modalTitle = <div className="popup-title"><FormattedMessage id={`delete.popup.title`} /></div>
        const modalBody = <div className="min-vh-25">
            <Form>
                {this.state.errorMessage ?
                    <div className={this.state.errorMessage ? "alert alert-danger" : "alert alert-danger dspNone"} role="alert">
                        {this.state.errorMessage}
                    </div> : <FormattedMessage id={`groupmanagement.confirm.message.deletedevice`} />}
            </Form>
        </div>
        const modalFooter = !this.state.errorMessage ? [{ className: "modalCancelBtn", name: <FormattedMessage id={`deleteDevice.popup.button.cancel`} />, value: "cancel" }, { className: footerClassname, name: <FormattedMessage id={`deleteDevice.popup.button.ok`} />, value: "ok" }] : []
        return (
            <Fragment>
                {this.props.isDeleteDeviceModalOpen ?
                    <ModalWindow
                        show={this.props.isDeleteDeviceModalOpen}
                        onHide={() => this.props.closeModal("isDeleteDeviceModalOpen")}
                        size={"md"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        )
    }
}

export default DeleteDeviceComponent;
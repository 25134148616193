import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';

export default class CustomPagination extends Component {
    constructor(props) {
        super()
        this.state = {
            page: props.page,
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ page: nextProps.page })
    }
    changePage = (page) => {
        this.setState({ page }, () => this.props.onPageChange(page))
    }

    render() {
        const { page, pageSizeOptions, pageSize, previousText, nextText, firstText, lastText, pageText, onPageSizeChange, totalSize, ofText, rowsText, isDisplayPageRows } = this.props
        return (
            <Row className={totalSize > 0 ? 'custom-pagination ' : 'reset-custom-pagination'}>
                {
                    totalSize > 0 ?
                        <Col xs={7} className="text-right">
                            {isDisplayPageRows === undefined || isDisplayPageRows === true ?
                            <div> {pageText} &nbsp;
                            <select onChange={(e) => onPageSizeChange(Number(e.target.value))} value={pageSize}>
                                    {
                                        pageSizeOptions.map((opt, i) => {
                                            return <option key={i} value={opt}>{opt}</option>
                                        })
                                    }
                                </select> &nbsp;
                        <span> {page === 0 ? 1 : (page * pageSize) + 1}
                                - {((page + 1) * pageSize) > totalSize ? totalSize : ((page + 1) * pageSize)}&nbsp;
                            {ofText} {totalSize} {rowsText}
                        </span>
                            </div> : "" }
                        </Col> : ""
                }
                {
                    totalSize > pageSize ?
                        <Col xs={ isDisplayPageRows === undefined || isDisplayPageRows === true ? 5 : 12} className="text-right">
                            <span className={page === 0 ? "pagination-disable-btn" : "pagination-btn"} onClick={() => this.changePage(page - 1)}>{previousText}</span>
                            <span className={page === 0 ? "pagination-disable-btn" : "pagination-btn"} onClick={() => this.changePage(0)}>&laquo; {firstText}</span>
                            <span className={page === Math.ceil(parseFloat(totalSize) / parseFloat(pageSize) - 1) ? "pagination-disable-btn" : "pagination-btn"} onClick={() => this.changePage(Math.ceil(parseFloat(totalSize) / parseFloat(pageSize)) - 1)}>{lastText} &raquo;</span>
                            <span className={page === Math.ceil(parseFloat(totalSize) / parseFloat(pageSize) - 1) ? "pagination-disable-btn" : "pagination-btn"} onClick={() => this.changePage(page + 1)}>{nextText}</span>
                        </Col> : ""
                }
            </Row>
        )
    }
}
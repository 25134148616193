import * as actionDispatch from './treeViewActionDispatch';

export const initTree = (data) => {
    return dispatch => {
        dispatch(actionDispatch.setTree([data.data[0]])); // sending only orgnode
    };
};

export const updateSelectedTree = (data) => {
    return dispatch => {
        dispatch(actionDispatch.updateSelectedTree(data));
    };
};

export const dropTree = (tree) => {
    return dispatch => {
        dispatch(actionDispatch.onDropTree(tree));
    };
};
/*
export const onEditNode = (newNode, clickedNode, routeParams) => {
    let paramsObj = groupRouteParams({}, routeParams);
    paramsObj[clickedNode.node.type] = {
        id: null, name: clickedNode.node.title, title: clickedNode.node.title, type: clickedNode.node.type
    }
    paramsObj['apiType'] = clickedNode.node.type;
    return dispatch => {
        services.editNode(paramsObj, { name: newNode, type: clickedNode.node.type })
            .then(response => {
                dispatch(actionDispatch.onEditNode(newNode, clickedNode, routeParams));
            })
            .catch(error => {
                dispatch(actionDispatch.fetchTreeFailed());
            });
    };
};
*/
export const updateTreeRefArr = (newData,key) => {
    return dispatch => {
        dispatch(actionDispatch.updateTreeRefArr(newData,key));
    };
};
export const updateTreeDataKeyArr = (newData) => {
    return dispatch => {
        dispatch(actionDispatch.updateTreeDataKeyArr(newData));
    };
};
export const updateExpandCollapse = (val) => {
    return dispatch => {
        dispatch(actionDispatch.expandCollapseVal(val));
    };
};

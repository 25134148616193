import React, { Component } from 'react';
import { ProfileContext } from '../provider/profilesProvider';
import BlankPage from '../../../../hoc/blankPage';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../../hoc/loader';

class ProfilesList extends Component {
    static contextType = ProfileContext;
    render() {
        const profileList = this.context.state.profilesList.options.map((e,index) => <li key={index} className={this.context.state.profilesList.selected.name === e.name ? "lsNoneli active-profile" : "lsNoneli"} onClick = {()=> !this.context.state.rightContentLoader && this.context.onProfileClick(e)}>{e.name}</li>);
        return (
            <div>
                {
                    this.context.state.leftContentLoader ?
                        <Loader elementClass="tree-ajax-loader center-element" /> :
                        profileList.length !== 0 ?
                            <div>
                                <ol className="lsNone">
                                    {profileList}
                                </ol>
                            </div> :
                            <div>
                                <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                            </div>
                }
            </div>
        );
    }
}

export default ProfilesList;
import React from 'react';
import { FormattedMessage } from 'react-intl';

const style = {
    fontFamily: "'univers', Arial, sans-serif",
    fontSize: "1em",
    color: "#333333"
};

function intlFormater(value) {
    return <FormattedMessage id={`${value}`} />
}

export const monitorConfig = {
    showLogIByType: ['FEEDER', 'SITE', 'LATERAL', 'LATERAL_SITE'],
    filtersSection : [
        { label: <FormattedMessage id='monitor.label.sites' />, fName: 'sites', list:'sitesList', type: 'multiSelect' },
        { label: <FormattedMessage id='monitor.label.logIPoints' />, fName: 'logIPoints', list:'logIPointsList', type: 'multiSelect' },
        { label: <FormattedMessage id='monitor.label.phase' />, fName: 'phase', list:'phaseList', type: 'btnGroupCheckBox' },
        { label: <FormattedMessage id='monitor.label.secLogIPoints' />, fName: 'secondaryLogIPoints', list:'secondaryLogIPointsList', type: 'multiSelect' },
        { label: <FormattedMessage id='monitor.label.secondaryPhase' />, fName: 'secondaryPhase', list:'secondaryPhaseList', type: 'btnGroupCheckBox' }
    ],
    zoomRange: [
        { label: intlFormater('common.label.zoom.1'), value: 1 },
        { label: intlFormater('common.label.zoom.7'), value: 7, }, 
        { label: intlFormater('common.label.zoom.30'), value: 30 },
        { label: intlFormater('common.label.zoom.90'), value: 90 },
        { label: intlFormater('common.label.zoom.180'), value: 180 },
        { label: intlFormater('common.label.zoom.365'), value: 365 }
    ],
    selectedZoomRange: 7,
    endDateTimestamp: null,
    startDateTimestamp: null,
    startDate: null,
    endDate: null,
    maxDate:null,
    sitesList: [],
    sites: [],
    logIPointsList: [
        { name: 'logIPoints', text: 'RMS Current', value: 'i', keyIndex: 0 },
        { name: 'logIPoints', text: 'Temperature', value: 't', keyIndex: 1 }
    ],
    logIPoints: [{name: 'logIPoints', text: 'RMS Current', value: 'i'}],
    phaseList: [
        {name: 'A', value: 'A', keyIndex: 0},
        {name: 'B', value: 'B', keyIndex: 1},
        {name: 'C', value: 'C', keyIndex: 2},
    ],
    phase: [ 'A', 'B', 'C'],
    secondaryPhaseList: [
        {name: 'X1', value: 'X1', keyIndex: 0},
        {name: 'X3', value: 'X3', keyIndex: 1},
    ],
    secondaryPhase : ["X1","X3"],
    secondaryLogIPointsList: [
        { name: 'secondaryLogIPoints', text: 'Current', value: 'i', keyIndex: 0 },
        { name: 'secondaryLogIPoints', text: 'Voltage', value: 'v', keyIndex: 1 },
        { name: 'secondaryLogIPoints', text: 'Transformer Loading', value: 'l', keyIndex: 2},
        { name: 'secondaryLogIPoints', text: 'kVAR', value: 'kVAR', keyIndex: 3},
        { name: 'secondaryLogIPoints', text: 'kW', value: 'kW', keyIndex: 4}
    ],
    secondaryLogIPoints: [{name: 'secondaryLogIPoints', text: 'Current', value: 'i'},{name: 'secondaryLogIPoints', text: 'Voltage', value: 'v'},{name: 'secondaryLogIPoints', text: 'Transformer Loading', value: 'l'},{ name: 'secondaryLogIPoints', text: 'kVAR', value: 'kVAR'},{ name: 'secondaryLogIPoints', text: 'kW', value: 'kW'}],
    vc10SecondaryLogIPointsList: [
        { name: 'secondaryLogIPoints', text: 'Current', value: 'i', keyIndex: 0 },
        { name: 'secondaryLogIPoints', text: 'Voltage', value: 'v', keyIndex: 1 },
        { name: 'secondaryLogIPoints', text: 'kVAR Injected', value: 'kVARInjected', keyIndex: 2},
        { name: 'secondaryLogIPoints', text: 'Transformer Loading', value: 'l', keyIndex: 3},
        { name: 'secondaryLogIPoints', text: 'kVAR', value: 'kVAR', keyIndex: 4},
        { name: 'secondaryLogIPoints', text: 'kW', value: 'kW', keyIndex: 5},
        { name: 'secondaryLogIPoints', text: 'Power Factor', value: 'powerFactor', keyIndex: 6},
        { name: 'secondaryLogIPoints', text: 'Phase Angle', value: 'phaseAngle', keyIndex: 7}
    ],
    vc10SecondaryLogIPoints : [{name: 'secondaryLogIPoints', text: 'Current', value: 'i'},{name: 'secondaryLogIPoints', text: 'Voltage', value: 'v'},{ name: 'secondaryLogIPoints', text: 'kVAR Injected', value: 'kVARInjected'},{name: 'secondaryLogIPoints', text: 'Transformer Loading', value: 'l'},{ name: 'secondaryLogIPoints', text: 'kVAR', value: 'kVAR'},{ name: 'secondaryLogIPoints', text: 'kW', value: 'kW'},{ name: 'secondaryLogIPoints', text: 'Power Factor', value: 'powerFactor'},{ name: 'secondaryLogIPoints', text: 'Phase Angle', value: 'phaseAngle'}],
    exportOptions: [{ label: <FormattedMessage id='common.text.download.excel' />, value: 'excel' }, { label: <FormattedMessage id='common.text.download.csv' />, value: 'csv' }],
    scaleChartOptions: {
        highcharts: {
            credits: {
                enabled: false
            },
            chart: {
                alignTicks: false, //required for multiple series
                zoomType: 'x',
                height : (6) + '%',
                reflow: true
            },
            exporting: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            navigator: {
                outlineColor: "#ccc",
                series: {
                    color: '#FAA61A',
                    lineWidth: 0
                },
                xAxis: {
                    labels: {
                        style: style
                    }
                }
            },
            plotOptions: {
                line: {
                    connectNulls: false
                },
                flags: {
                    shape: "squarepin",
                    useHTML: true,
                    fillColor: "#fff",
                    states: {
                        hover: {
                            fillColor: '#666'
                        }
                    },
                    y: -40
                },
                dataGrouping: {
                    enabled: false
                }
            },
            rangeSelector: {
                enabled: false,
                buttons: []
            },
            scrollbar: {
                barBackgroundColor: '#e7e7e7',
                barBorderColor: '#aaa',
                buttonBackgroundColor: '#e7e7e7',
                buttonBorderColor: '#aaa',
                trackBackgroundColor: '#f7f7f7',
                trackBorderColor: '#aaa'
            },
            tooltip: {
                enabled: false,
                style: style
            },
            xAxis: {
                title: {
                    text: '',
                    style: style
                },
                labels: {
                    enabled: false,
                    style: style
                },
                lineWidth: 0,
                lineColor: 'transparent',
                gridLineWidth: 0,
                gridLineColor: 'transparent',
                type: 'datetime',
                ordinal: false,
                events: {
                }
            },
            yAxis: {
                title: {
                    text: '',
                    style: style
                },
                labels: {
                    enabled: false,
                    style: style
                }
            }
        }
    },
    monitorChartOptions: {
        colors: ['#FF7474', '#5CD65C', '#7495BE'], // controls which colors are used.
        dashStyles: ['Solid', 'ShortDash', 'ShortDot'],
        dataColumns: ["t", "aload", "bload", "cload", "atemperature", "btemperature", "ctemperature"],
        highcharts : {
            credits: {
                enabled: false
            },
            chart: {
                alignTicks: false, //required for multiple series
                zoomType: 'x',
                spacingTop: 0,
                spacingBottom: 4,
                spacingLeft: 0,
                reflow: true,
                resetZoomButton: {
                    theme: {
                        states: {
                            hover: {
                                fill: '#D98C14',
                                stroke: '#BF7400',
                            }
                        }
                    }
                }
        
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            navigator: {
                enabled: false
            },
            dataGrouping: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            xAxis: {
                title: {
                    text: '',
                    style: style
                },
                labels:{
                    style: style
                },
                gridLineWidth: 1,
                gridLineDashStyle: 'dot',
                type: 'datetime',
                lineColor: "#ddd",
                ordinal: false,
                events: {
                }
            },
            yAxis: [
                { // create and add the axis
                    allowDecimals:true,
                    labels: {
                        style: style,
                        x: -5,
                        padding: 2,
                       align: 'right',
                       formatter: function () {
                        return parseFloat(this.value.toFixed(3))
                        }
                    },
                    title: {
                        text: "Current",
                        style: style,
                        useHTML: true
                    },
                    lineWidth: 1,
                    opposite: false,
                    gridLineWidth: 1,
                    gridLineDashStyle : 'dot',
                },
                { // create and add the axis
                    allowDecimals:true,
                    labels: {
                        style: style,
                        x: 5,
                        padding: 2,
                       align: 'left',
                       formatter: function () {
                        return parseFloat(this.value.toFixed(3))
                        }
                    },
                    title: {
                        text: 'Temperature',
                        style: style,
                        useHTML: true
                    },
                    lineWidth: 1,
                    opposite: true,
                    gridLineWidth: 0,
                    gridLineDashStyle : 'dot'
                }
            ],
            plotOptions: {
                line: {
                    connectNulls: false,
                    animation :{
                        duration : 1200
                    },
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 0
                    }
                },
                series:{
                    gapUnit: 'value',
                    lineWidth: 2,
                    dataGrouping: {
                        enabled: false
                    },
                    animation :{
                        duration : 1200
                    },
                    marker:{
                        enabled: true,
                        radius:0,
                        states: {
                            hover: {
                                radius: 2
                            }
                        }
                    }
                },
                flags: {
                    shape: "squarepin",
                    useHTML: true,
                    fillColor: "#fff",
                    states: {
                        hover: {
                            fillColor : '#666'
                        }
                    },
                    y: -40
                },
                dataGrouping: {
                    enabled: false
                }
            }    
        } // high chart end
    },
    monitorUM1ChartOptions: {
        colors: ['#FF7474', '#5CD65C', '#7495BE'], // controls which colors are used.
        dashStyles: ['Solid', 'ShortDash', 'ShortDot'],
        dataColumns: [],
        highcharts : {
            credits: {
                enabled: false
            },
            chart: {
                alignTicks: false, //required for multiple series
                zoomType: 'x',
                spacingTop: 0,
                spacingBottom: 4,
                spacingLeft: 0,
                reflow: true,
                resetZoomButton: {
                    theme: {
                        states: {
                            hover: {
                                fill: '#D98C14',
                                stroke: '#BF7400',
                            }
                        }
                    }
                }
        
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            navigator: {
                enabled: false
            },
            dataGrouping: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            xAxis: {
                title: {
                    text: '',
                    style: style
                },
                labels:{
                    style: style
                },
                gridLineWidth: 1,
                gridLineDashStyle: 'dot',
                type: 'datetime',
                lineColor: "#ddd",
                ordinal: false,
                events: {
                }
            },
            yAxis: [
                { // create and add the axis
                    allowDecimals:true,
                    labels: {
                        style: style,
                        x: -5,
                        padding: 2,
                       align: 'right',
                       formatter: function () {
                        return parseFloat(this.value.toFixed(3))
                        }
                    },
                    title: {
                        text: "Current",
                        style: style,
                        useHTML: true
                    },
                    lineWidth: 1,
                    opposite: false,
                    gridLineWidth: 1,
                    gridLineDashStyle : 'dot',
                    showLastLabel: true
                },
                { // create and add the axis
                    allowDecimals:true,
                    labels: {
                        style: style,
                        x: 5,
                        padding: 2,
                       align: 'left',
                       formatter: function () {
                        return parseFloat(this.value.toFixed(3))
                        }
                    },
                    title: {
                        text: 'Voltage (V)',
                        style: style,
                        useHTML: true
                    },
                    lineWidth: 1,
                    opposite: true,
                    gridLineWidth: 0,
                    gridLineDashStyle : 'dot',
                    showLastLabel: true
                }
            ],
            plotOptions: {
                line: {
                    connectNulls: false,
                    animation :{
                        duration : 1200
                    },
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 0
                    }
                },
                series:{
                    gapUnit: 'value',
                    lineWidth: 2,
                    dataGrouping: {
                        enabled: false
                    },
                    animation :{
                        duration : 1200
                    },
                    marker:{
                        enabled: true,
                        radius:0,
                        states: {
                            hover: {
                                radius: 2
                            }
                        }
                    }
                },
                flags: {
                    shape: "squarepin",
                    useHTML: true,
                    fillColor: "#fff",
                    states: {
                        hover: {
                            fillColor : '#666'
                        }
                    },
                    y: -40
                },
                dataGrouping: {
                    enabled: false
                }
            }    
        } // high chart end
    },
    propertyName:['type', 'load_min', 'load_max', 'load_sum', 'load_count', 'temperature_min', 'temperature_max', 'temperature_sum', 'temperature_count'],
    um1PropertyName : ['type', 'phaseACurrent','phaseACurrent_min', 'phaseACurrent_max', 'phaseACurrent_sum', 'phaseACurrent_count',
    'x1Current','x1Current_min', 'x1Current_max', 'x1Current_sum', 'x1Current_count','x3Current','x3Current_min', 'x3Current_max', 'x3Current_sum', 'x3Current_count',
    'v120X1','v120X1_min', 'v120X1_max', 'v120X1_sum', 'v120X1_count','v120X3','v120X3_min', 'v120X3_max', 'v120X3_sum', 'v120X3_count','primaryLoad',
    'primaryLoad_min', 'primaryLoad_max', 'primaryLoad_sum', 'primaryLoad_count','kvaThreshold','kvaThreshold_min', 'kvaThreshold_max', 'kvaThreshold_sum', 'kvaThreshold_count','kW_min',"kW_max","kW_sum","kW_count",'kVAR_min',"kVAR_max","kVAR_sum","kVAR_count"],
    vc10PropertyName:['type','voltage_min','voltage_max','voltage_sum','voltage_count','voltageSetPoint_min','voltageSetPoint_max','voltageSetPoint_sum','voltageSetPoint_count','x1Current_min', 'kVARInjected_min','kVARInjected_max','kVARInjected_sum','kVARInjected_count','x1Current_min','x1Current_max', 'x1Current_sum', 'x1Current_count','x3Current_min', 'x3Current_max', 'x3Current_sum', 'x3Current_count',
    'kW_min',"kW_max","kW_sum","kW_count",'kVAR_min',"kVAR_max","kVAR_sum","kVAR_count",'kvaThreshold_min', 'kvaThreshold_max', 'kvaThreshold_sum', 'kvaThreshold_count','primaryLoad_min', 'primaryLoad_max', 'primaryLoad_sum', 'primaryLoad_count',
    'x1PowerFactor_min', 'x1PowerFactor_max', 'x1PowerFactor_sum', 'x1PowerFactor_count','x3PowerFactor_min', 'x3PowerFactor_max', 'x3PowerFactor_sum', 'x3PowerFactor_count','x1PhaseAngle_min', 'x1PhaseAngle_max', 'x1PhaseAngle_sum', 'x1PhaseAngle_count','x3PhaseAngle_min', 'x3PhaseAngle_max', 'x3PhaseAngle_sum', 'x3PhaseAngle_count']
}
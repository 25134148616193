import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { NotificationTemplateContext } from '../provider/notificationTemplateProvider';
import ModalWindow from '../../../hoc/modelWindow';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import TextField from '../../../hoc/form/textField';
import AlertMessage from '../../../hoc/alert';

class NotifiTempValidationModal extends Component {
    static contextType = NotificationTemplateContext;

    onBtnClickHandleBtn = (value) => {
        this.context.validationModalActionBtn(value)
    }

    updateValidationModalTitle = () => {
        let title = '';
        let iconName = <FontAwesomeIcon icon={faCheck} color='green' size={'lg'} />;
        if (this.context.state.currentBtnName === 'delete') title = this.context.state.isDisplayModalCloseBtn ? <span> {iconName} <FormattedMessage id={"db.common.delete"} /></span> : <FormattedMessage id={"settings.notificationTemplates.text.deletetemplate.confirmationtitle"} />;
        else if (this.context.state.currentBtnName === 'saveAs') title = this.context.state.isDisplayModalCloseBtn ? <span> {iconName} <FormattedMessage id={"manage.profiles.saveas"} /></span> : <FormattedMessage id={"manage.profiles.saveas"} />;
        else if (this.context.state.currentBtnName === 'save' && this.context.state.isDisplayModalCloseBtn) title = <span> {iconName} <FormattedMessage id={"dm.column.save"} /></span>;
        else if (this.context.state.currentBtnName === 'create' && this.context.state.isDisplayModalCloseBtn) title = <span> {iconName} <FormattedMessage id={"db.common.create"} /></span>;
        else if (this.context.state.modalName === 'templateChangeWarning' || this.context.state.modalName === 'categoryChangeWarning') title = <FormattedMessage id="settings.notificationTemplates.text.changetemplate.confirmationtitle" />;
        else if (this.context.state.modalName === 'createTemplateWarning') title = <FormattedMessage id={'settings.notificationTemplates.text.createtemplate.confirmationtitle'} />;
        return title
    }

    render() {
        return (
            <ModalWindow
                show={this.context.state.isOpenValidationModal}
                onHide={this.context.validationModalActionBtn}
                size={'md'}
                title={this.updateValidationModalTitle()}
                dialogClassName={'notification-validate-modal'}
                onBtnClickHandler={this.onBtnClickHandleBtn}
                modeldata={{
                    content:
                        <React.Fragment>
                            {this.context.state.validateModalResMsg !== "" ? this.context.state.validateModalResMsg : ""}

                            {/* display error message if saveAs Name is empty*/}
                            {this.context.state.currentBtnName === 'saveAs' && !this.context.state.isDisplayModalCloseBtn ?
                                <React.Fragment>
                                    {this.context.state.validationMsg.msg !== "" && this.context.state.validationMsg.for === 'validationModal' ?
                                        <Row style={{ marginBottom: '1.2vh' }}>
                                            <Col>
                                                <AlertMessage message={this.context.state.validationMsg.msg} type={this.context.state.validationMsg.type} setVisible={this.context.closeAlertMessage} />
                                            </Col>
                                        </Row>
                                        : ''}

                                    {/* display Save as template field*/}
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col xs={4} className="text-right"><b><FormattedMessage id={"settings.notificationTemplates.text.newTemplateName"} /></b></Col>
                                        <Col>
                                            <TextField
                                                type={'text'}
                                                value={this.context.state.saveAsName}
                                                // isDisabled={this.context.state.formData.systemDefined}
                                                field={'saveAsName'}
                                                handleOnchange={this.context.updateSaveAsFormData}
                                                placeholder="Enter the new template name"
                                                size="sm"
                                            // className={item.isErr ? 'form-err-border-red' : ""}
                                            /></Col>
                                    </Row>
                                </React.Fragment>
                                : ''}
                        </React.Fragment>
                }}
                footer={
                    this.context.state.isDisplayModalCloseBtn ?
                        [
                            { className: "modalCancelBtn", name: <FormattedMessage id="db.common.close" />, value: "close" }
                        ]
                        :
                        [
                            { className: "modalCancelBtn", name: <FormattedMessage id="dm.column.cancel" />, value: "cancel" },
                            { className: "modalSaveBtn", name: <FormattedMessage id="db.common.ok" />, value: "ok" }
                        ]
                }
            />
        );
    }
}

export default NotifiTempValidationModal;
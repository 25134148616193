import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import TableTopSection from '../components/TableTopSection';
import { systemAdminData } from '../../../utility/systemAdminUtility';
import { SystemAdminContext } from '../provider/systemAdminProvider';
import UploadSystemAdminFile from '../components/uploadSystemAdminFile';
import { FormattedMessage } from 'react-intl';
import BlankPage from '../../../hoc/blankPage';
import Loader from '../../../hoc/loader';
import Alert from '../../../hoc/alert';
import Table from '../../../hoc/table';
import TableMultiSortNumber from '../../../hoc/tableMultiSortNumber';

class MenuFecturingFile extends Component {
    static contextType = SystemAdminContext;
    constructor(props) {
        super(props);
        this.state = {
            columnsData: [
                ...systemAdminData.manufacturingData,
                { accessor: 'status', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.status' />} />, Cell: ({ original }) => <span className="download-btn" onClick={() => this.context.handleDownloadFile(original, 'menuFacFilestatus')}>{original.status}</span> },
                { accessor: 'jwtAvailable', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.jwtFile' />} />, Cell: ({ original }) => <span className="download-btn" onClick={() => this.context.handleDownloadFile(original, 'jwtFile')}>{original.jwtAvailable === false ? "" : <FormattedMessage id='db.sysAdmin.download' />}</span> }
            ]
        }
    }

    // handleMultipleSort = (rowData) => {
    //     let updateColumnData = this.context.updateSortNumber(rowData, this.state.columnsData)
    //     this.setState({ columnsData: updateColumnData })
    // }

    render() {
        return (
            <Row className="menufacturingFile">
                <Col>
                    <Row>
                        <Col className='margin-left-1'>
                            <TableTopSection
                                title={<FormattedMessage id='db.sysAdmin.manufacturingFile' />}
                                displayBtn={
                                    <UploadSystemAdminFile
                                        tooltipTitle={<FormattedMessage id='db.sysAdmin.uploadManufacturingFile' />}
                                        uploadFrom="manufacturingFile"
                                        fileAccept=".jwt"
                                    />}
                            />
                            {this.context.state.manufactureFileRes.msg !== "" ?
                                <Row className="margin-top-2vh">
                                    <Col>
                                        <Alert type={this.context.state.manufactureFileRes.type}
                                            message={this.context.state.manufactureFileRes.msg}
                                            setVisible={() => this.context.handleRemoveAlert('menufactringFile')}/>
                                    </Col>
                                </Row>
                                : ""}
                        </Col>
                    </Row>
                    {this.context.state.lodingMenufacturingData ? <Loader elementClass="tree-ajax-loader center-element" /> : <Row noGutters={false}>
                        {this.context.state.menufacturingData.length !== 0 ?
                            <Col className={`${this.context.state.OSName === 'MacOS' ? 'react-table-manufac-full padding-0' : 'react-table-manufac padding-0'}`} >
                                <Table 
                                     tableColumn={this.state.columnsData}
                                     tableRowData={this.context.state.menufacturingData}
                                     isDisplayPageRows={false}
                                />
                            </Col>
                            :
                            <div><BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /></div>
                        }
                    </Row>}
                </Col>
            </Row>
        );
    }
}

export default MenuFecturingFile;

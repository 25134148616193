import React, { Component, Fragment } from 'react';
import { formFields } from '../../../../utility/addDeviceUtility';
import { FormControl, FormGroup, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ToggleSwitch from '../../../../hoc/toggleSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import SingleSelect from '../../../../hoc/singleSelect';

let commonData = [];
let positionFields = [];

class UM3DeviceComponent extends Component {
    state = {
        positionValue: 1,
        positionList: [],
        togglePositions: [true]
    }

    onSwitchChangeHandler = (e, position) => {
        const tempState = [...this.state.positionList];
        if (!tempState[position]["switchDisabled"] || tempState[position]["switchDisabled"] === undefined) {
            tempState[position]["switchDisabled"] = true;
            this.props.disablePositions(position, false);
        }
        else {
            tempState[position]["switchDisabled"] = false;
            this.props.disablePositions(position, true);
        }
        this.setState({ positionList: tempState });
    }

    addNewPosition = () => {
        let dataToAppend = positionFields.map((dt) => {
            const copiedData = Object.assign({}, dt);
            if (copiedData.key === "site") {
                copiedData.options = [];
            }
            return copiedData;
        });
        dataToAppend["switchDisabled"] = true
        const position = [...this.state.togglePositions];
        position.push(true);
        this.props.addPositionDetails(this.state.positionValue + 1);
        this.setState({
            positionList: this.state.positionList.concat([dataToAppend]),
            positionValue: this.state.positionValue + 1,
            togglePositions: position
        });
    }

    /* collapse or expand um3 positions */
    toggleUm3Positions = (index) => {
        const position = [...this.state.togglePositions];
        position[index] = !position[index];
        this.setState({ togglePositions: position });
    }

    componentDidMount() {
        const tempArr = [];
        tempArr.push(positionFields);
        tempArr[0]["switchDisabled"] = true;
        this.setState({ positionList: tempArr });
    }

    setHardwareVersionValue=(value)=>{
        this.setState({
            positionList: this.state.positionList.length > 1 ? this.state.positionList.slice(0,1) : this.state.positionList, // for new UM3+ compact required only one position
            positionValue: this.state.positionValue > 1 ? 1 : this.state.positionValue
        }, ()=> this.props.setHardwareVersion(value))   //passing hardwareRevesion value to parent and storing there.
    }

    render() {
        if(this.props.hardwareRevision === "CU") {  //checking if user selected new UM3+("CU"), then no need to display 'sensor ip address' and 'Firmware Version'
            commonData = formFields.filter(field => field.displayType !== "UM3" && field.key !== 'sensorIPAddress' && field.key !== 'softwareVersion' && field.displayType !== false ? field : "");
        } else { //checking if user selected OLD UM3+ ("C")
            commonData = formFields.filter(field => field.displayType !== "UM3" && field.displayType !== false ? field : "");
        }
        
        positionFields = formFields.filter(field => field.displayType === "UM3" ? field : "");

        const tempPosition = this.state.positionList.map((position, i) => {
            return (
                <div key={i}>
                    <div className="mb-5 multi-position">
                        <span onClick={() => this.toggleUm3Positions(i)} className={this.state.togglePositions[i] ? "fas fa-chevron-down multi-position-chevron" : "fas fa-chevron-right multi-position-chevron"} ></span>
                        <span onClick={() => this.toggleUm3Positions(i)} className="multi-position-heading">Position - {i + 1}</span>
                        {this.state.positionValue > 1 ? <div className="switch float-right multi-position-switch"><ToggleSwitch toggleSwitchValue={(e) => this.onSwitchChangeHandler(e, i)} value={position.switchDisabled} leftValue="On" rightValue="Off" /></div> : ""}
                    </div>
                    {this.state.togglePositions[i] ? <div className={!position.switchDisabled ? "ptr-none" : ""}>
                        {
                            position.map(field => {
                                let label, template;
                                if (this.props.state.deviceType) {
                                    if (field.displayType === "UM3") {
                                        label = <FormattedMessage id={`groupmanagement.label.${field.key}`} />;
                                        if (field.type === "select") {
                                            if (field.key === "site" || field.key === "lateral" || field.key === "feeder") {
                                               const temp = this.props.state.positions && this.props.state.positions[i] && this.props.state.positions[i][field.key+"Options"];
                                               template = <Fragment>
                                                    <SingleSelect disabled={field.isDisabled} data={temp ? temp : [{ label: "--select--", value: "--select--" }]} setType={(e) => this.props.ddlPositionValueUpdate(e, field.key, i)} value={this.props.state.positions && this.props.state.positions[i] && this.props.state.positions[i][field.key] ? this.props.state.positions[i][field.key] : "--select--"} icon='ok' />
                                                    <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                                </Fragment>
                                            } else {
                                                template = <Fragment>
                                                    <SingleSelect disabled={  field.isDisabled} data={field.options.length > 0 ? field.options : [{ label: "--select--", value: "--select--" }]} setType={(e) => this.props.ddlPositionValueUpdate(e, field.key, i)} value={this.props.state.positions && this.props.state.positions[i] && this.props.state.positions[i][field.key] ? this.props.state.positions[i][field.key] : "--select--"} icon='ok' />
                                                    <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                                </Fragment>
                                            }
                                        }
                                    }
                                }
                                let data = label && template ?
                                    <FormGroup key={field.key} className="clearfix">
                                        <Col sm={4} xs={4} className="float-left mb-2 m-0 p-0">
                                            {label}{field.isMandatory ? "*" : ""}
                                        </Col>
                                        <Col sm={8} xs={8} className="float-left mb-2">
                                            {template}
                                        </Col>
                                    </FormGroup> : ""
                                return (data)
                            })
                        }
                    </div> : ""}
                </div>
            )
        });

        return (
            <div>
                {/* start adding hardwareRevesion field for um3+ device */}
                <FormGroup>
                    <Col sm={4} xs={4} className="float-left mb-1 m-0 p-0"> <FormattedMessage id={"groupmanagement.addDevice.label.hardwareRevision"}/>* </Col>
                    <Col sm={8} xs={8} className="float-left mb-1">
                        <SingleSelect data={this.props.hardwareRevisionList} value={this.props.hardwareRevision ? this.props.hardwareRevision : '--select--'} setType={(evt) => this.setHardwareVersionValue(evt) } icon='ok' />
                        <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                    </Col>
                    <br/>
                    <br/>
                </FormGroup>
                 {/* end adding hardwareRevesion field for um3+ device */}

                {this.props.hardwareRevision && this.props.hardwareRevision !== '--select--' &&  //Hardware version need to select to display the all UM3Related fields
                <>
                    <div>
                        {
                            commonData && commonData.map((field, i) => {
                                let label, template;
                                if (this.props.state.deviceType) {
                                    if (field.value === "common") {
                                        label = <FormattedMessage id={`groupmanagement.label.${field.key}`} />;
                                        if (field.type === "input") {
                                            template = <FormControl type="text" label="Text" className="inpFlSz" onChange={(e) => this.props.inputChangeHandler(e, field.key)} value={this.props.state[field.key] ? this.props.state[field.key] : ""}/>
                                        }
                                        else if (field.type === "select") {
                                            template = <Fragment>
                                                <SingleSelect disabled={field.isDisabled} data={field.options.length > 0 ? field.options : [{ label: "--select--", value: "--select--" }]} setType={(e) => this.props.ddlValueUpdate(e, field.key)} value={this.props.state[field.key] ? this.props.state[field.key] : "--select--"} icon='ok' />
                                                <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                            </Fragment>
                                        }
                                        else if (field.type === "textarea") {
                                            template = <textarea rows="3" className="inpFlSz form-control" onChange={(e) => this.props.inputChangeHandler(e, field.key)} value={this.props.state[field.key] ? this.props.state[field.key] : ""}/>
                                        }
                                        else if(field.type==="toggle"){ //for new UM3+ (hardwareRevision = "CU") device should be disabled'secure' field.
                                            template = (field.key === 'secured' && this.props.hardwareRevision !== "CU") && <ToggleSwitch value={this.props.switchSecuredState} toggleSwitchValue={() => { this.props.onToggleSecured() }} leftValue="On" rightValue="Off" />
                                        }
                                    }
                                }

                                let data = label && template ?
                                    <FormGroup key={field.key} className="clearfix">
                                        <Col sm={4} xs={4} className={this.props.hardwareRevision === "CU" && label.props.id ==='groupmanagement.label.secured' ? " disabled float-left mb-2 m-0 p-0" : "float-left mb-2 m-0 p-0"}>
                                            {label}{field.isMandatory ? "*" : ""}
                                        </Col>
                                        <Col sm={8} xs={8} className="float-left mb-2">
                                            {template}
                                        </Col>
                                    </FormGroup> : ""
                                return (data)
                            })
                        }
                    </div>
                    <div>{tempPosition}</div>
                    { this.props.hardwareRevision && this.props.hardwareRevision !== '--select--' && this.props.hardwareRevision === "C" &&  //Need to display Add Position buttion if user select old UM3+ (HardwareVersion = "C"), For new UM3+ (hardwareRevision === "CU") have only one position
                        <div className="text-center jst-inbtwn"><Button className="btn btn-primary" disabled={this.state.positionValue >= 4 ? true : false} onClick={this.addNewPosition}> <FormattedMessage id={"groupmanagement.addDevice.label.addPosition"}/></Button></div>
                    }
                </>
                }
            </div>
        )
    }
}

export default UM3DeviceComponent;
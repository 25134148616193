import { getURL } from '../../../providers/configProvider';
import { requestGET, requestPOST } from '../../../services/extendedService';


export const getAlertRegionData = (params, regionOrSubstation) => {
    const url = getURL('groupManagement', regionOrSubstation, params);
    return requestGET(url);
};

export const getAlertSubstations = (params, regionOrSubstation) => {
    const url = getURL('groupManagement', 'selectedsubstations', { 'REGION': params.REGION, 'apiType': 'data' });
    return requestGET(url);
};

export const loadNotification = (params) => {
    const url = getURL('groupManagement', 'loadnotificationdata', params);
    return requestGET(url);
};

export const saveUpdatedNotification = (updatedData) => {
    const url = getURL('groupManagement', 'updatenotificationdata', { 'apiType': 'data' });
    return requestPOST(url, updatedData)
};
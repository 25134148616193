import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import TabsComponent from '../../../hoc/tabs';
import { disturbancesData } from '../../../utility/disturbanceAnalyticsUtility'
import Table from '../../../hoc/table';
import BlankPage from '../../../hoc/blankPage';
import { FormattedMessage } from 'react-intl';
import TableMultiSortNumber from '../../../hoc/tableMultiSortNumber'
import { DisturbanceAnalyticsContext } from '../provider/disturbanceAnalyticsProvider';
import CountersChart from './countersChart';
import Loader from '../../../hoc/loader';

class CountersView extends PureComponent {

    static contextType = DisturbanceAnalyticsContext;

    updateColumnData = () => {
        let clmData = this.context.state.tableColumnData;
        let rowData = this.context.state.tableRowData;
        let newData = [];

        var findValueTypeAsObj = []; //Storing all the property if type is object.

        rowData.forEach(item => {
            findValueTypeAsObj = [];
            Object.entries(item).forEach(([key, value]) => {
                if (value !== null && typeof value === 'object') {
                    findValueTypeAsObj.push(key)
                }
            })
        })

        clmData.forEach(item => {
            if (findValueTypeAsObj.includes((item.name).toString())) {
                newData.push({
                    Header: (original) => <React.Fragment><TableMultiSortNumber column={original} label={item.name + ' Count'} /></React.Fragment>,
                    id: item.name,
                    accessor: data =>
                        <div className="text-left inline-block">
                            {
                                data[item.name] && Object.entries(data[item.name]).length && Object.entries(data[item.name]).map(([key, val]) => (
                                    <React.Fragment key={key}> {key.toUpperCase()} : {val === null ? '-' : val} <br /></React.Fragment>
                                ))
                            }
                        </div>
                })
            } else {
                newData.push({
                    accessor: item.name,
                    Header: (original) => <React.Fragment><TableMultiSortNumber column={original} label={item.name + ' Count'} /></React.Fragment> 
                })
            }
        })

        let firstColumn = [{ accessor: 'name', Header: (original) => <TableMultiSortNumber column={original} label={(this.context.state.treeNode && (this.context.state.treeNode.type !== 'SITE') && this.context.state.treeNode.children && this.context.state.treeNode.children.length > 0) ? (this.context.state.treeNode.children[0].type).charAt(0).toUpperCase() + (this.context.state.treeNode.children[0].type).slice(1).toLowerCase() + ' Name' : 'Phase Name'} />, sortable: false, className: 'column-center-align' }];
        return [...firstColumn, ...newData]
    }

    render() {
        return (
            <Row>
                <Col className='p-0'>
                    <TabsComponent
                        currentTab={this.context.state.currentTabVal}
                        handleTabSelect={this.context.handleTabSelect}
                        allTabs={disturbancesData.tabsData}
                        tabBody={
                            <Row className='counters-view-height m-0'>
                                <Col className='p-0'>
                                    {this.context.state.tableRowData.length !== 0 ?
                                        <React.Fragment>
                                            {
                                                this.context.state.currentTabVal === 'countersTable' ?
                                                    <Table
                                                        tableColumn={this.updateColumnData()} //preparing table column
                                                        tableRowData={this.context.state.tableRowData}
                                                        showPagination={false}
                                                        isTdComponent={true}
                                                    /> : <div className="disturbance-chart"><CountersChart options={this.context.state.chartOptions} treeNode={this.context.state.treeNode} countersData={this.context.state.countersData} /></div>
                                            }
                                        </React.Fragment>
                                        : this.context.state.isLoadingData ? <div className="loader-wrap"><Loader elementClass="tree-ajax-loader center-element" /></div> : <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
                                </Col>
                            </Row>
                        }
                    />
                </Col>
            </Row>
        );
    }
}

export default CountersView;
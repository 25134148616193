import * as actionTypes from '../mainContent/dashboard/actions/dashboardActionTypes';

const initialState = {
    redirectData: {},
    defaultDateRange: 1,
    requestParams: { "apiType": 'data', },
    bubbleDateRange: 1,
    bubblePayload: [],
    heatMapDateRange: 1,
    hitmapFilterType: 'Fault Momentary Interruption',
    hitmapPayload: [],
    watchlistDateRange: 1,

    eLogDateRange: 1,
    eLogStateFilterData: "Sustained Interruption",
    eLogTypeFilterData: "All",
    eventLogNodeType: "Feeders",
};

const dashboardReducer = function (currentState = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_SELECTED_TREE_FROM_DASHBOARD:
            return {
                ...currentState,
                redirectData: action.data
            }
        case actionTypes.UPDATE_BUBBLE_DATE_RANGE_DATA:
            return {
                ...currentState,
                bubbleDateRange: action.range
            }
        case actionTypes.UPDATE_BUBBLE_PAYLOAD_DATA:
            return {
                ...currentState,
                bubblePayload: action.payloadData
            }
        case actionTypes.UPDATE_HEATMAP_DATE_RANGE_DATA:
            return {
                ...currentState,
                heatMapDateRange: action.range
            }
        case actionTypes.UPDATE_HEATMAP_DEFAULT_TYPE:
            return {
                ...currentState,
                hitmapFilterType: action.filterType
            }
        case actionTypes.UPDATE_HEATMAP_PAYLOAD_DATA:
            return {
                ...currentState,
                hitmapPayload: action.payloadData
            }
        case actionTypes.UPDATE_WATCHLIST_DATE_RANGE_DATA:
            return {
                ...currentState,
                watchlistDateRange: action.range
            }

        case actionTypes.UPDATE_ELOG_DATE_RANGE_DATA:
            return {
                ...currentState,
                eLogDateRange: action.range
            }

        case actionTypes.UPDATE_ELOG_STATE_FILTER_DATA:
            return {
                ...currentState,
                eLogStateFilterData: action.data
            }
        case actionTypes.UPDATE_ELOG_TYPE_FILTER_DATA:
            return {
                ...currentState,
                eLogTypeFilterData: action.data
            }

        default:
            return currentState
    }
}

export default dashboardReducer;
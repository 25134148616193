import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ModalWindow = (props) => {
    return (
        <Modal show={props.show}
            onHide={props.onHide}
            backdrop="static"
            keyboard={false}
            backdropClassName = {props.backdropClassName}
            aria-labelledby="contained-modal-title-sm"
            size={props.size ? props.size : "md"}
            dialogClassName={props.dialogClassName + " modal-dialog-centered"}
        >
            <Modal.Header closeButton={props.closeButton===false?props.closeButton:true}> {props.title} </Modal.Header>
            <Modal.Body>
                {props.modeldata.content}
            </Modal.Body>
            {props.footer && props.footer.length > 0 ? <Modal.Footer>
                {props.footer.map((foo, i) => {
                    return foo.leftContent ? <span key={i} className={"modalLeftMsgDefault " + foo.className}>{foo.name}</span> :
                        <Button key={foo.value + '-' + i} className={"btn " + foo.className} disabled={foo.disabled} onClick={() => props.onBtnClickHandler ? props.onBtnClickHandler(foo.value,props) : ""}>{foo.name}</Button>
                })}
            </Modal.Footer> : null}
        </Modal>
    );
}

ModalWindow.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    size: PropTypes.string, // sm|md|lg|xl 
    backdropClassName: PropTypes.string,
    dialogClassName: PropTypes.string,
    title: PropTypes.object,
    content: PropTypes.element,
    footer: PropTypes.array, // refer below for how to pass data
    onBtnClickHandler: PropTypes.func, // It will return the value to the component based on the value the function should be called
    closeButton: PropTypes.bool // To show close button on the model
}
export default ModalWindow;

/* Footer data Structure
 [{name: FormattedMessage/String (MANDATORY),
className: "if Add/Save/Confirm use "modalSaveBtn", for Cancel use "modalCancelBtn" or you can use any className based on requirement" (MANDATORY),
value: "string --> on click of the button we will recieve this value" (MANDATORY),
disable: Bool (OPTIONAL)
leftContent: Bool (OPTINAL) --> For adding any left content for the footer like message}] */
import React, { Component} from 'react';
import DeviceActionsContainer from './deviceActionsContainer';
import { Col } from 'react-bootstrap';
import Loader from '../../../../hoc/loader';
import DeviceListTable from '../components/deviceListTableComponent';
import { DeviceContext } from '../provider/deviceProvider';
import { tableOptions } from '../../../../utility/deviceManagementUtility';

class ManageDetailComponent extends Component {

  static contextType = DeviceContext;
  
  render() {
    const LoaderData = (this.context.state.loading || this.context.state.dashboardFlag) ? <div className="loader-wrap"><Loader elementClass="device-management-ajax-loader" /></div> : null
    const nodeType = this.context.state.treeNode && this.context.state.treeNode.type ? this.context.state.treeNode.type : "";
    const DeviceListTableData = <DeviceListTable loading={this.context.state.loading} devicesCount={this.context.state.totalDevicesCount} devices={this.context.state.devices} className={nodeType === "SITE" || nodeType === "LATERAL_SITE" ? "mar-top-15" : null} contextState={this.context.state} options={this.context.state.options} updateDeviceMngmntUtility={(pageNo, sizePerPage) => this.context.updateDeviceMangementUtility(pageNo, sizePerPage)} />
    return (
      <React.Fragment>
        {LoaderData}
        <Col className="content-right pad-rgt-lef m-0 p-0" xs={12} md={12}>
          <DeviceActionsContainer selectedNode={this.context.state.treeNode} orgId={tableOptions.orgId} devices={this.context.state.devices} nodeType={nodeType} getEnabledColumn={(evt) => this.context.setColumnStatus(evt)} enableFilterBtn={this.context.state.enableFilterBtn} onExportBtnClick={(type) => this.context.onExportBtnClick(type)} displayColumns={this.context.state.displayColumns} />
          { 
            DeviceListTableData
          }
        </Col>
      </React.Fragment>
    );
  }
}


export default ManageDetailComponent;
import React, { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const PropertyTable = (props) => {
    let selectedPhases = (props.phase && props.phase.length > 0) ? props.phase : [ 'A', 'B', 'C'];
    let selectedView = (["UM1","VC10"].includes(props.treeNode.siteDeviceType) && ["SITE","LATERAL_SITE"].includes(props.treeNode.type)) ? "both" : (props.logIPoints && props.logIPoints.length === 1) ? (props.logIPoints[0].value === 'i') ? 'load' : 'temperature' : 'both';

    return (
        <Fragment>
            <div className="p-3 border-1px">
                <FormattedMessage id={`monitor.label.summary`} />&nbsp;:&nbsp;{props.startDate}&nbsp;<FormattedMessage id={`monitor.label.to`} />&nbsp;{props.endDate}
            </div>
            <Table className="property-table" bordered >
                <thead>
                    <tr>
                        <th className="text-left"><FormattedMessage id={`monitor.label.property`} /></th>
                        { selectedPhases
                            .sort((a, b) => a.localeCompare(b))
                            .map((pha, ph) => { return <th key={ph}><FormattedMessage id={`monitor.label.${pha}`} /></th> }) 
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        (props.item.phaseDetails && props.item.phaseDetails.length > 0) ?
                        props.item.phaseDetails.map((details, d) => {
                            return ((selectedView === details.type) || (selectedView === 'both') || (details.type === '')) ?
                            <tr key={d}>
                                <td className="text-left">
                                    <FormattedMessage id={`monitor.label.${details.name}`} />
                                    { (details.suffix !== '') ? <span dangerouslySetInnerHTML={{__html: details.suffix }} ></span> : null }
                                </td>
                                { selectedPhases
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((pha, p) => { return <td key={p}>{details[pha]}</td> }) 
                                }
                            </tr> : null
                        }) : null
                    }
                </tbody>
            </Table>
        </Fragment>
    );
}

export default PropertyTable;
import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import TableTopSection from '../components/TableTopSection';
import { systemAdminData } from '../../../utility/systemAdminUtility';
import { SystemAdminContext } from '../provider/systemAdminProvider';
import UploadSystemAdminFile from '../components/uploadSystemAdminFile';
import DownloadSampleFile from '../components/downloadSampleFile';
import { FormattedMessage } from 'react-intl';
import BlankPage from '../../../hoc/blankPage';
import Loader from '../../../hoc/loader';
import Alert from '../../../hoc/alert';
import Table from '../../../hoc/table';
import TableMultiSortNumber from '../../../hoc/tableMultiSortNumber';

class GridMapingFile extends Component {
    static contextType = SystemAdminContext;
    constructor(props) {
        super(props);
        this.state = {
            columnsData: [
                ...systemAdminData.gridMapingData,
                { accessor: 'status', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.status' />} />, Cell: ({ original }) => <span className="download-btn" onClick={() => this.context.handleDownloadFile(original, 'gridmapStatus')}>{original.status}</span> },
            ]
        }
    }

    // handleMultipleSort = (rowData) => {
    //     let updateColumnData = this.context.updateSortNumber(rowData, this.state.columnsData)
    //     this.setState({ columnsData: updateColumnData })
    // }

    render() {
        return (
            <Row className="gridMapping">
                <Col>
                    <Row>
                        <Col>
                            <TableTopSection
                                title={<FormattedMessage id='db.sysAdmin.gridMappingFile' />}
                                isDisplayDeviceMap={true}
                                displayBtn={
                                    <React.Fragment>
                                        <DownloadSampleFile from={'gridMapingSampleFile'} tooltipTitle={<FormattedMessage id='db.sysAdmin.sampleGridMappingFile'/>}/>
                                        <UploadSystemAdminFile
                                            tooltipTitle={<FormattedMessage id='db.sysAdmin.uploadGridMappingFile' />}
                                            uploadFrom="gridMappingFile"
                                            fileAccept=".csv"
                                        />
                                    </React.Fragment>
                                }
                            />
                            {this.context.state.gridMappingFileRes.msg !== "" ?
                                <Row className="margin-top-2vh">
                                    <Col>
                                        <Alert type={this.context.state.gridMappingFileRes.type}
                                            message={this.context.state.gridMappingFileRes.msg}
                                            setVisible={() => this.context.handleRemoveAlert('gridMappingFile')}/>
                                    </Col>
                                </Row>
                                : ""}
                        </Col>
                    </Row>
                    {this.context.state.loadingGridMappingData ? <Loader elementClass="tree-ajax-loader center-element" /> :  <Row>
                        {this.context.state.gridMapingFile.length !== 0 ?
                            <Col className={`${ this.context.state.OSName === 'MacOS' ? 'react-table-gridmapping-full padding-0' : 'react-table-gridmapping padding-0' }`}>
                                <Table 
                                     tableColumn={this.state.columnsData}
                                     tableRowData={this.context.state.gridMapingFile}
                                     isDisplayPageRows={false}
                                    />
                            </Col>
                            :
                            <div><BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /></div>
                        }
                    </Row>}
                   
                </Col>
            </Row>
        );
    }
}

export default GridMapingFile;
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './index.css';
import AuditTrailProvider from './provider/auditTrailProvider';
import AuditTrailContainer from './containers/auditTrailContainer';
import { FormattedMessage } from 'react-intl';

const AuditTrail = () => {
    return (
        <Container fluid className='audit-trail'>
            <Row>
                <Col>
                    <Row>
                        <Col className="p-0 m-0"><FormattedMessage id='db.common.setting' /> : <FormattedMessage id='nav.main.audittrail' /></Col>
                    </Row>
                    <AuditTrailProvider>
                        <AuditTrailContainer />
                    </AuditTrailProvider>
                </Col>
            </Row>
        </Container>
    );
}

export default AuditTrail;
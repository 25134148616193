import React, { Component } from "react";
import HighchartsReact from 'highcharts-react-official';
import HighCharts, { Series } from "highcharts/highstock";
import HC_more from 'highcharts/highcharts-more';
import Moment from 'moment';
import mTZ from 'moment-timezone';
import { injectIntl } from 'react-intl';
import { eventPrecursorData } from '../../../../utility/eventPrecursorUtility';
import { fetchBrowserTimeZone } from '../../../../services/utilityService';
HC_more(HighCharts)
window.moment = Moment;
mTZ();
class EventPrecursorChart extends Component {
    constructor(props) {
        super(props)
        HighCharts.setOptions({
            colors: ['#FF7474', '#5CD65C', '#7495BE'], // controls which colors are used.
            global: {
                useUTC: false,
                timezone: this.props.data.roleAndPermission.timezone
            }
        })
    }
    render() {
        const { chartOptions } = { ...eventPrecursorData }
        const { roleAndPermission } = this.props.data
        return (
            <React.Fragment>
                <React.Fragment>
                    <HighchartsReact
                        highcharts={HighCharts}
                        //constructorType={"stockChart"}
                        options={{
                            ...chartOptions.highcharts,
                            ...chartOptions.highcharts.title.text = `${this.props.data.epTableSelectedRow.region} - ${this.props.data.epTableSelectedRow.substation} - ${this.props.data.epTableSelectedRow.feeder} - ${this.props.data.epTableSelectedRow.feedersite}`,
                            ...chartOptions.highcharts.xAxis.max = this.props.data.chartEndTimestamp,
                            ...chartOptions.highcharts.xAxis.min = this.props.data.chartStartTimestamp,
                            series: [...this.props.data.eventPreCursorChartData],
                            ...{
                                tooltip: {
                                    crosshairs: true,
                                    alignTicks: false,
                                    formatter: function () {
                                        let s = moment(this.x).format("MM/DD/YYYY hh:mm") + '<br/>';
                                        if (this.series.name !== "Status") {
                                            s += `${this.series.name} : ${this.y}`
                                        } else {
                                            s += `${this.point.status}`
                                        }
                                        return s;
                                    }
                                }
                            }
                        }}
                    />
                    <div className="d-flex justify-content-between position-relative chartBtn"><span onClick={() => this.props.context.onPrevNextMonthSelection("prev")}>&lt; previous month</span><span onClick={() => this.props.context.onPrevNextMonthSelection("next")} className={this.props.data.chartFinalEndTimestamp === this.props.data.chartEndTimestamp ? "disabled" : ""}>next month &gt;</span></div>
                </React.Fragment>
            </React.Fragment>
        )
    }
}

export default injectIntl(EventPrecursorChart);
import * as actionTypes from '../header/actions/headerActionTypes';

const intialState = {
    currentRoute: '',
    disableTree: ['dashboard', 'userManagement', 'licenseManagement', 'equipmentProfile', 'systemAdmin', 'deviceGateway', 'auditTrail', 'customReportsManagement', 'manage/reports', 'manage/otapstatus', 'manage/configstatus', 'manage/profiles', 'manage/classAssignment', 'manage/virtualDeviceManagement', 'reports/custom', 'customReportManageSchedules'],
    enableSubMenus: ['manage', 'events', 'insights'],
    disableTreeView: true,
    samlLogout: false,
    samlFailed: false
}

const headerReducer = function (currentState = intialState, action) {
    switch (action.type) {
        case actionTypes.ON_ROUTE_CHANGE:
            return {
                ...currentState,
                currentRoute: action.route,
                disableTreeView: action.isTreeDisable,
            }
        case actionTypes.SAML_LOGOUT:
            return {
                ...currentState,
                samlLogout: action.samlLogout
            }
        case actionTypes.SAML_FAILED:
            return {
                ...currentState,
                samlFailed: action.samlFailed
            }
        default:
            return currentState
    }
}

export default headerReducer;
import React, { Component, Fragment } from 'react';
import { faPencilAlt, faArrowsAlt, faBroadcastTower, faLightbulb, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { getEditDeviceData, deviceLocation, loadNetworkProviderList, getPingStatus, getflashStatus, enableGpsForZm1 } from '../services/deviceService';
import EditDeviceComponent from './editDeviceComponent';
import MoveDeviceComponent from './moveDeviceComponent';
import NetworkTestComponent from './networkTestComponent';
import FlashDeviceComponent from './flashDeviceComponent';
import { formFields } from '../../../../utility/addDeviceUtility';
import { DeviceContext } from '../provider/deviceProvider';
import { getNodes } from '../../../../treeview/containers/treeMethods';
import { compareValues } from '../../../../services/utilityService';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Tooltip from '../../../../hoc/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getProfileData } from '../../../equipmentProfile/services/eProfileService';
import { showAlertToaster } from '../../../../login/actions/loginActionDispatch';
import { FormattedMessage } from 'react-intl';
import ampleStore from '../../../../store';

class Actions extends Component {

    static contextType = DeviceContext;

    state = {
        isModalOpen: false,
        editData: {},
        moveData: {},
        providerData: [],
        pingStatus: {},
        flashStatus: [],
        isEdit: false,
        isMove: false,
        isNetworkTest: false,
        isFlash: false,
        isLoadingData: false
    }

    //On Click of Edit Button
    fetchEditAction = async (e, info) => {
        e.preventDefault();
        this.setState({ isEdit: true, isModalOpen: true, isLoadingData: true }, async () => {
            const list = [{ label: "--select--", value: "--select--" }];
            const editData = await getEditDeviceData(info.id);
            const providerData = await loadNetworkProviderList(info.deviceType, info.hardwareVersion);  // 2nd param (hardwarerevision) for UM3+ devices we are sending value (C,CU), rest all device types are sending as blank or undefined
            const transformerProfile =(info.deviceType==="UM1"||info.deviceType==="VC10")? await getProfileData("getTransformerProfileData", this.props.orgId):[];
            if (providerData.length > 0) {
                providerData.sort();
                providerData.forEach(item => {
                    list.push({ label: item, value: item });
                });
                formFields.filter(field => field.key === "networkProvider" ? field.options = list : "")
            }
            if (transformerProfile.length>0){
                const transformerList = [{ label: "--select--", value: "--select--" }]
                transformerProfile.sort();
                transformerProfile.forEach(item => { item.transformerProfileName && transformerList.push({ label: item.transformerProfileName, value: item.id }) })
                transformerList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
                formFields.filter(field => field.key === "transformer" ? field.options = transformerList : "")
            }
            this.setState({ editData: editData, providerData: providerData, isLoadingData: false });
        })
    }

    //On Click of Move Button
    fetchMoveAction = async (e) => {
        e.preventDefault();
        this.setState({ isMove: true, isModalOpen: true }, async () => {
            const list = [{ label: "--select--", value: "--select--" }];
            const movedata = await deviceLocation(this.props.orgId, this.props.data.id)
            const treeData = this.context.state.treeNode.routeParams ? this.context.state.treeNode.routeParams[1] : this.context.state.treeNode;
            const routeParams = [treeData.type, treeData];
            let dropdownData = await getNodes(routeParams);
            dropdownData = dropdownData && dropdownData.length ? dropdownData : [];
            if (dropdownData.length > 0) {
                dropdownData = dropdownData.sort(compareValues("name"));
                dropdownData.forEach(item => {
                    list.push({ label: item.name, value: item.name });
                    formFields.filter(field => field.key === item.type.toLowerCase() ? field.options = list : "");
                });
            }
            this.setState({ moveData: movedata });
        })
    }

    //On Click of Network Test Button
    fetchNetworkTestAction = async (e, info) => {
        e.preventDefault();
        this.setState({ isModalOpen: true, isNetworkTest: true,isLoadingData: true }, async () => {
            const pingStatus = await getPingStatus(info.id);
            this.setState({ pingStatus: pingStatus, isLoadingData: false  });
        });
    }

    //On Click of Flash Button
    fetchFlashAction = async (e, info) => {
        e.preventDefault();
        this.setState({ isModalOpen: true, isFlash: true, isLoadingData: true }, async () => {
            const flashStatus = await getflashStatus(info.id);
            this.setState({ flashStatus: flashStatus, isLoadingData: false })
        })
    }

    //On click of enable GPS icon for ZM1 devices
    enableGpsAction = async (e, info) => {
        e.preventDefault();
        const data = await enableGpsForZm1(info.id);
        if (data) {
            window.scrollTo(0,0);
            data.status !== "FAIL" ? ampleStore.dispatch(showAlertToaster(<><FormattedMessage id='groupmanagement.success.enableGps' /> <strong>{info.serialNumber} </strong></>, 'success')) : ampleStore.dispatch(showAlertToaster(data.message, 'danger'))
        }
    }

    //On Click of X button on popup
    onCloseModal = () => {
        this.setState({ isModalOpen: false, isEdit: false, isMove: false, isNetworkTest: false, isFlash: false })
    }
    render() {
        const { roleId } = this.props.roleAndPermission;
        return (
            <Fragment>
                <div className='actionColumnwidth'>
                    <div className={'mr-3 d-inline ' + ((roleId === 3 || roleId === 4 || this.props.data.notProcessed || this.props.data.deviceType === 'UM1' || this.props.data.deviceType === 'VC10' ) ? "disabled" : "")} onClick={(roleId !== 3 && roleId !== 4) ? (e) => this.fetchMoveAction(e) : undefined}>
                    <Tooltip baseContent={<FontAwesomeIcon icon={faArrowsAlt} size="sm"/>} message={"Move"} placement={'top'} />
                    </div>
                    <div className={'mr-3 d-inline ' + ((roleId === 3 || roleId === 4 || this.props.data.notProcessed) ? "disabled" : "")} onClick={(roleId !== 3 && roleId !== 4) ? (e) => this.fetchEditAction(e, this.props.data) : undefined}>
                    <Tooltip baseContent={<FontAwesomeIcon icon={faPencilAlt} size="sm"/>} message={"Edit"} placement={'top'} />
                    </div>
                    <div onClick={(e) => this.fetchNetworkTestAction(e, this.props.data)} className={'mr-3 ' + (this.props.data.deviceType !== "ZM1" ? "d-inline" : "d-none") + (this.props.data.deviceType === 'UM1' || this.props.data.deviceType === 'VC10' || this.props.data.notProcessed || this.props.data.mcpDevice ? " disabled" : "")}>
                    <Tooltip baseContent={<FontAwesomeIcon icon={faBroadcastTower} size="sm"/>} message={"Network Test"} placement={'top'} />
                    </div>
                    <div onClick={(e) => this.fetchFlashAction(e, this.props.data)} className={'mr-3 ' + (this.props.data.deviceType !== "ZM1" ? "d-inline" : "d-none") + (this.props.data.deviceType === 'UM1' || this.props.data.deviceType === 'VC10' || this.props.data.notProcessed || this.props.data.mcpDevice ? " disabled" : "")}>
                    <Tooltip baseContent={<FontAwesomeIcon icon={faLightbulb} size="sm"/>} message={"LED Flash"} placement={'top'} />
                    </div>
                    <div onClick={(e) => this.enableGpsAction(e, this.props.data)} className={'mr-3 ' + (this.props.data.deviceType === "ZM1" ? "d-inline" : "d-none") + (this.props.data.notProcessed ? " disabled" : "")}>
                        <Tooltip baseContent={<FontAwesomeIcon icon={faMapMarkerAlt} size="sm" />} message={"Enable GPS"} placement={'top'} />
                    </div>
                </div>
                {
                    this.state.isEdit ?
                        <EditDeviceComponent selectedNode={this.context.state.treeNode} isModalOpen={this.state.isModalOpen} orgId={this.props.orgId} rowData={this.props.data} onCloseModal={this.onCloseModal} serialNumber={this.props.data.serialNumber} editData={this.state.editData} providerData={this.state.providerData} loader={this.state.isLoadingData} /> :
                        this.state.isMove ?
                            <MoveDeviceComponent selectedNode={this.context.state.treeNode} isModalOpen={this.state.isModalOpen} orgId={this.props.orgId} serialNumber={this.props.data.serialNumber} rowData={this.props.data} moveRowData={this.state.moveData} onCloseModal={this.onCloseModal} /> :
                            this.state.isNetworkTest ?
                                <NetworkTestComponent isModalOpen={this.state.isModalOpen} serialNumber={this.props.data.serialNumber} onCloseModal={this.onCloseModal} pingStatus={this.state.pingStatus} rowData={this.props.data} loader={this.state.isLoadingData}/> :
                                this.state.isFlash ?
                                    <FlashDeviceComponent isModalOpen={this.state.isModalOpen} onCloseModal={this.onCloseModal} rowData={this.props.data} flashStatus={this.state.flashStatus} loader={this.state.isLoadingData} /> : ""
                }

            </Fragment>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default withRouter(connect(mapStateToProps, {})(Actions));
import React from 'react';
import VirtualDeviceMngmntContainer from './containers/virtualDeviceContainer';
import VirtualDeviceMngmntProvider from './provider/virtualDeviceProvider';
import './index.css';


const VirtualDeviceManagement=()=>{
    return(
            <VirtualDeviceMngmntProvider>
                <VirtualDeviceMngmntContainer />
            </VirtualDeviceMngmntProvider>
    )
}

export default VirtualDeviceManagement;
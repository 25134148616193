import React, { Component, Fragment } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import * as deviceManagementUtility from '../../../../utility/deviceManagementUtility';
import { FormattedMessage } from 'react-intl';
import MultiSelectDropdown from '../../details/components/multiselectDropdownComponent';
import { DeviceContext } from '../../details/provider/deviceProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

let filterLength = 0;

class ManageSummaryCustomFiltersComponent extends Component {
    static contextType = DeviceContext;
    state = {
        filterColumnList: [],
    }
    myRef = React.createRef()
    /**
     * Reset Btn Click Functionality
     */
    reset = () => {
        this.saveSelectedFilters("reset");
        this.context.getDeviceList(this.context.state.treeNode);
    };

    /**
     * Save and Apply Btn Click Functionality
     */
    saveandapply = () => {
        this.saveSelectedFilters("saveandapply");
        this.context.getDeviceList(this.context.state.treeNode);
    };

    /**
     * Apply Btn Click Functionality 
     */
    apply = () => {
        this.saveSelectedFilters("apply");
        deviceManagementUtility.tableOptions.pageNo = 1;
        this.context.getDeviceList(this.context.state.treeNode, true);
    }

    saveSelectedFilters = (filterMode) => {
        if (filterMode === "reset") {
            this.state.filterColumnList.forEach(column => {
                //column.items = column.selectedItems;
                column.summaryItems = [];
                column.summarySelectedItems = [];
            })
        }
        else {
            this.state.filterColumnList.forEach(column => {
                column.summarySelectedItems = column.summaryItems;
            })
        }
        this.setState({
            filterColumnList: this.state.filterColumnList
        });
        this.findFilterLength(this.state.filterColumnList);
        deviceManagementUtility.tableOptions.customFilterColumns = this.state.filterColumnList;
        //this.myRef.current._values.open = false;
        this.myRef.current.click();
    }

    findFilterLength = (data) => {
        filterLength = 0;
        data.forEach(dt => {
            if (dt.summarySelectedItems.length > 0 && dt.type === "dropdown") {
                filterLength = filterLength + 1;
                dt.length = dt.data.length;
            }
            else if(dt.summarySelectedItems.length > 0 && dt.type === "textBox") {
                filterLength = filterLength + 1;
            }
        });
        deviceManagementUtility.tableOptions.summaryFilterSelectionCount = filterLength;
    }

    componentDidMount() {
        filterLength = deviceManagementUtility.tableOptions.summaryFilterSelectionCount;
        this.setState({
            filterColumnList: deviceManagementUtility.tableOptions.customFilterColumns
        });
        //this.findFilterLength(this.state.filterColumnList);
    }

    /**
     * Dropdown Value on change 
     */
    onChangeHandler = (optionList, dataFieldName) => {
        this.state.filterColumnList.forEach(column => {
            if (optionList.type) {
                if (column.dataField === dataFieldName) {
                    column.summaryItems = [];
                    if (optionList.target.value.length > 0) {
                        column.summaryItems.push(optionList.target.value)
                    }
//                    else {
  //                      column.summaryItems = column.summaryItems;
    //                }
                }
            }
            else {
                if (optionList.length > 0) {
                    optionList.forEach(selectedOption => {
                        if (column.dataField === selectedOption.name) {
                            column.length = column.data.length;
                            column.summaryItems = optionList;
                        }
                    })
                }
                else if (optionList.length === 0 && column.dataField === dataFieldName) {
                    column.summaryItems = optionList;
                }
            }
        });
        this.setState({
            filterColumnList: this.state.filterColumnList
        });
    }

    dontClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    handleShowAllBtn = (columnName) => {
        this.state.filterColumnList.filter(column => column.dataField === columnName ? column.summaryItems = [] : "");
        this.setState({ filterColumnList: this.state.filterColumnList })
    }

    render() {
        let customFilterButtons = deviceManagementUtility.tableOptions.customFilterButtons;
        const filterColumns = this.state.filterColumnList.map((column, index) => {
            if (column.type === "textBox") {
                return (
                    <li key={index} className="row m-0 p-0 mb-2">
                        <label className="customFilterStyle col-5 m-0 p-0"><FormattedMessage id={`dm.column.${column.dataField}`} /></label>
                        <input type="textbox" value={column.summaryItems.toString()} onChange={e => this.onChangeHandler(e, column.dataField)} onBlur={e => this.onChangeHandler(e, column.dataField)} onSelect={this.dontClose} className="customFilterStyle txtbox-style col-7 p-2 m-0 p-0 form-control" placeholder="Search" name={column.dataField} />
                    </li>
                )
            }
            else {
                return (
                    <div key={index} className="row m-0 p-0 mb-2">
                        <label className="customFilterStyle col-5 m-0 p-0"><FormattedMessage id={`dm.column.${column.dataField}`} /></label>
                        <MultiSelectDropdown
                            selectedArrayList={column.summaryItems}
                            optionList={column.data.map((option, i) => ({
                                "name" : column.dataField,
                                "text": option,
                                "value": option,
                                "keyIndex": i
                            }))}
                            onChange={this.onChangeHandler}
                            name={column.dataField}
                            manySelectedPlaceholder={`%s/${column.length} Checked, ... `}
                            allSelectedPlaceholder={`%s/${column.length} Checked, ... `}
                            placeholder="Select"
                            multiple={true}
                            includeSelectAll={false}
                            includeFilter={false}
                            open={false}
                            keepOpen={true}
                            valueKey="value"
                            labelKey="text"
                            handleShowAllBtn={() => this.handleShowAllBtn(column.dataField)}
                            btmLabel={"Show All"}
                        />
                    </div>
                )
            }
        });

        const filterButtons = customFilterButtons.map((button, i) => {
                return !button.hidden ? button.dataField === "resetAll" ? <Button key={i} className={"btnStyling"} disabled={filterLength === 0  ? true : false} onClick={this[button.action]} variant="secondary"><FormattedMessage id={`dm.column.${button.dataField}`} /></Button> : <Button key={i} className="btnStyling" onClick={this[button.action]} variant="secondary"><FormattedMessage id={`dm.column.${button.dataField}`} /></Button> : "";
        });
        // {this.findFilterLength(this.state.filterColumnList)};
        return (
            <Fragment>
                <Dropdown id="dropdown-custom-filters">
                    <Dropdown.Toggle className="btn-outline-secondary btn-group-icons btn-light" ref={this.myRef}>
                        <FontAwesomeIcon icon={faFilter} />
                   </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-disable-autohide dropdown-filters">
                        {filterColumns}
                        {filterButtons}
                    </Dropdown.Menu>
                </Dropdown>
                <span className="badge badge-notify">{filterLength > 0 ? filterLength : "" }</span>
            </Fragment>
        );
    }
}

export default ManageSummaryCustomFiltersComponent;
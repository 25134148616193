import React, { Component } from 'react';
import TextField from '../../../../hoc/form/textField';
import CheckBox from '../../../../hoc/form/checkbox';
import ToggleSwitch from '../../../../hoc/toggleSwitch';
import { FormattedMessage } from 'react-intl';
import PopOverMessage from '../../../../hoc/popOver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ProfileContext } from '../provider/profilesProvider';
import { ProfileDataOrder, getSortedList } from '../../../../utility/manageProfileUtility';


class UM1TabContent extends Component {
    static contextType = ProfileContext;

    getPopOver(key, label) {
        return (this.props.popOver ? <PopOverMessage trigger="focus" keyVal={`${key}-${label}`} placement="right" popoverBody={
            <div className="m-2"><FormattedMessage id={`manage.profiles.${label}-popOverContent`} /></div>
        } displayPopOver={
            <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
        } /> : null)
    }
    render(){
        return(
            <div>
            {this.props.contentArrParent !== undefined && Object.entries(getSortedList(this.props.contentArrParent,ProfileDataOrder[this.props.deviceType][this.props.parentObj]["headers"])).map(([label, value], key) => {
                let checkboxValue = this.props.contentArrParent[label]["defaultAPN"];
                let adminFlag = this.props.parentObj === 'um1Network' && this.context.roleAndPermission.roleName !== 'ROLE_ADMIN' ? true : false;
                return (
                    typeof value === 'object' ? 
                    <div key={key}>
                        {Object.keys(value).length > 0 && <div className="form-section">
                            <span className="form-section-content-l form-header">{<FormattedMessage id={`manage.profiles.${label}`} />}</span>
                        </div>}
                        {label === "pdpContext" && <div className="form-section">
                            <CheckBox 
                                label={<span><FormattedMessage id='manage.profiles.networkCheckBox' /> </span>}
                                checked={this.props.contentArrParent[label]["defaultAPN"]}
                                handleOnchange={(e)=>this.props.handleOnchangeForms(this.props.parentObj,"defaultAPN",checkboxValue === true ? false : true,label)}
                                disabled={adminFlag}
                            />
                        </div>}
                        {
                            Object.entries(getSortedList(this.props.contentArrParent[label],ProfileDataOrder[this.props.deviceType][this.props.parentObj][label])).map(([label1, value1], key1) => {
                                return (
                                    label1 !== "defaultAPN" && <div className="form-section d-flex" key={key1}>
                                        <span className="form-section-content-l-70">
                                            {this.props.updateFormattedMessage(label1, this.props.rules[label])}
                                            {this.getPopOver( key, label1 )}
                                        </span>
                                        <span className="form-section-content-r-30">{
                                            this.props.isBooleanValue(value1) ?
                                                <ToggleSwitch
                                                    value={value1 === true ? true : false}
                                                    toggleSwitchValue={(e)=>this.props.handleOnchangeForms(this.props.parentObj,label1,value1 === true ? false : true,label)}
                                                    leftValue="On"
                                                    rightValue="Off"
                                                    disabled = { adminFlag || (label1 !== "cid" && checkboxValue)}
                                                />
                                                : <TextField
                                                    type="text"
                                                    value={value1 ? value1 : ""}
                                                    field={value1}
                                                    handleOnchange={(e)=>this.props.handleOnchangeForms(this.props.parentObj,label1,e,label)}
                                                    className={this.props.formError[label1] !== undefined && this.props.formError[label1] === true ? "form-err-border-red" : ""}
                                                    isDisabled = { adminFlag || (label1 !== "cid" && checkboxValue)}
                                                />
                                        }</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                : 
                    <div className="form-section d-flex" key={key}>
                        <span className="form-section-content-l-70">
                            {this.props.updateFormattedMessage(label, this.props.rules[label])}
                            {this.getPopOver( key, label )}
                        </span>
                        <span className="form-section-content-r-30">{
                            this.props.isBooleanValue(value) ?
                                <ToggleSwitch
                                    value={value === true ? true : false}
                                    toggleSwitchValue={(e)=>this.props.handleOnchangeForms(this.props.parentObj,label,value === true ? false : true,null)}
                                    leftValue="On"
                                    rightValue="Off"
                                />
                                : <TextField
                                    type="text"
                                    isDisabled={false}
                                    value={value ? value : ""}
                                    field={value}
                                    handleOnchange={(e)=>this.props.handleOnchangeForms(this.props.parentObj,label,e,null)}
                                    className={this.props.formError[label] !== undefined && this.props.formError[label] === true ? "form-err-border-red" : ""}
                                />
                        }</span>
                    </div>

                )
            })
            }
            </div>
        )
    }
}

export default UM1TabContent;
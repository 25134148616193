import { getURL } from '../../../providers/configProvider';
import { requestPOST, requestGET, requestDELETE } from '../../../services/extendedService';

export const getProfileData = (type, orgId) => {
    let params = { 'apiType': 'data', ORGID: orgId }
    const url = getURL('equipementprofile', type, params, true);
    return requestGET(url);
};

export const createProfileData = (obj, type) => {
    let params = { 'apiType': 'data' }
    const url = getURL('equipementprofile', type, params, true);
    return requestPOST(url, obj, {}, { validateSuccess: false });
};

export const deleteProfile = (type, profileId) => {
    let params = { 'apiType': 'data', PROFILEID: profileId }
    const url = getURL('equipementprofile', type, params);
    return requestDELETE(url, {}, { validateSuccess: false });
}

export const getProfileMapping = (TransformerIds, type, orgId) => {
    let params = { 'apiType': 'data', ORGID: orgId }
    const url = getURL('equipementprofile', type, params);
    return requestPOST(url, TransformerIds, {}, { validateSuccess: false })
}
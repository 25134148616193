import { getRestAPIURL } from '../../providers/configProvider';
import { authorizeApi, getTokenService, loginURL } from '../services/loginServices';
import * as loginDispatch from './loginActionDispatch';
import { generateCodeChallenge, generateCodeVerifier, setAuthToken } from '../../utility/authTokenUtility';
import { setSession } from '../../home/actions/homeActionsBuilder';
import { logoutToken } from '../../mainContent/userManagement/services/userService';
import { getTokenExpiryTime } from '../../services/utilityService';

export const logIn = (postData) => {
    return dispatch => {
        dispatch(loginDispatch.onLogIn(postData));
    };
};

export const logOut = () => {
    return dispatch => {
        dispatch(loginDispatch.onLogOut());
    }

}

export const updateGlobalTree = (newData) => {
    return dispatch => {
        dispatch(loginDispatch.updateGlobalTree(newData));
    };
};
export const updateSearchQuery = (newData) => {
    return dispatch => {
        dispatch(loginDispatch.updateSearchQuery(newData));
    };
};
export const notify = (newData) => {
    return dispatch => {
        dispatch(loginDispatch.notify(newData));
    };
};
export const showAlertToaster = (errorMessage) => {
    return dispatch => {
        dispatch(loginDispatch.showAlertToaster(errorMessage));
    };
}
export const closeAlertToaster = () => {
    return dispatch => {
        dispatch(loginDispatch.closeAlertToaster());
    };
}
export const openErrorWindow = (errorMessage, errorTile) => {
    return dispatch => {
        dispatch(loginDispatch.openErrorWindow(errorMessage, errorTile));
    };
}
export const closeErrorWindow = (errorMessage) => {
    return dispatch => {
        dispatch(loginDispatch.closeErrorWindow(errorMessage));
    };
}
export const setLoader = (loaderData) => {
    return dispatch => {
        dispatch(loginDispatch.setLoader(loaderData));
    };
}

export const getToken = (code) =>{
    return dispatch => {
        const client = 'react-client';
        const secret = 'secret';
        const grant_type = 'authorization_code'
        const verifier = localStorage.getItem('codeVerifier');
        const orgId = localStorage.getItem('orgId');
        const body = `client_id=${client}&redirect_uri=${getRestAPIURL(true)}amplemanage/authorized&grant_type=${grant_type}&code=${code}&code_verifier=${verifier}&orgId=${orgId}`;
        getTokenService(body,client,secret)
            .then(res=>{
            if(res&&res.status===200){
                if (res.data?.id_token) {
                    localStorage.setItem('token',JSON.stringify({...res.data}));
                    localStorage.setItem('tokenExpiryTime', new Date().getTime() + JSON.parse(localStorage.getItem('token')).expires_in * 1000)
                    setAuthToken(res.data.access_token);
                    dispatch(loginDispatch.setAuthToken(res.data));
                    const TET =  getTokenExpiryTime(res.data.expires_in)
                    dispatch(loginDispatch.setTET(TET));
                    dispatch(setSession())
                }
            }
        }).catch(err=>{
            localStorage.clear();
            window.location.href = `${window.location.origin}/amplemanage/login`;  
        })
    }
}

export const handleAuthApi = () => {
  localStorage.setItem("loader", true);
  const verifier = generateCodeVerifier();
  localStorage.setItem("codeVerifier", verifier);
  const generateCodeChallengePkce = generateCodeChallenge();
  localStorage.setItem("codeChallenge", generateCodeChallengePkce);
  const codeChallenge = localStorage.getItem("codeChallenge");
  const authorizeApiparams = {
    response_type: "code",
    client_id: "react-client",
    scope: "openid",
    redirect_uri: getRestAPIURL(true),
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
  };
  const link = authorizeApi(authorizeApiparams);
  window.location.href = link;
};


export const useRefreshForToken = () =>{
    return dispatch => {
        if (JSON.parse(localStorage.getItem('token'))) {
            const client = 'react-client';
            const secret = 'secret';
            const token = JSON.parse(localStorage.getItem('token'))
            const body = `grant_type=refresh_token&refresh_token=${token.refresh_token}`
            getTokenService(body, client, secret)
                .then((res) => {
                    if (res && res.status === 200) {
                        if (res.data?.id_token) {
                            localStorage.setItem('token', JSON.stringify({ ...res.data }));
                            localStorage.setItem('tokenExpiryTime', new Date().getTime() + JSON.parse(localStorage.getItem('token')).expires_in * 1000)
                            setAuthToken(res.data.access_token);
                            const TET = getTokenExpiryTime(res.data.expires_in)
                            dispatch(loginDispatch.setTET(TET));
                            dispatch(loginDispatch.setAuthToken(res.data));
                            //token expiry time is stored in local storage and also in redux store for timer to compare and refresh token in duplicate tabs
                        }
                    }
                })
                .catch((err) => {
                    localStorage.clear();
                    window.location.href = `${window.location.origin}/amplemanage/login`;
                })
        }
        else {
            localStorage.clear();
            window.location.href = `${window.location.origin}/amplemanage/login`;
        }
    }
}

export const logoutTokenHandler = (isSaml) => {
    if (JSON.parse(localStorage.getItem('token'))) {
        logoutToken()
            .then((data) => {
                logOut();
                setLoader(false);
                localStorage.clear();
                isSaml?
                    window.location.href = `${window.location.origin}/amplemanage/samlLogout`
                        :window.location.href = `${window.location.origin}/amplemanage/login`;
            })
            .catch((err) => {
                console.log(err);
                logOut();
                setLoader(false);
                localStorage.clear();
                isSaml?
                    window.location.href = `${window.location.origin}/amplemanage/samlLogout`
                        :window.location.href = `${window.location.origin}/amplemanage/login`;
            })
    }
    else {
        localStorage.clear();
        window.location.href = `${window.location.origin}/amplemanage/login`;
    }
}
import React, { Component, createContext } from 'react';
import { getManageSchedulesReportData, deleteSchedule } from '../services/manageSchedulesService';
import { displayColumns } from '../../../utility/manageSchedulesUtility';

export const ManageSchedulesContext = createContext();

class ManageSchedulesProvider extends Component {
    state = {
        loading: false,
        reportData: [],
        displayColumns: displayColumns.data,
        isModalOpen: false,
        rowData: {}
    }
    fetchManageScheduleReport = () => {
        this.setState({ loading: true });
        getManageSchedulesReportData()
            .then(data => {
                if (data && data.length > 0) {
                    this.setState({
                        loading: false,
                        reportData: data
                    });
                } else {
                    this.setState({
                        loading: false,
                        reportData: []
                    });
                }
            }).catch(err => {
            });
    }
    handleDeleteIconClick = (row) => {
        this.setState({ isModalOpen: true, rowData: row });
    }
    setColumnStatus = (value) => {
        let prevData = this.state.displayColumns;
        let filterData = [];
        if (prevData.includes(value)) filterData = prevData.filter(item => item !== value)
        else filterData = [...prevData, value]
        displayColumns.data = filterData;
        this.setState({ displayColumns: filterData })
    }
    onCloseModal = () => {
        this.setState({ isModalOpen: false });
    }
    onDeleteReportClickHandler = () => {
        this.setState({ loading: true, isModalOpen: false });
        deleteSchedule(this.state.rowData.id)
            .then((data) => {
                if (data) {
                    this.setState({
                        loading: false,
                        rowData: {}
                    });
                    this.fetchManageScheduleReport();
                }
            }).catch(err => {
            })
    }
    componentDidMount() {
        this.fetchManageScheduleReport();
    }
    render() {
        return (
            <ManageSchedulesContext.Provider value={{
                state: this.state,
                fetchManageScheduleReport: this.fetchManageScheduleReport,
                handleDeleteIconClick: this.handleDeleteIconClick,
                setColumnStatus: this.setColumnStatus,
                onCloseModal: this.onCloseModal,
                onDeleteReportClickHandler: this.onDeleteReportClickHandler
            }}>
                {this.props.children}
            </ManageSchedulesContext.Provider>
        )
    }
}
export default ManageSchedulesProvider;
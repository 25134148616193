import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Form } from 'react-bootstrap'
import { ProfileContext } from '../provider/profilesProvider';
import SingleSelect from '../../../../hoc/singleSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

class DeviceType extends Component {
    static contextType = ProfileContext;
    render() {
        return (
            <Row className="pt-2">
                <Col>
                    <Form.Row key={1}>
                        <Col xs={6} className="form-left"> <FormattedMessage id={`dm.column.deviceType`} /></Col>
                        <Col xs={6} className="form-right single-select-dropdown">
                            {/* <SelectField
                                field={[]}
                                defaultValue={this.context.state.deviceTypeList.selected}
                                handleOnchange={this.context.onchangeDeviceType}
                                optionData={this.context.state.deviceTypeList.options}
                                size={'sm'}
                            /> */}
                                {
                                <React.Fragment>
                                <SingleSelect
                                    value={this.context.state.deviceTypeList.selected}
                                    setType={this.context.onchangeDeviceType}
                                    data={this.context.state.deviceTypeList.options}
                                    icon='ok'
                                />
                                <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                </React.Fragment>
                                }
                            </Col>
                    </Form.Row>
                </Col>
            </Row>
        );
    }
}

export default DeviceType;
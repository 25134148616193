import React, { Component, createContext } from 'react';
import { getFaultsData, getFaultsDetails, exportData, getWaveformData, downloadSegmentData, getWaveformDownload, getMapData,getDistancetData,reCalculateFaultLocation } from '../services/faultDetailsService';
import { getMapCenter } from '../../../mapView/services/mapService';
import { groupRouteParams, dateConvertion, getBrowserTimeZone } from '../../../../services/utilityService';
import { connect } from 'react-redux';
import { faultDetailsData, keyValue, chartData,formatMs} from '../../../../utility/faultDetailsUtility';
import moment from 'moment-timezone';
import MarkerClusterer from '@google/markerclusterer';
import { withRouter } from "react-router-dom";
import { updateSelectedTreeFromDashboard } from '../../../dashboard/actions/index';
import { updateFilterData } from '../../register/actions/index';
import {closeUnusedTreeNodes} from "../../../../utility/faultAnalysisUtility";
import { setDownloadCSV, exitFullscreenToNormalMode } from '../../../../filters/commonFunction';

export const DetailsContext = createContext()
let infoWindow, spiderCenterMarker, markerSettings, googleMapRef, googleRef, markers = [], markerPositions = {};

class DetailsProvider extends Component {
  constructor(props) {
    super(props)
    let dashboardTimeStamp = sessionStorage.getItem("faultTimeStamp")? JSON.parse(sessionStorage.getItem('faultTimeStamp')):'';
    this.state = {
      treeLoading: false,
      treeNode: this.props.treeNode,
      updatedTreeData: this.props.updatedTreeData,
      timeZone: getBrowserTimeZone(),
      mainTableData: [],
      allTableData: [],
      feederFaultId: "",
      faultDetailsTableData: {},
      faultDetailsTableDataCopy: {},
      isLoadingMainTableData: false,
      isLoadingfltDtlTblData: false,
      userRole: this.props.roleAndPermission.roleId,
      filterFormOptionData: { faultState: [], faultType: [], faultClassification: [] },
      formData: {
        faultState: faultDetailsData.selectedDataByUser.faultState.length !== 0 ? faultDetailsData.selectedDataByUser.faultState : [],
        faultType: faultDetailsData.selectedDataByUser.faultType.length !== 0 ? faultDetailsData.selectedDataByUser.faultType : [],
        faultClassification: faultDetailsData.selectedDataByUser.faultClassification.length !== 0 ? faultDetailsData.selectedDataByUser.faultClassification : [],
        minRange: faultDetailsData.selectedDataByUser.minRange !== '' ? faultDetailsData.selectedDataByUser.minRange : '',
        maxRange: faultDetailsData.selectedDataByUser.maxRange !== '' ? faultDetailsData.selectedDataByUser.maxRange : ''
      },
      selectedDate: {
        startTimestamp:dashboardTimeStamp? dashboardTimeStamp.startTimestamp :faultDetailsData.selectedDataByUser.startTimestamp === "" ? faultDetailsData.selectedDataByUser.endTimestamp - ((30 * 1000 * 3600 * 24) - 1) : faultDetailsData.selectedDataByUser.startTimestamp,
        endTimestamp: dashboardTimeStamp? dashboardTimeStamp.endTimestamp:faultDetailsData.selectedDataByUser.endTimestamp,
      },
      totalBadgeCount: 0,
      formErr: {},
      isOpenChartModal: false,
      waveformList: [],
      backupWaveformList: [],
      typeVal: ['it'],

      mapViewData: {},
      isLoadingData: false,

      // ======> for map view
      mapZoom: 5,
      center: {
        lat: 10.99835602,
        lng: 77.01502627
      },
      isOpenStreetViewModal: false,
      streetViewLabel: '',
      isLodingStreet: false,
      streetViewAvailable: false,
      restrictWaveformCall: false,
      pageNumber: 1,
      showLoadMore: false,
      pageSize: 100,
      faultDataLineDisplay:[],
      disableRecalculate:false,

      siteTablePageNum:1,
      siteTableShowLoadMore: false,
      siteTablePageSize: 100,
    }
    this.OverlappingMarkerSpiderfier = null
    this.oms = null
    this.spiderfierOptions = {
      keepSpiderfied: true,
      nearbyDistance: 1,
      legWeight: 1,
      circleFootSeparation: 100,
      markersWontMove: true, markersWontHide: true
    }
    this.spiderfierAllowedZoom = 15
    this.fetchMapDataStart = false;
    this.chartList = [];
  }

  componentDidMount() {
    if (this.state.treeNode && this.state.treeNode.length !== 0 && (!this.props.treeLoader)) {
      // this.getAllFaultDetailsData();
      let dashboardTimeStamp = sessionStorage.getItem("faultTimeStamp")? JSON.parse(sessionStorage.getItem('faultTimeStamp')):'';
      dashboardTimeStamp&&this.setState({selectedDate: {startTimestamp:dashboardTimeStamp.startTimestamp,endTimestamp:dashboardTimeStamp.endTimestamp}})
      !this.props.dashboardFlag&&this.dashboardFlagCheckLoadData() 
      this.storeSelectedFilterData(this.state.formData);
      this.updateBadgeData(this.state.formData);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      treeNode: nextProps.treeNode,
      treeLoading: nextProps.treeLoader,
      selectSearchedNode : nextProps.selectSearchedNode
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.treeLoading && this.state.treeNode.type !== 'ROOTNODE' && this.state.treeNode.type !== 'REGION') {
      if ((prevProps.treeNode['name'] !== this.props.treeNode['name']) || (prevProps.treeNode['type'] !== this.props.treeNode['type']) || (prevProps.treeNode['id'] !== this.props.treeNode['id'])) {
        !this.props.dashboardFlag&&this.dashboardFlagCheckLoadData() 
      }
    }
  }

  componentWillUnmount(){
    this.setState({ mapViewData: {}, mainTableData:[] })
    this.oms = null;
    this.fetchMapDataStart=false;
    markers = []; markerPositions = {};
    const emptyFormdata = { eventType: '', eventState: '', classificationType: '', isReDirect: false }
    if (this.props.history.location.pathname !== '/events/details') {
      this.props.updateFilterData(emptyFormdata);
      sessionStorage.removeItem('faultTimeStamp');
    }

  }

  dashboardFlagCheckLoadData = ()=>{
    this.loadData(this.state.treeNode);
    this.props.setDashboardFlag();
  }

  getAllFaultDetailsData = async () => {
    this.setState({ isLoadingMainTableData: true }, () => {
      let requestParams = this.updateReqParams(this.state.selectedDate)
      var params = groupRouteParams(requestParams, this.state.treeNode.routeParams);
      getFaultsData(params)
        .then(data => {
          if (data.status !== 'FAIL') {
            var tableData = data && data.tableData ? data.tableData : [];
            this.generateFaultFiltersData(data);
            this.assignHistoryDataOnTable(tableData);
          }
        }).catch(err => this.setState({ isLoadingMainTableData: false }))
    })
  }

  generateFaultFiltersData = (filterData) => {
    this.setState({
      filterFormOptionData: {
        faultState: this.makeFilterData(filterData.filters.faultState),
        faultType: this.makeFilterData(filterData.filters.faultType),
        faultClassification: this.makeFilterData(filterData.filters.faultClassification),
      }
    })
  }

  makeFilterData = (data) => {
    let tempArr = [];
    data.map((item, key) => tempArr.push({ name: item, text: item, value: item, keyIndex: key, id: item }))
    return tempArr
  }

  assignHistoryDataOnTable = (data) => {

    let updateFormData = { //if manual clicked
        faultState: faultDetailsData.selectedDataByUser.faultState,
        faultType: faultDetailsData.selectedDataByUser.faultType,
        faultClassification: faultDetailsData.selectedDataByUser.faultClassification,
        minRange: faultDetailsData.selectedDataByUser.minRange,
        maxRange: faultDetailsData.selectedDataByUser.maxRange
     };

    if( this.props.registerData.isReDirect ){ //if redirecting from another page to this page (dashboard - heatmap chart, watchlist table, Fault: Register table location column ) then need to update filter data from reducer.
      updateFormData.faultState = this.props.registerData.eventState ? this.updateFilterData(this.props.registerData.eventState) : [];
      updateFormData.faultType = this.props.registerData.eventType ? this.updateFilterData(this.props.registerData.eventType): [];
      updateFormData.faultClassification = [];
      updateFormData.minRange = '';
      updateFormData.maxRange = '';
    }
  
    this.setState({ allTableData: data, mainTableData: data, mainTableDataCopy: data, isLoadingMainTableData: false,formData: updateFormData }, () => {
      this.filterTableDataSet()
      this.updateBadgeData(this.state.formData);
    })
  }

  loadMoreTableData = () => {
    let data = this.fetchFilteredData();
    this.setState({pageNumber: this.state.pageNumber + 1}, () => {
      this.setState({ mainTableData: data.slice(0,(this.state.pageNumber * this.state.pageSize)), mainTableDataCopy: data.slice(0,(this.state.pageNumber * this.state.pageSize)) }, () => {
        (data.length === this.state.mainTableData.length) && this.setState({showLoadMore: false});
      })
    })
  }

  fetchFilteredData = () => {
    let data = [...this.state.allTableData]
    data = this.state.formData.faultType.length > 0 ? keyValue(data, this.state.formData.faultType, "eventType") : data;
    data = this.state.formData.faultState.length > 0 ? keyValue(data, this.state.formData.faultState, "eventState") : data;
    data = this.state.formData.faultClassification.length > 0 ? keyValue(data, this.state.formData.faultClassification, "classificationType") : data;
    return data;
  }

  updateFilterData=(propertyName)=> [{name: propertyName, text: propertyName, value: propertyName, id: propertyName, keyIndex: 1}]

  faultDetailsData = (id) => { //for child table api
    this.setState({ isLoadingfltDtlTblData: true }, () => {
      var params = { "ORGID": this.props.tree[0].id, 'apiType': 'COMMON', 'feederFaultId': id };
      getFaultsDetails(params)
        .then(data => {
          if (data.status !== 'FAIL') {
            var eventData = (data && data.hasOwnProperty('events')) ? data.events : [];
            this.setState({ faultDetailsTableData: { ...data, events: eventData.slice(0,(this.state.siteTablePageNum * this.state.siteTablePageSize)) }, faultDetailsTableDataCopy: { ...data, events: eventData }, isLoadingfltDtlTblData: false },()=>{
              if(eventData.length > this.state.siteTablePageSize) {
                this.setState({siteTableShowLoadMore: true})
              } else this.setState({siteTableShowLoadMore: false})
            })
          }
        }).catch(err => this.setState({ isLoadingfltDtlTblData: false }))
    })
  }

  loadMoreSiteTableData=()=>{
    this.setState({siteTablePageNum: this.state.siteTablePageNum + 1}, () => {
      this.setState({ faultDetailsTableData: { ...this.state.faultDetailsTableData, events: this.state.faultDetailsTableDataCopy.events.slice(0,(this.state.siteTablePageNum * this.state.siteTablePageSize))}}, () => {
        if(this.state.faultDetailsTableDataCopy.events.length === this.state.faultDetailsTableData.events.length) this.setState({siteTableShowLoadMore: false});
      })
    })
  }

  updateReqParams = (data) => {
    let dashboardTimeStamp = sessionStorage.getItem("faultTimeStamp")? JSON.parse(sessionStorage.getItem('faultTimeStamp')):'';
    let startTimestamp = dashboardTimeStamp?  moment(dashboardTimeStamp.startTimestamp).set({hour:0, minute:0, second:0, millisecond:0}).valueOf(): faultDetailsData.selectedDataByUser.startTimestamp !== "" ? moment(faultDetailsData.selectedDataByUser.startTimestamp).set({hour:0, minute:0, second:0, millisecond:0}).valueOf() : data.startTimestamp;
    let endTimestamp = dashboardTimeStamp? moment(dashboardTimeStamp.endTimestamp).set({hour:23, minute:59, second:59, millisecond:999}).valueOf(): faultDetailsData.selectedDataByUser.endTimestamp !== "" ? moment(faultDetailsData.selectedDataByUser.endTimestamp).set({hour:23, minute:59, second:59, millisecond:999}).valueOf() : data.endTimestamp;
    let finalData = { STARTDATE: new Date(startTimestamp).setHours(0, 0, 0, 0), ENDDATE: new Date(endTimestamp).setHours(23, 59, 59, 999), 'TIMEZONE': getBrowserTimeZone(this.state.timeZone) }
    return finalData
  }

  handleRowOnClick = (row, state) => {
    this.setState({ feederFaultId: row.feederFaultId ? row.feederFaultId : "", faultRowData: row, siteTablePageNum: 1 }, async () => {
      this.faultDetailsData(this.state.feederFaultId);
      this.getAllMapViewData(this.state.feederFaultId);
      this.faultDataLineDisplay(this.state.feederFaultId,row);
    });
  }

  updateFirstAsRowSelected = (data) => { //on sorting table
    this.setState({ feederFaultId: data[0].feederFaultId, faultRowData: data[0] }, () => {
      this.faultDetailsData(this.state.feederFaultId);
      this.getAllMapViewData(this.state.feederFaultId);
      this.faultDataLineDisplay(this.state.feederFaultId,data[0]);
    })
  }

  onChangeHandler = (selectedList, field) => {
    if (field === 'minRange' || field === 'maxRange') {
      if (Number.isInteger(Number(selectedList)) && selectedList.length <= 5) {
        this.setState({ formData: { ...this.state.formData, [field]: selectedList } }, () => this.checkFormValidation(field))
      }
    } else this.setState({ formData: { ...this.state.formData, [field]: selectedList } })
  }

  checkFormValidation = (field) => {
    if (field === 'minRange' && this.state.formData.minRange !== "" && this.state.formData.maxRange !== "" && Number(this.state.formData.minRange) > Number(this.state.formData.maxRange)) {
      this.setState({ formErr: { ...this.state.formErr, [field]: true } })
    } else if (field === 'maxRange' && this.state.formData.maxRange !== "" && this.state.formData.minRange !== "" && Number(this.state.formData.maxRange) < Number(this.state.formData.minRange)) {
      this.setState({ formErr: { ...this.state.formErr, [field]: true } })
    } else this.setState({ formErr: { minRange: false, maxRange: false } })
  }

  handleShowAllBtn = (fieldName) => this.setState({ formData: { ...this.state.formData, [fieldName]: [] } })

  handleClickFilterApply = () => {
    this.setState({pageNumber:1});
    this.state.mainTableDataCopy.length > 0 && this.filterTableDataSet();
    this.storeSelectedFilterData(this.state.formData);
    let data = {eventState: this.state.formData.faultState.map(data=> data.name), eventType: this.state.formData.faultType.map(data=> data.name), classificationType:this.state.formData.faultClassification.map(data=> data.name)};
    this.props.updateFilterData(data);
    this.updateBadgeData(this.state.formData);
  }

  filterTableDataSet = () => {
    let data = this.fetchFilteredData();
    data = data && data.length > 0 ? data.filter((obj) => {
      if ((!isNaN(Number(this.state.formData.minRange)) && this.state.formData.minRange) && (!isNaN(Number(this.state.formData.maxRange)) && this.state.formData.maxRange)) {
        return obj['faultCurrent'] >= this.state.formData.minRange && obj['faultCurrent'] <= this.state.formData.maxRange;
      } else if (!isNaN(Number(this.state.formData.minRange)) && this.state.formData.minRange) {
        return obj['faultCurrent'] >= this.state.formData.minRange;
      } else if (!isNaN(Number(this.state.formData.maxRange)) && this.state.formData.maxRange) {
        return obj['faultCurrent'] <= this.state.formData.maxRange;
      } else {
        return true;
      }
    }) : data;
    if (data.length > this.state.pageSize) this.setState({showLoadMore: true});
    else this.setState({showLoadMore: false});
    
    this.setState({ allTableData: [...this.state.allTableData],mainTableData: data.slice(0,this.state.pageSize), mainTableDataCopy: [...this.state.allTableData] }, () => {
      if (this.state.mainTableData.length > 0) {
        this.setState({ feederFaultId: this.state.mainTableData[0].feederFaultId, faultRowData: this.state.mainTableData[0] })
        this.faultDetailsData(this.state.mainTableData[0].feederFaultId)
        this.getAllMapViewData(this.state.mainTableData[0].feederFaultId);
        this.faultDataLineDisplay(this.state.mainTableData[0].feederFaultId,this.state.mainTableData[0]);
      } else {
        this.faultDataLineDisplay();
        this.setState({ faultDetailsTableData: {}, faultDetailsTableDataCopy: {}, mapViewData: { sites: [], lat_min: null, lon_min: null, lat_max: null, lon_max: null } },
          () => {
            setTimeout(()=>{
              this.extendMap(this.state.mapViewData);
              this.reloadMapData(this.state.mapViewData);
              googleMapRef?.setOptions({ zoom: 3});
            },0)
          })
      }
    });
  }

  storeSelectedFilterData = (data) => {
    faultDetailsData.selectedDataByUser.faultType = data.faultType;
    faultDetailsData.selectedDataByUser.faultState = data.faultState;
    faultDetailsData.selectedDataByUser.faultClassification = data.faultClassification;
    faultDetailsData.selectedDataByUser.minRange = data.minRange;
    faultDetailsData.selectedDataByUser.maxRange = data.maxRange
  }

  updateBadgeData = (formData) => {
    let num = 0;
    for (var key of Object.keys(formData)) {
      if (formData[key] !== '' && formData[key].length !== 0) {
        num = num + 1
      }
    }
    if (formData.minRange !== "" && formData.maxRange !== "") num = num - 1
    this.setState({ totalBadgeCount: num })
  }

  handleClickFilterReset = () => {
    faultDetailsData.selectedDataByUser.faultType = [];
    faultDetailsData.selectedDataByUser.faultState = [];
    faultDetailsData.selectedDataByUser.faultClassification = [];
    faultDetailsData.selectedDataByUser.minRange = '';
    faultDetailsData.selectedDataByUser.maxRange = '';
   // this.setState({ mainTableData: this.state.mainTableDataCopy, formData: { faultState: [], faultType: [], faultClassification: [], minRange: '', maxRange: '' } }, () => {
    this.setState({pageNumber : 1,formData: { faultState: [], faultType: [], faultClassification: [], minRange: '', maxRange: '' } }, () => {
      this.updateBadgeData(this.state.formData)
      let mainTableDataLength = this.state.mainTableData.length;
      this.setState({ feederFaultId: mainTableDataLength > 0 ? this.state.mainTableData[0].feederFaultId : "", faultRowData: mainTableDataLength > 0 ? this.state.mainTableData[0] : {}})
      this.filterTableDataSet();
      if(mainTableDataLength > 0 ){
        this.faultDetailsData(this.state.mainTableData[0].feederFaultId);
        this.getAllMapViewData(this.state.mainTableData[0].feederFaultId);
        this.faultDataLineDisplay(this.state.mainTableData[0].feederFaultId,this.state.mainTableData[0]);
      }
    })
  }

  calenderCallback = (start, end) => {
    sessionStorage.removeItem('faultTimeStamp');
    faultDetailsData.selectedDataByUser.startTimestamp = moment(new Date(start));
    faultDetailsData.selectedDataByUser.endTimestamp = moment(new Date(end));
    this.setState({
      selectedDate:
        { startTimestamp: moment(new Date(start)), endTimestamp: moment(new Date(end)) }
    }, () => this.getAllFaultDetailsData())
  }

  handleExportData = (fileFormat) => {
    let requestParams = this.updateReqParams(this.state.selectedDate)
    var params = groupRouteParams(requestParams, this.state.treeNode.routeParams);
    this.setState({ isLoadingData: true }, () => {
      exportData({ ...params, FORMAT: fileFormat }) //appended 'format' property for export api call and its required with params
        .then(data => {
          setDownloadCSV(data);
          this.setState({ isLoadingData: false })
        }).catch(err => { this.setState({ isLoadingData: false }) })
    })
  }

  handleOpenOrCloseChartModal = (val) => {
    this.setState({ isOpenChartModal: val ,restrictWaveformCall:false}, () => {
      this.state.isOpenChartModal && this.getAllWaveformData();
      !this.state.isOpenChartModal && this.setState({ waveformList: [], backupWaveformList: [], typeVal: ['it'] })
      this.chartList=[];
      chartData.chartList = [];
    })
  }

  getAllWaveformData = () => {
    let requestParams = { 'ORGID': this.props.tree[0].id, 'CFCIEVENTID': this.state.feederFaultId, "apiType": "COMMON" };
    var reqBody = { feederFaultEventId: this.state.feederFaultId, site: [], deviceType: [], phase: ['A', 'B', 'C'], dataType: this.state.typeVal.length !== 0 ? this.state.typeVal : ["it", "et"] };
    this.setState({ isLoadingChartData: true }, () => {
      getWaveformData(requestParams, reqBody)
        .then(data => {
          if (data.status !== 'FAIL') {
            var list = [];
            if (data.hasOwnProperty('siteDetails') && Object.keys(data.siteDetails).length > 0) {
              Object.entries(data.siteDetails).forEach(function ([key, value]) {
                var phases = [];
                var phasesList = [];
                var eventTimes = [];
                var details = [];
                if (value && Object.keys(value).length > 0) {
                  Object.entries(value).forEach(function ([phaseName, phaseValue]) {
                    phases.push(phaseName);
                    phasesList.push({
                      id: phaseName.toLowerCase(),
                      content: phaseName.toLowerCase() + '-template',
                      active: (phases.length === 0) ? true : false
                    })
                    phaseValue.summaries.forEach(function (summary) {
                      summary['phase'] = phaseName;
                      eventTimes.push(summary.deviceTime);
                      // summary.deviceTime = summary.deviceTime;
                      summary.deviceTime = dateConvertion(summary.deviceTime, 'maxLongDate');
                      details.push(summary);
                    });
                  });
                }
                data.siteDetails[key].breadcrumb = data.region + ' | ' + data.substation + ' | ' + data.feeder + ' | ' + key;
                data.siteDetails[key].phases = phases;
                data.siteDetails[key].phasesList = phasesList;
                data.siteDetails[key].eventTimes = eventTimes;
                data.siteDetails[key].details = details;
                data.siteDetails[key].siteName = key;
                data.siteDetails[key].region = data.region;
                data.siteDetails[key].substation = data.substation;
                data.siteDetails[key].feeder = data.feeder;
                data.siteDetails[key]['detailCollapsible'] = true;
                data.siteDetails[key]['siteMinMaxTime'] = data.siteMinMaxTime[key];
                list.push(data.siteDetails[key]);
              });
            }
            this.setState({ waveformList: list, backupWaveformList: list, isLoadingChartData: false }, () => {
              // setting min and max value after zoom / reset zoom
              this.chartList && this.chartList.forEach(seriesdata => {
                list[0].siteMinMaxTime ? seriesdata.xAxis && seriesdata.xAxis[0].setExtremes(list[0].siteMinMaxTime[0], list[0].siteMinMaxTime[1], true, false) : seriesdata.xAxis && seriesdata.xAxis[0].setExtremes(null, null, true, false);
              })
            })
          }
        }).catch(err => this.setState({ isLoadingChartData: false }))
    })
  }

  // updateChartData = (val) => {
  //   //this.setState({ isLoadingChartData: true });
  //   let allData = this.state.typeVal;
  //   if (allData.indexOf(val) !== -1) allData = allData.filter(item => item !== val)
  //   else allData.push(val)
  //  // this.setState({ typeVal: allData }, () => {
  //     if (!this.state.restrictWaveformCall) {
  //       this.getAllWaveformData();
  //       this.setState({ restrictWaveformCall: allData.length === 0 || val === "et" })
  //     }
  //     else {
  //       let tempData = JSON.parse(JSON.stringify(this.state.backupWaveformList));
  //       if (allData.length > 0 && allData.length < 2) {
  //         let temp = ["it", "et"].find(e => e !== allData[0]);
  //         tempData.forEach((e, ind) => {
  //           if (e["A"]) { e["A"][temp + "Series"] = [] }
  //           if (e["B"]) { e["B"][temp + "Series"] = [] }
  //           if (e["C"]) { e["C"][temp + "Series"] = [] }
  //         })
  //       }
  //       this.setState({ waveformList: tempData,typeVal: allData }, () => {
  //       //  this.setState({ isLoadingChartData: false });
  //       })
  //     }
  //  // }
  //  // )
  // }


  updateChartData = (val) => {
    let allData = this.state.typeVal;
    if (allData.indexOf(val) !== -1) allData = allData.filter(item => item !== val)
    else allData.push(val)
    this.setState({ typeVal: allData }, () => {
      this.getAllWaveformData();
    })
  }
  
  downloadWaveform = (detail) => {
    let faultSegmentId = (detail && detail.hasOwnProperty('faultSegmentId')) ? detail.faultSegmentId : 0;
    let requestParams = { 'ORGID': this.props.tree[0].id, 'SEGEVENTID': faultSegmentId, "apiType": "COMMON" };
    this.setState({ isLoadingChartData: true }, () => {
      downloadSegmentData(requestParams)
        .then(data => {
          if (data.status !== 'FAIL') {
            this.getAllWaveformData()
          }
        }).catch(err => this.setState({ isLoadingChartData: false }))
    })
  }

  handleWaveformDownload = (faultData) => {
    var faultId = (faultData && faultData.hasOwnProperty('feederFaultId')) ? faultData.feederFaultId : 0;
    var requestParams = { 'ORGID': this.props.tree[0].id, 'CFCIEVENTID': faultId, "apiType": "COMMON" };
    this.setState({ isLoadingMainTableData: true }, () => {
      getWaveformDownload(requestParams)
        .then(data => {
          if (data.status !== 'FAIL') {
            this.getAllFaultDetailsData();
          } else this.setState({ isLoadingMainTableData: false })
        }).catch(err => this.setState({ isLoadingMainTableData: false }))
    })
  }

  getAllMapViewData = (id) => { //for map view data
    this.setState({ loading: true }, () => {
      var params = { "ORGID": this.props.tree[0].id, 'apiType': 'COMMON', 'feederFaultId': id };
      getMapData(params)
        .then(data => {
          if (data.status !== 'FAIL') {
            this.setState({ mapViewData: data }, () => {        
                this.extendMap(this.state.mapViewData);
                  this.reloadMapData(this.state.mapViewData);   
            })
          }
        }).catch(err => this.setState({ loading: false }))
    })
  }

  getCenterMapData = async () => {
      const params = { 'ORGID': this.props.tree[0].id, 'apiType': 'data' };
      let centerr = await getMapCenter(params)
      this.setState({
        center: {
          lat: centerr[0],
          lng: centerr[1]
        }
      });
  }

  extendMap = (data) => {
    if(googleRef){
      data.lat_min = data.lat_min ? data.lat_min : 0;
      data.lon_min = data.lon_min ? data.lon_min : 0;
      data.lat_max = data.lat_max ? data.lat_max : 0;
      data.lon_max = data.lon_max ? data.lon_max : 0;
      let place1 = new googleRef.LatLng(data.lat_min, data.lon_min);
      let place2 = new googleRef.LatLng(data.lat_max, data.lon_max);
      let bounds = new googleRef.LatLngBounds();
      bounds.extend(place1);
      bounds.extend(place2);
      googleMapRef.fitBounds(bounds);
      let mapcenter = googleMapRef.getCenter();
      googleMapRef.setCenter(new googleRef.LatLng((mapcenter.lat() + 0.0000001), mapcenter.lng()));
      googleMapRef.setOptions({ minZoom: 3});
    }  
  }

  reloadMapData = async (resData) => {
    if(infoWindow)infoWindow.close();
    if(googleRef){
      if (this.markerCluster) {
        this.markerCluster.clearMarkers()
      } else if (markers.length !== 0) {
        for (let m in markers) {
          markers[m].setMap(null);
        }
      }
  
      if (this.oms) this.unspiderfyHandler();
  
      this.setState({ loading: true });
      this.fetchMapDataStart = true;
      let data = resData;
      this.setState({ loading: false });
      markers = [];
      if (data && data.hasOwnProperty('sites')) {
        infoWindow = new googleRef.InfoWindow();
        let content = '';
        let infoCallback = function (marker, content, infowindow) {
          return function () {
            infoWindow.close();
            infoWindow.setContent(marker.customInfo);
            infoWindow.open(googleMapRef, marker);
          };
        };
        for (let site in data.sites) {
          let markerInfo = data.sites[site];
          let phasesList = Object.values(markerInfo.phases);
          let serialNumber = null;
          for (let i = 0; i < phasesList.length; i++) {
            if (phasesList[i].serialNumber) {
              serialNumber = phasesList[i].serialNumber;
              break;
            }
          }
          if (!serialNumber) { continue; }
          let latLng = new googleRef.LatLng(markerInfo.latitude, markerInfo.longitude);
          let markerIconName = this.siteMarkerIcon(markerInfo, this.state.faultRowData);
          let markerIconPath = require('../../../../resources/images/' + markerIconName + '.png');
          let markerOptions = (markerIconName.length === 10) ? markerSettings.pshadow : ((markerIconName.length === 8) ? markerSettings.ptrio : ((markerIconName.length === 7) ? markerSettings.plus : ((markerIconName.length === 6) ? markerSettings.shadow : ((markerIconName.length === 4) ? markerSettings.trio : markerSettings.plain))));
  
          let marker = new googleRef.Marker({
            position: latLng,
            serialNumber: serialNumber,
            status: markerInfo.status ? markerInfo.status : '',
            siteDeviceType: markerInfo.siteDeviceType ? markerInfo.siteDeviceType : '',
            positionNo: markerInfo.position ? markerInfo.position : 0,
            positionCount: markerInfo.um3TotalPositonCount ? markerInfo.um3TotalPositonCount : 0,
            numberOfActiveFaults: markerInfo.activeFaultsCount ? markerInfo.activeFaultsCount : 0,
            numberOfActiveInterruptions: markerInfo.activeInterruptionsCount ? markerInfo.activeInterruptionsCount : 0,
            markerIconPath: '../../../../resources/images/' + markerIconName + '.png',
            icon: {
              url: markerIconPath,
              size: markerOptions.size,
              origin: markerOptions.origin,
              anchor: markerOptions.anchor
            },
            // zIndex: markerInfo.activeFaultsCount ? 8888 : (markerInfo.activeInterruptionsCount ? 8887 : 8886),
            zIndex: markerInfo.fault ? 8888 : (markerInfo.interruption ? 8887 : 8886), //from angular updated
            customInfo: this.getTooltipInfo('A', markerInfo.phases['A'], markerInfo)	// TODO: not clear yet
          });
          googleRef.event.addListener(marker, 'mouseover', infoCallback(marker, content, infoWindow));
          markers.push(marker);
          markerPositions[serialNumber] = latLng;
        }
      }
      this.drawMarkers();
      this.setState({ loading: false });
    }
  }

  stopInterval = () => {
    clearInterval(this.intervalId);
  }

  drawMarkers = () => {
    this.stopInterval();
    this.intervalId = setInterval(this.checkMarkers, 1000);
  }

  checkMarkers = async () => {
    if (markers && markers.length > 0) {  //from angular updated
      this.setState({ loading: true });
      MarkerClusterer.CALCULATOR = function (markers, numStyles) {
        let index = 0;
        let count = 0;
        for (let marker in markers) {
          if (markers[marker].status != null)
            count++
        }
        count = count.toString();
        let title = "Number of devices in this cluster: " + count + ". Click to zoom";

        let dv = count;
        while (dv !== 0) {
          dv = parseInt(dv / 10, 10);
          index++;
        }

        index = Math.min(index, numStyles);
        return {
          text: count,
          index: index,
          title: title
        };
      };

      // this.markerCluster = new MarkerClusterer(map, markers, clusterOptions);
      for (let m in markers) {
        markers[m].setMap(googleMapRef);
      }

      // Spidefier construction
      this.oms = new this.OverlappingMarkerSpiderfier.OverlappingMarkerSpiderfier(googleMapRef, this.spiderfierOptions);

      this.oms.addListener('spiderfy', function (smarkers) {
        // Update the marker icons
        for (let i = 0; i < smarkers.length; i++) {
          let markerIcon = smarkers[i].markerIconPath;
          if (markerIcon && markerIcon.indexOf("UM3")) {
            let newMarkerIconUrl = markerIcon.replace("-UM3", "");
            let markerIconName = newMarkerIconUrl.split("/").splice(-1)[0].replace(".png", "");
            let markerOptions = (markerIconName.length === 10) ? markerSettings.pshadow : ((markerIconName.length === 8) ? markerSettings.ptrio : ((markerIconName.length === 7) ? markerSettings.plus : ((markerIconName.length === 6) ? markerSettings.shadow : ((markerIconName.length === 4) ? markerSettings.trio : markerSettings.plain))));
            let markerIconPath = require('../../../../resources/images/' + markerIconName + '.png');
            smarkers[i].setIcon({
              url: markerIconPath,
              size: markerOptions.size,
              origin: markerOptions.origin,
              anchor: markerOptions.anchor
            });
          }
        }

        // Enable the center for spiderfier view
        if (spiderCenterMarker && smarkers.length !==0) {
          spiderCenterMarker.setMap(googleMapRef);
          spiderCenterMarker.setPosition(markerPositions[smarkers[0].serialNumber] ? markerPositions[smarkers[0].serialNumber] : smarkers[0].getPosition());
        }
      });

      this.oms.addListener("unspiderfy", smarkers => {
        for (let i = 0; i < smarkers.length; i++) {
          if ((smarkers[i].siteDeviceType === 'UM3+') && (smarkers[i].positionCount > 1)) {
            let newMarkerIconUrl = smarkers[i].markerIconPath;
            let markerIconName = newMarkerIconUrl.split("/").splice(-1)[0].replace(".png", "");
            let markerOptions = (markerIconName.length === 10) ? markerSettings.pshadow : ((markerIconName.length === 8) ? markerSettings.ptrio : ((markerIconName.length === 7) ? markerSettings.plus : ((markerIconName.length === 6) ? markerSettings.shadow : ((markerIconName.length === 4) ? markerSettings.trio : markerSettings.plain))));
            let markerIconPath = require('../../../../resources/images/' + markerIconName + '.png');
            smarkers[i].setIcon({
              url: markerIconPath,
              size: markerOptions.size,
              origin: markerOptions.origin,
              anchor: markerOptions.anchor
            });
          }
        }

        // Disable the spiderfier center
        if (spiderCenterMarker) spiderCenterMarker.setMap(null);
        if (infoWindow) infoWindow.close();
      });

      // Handle spiderfier construction    
      let boundedMarkers = [];  
      let constructSpiderfierSet = () => {
        // Find the markers inside visible area
        // let bounds = googleMapRef.getBounds();
        boundedMarkers = [];
        for (let marker in markers) {
          let currMarker = markers[marker];
          // if(bounds.contains(currMarker.getPosition())) {
          boundedMarkers.push(currMarker);
          this.oms.addMarker(currMarker);
          // }
        }
        setTimeout(() => {
          //['numberOfActiveFaults', 'numberOfActiveInterruptions', 'positionCount', 'positionNo'] out of this temp sorting only with position no
          boundedMarkers && boundedMarkers.sort((a,b)=>a.positionNo - b.positionNo)
          if (boundedMarkers.length > 1) {
            googleRef.event.trigger(boundedMarkers[boundedMarkers.length-1], 'click');
          } else if (spiderCenterMarker) {
            spiderCenterMarker.setMap(null);
          }
        }, 500);
      }

      constructSpiderfierSet();

      // Handle zoom change event for spiderfier
      googleMapRef.addListener('zoom_changed', function () {
        constructSpiderfierSet();
      });

      this.setState({ loading: false });
      this.stopInterval();
    } else {
      if (!this.fetchMapDataStart) {
        this.setState({ loading: true });
        this.reloadMapData();
      }
    }
  };

  getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      styles: [{
        featureType: "poi.business",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }]
      }],
      infoWindow: {
        show: false,
        center: this.state.center,
        options: {
          boxStyle: {
            backgroundColor: "#CCC",
            border: "1px solid #999",
            borderRadius: "5px",
            width: "200px",
            height: "auto",
            padding: "10px"
          },
          content: "Device Info",
          zIndex: 99999999
        }
      },
      markersEvents: {
        mouseover: function (marker, eventName, model, args) {
          googleMapRef.infoWindow.options.content = model.customInfo;
          googleMapRef.infoWindow.center = { latitude: model.latitude, longitude: model.longitude };
          googleMapRef.infoWindow.show = true;
        }
      },
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [
              maps.MapTypeId.ROADMAP,
              maps.MapTypeId.SATELLITE,
              maps.MapTypeId.HYBRID,
              maps.MapTypeId.TERRAIN
          ]
      },
      
      //maxZoom: 5,
      restriction: {
        latLngBounds: { north: 85, south: -85, west: -170, east: 170 },
        strictBounds: true,
      },
    };
  }

  unspiderfyHandler = () => {
    this.oms.unspiderfy();
  };


  onGoogleApiLoaded = async (map, maps) => {
    googleMapRef = map;
    googleRef = maps;
    markerSettings = {
      // Default marker properties
      plain: {
        size: new googleRef.Size(45, 64),
        origin: new googleRef.Point(0, 0),
        anchor: new googleRef.Point(23, 64)
      },

      // Marker with plus properties
      plus: {
        size: new googleRef.Size(45, 77),
        origin: new googleRef.Point(0, 0),
        anchor: new googleRef.Point(23, 77)
      },

      // Marker with triangle properties
      trio: {
        size: new googleRef.Size(66, 78),
        origin: new googleRef.Point(0, 0),
        anchor: new googleRef.Point(22, 78)
      },

      // Marker with plus & triangle properties
      ptrio: {
        size: new googleRef.Size(66, 97),
        origin: new googleRef.Point(0, 0),
        anchor: new googleRef.Point(22, 97)
      },

      // Marker with shadow
      shadow: {
        size: new googleRef.Size(58, 88),
        origin: new googleRef.Point(0, 0),
        anchor: new googleRef.Point(29, 88)
      },

      // Marker with shadow & plus
      pshadow: {
        size: new googleRef.Size(57, 87),
        origin: new googleRef.Point(0, 0),
        anchor: new googleRef.Point(29, 87)
      }
    };
    this.OverlappingMarkerSpiderfier = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
    // this.getCenterMapData()
    // const params = {'ORGID': this.props.tree[0].id, 'apiType': 'data'};
    // this.setState({ loading: true });
    // let center = await getMapCenter(params);
    // if (center && center.length > 0) {
    //   this.setState({
    //     center: {
    //       lat: center[0],
    //       lng: center[1]
    //     }
    //   });
    // }
    this.drawMarkers();
    spiderCenterMarker = new googleRef.Marker({
      icon: {
        url: require(`../../../../resources/images/minus.png`),
        size: new googleRef.Size(19, 19),
        origin: new googleRef.Point(0, 0),
        anchor: new googleRef.Point(9, 9)
      },
      zIndex: 9999
    });
    googleRef.event.addListener(spiderCenterMarker, 'click', this.unspiderfyHandler);
  }

  siteMarkerIcon = (siteInfo, faultInfo) => {
    var upstreamSite = (faultInfo && faultInfo.hasOwnProperty('upStreamSite')) ? faultInfo.upStreamSite : '';
    let defaultIconName = 'BBB'; // Consider all phases empty as default icon
    let markerIconName = '';
    let siteStatusList = [];
    if (siteInfo.phases) {
      for (var phase in siteInfo.phases) {
        var phaseInfo = siteInfo.phases[phase];
        if (phaseInfo.fault || (phaseInfo.numberOfActiveFaults && phaseInfo.numberOfActiveFaults > 0)) {
          markerIconName += 'R';
        } else if (phaseInfo.interruption || (phaseInfo.numberOfActiveInterruptions && phaseInfo.numberOfActiveInterruptions > 0)) {
          markerIconName += 'O';
        } else if (!phaseInfo.serialNumber) {
          markerIconName += 'B';
        } else {
          markerIconName += 'G';
        }

        siteStatusList.push(phaseInfo.status);
      }
    }
    if (siteStatusList && siteStatusList[0]) {
      markerIconName += (siteStatusList.indexOf('OFFLINE') !== -1) ? 'O' : ((siteStatusList.indexOf('INTERMITTENT') !== -1) ? 'I' : ((siteStatusList.indexOf('UNREGISTERED') !== -1) ? 'U' : ''));
    }

    // Check for UM3+ position count before returning the icon name
    //return (markerIconName ? markerIconName : defaultIconName) + (((siteInfo.siteDeviceType === 'UM3+') && (siteInfo.um3TotalPositonCount > 1)) ? '-UM3' : '');

    // Refine the icon name with UM3+ position count, upstream and deleted checks
    if (!markerIconName) { markerIconName = defaultIconName; }
    markerIconName += ((siteInfo.siteDeviceType === 'UM3+') && (siteInfo.um3TotalPositonCount > 1)) ? '-UM3' : '';
    markerIconName += siteInfo.deleted ? '-DS' : ((siteInfo.site === upstreamSite) ? '-US' : '');

    return markerIconName;
  };

  getTooltipInfo = (phase, phaseData, siteData) => {

    this.toggleStreetView = (lat, long, streetViewLabel, streetViewAngle) => {
      
      // Exit fullscreen mode if map element is currently in fullscreen mode, calls the exitFullscreen function to revert from fullscreen to the normal map view
      document.fullscreenElement && exitFullscreenToNormalMode();

      this.setState({
        isOpenStreetViewModal: true,
        isLodingStreet: true,
        streetViewLabel: streetViewLabel
      });
      let streetViewService = new googleRef.StreetViewService();
      let STREETVIEW_MAX_DISTANCE = 100;
      let latLng = new googleRef.LatLng(lat, long);

      streetViewService.getPanoramaByLocation(latLng, STREETVIEW_MAX_DISTANCE, (streetViewPanoramaData, status) => {
        if (status === googleRef.StreetViewStatus.OK) {
          this.setState({
            isLodingStreet: false,
            streetViewAvailable: true
          });
          let panorama = new googleRef.StreetViewPanorama(document.getElementById('streetView'), {
            pov: {
              heading: 300,
              pitch: (streetViewAngle != null) ? streetViewAngle : 30
            },
            addressControl: false
          });
          panorama.setPano(streetViewPanoramaData.location.pano);
          panorama.setVisible(true);
        } else {
          this.setState({
            isLodingStreet: false,
            streetViewAvailable: false
          });
        }
      });
    }

    this.navigatePage = (pageName, siteData) => {
      let path = {};
      if ((siteData.lateral == null) || (siteData.lateral === '')) {
        path = { regionName: siteData.region, substationName: siteData.substation, feederName: siteData.feeder, siteName: siteData.site, type: "SITE" }
      } else {
        path = { regionName: siteData.region, substationName: siteData.substation, feederName: siteData.feeder, siteName: siteData.site, lateralName: siteData.lateral, lateralSiteName: siteData.lateralSite, type: "LATERAL_SITE" }
      }
      this.props.setDashboardFlagState(true);
      this.props.updateSelectedTreeFromDashboard(path);
      this.props.history.push("/manage/details");
    };

    window.mapScope = this;
    var breadcrumb = "";
    if (siteData.lateral === null) {
      breadcrumb += "<span class='tooltip-links txt-color' onclick='javascript:mapScope.navigatePage(\"managedevices\"," + JSON.stringify(siteData) + ")'>" + siteData.site + "</span>";
    } else {
      breadcrumb += siteData.lateral +
        " - " +
        "<span class='tooltip-links txt-color' onclick='javascript:mapScope.navigatePage(\"managedevices\"," + JSON.stringify(siteData) + ")'>" + siteData.lateralSite + "</span>";
    }
    let siteName = siteData.lateral === null ? siteData.site : siteData.lateral + " - " + siteData.lateralSite;
    var info = "<div class='tooltip-title'><span class='fas'>&#xf0da;</span><span>" + breadcrumb + " - " + siteData.eventType + "</span></div><div>";
    info += "<fieldset class='tooltip-wrap' style='width: 100%;'> <table class='table tooltip-table'><thead><tr><th>Phase</th><th>Current(A<sub>rms</sub>)</th><th>Duration</th></thead>";

    for (let phase in siteData.phases) {
      var interruptionDuration = ((siteData.phases[phase].interruptionDuration === '') || (siteData.phases[phase].interruptionDuration === "-")) ? siteData.phases[phase].interruptionDuration : formatMs(siteData.phases[phase].interruptionDuration,"uptoSecs");
      info += "<tbody><tr><td>" + phase + "</td><td>" + siteData.phases[phase].faultCurrent + "</td><td>" + interruptionDuration + "</td></tr>";
    }

    info += "</tbody></table>";
    if (siteData.deleted) {
      info += "<div> * Site has been deleted </div>";
    }

    let lat = siteData.latitude;
    let long = siteData.longitude;
    let streetViewLabel = " " + siteData.region + " - " + siteData.substation + " - " + siteData.feeder + " - " + siteName;
    let streetViewAngle = (siteData.siteDeviceType === "UM3+") ? 0 : 30;

    info += "</div><hr><div>" +
      "<span class='tooltip-links txt-color' onclick='javascript:mapScope.toggleStreetView(" + lat + "," + long + "," + JSON.stringify(streetViewLabel) + "," + streetViewAngle + ")'>Street View</span>" +
      "<a class='tooltip-links'  target='_blank' href='https://www.google.com/maps/dir/?api=1&destination=" + lat + "," + long + "' >Get directions</a>" +
      "</div>";

    return info;

  };

  closeStreetViewModal = () => {
    googleMapRef.getStreetView().setVisible(false);
    this.setState({ isOpenStreetViewModal: false })
  }

  loadData = (nodeData, nodeValue, type) => {
    if (nodeValue && type !== 'ROOTNODE') {
      const index = this.state.treeNode.routeParams && this.state.treeNode.routeParams.findIndex(item => item === type);
      let obj = this.state.treeNode.routeParams ? index !== -1 ? this.state.treeNode.routeParams[index - 1].children.find(item => item.name === nodeValue) :
        this.state.treeNode.children.find(item => item.name === nodeValue) :
        this.state.treeNode.children.find(item => item.type === type && item.name === nodeValue);
        obj = closeUnusedTreeNodes(obj,type);
      obj && this.props.loadNextLevel({ "node": obj }, obj.routeParams, null, obj.name);
    } else if (!nodeData || (nodeValue && type === 'ROOTNODE')) {
        this.props.loadNextLevel({ "node": this.props.tree[0] }, null, null, this.props.tree[0].name);
    } else if (nodeData && nodeData.name) {
        if (nodeData && ((nodeData.type !== 'SITE') && (nodeData.type !== 'LATERAL') && (nodeData.type !== 'LATERAL_SITE'))) {
            this.getAllFaultDetailsData();
        } else {
          // Lateral and sites are disabled so redirect to feeder node
          let obj = nodeData.routeParams && nodeData.routeParams.length && nodeData.routeParams.find(item => item.type === "FEEDER")
          obj && this.props.loadNextLevel({ "node": obj }, obj.routeParams, null, obj.name);
        }
    }
    !this.props.dashboardFlag&&this.props.setDashboardFlag()
};

getNodeName = (nodeData, type) => {
    let text = null;
    nodeData && nodeData.routeParams && nodeData.routeParams.length && nodeData.routeParams.forEach(item => {
        if (item.type && type === item.type) {
            text = item.name;
        }
    })
    return text;
}


  faultDataLineDisplay = (id, row) => {
    if (id && row) {
      this.setState({ faultLineloading: true }, () => {
        let params = {'apiType': 'COMMON', 'feederFaultId': id, 'feederId': this.state.treeNode.type === "SUBSTATION" ? this.state.treeNode.children.find(e => e.name === row.feeder).id : this.state.treeNode.id };
        getDistancetData(params)
          .then(data => {
            let arr = [];
            if (data.sites?.filter(e => e.fault).length > 0 && data.sites?.filter(e => e.siteDeviceType === "UM1").length > 0) {
              for (let site in data.sites) {
                let markerIconName = data.sites[site].site !== "Normally Open" ? this.siteMarkerIcon(data.sites[site], this.state.faultRowData) : "BBB";
                let obj = { markerIconName: markerIconName, interruption: data.sites[site].interruption, fault: data.sites[site].fault, site: data.sites[site].site !== "Normally Open" ? data.sites[site].site : "N.O", faultDistance:  data.sites[site].faultDistance, faultdistanceLeft: data.sites[site].faultdistanceLeft, faultDistanceRight: data.sites[site].faultDistanceRight }
                arr.push(obj);
              }
            }
            this.setState({
              faultDataLineDisplay: arr,
              faultLineloading: false,
             // disableRecalculate: arr.length > 0 ? arr.filter(e => e.faultDistance).length > 0 : true
            });
          }).catch(err => this.setState({ faultLineloading: false }))
      })
    } else
      this.setState({ faultDataLineDisplay: [], disableRecalculate: true });
  }

  reCalculateFaultLocation = () => {
    this.setState({ faultLineloading: true }, () => {
      let params = {'apiType': 'COMMON', 'feederFaultId': this.state.feederFaultId, 'feederId': this.state.treeNode.type === "SUBSTATION" ? this.state.treeNode.children.find(e => e.name === this.state.faultRowData.feeder).id : this.state.treeNode.id ,'lateralId': this.state.faultRowData.lateralId};
      reCalculateFaultLocation(params)
        .then(data => {
          if (data.status !== 'FAIL') {
            setTimeout(()=>{
              this.faultDataLineDisplay(this.state.feederFaultId,this.state.faultRowData);
            },50000);
          } 
          this.setState({ faultLineloading: false }) 
        }).catch(err => this.setState({ faultLineloading: false }))
    });
  }

  render() {
    return (
      <DetailsContext.Provider value={{
        state: this.state,
        handleRowOnClick: this.handleRowOnClick,
        updateFirstAsRowSelected: this.updateFirstAsRowSelected,
        onChangeHandler: this.onChangeHandler,
        handleShowAllBtn: this.handleShowAllBtn,
        handleClickFilterApply: this.handleClickFilterApply,
        handleClickFilterReset: this.handleClickFilterReset,
        onCalenderCallback: this.calenderCallback,
        handleExportData: this.handleExportData,
        handleOpenOrCloseChartModal: this.handleOpenOrCloseChartModal,
        updateChartData: this.updateChartData,
        downloadWaveform: this.downloadWaveform,
        handleWaveformDownload: this.handleWaveformDownload,
        loadMoreTableData: this.loadMoreTableData,
        getOptions: this.getMapOptions,
        googleApiLoaded: this.onGoogleApiLoaded,
        closeStreetViewModal: this.closeStreetViewModal,
        loadData : this.loadData,
        loadMoreSiteTableData: this.loadMoreSiteTableData,
        chartList: this.chartList,
        reCalculateFaultLocation:this.reCalculateFaultLocation,
        roleAndPermission:this.props.roleAndPermission
      }}>
        {this.props.children}
      </DetailsContext.Provider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    roleAndPermission: state.loginData.roleAndPermission,
    tree: state.treeviewData.tree,
    registerData: state.faultAnalysisData.filterSelectionData

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      updateSelectedTreeFromDashboard: (data) => dispatch(updateSelectedTreeFromDashboard(data)),
      updateFilterData: (data) => dispatch(updateFilterData(data))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsProvider))

export const DetailsConsumer = DetailsContext.Consumer;
import React, { useState } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AmpleIcon = (props) => {
    const [value, setValue] = useState(false);
    const handleOnEnter = () => {
        setValue(true)
    }
    const handleOnHide = () => {
        setValue(false)
    }
    // Construct icon element based on type
    let iconElem = (props.type === "fontawesome") ? <FontAwesomeIcon icon={props.name} /> : ((props.type === "img") ? <span className={`img-icon-${props.name}`}></span> : <span>{props.tooltipText}</span>);

    return (
        <span className={`text-center ample-icon ${props.extraClass}`}>
            <OverlayTrigger
                placement="top"
                onEnter={handleOnEnter}
                onExit={handleOnHide}
                overlay={
                    <Tooltip className="tooltip">
                        <span style={{ display: value ? 'block' : 'none' }}>{props.tooltipText}</span>
                    </Tooltip>
                }
            >
                {iconElem}
            </OverlayTrigger>
        </span>
    );
};

export default AmpleIcon;
import React, { Component } from 'react'
import { Tabs, Tab } from 'react-bootstrap';

class TabsComponent extends Component {
    // constructor(props) {
    //     super()
    //     this.state = {
    //         page: props.page,
    //     }
    // }
    render() {
        return (
            // <Row>
            <Tabs
                id="controlled-tab-example"
                activeKey={this.props.currentTab}
                onSelect={this.props.handleTabSelect}
            >
                {this.props.allTabs.length !== 0 ? this.props.allTabs.map((item, key) => {
                    return <Tab key={key} eventKey={item.value} title={item.label} disabled={item.disabled ? true : false}>
                        {this.props.tabBody}
                    </Tab>
                }) : ""}

                {/* <Tab eventKey="profile" title="Profile">
                        Tab 2
                            </Tab> */}
            </Tabs>
            // </Row>
        )
    }
}

export default TabsComponent
import React, { Component } from "react";
import { Row, Col } from 'react-bootstrap'
import AlertMessage from "../../../../hoc/alert";
import Tabs from '../../../../hoc/tabs';
import ClassAssignmentTabData from '../components/classAssignmentTabData';
import { ClassAssignmentContext } from '../provider/classAssignmentProvider';

class ClassAssignmentContainer extends Component {
    static contextType = ClassAssignmentContext;
    render() {
        const selectedTabData = this.context.state.currentTab === "UM3+" ? this.context.state.um3TabClassAssignmentData :
            this.context.state.currentTab === "MM3" ? this.context.state.mm3TabClassAssignmentData :
            this.context.state.currentTab === "MM3ai" ? this.context.state.mm3aiTabClassAssignmentData :
                this.context.state.currentTab === "ZM1" ? this.context.state.zm1TabClassAssignmentData : {};
                
        return (
            <div className="manage-classAssignment">
                <Row className="p-0 m-0">
                    <Col className="p-0 m-0">
                        {
                            (this.context.state.alertMessage.message) && <AlertMessage position={"align-alert-center"} type={this.context.state.alertMessage.type} timer={10000} message={this.context.state.alertMessage.message} setVisible={() => this.context.onCloseAlert()} />
                        }
                        <Tabs
                            currentTab={this.context.state.currentTab}
                            handleTabSelect={this.context.handleTabSelect}
                            allTabs={this.context.state.allTabs}
                            tabBody={
                                <ClassAssignmentTabData
                                    isLoading={this.context.state.isLoading}
                                    currentTab={this.context.state.currentTab}
                                    isBtnDisabled={this.context.state.isBtnDisabled}
                                    tabClassAssignmentData={selectedTabData}
                                    popupData={this.context.state}
                                    onSaveBtnClickHandler={() => this.context.onSaveBtnClickHandler()}
                                    onCancelBtnClickHandler={() => this.context.onCancelBtnClickHandler()}
                                    inputChangeHandler={(e, fieldName) => this.context.inputChangeHandler(e, fieldName)}
                                    noDataAvailable = {this.context.state.noDataAvailable}
                                    />
                            }
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ClassAssignmentContainer;
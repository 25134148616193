import React, { Component, Fragment } from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getDateConvertedString } from '../../../../services/utilityService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { initiateFlash } from '../services/deviceService';
import ModalWindow from '../../../../hoc/modelWindow';
import Loader from '../../../../hoc/loader';
import ampleStore from '../../../../store';
import { showAlertToaster } from '../../../../login/actions/loginActionDispatch';

class FlashDeviceComponent extends Component {
    state = {
        showPopup: false,
        isLoadingData: false
    }

    onFlashClickHandler = async () => {
        this.setState({ isLoadingData: true }, async () => {
            const res = await initiateFlash(this.props.rowData.id);
            if (res) {
                if (res.status === "FAIL") {
                    this.setState({ showPopup: true, isLoadingData: false })
                    window.scrollTo(0,0);
                    ampleStore.dispatch(showAlertToaster(<div><strong>{this.props.rowData.serialNumber}</strong>: {res.message}</div>,'danger'))
                    this.props.onCloseModal()
                } else {
                    this.setState({ isLoadingData: false })
                    window.scrollTo(0,0);
                    ampleStore.dispatch(showAlertToaster(<div><strong>{this.props.rowData.serialNumber}</strong>: {res}</div>,'success'))
                    this.props.onCloseModal()
                }
            }
        })
    }

    closePopup = () => {
        this.setState({ showPopup: false })
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
        return btnName === "initiateFlash" ? this.onFlashClickHandler() :
            btnName === "cancel" || btnName === "close" ? this.props.onCloseModal() :
                btnName === "showPopupClose" ? this.closePopup() : null;
    }

    render() {
        let modalTitle, modalBody, modalFooter, footerClassname;
            modalTitle = <div className="popup-title"><FormattedMessage id={`flashDevice.popup.title`} /></div>;
            modalBody = <div className="min-vh-25">
                {this.state.isLoadingData || this.props.loader ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                <div className="col-12 p-0 m-0 line-height-4">
                    <div className="col-6 float-left">
                        <Row><span className=" col-6 font-weight-bold p-0 m-0"><FormattedMessage id={`flashDevice.deviceInformation.title`} /></span></Row>
                        <Row>
                            <span className="col-6 p-0 m-0"><FormattedMessage id={`flashDevice.serialNumber.title`} /></span>
                            <span className="col-1">:</span>
                            <span className="col-5 p-0 m-0">{this.props.rowData.serialNumber}</span>
                        </Row>
                        <Row>
                            <span className="col-6 p-0 m-0"><FormattedMessage id={`flashDevice.deviceStatus.title`} /></span>
                            <span className="col-1">:</span>
                            <span className="col-5 p-0 m-0">{this.props.rowData.status}</span>
                        </Row>
                        <Row>
                            <span className="col-6 p-0 m-0"><FormattedMessage id={`flashDevice.deviceState.title`} /></span>
                            <span className="col-1">:</span>
                            <span className="col-5 p-0 m-0">{this.props.rowData.deviceState}</span>
                        </Row>
                        <Row>
                            <span className="col-6 p-0 m-0"><FormattedMessage id={`flashDevice.lastCommunicationTime.title`} /></span>
                            <span className="col-1">:</span>
                            <span className="col-5 p-0 m-0">{getDateConvertedString(this.props.rowData.lastSuccess)}</span>
                        </Row>
                    </div>
                    {
                        this.props.flashStatus.length > 0 ?
                            <div className="col-6 float-left">
                                <Row><span className=" col-6 font-weight-bold p-0 m-0"><FormattedMessage id={`flashDevice.prevFlashInfo.title`} /></span></Row>
                                <Row>
                                    <span className="col-6 p-0 m-0"><FormattedMessage id={`flashDevice.initiatedAt.title`} /></span>
                                    <span className="col-1">:</span>
                                    <span className="col-5 p-0 m-0">{getDateConvertedString(this.props.flashStatus[0].initiatedAt)}</span>
                                </Row>
                                <Row>
                                    <span className="col-6 p-0 m-0"><FormattedMessage id={`flashDevice.initiatedBy.title`} /></span>
                                    <span className="col-1">:</span>
                                    <span className="col-5 p-0 m-0">{this.props.flashStatus[0].initiatedBy}</span>
                                </Row>
                                <Row>
                                    <span className="col-6 p-0 m-0"><FormattedMessage id={`flashDevice.flashStatus.title`} /></span>
                                    <span className="col-1">:</span>
                                    <span className="col-5 p-0 m-0">{this.props.flashStatus[0].status}</span>
                                </Row>
                                <Row>
                                    <span className="col-6 p-0 m-0"><FormattedMessage id={`flashDevice.reason.title`} /></span>
                                    <span className="col-1">:</span>
                                    <span className="col-5 p-0 m-0">{this.props.flashStatus[0].statusMessage ? this.props.flashStatus[0].statusMessage : ""}</span>
                                </Row>
                            </div> : ""
                    }
                </div>
            </div>
            footerClassname = this.props.flashStatus.length && this.props.flashStatus[0].status === 'PENDING' ? "modalSaveBtn disabled" : "modalSaveBtn"
            modalFooter = [{ className: footerClassname, name: <FormattedMessage id={`flashDevice.popup.button.initiateFlash`} />, value: "initiateFlash" }, { className: "modalCancelBtn", name: <FormattedMessage id={`deleteDevice.popup.button.cancel`} />, value: "cancel" }]
        return (
            <Fragment>
                {this.props.isModalOpen ?
                    <ModalWindow
                        show={this.props.isModalOpen}
                        onHide={() => this.props.onCloseModal()}
                        size={"lg"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> :
                    this.state.showPopup ?
                        <ModalWindow
                            show={this.props.showPopup}
                            onHide={() => this.closePopup()}
                            size={"lg"}
                            backdropClassName="zIndex1050"
                            title={modalTitle}
                            modeldata={{ content: modalBody }}
                            footer={modalFooter}
                            onBtnClickHandler={this.onClickHandler} /> : ""
                }
            </Fragment>
        )
    }
}

export default FlashDeviceComponent;
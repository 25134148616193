import * as actionTypes from './treeViewActionTypes';


const checkSite = node => node.type.indexOf('SITE') > -1;

export const setTree = (tree) => {
    //map response tree name to title - since tree view library needs tittle. 
    const mapedTree = tree.map(node => {
//        const { regions, ...currentNode } = node;
        return {
            ...node,
            //title: node.name,
//            subtitle:node.id+"_"+node.name,
            arrow: !checkSite(node) ? 'right' : '',
            expanded: node.hasOwnProperty("expanded") ? node.expanded : false
        }
    })
    return {
        type: actionTypes.LOAD_INITIAL_TREE,
        tree: mapedTree
    };
};

export const updateSelectedTree = (tree) => {
    return {
        type: actionTypes.UPDATE_SELECTED_TREE,
        tree: tree
    };
};

export const onDropTree = (tree) => {
    return {
        type: actionTypes.LOAD_DROPPED_TREE,
        tree: tree
    };
};

export const fetchTreeFailed = () => {
    return {
        type: actionTypes.FETCH_TREE_FAILED
    };
};

export const onEditNode = (newNode, clickedNode, routeParams) => {
    const updatedNode = clickedNode;
    updatedNode.node.name = newNode;
    updatedNode.node.title = newNode;
    return {
        type: actionTypes.EDIT_NODE,
        newNode: [updatedNode.node],
        routeParams: routeParams,
        clickedNode: clickedNode
    }
}

export const setRouteParams = (routeParams) => {
    return {
        type: actionTypes.SET_ROUTE_PARAMS,
        routeParams: routeParams
    }
}
export const updateTreeRefArr = (newData,key) => {
    return {
        type: actionTypes.TREE_REF_ARRAY,
        reference: newData,
        key:key
    }
}
export const updateTreeDataKeyArr = (newData) => {
    return {
        type: actionTypes.UPDATE_KEYVALPAIR_ARRAY,
        data: newData
    }
}

export const expandCollapseVal = (val) => {
    return {
        type: actionTypes.UPDATE_EXPAND_COLLAPSE,
        expColVal: val
    }
} 

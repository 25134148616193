import { getURL } from '../../../../providers/configProvider';
import { requestPOST, requestGET } from '../../../../services/extendedService';

export const getReportSettings = () => {
  const url = getURL('deviceManagement', 'reportSettings', {'apiType':'DEVICE'});
  return requestGET(url);
}

export const generateReportData = (params, errorTile) => {
  let apiKey = (params.reportType === 'Sensors on Low Amp') ? 'lowAmpReportGenerate' : 'reportGenerate';
  const url = getURL('deviceManagement', apiKey, {'apiType':'DEVICE'});
  return requestPOST(url, params, {},{ validateSuccess: false, errorTile: errorTile});
}

export const getReportFile = (params, reqConfig) => {
  let apiKey = (params.reportType === 'Sensors on Low Amp') ? 'lowAmpReportDownload' : 'reportDownload';
  const url = getURL('deviceManagement', apiKey, { 'fileName': params.fileName.data, 'reportFormat' : params.reportFormat,'apiType': 'DEVICE' ,'separator':params.separator});
  return requestGET(url, reqConfig, { validateSuccess: false });
}
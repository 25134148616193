import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Loader from '../../../hoc/loader';
import BlankPage from '../../../hoc/blankPage';
import ModalWindow from '../../../hoc/modelWindow';

class StreetViewModal extends Component {

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <ModalWindow 
            show={true}
            size="xl"
            dialogClassName={"StreetViewModal"}
            title={<span><span className="popup-title">{formatMessage({ id: "mapview.title.streetview" })} <span class='fas'>&#xf0da;</span></span> <br />{this.props.streetViewLabel}</span>}
            onBtnClickHandler={this.onBtnClickHandlera}
            onHide={this.props.onCloseModal}
            modeldata={{
                content: <React.Fragment>
                    {this.props.isLodingStreet === undefined || this.props.isLodingStreet ? <Loader elementClass="tree-ajax-loader center-element" /> : null}
                    { (this.props.streetViewAvailable !== undefined && this.props.streetViewAvailable && !this.props.isLodingStreet) ?
                    <div id="streetView" style={{ position: "static !important", height: "500px"}}>
                    </div> : <BlankPage blankText={formatMessage({id:'mapview.streetavailable.empty'}) } /> }
                </React.Fragment>
            }}

            
            />
        )
    }
}

export default injectIntl(StreetViewModal);
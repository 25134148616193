import React, { Component, Fragment } from 'react';
import Loader from '../../../../hoc/loader';
import { Col } from 'react-bootstrap';
import { DeviceContext } from '../../details/provider/deviceProvider';
import ManageSummaryComponent from '../components/manageSummaryComponent';
import ManageSummaryActionComponent from '../components/manageSumaryActionComponent'

class ManageSummaryContainer extends Component {
    static contextType = DeviceContext;
    render() {
        let LoaderData = (this.context.state.loading) ? <div className="loader-wrap bg-transparent"><Loader elementClass="device-management-ajax-loader" /></div> : "";
        return (
            <Fragment>
                {LoaderData}
                <Col className="content-right mt-4 pad-rgt-lef" xs={12} md={12}>
                    <ManageSummaryActionComponent />
                    {
                        <ManageSummaryComponent summaryData={this.context.state.summaryData} loading={this.context.state.loading} />
                    }
                </Col>
            </Fragment>
        )
    }
}

export default ManageSummaryContainer;
import React, { Component, createContext,createRef } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { getAllDeviceTypes, getAllProfiles, getProfileById, deleteProfileById, saveProfile } from '../services/profileService';
import { FormattedMessage } from 'react-intl';
import TextField from '../../../../hoc/form/textField';
import { dnpTimeArrVal } from '../../../../utility/manageProfileUtility';
import { getCurrentUser } from '../../../userManagement/services/userService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { alphaNumericRegex, stringOnlyRegex, ipAddressRegex, numberOnlyRegex, negativeNumberRegex } from '../../../../filters/commonFunction';
export const ProfileContext = createContext()

class ProfilesProvider extends Component {
    state = {
        deviceTypeList: {
            options: [{ label: "Select", value: "Select" }],
            selected: "Select"
        },
        profilesList: {
            options: [],
            selected: {}
        },
        leftContentLoader: false,
        rightContentLoader: false,
        newProfile: false,
        profileDetails: {
            tabs: [],
            profileData: {},
        },
        message: {
            status: false,
            msg: "",
            type: ""
        },
        currentTab: "",
        profile: {
            selectedProfile: {},
            currentProfile: {}
        },
        formError: {},
        modal: {
            showModal: false,
            title: "",
            modalClose: "",
            modeldata: {},
            footer: []
        },
        finalProfileName: "",
        isProfileNameValid: true,
        tempDnptimeObj: {},
        firmwareList: {
            options: [{ label: "Select", value: "Select" }],
            selected: "Select"
        },
        hardwareList: {
            options: [{ label: "Select", value: "Select" }],
            selected: "Select"
        },
        fwList: [],
        hwList: [],
        multiPositionConductor: [
            { key: 'conductorDataP2', rule: 'conductorDataRulesP2', selected: false },
            { key: 'conductorDataP3', rule: 'conductorDataRulesP3', selected: false },
            { key: 'conductorDataP4', rule: 'conductorDataRulesP4', selected: false }
        ],
        userTemp: "",
        nohwRevision: false
    }
    newProfileNameRef = createRef(null);
    componentDidMount() {
        this.getTempValue();
        this.getDeviceTypes();
    }
    componentDidUpdate(){
        setTimeout(()=>{
            this.newProfileNameRef?.current?.focus();
        },100);
    }
    getTempValue = () => {
        getCurrentUser()
            .then(data => {
                this.setState({ userTemp: data.temperaturePreference });
            }).catch(error => {
            })
    }
    getDeviceTypes = () => {
        //method to set device type and call profiles based on default device type
        this.setState({ leftContentLoader: true }, () => {
            getAllDeviceTypes().then(data => {
                let newData = data.length !== 0 ? (data).sort() : [];
                let tmpArr = [{ label: "Select", value: "Select" }];
                newData.map(item => tmpArr.push({ label: item, value: item }));
                let selectedData = data.indexOf("MM3") !== -1 ? "MM3" : (data && data.length > 0) ? data[0] : 'Select';
                this.setState({
                    deviceTypeList: {
                        options: tmpArr,
                        selected: selectedData,
                    },
                    newProfile: selectedData === "Select",
                    nohwRevision: selectedData === "Select"
                }, () => {
                    this.getProfileList();
                })
            }).catch(error => {
                //display message as service error pending
                this.setState({ leftContentLoader: false });
            })
        });
    }
    getProfileList = (name) => {
        let selectedDeviceType = this.state.deviceTypeList.selected;
        getAllProfiles(selectedDeviceType).then(data => {
            if (data.length > 0) {
                let newData = data.length !== 0 ? data : [];
                let tmpArr = [];
                newData.map(item => tmpArr.push({ label: item.name, value: item.id, id: item.id, name: item.name }));
                if (tmpArr.length !== 0) {
                    if (typeof (name) === 'undefined' || name === '') {
                        //default selection of profile
                        this.setState({
                            profilesList: {
                                options: tmpArr,
                                selected: tmpArr[0]
                            },
                            leftContentLoader: false,
                            firmwareList: {
                                options: [{ label: "Select", value: "Select" }],
                                selected: "Select"
                            },
                            hardwareList: {
                                options: [{ label: "Select", value: "Select" }],
                                selected: "Select"
                            }
                        }, () => {
                            //call api to display right side pane based on selected profile
                            this.fetchProfile(tmpArr[0]);
                        });
                    }
                    else {
                        let newProfile = tmpArr.find(e => e.label === name);
                        this.setState({
                            profilesList: {
                                options: tmpArr,
                                selected: newProfile
                            },
                            leftContentLoader: false
                        }, () => {
                            //call api to display right side pane based on selected profile
                            this.fetchProfile(newProfile);
                        });
                    }
                }
            } else {
                this.setState({
                    leftContentLoader: false,
                    rightContentLoader: false,
                    profilesList: {
                        options: [],
                        selected: {}
                    },
                    profileDetails: {
                        tabs: [],
                        profileData: {},
                    },
                    profile: {
                        selectedProfile: {},
                        currentProfile: {}
                    },
                    finalProfileName: "",
                });
            }
        }).catch(error => {
            this.setState({ leftContentLoader: false });
        });
    }
    fetchProfile = (profile) => {
        this.setState({
            formError: {},
            rightContentLoader: true,
            // message: {
            //     status: false,
            //     msg: "",
            //     type:""
            // }
        }, () => {
            let obj = {
                profileId: profile && profile.id ? profile.id : "default",
                profileType: this.state.deviceTypeList.selected,
                existingProfileId: this.state.profilesList.selected && this.state.profilesList.selected.id !== "default" ? this.state.profilesList.selected.id : "",
                selectedFwVersion: this.state.firmwareList.selected !== "Select" ? this.state.firmwareList.selected : '',
                selectedHWRevision:this.state.hardwareList.selected !== "Select" ? this.state.hardwareList.selected : ""
            }
            getProfileById(obj).then(data => {
                this.setState({ rightContentLoader: false }, () => {
                    data = data ? data : {};
                    this.formProfileData(data, obj);
                });
            }).catch(error => {
                this.setState({
                    message: {
                        status: true,
                        msg: error.message,
                        type: "error"
                    }
                });
            })
        })
    }
    formProfileData = (data, obj) => {
        if (data.status === "FAIL") {
            let lValue = { ...this.state.profileDetails };
            lValue.tabs = [];
            this.setState({
                profileDetails: lValue,
                message: {
                    status: true,
                    msg: data.message,
                    type: "error"
                }
            });
        } else {
            if (this.state.newProfile || obj.profileId !== "default") {
            let zmList = obj.profileType === 'ZM1' ? (data.availableFirmwares && Object.keys(data.availableFirmwares).length > 0) ? data.availableFirmwares : {} : (data.availableHardwares && Object.keys(data.availableHardwares).length > 0) ? data.availableHardwares : {};
            let frArr = [{ label: "Select", value: "Select" }];
            let hwArr = [{ label: "Select", value: "Select" }];
            if (zmList && Object.keys(zmList).length > 0) {
                Object.keys(zmList).map(item => obj.profileType === 'ZM1' ? frArr.push({ label: item, value: item }) : hwArr.push({ label: item, value: item }))
            }
            if (obj.profileType === 'ZM1') {
                if (data.firmware) {
                    Object.values(zmList[data.firmware]).map(item => hwArr.push({ label: item, value: item }))
                } else if (this.state.firmwareList.selected !== "Select" && zmList[this.state.firmwareList.selected]) {
                    Object.values(zmList[this.state.firmwareList.selected]).map(item => hwArr.push({ label: item, value: item }))
                }
            } else {
                if (data.hwRevision) {
                    Object.values(zmList[data.hwRevision]).map(item => frArr.push({ label: item, value: item }))
                } else if (this.state.hardwareList.selected !== "Select" && zmList[this.state.hardwareList.selected]) {
                    Object.values(zmList[this.state.hardwareList.selected]).map(item => frArr.push({ label: item, value: item }))
                }
            }
            this.setState({
                firmwareList: {
                    options: frArr,
                    selected:data.firmware ? data.firmware : obj.selectedFwVersion !== "" ? obj.selectedFwVersion : "Select"
                }, hardwareList: {
                    options: hwArr,
                    selected:data.hwRevision ? data.hwRevision :obj.selectedHWRevision !== "" ? obj.selectedHWRevision : "Select"
                },
                fwList: zmList,
                hwList: zmList
            });

            if (Object.keys(data).length > 0) {
                let lData = { ...data };
                let lSelectData = [];
                switch (this.state.deviceTypeList.selected) {
                    case "MM3":
                    case "MM3ai":
                        lSelectData = ["cfciData", "nonCfciData", "logiData", "anomalyData"];
                    break;
                    case "UM3+":
                        lSelectData = lData['hwRevision'] || obj['selectedHWRevision'] ? lData['hwRevision'] === 'CU' || obj['selectedHWRevision'] === 'CU' ? ["faultDetectionMCP", "visualIndicatorMCP", "loggingMCP", "advanceSettingsMCP"] : ["deviceSettings", "conductorSettings"] : [];
                        if(lData['hwRevision'] !== 'CU' && obj['selectedHWRevision'] !== 'CU')
                        lData = data['umData'];
                        break;
                    case "UM1":
                        lSelectData = ["cfciDataUM1", "nonCfciDataUM1", "anomalyDataUM1", "networkDataUM1"];
                        break;
                    case "VC10":
                        lSelectData = ["faultDetectionMCP", "visualIndicatorMCP", "loggingMCP", "advanceSettingsMCP", 'controlMCP'];
                        break;
                    default:
                        lSelectData = ["zmCfciData", "zmDisturbanceAedData", "zmNetworkData"];
                        break;
                    }
                let lTabs = [{ label: <FormattedMessage id={`manage.profiles.info`} />, value: "Profile Name" }];
                for (let key in lData) {
                    if (lSelectData.includes(key) && lData[key] != null) {
                        lTabs.push({ label: <FormattedMessage id={`manage.profiles.${key}`} />, value: lSelectData[lSelectData.indexOf(key)] });
                    }
                }
                let lValue = { ...this.state.profileDetails };
                lValue.tabs = lTabs;

                let profileData = {
                    profileId: data.profileId,
                    profileName: data.profileName ?  data.profileName : this.state.profile.selectedProfile.profileName ? this.state.profile.selectedProfile.profileName : "",
                    description: data.description ?  data.description : "",
                    // profileName: ((data.profileId == 0) && data.hwRevision && data.hwRevision.length > 0 && (Object.keys($scope.HWRevision.selected).length > 0)) ? $scope.profileData.profileName : data.profileName,
                    // description: ((data.profileId == 0) && data.hwRevision && data.hwRevision.length > 0 && (Object.keys($scope.HWRevision.selected).length > 0)) ? $scope.profileData.description : data.description,
                    firmware: data.firmware,
                    hwRevision: data.hwRevision,
                    zmMode: (data.zmMode === null) ? null : (data.zmMode === "LATERAL") ? false : data.zmMode === 0 ? false : true,
                    //UM3+
                    conductorData: data.umData && data.umData.conductorSettings && data.umData.conductorSettings.conductorData ? data.umData.conductorSettings.conductorData : {},
                    conductorDataRules: data.umData && data.umData.conductorSettings && data.umData.conductorSettings.rules ? data.umData.conductorSettings.rules : {},
                    conductorDataP2: data.umData && data.umData.conductorSettings && data.umData.conductorSettings.conductorDataP2 ? data.umData.conductorSettings.conductorDataP2 : null,
                    conductorDataRulesP2: data.umData && data.umData.conductorSettings && data.umData.conductorSettings.rulesP2 ? data.umData.conductorSettings.rulesP2 : null,
                    conductorDataP3: data.umData && data.umData.conductorSettings && data.umData.conductorSettings.conductorDataP3 ? data.umData.conductorSettings.conductorDataP3 : null,
                    conductorDataRulesP3: data.umData && data.umData.conductorSettings && data.umData.conductorSettings.rulesP3 ? data.umData.conductorSettings.rulesP3 : null,
                    conductorDataP4: data.umData && data.umData.conductorSettings && data.umData.conductorSettings.conductorDataP4 ? data.umData.conductorSettings.conductorDataP4 : null,
                    conductorDataRulesP4: data.umData && data.umData.conductorSettings && data.umData.conductorSettings.rulesP4 ? data.umData.conductorSettings.rulesP4 : null,
                    deviceData: data.umData && data.umData.deviceSettings && data.umData.deviceSettings.deviceData ? data.umData.deviceSettings.deviceData : {},
                    deviceDataRules: data.umData && data.umData.deviceSettings && data.umData.deviceSettings.rules ? data.umData.deviceSettings.rules : {},
                    //UM3+ ends
                    zmCfci: data.zmCfciData && data.zmCfciData.zm ? data.zmCfciData.zm : {},
                    zmCfciRules: data.zmCfciData && data.zmCfciData.rules ? data.zmCfciData.rules : {},
                    zmAed: data.zmDisturbanceAedData && data.zmDisturbanceAedData.zm ? data.zmDisturbanceAedData.zm : {},
                    zmAedRules: data.zmDisturbanceAedData && data.zmDisturbanceAedData.rules ? data.zmDisturbanceAedData.rules : {},
                    zmNetwork: data.zmNetworkData && data.zmNetworkData.zm ? data.zmNetworkData.zm : {},
                    zmNetworkRules: data.zmNetworkData && data.zmNetworkData.rules ? data.zmNetworkData.rules : {},
                    logi: data.logiData && data.logiData.logi ? data.logiData.logi : {},
                    logiRules: data.logiData && data.logiData.rules ? data.logiData.rules : {},
                    waveform: data.logiData && data.waveformData.waveform ? data.waveformData.waveform : {},
                    //UM1 starts here
                    cfci: data.cfciData && data.cfciData.cfci ? data.cfciData.cfci :
                        data.cfciDataUM1 && data.cfciDataUM1.cfci ? data.cfciDataUM1.cfci : {},
                    cfciRules: data.cfciData && data.cfciData.rules ? data.cfciData.rules :
                        data.cfciDataUM1 && data.cfciDataUM1.rules ? data.cfciDataUM1.rules : {},
                    nonCfci: data.nonCfciData && data.nonCfciData.nonCfci ? data.nonCfciData.nonCfci :
                        data.nonCfciDataUM1 && data.nonCfciDataUM1.nonCfci ? data.nonCfciDataUM1.nonCfci : {},
                    nonCfciRules: data.nonCfciData && data.nonCfciData.rules ? data.nonCfciData.rules :
                        data.nonCfciDataUM1 && data.nonCfciDataUM1.rules ? data.nonCfciDataUM1.rules : {},
                    anomaly: data.anomalyData && data.anomalyData.anomaly ? data.anomalyData.anomaly :
                        data.anomalyDataUM1 && data.anomalyDataUM1.anomaly ? data.anomalyDataUM1.anomaly : {},
                    anomalyRules: data.anomalyData && data.anomalyData.rules ? data.anomalyData.rules :
                        data.anomalyDataUM1 && data.anomalyDataUM1.rules ? data.anomalyDataUM1.rules : {},
                    um1Network: data.networkDataUM1 && data.networkDataUM1.um1Network ? data.networkDataUM1.um1Network : {},
                    um1NetworkRules: data.networkDataUM1 && data.networkDataUM1.rules ? data.networkDataUM1.rules : {},
                    //UM1 ends here

                    //vc10 and UM3+ compact start here
                    //faultDetection Profile Tab
                    faultDetection: data.faultDetectionMCP && data.faultDetectionMCP.faultDetection ? data.faultDetectionMCP.faultDetection : {},
                    faultDetectionRules: data.faultDetectionMCP && data.faultDetectionMCP.rules ? data.faultDetectionMCP.rules : {},
                    //Visual Indicator Tab
                    visualIndicator: data.visualIndicatorMCP && data.visualIndicatorMCP.visualIndicator ? data.visualIndicatorMCP.visualIndicator : {},
                    visualIndicatorRules: data.visualIndicatorMCP && data.visualIndicatorMCP.rules ? data.visualIndicatorMCP.rules : {},
                    //Logging Tab
                    logging: data.loggingMCP && data.loggingMCP.logging ? data.loggingMCP.logging : {},
                    loggingRules: data.loggingMCP && data.loggingMCP.rules ? data.loggingMCP.rules : {},
                    //vc10 - Control Tab
                    control: data.controlMCP && data.controlMCP.control ? data.controlMCP.control : {},
                    controlRules: data.controlMCP && data.controlMCP.rules ? data.controlMCP.rules : {},
                    //Advanced Setting Tab
                    advanceSettings: data.advanceSettingsMCP && data.advanceSettingsMCP.advanceSettings ? data.advanceSettingsMCP.advanceSettings : {},
                    advanceSettingsRules: data.advanceSettingsMCP && data.advanceSettingsMCP.rules ? data.advanceSettingsMCP.rules : {}
                    //vc10 and UM3+ compact ends here

                };
                this.parseProfileData(profileData);
                let profile = {
                    selectedProfile: profileData,
                    currentProfile: JSON.parse(JSON.stringify(profileData)),
                }
                let sampObj = {};
                Object.entries(profileData.nonCfci).forEach(([key, value]) => {
                    if (["DnpDSTTimeOffset", "DnpUTCTimeOffset", "LocalTimeOffset"].includes(key)) {
                        sampObj[key] = [this.setDnptime(value, "HH"), this.setDnptime(value, "MM")];
                    }
                })
                let lmultiPositionConductor = [...this.state.multiPositionConductor];
                if (profileData.conductorData && profileData.conductorDataP2) {

                    lmultiPositionConductor.forEach((e, indx) => {
                        if (JSON.stringify(lData["conductorSettings"]["conductorData"]) === JSON.stringify(lData["conductorSettings"][e.key])) {
                            e.selected = true;
                        } else {
                            e.selected = false;
                        }
                    })
                }
                
                this.setState({ profileDetails: lValue, currentTab: "Profile Name", profile: profile, finalProfileName: JSON.parse(JSON.stringify(profileData.profileName)), tempDnptimeObj: sampObj, multiPositionConductor: lmultiPositionConductor},
                    () => {
                        if (!(["MM3","MM3ai","UM1"].includes(this.state.deviceTypeList.selected)) && this.state.hardwareList.selected === "Select") {
                            this.disableEnableTab(true);
                        }
                    });
                // }
                }
            }
        }
    }
    setDnptime = (dnptime, key) => {
        var strVal = '' + dnptime;
        var splitSign = [];
        if ((strVal.indexOf('-') !== -1) || (strVal.indexOf('+') !== -1)) {
            splitSign[0] = strVal.substr(0, 1);
            splitSign[1] = strVal.substr(1, strVal.length - 1);
        }
        var num = (splitSign && (splitSign.length > 0)) ? this.pad_with_zeroes(splitSign[1], 4) : this.pad_with_zeroes(strVal, 4);
        num = num.match(/[\s\S]{1,2}/g) || [];
        return (key === 'HH') ? ((typeof (splitSign[0]) === "undefined") ? '' : splitSign[0]) + num[0] : num[1];
    };
    pad_with_zeroes = (number, length) => {
        var my_string = '' + number;
        while (my_string.length < length) {
            my_string = '0' + my_string;
        }
        return my_string;
    };
    onchangeDeviceType = (e) => {
        if (typeof (e) !== undefined) {
            let currDeviceTypeList = { ...this.state.deviceTypeList };
            currDeviceTypeList.selected = e;
            this.setState({
                nohwRevision: e === "Select",
                deviceTypeList: currDeviceTypeList,
                leftContentLoader: true,
                newProfile: e === "Select",
                message: {
                    status: false,
                    msg: "",
                    type: ""
                },
                profile: {
                    ...this.state.profile,
                    selectedProfile: {
                        ...this.state.profile.selectedProfile,
                        description: ""
                    }
                }
            }, () => {
                this.getProfileList();
            });
        }
    }

    continueFetchProfile = (pCurrentProfile) => {
        let lModal = { ...this.state.modal };
        let lProfile = {...this.state.profile};
        lModal.showModal = false;
        lProfile.selectedProfile.description = "";
        lProfile.selectedProfile.profileName = ""
        this.setState({
            nohwRevision: false,
            modal: lModal,
            message: {
                status: false,
                msg: "",
                type: ""
            },
           profile:lProfile,
           firmwareList: {
            options: [{ label: "Select", value: "Select" }],
            selected: "Select"
        },
        hardwareList: {
            options: [{ label: "Select", value: "Select" }],
            selected: "Select"
        }
        }, () => {
            let currProfileList = { ...this.state.profilesList };
            if (this.state.newProfile) {
                currProfileList.options.splice(0, 1);
            }
            currProfileList.selected = pCurrentProfile;
            this.setState({
                profilesList: currProfileList,
                newProfile: false
            }, () => {
                this.fetchProfile(pCurrentProfile);
            });
        });
    }
    onProfileClick = (pCurrentProfile) => {
        if (pCurrentProfile.id !== this.state.profilesList.selected.id) {
            if ((JSON.stringify(this.state.profile.selectedProfile)) === (JSON.stringify(this.state.profile.currentProfile))) {
                //no popup if no changes in profiles
                this.continueFetchProfile(pCurrentProfile);
            } else {
                this.setState({
                    modal: {
                        showModal: true,
                        title: <FormattedMessage id={`manage.profiles.changeprofile`} />,
                        modalClose: this.modalClose,
                        modeldata: {
                            content: <div><FormattedMessage id="manage.profiles.changeprofilecontent" /></div>
                        },
                        footer: [
                            { className: "modalCancelBtn", name: <FormattedMessage id='manage.profiles.cancel' />, value: "modalClose" },
                            { className: "modalSaveBtn", name: <FormattedMessage id='manage.profiles.ok' />, value: "modalSave" }
                        ],
                        currentProfile: pCurrentProfile
                    }
                });
            }
        }
    }
    modalClose = () => {
        let lModal = { ...this.state.modal }
        lModal.showModal = false
        this.setState({
            modal: lModal
        })
    }

    createNewProfile = () => {
        if ((JSON.stringify(this.state.profile.selectedProfile)) !== (JSON.stringify(this.state.profile.currentProfile))) {
            this.setState({
                modal: {
                    showModal: true,
                    title: <FormattedMessage id={`manage.profiles.createprofile`} />,
                    modalClose: this.modalClose,
                    modeldata: {
                        content: <div><FormattedMessage id="manage.profiles.createprofilecontent" /></div>
                    },
                    footer: [
                        { className: "modalSaveBtn", name: <FormattedMessage id='manage.profiles.create' />, value: "createprofile" }
                    ]
                }
            });
        } else {
            this.continueCreateNewProfile();
        }
    }
    continueCreateNewProfile = () => {
        this.modalClose();
        let newProfileObj = { value: -1, label: 'New profile', id: 'default', name: 'New profile' };
        let currentProfileList = { ...this.state.profilesList };
        currentProfileList.options.splice(0, 0, newProfileObj);
        currentProfileList.selected = newProfileObj;
        this.setState({
            profilesList: currentProfileList,
            newProfile: true,
            message: {
                status: false,
                msg: "",
                type: ""
            },
            firmwareList: {
                options: [{ label: "Select", value: "Select" }],
                selected: "Select"
            },
            hardwareList: {
                options: [{ label: "Select", value: "Select" }],
                selected: "Select"
            },
            profile:{
                selectedProfile:{},
                currentProfile:{}
            }
        }, () => {
            //call new profiles info
            this.fetchProfile();
        })
    }
    /**
        Parse Data and change all the boolean/number represented in numbers
        **/
    parseProfileData = function (data, isObj) {
        for (var i in data) {
            var item = data[i];
            if (typeof (item) === "string") {
                if (item === "true" || item === "false") {
                    data[i] = item === "true" ? true : false;
                } else if (item === "") {
                    data[i] = item;
                } else if (!isNaN(Number(item))) {
                    // Convert string to integer except parent obj(profileName)
                    // data[i] = isObj ? Number(item) : item;
                }
            } else if (typeof (item) === "object") {
                this.parseProfileData(data[i], true);
            }
        }
    };

    handleTabSelect = (crntVal) => {
        this.setState({
            currentTab: crntVal
        })
    }
    handleOnchangeForm = (from, field, value, child, identifier) => {
        let lProfile = { ...this.state.profile };
        let lFinalProfileName;
        if (from === "general") {
            lProfile.selectedProfile[field] = value;
            if (field === "profileName") {
                lFinalProfileName = value.trim();
            }
            this.setState({ profile: lProfile, finalProfileName: lFinalProfileName}, () => {
                if (field === "profileName") {
                    this.profileNameValidate(field, this.state.finalProfileName);
                }
            });
        } else if (from === "cfci" || from === "zmNetwork" || ((["UM1", "VC10", "UM3+"].includes(this.state.deviceTypeList.selected)) && (["anomaly", "um1Network", "nonCfci", "logging", "advanceSettings", "control", "faultDetection", "visualIndicator"].includes(from) ))) {
            !child ? lProfile.selectedProfile[from][field] = value : lProfile.selectedProfile[from][child][field] = value;
            
            if(from === 'control'){ //checking this condition for vc10 control tab
                if(["varControlOperatingMode", "varControlEnable"].includes(field)){
                    //Required fields need to clear error while changing 'VAR Control Operating Mode' / 'VAR Control Enable' - start
                    let reqFieldTovalidateFalse =  ['scheduleToggle', 'voltageSetPoint', 'voltageBandwidth_fspm', 'evaluationDelay', 'operatingDelay', 'voltageBandwidth_am', 'highVoltageSP', 'lowVoltageSP', 'analysisWindow']
                    let allformErrFields =  Object.assign({}, this.state.formError);
                    reqFieldTovalidateFalse.forEach(item => allformErrFields[item] ? allformErrFields[item] = false : allformErrFields[item])
                    this.setState({ formError: allformErrFields })
                    //Required field need to error false while changing 'VAR Control Operating Mode' / 'VAR Control Enable' - end

                    if(field === "varControlOperatingMode"){ //if user change only 'VAR Control Operating Mode' dropdown reseting all the points field to default value except 'varControlOperatingMode'
                        lProfile.selectedProfile.control = JSON.parse(JSON.stringify({...lProfile.currentProfile.control, varControlOperatingMode: value, varControlEnable: lProfile.selectedProfile.control.varControlEnable}))
                    } else if(field === "varControlEnable"){ // if user change only 'VAR Control Enable' toggle reseting all the points field to default value except 'varControlEnable'
                        lProfile.selectedProfile.control = JSON.parse(JSON.stringify({...lProfile.currentProfile.control, varControlEnable: value}))
                    }
                }
            }

            this.setState({ profile: lProfile }, () => {
                if (value !== true && value !== false)
                    this.updateFormError(field, from, value, child)
            });
            
        } else if (from === "conductorData") {
            lProfile.selectedProfile[from][field] = value;
            this.setState({ profile: lProfile }, () => {
                this.updateFormError(field, from, value, child);
                if(lProfile.selectedProfile.conductorDataP2 !== null && lProfile.selectedProfile.conductorDataP3 !== null && lProfile.selectedProfile.conductorDataP3 !== null) {
                    this.getCopyFromPosition1(field);
                }
            });
        }
        else {
            let tObj = { ...this.state.tempDnptimeObj };
            if (!dnpTimeArrVal.includes(field)) {
                if (field === "zmMode") {
                    lProfile.selectedProfile[field] = value;
                } else {
                    if ((field === "At-Doc-threshold" || field === "At-Disturbance-Detector") && !value) {
                        let lProfile = { ...this.state.profile };
                        let changedDetectorVal = lProfile.selectedProfile.zmAed["At-Disturbance-Detector"];
                        lProfile.selectedProfile.zmAed = JSON.parse(JSON.stringify(lProfile.currentProfile.zmAed));
                        lProfile.selectedProfile.zmAed["At-Disturbance-Detector"] = changedDetectorVal;
                        lProfile.selectedProfile.zmAed["At-Doc-threshold"] = false;
                        if (field === "At-Disturbance-Detector") {
                            lProfile.selectedProfile.zmAed[field] = false;
                        }
                        let { 'At-Floor': At_Floor, "At-History": At_History, 'At-Percent': At_Percent, ...rest } = this.state.formError;
                        this.setState({ formError: rest })
                    } else {
                        lProfile.selectedProfile[from][field] = value;
                    }
                }
            } else {
                identifier === "HH" ? tObj[field][0] = value : tObj[field][1] = value;
                lProfile.selectedProfile[from][field] = tObj[field][0] + tObj[field][1];
            }
            this.setState({ profile: lProfile, tempDnptimeObj: tObj }, () => {
                if (value !== true && value !== false && !dnpTimeArrVal.includes(field) && field !== "DnpStaleStackTime") {
                    this.updateFormError(field, from, value, child);
                } else if (dnpTimeArrVal.includes(field)) {
                    let tVal = tObj[field][0] + tObj[field][1];
                    let dataRules = this.state.profile.selectedProfile[from + "Rules"];
                    this.checkTimeOffset(Number(dataRules[field].min), Number(dataRules[field].max), tVal, field);
                } else if (field === "DnpStaleStackTime") {
                    this.dnpStaleStackTimeValidate(value, field,from);
                }
            }
            );
        }
    }
    dnpStaleStackTimeValidate = (val, key,from) => {
        let lFormError = { ...this.state.formError }
        if (((parseInt(val,10) < 3600) && (parseInt(val,10) !== 0)) || parseInt(val,10) > this.state.profile.selectedProfile[from + "Rules"][key].max) {
            lFormError[key] = true;
        } else if(val === ""){
            lFormError[key] = true;
        }else {
            lFormError[key] = false;
        }
        this.setState({ formError: lFormError });
    }
    getCopyFromPosition1 = (field) => {
        let lmultiPositionConductor = [...this.state.multiPositionConductor];
        let lProfile = { ...this.state.profile };
        //let lFormError = { ...this.state.formError };
        lmultiPositionConductor.forEach(e => {
            if (e.selected) {
                lProfile.selectedProfile[e.key][field] = JSON.parse(JSON.stringify(lProfile.selectedProfile["conductorData"][field]));
                lProfile.selectedProfile[e.rule][field] = JSON.parse(JSON.stringify(lProfile.selectedProfile["conductorDataRules"][field]));
                setTimeout(() => {
                    this.updateFormError(field, e.key, lProfile.selectedProfile[e.key][field]);
                }, 0);
            }
        })
        this.setState({ profile: lProfile });
    }
    checkTimeOffset = (min, max, value, key) => {
        let lFormError = { ...this.state.formError }
        // Checking - ve range in min value
        if ((parseInt(value,10) >= parseInt(min,10)) && (parseInt(value,10) <= parseInt(max,10)) && (value !== '') && Number.isInteger(parseInt(value,10))) {
            let minBuffer = (min.toString().substring(0, 1) === "-") ? 1 : 0;
            let min_hrs = parseInt(min.toString().substring(0, (2 + minBuffer)),10);
            let max_hrs = parseInt(max.toString().substring(0, 2),10);

            // Checking - ve range in entered value
            let valBuffer = (value.substring(0, 1) === "-") ? 1 : 0;
            let hrs = parseInt(value.substring(0, (2 + valBuffer)),10);
            let mins = parseInt(value.substring((2 + valBuffer), (4 + valBuffer)),10);
            let valueLength = 4 + valBuffer;

            // Checking entered value length if exceed 4 or 5 

            if ((value.length === valueLength) || ((hrs >= min_hrs) && (hrs <= max_hrs))) {
                if (((mins >= parseInt("00",10)) && (mins <= parseInt("59",10))) || (hrs === parseInt("12",10) && mins === parseInt("00",10))) {
                    lFormError[key] = false;
                    this.setState({ formError: lFormError });
                } else {
                    lFormError[key] = true;
                    this.setState({ formError: lFormError });
                }
            } else {
                lFormError[key] = true;
                this.setState({ formError: lFormError });
            }
        } else {
            lFormError[key] = true;
            this.setState({ formError: lFormError });
        }
    };

    updateFormError = (field, from, value, child) => {
        let numOnlyRegex = numberOnlyRegex;
        let dataFrom = this.state.profile.selectedProfile[from];
        let dataRules, lDataArr, lField;
        if (from === "conductorData" || from === "conductorDataP2" || from === "conductorDataP3" || from === "conductorDataP4") {
            lDataArr = from.split("conductorData");
            dataRules = lDataArr[1] ? this.state.profile.selectedProfile["conductorDataRules" + lDataArr[1]] : this.state.profile.selectedProfile[from + "Rules"];
            lField = lDataArr[1] ? field + lDataArr[1] : field;
        }
        else {
            dataRules = this.state.profile.selectedProfile[from + "Rules"];
            lField = field;
        }
        if(field === "LogITempThreshold1" || field === "LogITempThreshold2" || field === "AuditLogInterval") {
            numOnlyRegex = negativeNumberRegex;
        } else if( [ "voltageBandwidth_am", "highVoltageSP", "lowVoltageSP", "voltageBandwidth_fspm"].includes(field) ) numOnlyRegex = /^\d+([.]\d)?$/; // allow decial points with single digit after decimal
        let isRulesAvail = dataRules !== null ? dataRules : null;
        let lFormError = child ? (isRulesAvail !== null && isRulesAvail[child][field].min !== null && Number(dataRules[child][field].min) > Number(dataFrom[child][field]))
            || (isRulesAvail !== null && isRulesAvail[child][field].max !== null && Number(dataRules[child][field].max) < Number(dataFrom[child][field]))
            || dataFrom[child][field] === ""
            || !numOnlyRegex.test(value === "true" || value === "false" || (from === "advanceSettings" && field === "timezone") ? "" : value)
            ? { ...this.state.formError, [lField]: true } : { ...this.state.formError, [lField]: false } :
            (dataRules[field].min != null && dataRules[field].max != null) ?
                (isRulesAvail !== null && Number(dataRules[field].min) > Number(dataFrom[field]))
                    || (isRulesAvail !== null && Number(dataRules[field].max) < Number(dataFrom[field]))
                    || dataFrom[field] === ""
                    || !numOnlyRegex.test(value === "true" || value === "false" ? "" : value)
                    ? { ...this.state.formError, [lField]: true } : { ...this.state.formError, [lField]: false }
                : { ...this.state.formError, [lField]: false }
        if (["brokerIPAddress","pdpAddress","pdpType"].includes(field)) {
            dataFrom[child][field].trim() === "" ? lFormError = { ...this.state.formError, [lField]: true } : delete lFormError[lField]
        }else if(["apn","commonName"].includes(field)){
            delete lFormError[lField]
        }
        // Text field validations for ZM1 network tab starts
        if (from === "zmNetwork") {
            switch (field) {
                case 'Tls-Commom-Name':
                    if (value && (!stringOnlyRegex.test(value) || value.length > 30)) lFormError = { ...this.state.formError, [lField]: true }
                    else lFormError = { ...this.state.formError, [lField]: false }
                    break;
                case 'Tls-Configuration':
                case "Access-Point-Name":
                case 'AGW1-Apn':
                case 'AGW2-Apn':
                    if (value && value.length > 64) lFormError = { ...this.state.formError, [lField]: true }
                    else lFormError = { ...this.state.formError, [lField]: false }
                    break;
                case 'AGW1-Ip':
                case 'AGW2-Ip':
                case 'DNP Master IP Address':
                    if (value && (!ipAddressRegex.test(value) || value.length < 7 || value.length > 15)) lFormError = { ...this.state.formError, [lField]: true }
                    else lFormError = { ...this.state.formError, [lField]: false }
                    break;
                default:
                    if(!value) lFormError = { ...this.state.formError, [lField]: false }    // ZM1 network : Fields can be empty. If the field is left empty, Ample shall not send that settings over to ZM1
                    break;
            }
        }
        // Text field validations for ZM1 network tab ends
        this.setState({ formError: lFormError });
    }

    confirmDeleteProfile = () => {
        this.setState({
            modal: {
                showModal: true,
                title: <FormattedMessage id={`manage.profiles.delete`} />,
                modalClose: this.modalClose,
                modeldata: {
                    content: <div><FormattedMessage id="manage.profiles.deleteprofilecontent" /></div>
                },
                footer: [
                    { className: "modalCancelBtn", name: <FormattedMessage id='manage.profiles.cancel' />, value: "modalClose" },
                    { className: "modalSaveBtn", name: <FormattedMessage id='manage.profiles.ok' />, value: "deleteProfile" }
                ]
            }
        });
    }
    deleteProfile = () => {
        let lId = this.state.profile.selectedProfile && this.state.profile.selectedProfile.profileId ? this.state.profile.selectedProfile.profileId : null;
        this.setState({
            leftContentLoader: true,
            rightContentLoader: true,
            modal: {
                showModal: false,
            },
            message: {
                status: false,
                msg: "",
                type: ""
            }
        }, () => {
            deleteProfileById(lId, this.state.profile.selectedProfile).then(data => {
                if (data) {
                    this.setState({
                        modal: {
                            showModal: true,
                            title: <div><FontAwesomeIcon icon={faExclamationTriangle} className="text-danger"/> <FormattedMessage id={`manage.profiles.delete`} /></div>,
                            modalClose: this.modalClose,
                            modeldata: {
                                content: <div> {data.status === "OK" ? <FormattedMessage id="manage.profiles.deletesuccesscontent" /> : data.message }</div>
                            },
                            footer: [
                                { className: "modalCancelBtn", name: <FormattedMessage id='manage.profiles.close' />, value: "modalClose" }
                            ]
                        },
                        leftContentLoader: data.status === "OK",
                        rightContentLoader: data.status === "OK",
                    });
                    if(data.status === "OK")
                    this.getProfileList();
                }
            }).catch(error => {
                this.setState({
                    message: {
                        status: true,
                        msg: error.message,
                        type: "error"
                    }
                });
            })
        })
    }

    saveProfile = (pInfo) => {
        this.continueSaveProfileModal();
        this.finalProfileNameChangefunc(this.state.profile.selectedProfile.profileName); 
    }
    continueSaveProfileModal = () =>{
        this.setState({
            finalProfileName: this.state.profile.selectedProfile.profileName
        }, () => {
            this.saveProfileModal();
        });
    }
    saveProfileModal = () => {
        if (!Object.values(this.state.formError).includes(true)) {
            this.setState({
                modal: {
                    showModal: true,
                    title: <FormattedMessage id={`manage.profiles.saveprofileas`} />,
                    modalClose: this.modalClose,
                    modeldata: {
                        content: <div>
                            <div className="col-12 m-0 p-0">
                                <span className="col-4 float-left">
                                    <FormattedMessage id="manage.profiles.saveprofileasprofilename" />
                                </span>
                                <span className="col-8 float-left">
                                    <TextField
                                        refVal = {this.newProfileNameRef}
                                        type="text"
                                        isDisabled={false}
                                        value={this.state.finalProfileName ? this.state.finalProfileName : ""}
                                        field={""}
                                        handleOnchange={(e) => this.finalProfileNameChangefunc(e)}
                                        className={this.state.finalProfileName === "" || !this.state.isProfileNameValid? "form-err-border-red sz-49" : "sz-49"}
                                    />
                                </span>
                            </div>
                            {!this.state.isProfileNameValid && <div className="col-12 d-flex flex-row-reverse inline-error danger-color">
                                {this.state.finalProfileName.trim() === "" ? <FormattedMessage id="manage.profiles.profileNameEmpty" /> : <FormattedMessage id="manage.profiles.profilenameerror" />} 
                            </div>}
                        </div>
                    },
                    footer: [
                        { className: "modalCancelBtn", name: <FormattedMessage id='manage.profiles.cancel' />, value: "modalClose" },
                        { className: "modalSaveBtn", disabled: !this.state.isProfileNameValid, name: <FormattedMessage id='manage.profiles.create' />, value: "create" }
                    ]
                }

                ,
                message: {
                    status: false,
                    msg: "",
                    type: ""
                }
            });
        } else {
            if (this.state.formError.profileName) {
                this.setState({
                    message: {
                        status: true,
                        msg: <FormattedMessage id='manage.profiles.profilenameerror' />,
                        type: "error"
                    }
                });
            } else {
                this.setState({
                    message: {
                        status: true,
                        msg: <FormattedMessage id='manage.profiles.pleasefixerrorform' />,
                        type: "error"
                    }
                });
            }
        }
    }
    finalProfileNameChangefunc = (e) => {
        let re = alphaNumericRegex, value = e?.trim(), isProfileNameValid = true;
        if (value?.length < 2 || value?.length > 64 || !re.test(e) || value?.trim() === '')
            isProfileNameValid= false;
        this.setState({ finalProfileName: e, isProfileNameValid: isProfileNameValid }, () => {  this.saveProfileModal();  })
    }

    continueSaveProfile = () => {
        if (this.state.finalProfileName && this.state.isProfileNameValid) {
            this.modalClose();
            this.setState({
                leftContentLoader: true,
                rightContentLoader: true,
            }, () => {
                let finalData = this.prepareSaveFormat(JSON.parse(JSON.stringify(this.state.profile.selectedProfile)));
                finalData.profileName = this.state.finalProfileName;
                //sending a new property "deviceType" which is mandatory for mm3ai and optional for other device types.
                finalData.deviceType = this.state.deviceTypeList.selected;
                let profileType = this.state.deviceTypeList.selected;
                saveProfile(profileType, finalData).then(data => {
                    if (data !== undefined) {
                        if (data.status !== "FAIL") {
                            this.setState({
                                newProfile: false,
                                message: {
                                    status: true,
                                    msg: <FormattedMessage id='manage.profiles.profilesuccess' />,
                                    type: "success"
                                },
                            }, () => {
                                this.modalClose();
                                this.getProfileList(this.state.finalProfileName);
                            })
                        } else {
                            this.modalClose();
                            this.setState({
                                message: {
                                    status: true,
                                    msg: data.message,
                                    type: "error"
                                },
                                leftContentLoader: false,
                                rightContentLoader: false,
                            });
                        }
                    } else {
                        this.setState({ leftContentLoader: false, rightContentLoader: false });
                    }
                }).catch(error => {
                    this.setState({
                        message: {
                            status: true,
                            msg: error.message,
                            type: "error"
                        },
                        leftContentLoader: false,
                        rightContentLoader: false,
                    });
                })
            })
        }
    }

    prepareSaveFormat = (pData) => {
        let lData = {
            "profileId": pData.profileId,
            "profileName": pData.profileName,
            "description": pData.description,
        };
        if (!(["MM3","MM3ai","UM1"].includes(this.state.deviceTypeList.selected))) {
            lData["firmware"] = this.state.firmwareList.selected ? this.state.firmwareList.selected : null;
            lData["hwRevision"] = this.state.hardwareList.selected ? this.state.hardwareList.selected : null;
            lData["zmMode"] = (pData.zmMode == null) ? null : (pData.zmMode) ? 1 : 0;
        }
        switch((this.state.deviceTypeList.selected)) {
            case "ZM1":
                lData["zmCfciData"] = {
                    "zm": pData.zmCfci,
                    "rules": pData.zmCfciRules
                };
                lData["zmDisturbanceAedData"] = {
                    "zm": pData.zmAed,
                    "rules": pData.zmAedRules
                };
                lData["zmNetworkData"] = {
                    "zm": pData.zmNetwork,
                    "rules": pData.zmNetworkRules
                };
              break;

              case "UM3+":
                if (lData.hwRevision === 'CU') {
                    lData["faultDetectionMCP"] = {
                        "faultDetection": pData.faultDetection,
                        "rules": pData.faultDetectionRules
                    };
                    lData["visualIndicatorMCP"] = {
                        "visualIndicator": pData.visualIndicator,
                        "rules": pData.visualIndicatorRules
                    };
                    lData["advanceSettingsMCP"] = {
                        "advanceSettings": pData.advanceSettings,
                        "rules": pData.advanceSettingsRules
                    };
                    lData["loggingMCP"] = {
                        "logging": pData.logging,
                        "rules": pData.loggingRules
                    };
                } else {
                    lData["umData"] = {
                        "conductorSettings": {
                            "conductorData": pData.conductorData,
                            "rules": pData.conductorDataRules,
                            "conductorDataP2": pData.conductorDataP2,
                            "rulesP2": pData.conductorDataRulesP2,
                            "conductorDataP3": pData.conductorDataP3,
                            "rulesP3": pData.conductorDataRulesP3,
                            "conductorDataP4": pData.conductorDataP4,
                            "rulesP4": pData.conductorDataRulesP4,
                        },
                        "deviceSettings": {
                            "deviceData": pData.deviceData,
                            "rules": pData.deviceDataRules
                        }
                    }
                }
                break;

              case "UM1":
                lData["cfciDataUM1"] = {
                    "cfci": pData.cfci,
                    "rules": pData.cfciRules
                };
                lData["nonCfciDataUM1"] = {
                    "nonCfci": pData.nonCfci,
                    "rules": pData.nonCfciRules
                };
                lData["networkDataUM1"] = {
                    "um1Network": pData.um1Network,
                    "rules": pData.um1NetworkRules
                };
                lData["anomalyDataUM1"] = {
                    "anomaly": pData.anomaly,
                    "rules": pData.anomalyRules
                };
                break;

              case "VC10":
                lData["faultDetectionMCP"] = {
                    "faultDetection": pData.faultDetection,
                    "rules": pData.faultDetectionRules
                };
                lData["visualIndicatorMCP"] = {
                    "visualIndicator": pData.visualIndicator,
                    "rules": pData.visualIndicatorRules
                };
                lData["controlMCP"] = {
                    "control": pData.control,
                    "rules": pData.controlRules
                };
                lData["advanceSettingsMCP"] = {
                    "advanceSettings": pData.advanceSettings,
                    "rules": pData.advanceSettingsRules
                };
                lData["loggingMCP"] = {
                    "logging": pData.logging,
                    "rules": pData.loggingRules
                };
                break;

              default:
                lData["waveformData"] = {
                    "waveform": pData.waveform
                };
                lData["logiData"] = {
                    "logi": pData.logi,
                    "rules": pData.logiRules
                };
                lData["cfciData"] = {
                    "cfci": pData.cfci,
                    "rules": pData.cfciRules
                };
                lData["nonCfciData"] = {
                    "nonCfci": pData.nonCfci,
                    "rules": pData.nonCfciRules
                };
                lData["anomalyData"] = {
                    "anomaly": pData.anomaly,
                    "rules": pData.anomalyRules
                };
                break;
        }
        return lData;
    }

    profileNameValidate = function (field, value) {
        var re = alphaNumericRegex;
        let formErr = { ...this.state.formError };
        if (value === '' || value.length < 2 || value.length > 64 || !re.test(value)) {
            formErr[field] = true;
        } else {
            formErr[field] = false;
        }
        this.setState({ formError: formErr })
    };

    onChangeSelect = (e, crrSelect) => {
        let lcurrentProfile = JSON.parse(JSON.stringify(this.state.profile.selectedProfile));
        let lselectedProfile = JSON.parse(JSON.stringify(this.state.profile.currentProfile));
        delete lcurrentProfile.profileName;
        delete lcurrentProfile.description;
        delete lselectedProfile.profileName;
        delete lselectedProfile.description;
       // if ((JSON.stringify(lcurrentProfile)) === (JSON.stringify(lselectedProfile))) {
      
        if ((JSON.stringify(lcurrentProfile)) === (JSON.stringify(lselectedProfile)) || crrSelect === "firmwareList" || crrSelect === "hardwareList" || e ==="Select") {
           this.continueonChangeSelect(e, crrSelect);
        } else {
            this.setState({
                modal: {
                    showModal: true,
                    title: <FormattedMessage id={`manage.profiles.changeprofile`} />,
                    modalClose: this.modalClose,
                    modeldata: {
                        content: <div><FormattedMessage id="manage.profiles.changeprofilecontent" /></div>
                    },
                    footer: [
                        { className: "modalCancelBtn", name: <FormattedMessage id='manage.profiles.cancel' />, value: "modalClose" },
                        { className: "modalSaveBtn", name: <FormattedMessage id='manage.profiles.ok' />, value: "onChangeSelect" }
                    ],
                    onChangeSelectObj: {e:e,crrSelect:crrSelect}
                }
            });
        }  
    }

    continueonChangeSelect = (e, crrSelect) => {
        const { deviceTypeList, fwList, hwList, firmwareList, hardwareList } = this.state;
        let selectArr = [{ label: "Select", value: "Select" }];
        if (deviceTypeList.selected === 'ZM1') { // Select FW version first and HW revision next for ZM1
            if (crrSelect === "firmwareList") {
                if (fwList[e]) {
                    Object.values(fwList[e]).forEach(item => selectArr.push({ label: item, value: item }));
                }
                let fwListOption = [...firmwareList.options];
                this.setState({
                    firmwareList: {
                        options: fwListOption,
                        selected: e
                    },
                    hardwareList: {
                        options: selectArr,
                        selected: "Select"
                    },
                }, () => {
                    this.disableEnableTab(true);
                });
            } else {
                let hwListOption = [...hardwareList.options];
                this.setState({
                    hardwareList: {
                        options: hwListOption,
                        selected: e
                    },
                }, () => {
                    if (e === "Select") {
                        this.disableEnableTab(true);
                    } else {
                        this.disableEnableTab(false);
                        this.modalClose();
                        this.fetchProfile();
                    }
                });
            }
        } else { // Select HW revision first and FW version next for VC10 & UM3+ compact
            if (crrSelect === "hardwareList") {
                if (hwList[e]) {
                    Object.values(hwList[e]).forEach(item => selectArr.push({ label: item, value: item }));
                }
                let hwListOption = [...hardwareList.options];
                this.setState({
                    firmwareList: {
                        options: selectArr,
                        selected: "Select"
                    },
                    hardwareList: {
                        options: hwListOption,
                        selected: e
                    },
                }, () => {
                    this.disableEnableTab(true);
                });
            } else {
                let fwListOption = [...firmwareList.options];
                this.setState({
                    firmwareList: {
                        options: fwListOption,
                        selected: e
                    },
                }, () => {
                    if (e === "Select") {
                        this.disableEnableTab(true);
                    } else {
                        this.disableEnableTab(false);
                        this.modalClose();
                        this.fetchProfile();
                    }
                });
            }
        }
    }

    disableEnableTab = (val) => {
        let lProfileDetails = { ...this.state.profileDetails };
        lProfileDetails.tabs.forEach(e => {
            if (e.value !== "Profile Name") {
                e.disabled = val;
            }
        })
        this.setState({
            profileDetails: lProfileDetails,
            nohwRevision: val
        })
    }
    handleCheckBox = (val, item) => {
        let lmultiPositionConductor = [...this.state.multiPositionConductor];
        let lIndex = lmultiPositionConductor.findIndex(e => e === val);
        lmultiPositionConductor[lIndex].selected = item;

        this.setState({
            multiPositionConductor: lmultiPositionConductor
        }, () => {
            if (item) {
                let lProfile = { ...this.state.profile };
                lProfile.selectedProfile[val.key] = JSON.parse(JSON.stringify(lProfile.selectedProfile["conductorData"]));
                this.setState({ profile: lProfile });
            }
        });
    }
    modelClickHandler = (type, props) => {
        if (props.currentProfile && type !== "modalClose") {
            this.continueFetchProfile(props.currentProfile)
        }
        else if(props.onChangeSelectObj  && type !== "modalClose"){
            this.continueonChangeSelect(props.onChangeSelectObj.e, props.onChangeSelectObj.crrSelect);
        }
        else {
            if (type === "modalClose") {
                this.modalClose();
            } else if (type === "deleteProfile") {
                this.deleteProfile();
            } else if (type === "createprofile") {
                this.continueCreateNewProfile();
            } else if (type === "create") {
                this.continueSaveProfile();
            }
        }
    }
    
    closeAlertMessage=()=>{
        this.setState({
            message: {
                status: false,
                msg: "",
                type: ""
            }
        })
    }

    render() {
        return (
            <ProfileContext.Provider value={{
                state: this.state,
                onchangeDeviceType: this.onchangeDeviceType,
                onProfileClick: this.onProfileClick,
                createNewProfile: this.createNewProfile,
                handleTabSelect: this.handleTabSelect,
                handleOnchangeForm: this.handleOnchangeForm,
                confirmDeleteProfile: this.confirmDeleteProfile,
                saveProfile: this.saveProfile,
                onChangeSelect: this.onChangeSelect,
                handleCheckBox: this.handleCheckBox,
                modalClose: this.modalClose,
                modelClickHandler: this.modelClickHandler,
                closeAlertMessage:this.closeAlertMessage,
                roleAndPermission: this.props.roleAndPermission
            }}>
                {this.props.children}
            </ProfileContext.Provider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default withRouter(connect(mapStateToProps, {})(ProfilesProvider));

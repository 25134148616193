import * as actionTypes from './headerActionTypes';

export const changeRoute = (route, isTreeDisable) => {
    return {
        type: actionTypes.ON_ROUTE_CHANGE,
        route: route,
        isTreeDisable: isTreeDisable
    }
}

export const samlLogout = (isSamlLogout) => {
    return {
        type: actionTypes.SAML_LOGOUT,
        samlLogout: isSamlLogout
    }
}

export const samlFailed = (isSamlFailed) => {
    return {
        type: actionTypes.SAML_FAILED,
        samlLogout: isSamlFailed
    }
}
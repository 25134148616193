import React from 'react';
import LicenseManagementContainer from './containers/licenseContainer';
import { Row, Col, Container } from 'react-bootstrap';
import './index.css';
import LicenceProvider from './provider/licenceProvider';

const LicenseManagement = () => {
    return (
        <Container fluid className='license-management'>
            <Row>
                <Col>
                    <LicenceProvider>
                        <div><LicenseManagementContainer /></div>
                    </LicenceProvider>
                </Col>
            </Row>
        </Container>
    );
}

export default LicenseManagement;
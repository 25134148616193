import React,{Fragment, useEffect, useRef} from 'react';
import { Row, Col, Form, } from 'react-bootstrap';
import TextField from '../../../hoc/form/textField';
import SearchWithSelectField from '../../../hoc/form/searchWithSelectField';
import SingleSelect from '../../../hoc/singleSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

const ModalForm = ({ currentTab, generalData, preferencesData, formData, handleOnchangeForm, addOrEdit,timezone, roleAndPermission }) => {
    const nameFocusRef = useRef(null);
    useEffect(()=>{
        nameFocusRef.current.focus();
    },[])
    return (
        <Row>
            <Col>
                {currentTab === 'general' ?
                    generalData.map((item, key) => {
                        return item.type === 'select' ?
                            <Form.Row key={key} className='inline-center-item'>
                                <Col xs={4} className="text-right">{item.label}{item.isMandatory ? '*' : ""}:</Col>
                                <Col xs={7} className="form-right">
                                    <Fragment>
                                        <div className="single-select-dropdown">
                                            <SingleSelect
                                                data={item.options}
                                                setType={(e) => { handleOnchangeForm(e, item.value) }}
                                                value={item.options.length > 0 ? item.options.find(i => i.value === formData[item.value]).label ? item.options.find(i => i.value === formData[item.value]).label : "--select--": "--select--"} 
                                                icon='ok'
                                            />
                                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                        </div>
                                    </Fragment>
                                    </Col>
                            </Form.Row>
                            :
                            <Form.Row key={key} className='inline-center-item'>
                                <Col xs={4} className={item.isErr? 'text-right form-err-color-red':'text-right'}>{item.label}{item.isMandatory ? '*' : ""}:</Col>
                                <Col xs={7} className="form-right">
                                    <TextField
                                        refVal = {item.value === "email" ? nameFocusRef : null}
                                        type={item.type}
                                        field={item.value}
                                        value={formData[item.value]}
                                        placeholder={item.label}
                                        handleOnchange={handleOnchangeForm}
                                        size={'sm'}
                                        isDisabled={(addOrEdit === 'edit' && item.value === 'email') || roleAndPermission.samlEnabled || roleAndPermission.ssoEnabled ? true : false}
                                        className= {item.isErr ? 'form-err-border-red' : "" }
                                    />
                                </Col>
                            </Form.Row>
                    }) : preferencesData.map((item, key) => {
                        return <Form.Row key={key} className='inline-center-item'>
                            <Col xs={4} className="text-right">{item.label}{item.isMandatory ? '*' : ""}:</Col>
                            <Col xs={7} className="form-right">
                                {item.value === 'timezone' ?
                                    <React.Fragment>
                                        <SearchWithSelectField
                                            field={item.value}
                                            defaultValue={formData[item.value] ? formData[item.value] : timezone ? item.options.find(e => e.value.split(" ")[1] === timezone).value : item.options.find(e => e.value.split(" ")[1] === "PST").value }
                                            handleOnchange={handleOnchangeForm}
                                            optionData={item.options}
                                        />
                                    </React.Fragment>
                                    :
                                    <Fragment>
                                        <div className="single-select-dropdown">
                                            <SingleSelect
                                                data={item.options}
                                                setType={(e) => { handleOnchangeForm(e, item.value) }}
                                                value={item.options.find(i => i.value === formData[item.value]) ? item.options.find(i => i.value === formData[item.value]).label :item.options.length > 0 ? item.options[0].value : "--select--"} icon='ok'
                                            />
                                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                        </div>
                                    </Fragment>

                                }
                            </Col>
                        </Form.Row>
                    })
                }
            </Col>
        </Row>
    );
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default connect(mapStateToProps, {})(ModalForm);
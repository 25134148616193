import React, { Component } from 'react'
import { DGWContext } from '../provider/dgwProvider'
import { DGWData } from '../../../utility/dgwUtility'
import BlankPage from '../../../hoc/blankPage';
import { FormattedMessage } from 'react-intl';
import Table from '../../../hoc/table';
import Loader from '../../../hoc/loader'
import { Row, Col } from 'react-bootstrap';
import CustomImgIcon from '../../../hoc/customImgIcon';
import TooltipMessage from '../../../hoc/tooltip';

class DGWTable extends Component {
    static contextType = DGWContext;
    constructor(props) {
        super(props);
        this.state = {
            columnsData: DGWData.columnsData,
        }
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} className="p-0-m-0 pb-2 d-flex justify-content-between">
                        <div><FormattedMessage id='db.common.setting' />&nbsp;:&nbsp;<FormattedMessage id='dgw.table.header' /></div>
                        <div className='mt-2'>
                        <TooltipMessage
                            placement='left'
                            message={<FormattedMessage id="dgw.common.viewCertificate" />}
                            baseContent={<span className='ceritifcate-view-btn' onClick={(e) => { this.context.handleCertDisp(e) }}>
                                <CustomImgIcon icon="glyphicons-cert.png" width="3vh" height="3vh" />
                            </span>}
                        />
                        </div>
                    </Col>
                </Row>

                <div className={ this.context.state.dgwTableData.length === 0 ? 'dgwDataTbl' : ""}>
                {this.context.state.isLoadingTblData ? <Loader elementClass="tree-ajax-loader center-element" /> : this.context.state.dgwTableData.length !== 0 ?
                        <Table
                            tableColumn={this.state.columnsData}
                            tableRowData={this.context.state.dgwTableData}
                            showPagination={true}
                        />
                        :
                            !this.context.state.isLoadingTblData ? <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /> : "" }
                </div>
            </React.Fragment>
        );
    }
}

export default DGWTable;

import React from 'react';
import ManageSummaryCustomFiltersComponent from './manageSummaryCustomFiltersComponent';

const ManageSummaryActionComponent = () => {
    return (
        <div className="float-right btn-toolbar mr-3 mb-3" role="toolbar">
            <div className="export-button btn-group mar-left-10 btn-group-icons" role="group">
                {
                    <ManageSummaryCustomFiltersComponent />
                }
            </div>
        </div>
    )
}

export default ManageSummaryActionComponent;
import React, { Component } from 'react';
import Tree from './containers/treeContainer';
import './treeview.css';
import './siteOrder.css';

class Treeview extends Component {
  render() {
    return (
      <Tree />
    );
  }
}

export default Treeview;
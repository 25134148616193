import { getURL } from '../../../providers/configProvider';
import { requestPOST, requestGET, requestQAll } from '../../../services/extendedService';

export const makeBubbleChartAPIRequest = (requestParams, payload) => {
    const url = getURL('arm', 'loadandunbalance', requestParams);
    return requestPOST(url, payload);
};

export const getLoadAndUnbalanceFeederData = (requestParams, payload) => {
    const url = getURL('arm', 'loadandunbalancefeeder', requestParams);
    return requestPOST(url, payload);
};

export const getLoadAndUnbalanceSiteData = (requestParams, payload) => {
    const url = getURL('arm', 'loadandunbalancesite', requestParams);
    return requestPOST(url, payload);
};

export const getMomentaryORDisturbance = (requestParams, payload) => {
    var urlName = (payload['type'] === "momentary") ? 'treemapmom' : 'treemapdis';
    const url = getURL('arm', urlName, requestParams);
    return requestPOST(url, payload);
};

export const getWatchlistURL = (requestParams) => {
    var namePath = ['watchlistinfopowerquality', 'watchlistinfofaults', 'watchlistinfodisturbance'];
    var urls = [];
    namePath.forEach(urlName => {
        urls.push(getURL('arm', urlName, requestParams));
    });
    return requestQAll(urls);
};

export const getFaultURL = (params, reqBody) => {
    var url = getURL('dashboard', 'topfaults', params);
    return requestPOST(url, reqBody);
}

export const getWatchlistSubstations = (params, currentNode, hasRegionName) => {
    var url = getURL('dashboard', 'selectedregions', params);
    return requestGET(url);
}

export const getWatchlistSubstationsAndFeeder = (params, clickedType) => {
    var url = "";
    if (clickedType === 'REGION') {
        url = getURL('dashboard', 'selectedsubstations', { 'REGION': params.REGION, 'apiType': 'data' });
    } else {
        url = getURL('dashboard', 'selectedfeeders', { 'REGION': params.REGION, 'SUBSTATION': params.SUBSTATION, 'apiType': 'data' });
    }
    return requestGET(url);
}

export const saveUpdatedWatchlist = (updatedData) => {
    var url = getURL('dashboard', 'updatewatchlistdata', { 'apiType': 'data' });
    return requestPOST(url, updatedData);
}

import React, { Component, Fragment } from 'react';
import ModalWindow from '../../../hoc/modelWindow';

class AdditinalInfoModal extends Component {
    state = {
        isOpenModal: false
    }

    getReasonVal = (item) => {
        let iswordWrap = item.additionalInfo !== null && item.additionalInfo.length > 25 ? true : false;
        return <span className={iswordWrap ? 'cursor-pointer active-color' : ""} onClick={iswordWrap ? (e) => this.getReasonDetailsData(e, item) : (e) => e.preventDefault()}>
            {item.additionalInfo !== null && item.additionalInfo !== undefined ? this.truncate(item.additionalInfo, 25) : ""}
        </span>
    }

    truncate = (source, size) => {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }

    getReasonDetailsData = async (e, info) => {
        e.preventDefault();
        this.setState({
            isOpenModal: true,
            modalText: info.additionalInfo
        })
    }

    onCloseModal = () => {
        this.setState({ isOpenModal: false })
    }

    render() {
        return (
            <Fragment>
                <span>{this.getReasonVal(this.props.data.row)}</span>
                {this.state.isOpenModal ?
                    <ModalWindow
                        show={this.state.isOpenModal}
                        onHide={this.onCloseModal}
                        size={"md"}
                        title={this.props.title}
                        dialogClassName={'reasonModal'}
                        modeldata={{ content: this.state.modalText }}
                    />
                    : ""}
            </Fragment>
        )
    }
}

export default AdditinalInfoModal;
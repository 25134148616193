import React, { createContext } from 'react';
import { getConfigStatusData } from '../services/configStatusService';
import { connect } from 'react-redux';
import * as currentJobUtility from '../../../../utility/currentJobUtility';
export const ConfigStatusContext = createContext();

class ConfigStatusProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isParameterLoading: false,
            selectedJobName: "",
            rowDevice: "",
            currentStatus: "",
            jobList: [],
            jobStatus: {},
            deviceListTableDataset: [],
            failureDevices: [],
            deviceParameters: [],
            orgId: this.props.tree[0].id,
            displayColumns: currentJobUtility.selectedColumn.data,
            roleAndPermission: this.props.roleAndPermission
        }
    }

    componentDidMount() {
        this.fetchJobList();
    }

    /* Get the list of jobs (left side view) */
    fetchJobList = () => {
        var params = { 'ORGID': this.state.orgId };
        this.setState({ isLoading: true }, () => {
            getConfigStatusData(params, 'joblist')
                .then(data => {
                    this.setState({ jobList: data && data.length > 0 ? data : [], isLoading: false });
                    if (data && data.length > 0) {
                        this.initialJobListSelection(this.state.selectedJobName || data[0].jobName);
                    }
                }).catch(error => {
                    this.setState({ jobList: [], isLoading: false })
                });
        })
    }
    /* Get individual job details (middle view) */
    fetchJobDetails = (jobName) => {
        currentJobUtility.tableOptions.selectedRows = [];
        currentJobUtility.tableOptions.enableRetryBtn = false;
        currentJobUtility.tableOptions.enableRetryAllBtn = false;
        let params = { 'ORGID': this.state.orgId, 'JOBNAME': jobName, 'apiType': 'data' };
        this.setState({ isLoading: true }, () => {
            getConfigStatusData(params, 'jobDetails')
                .then(data => {
                    this.setState({
                        deviceListTableDataset: data.configDetails && data.configDetails.length ? data.configDetails : [],
                        currentStatus: data.jobStatusDetails.statusDetails && Object.keys(data.jobStatusDetails.statusDetails).length > 0 ? data.jobStatusDetails.statusDetails.inProgress ? "INPROGRESS" : data.jobStatusDetails.statusDetails.failure != 0 ? "FAILURE" : "SUCCESS" : "",
                        jobStatus: data.jobStatusDetails ? data.jobStatusDetails : {},
                        isLoading: false
                    });
                    if (data && data.configDetails && data.configDetails.length > 0) {
                        this.initialDeviceSelection(data.configDetails[0]);
                        this.failedDevices(data.configDetails);
                    }
                }).catch(error => {
                    this.setState({ isLoading: false, deviceListTableDataset: [], currentStatus: "", jobStatus: [] })
                });
        })
    };

    /* Get parameters data on device click */
    fetchDeviceParametersData = (deviceId) => {
        let params = { 'ORGID': this.state.orgId, 'JOBNAME': this.state.selectedJobName, 'DEVICEID': deviceId, };
        this.setState({ isParameterLoading: true }, () => {
            getConfigStatusData(params, 'deviceStatus')
                .then(data => {
                    this.setState({ deviceParameters: data && data.length > 0 ? data : [], isParameterLoading: false });
                }).catch(error => {
                    this.setState({ deviceParameters: [], isParameterLoading: false })
                });
        })
    }

    /* On click of job or selected searched job (left side view)*/
    updateSelectedJobName = (job) => {
        if (job && job.jobName) {
            this.setState({ selectedJobName: job.jobName, currentStatus: "" });
            if (this.state.selectedJobName !== job.jobName) {
                this.fetchJobDetails(job.jobName);
            }
        }
    }

    /* select first job from the list on load */
    initialJobListSelection = (jobname) => {
        this.setState({ selectedJobName: jobname });
        this.fetchJobDetails(jobname);
    }

    initialDeviceSelection = (device) => {
        this.setState({ rowDevice: device.serialNumber ? device.serialNumber : "" });
        device.id && this.fetchDeviceParametersData(device.id);
    }

    failedDevices = (tableData) => {
        var list = [];
        tableData && tableData.length > 0 && tableData.forEach((key) => {
            let configStatusChk = currentJobUtility.configStatusCheck(key, 'configStatus');
            let deviceStatusChk = currentJobUtility.deviceStatusCheck(key, 'deviceStatus');
            let otapStatusChk = currentJobUtility.otapStatusCheck(key, 'otapJobQueueStatus', 'otapStatus');
            if (!configStatusChk && !deviceStatusChk && !otapStatusChk && ((key.jobStatus === "FAILURE") || (key.jobStatus === "ABORTED") || (key.jobStatus === "TIMEOUT"))) {
                list.push(key.id);
            }

            this.setState({ failureDevices: list });
            currentJobUtility.tableOptions.enableRetryAllBtn = false;
        })
        // Toggle RetryAll Btn
        if (list.length > 0) {
            currentJobUtility.tableOptions.enableRetryAllBtn = true;
        }
    }

    /* Fetch job list status */
    getJobListStatus = (item) => {
        if(item.status==="TRANSFER_COMPLETED") return item.status
        else{
        if (this.state.selectedJobName === item.jobName) {
            if (item.status === this.state.currentStatus || this.state.currentStatus === "") {
                return item.status;
            } else {
                let templist = [...this.state.jobList];
                templist.find(e => e.jobName == item.jobName).status = this.state.currentStatus;
                this.setState({ jobList: templist });
                return this.state.currentStatus;
            }
        } else {
            return item.status;
        }
    }
    }

    render() {
        return (
            <ConfigStatusContext.Provider value={{
                state: this.state,
                fetchJobList: this.fetchJobList,
                initialDeviceSelection: this.initialDeviceSelection,
                updateSelectedJobName: this.updateSelectedJobName,
                fetchDeviceParametersData: this.fetchDeviceParametersData,
                getJobListStatus: this.getJobListStatus
            }}>
                {this.props.children}
            </ConfigStatusContext.Provider>
        )
    }
}
// Getting the state from the store
const mapStateToProps = state => {
    return {
        tree: state.treeviewData.tree,
        roleAndPermission: state.loginData.roleAndPermission
    };
}

export default connect(mapStateToProps)(ConfigStatusProvider);
import React, { Component, Fragment } from 'react';
import { Col } from 'react-bootstrap';
import NoDataAvailableComponent from '../../../../hoc/noDataAvailableComponent';
import { SummaryContext } from '../provider/summaryProvider';
import Table from '../../../../hoc/table';
import ReactTablePropType from '../../../../hoc/reactTablePropType';
import { summaryFilterData, tableOptions } from '../../../../utility/eventsSummaryUtility';
import { connect } from 'react-redux';
import { updateSelectedTreeFromDashboard } from '../../../dashboard/actions/index';
import { withRouter } from "react-router";
import { dateConvertion } from '../../../../services/utilityService';

class SummaryTableView extends Component {
    static contextType = SummaryContext;
    state = {
        columns: tableOptions.tableColumns
    }
    filterTableColumns = () => {
        let columnsToBeRemoved = [];
        if (summaryFilterData.groupBy === "Feeder") {
            columnsToBeRemoved.push("upStreamSite", "lateral");
        } if (summaryFilterData.groupBy === "UpStreamFeederSite") {
            columnsToBeRemoved.push("lateral", "noFaultSustainedInterruption", "noFaultMomentaryInterruption");
        } if (summaryFilterData.groupBy === "UpStreamFeederAndLateralSite") {
            columnsToBeRemoved.push("noFaultSustainedInterruption", "noFaultMomentaryInterruption");
        } if (this.context.state.treeNode.type === "REGION") {
            columnsToBeRemoved.push("region");
        } if (this.context.state.treeNode.type === "SUBSTATION") {
            columnsToBeRemoved.push("region", "substation");
        }
        return tableOptions.tableColumns.filter(x => !columnsToBeRemoved.includes(x.accessor));
    }
    columnClick = (state, rowInfo, column) => {
        if (column) {
            return {
                onClick: () => {
                    if (column.id === "feeder") {
                        sessionStorage.setItem("faultTimeStamp",JSON.stringify({startTimestamp:dateConvertion(this.context.state.startDate, 'short'),endTimestamp:dateConvertion(this.context.state.endDate, 'short')}));
                        const feederDetails = { feederName: rowInfo.original.feeder, name: rowInfo.original.feeder, regionName: rowInfo.original.region, substationName: rowInfo.original.substation, type: "FEEDER" }
                        this.props.updateSelectedTreeFromDashboard(feederDetails);
                        this.props.history.push("/events/details");
                    }
                    if (column.id === "substation") {
                        sessionStorage.setItem("faultTimeStamp",JSON.stringify({startTimestamp:dateConvertion(this.context.state.startDate, 'short'),endTimestamp:dateConvertion(this.context.state.endDate, 'short')}))
                        const substationDetails = { name: rowInfo.original.substation, regionName: rowInfo.original.region, substationName: rowInfo.original.substation, type: "SUBSTATION" }
                        this.props.updateSelectedTreeFromDashboard(substationDetails);
                        this.props.history.push("/events/details");
                    }
                }
            }
        } else {
            return "";
        }
    }
    render() {
        let content = null;
        if (this.context.state.faultData.length > 0 && (this.context.state.notificationCount > 0 ? this.context.state.filteredData.length > 0 : true)) {
            content =
                <Col xs={12} md={12} className={"faultData clr-fix pad-rgt-lef mt-3 m-0 p-0 " + this.props.className}>
                    <ReactTablePropType
                        reactTableContainer={
                            <Table
                                tableColumn={this.filterTableColumns()}
                                tableRowData={this.context.state.notificationCount > 0 ? this.context.state.filteredData : this.context.state.faultData}
                                getTdProps={this.columnClick} />

                        } />
                </Col>
        } else {
            content = !this.context.state.loading ? <NoDataAvailableComponent /> : ""
        }
        return (
            <Fragment>
                {content}
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedTreeFromDashboard: (data) => dispatch(updateSelectedTreeFromDashboard(data))
    }
}
export default withRouter(connect(null, mapDispatchToProps)(SummaryTableView));
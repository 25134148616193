import React, { useContext } from 'react';
import {VirtualDeviceContext} from '../../provider/virtualDeviceProvider';

const VDprofileTemplateCheckbox = (props) => {
    const contextData = useContext(VirtualDeviceContext);
    return (
        <input 
            type="checkbox" 
            key={Math.random()}
            checked={contextData.state.mainFormData.vdProfileSettings[props.rowData.row._index].selected}
            onChange={(e) => contextData.handleMainFormData(e.target.checked, props.rowData, 'vdProfileSettings', 'selected')}
        />
    );
};

export default VDprofileTemplateCheckbox;
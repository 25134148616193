import React, { Component, Fragment } from 'react';
import SummaryContainer from './containers/summaryContainer';
import { TreeConsumer } from '../../../treeview/provider/treeProvider';
import SummaryProvider from './provider/summaryProvider';
import BaseMessage from '../../../hoc/baseMessage';
import './index.css';

class FaultSummary extends Component {
    redirecttoDetailFeeder = () => {
        this.props.history.push("/events/details");
    }
    render() {
        return (
            <Fragment>
                <TreeConsumer>
                    {(context) => (
                        <React.Fragment>
                            <div className="events-summary">
                                <div className={context.state.breadCrumb ? "with-breadcrumb-events" :""}>
                                <SummaryProvider loadNextLevel={context.loadNextLevel} treeNode={context.state.highlightedNode.node} setDashboardFlag={context.cancelApiCallFromDashboard} dashboardFlag={context.state.dashboardFlag} treeLoader={context.state.loading}> {
                                    (!context.state.highlightedNode) ? <BaseMessage className={"content-height"} message="events.summary.baseMessage" /> :
                                        (context.state.highlightedNode.node.type.indexOf('FEEDER') > -1) ?
                                            <BaseMessage className={"content-height"} message="events.summary.baseMessage" enableSubLink="true" nodeName={context.state.highlightedNode.node.name} onClickHandler={this.redirecttoDetailFeeder} /> : <SummaryContainer />
                                }
                                </SummaryProvider>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </TreeConsumer>
            </Fragment>
        );
    }
}

export default FaultSummary;
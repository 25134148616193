import React, { useContext, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import CertificateContainer from './CertificateContainer';
import { DGWContext } from '../provider/dgwProvider';


const CertificateModal = () => {

    const {veiwAmpleCertificate} =  useContext(DGWContext);

    useEffect(()=>{
        veiwAmpleCertificate();
    },[])

    return (
        <Row className="sg-name-modal">
            <Col>

                {/*  body section certificate */}
                <Row className="sg-name-modal-body">
                    <Col>
                        {/* Display current and previous certificate section*/}
                        <Row className="certificate-section">
                            <Col>
                                <CertificateContainer /> 
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default CertificateModal;
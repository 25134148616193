import { getURL } from '../../../../providers/configProvider';
import { requestPOST, requestGET, requestPOSTtreeView } from '../../../../services/extendedService';

export const getNodeData = (params, reqBody) => {
  const url = getURL('deviceManagement', 'getData', params);
  return requestPOSTtreeView(url, reqBody)
}

export const getFilterList = (name) => {
  const url = getURL('deviceManagement', 'getfilters', { 'MODULE': name, 'apiType': 'DEVICE' });
  return requestGET(url);
}

export const getCommServerDetails = (name, params) => {
  const url = getURL('systemadmin', name, params);
  return requestGET(url);
}

export const loadDeviceTypeList = () => {
  const url = getURL('groupManagement', 'productnamelist', { 'apiType': 'data' });
  return requestGET(url);
}

export const loadCommServerList = (id) => {
  const url = getURL('groupManagement', 'commserverlist', { 'ORGID': id, 'apiType': 'data' });
  return requestGET(url);
}

export const loadDeviceStateList = () => {
  const url = getURL('groupManagement', 'devicestatelist', { 'apiType': 'data' });
  return requestGET(url);
}

export const loadNetworkProviderList = (productName, hardwareRevision) => {
  const url = getURL('groupManagement', 'networkproviderlist', { 'PRODUCTNAME': encodeURIComponent(productName), 'HARDWAREREVISION': hardwareRevision, 'apiType': 'data' });
  return requestGET(url);
}

export const loadSoftwareVersionList = (productName, platform) => {
  const url = getURL('groupManagement', 'softwareversionlist', { 'PRODUCTNAME': encodeURIComponent(productName), 'PLATFORM': encodeURIComponent(platform), 'apiType': 'data' });
  return requestGET(url);
}

export const addDevice = (orgId, params) => {
  const url = getURL('groupManagement', 'adddevice', { 'ORGID': orgId, 'apiType': 'data' });
  return requestPOST(url, params);
}

export const loadNetworkGroupsList = (orgId, sgwId, deviceType) => {
  const url = getURL('groupmanagement', 'groupnamelist', { 'ORGID': orgId, 'SERVERID': sgwId, 'apiType': 'data', 'DEVICETYPE': encodeURIComponent(deviceType) });
  return requestGET(url);
}

export const getEditDeviceData = (deviceId) => {
  const url = getURL('groupManagement', 'fetchNodeData', { 'apiType': 'data', 'DEVICEID': deviceId });
  return requestGET(url);
}

/* Get um3 multi position device location details */
export const deviceLocation = (orgid, id) => {
  const url = getURL('deviceManagement', 'devicelocation', { 'ORGID': orgid, 'ID': id, 'apiType': 'DEVICE' });
  return requestPOST(url);
}

export const updateEditDeviceData = (orgId, deviceId, params) => {
  const url = getURL('groupManagement', 'updateNodeData', { 'ORGID': orgId, 'DEVICEID': deviceId, 'apiType': 'data' });
  return requestPOST(url, params);
}

export const moveDevice = (orgId, deviceId, params) => {
  const url = getURL('groupManagement', 'movedevice', { 'ORGID': orgId, 'DEVICEID': deviceId, 'apiType': 'data' });
  return requestPOST(url, params);
}

export const deleteDevices = (deleteDevicesObj) => {
  const url = getURL('deviceManagement', 'delete', { 'apiType': 'DEVICE' });
  return requestPOST(url, deleteDevicesObj);
}

export const integrityPollDevices = (integrityPollDevicesObj) => {
  const url = getURL('deviceManagement', 'integrityPoll', { 'apiType': 'DEVICE' });
  return requestPOST(url, integrityPollDevicesObj);
}

export const getPingStatus = (deviceId) => {
  const url = getURL('deviceManagement', 'pingstatus', { 'DEVICEID': deviceId, 'apiType': 'DEVICE' });
  return requestGET(url);
}

export const initiatePing = (deviceId) => {
  const url = getURL('deviceManagement', 'initiateping', { 'DEVICEID': deviceId, 'apiType': 'DEVICE' });
  return requestGET(url);
}

export const getflashStatus = (deviceId) => {
  const url = getURL('deviceManagement', 'flashstatus', { 'DEVICEID': deviceId, 'apiType': 'DEVICE' });
  return requestGET(url);
}

export const initiateFlash = (deviceId) => {
  const url = getURL('deviceManagement', 'initiateflash', { 'DEVICEID': deviceId, 'apiType': 'DEVICE' });
  return requestGET(url);
}

export const exportData = (params) => {
  const url = getURL('deviceManagement', 'exportData', params);
  const headers = {'Content-Type': 'blob'};
  const config= {method: 'GET', url: url, responseType: 'arraybuffer', headers};
  return requestGET(url,config)
}

export const deviceStateList = () => {
  const url = getURL('deviceManagement', 'devicestates', { 'apiType': 'DEVICE' });
  return requestGET(url);
}

export const applyState = (params, id, errorTitle) => {
  const url = getURL('deviceManagement', 'applystate', { 'ORGID': id, 'apiType': 'DEVICE' });
  return requestPOST(url, params, {}, { errorTile: errorTitle });
}

// Device Details
export const getDeviceData = (deviceId, position) => {
  const url = getURL('deviceManagement', 'detail', { 'DEVICEID': deviceId, 'POSITION': position, 'apiType': 'DEVICE' });
  return requestGET(url);
}

export const getHealthData = (healthId) => {
  const url = getURL('deviceManagement', 'healthreport', { 'HEALTHID': healthId, 'apiType': 'DEVICE' });
  const headers = {'Content-Type': 'blob'};
  const config= {method: 'GET', url: url, responseType: 'arraybuffer', headers};
  return requestGET(url,config)
}

export const getLoggerFilesData = (loggerId) => {
  const url = getURL('deviceManagement', 'loggerfiles', { 'LOGGERID': loggerId, 'apiType': 'DEVICE' });
  const headers = {'Content-Type': 'blob'};
  const config= {method: 'GET', url: url, responseType: 'arraybuffer', headers};
  return requestGET(url,config)
}

export const downloadLogs = (systemLogId) => {
  const url = getURL('deviceManagement', 'downloadLogsUrl', { 'SYSTEMLOGID': systemLogId, 'apiType': 'DEVICE' });
  const headers = {'Content-Type': 'blob'};
  const config= {method: 'GET', url: url, responseType: 'arraybuffer', headers};
  return requestGET(url,config)
}

export const generateDiagnostics = (deviceId) => {
  const url = getURL('deviceManagement', 'generateDiagnosticsUrl', { 'DEVICEID': deviceId, 'apiType': 'DEVICE' });
  return requestPOST(url, {});
}

export const getRebootUrl = (serialNumber) => {
  const url = getURL('deviceManagement', 'getRebootUrl', { 'SERIALNUMBER': serialNumber, 'apiType': 'DEVICE' });
  return requestGET(url);
}

// ==connected percentage ==
export const getconnectionStatus = (deviceId) => {
  const url = getURL('deviceManagement', 'connectionstatus', {'DEVICEID': deviceId,'apiType':'DEVICE'});
  return requestGET(url);
}
export const getLoadData = (deviceId) => {
  const url = getURL('deviceManagement', 'logistatus', {'DEVICEID': deviceId,'apiType':'DEVICE'});
  return requestGET(url);
}

export const loadProfileList = (firmwares) => {
  const url = getURL('deviceManagement', 'profilelist', { 'FIRMWARES': firmwares, 'apiType': 'DEVICE' });
  return requestGET(url);
}

export const applyConfig = (params, errorTitle) => {
  const url = getURL('deviceManagement', 'applyprofile', { 'apiType': 'DEVICE' });
  return requestPOST(url, params, {}, { errorTile: errorTitle });
}

export const loadFirmwareversionList = () => {
  const url = getURL('deviceManagement', 'firmwareversion', { 'apiType': 'DEVICE' });
  return requestGET(url);
}

export const applyUpgrade = (params, errorTitle) => {
  const url = getURL('deviceManagement', 'applyupgrade', { 'apiType': 'DEVICE' });
  return requestPOST(url, params, {}, { errorTile: errorTitle });
}

export const enableGpsForZm1 = (deviceId) => {
  const url = getURL('groupManagement', 'enableGpsForZm1', { 'apiType': 'data', 'DEVICEID': deviceId });
  return requestGET(url);
}

export const gethardwareRevisionData = (productName) => {
  const url = getURL('groupManagement', 'hardwareRevisionList', { 'apiType': 'data', 'PRODUCTNAME': encodeURIComponent(productName) });
  return requestGET(url);
}
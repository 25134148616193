import React from 'react'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../hoc/tooltip';
import { SystemAdminContext } from '../provider/systemAdminProvider';

class UploadSystemAdminFile extends React.Component {
    static contextType = SystemAdminContext;
    render() {
        const { roleId } = this.props.roleAndPermission
        const enableUpload = roleId === 1 || ( roleId === 2 && ( this.props.uploadFrom === 'firmwareFile' || this.props.uploadFrom === 'inventoryFile' || this.props.uploadFrom === 'manufacturingFile'))
        return (
            <div className="text-right">
                <span className={enableUpload ? "custom-file-inputs float-right" : "disabled-file-inputs float-right"} >
                    <Tooltip placement="top" message={this.props.tooltipTitle} baseContent={
                        <span className='tooltip-btn'>
                            <FontAwesomeIcon icon={faUpload} size="sm" className={enableUpload ? "input-icon-color" : "disabled-input-icon-color"} />
                            <input type="file" disabled={enableUpload ? false : true}
                                onChange={(e) => this.context.handleFileUpload(this.props.uploadFrom, e)}
                                onClick={(event) => event.target.value = null}
                                accept={this.props.fileAccept} />
                        </span>
                    } />
                </span>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default connect(mapStateToProps, {})(UploadSystemAdminFile);
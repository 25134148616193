import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import 'react-table-6/react-table.css';
import { DGWContext } from '../provider/dgwProvider'
import DGWTable from './dgwTable'
import DgwUploadCertificateContainer from './dgwIUploadCertificateContainer';
import CertificateModal from '../components/DgwCertificateModal';

class DGWContainer extends Component {
    static contextType = DGWContext;
    render() {
        return (
            <>
            <Row>
                <Col>
                    <DGWTable />
                </Col>
                    {this.context.state.isCertificateModalDisplay && <CertificateModal />}
            </Row>
            <Row >
            <Col>
                <DgwUploadCertificateContainer/>
            </Col>
            </Row>
            </>
    );
    }
}

export default DGWContainer;

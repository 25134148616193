import React, { Component, Fragment } from 'react';
import MultiSelectDropdown from '../../../manage/details/components/multiselectDropdownComponent';
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { SiteContext } from '../provider/siteProvider';
import * as siteUtility from '../../../../utility/siteUtility';
import { FormattedMessage } from 'react-intl';
// let filterLength = 0;

class CustomFilter extends Component {
    static contextType = SiteContext;
    static filterLength = 0;
    state = {
        filterColumnList: [],
        customFilterButtons :[]
    }
    myRef = React.createRef()

    onChangeHandler = (optionList, dataFieldName) => {
        this.state.filterColumnList.forEach(column => {
            if (optionList.type) {
                if (column.dataField === dataFieldName) {
                    //column.items = [];
                    if (optionList.target.value.length > 0) {
                        column.items.push(optionList.target.value)
                    }
                }
            }
            else {
                if (optionList.length > 0) {
                    optionList.forEach(selectedOption => {
                        if (column.dataField === selectedOption.name) {
                            column.length = column.data.length;
                            column.items = optionList;
                        }
                    })
                }
                else if (optionList.length === 0 && column.dataField === dataFieldName) {
                    column.items = optionList;
                }
            }
        });
        this.setState({
            filterColumnList: this.state.filterColumnList
        });
    }

    handleShowAllBtn = (columnName) => {
        this.state.filterColumnList.filter(column => column.dataField === columnName ? column.items = [] : "");
        this.setState({ filterColumnList: this.state.filterColumnList })
    }

    reset = () => {
        this.saveSelectedFilters("reset");
        this.context.getSiteList(this.context.state.treeNode);
    };

    apply = () => {
        this.saveSelectedFilters("apply");
        siteUtility.tableOptions.pageNo = 1;
        this.context.getSiteList(this.context.state.treeNode, true);
    }

    saveSelectedFilters = (filterMode) => {
        if (filterMode === "reset") {
            this.state.filterColumnList.forEach(column => {
                //column.items = column.selectedItems;
                column.items = [];
                column.selectedItems = [];
            })
        }
        else {
            this.state.filterColumnList.forEach(column => {
                column.selectedItems = column.items;
            })
        }
        this.setState({
            filterColumnList: this.state.filterColumnList
        });
        this.findFilterLength(this.state.filterColumnList);
        siteUtility.tableOptions.customFilterColumns = this.state.filterColumnList;
        this.myRef.current.click();
    }

    findFilterLength = (data) => {
        this.filterLength = 0;
        data.forEach(dt => {
            if (dt.selectedItems.length > 0 && dt.type === "dropdown") {
                this.filterLength = this.filterLength + 1;
                dt.length = dt.data.length;
            }
            else if (dt.selectedItems.length > 0 && dt.type === "textBox") {
                this.filterLength = this.filterLength + 1;
            }
        });
        siteUtility.tableOptions.filterSelectionCount = this.filterLength;
    }

    componentDidMount() {
        this.filterLength = siteUtility.tableOptions.filterSelectionCount;
        this.setState({
            filterColumnList: siteUtility.tableOptions.customFilterColumns,
            customFilterButtons: siteUtility.tableOptions.customFilterButtons,
        });
    }

    render() {
        const filterColumns = this.state.filterColumnList.map((column, index) => {
            return (
                <div key={index} className="row m-0 p-0 mb-2">
                    <label className="customFilterStyle col-5 m-0 p-0"><FormattedMessage id={`dm.column.${column.dataField}`} /></label>
                    <MultiSelectDropdown
                        selectedArrayList={column.items}
                        optionList={
                            column.data.length > 0 ?
                                column.data.map((option, i) => ({
                                    "name": column.dataField,
                                    "text": option,
                                    "value": option,
                                    "keyIndex": i
                                })) : []
                        }
                        onChange={this.onChangeHandler}
                        name={column.dataField}
                        manySelectedPlaceholder={`%s/${column.length} Checked, ... `}
                        allSelectedPlaceholder={`%s/${column.length} Checked, ... `}
                        placeholder="Select"
                        multiple={true}
                        includeSelectAll={false}
                        includeFilter={false}
                        open={false}
                        keepOpen={true}
                        valueKey="value"
                        labelKey="text"
                        handleShowAllBtn={() => this.handleShowAllBtn(column.dataField)}
                        btmLabel={"Show All"}
                    />
                </div>
            )

        });

        const filterButtons = this.state.customFilterButtons.map((button, i) => {

            return !button.hidden ? button.dataField === "resetAll" ? <Button key={i} className="btnStyling mb-2" disabled={this.filterLength === 0 ? true : false} onClick={this[button.action]}><FormattedMessage id={`dm.column.${button.dataField}`} /></Button> : <Button key={i} className="btnStyling mb-2" onClick={this[button.action]}><FormattedMessage id={`dm.column.${button.dataField}`} /></Button> : "";
        });

        return (
            <Fragment>
                <Dropdown id="dropdown-custom-filters" className = "pr-1" >
                    <Dropdown.Toggle className="btn-outline-secondary btn-group-icons btn-light grp-btn-first m-0"  ref={this.myRef} disabled={this.context.state.treeNode && this.context.state.treeNode.type === "SITE" ? true : false}>
                        <FontAwesomeIcon icon={faFilter} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-disable-autohide dropdown-filters">
                        {filterColumns}
                        {filterButtons} 
                    </Dropdown.Menu>
                </Dropdown>
                <span className="badge badge-notify">{this.filterLength > 0 ? this.filterLength : ""}</span>
            </Fragment>
        )
    }
}

export default CustomFilter;
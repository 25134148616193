import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import HeatMapChartContainer from './heatmapContainer';
import BubbleMapChartContainer from './bubbleMapChartContainer';
import WatchListContainer from './watchListContainer';
// import EventLogContainer from './eventLogContainer';

class DashBoard extends Component {
    render() {
        return (
            <Container fluid>
                <Row noGutters={false} className="p-0 pb-2">
                    <Col xs={6} className="pr-2">
                        <BubbleMapChartContainer orgId={this.props.tree[0].id} rootNodeName={this.props.tree[0].name} />
                    </Col>
                    <Col xs={6} className="pl-4 pr-0">
                        <HeatMapChartContainer orgId={this.props.tree[0].id} rootNodeName={this.props.tree[0].name} />
                    </Col>
                </Row>
                <Row noGutters={false} className="mt-1">
                    <Col xs={12}>
                        <WatchListContainer orgId={this.props.tree[0].id} rootNodeName={this.props.tree[0].name} />
                    </Col>
                    {/* <Col xs={6}>
                        <EventLogContainer orgId={this.props.tree[0].id} />
                    </Col> */}
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        tree: state.treeviewData.tree
    };
}

export default connect(mapStateToProps)(DashBoard);
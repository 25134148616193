import axios from 'axios';
import React from 'react';
import ampleStore from '../store';
import { logoutTokenHandler, openErrorWindow } from '../login/actions/loginActionsBuilder';
import { FormattedMessage } from 'react-intl';
import { setAuthToken } from '../utility/authTokenUtility';

const CancelToken = axios.CancelToken;
let cancel;
let configParams = {
    timeout : 180000	// 3 * 60 * 1000 - 3 minutes in milliseconds
};

export const requestPOST = async(url, params, reqConfig, options) => {
    if (JSON.parse(localStorage.getItem('token'))) {
    setAuthToken(JSON.parse(localStorage.getItem('token')).access_token); //set AuthHeader
    if (!reqConfig) reqConfig = {};
    reqConfig['timeout'] = configParams.timeout;
    return axios.post(url, params, reqConfig)
        .then(response => {
            if(options && !options.validateSuccess){
                return response;
            }
            if (typeof response.data === 'object') {
                var data = response.data.data ? response.data.data : response.data;
                return data;
            }
        })
        .catch(err => {
            if(err.response && err.response.status === 401){
                logoutTokenHandler();
            }
            else return ampleStore.dispatch(openErrorWindow(<FormattedMessage id='common.error.server'/>));
        })
    }
    else {
        localStorage.clear();
        window.location.href = `${window.location.origin}/amplemanage/login`;
    }
}

export const requestPOSTtreeView = async(url, params) => {
    if (JSON.parse(localStorage.getItem('token'))) {
    setAuthToken(JSON.parse(localStorage.getItem('token')).access_token); //set AuthHeader
    if (cancel !== undefined ) {
        cancel();
    }
    return axios.post(url, params)
    .then(response => {
        if (typeof response.data === 'object') {
            var data = response.data.data ? response.data.data : response.data;
            return data;
        }
    }).catch(err => {
        if (axios.isCancel(err)) {
            return { message: "data canceled" };
        } else {
            if(err.response && err.response.status === 401){
                logoutTokenHandler();
            }else return ampleStore.dispatch(openErrorWindow(<FormattedMessage id='common.error.server'/>));
        }
    })
    }
    else {
        localStorage.clear();
        window.location.href = `${window.location.origin}/amplemanage/login`;
    }
}

export const requestGET = async(url, reqConfig, options) => {
    if (JSON.parse(localStorage.getItem('token'))) {
    setAuthToken(JSON.parse(localStorage.getItem('token')).access_token); //set AuthHeader
    if (!reqConfig) reqConfig = {};
    reqConfig['timeout'] = configParams.timeout;
    return axios.get(url, reqConfig)
        .then(response => {
            if(options && !options.validateSuccess){
                return response;
            }
            if (typeof response.data === 'object'&& reqConfig.headers?.['Content-Type'] !== 'blob') {
                var data = response.data.data ? response.data.data : response.data;
                return data;
            } else if ((typeof response.data === 'string')||(typeof response.data === 'object'&& reqConfig?.headers?.['Content-Type'] === 'blob')){
                return response;
            }
        })
        .catch(err => {
            if(err.response && err.response.status === 401){
                logoutTokenHandler();
            }else return ampleStore.dispatch(openErrorWindow(<FormattedMessage id='common.error.server'/>));
        })
    }
    else {
        localStorage.clear();
        window.location.href = `${window.location.origin}/amplemanage/login`;
    }
}

export const requestDELETE = async (url, reqConfig, options) => {
    if (JSON.parse(localStorage.getItem('token'))) {
    setAuthToken(JSON.parse(localStorage.getItem('token')).access_token); //set AuthHeader
    if (!reqConfig) reqConfig = {};
    reqConfig['timeout'] = configParams.timeout;
    return axios.delete(url, reqConfig)
        .then(response => {
            if (options && !options.validateSuccess) {
                return response;
            }
            if (typeof response.data === 'object') {
                var data = response.data.data ? response.data.data : response.data;
                return data;
            } else if (typeof response.data === 'string') {
                return url
            }
        })
        .catch(err => {
            return ampleStore.dispatch(openErrorWindow(<FormattedMessage id='common.error.server' />));
        })
    }
    else {
        localStorage.clear();
        window.location.href = `${window.location.origin}/amplemanage/login`;
    }
}

export const requestQAll = async(urls) => {
    if (JSON.parse(localStorage.getItem('token'))) {
    setAuthToken(JSON.parse(localStorage.getItem('token')).access_token); //set AuthHeader
    var urlCalls = urls.map(url => axios.get(url));
    return axios.all(urlCalls)
        .then(responses => {
            var res = [];
            responses.forEach(response => {
                if (response.data === '') {
                    res.push(0);
                } else if (typeof response.data === 'object') {
                    var data = response.data.data ? response.data.data : response.data;
                    res.push(data);
                }
            })
            return res
        })
        .catch(err => {
            return ampleStore.dispatch(openErrorWindow(<FormattedMessage id='common.error.server'/>));
        })
    }
    else {
        localStorage.clear();
        window.location.href = `${window.location.origin}/amplemanage/login`;
    }
}
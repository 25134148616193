import React, { Component,Fragment } from 'react';
import { ProfileContext } from '../provider/profilesProvider';
import CommonTabContent from './commonTabContent';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../../../hoc/tooltip';
import PopOverMessage from '../../../../hoc/popOver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ToggleSwitch from '../../../../hoc/toggleSwitch';
import TextField from '../../../../hoc/form/textField';
import CheckBox from '../../../../hoc/form/checkbox';
import { ProfileDataOrder, getSortedList } from '../../../../utility/manageProfileUtility';
import VC10TabContent from './vc10TabContent';

class UM3ProfileConfig extends Component {
    static contextType = ProfileContext;
    render() {
        return (
            <div>
                {
                    this.context.state.currentTab === "deviceSettings" ?
                        <div>
                            <CommonTabContent
                                contentArrParent={this.context.state.profile.selectedProfile.deviceData}
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.deviceDataRules}
                                isBooleanValue={this.props.isBooleanValue}
                                isTimeoffset={this.props.isTimeoffset}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='deviceData'
                                disabled={false}
                                formError={this.context.state.formError}
                                headerContent={false}
                                popOver={true}
                                showZM1CfciFields={this.props.showZM1CfciFields}
                                deviceType={this.props.deviceType}
                            />
                        </div> : null
                }
                {
                    this.context.state.currentTab === "conductorSettings" ?
                    this.context.state.profile.selectedProfile.conductorDataP2 == null && this.context.state.profile.selectedProfile.conductorDataP3 == null && this.context.state.profile.selectedProfile.conductorDataP4 == null ?
                    <div>
                            <CommonTabContent
                                contentArrParent={this.context.state.profile.selectedProfile.conductorData}
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.conductorDataRules}
                                isBooleanValue={this.props.isBooleanValue}
                                isTimeoffset={this.props.isTimeoffset}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='conductorData'
                                disabled={false}
                                formError={this.context.state.formError}
                                headerContent={false}
                                popOver={true}
                                showZM1CfciFields={this.props.showZM1CfciFields}
                                deviceType={this.props.deviceType}
                            />
                        </div>
                    :
                        <div>
                            <Row className="p-0 m-1 bg-grey-clr font-weight-bold">
                                <Col className="col-4"><FormattedMessage id={`manage.profiles.pointname`} /></Col>
                                <Col className="col-2"><FormattedMessage id={`manage.profiles.position1`} /></Col>
                                <Col className="col-2"><FormattedMessage id={`manage.profiles.position2`} /></Col>
                                <Col className="col-2"><FormattedMessage id={`manage.profiles.position3`} /></Col>
                                <Col className="col-2"><FormattedMessage id={`manage.profiles.position4`} /></Col>
                            </Row>
                            <Row className="p-0 m-1">
                                <Col className="col-4"></Col>
                                <Col className="col-2"></Col>
                              
                                    {Object.values(this.context.state.multiPositionConductor).map((e1,i1)=>{
                                        return(
                                            <Col className="col-2" key={`${e1}-${i1}`}>
                                                <CheckBox label={<span><FormattedMessage id='manage.profiles.sameaspos1' /> </span>}
                                                    checked={e1.selected}
                                                    field={e1}
                                                    handleOnchange={this.context.handleCheckBox}
                                                    disabled={false}
                                                />
                                            </Col>
                                        )
                                    })}
                               
                            
                                
                            </Row>
                            <Row className="p-0 m-1">
                                {
                                    Object.keys(getSortedList(this.context.state.profile.selectedProfile.conductorData,ProfileDataOrder[this.props.deviceType]["conductorData"], this.props.deviceType)).map((e,i) => {
                                        return(
                                            <Fragment key={`${e}-${i}`}>
                                            <Col className="col-4 p-t-5">
                                                <span>
                                                    <Tooltip placement="top" message={e} baseContent={this.props.updateFormattedMessage(e, this.context.state.profile.selectedProfile.conductorDataRules)} />
                                                    <PopOverMessage trigger="focus" keyVal={`${e}-${i}`} placement="right" popoverBody={
                                                        <div class="m-3"><FormattedMessage id={`manage.profiles.${e}-popOverContent`} /></div>
                                                    } displayPopOver={
                                                        <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
                                                    } />
                                                </span>
                                            </Col>
                                            <Col className="col-2 p-t-5">
                                                    {
                                                        !this.props.isBooleanValue(this.context.state.profile.selectedProfile.conductorData[e]) ?
                                                            <TextField
                                                                type="text"
                                                                // isDisabled={this.props.disabled}
                                                                value={this.context.state.profile.selectedProfile.conductorData[e] ? this.context.state.profile.selectedProfile.conductorData[e] : ""}
                                                                field={this.context.state.profile.selectedProfile.conductorData[e]}
                                                                handleOnchange={(e1) => this.props.handleOnchangeForms("conductorData", e, e1)}
                                                                className={this.context.state.formError[e] !== undefined && this.context.state.formError[e] === true ? "form-err-border-red ml-2p" : ""}
                                                            /> :
                                                            <ToggleSwitch
                                                                value={this.context.state.profile.selectedProfile.conductorData[e] === true ? true : false}
                                                                toggleSwitchValue={(e1) => this.props.handleOnchangeForms("conductorData", e, this.context.state.profile.selectedProfile.conductorData[e] === true ? false : true)}
                                                                leftValue="On"
                                                                rightValue="Off"
                                                            />
                                                    }
                                            </Col>
                                            <Col className="col-2 p-t-5">
                                                    {
                                                        !this.props.isBooleanValue(this.context.state.profile.selectedProfile.conductorDataP2[e]) ?
                                                            <TextField
                                                                type="text"
                                                                isDisabled={this.context.state.multiPositionConductor[0].selected}
                                                                value={this.context.state.profile.selectedProfile.conductorDataP2[e] ? this.context.state.profile.selectedProfile.conductorDataP2[e] : ""}
                                                                field={this.context.state.profile.selectedProfile.conductorDataP2[e]}
                                                                handleOnchange={(e1) => this.props.handleOnchangeForms("conductorDataP2", e, e1)}
                                                                className={this.context.state.formError[e + "P2"] !== undefined && this.context.state.formError[e + "P2"] === true ? "form-err-border-red ml-2p" : ""}
                                                            /> :
                                                            <ToggleSwitch
                                                                value={this.context.state.profile.selectedProfile.conductorDataP2[e] === true ? true : false}
                                                                toggleSwitchValue={(e1) => this.props.handleOnchangeForms("conductorDataP2", e, this.context.state.profile.selectedProfile.conductorDataP2[e] === true ? false : true)}
                                                                leftValue="On"
                                                                rightValue="Off"
                                                                disabled = {this.context.state.multiPositionConductor[0].selected}
                                                            />
                                                    }
                                            </Col>
                                            <Col className="col-2 p-t-5">
                                                    {
                                                        !this.props.isBooleanValue(this.context.state.profile.selectedProfile.conductorDataP3[e]) ?
                                                            <TextField
                                                                type="text"
                                                                isDisabled={this.context.state.multiPositionConductor[1].selected}
                                                                value={this.context.state.profile.selectedProfile.conductorDataP3[e] ? this.context.state.profile.selectedProfile.conductorDataP3[e] : ""}
                                                                field={this.context.state.profile.selectedProfile.conductorDataP3[e]}
                                                                handleOnchange={(e1) => this.props.handleOnchangeForms("conductorDataP3", e, e1)}
                                                                className={this.context.state.formError[e + "P3"] !== undefined && this.context.state.formError[e + "P3"] === true ? "form-err-border-red ml-2p" : ""}
                                                            /> :
                                                            <ToggleSwitch
                                                                value={this.context.state.profile.selectedProfile.conductorDataP3[e] === true ? true : false}
                                                                toggleSwitchValue={(e1) => this.props.handleOnchangeForms("conductorDataP3", e, this.context.state.profile.selectedProfile.conductorDataP3[e] === true ? false : true)}
                                                                leftValue="On"
                                                                rightValue="Off"
                                                                disabled = {this.context.state.multiPositionConductor[1].selected}
                                                            />
                                                    }
                                            </Col>
                                            <Col className="col-2 p-t-5">
                                                    {
                                                        !this.props.isBooleanValue(this.context.state.profile.selectedProfile.conductorDataP4[e]) ?
                                                            <TextField
                                                                type="text"
                                                                isDisabled={this.context.state.multiPositionConductor[2].selected}
                                                                value={this.context.state.profile.selectedProfile.conductorDataP4[e] ? this.context.state.profile.selectedProfile.conductorDataP4[e] : ""}
                                                                field={this.context.state.profile.selectedProfile.conductorDataP4[e]}
                                                                handleOnchange={(e1) => this.props.handleOnchangeForms("conductorDataP4", e, e1)}
                                                                className={this.context.state.formError[e + "P4"] !== undefined && this.context.state.formError[e + "P4"] === true ? "form-err-border-red ml-2p" : ""}
                                                            /> :
                                                            <ToggleSwitch
                                                                value={this.context.state.profile.selectedProfile.conductorDataP4[e] === true ? true : false}
                                                                toggleSwitchValue={(e1) => this.props.handleOnchangeForms("conductorDataP4", e, this.context.state.profile.selectedProfile.conductorDataP4[e] === true ? false : true)}
                                                                leftValue="On"
                                                                rightValue="Off"
                                                                disabled = {this.context.state.multiPositionConductor[2].selected}
                                                            />
                                                    }
                                            </Col>
                                        </Fragment>
                                        )
                                    })
                                }
                            </Row>
                        </div> : null
                }
                {
                    this.context.state.currentTab === "faultDetectionMCP" ?
                        <div>
                            <VC10TabContent
                                contentArrParent={this.context.state.profile.selectedProfile.faultDetection}
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.faultDetectionRules}
                                isBooleanValue={this.props.isBooleanValue}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='faultDetection'
                                disabled={false}
                                popOver={true}
                                formError={this.context.state.formError}
                                deviceType={this.props.deviceType}
                            />
                        </div> : null
                }

                {
                    this.context.state.currentTab === "visualIndicatorMCP" ?
                        <div>
                            <VC10TabContent
                                contentArrParent={this.context.state.profile.selectedProfile.visualIndicator}
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.visualIndicatorRules}
                                isBooleanValue={this.props.isBooleanValue}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='visualIndicator'
                                disabled={false}
                                popOver={true}
                                formError={this.context.state.formError}
                                deviceType={this.props.deviceType}
                            />
                        </div> : null
                }

                {
                    this.context.state.currentTab === "loggingMCP" ?
                        <div>
                            <VC10TabContent
                                contentArrParent={this.context.state.profile.selectedProfile.logging}
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.loggingRules}
                                isBooleanValue={this.props.isBooleanValue}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='logging'
                                disabled={false}
                                popOver={true}
                                formError={this.context.state.formError}
                                deviceType={this.props.deviceType}
                            />
                        </div> : null
                }
                {
                    this.context.state.currentTab === "advanceSettingsMCP" ?
                        <div>
                            <VC10TabContent
                                contentArrParent={this.context.state.profile.selectedProfile.advanceSettings}
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.advanceSettingsRules}
                                isBooleanValue={this.props.isBooleanValue}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='advanceSettings'
                                disabled={false}
                                popOver={true}
                                formError={this.context.state.formError}
                                deviceType={this.props.deviceType}
                            />
                        </div> : null
                }
            </div>
        )
    }
}

export default UM3ProfileConfig;
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import NavigationItem from './navigationItem';
import Menus from '../../config/menus.json';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { isGreaterThanTodaysDate } from '../../filters/dateHelper';

class NavigationItems extends Component {

    updateMainMenuItems = (licenseData) => {
        Menus.mainMenu.forEach(menu => {
            if (licenseData[menu.link] && licenseData[menu.link].visible) {
                menu.show = true;
                if(isGreaterThanTodaysDate(licenseData[menu.link].expiresOn)) menu.isDisabled = false;
            }
        });
    }

    render() {
        if (Object.keys(this.props.roleAndPermission).length && Object.keys(this.props.roleAndPermission.licenseData).length) {
            this.updateMainMenuItems(this.props.roleAndPermission.licenseData);
        }

        let menus = Menus['mainMenu'].map(menu => {
            return menu.show && <NavigationItem menuType="mainMenu" extraClass={menu.extraClass} isDisabled={menu.isDisabled} key={menu.key} link={`/${menu.link}`} exact="true"><FormattedMessage id={`${menu.key}`} /></NavigationItem>
        })

        return (
            <React.Fragment>
                {menus}
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default withRouter(connect(mapStateToProps, {})(NavigationItems));
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { saveUpdatedNotification } from '../services/emailSubscriptionService';
import { FormattedMessage } from 'react-intl';
import Alert from '../../../hoc/alert';
import Loader from '../../../hoc/loader';
import { userMngmntData } from '../../../utility/userManagementUtility';
import TreeViewWithCheckbox from '../../../hoc/treeViewWithCheckbox';
import NotificationTypeContainer from './notificationTypeContainer';
import ModalWindow from '../../../hoc/modelWindow';

class ConfigModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationData: [],
            isOpenModal: true,
            isloadingData: false,
            alertMessage: {},
            treeViewCheckedData: []
        }
    }

    closeEmailSubModal = () => {
        userMngmntData.isOpenEmailSubscriptionsModal = false;
        this.setState({ isOpenModal: true })
    }

    componentWillReceiveProps() {
        this.setState({ alertMessage: {} })
    }

    updateEmailSubscription = () => {
        var updateData = { "regions": this.state.treeViewCheckedData, "subscribedNotifications": this.state.notificationData.filter(item => ['device','event','insights'].includes(item.category)) };
        this.setState({ isloadingData: true }, () => {
            saveUpdatedNotification(updateData)
                .then((res) => {
                    if (res.status !== 'FAIL') {
                        this.setState({ alertMessage: { msg: <FormattedMessage id='db.es.updatenotificationinfo.success' />, type: 'success' }, isloadingData: false })
                    } else {
                        this.setState({ alertMessage: { msg: res.message ? res.message : <FormattedMessage id='db.es.updatenotificationinfo.failed' />, type: 'danger' }, isloadingData: false });
                    }
                }).catch((err) => this.setState({ isloadingData: false }))
        })
    }

    receiveUpdatedTreeData = (allData) => this.setState({ treeViewCheckedData: allData })

    handleOnCheckNotification = (data) => {
        this.setState({ notificationData: data })
    }
    onBtnClickHandlera=(value)=>{
        if(value === 'cancel') this.closeEmailSubModal()
        else if(value === 'update') this.updateEmailSubscription()
    }
    getUpdateButtonClassname = () => {
        if (this.state.treeViewCheckedData.length > 0) return 'modalSaveBtn';
        else return 'modalSaveBtn disabled';
    }
    render() {
        return (
            <Row>
                <Col>
                    {userMngmntData.isOpenEmailSubscriptionsModal ?
                        <ModalWindow
                            show={true}
                            size="lg"
                            dialogClassName={"email-subscription-modal"}
                            title={<FormattedMessage id='nav.main.slertsubscriptions' />}
                            onBtnClickHandler={this.onBtnClickHandlera}
                            onHide={this.closeEmailSubModal}
                            modeldata={{
                                content: <React.Fragment>
                                    {this.state.isloadingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                                    {this.state.alertMessage.type !== undefined ?
                                        <Row className="alert-message">
                                            <Col>
                                                <Alert type={this.state.alertMessage.type} message={this.state.alertMessage.msg} />
                                            </Col>
                                        </Row>
                                        :
                                        <React.Fragment>
                                            <Row>
                                                <Col>
                                                    <div className="region-substation-container">
                                                        <div className="notification-main-title"><FormattedMessage id='db.es.selectRegionSubstation' /></div>
                                                        <div className="region-substation-tree">
                                                            <TreeViewWithCheckbox
                                                                passUpdatedData={this.receiveUpdatedTreeData}
                                                                expandUpto={"SUBSTATION"} //display up to
                                                                checkedLableName={'subscribedForAlerts'} //for checkbox true or false by this value
                                                                from={"emailSubscription"}  //for api call
                                                                checkBoxVisible={'all'} // visible checkbox level
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <NotificationTypeContainer handleOnCheckNotification={this.handleOnCheckNotification} />
                                                </Col>
                                            </Row>
                                            <Row className="text-right bottom-hints">
                                                <Col >
                                                    <FormattedMessage id='db.es.modalBottomHitns' />
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }}
                            footer={
                                this.state.alertMessage.type !== undefined ? "" :
                                [{ className: "modalCancelBtn", name: <FormattedMessage id='dm.column.cancel' />, value: "cancel" }, 
                                { className: this.getUpdateButtonClassname(), name: <FormattedMessage id='db.common.update' />, value: "update" }]
                            }
                        />
                        : ""}
                </Col>
            </Row>
        );
    }
}

export default ConfigModalContainer;
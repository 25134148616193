import React from 'react';
import ReactTable from 'react-table-6';
import PropTypes from 'prop-types'

const ReactTablePropType = (props) => {
    return (
        <React.Fragment>
            {props.reactTableContainer}
        </React.Fragment>
    )
}

ReactTable.propTypes = {
    PaginationComponent: PropTypes.func,
    PadRowComponent: PropTypes.func,
    ResizerComponent: PropTypes.func,
    NoDataComponent: PropTypes.func,
    LoadingComponent: PropTypes.func,
    NextComponent: PropTypes.func,
    PreviousComponent: PropTypes.func,
    PivotComponent: PropTypes.func,
    AggregatedComponent: PropTypes.func,
    PivotValueComponent: PropTypes.func,
    ExpanderComponent: PropTypes.func,
    FilterComponent: PropTypes.func,
    TfootComponent: PropTypes.func,
    ThComponent: PropTypes.func,
    TrComponent: PropTypes.func,
    TrGroupComponent: PropTypes.func,
    TdComponent: PropTypes.func,
    TbodyComponent: PropTypes.func,
    TheadComponent: PropTypes.func,
    TableComponent: PropTypes.func,
}

export default ReactTablePropType;

import React from 'react';
import DeviceCustomFiltersComponent from './deviceCustomFiltersComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCog, faDownload, faTrash, faShareSquare, faTh, faRetweet, faPoll } from '@fortawesome/free-solid-svg-icons';
import { tableOptions, mangeDetailsColumFilterData, exportDeviceData } from '../../../../utility/deviceManagementUtility';
import Tooltip from '../../../../hoc/tooltip';
import DropdownMenuCheckbox from '../../../../hoc/dropdownMenuCheckbox';
import SingleSelect from '../../../../hoc/singleSelect';

const deviceActionComponent = (props) => {
    const notProcessed = tableOptions.selectedRows.length > 0 && tableOptions.selectedRows.filter(row => row.notProcessed === true).length > 0;
    const deviceStateActionEnable = props.isSelectedDeviceMove(tableOptions.selectedRows);
    const configureActionEnable = props.isDevicesConfig(tableOptions.selectedRows);
    return (
        <div className="float-right btn-toolbar mr-1 mb-3" role="toolbar">
            <div className="btn-group mar-right-15 btn-group-icons" role="group">
                <Tooltip baseContent={
                    <span onClick={props.roleAndPermission.roleId === 3 || props.roleAndPermission.roleId === 4 ? (e) => e.preventDefault() : props.addDeviceHandler} className={"btn btn-outline-secondary grp-btn-first " + (props.roleAndPermission.roleId === 3 || props.roleAndPermission.roleId === 4 ? "btn-secondary disabled cursor-not-allowed" : "btn-light")}>
                        <FontAwesomeIcon icon={faPlus} size="sm" style={{ color: props.roleAndPermission.roleId === 3 || props.roleAndPermission.roleId === 4 ? "#a5aaaf" : "" }} />
                    </span>} message={"Add Device"} placement={'top'} />

               
                <Tooltip baseContent={
                    <span disabled={(tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && configureActionEnable) ? false : true} onClick={(tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && configureActionEnable) ? props.onConfigDeviceClickHandler : (e) => e.preventDefault()} className={"btn btn-outline-secondary grp-btn-middle " + (tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && configureActionEnable ? "btn-light" : "btn-secondary disabled cursor-not-allowed")}>
                        <FontAwesomeIcon icon={faCog} size="sm" style={{ color: (tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && configureActionEnable) ? '' : "#a5aaaf" }} />
                    </span>} message={"Configure"} placement={'top'} />

                <Tooltip baseContent={
                    <span disabled={(tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && configureActionEnable) ? false : true} onClick={(tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && configureActionEnable) ? props.onUpgradeDeviceClickHandler : (e) => e.preventDefault()} className={"btn btn-outline-secondary grp-btn-middle " + (tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && configureActionEnable ? "btn-light" : "btn-secondary disabled cursor-not-allowed")}>
                        <FontAwesomeIcon icon={faDownload} size="sm" style={{ color: (tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && configureActionEnable) ? '' : "#a5aaaf" }} />
                    </span>} message={"Upgrade"} placement={'top'} />

                <Tooltip baseContent={
                    <span disabled={(tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && deviceStateActionEnable) ? false : true} onClick={(tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && deviceStateActionEnable) ? props.onStateChangeHandler : (e) => e.preventDefault()} className={"btn btn-outline-secondary grp-btn-middle " + (tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && deviceStateActionEnable && tableOptions.selectedRows.filter(e=> e.deviceType === 'UM1').length === 0 ? "btn-light" : "btn-secondary disabled cursor-not-allowed")}>
                        <FontAwesomeIcon icon={faRetweet} size="sm" style={{ color: (tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 && !notProcessed && deviceStateActionEnable) ? "" : "#a5aaaf" }} />
                    </span>} message={"State Change"} placement={'top'} />

                <Tooltip baseContent={
                    <span onClick={(tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4) ? props.deleteDeviceHandler : (e) => e.preventDefault()} className={"btn btn-outline-secondary grp-btn-middle " + (tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 ? "btn-light" : "btn-secondary disabled cursor-not-allowed")}>
                        <FontAwesomeIcon icon={faTrash} size="sm" style={{ color: (tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4) ? '' : "#a5aaaf" }} />
                    </span>} message={"Delete"} placement={'top'} />

                    <Tooltip baseContent={
                    <span onClick={(tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4) ? props.integrityPollDeviceHandler : (e) => e.preventDefault()} className={"btn btn-outline-secondary grp-btn-last " + (tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4 ? "btn-light" : "btn-secondary disabled cursor-not-allowed")}>
                        <FontAwesomeIcon icon={faPoll} size="sm" style={{ color: (tableOptions.selectedRows.length > 0 && props.roleAndPermission.roleId !== 3 && props.roleAndPermission.roleId !== 4) ? '' : "#a5aaaf" }} />
                    </span>} message={"Integrity Poll"} placement={'top'} />
            </div>

            <div className="mar-right-15 btn-group btn-group-icons export-content" role="group">
                <span className='single-select-dropdown'>
                <SingleSelect
                    data={exportDeviceData.exportData}
                    setType={props.onExportBtnClick}
                    value={<FontAwesomeIcon icon={faShareSquare} color={props.devicesData.length === 0?'#ccc':''} />}
                    isDisplayLabel={false}
                    disabled= {props.devicesData.length === 0}
                />
                </span>         
            </div>

            <div className="btn-group btn-group-icons" role="group">
                {
                    props.nodeType !== "SITE" && props.nodeType !== "LATERAL_SITE" ? <DeviceCustomFiltersComponent /> : ""
                }

                <span className="btn-outline-secondary btn-group-icons btn-light grp-btn-last">
                    <DropdownMenuCheckbox
                        btnContent={<span className="add-btn column-filter"><FontAwesomeIcon icon={faTh} size="sm" /></span>}
                        bodyContent={mangeDetailsColumFilterData.columFilter}
                        selectedCulumns={props.selectedColumnsData}
                        handleFilterChecked={props.getEnabledColumn}
                        isBtnDisabled={props.devicesData.length > 0 ? false : true}
                    />
                </span>
            </div>
        </div>
    );
}

export default deviceActionComponent;
import { FormattedMessage } from 'react-intl';
import React from 'react';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber'

const accessorValues = ['reportName', 'reportVisibility', 'reportAvailability']

export const tableOptions = {
    tableColumns: accessorValues.map(e => {
        return ({
            accessor: e,
            Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id={`customReports.manageReports.column.header.${e}`} />} />,
            sortedOrder: 0
        })
    }),

    editFields: [
        { label: 'reportVisibility', type: 'switch' },
        { label: 'reportAvailability', type: 'switch' }
    ]
}


import React, { PureComponent } from 'react';
import { EventPrecursorContext } from '../provider/eventPrecursorProvider';
import { eventPrecursorData } from '../../../../utility/eventPrecursorUtility';
import { Row, Col } from 'react-bootstrap';
import Table from '../../../../hoc/tableManualSort';
import Tooltip from '../../../../hoc/tooltip';
import { FormattedMessage } from 'react-intl';
import { faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as eventPrecursorUtility from '../../../../utility/eventPrecursorUtility';

class EventPrecursorTable extends PureComponent {
    static contextType = EventPrecursorContext;
    constructor(props) {
        super(props);
        this.state = {
            tableColumnData: [
                ...eventPrecursorData.tableColumnData,
                {
                    accessor: 'fieldInspection',
                    Header: (original) => <FormattedMessage id={"events.eventPrecursor.column.fieldInspection"} />,
                    sortable: false,
                    Cell: ({ original }) =>
                        <span><span onClick={() => { this.handleActionClick("add") }}><Tooltip baseContent={<FontAwesomeIcon icon={faPlusCircle} size="sm" />} message={"Add"} placement={'top'} /></span><span className='pl-3' onClick={() => { this.handleActionClick("view") }}><Tooltip baseContent={<FontAwesomeIcon icon={faEye} size="sm" />} message={"View"} placement={'top'} /></span></span>
                },
            ]
        }
    }

    rowSelection = (state, rowInfo) => {
        if (rowInfo && rowInfo.original) {
            return {
                onClick: e => { rowInfo.original.event_id && this.context.handleRowOnClick(rowInfo.original) },
                style: {
                    background: rowInfo.original.event_id === this.context.state.epTableSelectedRow.event_id ? "#F1D7AD" : ""
                }
            };
        } else {
            return {}
        }
    }

    handleActionClick = (pAction) => {
        this.context.onUpdateActionValue(pAction);
    }


    onPageChange = (pageNo, sizePerPage) => {
        window.scrollTo(0, 0);
        this.context.updateEventPrecursorUtilityValues(pageNo, sizePerPage);
    }

    onPageSizeChange = (sizePerPage, pageNo) => {
        window.scrollTo(0, 0);
        this.context.updateEventPrecursorUtilityValues(pageNo, sizePerPage);
    }
    onSortedChange = (pSelected) => {
        eventPrecursorData.tableOptions.ordering = pSelected;
        this.context.onTableSortChange();
    }
    render() {
        return (
            <Row className={`event-precursor-table`}>
                <Col className='p-0'>
                    <Table
                        tableColumn={this.state.tableColumnData}
                        tableRowData={this.context.state.eventPreCursorTableData}
                        sorted={eventPrecursorData.tableOptions.ordering}
                        showPagination={true}
                        rowSelectionCallback={(state, rowInfo) => this.rowSelection(state, rowInfo)}
                        pageNo={eventPrecursorUtility.eventPrecursorData.tableOptions.pageNo}
                        pageSize={eventPrecursorUtility.eventPrecursorData.tableOptions.pageSize}
                        totalSize={this.context.state.totalDevicesCount}
                        onPageChange={this.onPageChange}
                        onPageSizeChange={this.onPageSizeChange}
                        onSortedChange={this.onSortedChange}
                        pageSizeOptions={eventPrecursorUtility.eventPrecursorData.tableOptions.pageSizeOptions}
                        isManualTable={true}
                        lazyLoadConfigData={{ initialSize: 50, updatedSize: 50 }}
                    />
                </Col>
            </Row>
        )
    }
}
export default EventPrecursorTable;
import React, { Component, createContext } from 'react'
import { classAssignmentTabs } from "../../../../utility/deviceManagementUtility";
import { getClassAssignmentTabData, postClassAssignmentTabData } from '../services/classAssignmentService';
import { FormattedMessage } from 'react-intl';

export const ClassAssignmentContext = createContext();

class ClassAssignmentProvider extends Component {
    state = {
        currentTab: "",
        allTabs: classAssignmentTabs,
        um3TabInitialData: {},
        zm1TabInitialData: {},
        mm3TabInitialData: {},
        mm3aiTabInitialData: {},
        um3TabClassAssignmentData: {},
        zm1TabClassAssignmentData: {},
        mm3TabClassAssignmentData: {},
        mm3aiTabClassAssignmentData: {},
        isLoading: false,
        isBtnDisabled: true,
        alertMessage: { message: '', type: '' },
        noDataAvailable:false
    }
    getTabData = async (activeTab) => {
        this.setState({ isLoading: true, noDataAvailable: false })
        const tabData = await getClassAssignmentTabData(activeTab);
        
        if(tabData && tabData.status !== 'FAIL'){
            switch (activeTab) {
                case 'UM3+': 
                    this.setState({ um3TabInitialData: { "generic": tabData.generic, "ample": tabData.ample }, um3TabClassAssignmentData: { "generic": tabData.generic, "ample": tabData.ample }, isLoading: false, isBtnDisabled: true });
                break;
                case 'MM3': 
                    this.setState({ mm3TabInitialData: { "generic": tabData.generic, "ample": tabData.ample }, mm3TabClassAssignmentData: { "generic": tabData.generic, "ample": tabData.ample }, isLoading: false, isBtnDisabled: true });
                break;
                case 'MM3ai': 
                    this.setState({ mm3aiTabInitialData: { "generic": tabData.generic, "ample": tabData.ample }, mm3aiTabClassAssignmentData: { "generic": tabData.generic, "ample": tabData.ample }, isLoading: false, isBtnDisabled: true });
                break; 
                case 'ZM1':
                    this.setState({ zm1TabInitialData: { "generic-lateral": tabData["generic-lateral"], "ample-lateral": tabData["ample-lateral"], "generic-feeder": tabData["generic-feeder"], "ample-feeder": tabData["ample-feeder"] }, zm1TabClassAssignmentData: { "generic-lateral": tabData["generic-lateral"], "ample-lateral": tabData["ample-lateral"], "generic-feeder": tabData["generic-feeder"], "ample-feeder": tabData["ample-feeder"] }, isLoading: false, isBtnDisabled: true });
                break; 
                default:
                    break;
            }
        } else {
            this.setState({ isLoading: false , noDataAvailable: true })
        }
    }
    handleTabSelect = (tabName) => {
        this.setState({ currentTab: tabName });
        this.getTabData(tabName);
    }
    handleModifiedData = (e, fieldName, selectedTabData, initialTabData, stateValue) => {
        let btnToBeDisabled = true;
        if (initialTabData[fieldName] !== e.target.value) {
            btnToBeDisabled = false;
        } else {
            btnToBeDisabled = true;
        }
        if (e.target.value.trim() === "") {
            btnToBeDisabled = true;
        }
        selectedTabData[fieldName] = e.target.value;
        this.setState({ [stateValue]: selectedTabData, isBtnDisabled: btnToBeDisabled });
    }
    inputChangeHandler = (e, fieldName) => {
        if (this.state.currentTab && this.state.currentTab === "UM3+") {
            this.handleModifiedData(e, fieldName, this.state.um3TabClassAssignmentData, this.state.um3TabInitialData, "um3TabClassAssignmentData");
        } else if (this.state.currentTab && this.state.currentTab === "MM3") {
            this.handleModifiedData(e, fieldName, this.state.mm3TabClassAssignmentData, this.state.mm3TabInitialData, "mm3TabClassAssignmentData");
        } else if (this.state.currentTab && this.state.currentTab === "MM3ai") {
            this.handleModifiedData(e, fieldName, this.state.mm3aiTabClassAssignmentData, this.state.mm3aiTabInitialData, "mm3aiTabClassAssignmentData");
        } else if (this.state.currentTab && this.state.currentTab === "ZM1") {
            this.handleModifiedData(e, fieldName, this.state.zm1TabClassAssignmentData, this.state.zm1TabInitialData, "zm1TabClassAssignmentData");
        }
    }
    onSaveBtnClickHandler = async () => {
        this.setState({ isLoading: true, isBtnDisabled: true })
        const resBody = this.state.currentTab && this.state.currentTab === "UM3+" ? this.state.um3TabClassAssignmentData :
            this.state.currentTab && this.state.currentTab === "MM3" ? this.state.mm3TabClassAssignmentData :
            this.state.currentTab && this.state.currentTab === "MM3ai" ? this.state.mm3aiTabClassAssignmentData :
                this.state.currentTab && this.state.currentTab === "ZM1" ? this.state.zm1TabClassAssignmentData : "";
        const res = await postClassAssignmentTabData(resBody, this.state.currentTab);
        if (res) {
            if (res.status !== "FAIL") {
                this.setState({ alertMessage: { message: <FormattedMessage id={`manage.classAssignment.${this.state.currentTab.toLowerCase()}.successMessage`} />, type: 'success' }, isLoading: false, isBtnDisabled: true });
                this.handleTabSelect(this.state.currentTab);
            } else {
                this.setState({ alertMessage: { message: res.message, type: 'danger' }, isLoading: false });
            }
        }
    }
    onCancelBtnClickHandler = () => {
        if (!this.state.isBtnDisabled) {
            this.handleTabSelect(this.state.currentTab);
        }
    }
    onCloseAlert = () => this.setState({ alertMessage: { message: '', type: '' } });
    componentDidMount() {
        this.handleTabSelect("UM3+");
    }
    render() {
        return (
            <ClassAssignmentContext.Provider value={{
                state: this.state,
                handleTabSelect: this.handleTabSelect,
                inputChangeHandler: this.inputChangeHandler,
                onCloseAlert: this.onCloseAlert,
                onSaveBtnClickHandler: this.onSaveBtnClickHandler,
                onCancelBtnClickHandler: this.onCancelBtnClickHandler
            }}>
                {this.props.children}
            </ClassAssignmentContext.Provider>
        )
    }
}

export default ClassAssignmentProvider;
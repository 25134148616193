import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import SubNavigations from '../../header/navigation/subNavigations';
import Menus from '../../config/menus.json';
import InsightsRoutes from '../../routes/insightsRoutes';
import { connect } from 'react-redux';
import './index.css';
import { isGreaterThanTodaysDate } from '../../filters/dateHelper';

class Insights extends Component {
    updateMenuItems = (data) => {
        // Show or disable sub menus based on license visibility and expiresOn date
        Menus.insightsSubMenu.forEach(submenu => {
            //const parent = submenu['link'].includes('manage') ? 'manage' : submenu['link'].includes('events') ? 'events' : 'insights'
            Object.keys(data.licenseData).length && data.licenseData['insights']?.childNodes.forEach(child => {
                if (child.name === submenu.name && child.visible) {
                    submenu.show = true;
                    if(['Reoccurring Events','Harmonics'].includes(submenu.name))
                        submenu.show = false;
                    if (isGreaterThanTodaysDate(child.expiresOn)) submenu.isDisabled = false;
                }
            })
        });
    }
    render() {
        if (Object.keys(this.props.roleAndPermission).length > 0) {
            this.updateMenuItems(this.props.roleAndPermission)
        }
        return (
            <div className="secondary-navbar col-md-12 col-12 p-0 insights">
                <SubNavigations menu={Menus['insightsSubMenu']} />
                <InsightsRoutes />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}


export default  withRouter(connect(mapStateToProps, {})(Insights));
import { getURL } from '../../../providers/configProvider';
import { requestGET } from '../../../services/extendedService';

export const getMapCenter = (params) => {
  const url = getURL('map', 'mapcenter', params);
  return requestGET(url);
}

export const getlatlong = (params) => {
    const url = getURL('map', 'latlong', params);
    return requestGET(url);
}

export const getMapViewData = (params) => {
    const url = getURL('map', 'mapview', params);
    return requestGET(url);
}
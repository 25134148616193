import React, { Component, Fragment } from 'react';
import EventPrecursorContainer from './container/eventPrecursorContainer';
import { TreeConsumer } from '../../../treeview/provider/treeProvider';
import EventPrecursorProvider from './provider/eventPrecursorProvider';
import BaseMessage from '../../../hoc/baseMessage';

class EventPrecursor extends Component {
    render() {
        return (
            <Fragment>
                <TreeConsumer>
                    {(context) => (
                        <React.Fragment>
                            {
                                context.state.highlightedNode.node !== undefined ?
                                    <EventPrecursorProvider
                                        updatedTreeData={context.state.tree[0]}
                                        loadNextLevel={context.loadNextLevel}
                                        treeNode={context.state.highlightedNode.node}
                                    >
                                        <div className="event-precursor">
                                            <EventPrecursorContainer />
                                        </div>
                                    </EventPrecursorProvider>

                                    : <BaseMessage className={"content-height"} message="events.eventPrecursor.baseMessage" />
                            }
                        </React.Fragment>
                    )}
                </TreeConsumer>
            </Fragment >
        );
    }
}


export default EventPrecursor;
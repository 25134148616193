import React, { Component } from 'react';
import ManageSchedulesContainer from './containers/manageSchedulesContainer';
import ManageSchedulesProvider from './provider/manageSchedulesProvider';
import './index.css'

class ManageSchedules extends Component {
    render() {
        return (
            <div className="manageSchedules">
                <ManageSchedulesProvider>
                    <ManageSchedulesContainer />
                </ManageSchedulesProvider>
            </div>
        )
    }
}
export default ManageSchedules;
import { getURL } from '../../../../providers/configProvider';
import { requestPOST, requestGET, requestPOSTtreeView } from '../../../../services/extendedService';

export const getSites = (params, reqBody) => {
  const url = getURL('sites', 'getSites', params);
  return requestPOSTtreeView(url, reqBody);
}
export const postGroundFaultThreshold = (requestParams, payload)=>{
  const url = getURL('sites', 'groundFaultThresholddata', requestParams);
  return requestPOST(url, payload);
}
export const getFilterList = (requestParams) => {
  const url = getURL('sites', 'siteFilter', requestParams);
  return requestGET(url);
}
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import * as treeViewUtility from '../utility/treeViewUtility';
import { getTreeData } from '../treeview/services/treeService';
import Loader from './loader';
import 'react-sortable-tree/style.css';

class CustomReportTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: []
        };
        this.payload = { 'ROOTNODE': '', 'REGION': '', 'SUBSTATION': '', 'FEEDER': '', 'SITE': '', 'LATERAL': '', 'LATERAL_SITE': '' };
    }

    componentDidMount() {
        this.getNodeData();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.hasOwnProperty('needToPersistence')) { // force to reload
            if((this.props.needToPersistence !== '') && (this.props.needToPersistence !== nextProps.needToPersistence)) {
                this.getNodeData();
            }
        }
    }

    getNodeData = () => {
        this.getAllRegionData();
    }

    getAllRegionData = () => {
        this.setState({ isLoding: true, treeData: [] }, () => {
            getTreeData({ 'ROOTNODE': this.props.tree[0].id, 'apiType': 'ROOTNODE' }, 'nodes', '')
                .then(resData => {
                    if(this.props.from === "reports/custom") resData.forEach(e => e.subscribedForWatchlist = true);
                    if (resData && resData.length) this.setState({ treeData: this.updateResponseData(resData), isLoding: false })
                }).catch((err) => err)
        })
    }

    updateTempId = (payloadData, item, key) => {
        let uniqKey = '';
        if (payloadData.REGION === "") { uniqKey = item.name + key;
        } else if (payloadData.apiType === "REGION") { uniqKey = payloadData.REGION + item.name + key;
        } else if (payloadData.apiType === "SUBSTATION") { uniqKey = payloadData.REGION + payloadData.SUBSTATION + item.name + key;
        } else if (payloadData.apiType === "FEEDER") { uniqKey = payloadData.REGION + payloadData.SUBSTATION + payloadData.FEEDER + item.name + key;
        } else if (payloadData.apiType === "LATERAL") { uniqKey = payloadData.REGION + payloadData.SUBSTATION + payloadData.FEEDER + payloadData.LATERAL + item.name + key;
        } return uniqKey
    }

    updateResponseData = (resData) => {
        let data = resData.sort((a, b) => a.name.localeCompare(b.name));
        data.forEach((item, key) => {
            item.title = item.name;
            item.expanded = false;
            item.tempId = item.tempId = this.updateTempId(this.payload, item, key);;
        })
        return data
    }

    generatePayload = (rowInfo) => {
        switch (rowInfo.node.type) {
            case 'REGION':
                this.payload = { 'ROOTNODE': this.props.tree[0].id, 'REGION': rowInfo.node.title, 'apiType': 'REGION' }
                break;
            case 'SUBSTATION':
                this.payload['SUBSTATION'] = rowInfo.node.title;
                this.payload['apiType'] = 'SUBSTATION';
                break;
            case 'FEEDER':
                this.payload['FEEDER'] = rowInfo.node.title;
                this.payload['apiType'] = 'FEEDER';
                break;
            case 'SITE':
                this.payload['SITE'] = rowInfo.node.title;
                this.payload['apiType'] = 'SITE';
                break;
            case 'LATERAL':
                this.payload['LATERAL'] = rowInfo.node.title;
                this.payload['apiType'] = 'LATERAL';
                break;    
            case 'LATERAL_SITE':
                this.payload['LATERAL_SITE'] = rowInfo.node.title;
                this.payload['apiType'] = 'LATERAL_SITE';
                break;
            default:
                break;
        }
    }

    checkLevelMatch = (currentLevel, expandUpto) => {
        let match = false;
        switch (currentLevel) {
            case 'REGION':
                match = (expandUpto > 1) ? true : false;
                break;
            case 'SUBSTATION':
                match = (expandUpto > 2) ? true : false;
                break;
            case 'FEEDER':
                match = (expandUpto > 3) ? true : false;
                break;
            case 'SITE':
                match = (expandUpto > 4) ? true : false;
                break;
            case 'LATERAL':
                match = (expandUpto > 4) ? true : false;
                break;    
            case 'LATERAL_SITE':
                match = (expandUpto > 5) ? true : false;
                break;
            default:
                match = false;
                break;
        }
        return match;
    }

    handleClickOnItem = (rowInfo, event) => {
        let checked = event.target.checked;
        this.setState({ isLoding: true }, () => {
            if (!rowInfo.node.children && this.checkLevelMatch(rowInfo.node.type, this.props.expandUpto) && (rowInfo.node.type !== 'LATERAL_SITE') && (rowInfo.node.type !== 'SITE') ) {
                this.generatePayload(rowInfo);
                getTreeData(this.payload, 'nodes', rowInfo.node.type)
                .then(resData => {
                    if(this.props.from === "reports/custom") resData?.length && resData.forEach(e => e.subscribedForWatchlist =true);
                    let data = resData && resData.length ? this.updateResponseData(resData) : []
                    this.showOrHideChildrenData(rowInfo, checked, data);
                })
            } else {
                this.showOrHideChildrenData(rowInfo, checked);
            }
        })
    }

    showOrHideChildrenData = (rowInfo, checked, resData) => {
        let checkedValue = checked;
        let prevData = this.state.treeData;
        if (rowInfo.node.type === "REGION") {
            prevData.forEach((region, regionKey, regionArr) => {
                if (region.tempId === rowInfo.node.tempId) {
                    if (resData !== undefined) region.children = resData; // if data coming from response then assign it to children
                    region.expanded = checked !== undefined ? true : !region.expanded; // clicked from checkbox always axpand true or clicked from icon do it true/false
                    if (checkedValue !== undefined) { // if checked true of false
                        this.updateIntermediateSubscribedWatchlist(rowInfo.node.type, checkedValue, region, regionArr);
                    }
                    if (region.children && checkedValue !== undefined) { // if substation available
                        region.children.forEach(substation => {
                            if (checkedValue !== undefined) {
                                substation.selectionIntermediate = false;
                                substation[this.props.checkedLableName] = checkedValue;
                                substation.checked = checkedValue;
                            } else {
                                substation.selectionIntermediate = region.checked ? false : substation.selectionIntermediate;
                                substation[this.props.checkedLableName] = region.checked ? true : substation[this.props.checkedLableName];
                            }
                            if (substation.children) { // if Feeder available
                                substation.children.forEach(feeder => {
                                    if (checkedValue !== undefined) {
                                        feeder.selectionIntermediate = false;
                                        feeder[this.props.checkedLableName] = substation.checked ? substation.checked : false;
                                        feeder.checked = checkedValue;
                                    } else {
                                        feeder.selectionIntermediate = false;
                                        feeder[this.props.checkedLableName] = substation.checked ? true : feeder[this.props.checkedLableName];
                                    }
                                })
                            }
                        })
                    }
                }
            })
        } else if (rowInfo.node.type === "SUBSTATION") {
            prevData.forEach((region, regionKey, regionArr) => {
                if (region.children) {
                    region.children.forEach((substation, substationKey, subStationArr) => {
                        if (substation.tempId === rowInfo.node.tempId) {
                            if (resData !== undefined) substation.children = resData
                            substation.expanded = checked !== undefined ? true : !substation.expanded;
                            if (checkedValue !== undefined) { // if checked true or false
                                this.updateIntermediateSubscribedWatchlist(rowInfo.node.type, checkedValue, region, regionArr, substation, subStationArr)
                            }
                            if (substation.children) {
                                substation.children.forEach((feeder, feederKey, feederArr) => {
                                    if (checkedValue !== undefined) { // if checked true or false
                                        feeder.selectionIntermediate = false;
                                        feeder[this.props.checkedLableName] = substation.checked ? substation.checked : false;
                                        feeder.checked = checkedValue;
                                    } else {
                                        feeder.selectionIntermediate = false;
                                        feeder[this.props.checkedLableName] = substation.checked !== undefined ? substation.checked : feeder[this.props.checkedLableName];
                                        feeder.checked = substation.checked !== undefined ? substation.checked : feeder[this.props.checkedLableName];
                                    }
                                })
                            }
                        }
                    })
                }
            })
        } else if (rowInfo.node.type === "FEEDER") {
            prevData.forEach((region, regionKey, regionArr) => {
                if (region.children) {
                    region.children.forEach((substation, subStationkey, subStationArr) => {
                        if (substation.children) {
                            substation.children.forEach((feeder, feederKey, feederArr) => {
                                if (feeder.tempId === rowInfo.node.tempId) {
                                    if (resData !== undefined) feeder.children = resData
                                    feeder.expanded = checked !== undefined ? true : !feeder.expanded; // clicked from checkbox always axpand true or clicked from icon do it true/false
                                    if (checkedValue !== undefined) { // if checked true or false
                                        this.updateIntermediateSubscribedWatchlist(rowInfo.node.type, checkedValue, region, regionArr, substation, subStationArr, feeder, feederArr)
                                    }
                                    if (feeder.children) {
                                        feeder.children.forEach((site, siteKey, siteArr) => {
                                            if (checkedValue !== undefined) { // if checked true or false
                                                site.selectionIntermediate = false;
                                                site[this.props.checkedLableName] = feeder.checked ? feeder.checked : false;
                                                site.checked = checkedValue;
                                            } else {
                                                site.selectionIntermediate = false;
                                                site[this.props.checkedLableName] = feeder.checked !== undefined ? feeder.checked : site[this.props.checkedLableName];
                                                site.checked = feeder.checked !== undefined ? feeder.checked : site[this.props.checkedLableName];
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    })
                }
            })
        } else if ((rowInfo.node.type === "LATERAL") || (rowInfo.node.type === "SITE") ) {
            prevData.forEach((region, regionKey, regionArr) => {
                if (region.children) {
                    region.children.forEach((substation, subStationkey, subStationArr) => {
                        if (substation.children) {
                            substation.children.forEach((feeder, feederKey, feederArr) => {
                                if (feeder.children) {
                                    feeder.children.forEach((site, siteKey, siteArr) => {
                                        if (site.tempId === rowInfo.node.tempId) {
                                            if (resData !== undefined) site.children = resData
                                            site.expanded = checked !== undefined ? true : !site.expanded; // clicked from checkbox always axpand true or clicked from icon do it true/false
                                            if (checkedValue !== undefined) { // if checked true or false
                                                this.updateIntermediateSubscribedWatchlist(rowInfo.node.type, checkedValue, region, regionArr, substation, subStationArr, feeder, feederArr, site, siteArr);
                                            }
                                            if (site.children) {
                                                site.children.forEach((latsite, latsiteKey, latsiteArr) => {
                                                    if (checkedValue !== undefined) { // if checked true or false
                                                        latsite.selectionIntermediate = false;
                                                        latsite[this.props.checkedLableName] = feeder.checked ? feeder.checked : false;
                                                        latsite.checked = checkedValue;
                                                    } else {
                                                        latsite.selectionIntermediate = false;
                                                        latsite[this.props.checkedLableName] = feeder.checked !== undefined ? feeder.checked : latsite[this.props.checkedLableName];
                                                        latsite.checked = feeder.checked !== undefined ? feeder.checked : latsite[this.props.checkedLableName];
                                                    }
                                                })
                                            }
                                        }
                                    });
                                }
                            })
                        }
                    })
                }
            })
        } else if (rowInfo.node.type === "LATERAL_SITE") {
            prevData.forEach((region, regionKey, regionArr) => {
                if (region.children) {
                    region.children.forEach((substation, subStationkey, subStationArr) => {
                        if (substation.children) {
                            substation.children.forEach((feeder, feederKey, feederArr) => {
                                if (feeder.children) {
                                    feeder.children.forEach((site, siteKey, siteArr) => {
                                        if (site.children) {
                                            site.children.forEach((latsite, latsiteKey, latsiteArr) => {
                                                if (latsite.tempId === rowInfo.node.tempId) {
                                                    if (resData !== undefined) latsite.children = resData
                                                    latsite.expanded = checked !== undefined ? true : !latsite.expanded; // clicked from checkbox always axpand true or clicked from icon do it true/false
                                                    if (checkedValue !== undefined) { // if checked true or false
                                                        this.updateIntermediateSubscribedWatchlist(rowInfo.node.type, checkedValue, region, regionArr, substation, subStationArr, feeder, feederArr, site, siteArr, latsite, latsiteArr);
                                                    }
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });

        }
        this.setState({ treeData: prevData, isLoding: false });
        if (checkedValue !== undefined) this.updateCheckedData(prevData, this.props.checkedLableName, this.props.expandUpto )
    }

    updateIntermediateSubscribedWatchlist = (type, checkedValue, region, regionArr, substation, subStationArr, feeder, feederArr, site, siteArr, latsite, latsiteArr) => {
        if (type === 'REGION') {
            region.selectionIntermediate = false;
            region[this.props.checkedLableName] = checkedValue;
            region.checked = checkedValue;
        } else if (type === 'SUBSTATION') {
            substation.checked = checkedValue;
            substation.selectionIntermediate = false;
            substation[this.props.checkedLableName] = checkedValue;
            region.selectionIntermediate = this.checkinterMediateAndWatchlist(subStationArr).selectionIntermediate;
            region[this.props.checkedLableName] = this.checkinterMediateAndWatchlist(subStationArr)[this.props.checkedLableName];
            region.checked = this.checkinterMediateAndWatchlist(subStationArr)[this.props.checkedLableName];
        } else if (type === 'FEEDER') {
            feeder.checked = checkedValue;
            feeder.selectionIntermediate = false;
            feeder[this.props.checkedLableName] = checkedValue;
            substation.selectionIntermediate = this.checkinterMediateAndWatchlist(feederArr).selectionIntermediate;
            substation[this.props.checkedLableName] = this.checkinterMediateAndWatchlist(feederArr)[this.props.checkedLableName];
            region.selectionIntermediate = this.checkinterMediateAndWatchlist(subStationArr).selectionIntermediate;
            region[this.props.checkedLableName] = this.checkinterMediateAndWatchlist(subStationArr)[this.props.checkedLableName];

            substation.checked = this.checkinterMediateAndWatchlist(feederArr)[this.props.checkedLableName];
            region.checked = this.checkinterMediateAndWatchlist(subStationArr)[this.props.checkedLableName];
        } else {
            if ((type === 'SITE') || (type === 'LATERAL')) {
                site.checked = checkedValue;
                site.selectionIntermediate = false;
                site[this.props.checkedLableName] = checkedValue;
            } else if (type === 'LATERAL_SITE') {
                latsite.checked = checkedValue;
                latsite.selectionIntermediate = false;
                latsite[this.props.checkedLableName] = checkedValue;
                site.selectionIntermediate = this.checkinterMediateAndWatchlist(latsiteArr).selectionIntermediate;
                site[this.props.checkedLableName] = this.checkinterMediateAndWatchlist(latsiteArr)[this.props.checkedLableName];
                site.checked = this.checkinterMediateAndWatchlist(latsiteArr)[this.props.checkedLableName];
            }
            feeder.selectionIntermediate = this.checkinterMediateAndWatchlist(siteArr).selectionIntermediate;
            feeder[this.props.checkedLableName] = this.checkinterMediateAndWatchlist(siteArr)[this.props.checkedLableName];
            substation.selectionIntermediate = this.checkinterMediateAndWatchlist(feederArr).selectionIntermediate;
            substation[this.props.checkedLableName] = this.checkinterMediateAndWatchlist(feederArr)[this.props.checkedLableName];
            region.selectionIntermediate = this.checkinterMediateAndWatchlist(subStationArr).selectionIntermediate;
            region[this.props.checkedLableName] = this.checkinterMediateAndWatchlist(subStationArr)[this.props.checkedLableName];

            feeder.checked = this.checkinterMediateAndWatchlist(siteArr)[this.props.checkedLableName];
            substation.checked = this.checkinterMediateAndWatchlist(feederArr)[this.props.checkedLableName];
            region.checked = this.checkinterMediateAndWatchlist(subStationArr)[this.props.checkedLableName];
        }
    }

    checkinterMediateAndWatchlist = (data) => {

        let checkifAllAnyTrue = data.filter(item => item.selectionIntermediate === true);
        let checkifAllSubscTrue = data.filter(item => item.selectionIntermediate === false && item[this.props.checkedLableName] === true)
        let intermediate = {};
        if (checkifAllAnyTrue.length > 0) {
            intermediate.selectionIntermediate = true;
            intermediate[this.props.checkedLableName] = false;
        }
        if (checkifAllSubscTrue.length === data.length) {
            intermediate.selectionIntermediate = false;
            intermediate[this.props.checkedLableName] = true;
        }
        if (checkifAllSubscTrue.length < data.length) {
            intermediate.selectionIntermediate = true;
            intermediate[this.props.checkedLableName] = false;
        }
        if (checkifAllAnyTrue.length === 0 && checkifAllSubscTrue.length === 0) {
            intermediate.selectionIntermediate = false;
            intermediate[this.props.checkedLableName] = false;
        }
        return intermediate
    }

    indetSetter = (checked, el) => {
        if (el) el.indeterminate = checked
    }

    updateCheckedData = (allData, checkedLableName, expandUpto ) => {
        let treeData = {
            regionNames: [],
            substationHierIds: [],
            feederIds: [],
            lateralIds: [],
            siteIds: [],
            lateralSiteIds: [],
            selectedObj: [],
        };
        allData.forEach(function (obj) {
            if (obj.checked === true || obj.selectionIntermediate === true || (obj.selectionIntermediate === false && obj[checkedLableName] === true)) {
                treeData.selectedObj.push(obj.name);
                let subsChildren = obj.hasOwnProperty("children") ? obj.children : [];
                if (subsChildren.length > 0) {
                    subsChildren.forEach(function (subobj) {
                        if (subobj.checked === true || subobj.selectionIntermediate === true || (subobj.selectionIntermediate === false && subobj[checkedLableName] === true)) {
                            let feederChildren = subobj.hasOwnProperty("children") ? subobj.children : [];
                            if (feederChildren.length > 0) {
                                feederChildren.forEach(function (feederobj) {
                                    if (feederobj.checked === true || feederobj.selectionIntermediate === true || (feederobj.selectionIntermediate === false && feederobj[checkedLableName] === true)) {
                                        let siteChildren = feederobj.hasOwnProperty("children") ? feederobj.children : [];
                                        if (siteChildren.length > 0) {
                                            siteChildren.forEach(function (siteobj) {
                                                if (siteobj.checked === true || siteobj.selectionIntermediate === true || (siteobj.selectionIntermediate === false && siteobj[checkedLableName] === true)) {
                                                    let latSiteChildren = siteobj.hasOwnProperty("children") ? siteobj.children : [];
                                                    if (latSiteChildren.length > 0) {
                                                        latSiteChildren.forEach(function (latSiteobj) {
                                                            if (latSiteobj.checked === true || latSiteobj.selectionIntermediate === true || (latSiteobj.selectionIntermediate === false && latSiteobj[checkedLableName] === true)) {
                                                                treeData.lateralSiteIds.push(latSiteobj.id);
                                                            }
                                                        });
                                                    } else {
                                                        if (siteobj.type === 'SITE') {
                                                            treeData.siteIds.push(siteobj.id);
                                                        } else {
                                                            treeData.lateralIds.push(siteobj.id);
                                                        }
                                                    }
                                                }
                                            });
                                        } else {
                                            treeData.feederIds.push(feederobj.id);
                                        }
                                    }
                                });
                            } else {
                                treeData.substationHierIds.push(subobj.id);
                            }
                        }
                    });
                } else {
                    treeData.regionNames.push(obj.name);
                }
            }
        });
        this.props.passUpdatedData(treeData) 
    }

    render() {
        const { expandUpto, checkedLableName, checkBoxVisible } = this.props;
        return (
            <div className="checkbox-tree">
                { this.state.isLoding ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                {
                    this.state.treeData && this.state.treeData.length > 0 ?
                    <SortableTree
                        treeData={this.state.treeData.map(item => item)}
                        onChange={treeData => this.setState({ treeData })}
                        theme={FileExplorerTheme}
                        canDrag={false}
                        generateNodeProps={clickedNode => ({
                            onClick: (event) => this.handleClickOnItem(clickedNode, event),
                            icons: [
                                <div className="container-fluid">
                                    <span className="watchListItemPlusMinus" >
                                        { ((expandUpto == 5) && (clickedNode.node.type !== 'SITE') && (clickedNode.node.type !== 'LATERAL_SITE') ) ? <FontAwesomeIcon className="cursor-pointer" icon={clickedNode.node.expanded ? faMinus : faPlus} size="sm" /> : ""}
                                        { (this.checkLevelMatch(clickedNode.node.type, expandUpto) && (expandUpto <= 4))  ? <FontAwesomeIcon className="cursor-pointer" icon={clickedNode.node.expanded ? faMinus : faPlus} size="sm" /> : ""}
                                    </span>
                                    <span className="watchListItemIcon"> {treeViewUtility.getIcon(clickedNode)}</span>
                                    {
                                        ((checkBoxVisible === 'all') || (checkBoxVisible === clickedNode.node.type)) ?
                                        <span className="checkboxList">
                                            <input type="checkbox"
                                                checked={(clickedNode.node[checkedLableName] === true && clickedNode.node.selectionIntermediate === false) || (clickedNode.node[checkedLableName] === false && clickedNode.node.selectionIntermediate === true) ? true : false}
                                                onClick={e => e.stopPropagation()}
                                                ref={this.indetSetter.bind(this, clickedNode.node.selectionIntermediate)}
                                                onChange={(event) => this.handleClickOnItem(clickedNode, event)}
                                            />
                                        </span> : null
                                    }
                                </div>
                            ],
                        })}
                    /> : null
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tree: state.treeviewData.tree,
    };
}

export default connect(mapStateToProps, {})(CustomReportTree);
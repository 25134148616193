import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import moment from 'moment-timezone';

import { getTempUnit, getNodePath, getBrowserTimeZone, dateConvertion } from '../../../services/utilityService';
import { monitorConfig } from "../../../utility/monitorUtility";
import { getNodeData, exportData, getPrimaryCurrVoltage, getTransformerLoad} from '../services/monitorService';
import { setDownloadCSV } from '../../../filters/commonFunction';

export const MonitorContext = React.createContext();

class MonitorProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      treeNode: this.props.treeNode,
      loading: false,
      emptyGraphData: false,
      disableExport: true,
      exportLogi: false,
      disablePropertyView: true,
      maintainZoom: false,
      chartList: [],
      graphData: [],
      roleAndPermission: this.props.roleAndPermission,
      elementHeight: 0,
      ...monitorConfig
    }
    this.commonChart = null
  }

  componentWillUnmount() {
    Object.entries(monitorConfig).map(([key, data], k) => { 
      return monitorConfig[key] = ["secondaryLogIPoints","secondaryLogIPointsList"].includes(key) ? this.state["secondaryLogIPointsList"].filter(e => !["Phase Angle","Power Factor","kVAR Injected"].includes(e.text)) : (key !== 'monitorChartOptions' && key !== "logIPointsList" && key != "logIPoints") ? this.state[key] : data;
    });
    this.props.dashboardFlag && this.props.setDashboardFlag()
  }

  componentDidMount = async () => {
    if (this.state.treeNode && Object.keys(this.state.treeNode).length > 0 && (!this.props.treeLoader)) {
      this.setState({ 
        disablePropertyView: true,
        chartList: [],
        graphData: [],
      });
      if(!this.props.dashboardFlag){
      if(this.state.treeNode.siteDeviceType === "VC10"){
      this.getVC10LogIData(false) ;
      this.setState({
        secondaryLogIPointsList:[...monitorConfig.vc10SecondaryLogIPointsList],
        secondaryLogIPoints:[...monitorConfig.vc10SecondaryLogIPoints]
      });
      }
      else if(this.state.treeNode.siteDeviceType !== "UM1")
      this.getLogIData(false); 
      else{
        this.getUM1LogData(false);
        this.setState({
          logIPoints:this.state.logIPoints.length > 0 ? this.state.logIPointsList.filter(e => e.value === "i") : this.state.logIPoints,
          logIPointsList:this.state.logIPointsList.filter(e => e.value === "i"),
          secondaryLogIPointsList:[...monitorConfig.secondaryLogIPointsList],
          secondaryLogIPoints:[...monitorConfig.secondaryLogIPoints]
        })
      }
    }
    }
  }

  setDateByRange = (range) => {
    if (range && range > 0) {
      let currentDate = (range !== 1) ? new Date().getTime() - (1000 * 3600 * 24) : new Date().getTime();
      let endDateTimestamp = dateConvertion(currentDate, 'endDate', this.props.roleAndPermission.timezone);
      let startDateTimestamp = endDateTimestamp + 1 - (range * 1000 * 60 * 60 * 24)
      this.setState({ 
        endDate: dateConvertion(endDateTimestamp, 'short', this.props.roleAndPermission.timezone),
        startDate: dateConvertion(startDateTimestamp, 'short', this.props.roleAndPermission.timezone),
        maxDate:dateConvertion(endDateTimestamp, 'short', this.props.roleAndPermission.timezone)
      }, () => {
        if(this.state.treeNode.siteDeviceType === "VC10")
          this.getVC10LogIData(false);
        else if(this.state.treeNode.siteDeviceType !== "UM1")
          this.getLogIData(false); 
        else
          this.getUM1LogData(false);
       });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.treeNode) {
      let sitesList = [];
      if (nextProps.treeNode['children']) {
        let filteredChilds = (nextProps.treeNode && nextProps.treeNode.type === 'FEEDER') ? nextProps.treeNode['children'].filter(item => item.type === 'SITE') : (nextProps.treeNode && nextProps.treeNode.type === 'LATERAL') ? nextProps.treeNode['children'].filter(item => item.type === 'LATERAL_SITE') : [];
        filteredChilds.forEach((child, i) => {
          sitesList.push({ name: 'sites', text: child.name, value: child.name, type: child.type, keyIndex: i });
        });
      }
      if (JSON.stringify(sitesList) !== JSON.stringify(prevState.sitesList)) {
        return {
          treeNode: nextProps.treeNode,
          sitesList: sitesList,
          sites: sitesList
        }    
      }else{
        return{
          treeNode: nextProps.treeNode
        }
      }
    }
    return null;
  }

  getPropertyvalue = (objPhase, results, point, i) => {
    for(var p in point) {
      var value = point[p];
      if(p !== 't' && p !== 'id' && value === null && i === 0){
        results[objPhase+p+'_min'] = null;
        results[objPhase+p+'_max'] = null;   
      }
      if(p !== 't' && p !== 'id' && value !== null){
        if (i === 0) {
          results[objPhase+p+'_min'] = parseFloat(value).toFixed(3);
          results[objPhase+p+'_max'] = parseFloat(value).toFixed(3);
        }
        results[objPhase+p+'_count'] ++;
        results[objPhase+p+'_sum'] += value;
        if (parseFloat(value) > parseFloat(results[objPhase+p+'_max']) || results[objPhase+p+'_max'] === null) results[objPhase+p+'_max'] = parseFloat(value).toFixed(3);
        if (parseFloat(value) < parseFloat(results[objPhase+p+'_min']) || results[objPhase+p+'_min'] === null) results[objPhase+p+'_min'] = parseFloat(value).toFixed(3);
        results[objPhase+p+'_max'] = parseFloat(results[objPhase+p+'_max']).toFixed(3).replace(/[.,]000$/, "");
        results[objPhase+p+'_min'] = parseFloat(results[objPhase+p+'_min']).toFixed(3).replace(/[.,]000$/, "");
      }
    }
    return results;
  }

  updatePhaseDetails = (results,objPhase) => {
    let propertyList = [];
    if([ "UM1","VC10"].includes(this.state.treeNode.siteDeviceType) && ["SITE","LATERAL_SITE"].includes(this.state.treeNode.type))
    propertyList = [
      { name: 'type', suffix: '', type: ''},
      { name:'min',suffix: '', type: 'min'},
      { name:'max',suffix: '', type: 'max'},
      { name:'avg',suffix: '', type: 'avg'}
    ]
    else
    propertyList = [
      { name: 'type', suffix: '', type: ''},
      { name: 'load_min', suffix: ' (A<sub>rms</sub>)', type: 'load'},
      { name: 'load_max', suffix: ' (A<sub>rms</sub>)', type: 'load'},
      { name: 'load_avg', suffix: ' (A<sub>rms</sub>)', type: 'load'},
      { name: 'temperature_min', suffix: ' (' + getTempUnit('value') + ')', type: 'temperature'},
      { name: 'temperature_max', suffix: ' (' + getTempUnit('value') + ')', type: 'temperature'},
      { name: 'temperature_avg', suffix: ' (' + getTempUnit('value') + ')', type: 'temperature'}
    ];
    let um1Device = [ "UM1","VC10"].includes(this.state.treeNode.siteDeviceType) && ["SITE","LATERAL_SITE"].includes(this.state.treeNode.type)
    let phaseList = this.state.treeNode.siteDeviceType === "UM1" && ["SITE","LATERAL_SITE"].includes(this.state.treeNode.type) ?
          [{name: 'phaseACurrent', value: 'phaseACurrent', keyIndex: 0},
          {name: 'x1Current', value: 'x1Current', keyIndex: 1},
          {name: 'x3Current', value: 'x3Current', keyIndex: 2},
          {name: 'v120X1', value: 'v120X1', keyIndex: 3},
          {name: 'v120X3', value: 'v120X3', keyIndex: 4},
          {name: 'primaryLoad', value: 'primaryLoad', keyIndex: 5},
          {name: 'kvaThreshold', value: 'kvaThreshold', keyIndex: 6},
          {name: 'kW', value: 'kW', keyIndex: 7},
          {name: 'kVAR', value: 'kVAR', keyIndex: 8},
        ] : this.state.treeNode.siteDeviceType === "VC10" && ["SITE","LATERAL_SITE"].includes(this.state.treeNode.type) ? 
        [
          {name: 'voltage', value: 'voltage', keyIndex: 0},
          {name: 'kVARInjected', value: 'kVARInjected', keyIndex: 1},
          {name: 'x1Current', value: 'x1Current', keyIndex: 2},
          {name: 'x3Current', value: 'x3Current', keyIndex: 3},
          {name: 'primaryLoad', value: 'primaryLoad', keyIndex: 4},
          {name: 'kvaThreshold', value: 'kvaThreshold', keyIndex: 5},
          {name: 'kW', value: 'kW', keyIndex: 6},
          {name: 'kVAR', value: 'kVAR', keyIndex: 7},
          {name: 'x1PowerFactor', value: 'x1PowerFactor', keyIndex: 8},
          {name: 'x3PowerFactor', value: 'x3PowerFactor', keyIndex: 9},
          {name: 'x1PhaseAngle', value: 'x1PhaseAngle', keyIndex: 10},
          {name: 'x3PhaseAngle', value: 'x3PhaseAngle', keyIndex: 11},
          {name: 'voltageSetPoint', value: 'voltageSetPoint', keyIndex: 12},
          
        ] : this.state['phaseList'];
    propertyList.forEach((property, pty) => {
      phaseList.forEach((phase) => {
        if (property.name.indexOf('avg') === -1) {
          property[phase.value] = !um1Device ? (results[phase.value + property.name] || " - ") : (results[objPhase + property.name] || results[objPhase + phase.value + "_" + property.name] || " - ");
        } else {   
          property[phase.value] = !um1Device ? typeof(results[phase.value + property.type + '_min'] && results[phase.value + property.type + '_max']) === "string" &&  results[phase.value + property.type + '_min'] && results[phase.value + property.type + '_max'] === "0" ? 
          "0" : (results[phase.value + property.type + '_count'] !== undefined && results[phase.value + property.type + '_count'] !== 0 ) ? parseFloat(results[phase.value + property.type + '_sum'] / results[phase.value + property.type + '_count']).toFixed(3).replace(/[.,]000$/, "") : " - " :    
          typeof(results[objPhase +  phase.value + '_min'] && results[objPhase +  phase.value + '_max']) === "string" &&  results[objPhase +  phase.value + '_min'] && results[objPhase +  phase.value + '_max'] === "0" ? 
          "0" : (results[objPhase +  phase.value + '_count'] !== undefined && results[objPhase +  phase.value + '_count'] !== 0 ) ? parseFloat(results[objPhase +  phase.value + '_sum'] / results[objPhase +  phase.value + '_count']).toFixed(3).replace(/[.,]000$/, "") : " - ";
        }
      });
    });

    return propertyList;
  }

  processData = (siteData) => {
    let results = {};
    siteData['details'].map(detailObj => {
			let existingobj = {};
			let addOnArr = [];
      let objPhase = detailObj.phase;
        let propertyName = ["SITE","LATERAL_SITE"].includes(this.state.treeNode.type) &&  detailObj.type === "UM1" ? monitorConfig.um1PropertyName : ["SITE","LATERAL_SITE"].includes(this.state.treeNode.type) &&  detailObj.type === "VC10" ? monitorConfig.vc10PropertyName : monitorConfig.propertyName;
        propertyName.forEach((property) => { 
          results[objPhase + property] = (property === 'type') ? detailObj.type : 0;
        });
			if (detailObj && detailObj.pointData && detailObj.pointData.length) {
        detailObj['pointData'].map((pointDataObj, i) => {
          let newTimestamp = 0, addonObj = {};
					let diff = (i !== 0) ? (pointDataObj.t - existingobj.t)/(60*1000) : 0;
					let diffValue = 5;
					if (siteData.pointInterval) {
						diffValue = ((parseInt(siteData.pointInterval, 10) === 5) && siteData.zm1presence) ? 60 : parseInt(siteData.pointInterval, 10);
					} else if (siteData.zm1presence) {
						diffValue = 60;
					}
					if ((diff > diffValue) && (i !== 0)) {
						// Add the null valueCalculate the time for null value
						newTimestamp = parseInt((existingobj.t + ((pointDataObj.t - existingobj.t)/2)), 10);
						addonObj['inx'] = i;
						addonObj['id'] = parseInt(pointDataObj.id, 10) + 1000;
					}
					if (newTimestamp > 0) {
						addonObj['t'] = newTimestamp;
            if(detailObj.type === "VC10"){
             // addonObj['load'] = null;
              addonObj['x1Current'] = null;
              addonObj['x3Current'] = null;
              addonObj['primaryLoad'] = null;
              addonObj['kvaThreshold'] = null;
              addonObj['kW'] = null;
              addonObj['kVAR'] = null;
              addonObj['x1PowerFactor'] = null;
              addonObj['x3PowerFactor'] = null;
              addonObj['x1PhaseAngle'] = null;
              addonObj['x3PhaseAngle'] = null;
              addonObj['voltage'] = null;
              addonObj['kVARInjected'] = null;
              addonObj['voltageSetPoint'] = null;
              addonObj['voltageSetPointRange'] = [null,null];
              addonObj['kWRange'] = [null,null];
            }
            else if(detailObj.type !== "UM1"){
              addonObj['load'] = null;
						  addonObj['temperature'] = null;
            }else{
              addonObj['load'] = null;
						  addonObj['phaseACurrent'] = null;
              addonObj['x1Current'] = null;
              addonObj['x3Current'] = null;
              addonObj['v120X1'] = null;
              addonObj['v120X3'] = null;
              addonObj['primaryLoad'] = null;
              addonObj['kvaThreshold'] = null;
              addonObj['kW'] = null;
              addonObj['kVAR'] = null;
            }
					}
					if (newTimestamp > 0) {
						addOnArr.push(addonObj);
					}
          results = this.getPropertyvalue(objPhase, results, pointDataObj, i);
					existingobj = pointDataObj;
          return pointDataObj;
        });
    }
			if(addOnArr.length > 0){
				let preparedPointDate = detailObj.pointData.concat(addOnArr);
        detailObj.pointData = preparedPointDate.sort((a, b) => parseInt(a['t'], 10) - (parseInt(b['t'], 10)));
			}
      return detailObj;
    });
    siteData['phaseDetails'] = this.updatePhaseDetails(results,siteData['details'].length > 0 ? siteData['details'][0].phase : "");
    return siteData;
  }

  updateLogiData = (data, maintainZoom) => {
    let graphData = [];
    let hasPointData = false;
    let sitesList = (this.state.treeNode && ((this.state.treeNode.type === 'FEEDER') || (this.state.treeNode.type === 'LATERAL'))) ? this.state.sitesList : [data];
    sitesList.forEach((site, s) => {
      let matchedSite = (!site.hasOwnProperty('type')) ? [site] : (site.type === 'SITE') ? data.filter(d => ( (site.value === d.name) && (d.lateralName === null))) : data.filter(d => ( (site.value === d.name) && (d.lateralName !== null)));
      if (matchedSite && matchedSite.length > 0) {
        matchedSite[0].details.forEach((detailObj, d) => {
          if(detailObj.pointData && detailObj.pointData.length > 0){
            hasPointData = true;
          }
        });
        matchedSite[0].breadCrumb = matchedSite[0].regionName +' | '+ matchedSite[0].substationName +' | '+ matchedSite[0].feederName +' | ';
        matchedSite[0].breadCrumb += (matchedSite[0].lateralName === null) ?  matchedSite[0].name : matchedSite[0].lateralName +' | '+ matchedSite[0].name;
        graphData.push(this.processData(matchedSite[0]) );  
      }
    });
    this.setState({ loading: false, disableExport: !hasPointData, graphData: graphData, emptyGraphData: (sitesList && sitesList.length === 0) || graphData.length === 0 ? true : false, 
    });
  }

  getLogIData = async(maintainZoom) => {
    let exportLogi = (this.state.roleAndPermission && this.state.roleAndPermission.emPermissions) ? this.state.roleAndPermission.emPermissions.filter(permission => permission.permissionName === "EXPORT_LOGI") : [];
    if (this.state.showLogIByType.indexOf(this.state.treeNode.type) !== -1) {
      if (!this.state.startDate && !this.state.endDate) {
        return this.setDateByRange(this.state.selectedZoomRange);
      }
      const params = {'NODEPATH': getNodePath(this.state.treeNode.routeParams, true), 'apiType': 'data'};
      const reqBody = {
        'phase' : ['A','B','C'],
        'pointInterval': 0,
        'phaseType' : (this.state.logIPoints && this.state.logIPoints.length === 1 && this.state.logIPoints[0].value === 'i') ? ['load'] : (this.state.logIPoints && this.state.logIPoints.length === 1 && this.state.logIPoints[0].value === 't') ? ['temperature'] : ['load','temperature'],
        'timeZone': getBrowserTimeZone()
      };
      let startDateStr = dateConvertion(this.state.startDate+' 00:00:00:000', 'offsetTimestamp', this.props.roleAndPermission.timezone);
      reqBody['starttimestamp'] = (maintainZoom) ? dateConvertion(this.state.startDateTimestamp, 'serviceFormat', this.props.roleAndPermission.timezone) : dateConvertion(startDateStr, 'serviceFormat', this.props.roleAndPermission.timezone);
      let endDateStr = dateConvertion(this.state.endDate+' 23:59:59:999', 'offsetTimestamp', this.props.roleAndPermission.timezone);
      reqBody['endtimestamp'] = (maintainZoom) ? dateConvertion(this.state.endDateTimestamp, 'serviceFormat', this.props.roleAndPermission.timezone) : dateConvertion(endDateStr, 'serviceFormat', this.props.roleAndPermission.timezone);
      this.setState({ 
        loading: true, 
        emptyGraphData: false, 
        endDateTimestamp: (maintainZoom) ? this.state.endDateTimestamp : (endDateStr + 1000), 
        startDateTimestamp: (maintainZoom) ? this.state.startDateTimestamp : startDateStr, 
        maintainZoom: maintainZoom,
        exportLogi: (exportLogi && exportLogi.length > 0) ? exportLogi[0].hasPermission : false
       });
      getNodeData(params, reqBody)
      .then(data => {
        if(data.length === 0) {
          this.setState({ loading: false, emptyGraphData: true });
        } else {
          this.updateLogiData(data, maintainZoom);
        }
      }).catch(err => {
        this.setState({ loading: false })
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.treeNode && this.props.treeNode) {
      if ((prevProps.treeNode['name'] !== this.props.treeNode['name']) || (prevProps.treeNode['type'] !== this.props.treeNode['type']) || (prevProps.treeNode['id'] !== this.props.treeNode['id']) || this.props.loadDevicesList ) {
        this.props.setLoadDevicesList(false);
        this.setState({ 
          treeNode: this.props.treeNode,
          disablePropertyView: true,
          graphData: [],
          chartList: [],
          logIPointsList:monitorConfig.logIPointsList
        }, () => {
          if(!this.props.dashboardFlag){
          if(this.state.treeNode.siteDeviceType === "VC10"){
          this.getVC10LogIData(false) ; 
          this.setState({
            secondaryLogIPointsList:[...monitorConfig.vc10SecondaryLogIPointsList],
            secondaryLogIPoints:[...monitorConfig.vc10SecondaryLogIPoints]
          });
          }
          else if(this.state.treeNode.siteDeviceType !== "UM1")
          this.getLogIData(false);
          else{
            this.getUM1LogData(false);
            this.setState({
              logIPoints:this.state.logIPoints.length > 0 ? this.state.logIPointsList.filter(e => e.value === "i") : this.state.logIPoints,
              logIPointsList:this.state.logIPointsList.filter(e => e.value === "i"),
              secondaryLogIPointsList:[...monitorConfig.secondaryLogIPointsList],
              secondaryLogIPoints:[...monitorConfig.secondaryLogIPoints]
            })
          }
        }
        } );
      }
    } else if(this.props.treeNode) {
      this.setState({
        treeNode: this.props.treeNode,
       // logIPointsList:monitorConfig.logIPointsList
      },()=>{
        if(!this.props.dashboardFlag){
        if(this.state.treeNode.siteDeviceType === "VC10"){
        this.getVC10LogIData(false);
        this.setState({
          secondaryLogIPointsList:[...monitorConfig.vc10SecondaryLogIPointsList],
          secondaryLogIPoints:[...monitorConfig.vc10SecondaryLogIPoints]
        });
        }
        else if(this.state.treeNode.siteDeviceType !== "UM1")
        this.getLogIData(false); 
        else{
          this.getUM1LogData(false);
          this.setState({
            logIPoints:this.state.logIPoints.length > 0 ? this.state.logIPointsList.filter(e => e.value === "i") : this.state.logIPoints,
            logIPointsList:this.state.logIPointsList.filter(e => e.value === "i"),
            secondaryLogIPointsList:[...monitorConfig.secondaryLogIPointsList],
            secondaryLogIPoints:[...monitorConfig.secondaryLogIPoints]
          })
        }
      }
      });
    }
  }

  zoomChange = (value) => {
    if (this.state.selectedZoomRange !== value) {
      this.setState({ 
        graphData:this.state.treeNode.siteDeviceType === "VC10" ? [...this.state.graphData].map(e => {return {...e,hideChart : false}}) : this.state.graphData,
        endDate: null,
        startDate: null,
        selectedZoomRange: value,
        loading: true,
        secondaryLogIPointsList:this.state.treeNode.siteDeviceType === "VC10" ? [...monitorConfig.vc10SecondaryLogIPointsList] : [...monitorConfig.secondaryLogIPointsList],
        secondaryLogIPoints:this.state.treeNode.siteDeviceType === "VC10" ? [...monitorConfig.vc10SecondaryLogIPoints] : [...monitorConfig.secondaryLogIPoints]
      }, () => {
        this.setDateByRange(this.state.selectedZoomRange)
        this.onZoomOut(this.state.chartList)
      });
    }
  }

  calenderCallback = (start, end) => {
    this.setState({ 
      startDate: moment(new Date(start)).format('MM/DD/YYYY'), 
      endDate: moment(new Date(end)).format('MM/DD/YYYY'),
      selectedZoomRange: 0,
      loading:true
    }, () => {  
      if(this.state.treeNode.siteDeviceType === "VC10")
      this.getVC10LogIData(false);
      else if(this.state.treeNode.siteDeviceType !== "UM1") 
        this.getLogIData(false) 
        else
        this.getUM1LogData(false)
      });
  }

  onValChange = (value, field) => {
    if ((field && field[0] === 'logIPoints') || (field === 'logIPoints') ) {
      // Need to handle zoom changes
      this.setState({ [field]: value }, () => this.state.treeNode.siteDeviceType !== "UM1" && this.getLogIData(this.state.maintainZoom) );
    } else {
      this.setState({ [field]: value });
      this.state.treeNode.siteDeviceType === "VC10" && ((Array.isArray(field) && field[0] === "secondaryLogIPoints") || field === "secondaryLogIPoints") && this.setShoworHideGraphFlag(value)
    }
  }

  setShoworHideGraphFlag = (value) => {
      let lTempArr = [...this.state.graphData];
      lTempArr.forEach(e => {
        switch (e.chartType) {
          case "vc10PowerPhase":
            e.hideChart = value.length > 0  && !value.filter(e => e.value === "powerFactor" || e.value === "phaseAngle").length;
            break
          case "vc10Load":
            e.hideChart = value.length > 0  && !value.filter(e => e.value === "l").length;
            break
          case "vc10KWkVAR":
            e.hideChart = value.length > 0  && !value.filter(e => e.value === "kVAR" || e.value === "kW").length;
            break
          case "vc10SecCurr":
            e.hideChart = value.length > 0  && !value.filter(e => e.value === "i").length;
            break
          case "vc10VkVARInj":
            e.hideChart = value.length > 0  && !value.filter(e => e.value === "v" || e.value === "kVARInjected").length;
            break;
        }
      });
      this.setState({ graphData: lTempArr });
  }

  onScaleAfterSetExtremes = (chart, event) => {
    this.commonChart = chart;
    if (chart && !this.state.loading) {
      let extreme = chart.xAxis[0].getExtremes();
      if((extreme.userMax > 1) && (extreme.userMin > 1) && ((extreme.min !== this.state.startDateTimestamp) || (extreme.max !== (this.state.endDateTimestamp)) ) ) {
        this.setState({ startDateTimestamp: new Date(extreme.min).getTime(), endDateTimestamp: new Date(extreme.max).getTime() + 1000,loading: true }, () =>{ 
          if(this.state.treeNode.siteDeviceType === "VC10")
          this.getVC10LogIData(true);
          else if(this.state.treeNode.siteDeviceType !== "UM1")
          this.getLogIData(true); 
          else if((this.state.startDate === moment(this.state.startDateTimestamp).format('MM/DD/YYYY')) && (this.state.endDate === moment(this.state.endDateTimestamp - (1000 * 3600 * 24)).format('MM/DD/YYYY')))
          this.getUM1LogData(true);
          else
          this.getUM1LogData(true);
        });
      }
    }
  }

  onZoomOut = (chart, event) => {
    let extreme = (this.commonChart && this.commonChart.xAxis) ? this.commonChart.xAxis[0].getExtremes() : null;
    let startDateStr = dateConvertion(this.state.startDate+' 00:00:00:000', 'offsetTimestamp', this.props.roleAndPermission.timezone);
    let endDateStr = dateConvertion(this.state.endDate+' 23:59:59:999', 'offsetTimestamp', this.props.roleAndPermission.timezone);
    if(extreme && ((extreme.min !== startDateStr) || (extreme.max !== (endDateStr + 1000))) ) {
      // zoom out with common chart
      chart && chart.forEach((ch, i) => {
        if(ch && Object.keys(ch).length > 0 && ch.resetZoomButton) {
          ch.resetZoomButton.hide();
        }
      });
      if(event)
      this.state.treeNode.siteDeviceType === "VC10" ? this.getVC10LogIData(false) : this.state.treeNode.siteDeviceType !== "UM1" ? this.getLogIData(false) : this.getUM1LogData(false);
    }
  }

  onUpdateXScale = (chart, event) => {
    let extreme = (this.commonChart && this.commonChart.xAxis) ? this.commonChart.xAxis[0].getExtremes() : null;
    let startDateStr = dateConvertion(this.state.startDate+' 00:00:00:000', 'offsetTimestamp', this.props.roleAndPermission.timezone);
    let endDateStr = dateConvertion(this.state.endDate+' 23:59:59:999', 'offsetTimestamp', this.props.roleAndPermission.timezone);
    if(extreme && (extreme.userMax > 1) && (extreme.userMin > 1) && ((extreme.min !== startDateStr) || (extreme.max !== (endDateStr + 1000)) ) ) {
    // Set Extreme after scale Chart zoom
      chart && chart.forEach((ch, i) => {
        if(ch && Object.keys(ch).length > 0 && (!ch.resetZoomButton || ch.resetZoomButton.hasOwnProperty('visibility')) && ch.options) {
          ch.showResetZoom();
        }
      });
    } else if(extreme && (extreme.userMax > 1) && (extreme.userMin > 1) && ((extreme.min === startDateStr) && (extreme.max === (endDateStr + 1000)) && !event.trigger )){
      chart && chart.forEach((ch, i) => {
        if(ch && Object.keys(ch).length > 0 && ch.resetZoomButton !== undefined && Object.keys(ch.resetZoomButton).length !== 0 ) {
          ch.resetZoomButton.hide();
        }
      })
    }
    if(extreme && ((extreme.min !== event.min) || (extreme.max !== event.max) ) ) {
      // Set Extreme for inididual Chart
      this.commonChart.xAxis[0].setExtremes(event.min, event.max, true, false);
    }
    
  }

  getExportData = async(type) => {
    this.setState({ loading: true }, () => {
      const routeParams = this.state.treeNode.routeParams ? this.state.treeNode.routeParams : ["ROOTNODE", this.state.treeNode.id];
      const requestParams = {
        'FORMAT': type,
        'TIMEZONE':this.props.roleAndPermission.timezone,
        'NODEPATH': getNodePath(routeParams, true),
        'apiType': 'data'
      }
      let startDateStr = dateConvertion(this.state.startDate+' 00:00:00:000', 'offsetTimestamp', this.props.roleAndPermission.timezone);
      requestParams['STARTDATE'] = dateConvertion(startDateStr, 'serviceFormat', this.props.roleAndPermission.timezone);
      let endDateStr = dateConvertion(this.state.endDate+' 23:59:59:999', 'offsetTimestamp', this.props.roleAndPermission.timezone);
      requestParams['ENDDATE'] = dateConvertion(endDateStr, 'serviceFormat', this.props.roleAndPermission.timezone);
      exportData(requestParams)
        .then((data) => {
          setDownloadCSV(data);
          this.setState({ loading: false })
            
          
        }).catch((err) => this.setState({ loading: false }))
    })
  }

  //UM1 changes
getUM1LogData = async(maintainZoom) =>{
  let { reqBody, startDateStr, endDateStr, exportLogi } = this.getReqParams(maintainZoom);
  this.setState({ 
    loading: true,
    emptyGraphData: false, 
    endDateTimestamp: (maintainZoom) ? this.state.endDateTimestamp : (endDateStr + 1000), 
    startDateTimestamp: (maintainZoom) ? this.state.startDateTimestamp : startDateStr, 
    maintainZoom: maintainZoom,
    exportLogi: (exportLogi && exportLogi.length > 0) ? exportLogi[0].hasPermission : false
  })
  getPrimaryCurrVoltage(reqBody)
  .then(data => {
    if(Object.keys(data).length === 0) {
     this.setState({ loading: false, emptyGraphData: true });
    } else {
       //need to remove below added temperory line after api call is ready.
      //data[Object.keys(data)[0]].cLogPoints.forEach(e => {e.kVAR = e.phaseACurrent - 20; e.kW = e.x1Current; e.kWRange = [e.x1Current - 10,e.x1Current + 10]})
      if(this.state.treeNode.tier === "Advanced" || this.state.treeNode.tier === null || this.state.treeNode.tier === undefined)
      getTransformerLoad(reqBody)
      .then(transLoadRes => {
        this.formatUm1Data(data,transLoadRes);
      })
      else
      this.formatUm1Data(data,{});
    }
  }).catch(err => {
    this.setState({ loading: false })
  });
}

  formatUm1Data = (params1, params2) => {
    let finalArr = [], arr = [], obj = {}, disExp = true, um1ChartTypes;
    let mainObj = {
      "id": null,
      "name": this.props.treeNode.name,
      "feederName": this.props.treeNode.feederNode,
      "substationName": this.props.treeNode.substationNode,
      "regionName": this.props.treeNode.regionNode,
      "lateralName": null,
      "details": [],
      "pointInterval": null,
    };
   //need to add kva/kvar in second position later
   um1ChartTypes = this.state.treeNode.tier === "Advanced" || !this.state.treeNode.tier ? ["load","kwkvar","secondary", "primary"] : ["primary"]
   um1ChartTypes.forEach(item => {
      mainObj.chartType = item;
      arr.push(JSON.parse(JSON.stringify(mainObj)));
    })
    Object.keys(params1).forEach(item => {
      obj.phase = item;
      obj.type = this.props.treeNode.siteDeviceType;
      obj.pointData = params1[item].cLogPoints;
      arr.forEach(e => { e.pointInterval = e.chartType !== "load" ? params1[item]?.pointInterval : Object.keys(params2).length > 0 ? params2[item]?.pointInterval : []});
      if (params1[item].cLogPoints.length > 0) disExp = false;
    });
    arr.forEach((e, indx) => {
      if (e.chartType === "load" &&  Object.keys(params2).length > 0 && params2[obj.phase]) {
        params2[obj.phase].cLogPoints.forEach(item => item.kvaThreshold = item.kvaThreshold ? parseFloat(item.kvaThreshold) : item.kvaThreshold);
        e.details.push({ ...obj, pointData: params2[obj.phase] ? params2[obj.phase].cLogPoints : null });
      } else e.details.push(Object.keys(obj).length > 0 ? obj : { ...obj, type: "UM1", pointData: [] })
      finalArr.push(this.processData(e));
    });
    let secondaryLogIPointsList = [...this.state.secondaryLogIPointsList];
    let secondaryLogIPoints = [...this.state.secondaryLogIPoints];
    if(finalArr.find(e => e.chartType === "kwkvar")?.details[0]?.pointData?.filter(item => item.kWRange).filter(rangeItem=>rangeItem.kWRange[1]).length === 0){
      finalArr.splice(finalArr.findIndex(e => e.chartType === "kwkvar"),1);
      secondaryLogIPointsList = this.state.secondaryLogIPointsList.filter(e => e.text != "kVAR" && e.text != "kW");
      secondaryLogIPoints = this.state.secondaryLogIPoints.filter(e => e.text != "kVAR" && e.text != "kW")
    }
    if((finalArr.find(e => e.chartType === "load").details[0].pointData.filter(item=>item.primaryLoad||item.primaryLoad===0).length === 0)&&(finalArr.find(e => e.chartType === "load").details[0].pointData.filter(item=>item.kvaThreshold||item.kvaThreshold===0).length === 0)){
      finalArr.splice(finalArr.findIndex(e => e.chartType === "load"),1);
      secondaryLogIPointsList = secondaryLogIPointsList.filter(e => e.text != "Transformer Loading");
      secondaryLogIPoints = secondaryLogIPoints.filter(e => e.text != "Transformer Loading");
    }
    this.setState({ loading: false, graphData: finalArr, disableExport: disExp,secondaryLogIPointsList:secondaryLogIPointsList ,secondaryLogIPoints:secondaryLogIPoints});
  }
  
  getReqParams = (maintainZoom) => {
    let exportLogi = (this.state.roleAndPermission && this.state.roleAndPermission.emPermissions) ? this.state.roleAndPermission.emPermissions.filter(permission => permission.permissionName === "EXPORT_LOGI") : [];
    if (!this.state.startDate && !this.state.endDate) { return this.setDateByRange(this.state.selectedZoomRange); }
    const reqBody = {
      'NODEPATH': this.state.treeNode.type === "LATERAL_SITE" ? getNodePath(this.state.treeNode.routeParams, true, true).replace("lateralSite", "site") : getNodePath(this.state.treeNode.routeParams, true, true),
      'apiType': 'data',
      "ZOOM": maintainZoom
    };
    reqBody.NODEPATH = reqBody.NODEPATH.split("/").slice(1, reqBody.NODEPATH.split("/").length).join("/");
    let startDateStr = dateConvertion(this.state.startDate + ' 00:00:00:000', 'offsetTimestamp', this.props.roleAndPermission.timezone);
    reqBody['STARTDATE'] = (maintainZoom) ? dateConvertion(this.state.startDateTimestamp, 'serviceFormat', this.props.roleAndPermission.timezone) : dateConvertion(startDateStr, 'serviceFormat', this.props.roleAndPermission.timezone);
    let endDateStr = dateConvertion(this.state.endDate + ' 23:59:59:999', 'offsetTimestamp', this.props.roleAndPermission.timezone);
    reqBody['ENDDATE'] = (maintainZoom) ? dateConvertion(this.state.endDateTimestamp, 'serviceFormat', this.props.roleAndPermission.timezone) : dateConvertion(endDateStr, 'serviceFormat', this.props.roleAndPermission.timezone);
    return {
      "reqBody": reqBody,
      "startDateStr": startDateStr,
      "endDateStr": endDateStr,
      "exportLogi": exportLogi
    }
  }
  //VC10 changes
  getVC10LogIData = async (maintainZoom) => {
    let { reqBody, startDateStr, endDateStr, exportLogi } = this.getReqParams(maintainZoom);
    this.setState({
      loading: true,
      emptyGraphData: false,
      endDateTimestamp: (maintainZoom) ? this.state.endDateTimestamp : (endDateStr + 1000),
      startDateTimestamp: (maintainZoom) ? this.state.startDateTimestamp : startDateStr,
      maintainZoom: maintainZoom,
      exportLogi: (exportLogi && exportLogi.length > 0) ? exportLogi[0].hasPermission : false
    })
    getPrimaryCurrVoltage(reqBody)
      .then(data => {
     this.formatVc10Data(data);
      }).catch(err => {
        this.setState({ loading: false });
      });
  }

  formatVc10Data = (params) =>{
    let finalArr = [], arr = [], obj = {}, disExp = true, vc10ChartTypes = ["vc10VkVARInj","vc10SecCurr","vc10Load","vc10KWkVAR","vc10PowerPhase"];
    let Obj = {
      "id": null,
      "name": this.props.treeNode.name,
      "feederName": this.props.treeNode.feederNode,
      "substationName": this.props.treeNode.substationNode,
      "regionName": this.props.treeNode.regionNode,
      "lateralName": null,
      "details": [],
      "pointInterval": null,
    };
    vc10ChartTypes.forEach(item => {
      Obj.chartType = item;
      arr.push(JSON.parse(JSON.stringify(Obj)));
    });
    Object.keys(params).forEach(item => {
      obj.phase = item;
      obj.type = this.props.treeNode.siteDeviceType;
      obj.pointData = params[item].cLogPoints;
      if (params[item].cLogPoints.length > 0){ 
        disExp = false;
        params[item].cLogPoints.forEach(e => e.kvaThreshold = e.kvaThreshold ? parseFloat(e.kvaThreshold) : e.kvaThreshold);
      }
      arr.forEach(e => { e.pointInterval =  params[item]?.pointInterval})
      arr.forEach(e => { e.varCtrlPointInterval =  params[item]?.varCtrlPointInterval})
    });
    arr.forEach((e, indx) => {
      e.details.push({ ...obj});
      finalArr.push(this.processData(e));
    });
    if(this.state.graphData.length > 0)finalArr.forEach((e,i) => e.hideChart = e.chartType === this.state.graphData[i].chartType &&  this.state.graphData[i].hideChart ? this.state.graphData[i].hideChart : false );
    this.setState({ loading: false, graphData: finalArr, disableExport: disExp});
  }

  render() {
    return (
      <MonitorContext.Provider value={{
        state: this.state,
        zoomOnChange: this.zoomChange,
        onCalenderCallback: this.calenderCallback,
        setValChange: this.onValChange,
        scaleAfterSetExtremes: this.onScaleAfterSetExtremes,
        zoomOut: this.onZoomOut,
        updateXScale: this.onUpdateXScale,
        onExportClick: this.getExportData
      }}>
        {this.props.children}
      </MonitorContext.Provider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    roleAndPermission: state.loginData.roleAndPermission
  }
}

export default withRouter(connect(mapStateToProps, {})(MonitorProvider));

export const MonitorConsumer = MonitorContext.Consumer;
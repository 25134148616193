import React, { Component } from 'react';
import '../userManagement/index.css';
import ChangePasswordContainer from './containers/changePasswordContainer'

class ChangePassword extends Component {
    render() {
        return (
            <React.Fragment>
                <ChangePasswordContainer />
            </React.Fragment>
        );
    }
}

export default ChangePassword;
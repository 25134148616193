import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import TableTopSection from '../components/TableTopSection'
import { systemAdminData } from '../../../utility/systemAdminUtility';
import { SystemAdminContext } from '../provider/systemAdminProvider';
import UploadSystemAdminFile from '../components/uploadSystemAdminFile';
import { FormattedMessage } from 'react-intl';
import BlankPage from '../../../hoc/blankPage';
import Loader from '../../../hoc/loader';
import Alert from '../../../hoc/alert';
import Table from '../../../hoc/table';

class MenuFecturingFile extends Component {
    static contextType = SystemAdminContext;
    constructor(props) {
        super(props);
        this.state = {
            columnsData: systemAdminData.firmwareData
        }
    }

    // handleMultipleSort = (rowData) => {
    //     let updateColumnData = this.context.updateSortNumber(rowData, this.state.columnsData)
    //     this.setState({ columnsData: updateColumnData })
    // }

    render() {
        return (
            <Row className="firewareFile">
                <Col>
                    <Row >
                        <Col>
                            <TableTopSection
                                title={<FormattedMessage id='db.sysAdmin.importFirmwareFile' />}
                                displayBtn={
                                    <UploadSystemAdminFile
                                        tooltipTitle={<FormattedMessage id='db.sysAdmin.uploadFirmwareFile' />}
                                        uploadFrom="firmwareFile"
                                        fileAccept=".zip"
                                    />
                                }
                            />
                            {this.context.state.firmwareFileRes.msg !== "" ?
                                <Row className="margin-top-2vh">
                                    <Col>
                                        <Alert type={this.context.state.firmwareFileRes.type}
                                            message={this.context.state.firmwareFileRes.msg}
                                            setVisible={() => this.context.handleRemoveAlert('firmwareFile')} />
                                    </Col>
                                </Row>
                                : ""}
                        </Col>
                    </Row>
                    {this.context.state.lodingFirmwareData ? <Loader elementClass="tree-ajax-loader center-element" /> : <Row>
                        {this.context.state.firmwareFile.length !== 0 ?
                            <Col className={`${this.context.state.OSName === 'MacOS' ? 'react-table-firmware-full padding-0' : 'react-table-firmware padding-0'}`}>
                                <Table 
                                     tableColumn={this.state.columnsData}
                                     tableRowData={this.context.state.firmwareFile}
                                     isDisplayPageRows={false}
                                    />
                            </Col>
                            :
                            <div><BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /></div>
                        }
                    </Row>}
                    
                </Col>
            </Row>
        );
    }
}

export default MenuFecturingFile;
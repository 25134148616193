import * as actionTypes from '../mainContent/configureProperties/actions/configurePropertiesActionTypes';

const initialState = {
    oldMtfUploadView: '',
};

const configurePropertiesReducer = function (currentState = initialState, action) {
    
    switch (action.type) {
        case actionTypes.UPDATE_OLD_MTF_UPLOAD_VIEW:
            return {
                ...currentState,
                oldMtfUploadView: action.payload
            }
        default:
            return currentState
    }
}

export default configurePropertiesReducer;
export const ON_LOGIN = "ON_LOGIN";
export const ON_LOGOUT = "ON_LOGOUT";
export const SET_SESSION = "SET_SESSION";
export const UPDATE_FORM_LOGIN = "UPDATE_FORM_LOGIN"
export const UPDATE_GLOBAL_TREE = 'UPDATE_GLOBAL_TREE';
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';
export const UPDATE_NOTIFY = 'UPDATE_NOTIFY';
export const GET_ALL_PERMISSION_DATA = 'GET_ALL_PERMISSION_DATA';
export const OPEN_ERROR_WINDOW = "OPEN_ERROR_WINDOW";
export const CLOSE_ERROR_WINDOW = "CLOSE_ERROR_WINDOW";
export const OPEN_ALERT_WINDOW = "OPEN_ALERT_WINDOW";
export const CLOSE_ALERT_WINDOW = "CLOSE_ALERT_WINDOW";
export const SET_LOADER = "SET_LOADER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_TET = "SET_TET"; 
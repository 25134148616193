import { getURL } from '../../../providers/configProvider';
import { requestGET
,requestPOST } from '../../../services/extendedService';

export const getAllDGWData = () => {
    const url = getURL('devicegateway', 'dgwList', {'apiType':'data'}, true);
    return requestGET(url);
};

export const certHistoryOrCrlHistory = (apiType) => {
    const url = getURL('devicegateway', apiType, {'apiType':'data'});
    return requestGET(url);
};

export const uploadCertificate = (type, data) => {
    const url = getURL('devicegateway', type, {'apiType':'data'});
    return requestPOST(url, data)
};

export const displayCertificate = (type,requestParams) =>{
    const url = getURL('devicegateway',type,{'apiType':'data',...requestParams});
    return requestGET(url);
}
import { loadNextLevel } from '../services/treeService';
import { groupRouteParams } from './../../services/utilityService'
import { getClassName } from '../../utility/treeViewUtility';

// Checking the node type if typs is Site or not
const checkSite = node => node.type.indexOf('SITE') > -1;

// Upading the tree node based on the node type
export const updateTree = (currentState, updatedNode, clickedNode) => {
    if (updatedNode[0].type !== 'ROOTNODE') {
        // if (clickedNode.node.type.indexOf('SITE') === -1) {
        //     return findAndUpdateNode(currentState[0], updatedNode, clickedNode, false);
        // } else {
        //     return [];
        // }
        return findAndUpdateNode(currentState[0], updatedNode, clickedNode, false);
    }
    return updatedNode;
};

// inserting the next level node by handling the lowerSiblings
// export const findAndUpdateNode = (newNode, updatedNode, clickedNode, updatedGlobal) => {
//     const globalChild = ['Organization', 'regions', 'substations', 'feeders', 'sites'];
//     const lowerSiblings = clickedNode.lowerSiblingCounts;
//     let parent = "newNode";

//     for (let i = 1; i < lowerSiblings.length; i++) {
//         let level = (updatedGlobal) ? globalChild[i] : 'children';
//        let index = eval(parent)[level].length - (lowerSiblings[i] + 1);

//         parent = parent + "." + level + "[" + index + "]"

//         if (i === lowerSiblings.length - 1) {

//             parent = parent + " = updatedNode[0]";

//         }
//     }
//     return eval(parent);

// }


export const findAndUpdateNode = (newNode, updatedNode, clickedNode, updatedGlobal) => {
    let parentData = newNode;
    if (newNode.hasOwnProperty('children')) {
        newNode.children.forEach((region, i) => {
            if (region.type === updatedNode[0].type && region.name === updatedNode[0].name && region.id === updatedNode[0].id) {
                newNode.children[i] = updatedNode[0];
            }
            else {
                if (region.hasOwnProperty('children')) {
                    region.children.forEach((substation, j) => {
                        if (substation.type === updatedNode[0].type && substation.name === updatedNode[0].name && substation.id === updatedNode[0].id) {
                            newNode.children[i].children[j] = updatedNode[0];
                        }
                        else {
                            if (substation.hasOwnProperty('children')) {
                                substation.children.forEach((feeder, k) => {
                                    if (feeder.type === updatedNode[0].type && feeder.name === updatedNode[0].name && feeder.id === updatedNode[0].id) {
                                        newNode.children[i].children[j].children[k] = updatedNode[0];
                                    }
                                    else {
                                        if (feeder.hasOwnProperty('children')) {
                                            feeder.children.forEach((site, x) => {
                                                if (site.hasOwnProperty('children')) {
                                                    if (site.type === updatedNode[0].type && site.name === updatedNode[0].name && site.id === updatedNode[0].id) {
                                                        newNode.children[i].children[j].children[k].children[x] = updatedNode[0];
                                                    }
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
            }
        })
        parentData = updatedNode[0];
        return parentData;
        // return newNode;
    }
}



// Returning the updated node
const getnode = (clickedNode, nextLevelNode, without) => {
    if(!nextLevelNode || nextLevelNode === 'undefined') {
        return {}
    } else {
        let mapedTree = nextLevelNode.map(node => {
        const {[without]: deletedKey, ...currentNode} = node;
        return {
            ...currentNode,
            //title: node.name,
            subtitle:node.id+"_"+node.name,
            arrow: !checkSite(currentNode) ? 'right' : ''
        }
        })
        let updatedNode = {
            ...clickedNode.node,
            expanded: true,
            children: mapedTree,
            arrow: !checkSite(clickedNode.node) ? 'down' : ''
        }
        return {
            updatedNode: [updatedNode],
            clickedNode: clickedNode
        }
    }
}

export const updateSelectedNode = (clickedNode) => {
    let updatedNode = {
        ...clickedNode.node,
        expanded: true,
        arrow: !checkSite(clickedNode.node) ? 'down' : ''
    }
    return {
        updatedNode: [updatedNode],
        clickedNode: clickedNode
    }
}

// Closing the node level based on the node expanded property
export const closeNode = (currentState, clickedNode) => {
    let updatedNode = clickedNode;
    updatedNode.node.expanded = false;
    updatedNode.node.arrow = !checkSite(clickedNode.node) ? 'right' : '';
    return findAndUpdateNode(currentState[0], [updatedNode.node], clickedNode, false);
}

//Getting the child node by passing the clicked node details
export const getChildnode = (stateVar, clickedNode) => {
    let nextLevelNode = getNextLevelNode(clickedNode, stateVar.globalData);
    switch (clickedNode.node.nodeType) {
        case 'Organization':
            return getnode(clickedNode, nextLevelNode, 'substations');
        case 'Region':
            return getnode(clickedNode, nextLevelNode, 'feeders');
        case 'Substation':
            return getnode(clickedNode, nextLevelNode, 'sites');
        case 'Feeder':
            return getnode(clickedNode, nextLevelNode, '');
        default:
            return []
    }
}

export const getNodeRouteParams = (clickedNode) => {
    let routeParams = [];

    if(clickedNode && clickedNode.parentNode) {
        routeParams = clickedNode.node.routeParams;
        return routeParams;
    }

    routeParams.push(clickedNode.node.type);
    routeParams.push(clickedNode.node);

    return routeParams;
 }

 export const getNodes = (params) => {
    let paramsObj = {};
    if(params instanceof Array) {
        paramsObj = groupRouteParams(paramsObj, params);
    }
    else {
        paramsObj = params;
    }
    return loadNextLevel(paramsObj);
 }

 export const addNodeData = (clickedNode, item) => {
    let nodeData = clickedNode ? clickedNode.node : clickedNode.node.children;

    if(!nodeData.hasOwnProperty("children")) {
        nodeData.children = [];
    }

    let nodeItem = null;

    for(let i=0; i<nodeData.children.length; i++) {
        let childItem = nodeData.children[i];
        if(childItem.name === item.name && childItem.type === item.type) {
            nodeItem = childItem;
            break;
        }
    }

   // item.title = item.name;
    //item.subtitle = item.id+"_"+item.name,
    item.arrow = item.type ? !checkSite(item) ? 'right' : '' : ''
    
    if(nodeItem) {
        for(let p in item) {
            nodeItem[p] = item[p];
        }
        item = nodeItem;
    }
    else {
        nodeData.children.push(item);
    }

    return item;
 }

// Returning the parent node of the selected node
const getNextLevelNode = (clickedNode, tree) => {
    let parent = {};
    const lowerSiblings = clickedNode.lowerSiblingCounts;

    for (let i in lowerSiblings) {
        if (parseInt(i, 10) === 0) {
            if (i === "0") {
                parent = tree[tree.length - (lowerSiblings[i] + 1)];
            }
        } else {
            parent = parent[parent.length - (lowerSiblings[i] + 1)];
        }
        if (i === "0") {
            parent = parent.regions;
        } else if (i === "1") {
            parent = parent.substations
        } else if (i === "2") {
            parent = parent.feeders
        } else if (i === "3") {
            parent = parent.sites
        }
    }
    return parent;
}

// const setRoute = (parent) => {
//     let { id, name, type, title } = parent;
//     return { id, name, type, title };
// }

// Updating the shadow to the selected node
export const updateShadow = (clickedNode) => {
    if (clickedNode) {
        let className = `shadow${getClassName(clickedNode)}`;
        //let className = `shadow${clickedNode.id + clickedNode.type + clickedNode.name.replace(/ /g, '')} `;
        let selector = document.querySelectorAll(`.${className}`);
        if (selector && selector.length > 0) {
            for (let i in selector) {
                if (selector[i] && selector[i].parentNode && selector[i].parentNode.nextSibling && selector[i].parentNode.nextSibling.children[i] && selector[i].parentNode.nextSibling.children[i].children) {
                    selector[i].parentNode.nextSibling.children[i].children[i].style.boxShadow = "0px 0px 3px 2px #A8A6A6"
                }
            }
        }
    }
}

// Removing the shadow in the tree node
export const removeShadow = () => {
    let selector = document.querySelectorAll("[class*=shadow]")
    if (selector && selector.length > 0) {
        for (let i in selector) {
            if (selector[i] && selector[i].parentNode && selector[i].parentNode.nextSibling && selector[i].parentNode.nextSibling.children[0] && selector[i].parentNode.nextSibling.children[0].children) {
                selector[i].parentNode.nextSibling.children[0].children[0].style.boxShadow = null;
            }
        }
    }
}

// // Updating the shadow to the selected node
// export const updateShadow = (clickedNode) => {
//     if (clickedNode) {
//         let className = `shadow${clickedNode.type + clickedNode.name.replace(/ /g, '')} span`;
//         let selector = document.querySelectorAll(`.${className}`);
//         if (selector && selector[3]) {
//             selector[3].style.boxShadow = "0px 0px 3px 2px #A8A6A6"
//         }
//     }
// }

// // Removing the shadow in the tree node
// export const removeShadow = () => {
//     let selector = document.querySelectorAll("[class*=shadow] span")
//     if (selector && selector.length > 0) {
//         for (let i in selector) {
//             if (selector[i] && selector[i].style && selector[i].style.boxShadow)
//                 selector[i].style.boxShadow = null;
//         }
//     }
// }

// // Updating the Global tree node based on the service response
export const updatedGlobalNode = async(state, clickedNode) => {
    const children = await getUpdatedGlobalNode(clickedNode);
    return findAndUpdateNode(state.globalData[0], [children], clickedNode, true);
}

//Getting the updated node details by call the request query
const getUpdatedGlobalNode = async(clickedNode) => {
    return  [];
//    return await requestQuery(SUBSTATION_ID, { substationId: clickedNode.node.id }, 'substationById')
}

export const createTreeDataKeyValuePairObj=(passedObj)=>{
    let tempArr=[];
    let globalObj=passedObj;
    let obj={
        id:globalObj.id,
        name:globalObj.name,
        level:"orgId"
    }
    tempArr.push(obj);
    if(globalObj.regions){
        globalObj.regions.forEach(e=>{
            let obj1={
                id:e.id,
                name:e.name,
                level:"regionId"
            }
            tempArr.push(obj1);
            if(e.substations){
                e.substations.forEach(f=>{
                    let obj2={
                        id:f.id,
                        name:f.name,
                        level:"substationId"
                    }
                    tempArr.push(obj2);
                    if(f.feeders){
                        f.feeders.forEach(g=>{
                            let obj3={
                                id:g.id,
                                name:g.name,
                                level:"feederId"
                            }
                            tempArr.push(obj3);
                            if(g.sites){
                                g.sites.forEach(h=>{
                                    let obj4={
                                        id:h.id,
                                        name:h.name,
                                        level:"siteId"
                                    }
                                    tempArr.push(obj4);
                                })
                            }
                        })
                    }
                })
            }
        })
    }
    return(tempArr)
}

export const updateTreeOnEditNode=(state, selectedNode, actualUpdate)=>{
    let data = state.tree;
    data.map((item)=>{
      if(item.children.length){
        if(selectedNode.node.routeParams.indexOf("REGION") !== -1 ){
          item.children.map((rRigion)=>{
            if(rRigion.tempId === selectedNode.node.routeParams[3].tempId ){
              rRigion.name = actualUpdate.type === 'REGION' ? actualUpdate.name : rRigion.name;
              rRigion.routeParams[3].name = actualUpdate.type === 'REGION' ?  actualUpdate.name : rRigion.name;

              if(selectedNode.node.routeParams.indexOf("SUBSTATION") !== -1 ){
                rRigion?.children && rRigion.children.map((rSubS)=>{
                  
                  if(rSubS.tempId === selectedNode.node.routeParams[5].tempId ){
                    rSubS.name = actualUpdate.type === 'SUBSTATION' ? actualUpdate.name : rSubS.name;
                    rSubS.routeParams[5].name = actualUpdate.type === 'SUBSTATION' ?  actualUpdate.name : rSubS.name;

                    if(selectedNode.node.routeParams.indexOf("FEEDER") !== -1 ){
                      
                      rSubS.children.map((rFed)=>{

                        if(rFed.tempId === selectedNode.node.routeParams[7].tempId ){
                          rFed.name = actualUpdate.type === 'FEEDER' ? actualUpdate.name : rFed.name;
                          rFed.routeParams[7].name = actualUpdate.type === 'FEEDER' ?  actualUpdate.name : rFed.name;

                          if(selectedNode.node.routeParams.indexOf("LATERAL") !== -1 ){
                            rFed.children.map((rLat)=>{
                              if(rLat.id === selectedNode.node.routeParams[9].id ){
                                rLat.name = actualUpdate.type === 'LATERAL' ? actualUpdate.name : rLat.name;
                                rLat.routeParams[9].name = actualUpdate.type === 'LATERAL' ?  actualUpdate.name : rLat.name;

                                if(selectedNode.node.routeParams.indexOf("LATERAL_SITE") !== -1 ){
                                  rLat.children.map((ls)=>{
                                    if(ls.name === selectedNode.node.routeParams[11].name ){
                                      ls.name = actualUpdate.type === 'LATERAL_SITE' ? actualUpdate.name : ls.name;
                                      ls.routeParams[11].name = actualUpdate.type === 'LATERAL_SITE' ?  actualUpdate.name : ls.name;
                                    }
                                  })
                                }

                              }
                            })
                          }
                          
                          if(selectedNode.node.routeParams.indexOf("SITE") !== -1 ){
                            rFed.children.map((st)=>{
                                if(st.id === selectedNode.node.routeParams[9].id ){
                                    st.name = actualUpdate.type === 'SITE' ? actualUpdate.name : st.name;
                                    st.routeParams[9].name = actualUpdate.type === 'SITE' ?  actualUpdate.name : st.name;
                                }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
        }
      }
    })
    return data
  }

import React, { Component } from 'react';
import CustomReportProvider from './provider/customReportProvider';
import CustomReportContainer from './containers/customReportContainer';
import './index.css';

class CustomReport extends Component {
    render() {
        return (
            <CustomReportProvider selectedReportObj={this.props.selectedReportObj}>
                <CustomReportContainer />
            </CustomReportProvider>
        )
    }
}

export default CustomReport;
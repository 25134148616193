import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Table from '../../../../hoc/table';
import { DetailsContext } from '../provider/detailsProvider';
import { faultDetailsData } from '../../../../utility/faultDetailsUtility';
import BlankPage from '../../../../hoc/blankPage';
import Loader from '../../../../hoc/loader';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt, faCompressAlt } from '@fortawesome/free-solid-svg-icons';
import FaultMap from './faultMap'
import FaultLine from './faultLine';

class MapAndTable extends Component {
    static contextType = DetailsContext;

    constructor(props) {
        super(props)
        this.state = {
            isExpandIcon: true,
        }
    }

    handleExpandCollapseIcon = () => this.setState({ isExpandIcon: !this.state.isExpandIcon })
    
    render() {
        return (
            <Row className='map-with-table'>
                <Col>
                        <Row className={this.state.isExpandIcon && this.context.state.faultDataLineDisplay.length > 0 ? 'border-1px mb-2 fault-line-section' : 'd-none'}> 
                            <FaultLine/>
                        </Row>
                        <Row className={this.state.isExpandIcon ? 'border-1px mb-2 map-section' : 'd-none'}>
                            <Col className='p-0'><FaultMap /></Col>
                        </Row>

                    <Row className={`border-1px f-details-table ${!this.state.isExpandIcon ? this.context.state.faultDataLineDisplay.length > 0 ? 'f-details-table-expand' : 'f-details-table-expand-efl-expand' : ''}`}>
                        <Col className='p-0'>

                            {this.context.state.isLoadingfltDtlTblData ? <Loader elementClass="tree-ajax-loader center-element" /> :
                            <React.Fragment>
                                <span className="customTooltip expand-icon" onClick={this.handleExpandCollapseIcon}>
                                    <FontAwesomeIcon className='cursor-pointer' icon={this.state.isExpandIcon ? faExpandAlt : faCompressAlt} size="lg" />
                                    <span className="tooltiptext tooltiptext-center">{ this.state.isExpandIcon ? 'Expand' : 'Collapse' }</span>
                                </span>
                                {this.context.state.faultDetailsTableData.events && this.context.state.faultDetailsTableData.events.length !== 0 ?
                                    <span className={this.context.state.faultDetailsTableData.hasLaterals ? 'with-lateral-table' : 'without-lateral-table'}>
                                        <Table
                                            tableColumn={!this.context.state.faultDetailsTableData.hasLaterals ? faultDetailsData.fltDtlsColumnsData.filter(item=> item.accessor !== 'lateral') : faultDetailsData.fltDtlsColumnsData }
                                            tableRowData={this.context.state.faultDetailsTableData.events}
                                            pageSize={this.context.state.faultDetailsTableData.events.length}
                                            showPagination={false}
                                            resizable={false}
                                            />
                                            {this.context.state.siteTableShowLoadMore && <button className="load-more float-left lm-size" onClick={() => {this.context.loadMoreSiteTableData()}}><FormattedMessage id={"events.register.loadMore"} /></button>}
                                    </span>
                                    :
                                    <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                                }
                            </React.Fragment>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default MapAndTable;
import React, { Component } from 'react';
import ModalWindow from '../../../hoc/modelWindow';
import { FormattedMessage } from 'react-intl';
import { DisturbanceAnalyticsContext } from '../provider/disturbanceAnalyticsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class DisturbanceModal extends Component {
    static contextType = DisturbanceAnalyticsContext;

    render() {
        return (
            <ModalWindow
                show={true}
                onHide={this.props.onBtnClickHandleBtn}
                size={'md'}
                title={<span><FontAwesomeIcon icon={faExclamationTriangle} size="1x" color='red' />&nbsp; <FormattedMessage id="disturbances.slots.error.title" /></span>}
                dialogClassName={''}
                onBtnClickHandler={this.props.onBtnClickHandleBtn}
                modeldata={{
                    content:
                        <React.Fragment>
                            <FormattedMessage id={"disturbances.slots.error"}/>
                        </React.Fragment>
                }}
                footer={
                    [
                        { className: "modalCancelBtn", name: <FormattedMessage id="db.common.close" />, value: "close" }
                    ]
                }
            />
        );
    }
}

export default DisturbanceModal;
import { getURL } from '../../../../providers/configProvider';
import { requestPOST, requestGET } from '../../../../services/extendedService';

export const getClassAssignmentTabData = (tabName) => {
    const url = getURL('deviceManagement', 'tabdata', { 'SELECTEDTAB': tabName,'apiType': 'CLASS_ASSIGNMENT' });
    return requestGET(url);
}

export const postClassAssignmentTabData = (params, tabName) => {
    const url = getURL('deviceManagement', 'tabdata', { 'SELECTEDTAB': tabName, 'apiType': 'CLASS_ASSIGNMENT' });
    return requestPOST(url, params);
}
import React, { Component } from 'react';
import { TreeConsumer } from '../../treeview/provider/treeProvider';
import DisturbanceAnalyticsProvider from './provider/disturbanceAnalyticsProvider';
import DisturbanceAnalyticsContainer from './containers/disturbanceAnalyticsContainer';
import BaseMessage from '../../hoc/baseMessage';
import './index.css';

class DisturbanceAnalytics extends Component {

    render() {
        return (
            <React.Fragment>
                <TreeConsumer>
                    {(context) => (
                        <React.Fragment>
                            {context.state.highlightedNode.node !== undefined && context.state.highlightedNode.node.type !== 'ROOTNODE' ?
                                context.state.highlightedNode.node.type !== 'LATERAL' && context.state.highlightedNode.node.type !== 'LATERAL_SITE' && context.state.highlightedNode.node.siteDeviceType !== 'ZM1' ?
                                    <DisturbanceAnalyticsProvider setDashboardFlag={context.cancelApiCallFromDashboard} dashboardFlag={context.state.dashboardFlag} treeNode={context.state.highlightedNode.node} treeLoader={context.state.loading} breadCrumbName={context.state.selectedItem}>
                                        <div className="disturbances">
                                            <DisturbanceAnalyticsContainer />
                                        </div>
                                    </DisturbanceAnalyticsProvider>
                                    : <BaseMessage className={"content-height with-breadcrumb"} message={"disturbance.baseMessage.ZM1Device"} />
                                :
                                context.state.breadCrumb?<BaseMessage className={"content-height with-breadcrumb"} message={"disturbance.baseMessage"} />:<BaseMessage className={"content-height"} message={"disturbance.baseMessage"} />
                            }
                        </React.Fragment>
                    )}
                </TreeConsumer>
            </React.Fragment>
        );
    }
}

export default DisturbanceAnalytics;
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../hoc/tooltip';
import { SystemAdminContext } from '../provider/systemAdminProvider';

class DownloadSampleFile extends React.Component {
    static contextType = SystemAdminContext;
    
    render() {
        return (
            <span className="float-right download-sample-file">
                <Tooltip placement="top" message={this.props.tooltipTitle} baseContent={
                    <span className='padding-download-icon' onClick={()=>this.context.downloadSampleFile(this.props.from)}>
                        <FontAwesomeIcon icon={faDownload} size="sm" />
                    </span>
                } />
            </span>
        );
    }
}


export default DownloadSampleFile;
import React from 'react';

const CustomImgIcon = (props) => {
    return <img
        src={require(`../resources/images/${props.icon}`)}
        alt=""
        style={
            {
                width: props.width ? props.width :"1vh",
                height: props.height ? props.height :"1vh"
            }
        }
    />
}

export default CustomImgIcon
import { getURL } from '../../../providers/configProvider';
import { requestPOST } from '../../../services/extendedService';

// update custom report
export const updateCustomReportsManagement = (reportId, reqParams) => {
    let params = { 'apiType': 'data', 'REPORTID': reportId, 'VISIBLE': reqParams.visible, 'AVAILABLE': reqParams.available, 'PASSKEY': reqParams.passkey, 'PERMISSION': reqParams.rolePermission };
    const url = getURL('customreportsmanagement', 'update', params, true);
    return requestPOST(url, reqParams);
};
// delete custom report
export const deleteCustomReportsManagement = (reportId) => {
    let params = { 'apiType': 'data', 'REPORTID': encodeURIComponent(reportId) };
    const url = getURL('customreportsmanagement', 'delete', params, true);
    return requestPOST(url);
};
import React from 'react';

const TableMultiSortNumber = ({ column, label }) => {
    return (
        <React.Fragment>
            {label} <span className={column.column.sortedOrder > 0 ? "disp-sortedOrder" : ""}>{column.column.sortedOrder > 0 ? `${column.column.sortedOrder}` : ""}</span>
        </React.Fragment>
    )
}

export default TableMultiSortNumber;
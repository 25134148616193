import React, { Component } from "react";
import ForgotPasswordForm from '../components/forgotPasswordComponent';
import Footer from '../../footer';
import { sendResetPasswordEmail } from '../../home/services/homeService';
import { FormattedMessage } from 'react-intl';
import { getCookie, emailRegex } from '../../filters/commonFunction';

class ForgotPasswordView extends Component {

  state = {
    email: "",
    accountId: "",
    isEmailInvalid: false,
    isAccountIdInvalid: false,
    isLoading: false,
    statusMessage: { type: '', message: '' },
  };
  componentDidMount(){
    this.setState({
      email: getCookie('EMAIL') || "",
      accountId: getCookie('ACCOUNTID') || ""
    })
  }
  sendResetEmail = () => {
    this.setState({ submited: true }, () => {
      if (!this.state.isEmailInvalid && !this.state.isAccountIdInvalid) {
        this.setState({ isLoading: true }, () => {
          sendResetPasswordEmail(this.state.email, this.state.accountId).then(results => {
            let response = results.data;
            this.setState({ isLoading: false });
            if (response.status === 'OK') {
              this.setState({ submited: true, email: '', statusMessage: { type: 'success', message: response.message ? response.message : <FormattedMessage id='login.forgotpassword.email.success' /> } });
            } else if (response.status === 'FAIL') {
              this.setState({ statusMessage: { type: 'danger', message: response.message } });
            } else {
              this.setState({ statusMessage: { type: 'danger', message: (response.message ? response.message : <FormattedMessage id='common.error.server' />) } });
            }
          }).catch(error => {
            this.setState({ isLoading: false });
          });
        })
      }
    })
  }
  onEmailChangeValidation = (evt) => {
    document.cookie = "EMAIL =" + evt+"; path=/amplemanage";
    this.setState({ email: evt, isEmailInvalid: this.testValidation(emailRegex, evt) })
  }
  onAccountIdChangeValidation = (evt) => {
    document.cookie = "ACCOUNTID =" + evt+"; path=/amplemanage";
    this.setState({ accountId: evt, isAccountIdInvalid: !evt || (evt && !Number(evt)) })
  }

  testValidation = (regex, value) => !regex.test(value)

  render() {
    return (
      <React.Fragment>
        <div className="login-view">
          <div className="login-content">
            <div className="login-wrap">
              <ForgotPasswordForm
                stateObj={this.state}
                onChangeEmail={(evt) => this.onEmailChangeValidation(evt)}
                onChangeAccountID={(evt) => this.onAccountIdChangeValidation(evt)}
                sendResetEmail={this.sendResetEmail} />
            </div>
          </div>
        </div>
        <div className="login-footer">
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPasswordView;
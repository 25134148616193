import React,{Component} from 'react';
import { DeviceContext } from '../provider/deviceProvider';

class selctAllDeviceCheckbox extends Component{
    static contextType = DeviceContext;
    handleSelectAll = (e) => { this.context.handleSelectAll(e) };
    render(){
        return(
            <input onChange={this.handleSelectAll.bind(this)} type="checkbox" checked={this.context.state.selectAllCb}/>
        )
    }
}
export default selctAllDeviceCheckbox;
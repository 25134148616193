import React, { Component } from 'react';
import BlankPage from '../../../hoc/blankPage';
import Table from '../../../hoc/table';
import Loader from '../../../hoc/loader';
import { FormattedMessage } from 'react-intl';
import { UserContext } from '../provider/userProvider'
import Tooltip from '../../../hoc/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { tableData } from '../../../utility/userManagementUtility';
import SelectAllCB from './selectAllCB';
import CustomImgIcon from '../../../hoc/customImgIcon';
import { connect } from 'react-redux';

class UserTable extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            columnsData: [
                // { accessor: "checkBox", Header: <input onChange={this.handleSelectAll.bind(this)} type="checkbox"/>, Cell: ({ original }) => <input className={this.checkUserAccess(original, this.context.currentUserDetails) ? "cursor-not-allowed" : ""} disabled={this.checkUserAccess(original, this.context.currentUserDetails) ? true : false} onChange={this.handleOnChangeCheckBox.bind(this, original)} type="checkbox" checked={this.context.state.allChecked.includes(original.principalId) ? true : false} />, maxWidth: 45, sortable: false, filterable: false },
                { accessor: "checkBox", Header: <SelectAllCB />, Cell: ({ original }) => <input className={this.checkUserAccess(original, this.context.currentUserDetails) ? "cursor-not-allowed" : ""} disabled={this.checkUserAccess(original, this.context.currentUserDetails) ? true : false} onChange={this.handleOnChangeCheckBox.bind(this, original)} type="checkbox" checked={this.context.state.allChecked.includes(original.principalId) ? true : false} />, sortable: false, filterable: false },
                ...tableData.columnsData,
                {
                    accessor: 'action', show:!(this.props.roleAndPermission.samlEnabled || this.props.roleAndPermission.ssoEnabled), Header: <FormattedMessage id='dm.column.actions' />, sortable: false, Cell: ({ original }) => {
                        return <span style={{ color: this.checkUserAccess(original, this.context.currentUserDetails) ? "#afafaf" : "#5a5a5a" }}>
                            <Tooltip baseContent={<FontAwesomeIcon className={this.checkUserAccess(original, this.context.currentUserDetails) ? "cursor-not-allowed" : "cursor-pointer"} onClick={this.checkUserAccess(original, this.context.currentUserDetails) ? (e) => e.preventDefault() : this.handleDisableOrResetPasswordUser.bind(this, 'userState', original)} icon={original.enabled === 'True' ? faUnlock : faLock } size="sm" />} message={original.enabled === 'True' ? <FormattedMessage id='db.common.disableUser' /> : <FormattedMessage id='db.common.enableUser' />} placement={'top'}
                            /> &nbsp;&nbsp;
                            {/* <Tooltip baseContent={<FontAwesomeIcon className={this.checkUserAccess(original, this.context.currentUserDetails) ? "cursor-not-allowed test" : "cursor-pointer test"} onClick={this.checkUserAccess(original, this.context.currentUserDetails) ? (e) => e.preventDefault() : this.handleDisableOrResetPasswordUser.bind(this, 'resetpassword', original)} icon={faLaptop} size="sm" />} message={<FormattedMessage id='db.common.resetPassword' />} placement={'top'} /> */}
                            <Tooltip baseContent={<span className={this.checkUserAccess(original, this.context.currentUserDetails) ? "cursor-not-allowed test" : "cursor-pointer test"} onClick={this.checkUserAccess(original, this.context.currentUserDetails) ? (e) => e.preventDefault() : this.handleDisableOrResetPasswordUser.bind(this, 'resetpassword', original)} size="sm" ><CustomImgIcon icon="glyphicons-force-reset.png" width="1.5vh" height="1.3vh" /></span>} message={<FormattedMessage id='db.common.resetPassword' />} placement={'top'} />
                        </span>;
                    }
                }
            ],
            pageSize: tableData.pageSize,
        }
    }

    checkUserAccess = (original, currentUserDetails) => original.principalId === currentUserDetails.principalId || original.role < currentUserDetails.role

    handleOnChangeCheckBox = (rowInfo, e) => this.context.handleOnChangeCheckBox(rowInfo, e)

    // handleSelectAll = (e) => this.context.handleSelectAll(e);

    // onPageSizeOptionChange = pageSize => {
    //     // tableData.pageSize = pageSize;
    //     this.setState({
    //         pageSize: pageSize
    //     });
    //     this.forceUpdate();
    // };

    handleMultipleSort = (rowData) => {
        let updatedData = this.state.columnsData
        rowData.forEach((item, key, itemArr) => {
            this.state.columnsData.forEach(items => {
                if (item.id === items.accessor) {
                    items.sortNumber = itemArr.length > 1 ? key + 1 : "";
                } else {
                    let arr = itemArr.map(itemId => itemId.id);
                    if (!arr.includes(items.accessor) && items.hasOwnProperty('sortNumber')) delete items.sortNumber
                }
            })
        })
        this.setState({ columnsData: updatedData })
    }

    handleDisableOrResetPasswordUser = (clickedIcon, data) => {
        this.context.handleDisableUser(clickedIcon, data)
    };

    render() {
        return (
            <div style={{ minHeight: "15vh", border: this.context.state.usersList.length === 0 ? "1px solid #aeaeae" : "" }}>
                {this.context.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : this.context.state.usersList.length !== 0 ?
                    <Table
                        tableColumn={this.state.columnsData.filter(item => this.context.state.displayColumns.includes(item.accessor))}
                        tableRowData={this.context.state.usersList}
                    // initialSortingColumn={[{id: "userName", desc: false}]}
                    // multiSort={false}
                    />

                    // <ReactTablePropType
                    //     reactTableContainer={
                    //         <ReactTable
                    //             columns={this.state.columnsData.filter(item => this.context.state.displayColumns.includes(item.accessor))}
                    //             data={this.context.state.usersList}
                    //             PaginationComponent={Pagination}
                    //             showTotalPages={false}
                    //             canNextFromData={true}
                    //             loading={this.context.state.isLoading}
                    //             showQuickJumper={false}
                    //             pageSize={this.state.pageSize}
                    //             pageSizeOptions={tableData.pageSizeOptions}
                    //             minRows={0}
                    //             className="-striped -highlight"
                    //             multiSort={true}
                    //             onPageSizeChange={this.onPageSizeOptionChange}
                    //             previousText={tableData.previousText}
                    //             nextText={tableData.nextText}
                    //             pageText={tableData.pageText}
                    //             ofText={tableData.ofText}
                    //             rowsText={tableData.rowsText}
                    //             firstText={tableData.firstText}
                    //             lastText={tableData.lastText}
                    //             onSortedChange={(data) => this.handleMultipleSort(data)}
                    //             totalSize={this.context.state.usersList.length}
                    //         />
                    //     }
                    // /> 
                    :
                    <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default connect(mapStateToProps, null)(UserTable);
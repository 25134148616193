import React, { Component } from 'react'
import './index.css';
import DashboardContainer from './containers/dashboardContainer'

class Dashboard extends Component {

    render() {
        return (
            <div className="dashboard">
                <DashboardContainer />
            </div>
        );
    }
}

export default Dashboard;
import React, { createContext } from 'react';
import { getOtapStatusData } from '../services/otapstatusService';
import { connect } from 'react-redux';
import * as currentJobUtility from '../../../../utility/currentJobUtility';
export const OtapStatusContext = createContext();

class OtapStatusProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedJobName: "",
            rowDevice: "",
            jobList: [],
            jobStatus: {},
            deviceListTableDataset: [],
            failureDevices: [],
            orgId: this.props.tree[0].id,
            displayColumns: currentJobUtility.selectedColumn.data,
            roleAndPermission: this.props.roleAndPermission
        }
    }

    componentDidMount() {
        this.fetchJobList();
    }

    /* Get the list of jobs (left side view) */
    fetchJobList = () => {
        var params = { 'ORGID': this.state.orgId };
        this.setState({ isLoading: true }, () => {
            getOtapStatusData(params, 'joblist')
                .then(data => {
                    this.setState({ jobList: data ? data : [], isLoading: false });
                    if (data && data.length > 0) {
                        this.initialJobListSelection(this.state.selectedJobName || data[0].jobName);
                    }
                }).catch(error => {
                    this.setState({ jobList: [], isLoading: false })
                });
        })
    }
    /* Get individual job details (right side view) */
    fetchJobDetails = (jobName) => {
        currentJobUtility.tableOptions.selectedRows = [];
        currentJobUtility.tableOptions.enableAbortBtn = false;
        currentJobUtility.tableOptions.enableRetryBtn = false;
        currentJobUtility.tableOptions.enableRetryAllBtn = false;
        let params = { 'ORGID': this.state.orgId, 'JOBNAME': jobName, 'apiType': 'data' };
        this.setState({ isLoading: true }, () => {
            getOtapStatusData(params, 'jobDetails')
                .then(data => {
                    if (data && data.deviceDetails && data.deviceDetails.length > 0) {
                        this.initialDeviceSelection(data.deviceDetails[0].serialNumber);
                        this.failedDevices(data.deviceDetails);
                    }
                    this.setState({
                        deviceListTableDataset: data.deviceDetails && data.deviceDetails.length ? data.deviceDetails : [],
                        jobStatus: data.jobStatusDetails ? data.jobStatusDetails : {},
                        isLoading: false
                    });
                }).catch(error => {
                    this.setState({ isLoading: false, deviceListTableDataset: [], jobStatus: [] })
                });
        })
    };

    /* On click of job or selected searched job (left side view)*/
    updateSelectedJobName = (job) => {
        if (job && job.jobName) {
            this.setState({ selectedJobName: job.jobName });
            if (this.state.selectedJobName !== job.jobName) {
                this.fetchJobDetails(job.jobName);
            }
        }
    }

    /* select first job from the list on load */
    initialJobListSelection = (jobname) => {
        this.setState({ selectedJobName: jobname });
        this.fetchJobDetails(jobname);
    }

    initialDeviceSelection = (device) => {
        this.setState({ rowDevice: device ? device : "" });
    }

    failedDevices = (tableData) => {
        var list = [];
        tableData && tableData.length > 0 && tableData.forEach((key) => {
            let otapStatusCheck = currentJobUtility.otapStatusCheckUpgradeJobsRetry(key);
            let configStatusChk = currentJobUtility.configStatusCheck(key, 'configStatus');
            let deviceStatusChk = currentJobUtility.deviceStatusCheck(key, 'deviceStatus');
            if (!otapStatusCheck && !configStatusChk && !deviceStatusChk) {
                list.push(key.id + "");
            }
            this.setState({ failureDevices: list });
            currentJobUtility.tableOptions.enableRetryAllBtn = false;
        })
        // Toggle RetryAll Btn
        if (list.length > 0) {
            currentJobUtility.tableOptions.enableRetryAllBtn = true;
        }
    }

    render() {
        return (
            <OtapStatusContext.Provider value={{
                state: this.state,
                fetchJobList: this.fetchJobList,
                initialDeviceSelection: this.initialDeviceSelection,
                updateSelectedJobName: this.updateSelectedJobName
            }}>
                {this.props.children}
            </OtapStatusContext.Provider>
        )
    }
}
// Getting the state from the store
const mapStateToProps = state => {
    return {
        tree: state.treeviewData.tree,
        roleAndPermission: state.loginData.roleAndPermission
    };
}

export default connect(mapStateToProps)(OtapStatusProvider);
//   export const OtapStatusConsumer = OtapStatusContext.Consumer;
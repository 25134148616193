import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import UserAction from '../components/userAction';
import UserModal from './userModal';
import { UserContext } from '../provider/userProvider';
import UserTable from './userTable';

class UserManagementContainer extends Component {
    static contextType = UserContext;
    render() {
        return (
            <Row className="p-0 m-0">
                <Col className="p-0 m-0">
                    <UserAction />
                    <UserTable />
                    {this.context.state.isOpenModal ?
                        <UserModal />
                    : ""}
                </Col>
            </Row>
        );
    }
}

export default UserManagementContainer
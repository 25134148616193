import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { userMngmntData } from '../../utility/userManagementUtility';
import ModalWindow from '../../hoc/modelWindow';
import Loader from '../../hoc/loader';
import { getProductInfo } from '../../mainContent/userManagement/services/userService';
import { Row, Col } from 'react-bootstrap';
import '../../mainContent/userManagement/index.css'

class AboutModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            productInfo: {}
        }
    }

    handleOnCLick = () => {
        userMngmntData.isOpenAboutModal = true;
        this.setState({ isOpenModal: true })
        this.getproductInfoData();
    }

    closeNotificationTemplateModal = () => {
        userMngmntData.isOpenAboutModal = false;
        this.setState({ isOpenModal: false, productInfo: {} })
    }

    getproductInfoData = () => {
        this.setState({ isLoadingData: true }, () => {
            getProductInfo()
                .then((res) => {
                    let data = res.status !== 'FAIL' ? res : {};
                    this.setState({ productInfo: data, isLoadingData: false })
                }).catch((err) => this.setState({ isLoadingData: false }))
        })
    }

    onBtnClickHandleBtn=()=> this.closeNotificationTemplateModal();

    render() {
        const { productInfo } = this.state;
        return (
            <React.Fragment>
                <div onClick={this.handleOnCLick} className="noNavigationItemLink">
                    <FormattedMessage id={`nav.main.aboutAmpleManage`} />
                </div>

                {userMngmntData.isOpenAboutModal ?
                    <ModalWindow
                        show={this.state.isOpenModal}
                        onHide={this.closeNotificationTemplateModal}
                        size={'sm'}
                        title={<FormattedMessage id={"nav.main.aboutAmpleManage"} />}
                        onBtnClickHandler={this.onBtnClickHandleBtn}
                        dialogClassName={"aboutModal"}
                        modeldata={{
                            content:
                                <React.Fragment>
                                    {this.state.isLoadingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                                    <Row className="text-center">
                                        <Col>
                                            <div className='title'><FormattedMessage id={'product.text.name'}/></div>
                                            <div><FormattedMessage id={'product.text.version'} /> {productInfo.releaseVersion} </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                        }}
                        footer={
                            [
                                { className: "modalCancelBtn", name: <FormattedMessage id="db.common.ok" />, value: "close" }
                            ]
                        }
                    />
                    : ""}
            </React.Fragment>
        );
    }
}

export default AboutModal;
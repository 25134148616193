import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import Table from '../../../hoc/table';
import FilterComponent from '../components/filterComponent';
import { AuditTrailContext } from '../provider/auditTrailProvider';
import { auditTrailData } from '../../../utility/auditTrailUtility';
import BlankPage from '../../../hoc/blankPage';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../hoc/loader';

class AuditTrailContainer extends PureComponent {
    static contextType = AuditTrailContext;
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillUnmount(){
        auditTrailData.pageSize = 10;
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col><FilterComponent /></Col>
                </Row>

                <Row style={{ marginTop: '2vh' }}>
                    <Col className="p-0 m-0">
                        {this.context.state.isLoadingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                        {this.context.state.tableData.length !== 0 ?
                            <Table
                                tableColumn={auditTrailData.columnsData}
                                tableRowData={this.context.state.tableData}
                                onPageSizeChange={this.context.onPageSizeOptionChange}
                                onPageChange={this.context.onPageChangeHandler}
                                pageNo={auditTrailData.pageNo}
                                pageSize={auditTrailData.pageSize}
                                totalSize={this.context.state.totalRowCount}
                                isManualTable={true}
                                // lazyLoadConfigData={{initialSize : 50, updatedSize : 30}}
                            />
                            :
                            <div className='at-blankpage'>
                               {!this.context.state.isLoadingData ? <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /> : ""}
                            </div>
                        }
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}

export default AuditTrailContainer;
import React, { Component } from 'react';
import { TreeConsumer } from '../../treeview/provider/treeProvider';
import MonitorProvider from './provider/monitorProvider';
import MonitorContainer from './containers/monitorContainer';
import BaseMessage from '../../hoc/baseMessage';
import './index.css';

class Monitor extends Component {

    render() {
        let disablecontent = ['', 'ROOTNODE', 'REGION', 'SUBSTATION']
        return (
            <React.Fragment>
                <TreeConsumer>
                    {(context) => (
                        <React.Fragment>
                            <div className="monitor">
                                <MonitorProvider setDashboardFlag={context.cancelApiCallFromDashboard} dashboardFlag={context.state.dashboardFlag} loadDevicesList={context.state.loadDevicesList} setLoadDevicesList={context.setLoadDevicesList} treeNode={context.state.highlightedNode.node} treeLoader={context.state.loading}> {
                                    ((!context.state.highlightedNode) || (context.state.highlightedNode && (disablecontent.indexOf(context.state.highlightedNode.node.type) !== -1))) ? (context.state.breadCrumb?<BaseMessage className={"content-height with-breadcrumb"} message="monitor.baseMessage" />:<BaseMessage className={"content-height"} message="monitor.baseMessage" />) : <MonitorContainer />
                                }
                                </MonitorProvider>
                            </div>
                        </React.Fragment>
                    )}
                </TreeConsumer>
            </React.Fragment>
        );
    }
}

export default Monitor;
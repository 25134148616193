import React, { Component, Fragment } from 'react';
import SiteViewModal from './siteViewModal';
class SiteView extends Component {
    state = {
        showModal: false
    }
    openModal = () => {
        this.setState({
            showModal: true
        })
    };
    onCloseModal = () =>{
        this.setState({
            showModal: false
        })
    }
    render() {
        return (
            <Fragment>
                <span onClick={(e) => this.openModal(e, this.props.data)} className='cursor-pointer active-color'>{this.props.data.original.feedersite}</span>
                {this.state.showModal ? <SiteViewModal  data={this.state} content={this.props.data.original} onCloseModal={this.onCloseModal}/> : null}
            </Fragment>
        )
    }
}

export default SiteView;
import React, { Component } from 'react';
import ManageDetailComponent from './containers/deviceManagementContainer';
import { TreeConsumer } from '../../../treeview/provider/treeProvider';
import DeviceProvider from './provider/deviceProvider';
import BaseMessage from '../../../hoc/baseMessage';
import './index.css'

class ManageDetails extends Component {
    render() {
        return (
            <React.Fragment>
                <TreeConsumer>
                    {(context) => (
                        <React.Fragment>
                            <div className="manage-details">
                                <DeviceProvider 
                                    setDashboardFlag={context.cancelApiCallFromDashboard} 
                                    dashboardFlag={context.state.dashboardFlag} 
                                    loadDevicesList={context.state.loadDevicesList}
                                    setLoadDevicesList={context.setLoadDevicesList}
                                    loadNextLevel={context.loadNextLevel} 
                                    selectSearchedNode={context.selectSearchedNode} 
                                    skipRightSideApiCall={context.skipRightSideApiCall}
                                    treeNode={context.state.highlightedNode.node} 
                                    treeLoader={context.state.loading} 
                                    selectedPage="details"
                                    > 
                                    {
                                    (!context.state.highlightedNode) ? <BaseMessage className={"content-height"} message="manage.details.baseMessage" /> : <ManageDetailComponent />
                                    }
                                </DeviceProvider>
                            </div>
                        </React.Fragment>
                    )}
                </TreeConsumer>
            </React.Fragment>
        );
    }
}

export default ManageDetails;

// loadNextLevel - On Save need to re-direct to the particular node
// treeNode: Highlighted Node
// treeLoader: Whether the tree loader is enable/disable
// selectedPage: For filter data to track whether we are which page summary/device
import React, { useEffect } from "react";
import Loader from '../../hoc/loader';
import { handleAuthApi } from "../actions/loginActionsBuilder";

const Authorize = () => {

    useEffect(() => {
        handleAuthApi();
    }, [])
  return <Loader elementClass="tree-ajax-loader center-element" />;
};

export default Authorize;

import React, { createContext } from 'react';
import { updateCustomReportsManagement, deleteCustomReportsManagement } from '../services/customReportsManagementService';

export const CustomReportsManagementContext = createContext();

class CustomReportsManagementProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalLoading: false,
            editOrDelete: "",
            selectedRow: {}
        }
    }
    // Open modal popup on click of edit or delete
    handleEditOrDeleteIconClick = (row, action) => {
        this.setState({ isOpenModal: true, editOrDelete: action, selectedRow: row })
    }
    // Handle on toggle switch
    onToggleSwitchChange = (field, value) => {
        let updatedFields = { ...this.state.selectedRow, [field]: value }
        this.setState({ selectedRow: updatedFields })
    }
    // Update the report on click of update button
    updateCustomReport = () => {
        this.setState({ modalLoading: true }, () => {
            let reqParams = {
                visible: this.state.selectedRow.reportVisibility,
                available: this.state.selectedRow.reportAvailability,
                rolePermission: this.state.selectedRow.userRole
            };
            updateCustomReportsManagement(this.state.selectedRow.id, reqParams).then((res) => {
                this.setState({ modalLoading: false });
                if (res.status !== 'FAIL') {
                    this.onCloseModal();
                }
            }).catch(err => {
                this.setState({ modalLoading: false });
            })
        })
    }
    // Delete the report
    deleteCustomReport = () => {
        this.setState({ modalLoading: true }, () => {
            deleteCustomReportsManagement(this.state.selectedRow.id).then((res) => {
                this.setState({ modalLoading: false });
                if (res) {
                    this.onCloseModal();
                }
            }).catch(err => {
                this.setState({ modalLoading: false });
            })
        })
    }
    // Close edit or delete modal
    onCloseModal = () => this.setState({ isOpenModal: false });

    render() {
        return (
            <CustomReportsManagementContext.Provider value={{
                state: this.state,
                onToggleSwitchChange: this.onToggleSwitchChange,
                updateCustomReport: this.updateCustomReport,
                deleteCustomReport: this.deleteCustomReport,
                handleEditOrDeleteIconClick: this.handleEditOrDeleteIconClick,
                onCloseModal: this.onCloseModal,
            }}>
                {this.props.children}
            </CustomReportsManagementContext.Provider>
        )
    }
}

export default CustomReportsManagementProvider;
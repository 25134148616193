import React, { useState, useContext } from 'react'
import { Row, Col } from 'react-bootstrap';
import TableTopSection from '../components/TableTopSection';
import { systemAdminData } from '../../../utility/systemAdminUtility';
import { SystemAdminContext } from '../provider/systemAdminProvider';
import UploadSystemAdminFile from '../components/uploadSystemAdminFile';
import DownloadSampleFile from '../components/downloadSampleFile';
import { FormattedMessage } from 'react-intl';
import BlankPage from '../../../hoc/blankPage';
import Loader from '../../../hoc/loader';
import Alert from '../../../hoc/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Table from '../../../hoc/table';
import TableMultiSortNumber from '../../../hoc/tableMultiSortNumber';
import SysAdminFileUploadErrModal from './sysAdminFileUploadErrModal';

const InventoryFile =()=> {
    const contextData = useContext(SystemAdminContext);
    const [columnsData] = useState([
        ...systemAdminData.inventoryMasterData, 
        { accessor: 'result', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.resultFile' />} />, Cell: ({ original }) => <span className="download-btn" onClick={() => contextData.handleDownloadFile(original, 'inventoryResult')}>{original.result}</span> }, 
        ]);
    const {loadingInventoryUploadDownload, inventoryFileRes, loadingInventoryData, OSName, inventoryMasterFile, openUploadErrDetailsModal } = contextData.state;
    return (
            <Row className="menufacturingFile">
                <Col>
                {loadingInventoryUploadDownload && <Loader elementClass="tree-ajax-loader center-element" />}
                    <Row>
                        <Col>
                            <TableTopSection
                                title={<FormattedMessage id='db.sysAdmin.inventoryFile' />}
                                displayBtn={
                                    <React.Fragment>
                                        <DownloadSampleFile from={'inventorySampleFile'} tooltipTitle={<FormattedMessage id='db.sysAdmin.downloadSampleFile' />}
                                        />
                                        <UploadSystemAdminFile
                                            tooltipTitle={<FormattedMessage id='db.sysAdmin.uploadInventoryFile' />}
                                            uploadFrom="inventoryFile"
                                            fileAccept=".csv"
                                        />
                                    </React.Fragment>
                                }
                            />
                            {inventoryFileRes.msg !== "" ?
                                <Row className="mt-3 mb-3">
                                    <Col>
                                        <Alert type={inventoryFileRes.type}
                                            message={<div>
                                                {inventoryFileRes.msg} &nbsp;
                                                {typeof (inventoryFileRes.msg) !== "object" ?
                                                    inventoryFileRes.msg.indexOf('Failed to upload') !== -1 && <a style={{ color: '#BF7400' }} href="javascript:" onClick={() => contextData.handleUploadErrorDetailsClick(true)}><FormattedMessage id="db.sysAdmin.ClickHereForMoreDetails" /></a> : ''}
                                                </div>}
                                            setVisible={() => contextData.handleRemoveAlert('inventoryFile')}
                                        />
                                    </Col>
                                </Row>
                                : ""}
                        </Col>
                    </Row>

                    {loadingInventoryData ? <Loader elementClass="tree-ajax-loader center-element" /> :  <Row>
                        {inventoryMasterFile.length !== 0 ?
                            <Col className={`${ OSName === 'MacOS' ? 'react-table-gridmapping-full padding-0' : 'react-table-gridmapping padding-0' }`+ ' m-2'}>
                                <Table 
                                     tableColumn={columnsData}
                                     tableRowData={inventoryMasterFile}
                                     isDisplayPageRows={false}
                                    />
                            </Col>
                            :
                            <div><BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /></div>
                        }
                    </Row>}

                    {openUploadErrDetailsModal && <SysAdminFileUploadErrModal />}
                </Col>
            </Row>
        );
}

export default InventoryFile;
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AlertMessage from '../../../../hoc/alert';
import Button from '../../../../hoc/button';
import { ProfileContext } from '../provider/profilesProvider';

class ProfileActions extends Component {
    static contextType = ProfileContext;
    render() {
        const shouldDisable = this.context.state.profilesList.options.length === 0;
        return (
            <div className="title-view col-12 m-0 p-0 d-flex justify-content-between align-items-center">
                <div className="float-left padding-10">
                    <label className="my-auto float-left">{this.context.state.profilesList.selected.name}</label>
                </div>
                <div>
                {(this.context.state.message.status && this.context.state.message.type === "error") ? <AlertMessage type={'danger'} message={this.context.state.message.msg} timer={10000} setVisible={this.context.closeAlertMessage}/>  : ""}
                {(this.context.state.message.status && this.context.state.message.type === "success") ? <AlertMessage type={'success'} message={this.context.state.message.msg} timer={10000} setVisible={this.context.closeAlertMessage}/> : ""}
                </div>
                <div className="float-right">
                    {
                        this.context.state.newProfile ? 
                            <div className="m-0 p-0">
                                <Button handleButtonClick={this.context.saveProfile} className={this.context.state.nohwRevision ? "new btns disabled" : "new btns"} btnText={<FormattedMessage id='manage.profiles.create' />} isDisabled = {this.context.state.nohwRevision}/>
                            </div> :
                            <div className="m-0 p-0">
                                <Button handleButtonClick={this.context.saveProfile} className={this.context.state.nohwRevision || shouldDisable ? "new btns disabled" : "new btns"} btnText={<FormattedMessage id='manage.profiles.saveas' />} isDisabled = {this.context.state.nohwRevision || shouldDisable}/>
                                <Button handleButtonClick={this.context.confirmDeleteProfile} className={this.context.state.nohwRevision || shouldDisable? "new btns disabled" : "new btns"} btnText={<FormattedMessage id='manage.profiles.delete' />} isDisabled = {this.context.state.nohwRevision || shouldDisable}/>
                            </div>
                    }
                   
                    
                </div>
            </div>
        );
    }
}

export default ProfileActions;
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../../../../hoc/loader';
import { getRebootUrl } from '../../../services/deviceService';
import { DeviceContext } from '../../../provider/deviceProvider';
import ModalWindow from '../../../../../../hoc/modelWindow';

class ConfirmRebootModal extends Component {
    static contextType = DeviceContext;
    state = {
        resMessage: "",
        isLoding: false
    }

    confirmReboot = () => {
        let serialNumber = this.context.state.deviceDetailsData.summary[0].items['Serial Number'];
        this.setState({ isLoding: true }, () => {
            getRebootUrl(serialNumber)
                .then((res) => {
                    if (res.status !== "FAIL") {
                        this.context.rebootButtonDisable()
                        this.setState({ resMessage: res, isLoding: false })
                    }
                }).catch((err) => {
                })
        })

    }

    onBtnClickHandlera=(value)=>{
        if (value === 'cancel') this.props.onCloseConfirmRebootModal();
        else if(value === 'confirm' ) this.confirmReboot();
    }

    render() {
        return (
            <ModalWindow
                show={true}
                size="md"
                dialogClassName={"detailsModal"}
                title={<FormattedMessage id={"dm.tab.diagnostics.systemlogs.reboot"} />}
                onBtnClickHandler={this.onBtnClickHandlera}
                onHide={this.props.onCloseConfirmRebootModal}
                modeldata={{
                    content: <React.Fragment>
                        {this.state.isLoding ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                        <Row className='reboot-modal-body'>
                            <Col>
                                {this.state.resMessage !== "" ? this.state.resMessage : <FormattedMessage id={"dm.tab.diagnostics.systemlogs.rebootWarningMsg"} />}
                            </Col>
                        </Row>
                    </React.Fragment>
                }}
                footer={
                    this.state.resMessage !== "" ?
                    [
                        { className: "modalSaveBtn", name: <FormattedMessage id='db.common.close' />, value: "cancel" }, 
                    ]
                    :
                    [
                        { className: "modalCancelBtn", name: <FormattedMessage id="dm.column.cancel" />, value: "cancel" }, 
                        { className: "modalSaveBtn", name: <FormattedMessage id={"groupmanagement.context.enableGPS.confirm.button"} />, value: "confirm" }
                    ]
                }
            />
        )
    }
}

export default ConfirmRebootModal;
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import RegisterHeader from '../components/registerHeader';
import RegisterTable from '../components/registerTable';
import Loader from '../../../../hoc/loader';
import NoDataAvailableComponent from '../../../../hoc/noDataAvailableComponent';
import { RegisterContext } from '../provider/registerProvider';

class RegisterContainer extends Component {
    static contextType = RegisterContext;
    render() {
        return (
            <React.Fragment>
                <Col xs={12} md={12}>
                    {this.context.state.isLoadingData ? <div className="loader-wrap"><Loader elementClass="tree-ajax-loader center-element" /></div> : ""}
                    <Row>
                        <Col className="p-0">
                            <RegisterHeader />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0">
                            <div className="events-table">{this.context.state.filteredTableDataset.length > 0 ? <RegisterTable /> : !this.context.state.isLoadingData && <NoDataAvailableComponent />}</div>
                        </Col>
                    </Row>
                </Col>
            </React.Fragment>
        );
    }
}

export default RegisterContainer;
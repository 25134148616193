import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {VirtualDeviceContext} from '../../provider/virtualDeviceProvider';
import Table from '../../../../../hoc/table';
import { tableData } from '../../../../../utility/virtualDevicerManagementUtility';

const AddorEditProfileTemplate=()=>{
    const contextData = useContext(VirtualDeviceContext);
    return (
            <Row className='p-3'>
                <Col>
                    {contextData.state.mainFormDataErr.emptyCheckboxErr?.isErr && <Row className='pb-2 error-color'><Col>{contextData.state.mainFormDataErr.emptyCheckboxErr?.errMsg}</Col></Row>}
                        <div>
                            <Table
                                tableColumn={ ((contextData.state.isErrorOnValidation || !contextData.state.isClickedContinueBtn) && contextData.state.clickedType !== 'View') ? tableData.profileTemplateTable : tableData.profileTemplateTable.filter((item)=> item.accessor !== 'selected' ) }
                                tableRowData={ (contextData.state.isErrorOnValidation || !contextData.state.isClickedContinueBtn) && contextData.state.clickedType !== 'View' ? contextData.state.mainFormData.vdProfileSettings : contextData.state.viewOnlyProfilePoints?.filter((item)=> item.selected === true ) }
                                showPagination={false}
                                pageSize={15}
                                multiSort={false}
                                sortable={false}
                            />
                        </div>
                </Col>
            </Row>
    )
}

export default AddorEditProfileTemplate;
import React from 'react';
import { Form } from 'react-bootstrap';

const TextArea = ({ type, field, value, placeholder, handleOnchange, size, readOnly, isDisabled, rows, className, handleOnKeyDown }) => {
    return (
        <Form.Control
            as={type}
            placeholder={placeholder}
            onChange={(e) => handleOnchange(e.target.value, field, e)}
            disabled={isDisabled !== undefined && isDisabled ? true : false}
            rows={rows}
            value={value}
            className={className !== undefined ? className : ""}
            onKeyDown={e => handleOnKeyDown ? handleOnKeyDown(e.target.value, field, e) : e.stopPropagation()}
        />
    )
}

export default TextArea;
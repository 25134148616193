import React,{Component} from 'react';
import { UserContext } from '../provider/userProvider'

class selctAllCB extends Component{
    static contextType = UserContext;
    handleSelectAll = (e) => this.context.handleSelectAll(e);
    render(){
        return(
            <input onChange={this.handleSelectAll.bind(this)} type="checkbox" checked={this.context.state.selectAll}/>
        )
    }
}
export default selctAllCB;
import * as actionTypes from './homeActionTypes';

export const setSession = (sessionData) => {
    return {
        type: actionTypes.SET_SESSION,
        sessionData: sessionData
    };
};

export const getAllRoleAndUserPermission = (permissionData) => {
    return {
        type: actionTypes.GET_ALL_PERMISSION_DATA,
        permissionData: permissionData
    };
};
import React, { Component, Fragment } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import NoDataAvailable from '../../../hoc/noDataAvailableComponent';
import ScaleChart from './scaleChart';
import StockChart from './stockChart';
import { MonitorContext } from '../provider/monitorProvider';
import PropertyTable from './propertyTable';
import {getTempUnit} from "../../../services/utilityService";
import Loader from '../../../hoc/loader';
import BlankPage from '../../../hoc/blankPage';
import { FormattedMessage } from 'react-intl';

class MonitorView extends Component {

    static contextType = MonitorContext;

    constructor(props) {
        super(props)
        this.state = {
            expandProperty: false
        }
    }
    myRef = React.createRef()

    updateDimensions = () => {
        if (this.myRef && this.myRef.current && this.context.state.elementHeight !== this.myRef.current.clientHeight) {
            this.context.setValChange(this.myRef.current.clientHeight, 'elementHeight');
        }
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate() {
        this.updateDimensions();
    }

    handleExpandProperty = (bool) => { this.setState({ expandProperty: (bool) ? true : !this.props.disablePropertyView ? false : true }); }

    um1PhaseValue = (type) => {
        let tableCol = [];
        type === "secondary" && this.context.state.secondaryLogIPoints.filter(e => e.text === "Voltage" || e.text === "Current").length > 0 && this.context.state.secondaryPhase.length > 0 ? this.context.state.secondaryPhase.forEach(e => {
            if (this.context.state.secondaryLogIPoints.filter(item => item.text === "Voltage").length > 0) tableCol.push("v120" + e);
            if (this.context.state.secondaryLogIPoints.filter(item => item.text === "Current").length > 0) tableCol.push(e.toLowerCase() + "Current");
        }) : type === "secondary" && this.context.state.secondaryLogIPoints.filter(e => e.text === "Voltage" || e.text === "Current").length > 0 ? ["X1", "X3"].forEach(e => {
            if (this.context.state.secondaryLogIPoints.filter(item => item.text === "Voltage").length > 0) tableCol.push("v120" + e);
            if (this.context.state.secondaryLogIPoints.filter(item => item.text === "Current").length > 0) tableCol.push(e.toLowerCase() + "Current");
        }) : type === "secondary" && (this.context.state.secondaryLogIPoints.filter(e => e.text === "Voltage" || e.text === "Current").length === 0 && this.context.state.secondaryPhase.length === 0) ? tableCol = ["x1Current", "x3Current", "v120X1", "v120X3"] :
        type === "secondary" && (this.context.state.secondaryLogIPoints.filter(e => e.text === "Voltage" || e.text === "Current").length === 0 && this.context.state.secondaryPhase.length > 0) ? this.context.state.secondaryPhase.forEach(e => {
            if (["x1Current", "x3Current", "v120X1", "v120X3"].includes("v120"+e)) tableCol.push("v120" + e);
            if (["x1Current", "x3Current", "v120X1", "v120X3"].includes(e.toLowerCase() + "Current")) tableCol.push(e.toLowerCase() + "Current");
        }):
            type === "load" && this.context.state.secondaryLogIPoints.filter(e => e.text === "Transformer Loading").length > 0 ? tableCol.push("primaryLoad", "kvaThreshold") :
                type === "kwkvar" && this.context.state.secondaryLogIPoints.filter(e => e.text === "kVAR" || e.text === "kW").length > 0 ? this.context.state.secondaryLogIPoints.filter(e => e.text === "kVAR" || e.text === "kW").forEach(d => tableCol.push(d.value)) :
                    type === "kwkvar" && this.context.state.secondaryLogIPoints.filter(e => e.text === "kVAR" || e.text === "kW").length === 0 ? tableCol = ["kVAR", "kW"]
                        : tableCol.push("kvaThreshold");
        return tableCol;
    }

    vc10PhaseValue = (type) => {
        let tableCol = [], lTempData;
        switch (type) {
            case "vc10VkVARInj":
                lTempData = this.context.state.secondaryLogIPoints.filter(e => ["Voltage","kVAR Injected"].includes(e.text));
                lTempData.length > 0 ? lTempData.forEach(e => { tableCol.push(e.text.charAt(0).toLowerCase() + e.text.replace(/\s/g, '').slice(1)); if (e.text === "Voltage") tableCol.push("voltageSetPoint") }) : tableCol.push("voltage", "voltageSetPoint", "kVARInjected");
                break;
            case "vc10SecCurr":
                this.context.state.secondaryPhase.length > 0 ? this.context.state.secondaryPhase.forEach(e => tableCol.push(e.toLowerCase() + "Current")) : tableCol.push("x1Current", "x3Current");
                break;
            case "vc10Load":
                tableCol.push("primaryLoad", "kvaThreshold");
                break;
            case "vc10KWkVAR":
                lTempData = this.context.state.secondaryLogIPoints.filter(e => e.text === "kVAR" || e.text === "kW");
                lTempData.length > 0 ? lTempData.forEach(d => tableCol.push(d.value)) : tableCol.push("kVAR", "kW");
                break;
            case "vc10PowerPhase":
                lTempData = this.context.state.secondaryLogIPoints.filter(e => e.text === "Power Factor" || e.text === "Phase Angle");
                (lTempData.length > 0 && this.context.state.secondaryPhase.length > 0) ? this.context.state.secondaryPhase.forEach(e => lTempData.forEach(t => tableCol.push(e.toLowerCase() + t.value.charAt(0).toUpperCase() + t.value.slice(1)))) :
                    lTempData.length > 0 ? ["x1", "x3"].forEach(e => lTempData.forEach(el => tableCol.push(e + el.value.charAt(0).toUpperCase() + el.value.slice(1)))) :
                        this.context.state.secondaryPhase.length > 0 ? this.context.state.secondaryPhase.forEach(e => tableCol.push(e.toLowerCase() + "PowerFactor", e.toLowerCase() + "PhaseAngle")) : ["x1", "x3"].forEach(e => tableCol.push(e + "PowerFactor", e + "PhaseAngle"));
                break;
        }
        return tableCol;
    }

    updateDummySecondaryYAxis = (yAxisData,axisVal,minmaxArray) =>{
        /** added the method to align the secondary yaxis for those charts which does'nt have a secondary yaxis. 
         * yAxisData is complete yAxis value
         * axisVal is number to identify for which axis dummy value need to be set 
         * minmaxArray is 2 dimentional array with min max values to set the softmin and softmax property for highchart*/
        const yAxisDataVal = JSON.parse(JSON.stringify(yAxisData));
        yAxisDataVal[axisVal] === undefined ? 
        yAxisDataVal[axisVal] = {
            allowDecimals:true,
                    labels: {
                        style: {},
                        x: 5,
                        padding: 2,
                       align: 'left'
                    },
                    title: {
                        text: 'Voltage (V)',
                        style: {},
                        useHTML: true
                    },
                    lineWidth: 1,
                    opposite: true,
                    gridLineWidth: 0,
                    gridLineDashStyle : 'dot'
        }
        : null;
        yAxisDataVal[axisVal].tickPositions  = this.context.state.secondaryLogIPoints.filter(item => item.text === "Voltage").length ? [0, 50, 100, 150, 200] : "";
        yAxisDataVal[axisVal].labels.style.visibility = "hidden";
        yAxisDataVal[axisVal].title.style.opacity = 0;
        yAxisDataVal[axisVal].lineColor = "transparent";
        let YAxisValue =  this.updateSoftMinmax(JSON.parse(JSON.stringify(yAxisDataVal)),minmaxArray)
        return YAxisValue;
    }

    updateSoftMinmax = (yAxisDataVal, minmaxArray) => {
        /** method to set softmin and softmax propert for highchart*/
        minmaxArray && minmaxArray.forEach((item, index) => yAxisDataVal[index] && (yAxisDataVal[index].softMin = minmaxArray[index][0], yAxisDataVal[index].softMax = minmaxArray[index][1]));
        return yAxisDataVal;
    }

    render() {
        const { scaleChartOptions, disableExport, maintainZoom, disablePropertyView, graphData, sitesList, sites, startDate, endDate, startDateTimestamp, endDateTimestamp, monitorChartOptions, phase, logIPoints, roleAndPermission, chartList, elementHeight ,monitorUM1ChartOptions,secondaryPhase,secondaryLogIPoints } = this.context.state;
        const um1PrimaryChartOptions = {
            ...monitorUM1ChartOptions,
                highcharts: {
                    ...monitorUM1ChartOptions.highcharts,
                   // yAxis: [{ ...monitorUM1ChartOptions.highcharts.yAxis[0] }],
                    yAxis: this.updateDummySecondaryYAxis(monitorUM1ChartOptions.highcharts.yAxis,1,[]),
                    title:{
                        text:`Primary Current - Phase ${graphData[0] && graphData[0].details[0] ? graphData[0].details[0].phase : ""}`
                    }
                },
                dataColumns: ["t", "phaseACurrent"],
        };
        const um1SecondaryChartOptions = {
            ...monitorUM1ChartOptions,
           highcharts: {
            ...monitorUM1ChartOptions.highcharts,
            yAxis:this.context.state.treeNode.siteDeviceType === "VC10"? this.updateDummySecondaryYAxis(monitorUM1ChartOptions.highcharts.yAxis,1,[]) :this.updateSoftMinmax(JSON.parse(JSON.stringify(monitorUM1ChartOptions.highcharts.yAxis)),[]),
            title:{
                text:this.context.state.treeNode.siteDeviceType === "VC10" ? "Secondary Current" : "Secondary Current and Voltage"
            }
        },
            dataColumns: this.context.state.treeNode.siteDeviceType === "VC10" ? ["t", "x1Current", "x3Current"] : ["t", "x1Current", "x3Current", "v120X1", "v120X3"]
        };
        const um1TransLoadChartOptions = {
            ...monitorUM1ChartOptions,
            highcharts: {
                ...monitorUM1ChartOptions.highcharts,
                yAxis: this.updateDummySecondaryYAxis(monitorUM1ChartOptions.highcharts.yAxis,1,[]),
                //yAxis: [{ ...monitorUM1ChartOptions.highcharts.yAxis[0] }],
                title:{
                    text:"Transformer Load (kVA)"
                }
            },
            dataColumns: ["t", "primaryLoad", "kvaThreshold"],
        };
        const um1KVAKVArChartOptions = {
            ...monitorUM1ChartOptions,
            highcharts: {
                ...monitorUM1ChartOptions.highcharts,
                yAxis: JSON.parse(JSON.stringify(this.updateDummySecondaryYAxis(monitorUM1ChartOptions.highcharts.yAxis,2,[]))),
                title:{
                    text:"kW and kVAR"
                }
            },
            dataColumns: ["t", "kVAR", "kW", "kWRange"],
        }
        const vc10PowerFactorFaceAngleOptions = {
            ...monitorUM1ChartOptions,
           highcharts: {
            ...monitorUM1ChartOptions.highcharts,
            yAxis:this.updateSoftMinmax(JSON.parse(JSON.stringify(monitorUM1ChartOptions.highcharts.yAxis)),[]),
            title:{
                text:"Power Factor and Phase Angle"
            }
        },
            dataColumns: ["t", "x1PowerFactor","x3PowerFactor", "x1PhaseAngle","x3PhaseAngle"]
        };
        const vc10VkVARInjChartOptions = {
            ...monitorUM1ChartOptions,
            highcharts: {
                ...monitorUM1ChartOptions.highcharts,
                yAxis: JSON.parse(JSON.stringify(this.updateDummySecondaryYAxis(monitorUM1ChartOptions.highcharts.yAxis,2,[]))),
                title:{
                    text:"Voltage and kVAR Injected"
                }
            },
            dataColumns: ["t", "voltage","voltageSetPoint","voltageSetPointRange", "kVARInjected"],
        }
        um1KVAKVArChartOptions.highcharts.yAxis[0].title.text = "kVAR";
        um1KVAKVArChartOptions.highcharts.yAxis[1].title.text = "Real Power (kW)";
        um1TransLoadChartOptions.highcharts.yAxis[0].title.text = "Apparent Power (kVA)";
        vc10PowerFactorFaceAngleOptions.highcharts.yAxis[0].title.text = "Power Factor";
        vc10PowerFactorFaceAngleOptions.highcharts.yAxis[1].title.text = "Phase Angle (Degree)";
        vc10VkVARInjChartOptions.highcharts.yAxis[0].title.text = "kVAR Injected";
        vc10VkVARInjChartOptions.highcharts.yAxis[1].title.text = "Voltage (V)";
        // vc10VkVARInjChartOptions.highcharts.yAxis[1].plotLines= [{
        //     color: '#FF0000',
        //     width: 2,
        //     value: 110
        // }]
        return (
            <Fragment>
                <Form className={!(maintainZoom || !disableExport) ? "mt-2" : ""}>
                    {
                        (maintainZoom || !disableExport) ?
                        <div className={"scale-view p-0 " + ((disablePropertyView) ? 'col-xs-12 col-12' : 'col-xs-9 col-9' ) }>
                            <ScaleChart 
                                options={scaleChartOptions}
                                startDateTimestamp={startDateTimestamp}
                                endDateTimestamp={endDateTimestamp}
                                startDate={startDate}
                                endDate={endDate}
                                hideAndShowTreeView={this.props.hideAndShowTreeView}
                                disablePropertyView={disablePropertyView}
                                timezone={roleAndPermission.timezone}
                                maintainZoom={maintainZoom}
                                afterSetExtremes={(chart, event) => this.context.scaleAfterSetExtremes(chart, event)}
                            ></ScaleChart>
                        </div> : null
                    }
                    {graphData.map((item, key) => {
                        item.phaseDetails.forEach(e => e.suffix = e.type==="temperature" ? ` (${getTempUnit('value')})` : e.suffix);
                        return ((sites && sites.filter(s => (s.text === item.name)).length === 1) || (sitesList.length === 0 && sites.length === 0) || (sitesList.length > 0 && sites.length === 0)) &&  !item.hideChart ?
                        <FormGroup key={key} className="clearfix m-0 p-2 mb-2 border-1px site-View" xs={12} md={12}>
                            <div className="pl-3"> {item.breadCrumb} </div>
                            <div className="row m-0 p-0" xs={12} md={12} ref={this.myRef}>
                            <div className={"graph-view p-0 pr-2 " + ((disablePropertyView || item.details.filter(e => e.pointData).length === 0) ? 'col-xs-12 col-12' : 'col-xs-9 col-9' ) } >
                                {/* {
                                 this.context.state.loading ?  <Loader elementClass="device-management-ajax-loader" /> : 
                                 (item.details && item.details.length > 0 && item.details.filter(e => e.pointData).length) ?
                                                <StockChart
                                                    siteData={item}
                                                    options={!item.chartType ? monitorChartOptions : item.chartType === "primary" ? um1PrimaryChartOptions : ["secondary","vc10SecCurr"].includes(item.chartType) ? um1SecondaryChartOptions : ["load","vc10Load"].includes(item.chartType) ? um1TransLoadChartOptions : (item.chartType === "vc10PowerPhase") ? vc10PowerFactorFaceAngleOptions : (item.chartType === "vc10VkVARInj") ? vc10VkVARInjChartOptions : um1KVAKVArChartOptions}
                                                    startDate={startDateTimestamp}
                                                    endDate={endDateTimestamp}
                                                    phase={phase}
                                                    secondaryPhase={secondaryPhase}
                                                    view={logIPoints}
                                                    secondaryView={secondaryLogIPoints}
                                                    hideAndShowTreeView={this.props.hideAndShowTreeView}
                                                    disablePropertyView={disablePropertyView}
                                                    timezone={roleAndPermission.timezone}
                                                    chartList={chartList}
                                                    elementHeight={elementHeight}
                                                    zoomOut={(chart, event) => this.context.zoomOut(chart, event)}
                                                    updateXScale={(chart, event) => this.context.updateXScale(chart, event)}
                                                    treeNode={this.context.state.treeNode}
                                                ></StockChart> 
                                  :  (item.details && item.details.length > 0 && item.details.filter(e => e.type === "VC10").length) ? <BlankPage blankText={<FormattedMessage id='monitor.feederVC10Message' />} /> : <NoDataAvailable/>
                                } */}
                                 {
                                 this.context.state.loading ?  <Loader elementClass="device-management-ajax-loader" /> : 
                                 (item.details && item.details.length > 0 && item.details.filter(e => e.type === "VC10").length) && ["FEEDER","LATERAL"].includes(this.context.state.treeNode.type) ? <BlankPage blankText={<FormattedMessage id='monitor.feederVC10Message' />} /> :
                                 (item.details && item.details.length > 0 && item.details.filter(e => e.pointData).length) ?
                                                <StockChart
                                                    siteData={item}
                                                    options={!item.chartType ? monitorChartOptions : item.chartType === "primary" ? um1PrimaryChartOptions : ["secondary","vc10SecCurr"].includes(item.chartType) ? um1SecondaryChartOptions : ["load","vc10Load"].includes(item.chartType) ? um1TransLoadChartOptions : (item.chartType === "vc10PowerPhase") ? vc10PowerFactorFaceAngleOptions : (item.chartType === "vc10VkVARInj") ? vc10VkVARInjChartOptions : um1KVAKVArChartOptions}
                                                    startDate={startDateTimestamp}
                                                    endDate={endDateTimestamp}
                                                    phase={phase}
                                                    secondaryPhase={secondaryPhase}
                                                    view={logIPoints}
                                                    secondaryView={secondaryLogIPoints}
                                                    hideAndShowTreeView={this.props.hideAndShowTreeView}
                                                    disablePropertyView={disablePropertyView}
                                                    timezone={roleAndPermission.timezone}
                                                    chartList={chartList}
                                                    elementHeight={elementHeight}
                                                    zoomOut={(chart, event) => this.context.zoomOut(chart, event)}
                                                    updateXScale={(chart, event) => this.context.updateXScale(chart, event)}
                                                    treeNode={this.context.state.treeNode}
                                                ></StockChart> 
                                   : <NoDataAvailable/>
                                }
                            </div>
                            {
                                (item.details && item.details.length > 0 && item.details.filter(e => e.pointData).length && disablePropertyView) ?
                                <span className="onhvr-expnd right-0" onClick={ ()=> this.context.setValChange(false, 'disablePropertyView') }> 
                                    <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                                </span> : null
                            }
                            {
                                (item.details && item.details.length > 0 && item.details.filter(e => e.pointData).length && !disablePropertyView) ?
                                <div className="property-view col-xs-3 col-3 p-0" >
                                   <div className='position-relative' onMouseEnter={ () => this.handleExpandProperty(true) } onMouseLeave={ () => this.handleExpandProperty(false)}>
                                    <PropertyTable 
                                        startDate = {startDate}
                                        endDate = {endDate}
                                        phase = { !item.chartType ? phase :  item.chartType === "primary" ? ["phaseACurrent"] : item.chartType === "secondary" ? this.um1PhaseValue(item.chartType) : ["vc10VkVARInj","vc10SecCurr","vc10Load","vc10KWkVAR","vc10PowerPhase"].includes(item.chartType) ? this.vc10PhaseValue(item.chartType) : this.um1PhaseValue(item.chartType)}
                                        logIPoints = {logIPoints}
                                        item = {
                                            !item.chartType ? item :
                                             {
                                                 ...item,
                                                 phaseDetails:[...item.phaseDetails.filter(item => item.name === "type" || item.type === "min" || item.type === "max" || item.type === "avg")]
                                             }  
                                        }
                                        treeNode = {this.context.state.treeNode}
                                    />
                                    { this.state.expandProperty ?
                                        <span className="onhvr-expnd-left left-0" onClick={ ()=> this.context.setValChange(true, 'disablePropertyView') }>
                                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                                        </span> : null
                                    }
                                    </div>
                                </div> : null
                            }

                            </div>
                        </FormGroup> : null
                    })}
                </Form>
            </Fragment>
        )
        
    }
}

const mapStateToProps = (state) => {
    return {
        hideAndShowTreeView: state.treeviewData.hideAndShowTreeView
    }
}

export default connect(mapStateToProps, {})(MonitorView);

import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as headerActions from '../header/actions';
import Register from '../mainContent/faultAnalysis/register';
import Summary from '../mainContent/faultAnalysis/summary';
import Details from '../mainContent/faultAnalysis/details';
import Manage from '../mainContent/manage'

class EventsRoutes extends React.PureComponent {

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.props.onRouteChange(this.props.location.pathname, this.props.disableTree.includes(this.props.location.pathname.slice(1)))
    }

    render() {
        return (
            <Switch>
                <Route path='/events/register' component={Register} />
                <Route path='/events/summary' component={Summary} />
                <Route path='/events/details' component={Details} />
                <Route path="/manage/details" component={Manage} />
                <Route exact path='/events' render={() => ( <Redirect to='/events/register'/> )}/>
            </Switch>
        );
    }
}

const mapStateToProps = state => {
    return {
        disableTree: state.headerData.disableTree
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onRouteChange: (route, isTreeDisable) => dispatch(headerActions.onRouteChange(route, isTreeDisable))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsRoutes));
import React, { Component, Fragment } from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getNodeDetailsField } from '../../utility/contextMenuUtility';
import ModalWindow from '../../hoc/modelWindow';

class NodeDetailsComponent extends Component {
    render() {
        const modalTitle = <div className="popup-title"><FormattedMessage id={`groupmanagement.context.details.title`} /> {this.props.clickedNode.node.name}</div>;
        const modalBody = <div className="min-vh-25">
            <Row className="p-0 m-0 mb-1">
                <div className="col-6 m-0 p-0"><FormattedMessage id='groupmanagement.context.details.name' /></div>
                <div className="col-6 m-0 p-0">{this.props.clickedNode.node.name}</div>
            </Row>
            {getNodeDetailsField[this.props.clickedNode.node.type].map((field, i) => {
                return (
                    <Row className="p-0 m-0 mb-1" key={i}>
                        <div className="col-6 m-0 p-0"><FormattedMessage id={`groupmanagement.context.details.${field}`} /></div>
                        {field !== "deviceLocation" ? <div className="col-6 m-0 p-0">{this.props.nodeDetails[field]}</div> :
                            <div className="col-6 m-0 p-0">{this.props.nodeDetails.lat ? this.props.nodeDetails.lat : "null"}, {this.props.nodeDetails.lon ? this.props.nodeDetails.lon : "null"}</div>
                        }
                    </Row>)
            })}
        </div>
        return (
            <Fragment>
                {this.props.isModalOpen ?
                    <ModalWindow
                        show={this.props.isModalOpen}
                        onHide={() => this.props.closeModal()}
                        size={"md"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }} /> : ""}
            </Fragment>
        )
    }
}

export default NodeDetailsComponent;
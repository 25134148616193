import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ChangePassword from '../../mainContent/changePassword/index';
import {changePasswordData} from '../../utility/userManagementUtility'

class OpenChangePasswordModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenChangePasswordModal: false
        }
    }

    handleOnCLick = () => {
        changePasswordData.isOpenChangePasswordeModal = true;
        this.setState({ isOpenChangePasswordModal: true })        
    }

    render() {
        return (
            <React.Fragment>
                <div onClick={this.handleOnCLick} className="noNavigationItemLink">
                    <FormattedMessage id={`nav.main.changepassword`} />
                </div>
                <div>
                    {this.state.isOpenChangePasswordModal ? <ChangePassword /> : ""}
                </div>
            </React.Fragment>
        );
    }
}

export default OpenChangePasswordModal;
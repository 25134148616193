import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import UpdateProfile from '../../mainContent/updateProfile/index';
import {userMngmntData} from '../../utility/userManagementUtility'

class OpenUpdateProfileModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenUpdateProfileModal: false
        }
    }

    handleOnCLick = () => {
        userMngmntData.isOpenUpdateProfileModal = true;
        this.setState({ isOpenUpdateProfileModal: true })
    }

    render() {
        return (
            <React.Fragment>
                <div onClick={this.handleOnCLick} className="noNavigationItemLink">
                    <FormattedMessage id={`nav.main.editprofile`} />
                </div>
                <div>
                    {this.state.isOpenUpdateProfileModal ? <UpdateProfile /> : ""}
                </div>
            </React.Fragment>
        );
    }
}

export default OpenUpdateProfileModal;
import { combineReducers } from 'redux';

import headerReducer from './headerReducer';
import treeviewReducer from './treeviewReducer';
import loginReducer from './loginReducer';
import deviceManagementReducer from './deviceManagementReducer';
import dashboardReducer from './dashboardReducer';
import configurePropertiesReducer from './configurePropertiesReducer';
import faultAnalysisReducer from './faultAnalysisReducer';

const ampleReducer = combineReducers({
    headerData: headerReducer,
    loginData:loginReducer,
    treeviewData: treeviewReducer,
    deviceManagementData: deviceManagementReducer,
    dashboardData : dashboardReducer,
    configurePropertiesData: configurePropertiesReducer,
    faultAnalysisData: faultAnalysisReducer
});

export default ampleReducer;
import React from 'react';
import CustomReportsManagementContainer from './containers/customReportsManagementContainer';
import CustomReportsManagementProvider from './provider/customReportsManagementProvider';

const CustomReportsManagement = () => {
    return (
        <CustomReportsManagementProvider>
            <CustomReportsManagementContainer />
        </CustomReportsManagementProvider>
    );
}
export default CustomReportsManagement;
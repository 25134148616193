
import { requestGET, requestPOST } from '../../services/extendedService';
import { getURL } from '../../providers/configProvider';
import { groupRouteParams, getNodePath } from './../../services/utilityService'


export const loadNextLevel = (params) => {
    let url = '';
    if (params.apiType.indexOf('SITE') >= 0) {
        url = getURL('deviceManagement', 'data', params);
    } else {
        url = getURL('groupManagement', 'nodes', params);
    }
    return requestGET(url);
}

export const nodeSearch = (orgId, searchedKey) => {
    const params = {'ROOTNODE': orgId, 'apiType':'SEARCH'};
    let url = getURL('groupManagement', 'nodes', params);
    return requestPOST(url, {'searchPattern': searchedKey});
}

export const editNode = (params, reqBody) => {
    let url = getURL('groupManagement', 'editNode', params);
    return requestPOST(url, reqBody);
}

//Adding / Updating / Deleting nodes 
export const nodeOperations = (route, mode, nodeDetails) => {
    const routeParams = [...route];
    const requstParams = { ...nodeDetails };
    const params = groupRouteParams(requstParams, routeParams);
    const url = getURL('groupManagement', mode, params);
    if (nodeDetails.type === 'CUSTOM_GROUPS') {
        delete nodeDetails.type;
        delete nodeDetails.parentId;
    }
    return requestPOST(url, nodeDetails);
}

export const enableGPSOperations = (route) => {
    const params = {'NODEPATH': getNodePath(route, false), 'apiType': 'data' };
    const url = getURL('groupManagement', 'enablegps', params);
    return requestPOST(url, {});
}

export const getNodeDetails = (params) => {
    const combinedParams = {'NODEPATH': getNodePath(params, false), 'apiType': 'data' };
    const url = getURL('groupManagement', 'details', combinedParams);
    return requestGET(url);
}

export const getTreeData = (params, apiType, clickedType) => {
    let url = getURL('groupManagement', apiType, params);
    return requestGET(url);
}

export const updateNode = (changedNode) => {
    const params = {'apiType':'data'};
    let url = getURL('groupManagement', 'updateNode', params);
    return requestPOST(url, changedNode);
}
export const editSiteOrder = (params) => {
    let url = getURL('groupManagement', 'editSiteOrder', params);
    return requestGET(url, {}, {validateSuccess: false});
}

export const updateSiteOrder = (siteOrderObject) => {
    const params = {'apiType':'data'};
    let url = getURL('groupManagement', 'siteOrder', params);
    return requestPOST(url, siteOrderObject, {}, {validateSuccess: false});
}
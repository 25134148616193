import React from 'react';
import { connect } from 'react-redux';
import FeederChart from '../containers/feederBarContainer';
import SiteChart from '../containers/siteLineContainer';
import TimeFilter from '../../../hoc/timeFilter';
import { dashboardData, feederBarData, siteLineData } from '../../../utility/dashboardUtility';
import BreadCrumb from '../../../hoc/breadCrumb';
import { getLoadAndUnbalanceFeederData, getLoadAndUnbalanceSiteData } from '../services/dashboardService';
import { numberToDate } from '../../../services/utilityService';
import Loader from '../../../hoc/loader'
import BlankPage from '../../../hoc/blankPage'
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../../../hoc/modelWindow';

class PopModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: this.props.dateRange,
      barOptions: feederBarData,
      lineOptions: siteLineData.options,
      isResDataBlank: false,
      series: [],
      lineSeries: [],
      customClassName: ''
    }
  }
  static getDerivedStateFromProps(props) {
    return {
      breadcrumbData: props.breadcrumbData
    };
  }
  componentDidMount() {
    this.getFeederBarChartData();
  }
  getFeederBarChartData = () => {
    let feederArr = this.state.barOptions;
    feederArr.series = this.emptyData(feederArr); // do all blank series data
    
    this.setState({ isLoding: true })
    let initialPayloadData = { orgId: this.props.orgId, range: this.state.dateRange, };
    let removeFirstElement = this.state.breadcrumbData.filter(item => item.type !== 'ROOTNODE'); // from this.state.payload remove always 1st element ([{name : 'SentientEnergy', type : "ROOTNODE"}]), because its not required to send
    let arrToObj = Object.fromEntries(removeFirstElement.map(item => [item.type, item.name])); //
    getLoadAndUnbalanceFeederData(this.props.requestParams, { ...initialPayloadData, ...arrToObj })
      .then(data => {
        if (data?.dataset?.length !== 0) {
          var size = 50;
          for (var i = 0; i < data.dataset.length; i += size) {
            this.processData(data.dataset.slice(i, i + size));
            this.getFeederBarLineChartData(data);
          }
          this.setState({ isResDataBlank: false, isLoding: false })
        } else {
          this.setState({ isResDataBlank: true, isLoding: false })
        }
      }).catch(error => {
      })
  }
  getFeederBarLineChartData = async (data) => {
    let lineChartArr = this.state.lineOptions;
    lineChartArr.series = await this.emptyData(lineChartArr); // do all blank series data

    var lineOptions = Object.assign({}, this.state.lineOptions);
    lineOptions['siteName'] = data.dataset ? data.dataset[0].name : data;
    let initialPayloadData = { orgId: this.props.orgId, range: this.state.dateRange, };
    let removeFirstElement = this.state.breadcrumbData.filter(item => item.type !== 'ROOTNODE'); // from this.state.payload remove always 1st element ([{name : 'SentientEnergy', type : "ROOTNODE"}]), because its not required to send
    let arrToObj = Object.fromEntries(removeFirstElement.map(item => [item.type, item.name])); //
    this.setState({ lineOptions: lineOptions, isLoding : true }, () => {
      getLoadAndUnbalanceSiteData(this.props.requestParams, { ...initialPayloadData, ...arrToObj, site: this.state.lineOptions.siteName })
        .then(data => {
          var size = 50;
          for (var i = 0; i < data.dataset.length; i += size) {
            this.processLineChartData(data.dataset.slice(i, i + size));
         }
        }).catch(error => {
        })
    });
  }

  processData = (filterData) => {
    this.setState({
      series: [...this.state.series, ...filterData]
    }, () => {
      var feederArr = Object.assign({}, this.state.barOptions);
      var categories = [];
      for (var i = 0; i < filterData.length; i++) {
        feederArr.series[0].data.push(filterData[i].phaseA && Number(filterData[i].phaseA));
        feederArr.series[1].data.push(filterData[i].phaseB && Number(filterData[i].phaseB));
        feederArr.series[2].data.push(filterData[i].phaseC && Number(filterData[i].phaseC));
        categories.push(filterData[i].name);
      }
      feederArr.xAxis[0].categories = categories;
      this.setState({
        barOptions: feederArr
      })
    })
  }

  processLineChartData = (data) => { 
    this.setState({
      lineSeries: [...this.state.lineSeries, ...data]
    }, () => {
      var dataObj = this.state.lineSeries;
      var timestamp = [];
      var lineOptions = Object.assign({}, this.state.lineOptions)
      for (var i in dataObj) {
        lineOptions.series[0].data.unshift(dataObj[i].unbalance);
         lineOptions.series[1].data.unshift(dataObj[i].maxLoad.phaseA);
         lineOptions.series[2].data.unshift(dataObj[i].maxLoad.phaseB);
         lineOptions.series[3].data.unshift(dataObj[i].maxLoad.phaseC);
        if (this.state.dateRange === 1) {
          timestamp.unshift(numberToDate(dataObj[i].fromDate) + ' ' + dataObj[i].fromTime.toString().split("").slice(1, 3).join("") + ":00");
        } else {
          timestamp.unshift(numberToDate(dataObj[i].fromDate));
        }
      }
      lineOptions.xAxis[0].categories = timestamp;
      this.setState({ lineOptions: lineOptions,isLoding : false })
    })
  }

  emptyData = (data) => {
    let tmpArr = [];
    for (let i = 0; i < data.series.length; i++) {
      tmpArr.push({ ...data.series[i], data: [] });
    }
    return tmpArr
  }

  handleOnClickTimeFilter = (value) => {
    value !== this.state.dateRange &&
      this.setState({ dateRange: value, series: [], lineSeries: [] }, () => {
        this.getFeederBarChartData();
      })
  }

  handleClose = () => {
    this.props.onHide();
  }

  updateSiteChart = (props) => {
    props !== this.state.lineOptions['siteName'] &&
      this.setState({lineSeries: [] },()=>{
        this.getFeederBarLineChartData(props)
      });
  }

  render() {
    return (
      <div className="popup-modal">
        <ModalWindow
          show={this.props.show}
          dialogClassName={"custom-dialog modal-dialog-centered" + this.props.customClassName}
          title={
            <div className="container-fluid text-center">
              <span className="float-right"><TimeFilter data={dashboardData.timeFilterData} handleOnClick={this.handleOnClickTimeFilter} selected={this.state.dateRange} /></span>
              <span className="header-title"><FormattedMessage id='db.modal.peakLoad' /></span><br />
              <span className='popup-bread-crumb'>
                <BreadCrumb data={this.state.breadcrumbData} isClickable={false} separatorSymble={' - '} />
              </span>
            </div>
          }
          onHide={this.handleClose}
          modeldata={{
            content: <React.Fragment>
              {
                !this.state.isResDataBlank ?
                  <React.Fragment>
                    {this.state.isLoding && <Loader elementClass="tree-ajax-loader center-element" /> }
                    <FeederChart options={this.state.barOptions} handleFeederClick={this.updateSiteChart} />
                    <hr />
                    <SiteChart optionss={this.state.lineOptions} />
                  </React.Fragment>
                  : <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
              }
            </React.Fragment>
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    requestParams: state.dashboardData.requestParams
  };
}
export default connect(mapStateToProps)(PopModal);
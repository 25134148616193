import React, { useContext, useState } from "react"
import { DGWContext } from "../provider/dgwProvider";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const CertificateContainer = () => {

    const { state, handleCertDisp } = useContext(DGWContext);

    return (
        <>
            <div>
                <Row>
                    <Col>
                        <div className="font-weight-bold h5"><FormattedMessage id='dgw.modal.header' /></div>
                    </Col>
                    <Col className="flex-content">
                        <span onClick={handleCertDisp} className='modal-close'><FontAwesomeIcon icon={faTimes} size='sm' /> </span>
                    </Col>
                </Row>
            </div>
            <div className="certificate-section">
            <div className="certificate-all">
                <div className="certificate-all-body" dangerouslySetInnerHTML={{ __html: state.certificateData }} />
            </div>
            </div>
        </>
    )
}
export default CertificateContainer
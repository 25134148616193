import React, { Component } from 'react';
import ProfilesContainer from './containers/profilesContainer';
import ProfilesProvider from './provider/profilesProvider';
import './index.css';

class Profiles extends Component {
    render() {
        return (
            <ProfilesProvider>
                <ProfilesContainer />
            </ProfilesProvider>
        );
    }
}

export default Profiles;
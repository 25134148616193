import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import { SystemAdminContext } from '../provider/systemAdminProvider';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { tableOptions } from '../../../utility/deviceManagementUtility'

class TableTopSection extends Component {
    redirectToManageDevice = () => {
        tableOptions.customFilterColumns.forEach(item => {
            if (item.dataField === 'state') {
                item.items = []; item.selectedItems = [];
                item.selectedItems.push({ name: item.dataField, text: 'Non-Production', value: 'Non-Production' })
            }
            if (item.dataField === 'statuses') {
                item.items = []; item.selectedItems = [];
                item.selectedItems.push({ name: item.dataField, text: 'UNREGISTERED', value: 'UNREGISTERED' })
            }
        })

        tableOptions.filterSelectionCount = 2;
        tableOptions.pageComingFrom = "systemAdmin"
        this.props.history.push("/manage/details")
    }

    render() {
        return (
            <Row className="table-top-section" >
                <Col className='p-0'>
                    <span className="font-size-2">{this.props.title}
                        {this.props.isDisplayDeviceMap !== undefined ?
                            <span onClick={this.context.state.gridUnmappedCount > 0 ? this.redirectToManageDevice : () => { }} className={this.context.state.gridUnmappedCount > 0 ? "cursor-pointer" : "cursor-no-drop"} >
                                &nbsp; {this.context.state.gridUnmappedCount}&nbsp;<FormattedMessage id='db.sysAdmin.deviceNotMapped' />
                            </span>
                            :
                            ""}
                    </span>
                </Col>
                <Col className="text-right p-0"> {this.props.displayBtn} </Col>
            </Row>
        );
    }
}

export default withRouter(TableTopSection);
TableTopSection.contextType = SystemAdminContext;
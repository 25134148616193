import React, { Fragment, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '../../../hoc/form/textField';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import DateRangePicker from "react-bootstrap-daterangepicker";
import Tooltip from '../../../hoc/tooltip';

const DateRangePick = (props) => {
    const datePickerRef = useRef();
    return (
        <Fragment>
            <span className="float-left col-form-label">
                <FormattedMessage id={`customreports.label.from`} />&nbsp;&nbsp;
            </span>
            <span className="float-left col-form-label p-0" style={{ width: '12vw' }}>
                <TextField
                    type={'text'}
                    value={props.startDate}
                    size={'md'}
                    className={'p-10 cursor-not-allowed'}
                    readOnly={true}
                    isDisabled={true}
                />
            </span>
            <span className="float-left col-form-label">
                &nbsp;&nbsp;<FormattedMessage id={`customreports.label.to`} />&nbsp;&nbsp;
            </span>
            <span className="float-left col-form-label p-0" style={{ width: '12vw' }}>
                <TextField
                    type={'text'}
                    value={props.endDate}
                    size={'md'}
                    className={'p-10 cursor-not-allowed'}
                    readOnly={true}
                    isDisabled={true}
                />
            </span>
            <span className="float-left col-form-label p-0 pl-2">
                {
                    (props.endDate !== null && props.startDate !== null) ?
                        <DateRangePicker ref={datePickerRef}
                            initialSettings={{
                                singleDatePicker: false,
                                showDropdowns: true,
                                startDate: props.startDate,
                                endDate: props.endDate,
                                linkedCalendars: false,
                                maxYear: parseInt(moment().format('YYYY'), 10),
                                maxDate: moment(props.endDate),
                                showISOWeekNumbers: true,
                                autoUpdateInput: true,
                                opens: 'left',
                                autoApply: true, //for display footer section (apply and cancel)
                            }}
                            onCallback={(event, picker) => props.onCallback(event, picker)}
                        >
                            <span className="btn btnStyling date-icon icon-width float-right border-1px m-0 py-1 px-3">
                            <Tooltip 
                                placement='top' 
                                message={<FormattedMessage id={`customreports.label.dates`} />} 
                                baseContent={ <FontAwesomeIcon icon={faCalendarAlt} size="lg" />}/>
                                
                            </span>
                        </DateRangePicker> : null
                }
            </span>
        </Fragment>
    );
}

export default DateRangePick;
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { ConfigStatusContext } from '../provider/configStatusProvider';
import JobStatus from '../components/jobStatusComponent';
import ConfigDeviceListTable from '../components/configDeviceListTableComponent';
import ConfigStatusActions from '../components/configStatusActionsComponent';
import Loader from '../../../../hoc/loader';
import BlankPage from '../../../../hoc/blankPage';
import { postConfigStatusData } from '../services/configStatusService';
import * as currentJobUtility from '../../../../utility/currentJobUtility';
import DeviceParameters from '../components/deviceParameters';

class JobDetailsContainer extends Component {
    static contextType = ConfigStatusContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            displayColumns: currentJobUtility.selectedColumn.configData
        }
    }

    /* Device list table columns filter */
    filterConfigTableColumn = (value) => {
        let prvData = this.state.displayColumns;
        let filterData = [];
        if (prvData.includes(value)) filterData = prvData.filter(item => item !== value)
        else filterData = [...prvData, value]
        currentJobUtility.selectedColumn.configData = filterData;
        sessionStorage.setItem("configStatusExpandColumn",  JSON.stringify(filterData));
        this.setState({ displayColumns: filterData })
    }

    handleOnChangeCheckBox = (event, device) => {
        if (!currentJobUtility.configStatusCheck(device, 'configStatus') &&
            !currentJobUtility.deviceStatusCheck(device, 'deviceStatus') &&
            !currentJobUtility.otapStatusCheck(device, 'otapJobQueueStatus', 'otapStatus')) {
            event.target.checked ? currentJobUtility.tableOptions.selectedRows.push(device) : currentJobUtility.tableOptions.selectedRows.splice(currentJobUtility.tableOptions.selectedRows.indexOf(device), 1)
        }
        (currentJobUtility.tableOptions.selectedRows.length > 0) ? currentJobUtility.tableOptions.enableRetryBtn = true : currentJobUtility.tableOptions.enableRetryBtn = false;
    }

    handleActionItems = (type) => {
        let deviceIds = [];
        if (type === 'retry') {
            currentJobUtility.tableOptions.selectedRows.length > 0 && currentJobUtility.tableOptions.selectedRows.forEach((device) => {
                deviceIds.push((device.id).toString());
            })
        }
        let devices = (type === 'retry') ? deviceIds : this.context.state.failureDevices;
        this.makeActionItemsAPiCall(devices);
    }

    makeActionItemsAPiCall = (devices) => {
        var params = {'apiType':'data'};
        this.setState({ isLoading: true }, () => {
            postConfigStatusData(params, devices, 'retryAll')
                .then(data => {
                    if (data.status !== 'FAIL') {
                        this.context.fetchJobList(this.context.state.selectedJobName);
                    }
                    this.setState({ isLoading: false });
                }).catch(error => {
                    this.setState({ isLoading: false });
                });
        })
    }

    componentDidMount = async () => {
        !sessionStorage.getItem("configStatusExpandColumn") ? sessionStorage.setItem("configStatusExpandColumn", JSON.stringify(this.state.displayColumns)) : this.setState({displayColumns:[...JSON.parse(sessionStorage.getItem("configStatusExpandColumn"))]});
    }

    render() {
        return (
            <Fragment >
                <JobStatus />
                <Row className="mr-0">
                    <Col xs={8} md={8} >
                        <ConfigStatusActions handleActionItems={(type) => this.handleActionItems(type)} filterConfigTableColumn={this.filterConfigTableColumn} stateObj={this.state} />
                        {this.context.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                        <div className="device-table">{this.context.state.deviceListTableDataset.length > 0 ?
                            <ConfigDeviceListTable handleOnChangeCheckBox={(evt, device) => (this.handleOnChangeCheckBox(evt, device))} stateObj={this.state} /> :
                            <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
                        </div>
                    </Col>
                    <Col xs={4} md={4} className="pl-0 pr-0" >
                        <DeviceParameters />
                    </Col>
                </Row>

            </Fragment>
        )
    }
}

export default JobDetailsContainer;
import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import HighCharts from "highcharts/highcharts";
import HC_more from 'highcharts/highcharts-more';
import { injectIntl } from 'react-intl';

HC_more(HighCharts)

class BubbleComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.chart = null;
    }
    componentDidMount() {
        this.chart = this.refs.chart.chart;
        this.chart.component = this;
    }
    loadPointData = (pointData) => {
        this.props.handleBubblePointClick(pointData);
    }
    render() {
        const { formatMessage } = this.props.intl;
        return (
            <span className='set-width-height'>
                <HighchartsReact
                    highcharts={HighCharts}
                    // options={this.props.options} //without format message for all options data
                    options={{ //with format message
                        ...this.props.options,
                        ...this.props.options.xAxis.title.text = formatMessage({ id: "db.bubbleChart.peakLoadA" }),
                        // ...this.props.options.xAxis.plotLines[0].label.text = formatMessage({ id: "db.bubbleChart.peakLoadThreshold" }),
                        ...this.props.options.yAxis.title.text = formatMessage({ id: "db.bubbleChart.peakUnbalanceA" }),
                        // ...this.props.options.yAxis.plotLines[0].label.text = formatMessage({ id: "db.bubbleChart.peakLoadThreshold" }),
                        ...{
                            tooltip: {
                                useHTML: true,
                                padding: 10,
                                headerFormat: '<div class="padding-0-10 padding-b-10"><table>',
                                pointFormat: '<tr><td colspan="2"><h6><strong>{point.name}</strong></h6></td></tr>' +
                                    `<tr><td style="padding-right: 0.5vh">${formatMessage({ id: "db.modal.peakLoad" })}: </td><td>{point.x} ${formatMessage({id: "common.label.amperes"})}</td></tr>` +
                                    `<tr><td style="padding-right: 0.5vh">${formatMessage({ id: "db.table.fullName.pu" })}: </td><td>{point.y} ${formatMessage({id: "common.label.amperes"})}</td></tr>` +
                                    `<tr><td style="padding-right: 0.5vh">${formatMessage({ id: "db.bubbleChart.deviceCount" })}: </td><td>{point.z}</td></tr>`,
                                footerFormat: '</table></div>'
                            },
                        }
                    }}
                    ref={"chart"}
                />
            </span>
        )
    }
}

export default injectIntl(BubbleComponent);
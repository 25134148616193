import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';

import { fetchBrowserTimeZone } from '../../../../services/utilityService';
import TextField from '../../../../hoc/form/textField';

const ReportPicker = (props) => {

    return (
        <Fragment>
        <Row className="m-o mx-1 p-10">
                <Col sm={2} xs={2} className="float-left col-form-label p-0 m-t-2 ">
                    <FormattedMessage id={`manage.report.dateRange`} />{fetchBrowserTimeZone('zoneAbbr', props.timezone)}:
                </Col>
                <Col sm={4} xs={4} className="float-left p-0 border-0">
                    <Col sm={4} xs={4} className="float-left p-0 border-0 p-r-10">
                        <TextField
                            type={'text'}
                            value={props.startDate}
                            size={'sm'}
                            className={'p-10 cursor-not-allowed'}
                            readOnly={true}
                            isDisabled={true}
                        />
                    </Col>
                    <Col sm={1} xs={1} className="float-left p-0 border-0 text-center" style={{'width':'3vh'}}>-</Col>
                    <Col sm={4} xs={4} className="float-left p-0 border-0 p-r-10">
                        <TextField
                            type={'text'}
                            value={props.endDate}
                            size={'sm'}
                            className={'p-10 cursor-not-allowed'}
                            readOnly={true}
                            isDisabled={true}
                        />
                    </Col>
                    <Col sm={1} xs={1} className="float-left p-0 border-0 mx-1">
                        <DateRangePicker
                            initialSettings={{
                                singleDatePicker: false,
                                showDropdowns: true,
                                startDate: props.startDate,
                                endDate: props.endDate,
                                maxYear: parseInt(moment().format('YYYY'), 10),
                                maxDate: moment(props.endDate),
                                maxSpan: props.maxSpan && { "days": props.maxSpan },
                                showISOWeekNumbers: true,
                                autoUpdateInput: true,
                                linkedCalendars: false,
                                opens: 'left',
                                autoApply: true, //for display footer section (apply and cancel)
                                showCustomRangeLabel : false
                            }}
                            onCallback={(event, picker) => props.onCallback(event, picker)}
                        >
                            <span className="btn btnStyling date-icon icon-width float-right border-1px m-0" style={{ 'paddingLeft' : '1.5vh' , 'paddingRight' : '1.5vh' }}><FontAwesomeIcon icon={faCalendarAlt}  /></span>
                        </DateRangePicker>
                    </Col>
                </Col>
            </Row>
        </Fragment>
    );
}

export default ReportPicker;
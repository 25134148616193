import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../hoc/button';
import { ProfileContext } from '../provider/profilesProvider';

class AddProfile extends Component {
    static contextType = ProfileContext;
    render() {
        return (
            // <React.Fragment>
            //     <div className="col-8 float-left mt-2">
            //         <label className="my-auto">{<FormattedMessage id='manage.profiles.profile' />}</label>
            //     </div>
            //     <div className="col-3 float-right p-0 m-0">
            //         <Button isDisabled={this.context.state.newProfile} handleButtonClick={this.context.createNewProfile} className={this.context.state.newProfile ? "new  disabled" : "new"} btnText={<FormattedMessage id='manage.profiles.new' />} />
            //     </div>
            // </React.Fragment>
            <React.Fragment>
                <label className="my-auto">{<FormattedMessage id='manage.profiles.profile' />}</label>
                <Button isDisabled={this.context.state.newProfile} handleButtonClick={this.context.createNewProfile} className={this.context.state.newProfile ? "new  disabled" : "new"} btnText={<FormattedMessage id='manage.profiles.new' />} />
            </React.Fragment>
        );
    }
}

export default AddProfile;
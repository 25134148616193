import React, { Component } from 'react';
import ClassAssignmentContainer from './containers/classAssignmentContainer';
import ClassAssignmentProvider from './provider/classAssignmentProvider';
import './index.css'

class ClassAssignment extends Component {
    render() {
        return (
            <ClassAssignmentProvider>
                <ClassAssignmentContainer />
            </ClassAssignmentProvider>
        );
    }
}
export default ClassAssignment;
export const formFields = [
    { key: 'secured', value: 'common', type: 'toggle',displayType: 'common',um1: false,isMandatory: false},
    { key: 'region', value: 'common', type: 'select', displayType: 'common', um1: true, isMandatory: true, options: [], isDisabled: false, isMove: true },
    { key: 'substation', value: 'common', type: 'select', displayType: 'common', um1: true, isMandatory: true, options: [], isDisabled: false, isMove: true },
    { key: 'controlMode', value: 'vc10', type: 'select', displayType: 'VC10', um1: false, isMandatory: false, options: [], isvc10:true },
    { key: 'feeder', value: 'common', type: 'select', displayType: 'UM3', um1: true, isMandatory: true, options: [], isDisabled: false, isMove: true },
    { key: 'lateral', value: 'ZM1', type: 'select', displayType: 'UM3', um1: true, isMandatory: false, options: [], isDisabled: false, isMove: true },
    { key: 'site', value: 'common', type: 'select', displayType: 'UM3', um1: true, isMandatory: true, options: [], isDisabled: false, isMove: true },
    { key: 'conductor1Phase', value: 'UM3', type: 'select', displayType: 'UM3', um1: false, isMandatory: false, options: [], isMove: true },
    { key: 'conductor2Phase', value: 'UM3', type: 'select', displayType: 'UM3', um1: false, isMandatory: false, options: [], isMove: true },
    { key: 'conductor3Phase', value: 'UM3', type: 'select', displayType: 'UM3', um1: false, isMandatory: false, options: [], isMove: true },
    { key: 'phase', value: 'common', type: 'select', displayType: false, um1: true, isMandatory: true, options: [], isMove: true },
    { key: 'serialNumber', value: 'common', type: 'input', displayType: 'common', um1: true, isMandatory: true },
    { key: 'deviceState', value: 'common', type: 'select', displayType: 'common', um1: true, isMandatory: false, options: [] },
    { key: 'description', value: 'common', type: 'textarea', displayType: 'common', um1: true, isMandatory: false, isMove: true },
    { key: 'fieldNotes', value: 'common', type: 'textarea', displayType: 'common', um1: true, isMandatory: false, isEdit: true,isUm1:true },
    { key: 'networkProvider', value: 'common', type: 'select', displayType: 'common', um1: true, isMandatory: true, options: [], isEdit: true ,isUm1:true},
    { key: 'sensorIPAddress', value: 'common', type: 'input', displayType: 'common', um1: false, isMandatory: true, isEdit: true },
    { key: 'softwareVersion', value: 'common', type: 'select', displayType: 'common', um1: false, isMandatory: true, options: [] },
    { key: 'externalID1', value: 'common', type: 'input', displayType: 'common', um1: true, isMandatory: false, isMove: true },
    { key: 'externalID2', value: 'common', type: 'input', displayType: 'common', um1: true, isMandatory: false, isMove: true },
    { key: 'externalID3', value: 'common', type: 'input', displayType: 'common', um1: true, isMandatory: false, isMove: true },
    { key: 'tier', value: 'UM1', type: 'select', displayType: 'UM1', um1: true, isMandatory: true, options: [], isvc10:false },
    { key: 'transformer', value: 'UM1', type: 'select', displayType: 'UM1', um1: true, isMandatory: false, options: [], isEdit: true ,isUm1:true, isvc10:true}
];
import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import {VirtualDeviceContext} from '../provider/virtualDeviceProvider';
import Tooltip from '../../../../hoc/tooltip';
import { makeSearchHighlight } from '../../../../filters/commonFunction';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';

export const VirtualDeviceNameColumn=(props)=>{
    const contextData = useContext(VirtualDeviceContext);
    const roleName = contextData.roleAndPermission.roleName;

    return (
        <Row> 
            <Col xs={1}>
            <Tooltip baseContent=
                {props.data.locked ? <FontAwesomeIcon icon={ faLockOpen } size="md"/> : <FontAwesomeIcon icon={ faLock} size="md"/>}
                message={props.data.locked ? <FormattedMessage id='vdm.tooltip.unlocked' /> : <FormattedMessage id='vdm.tooltip.locked' />}
                placement={'top'}
                />
                </Col>
            <Col className='m-0 text-left'> 
                {setEllipseView(props.data.name, contextData.state.deviceTableSearchValue, 'vdName',  props.data, roleName)}
            </Col>
        </Row>
    )
}

export const VirtualDeviceOtherColumn=(props)=>{
    const contextData = useContext(VirtualDeviceContext);
    return (
        <span dangerouslySetInnerHTML={{ __html: makeSearchHighlight(props.data ? props.data : '', contextData.state.deviceTableSearchValue ) }}/>
    )
}

export const VirtualDeviceDescription=(props)=>{
    const contextData = useContext(VirtualDeviceContext);
    return (<>{setEllipseView(props.data, contextData.state.deviceTableSearchValue, 'description')}</>)
}

export const setEllipseView=(value, searchValue, from, data, roleName)=>{
    const [isElips, setElips] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [isShowMore, setShowMore] = useState(false)
    const contextData = useContext(VirtualDeviceContext);

    const handleOnclick=(e)=>{
        e.preventDefault()
        setClicked(!clicked)
        setShowMore(!isShowMore)
    }
    return (
        <div style={{display: !isShowMore && 'flex'}}>
            <div ref={ref => {
                    if (!ref) return
                        let isOverflowing = 0;
                        if (!clicked) {
                            isOverflowing = ref.scrollWidth > ref.clientWidth
                            if (isOverflowing) setElips(true)
                            else setElips(false)
                        } else {
                            isOverflowing = ref.offsetHeight < 20
                            if (isOverflowing) {
                                setShowMore(false)
                                setClicked(false)
                            } else setShowMore(true)
                        }
                  }}
            onClick={(e) => (roleName === 'ROLE_ADMIN' || roleName === 'ROLE_ORG_ADMIN') && data.status === "ONLINE" && contextData.handleOnclickVirtualDevice(e, data, true)}
            style={{whiteSpace: !clicked ? 'nowrap': '', overflow: !clicked ? 'hidden': '' }}
            className={ from === 'vdName' && data.status === "ONLINE" && (roleName === 'ROLE_ADMIN' || roleName === 'ROLE_ORG_ADMIN') ? 'cursor-pointer active-color m-auto' : 'm-auto'}
            dangerouslySetInnerHTML={{ __html: makeSearchHighlight(value ? value : '', searchValue ) } }/> 
            {isElips && !isShowMore && <div className='cursor-pointer active-color ellips-view' onClick={(e)=> handleOnclick(e) }>...</div>}
            {isShowMore && <div className='cursor-pointer' onClick={(e)=> handleOnclick(e) }> <a href="javascript:void(0);"><FormattedMessage id='vdm.ellips.showMore' /></a></div> }
        </div>
    )
}
import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import MenufacturingFile from './menufacturingFile'
import FirmWareFile from '../containers/firmWareFile'
import GridMapingFile from '../containers/gridMapingFile';
import InventoryFile from '../containers/InventoryFile';
import CertificateFile from './certificateFile';
import { connect } from 'react-redux';

class LicenseManagementContainer extends Component {
    render() {
        return (
            <Container fluid>
                <Row noGutters={false}>
                    <FormattedMessage id='db.common.setting'/>&nbsp;:&nbsp;<FormattedMessage id='db.sysAdmin.title'/>
                </Row>
                <Row noGutters={false} className="margin-top-row">
                    <Col md={6}>
                    {this.props.oldMtfUploadViewData === 'false' ? <MenufacturingFile /> : <InventoryFile />}
                    </Col>
                    <Col md={6}>
                        <FirmWareFile />
                    </Col>
                </Row>
                <Row noGutters={false} className="margin-top-row">
                    <Col md={6}>
                    {this.props.oldMtfUploadViewData === 'false' ? <GridMapingFile /> : <CertificateFile /> }
                    </Col>
                </Row>
             </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        oldMtfUploadViewData: state.configurePropertiesData.oldMtfUploadView,
    };
}

export default connect(mapStateToProps, null)(LicenseManagementContainer);

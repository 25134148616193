import React from 'react';
import { FormattedMessage } from 'react-intl';

export const tableOptions = {
    pageNo: 1,
    pageSize: 25,
    pageSizeOptions: [25, 50, 100, 200],
    paginationSize: 4,
    firstPageText: 'First',
    prePageText: 'Prev',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Prev page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true
}

export const customReportConfig = {
    exportOptions: [{ label: <FormattedMessage id='common.text.download.excel' />, value: 'excel' }, { label: <FormattedMessage id='common.text.download.csv' />, value: 'csv' }]
}

export const scheduleFilterPopup = {
    filterForm: [
        { label: <FormattedMessage id={"customreports.schedule.name"} />, fName: 'name', type: 'textField' },
        { label: <FormattedMessage id={"customreports.schedule.users"} />, fName: 'users', type: 'multiSelect' },
        { label: <FormattedMessage id={"customreports.schedule.frequency"} />, fName: 'frequency', type: 'singleSelect' },
        { label: <FormattedMessage id={"customreports.schedule.startDate"} />, fName: 'startDate', type: 'date' },
        { label: <FormattedMessage id={"customreports.schedule.endDate"} />, fName: 'endDate', type: 'date' },
        { label: <FormattedMessage id={"customreports.schedule.emptyReports"} />, fName: 'emptyReports', type: 'checkBox' },
    ],
};

export const reportFrequency = [
    { text: "Daily", value: "Daily" },
    { text: "Weekly", value: "Weekly" },
    { text: "Monthly", value: "Monthly" },
];

export const convertReportFrequency = {
    "15 Minutes": "QUARTER_HOUR",
    "30 Minutes": "HALF_HOUR",
    "45 Minutes": "THREE_QUARTER_HOUR",
    "60 Minutes": "HOUR",
    "Daily": "DAILY",
    "Weekly": "WEEKLY",
    "Monthly": "MONTHLY",
    "Yearly": "YEARLY"
}
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import * as treeViewUtility from '../utility/treeViewUtility';
import { getWatchlistSubstationsAndFeeder, getWatchlistSubstations } from '../mainContent/dashboard/services/dashboardService';
import { getAlertRegionData, getAlertSubstations } from '../mainContent/emailSubscription/services/emailSubscriptionService';
import { getTreeData } from '../treeview/services/treeService';
import Loader from './loader';
import 'react-sortable-tree/style.css';
import BlankPage from './blankPage';
import { FormattedMessage } from 'react-intl';

class TreeViewWithCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: []
        };
        this.payload = { 'ROOTNODE': '', 'REGION': '', 'SUBSTATION': '' };
    }

    componentDidMount() {
        this.getNodeData();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.hasOwnProperty('needToPersistence')) { // force to reload
            if((this.props.needToPersistence !== '') && (this.props.needToPersistence !== nextProps.needToPersistence)) {
                this.getNodeData();
            }
        }
    }

    getNodeData = () => {
        if (this.props.from === 'dashboard') this.getWatchlistdata();
        else if (this.props.from === 'emailSubscription') this.getSubscribedRegionData();
        else this.getAllRegionData();
    }

    getWatchlistdata = () => {
        this.setState({ isLoding: true }, () => {
            getWatchlistSubstations({ 'ORGID': this.props.tree[0].id, apiType: "data" })
                .then(resData => {
                    if (resData && resData.length) this.setState({ treeData: this.updateResponseData(resData), isLoding: false },()=>{
                        this.updateCheckedData(this.state.treeData, this.props.checkedLableName, this.props.expandUpto )
                    })
                    else this.setState({ isLoding: false })
                }).catch((err) => this.setState({ isLoding: false }))
        })
    }

    getSubscribedRegionData = () => {
        this.setState({ isLoding: true }, () => {
            getAlertRegionData({ 'ORGID': this.props.tree[0].id, 'apiType': 'data' }, 'selectedregions')
                .then(resData => {
                    if (resData && resData.length) this.setState({ treeData: this.updateResponseData(resData), isLoding: false },()=>{
                        this.updateCheckedData(this.state.treeData, this.props.checkedLableName, this.props.expandUpto )
                    })
                    else {
                        this.setState({ isLoding: false })
                        this.props.passUpdatedData([])
                    }
                }).catch((err) => this.setState({ isLoding: false }))
        })
    }

    getAllRegionData = () => {
        this.setState({ isLoding: true, treeData: [] }, () => {
            getTreeData({ 'ROOTNODE': this.props.tree[0].id, 'apiType': 'ROOTNODE' }, 'nodes', '')
                .then(resData => {
                    if(resData && resData.status !== "FAIL"){
                        if(this.props.from === "reports/custom") resData.forEach(e => e.subscribedForWatchlist =true);
                        if (resData && resData.length) this.setState({ treeData: this.updateResponseData(resData), isLoding: false })
                    } else this.setState({ isLoding: false })
                }).catch((err) => this.setState({ isLoding: false }))
        })
    }

    updateTempId = (payloadData, item, key) => {
        let uniqKey = '';
        if (payloadData.REGION === "") uniqKey = item.name + key
        else if (payloadData.REGION !== "" && payloadData.SUBSTATION === "") uniqKey = payloadData.REGION + item.name + key
        else if (payloadData.REGION !== "" && payloadData.SUBSTATION !== "") uniqKey = payloadData.REGION + payloadData.SUBSTATION + item.name + key
        return uniqKey
    }

    updateResponseData = (resData) => {
        let data = resData.sort((a, b) => a.name.localeCompare(b.name));
        data.forEach((item, key) => {
            item.title = item.name;
            item.expanded = false;
            item.tempId = item.tempId = this.updateTempId(this.payload, item, key);
        })
        return data
    }

    generatePayload = (rowInfo) => {
        if (rowInfo.node.type === 'REGION') this.payload = { 'ROOTNODE': this.props.tree[0].id, 'REGION': rowInfo.node.title, 'SUBSTATION': '' ,'apiType' :rowInfo.node.type}
        else this.payload = { 'ROOTNODE': this.props.tree[0].id, 'REGION': rowInfo.parentNode.title, 'SUBSTATION': rowInfo.node.title ,'apiType' :rowInfo.node.type}
    }

    handleClickOnItem = (rowInfo, event) => {
        let checked = event.target.checked;
        this.setState({ isLoding: true }, () => {
            if (!rowInfo.node.children && rowInfo.node.type !== this.props.expandUpto) {
                this.generatePayload(rowInfo);
                if (this.props.from === 'dashboard') {
                    getWatchlistSubstationsAndFeeder(this.payload, rowInfo.node.type)
                    .then(resData => {
                        let data = resData && resData.length ? this.updateResponseData(resData) : []
                        this.showOrHideChildrenData(rowInfo, checked, data);
                    })
                } else if (this.props.from === 'emailSubscription') {
                    getAlertSubstations(this.payload, rowInfo.node.type)
                        .then(resData => {
                            let data = resData && resData.length ? this.updateResponseData(resData) : []
                            this.showOrHideChildrenData(rowInfo, checked, data);
                        })
                } else {
                    getTreeData(this.payload, 'nodes', rowInfo.node.type)
                    .then(resData => {
                        if(this.props.from === "reports/custom") resData.forEach(e => e.subscribedForWatchlist =true);
                        let data = resData && resData.length ? this.updateResponseData(resData) : []
                        this.showOrHideChildrenData(rowInfo, checked, data);
                    })
                }    
            } else {
                this.showOrHideChildrenData(rowInfo, checked);
            }
        })
    }

    showOrHideChildrenData = (rowInfo, checked, resData) => {

        let checkedValue = checked;
        let prevData = this.state.treeData;
        if (rowInfo.node.type === "REGION") {
            prevData.forEach((item, regionKey, regionArr) => {
                if (item.tempId === rowInfo.node.tempId) {
                    if (resData !== undefined) item.children = resData; // if data coming from response then assign it to children
                    item.expanded = checked !== undefined ? true : !item.expanded; // clicked from checkbox always axpand true or clicked from icon do it true/false
                    if (checkedValue !== undefined) { // if checked true of false
                        this.updateIntermediateSubscribedWatchlist(rowInfo.node.type, checkedValue, item, 'items', 'feeder', regionArr, 'subStationArr', 'feederArr');
                    }
                    if (item.children && checkedValue !== undefined) { // if substation available
                        item.children.forEach(items => {
                            if (checkedValue !== undefined) {
                                items.selectionIntermediate = false;
                                items[this.props.checkedLableName] = checkedValue;
                                items.checked = checkedValue;
                            } else {
                                items.selectionIntermediate = item.checked ? false : items.selectionIntermediate;
                                items[this.props.checkedLableName] = item.checked ? true : items[this.props.checkedLableName];
                            }
                            if (items.children) { // if Feeder available
                                items.children.forEach(feeder => {
                                    if (checkedValue !== undefined) {
                                        feeder.selectionIntermediate = false;
                                        feeder[this.props.checkedLableName] = items.checked ? items.checked : false;
                                        feeder.checked = checkedValue;
                                    } else {
                                        feeder.selectionIntermediate = false;
                                        feeder[this.props.checkedLableName] = items.checked ? true : feeder[this.props.checkedLableName];
                                    }
                                })
                            }
                        })
                    }
                }
            })
        } else if (rowInfo.node.type === "SUBSTATION") {
            prevData.forEach((item, regionKey, regionArr) => {
                if (item.children) {
                    item.children.forEach((items, substationKey, subStationArr) => {
                        if (items.tempId === rowInfo.node.tempId) {
                            if (resData !== undefined) items.children = resData
                            items.expanded = checked !== undefined ? true : !items.expanded;
                            if (checkedValue !== undefined) { // if checked true or false
                                this.updateIntermediateSubscribedWatchlist(rowInfo.node.type, checkedValue, item, items, 'feeder', regionArr, subStationArr, 'feederArr')
                            }
                            if (items.children) {
                                items.children.forEach((feeder, feederKey, feederArr) => {
                                    if (checkedValue !== undefined) { // if checked true or false
                                        feeder.selectionIntermediate = false;
                                        feeder[this.props.checkedLableName] = items.checked ? items.checked : false;
                                        feeder.checked = checkedValue;
                                    } else {
                                        feeder.selectionIntermediate = false;
                                        feeder[this.props.checkedLableName] = items.checked !== undefined ? items.checked : feeder[this.props.checkedLableName];
                                        feeder.checked = items.checked !== undefined ? items.checked : feeder[this.props.checkedLableName];
                                    }
                                })
                            }
                        }
                    })
                }
            })
        } else if (rowInfo.node.type === "FEEDER") {
            prevData.forEach((item, regionKey, regionArr) => {
                if (item.children) {
                    item.children.forEach((items, subStationkey, subStationArr) => {
                        if (items.children && checkedValue !== undefined) {
                            items.children.forEach((feeder, feederKey, feederArr) => {
                                if (feeder.tempId === rowInfo.node.tempId) {
                                    if (checkedValue !== undefined) { // if checked true or false
                                        this.updateIntermediateSubscribedWatchlist(rowInfo.node.type, checkedValue, item, items, feeder, regionArr, subStationArr, feederArr)
                                    }
                                }
                            })
                        }
                    })
                }
            })
        }
        this.setState({ treeData: prevData, isLoding: false });
        if (checkedValue !== undefined) this.updateCheckedData(prevData, this.props.checkedLableName, this.props.expandUpto )
    }

    updateIntermediateSubscribedWatchlist = (type, checkedValue, region, substation, feeder, regionArr, subStationArr, feederArr) => {

        if (type === 'REGION') {
            region.selectionIntermediate = false;
            region[this.props.checkedLableName] = checkedValue;
            region.checked = checkedValue;
        } else if (type === 'SUBSTATION') {
            substation.checked = checkedValue;
            substation.selectionIntermediate = false;
            substation[this.props.checkedLableName] = checkedValue;
            region.selectionIntermediate = this.checkinterMediateAndWatchlist(subStationArr).selectionIntermediate;
            region[this.props.checkedLableName] = this.checkinterMediateAndWatchlist(subStationArr)[this.props.checkedLableName];
            region.checked = this.checkinterMediateAndWatchlist(subStationArr)[this.props.checkedLableName];

        } else if (type === 'FEEDER') {
            feeder.checked = checkedValue;
            feeder.selectionIntermediate = false;
            feeder[this.props.checkedLableName] = checkedValue;
            substation.selectionIntermediate = this.checkinterMediateAndWatchlist(feederArr).selectionIntermediate;
            substation[this.props.checkedLableName] = this.checkinterMediateAndWatchlist(feederArr)[this.props.checkedLableName];
            region.selectionIntermediate = this.checkinterMediateAndWatchlist(subStationArr).selectionIntermediate;
            region[this.props.checkedLableName] = this.checkinterMediateAndWatchlist(subStationArr)[this.props.checkedLableName];

            substation.checked = this.checkinterMediateAndWatchlist(feederArr)[this.props.checkedLableName];
            region.checked = this.checkinterMediateAndWatchlist(subStationArr)[this.props.checkedLableName];
        }
    }

    checkinterMediateAndWatchlist = (data) => {

        let checkifAllAnyTrue = data.filter(item => item.selectionIntermediate === true);
        let checkifAllSubscTrue = data.filter(item => item.selectionIntermediate === false && item[this.props.checkedLableName] === true)
        let intermediate = {};
        if (checkifAllAnyTrue.length > 0) {
            intermediate.selectionIntermediate = true;
            intermediate[this.props.checkedLableName] = false;
        }
        if (checkifAllSubscTrue.length === data.length) {
            intermediate.selectionIntermediate = false;
            intermediate[this.props.checkedLableName] = true;
        }
        if (checkifAllSubscTrue.length < data.length) {
            intermediate.selectionIntermediate = true;
            intermediate[this.props.checkedLableName] = false;
        }
        if (checkifAllAnyTrue.length === 0 && checkifAllSubscTrue.length === 0) {
            intermediate.selectionIntermediate = false;
            intermediate[this.props.checkedLableName] = false;
        }
        return intermediate
    }

    indetSetter = (checked, el) => {
        if (el) el.indeterminate = checked
    }

    updateCheckedData = (allData, checkedLableName, expandUpto ) => {
        var data = [];
        allData.forEach(function (obj) {
            var substations = [];
            var children = obj.hasOwnProperty("children") ? obj.children : [];
            if (children.length > 0) {
                children.forEach(function (subobj) {
                    var feeders = [];
                    var feederChildren = subobj.hasOwnProperty("children") ? subobj.children : [];
                    if (feederChildren.length > 0) {
                        feederChildren.forEach(function (feedobj) {
                            feeders.push({
                                "feederName": feedobj.name,
                                "feederSelected": feedobj[checkedLableName] === true ? true : false,
                                "id": feedobj.id
                            });
                        });
                    }
                    if (expandUpto === 'FEEDER') {
                        substations.push({
                            "substationName": subobj.name,
                            "substationSelected": subobj.checked === true || subobj.selectionIntermediate === true || (subobj.selectionIntermediate === false && subobj[checkedLableName] === true) ? true : false,
                            "id": subobj.id,
                            "feeders": feeders
                        })
                    } else {
                        substations.push({
                            "substationName": subobj.name,
                            "substationSelected": subobj.checked === true || subobj.selectionIntermediate === true || (subobj.selectionIntermediate === false && subobj[checkedLableName] === true) ? true : false,
                            "id": subobj.id
                        })
                    }
                });
            }
            data.push({
                "regionName": obj.name,
                "regionSelected": obj.checked === true || obj.selectionIntermediate === true || (obj.selectionIntermediate === false && obj[checkedLableName] === true) ? true : false,
                "substations": substations
            });
        });
        this.props.passUpdatedData(data)
    }

    render() {
        const { expandUpto, checkedLableName, checkBoxVisible, checkBoxDisable } = this.props;
        return (
            <div className="checkbox-tree">
                { this.state.isLoding ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                {
                    this.state.treeData && this.state.treeData.length > 0 ?
                    <SortableTree
                        treeData={this.state.treeData.map(item => item)}
                        onChange={treeData => this.setState({ treeData })}
                        theme={FileExplorerTheme}
                        canDrag={false}
                        generateNodeProps={clickedNode => ({
                            onClick: (event) => this.handleClickOnItem(clickedNode, event),
                            icons: [
                                <div className="container-fluid tree-with-checkbox">
                                    <span className="watchListItemPlusMinus" >
                                        {clickedNode.node.type !== expandUpto ? <FontAwesomeIcon className="cursor-pointer" icon={clickedNode.node.expanded ? faMinus : faPlus} size="sm" /> : ""}
                                    </span>
                                    <span className="watchListItemIcon"> {treeViewUtility.getIcon(clickedNode)}</span>
                                    {
                                        ((checkBoxVisible === 'all') || (checkBoxVisible === clickedNode.node.type)) ?
                                        <span className="checkboxList">
                                            <input type="checkbox"
                                                checked={(clickedNode.node[checkedLableName] === true && clickedNode.node.selectionIntermediate === false) || (clickedNode.node[checkedLableName] === false && clickedNode.node.selectionIntermediate === true) ? true : false}
                                                disabled={checkBoxDisable && !((clickedNode.node[checkedLableName] === true && clickedNode.node.selectionIntermediate === false) || (clickedNode.node[checkedLableName] === false && clickedNode.node.selectionIntermediate === true))}
                                                onClick={e => e.stopPropagation()}
                                                ref={this.indetSetter.bind(this, clickedNode.node.selectionIntermediate)}
                                                onChange={(event) => this.handleClickOnItem(clickedNode, event)}
                                            />
                                        </span> : null
                                    }
                                </div>
                            ],
                        })}
                    /> : !this.state.isLoding && <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tree: state.treeviewData.tree,
    };
}

export default connect(mapStateToProps, {})(TreeViewWithCheckbox);
import React from 'react';
import HighchartsReact from 'highcharts-react-official'
import HighCharts from "highcharts/highcharts";
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

const SiteLineComponent = (props) => {
    const { formatMessage } = props.intl;
    return (
        <React.Fragment>
            <div className="container-fluid text-center">
                <span className="header-title">
                    <FormattedMessage id='db.modal.loadUnbalance' />&nbsp; - {props.options.siteName}
                </span><br />
            </div>
            <HighchartsReact
                highcharts={HighCharts}
                // options = { props.options} //without format message for all options data
                options={{ //with format message
                    ...props.options,
                    ...props.options.series.forEach(item => {
                        item.tooltip.valueSuffix = " " + formatMessage({ id: "common.label.amperes" });
                    }),
                    ...props.options.series[0].name = formatMessage({ id: "db.lineChart.unbalance" }),
                    ...props.options.series[1].name = formatMessage({ id: "db.feederBar.phaseA" }),
                    ...props.options.series[2].name = formatMessage({ id: "db.feederBar.phaseB" }),
                    ...props.options.series[3].name = formatMessage({ id: "db.feederBar.phaseC" }),
                    ...props.options.yAxis[0].title.text = formatMessage({ id: "db.bubbleChart.peakLoadA" }),
                    ...props.options.yAxis[1].title.text = formatMessage({ id: "db.lineChart.unbalanceA" }),
                }}
            />
        </React.Fragment>
    )
}

export default injectIntl(SiteLineComponent);
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';;
import { RegisterContext } from '../provider/registerProvider';
import TimeFilter from '../../../../hoc/timeFilter';
import CustomFilter from './customFilter';
import EventsBreadcrumbs from '../../../../hoc/eventsBreadcrumbs';

class RegisterHeader extends Component {
    static contextType = RegisterContext;
    render() {
        const { zoomRange, selectedZoomRange } = this.context.state;
        return (
            <React.Fragment>
                <Col xs={12} md={12} className="events-details">
                    <Row className="register-header">
                        <Col xs={9} md={9}>
                            <EventsBreadcrumbs updatedTreeData={this.context.state.updatedTreeData} nodeData={this.context.state.treeNode} handleOnClick={this.context.loadData} />
                        </Col>
                        <Col xs={3} md={3} className="text-right">
                            <TimeFilter data={zoomRange} handleOnClick={this.context.zoomOnChange} selected={selectedZoomRange} />
                            <span className="event-filter"><CustomFilter /></span>
                        </Col>
                    </Row>
                </Col>
            </React.Fragment>
        );
    }
}

export default RegisterHeader;
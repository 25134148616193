import React from 'react';
import SiteLineComponent from '../components/siteLineChartComponent';

class SiteChart extends React.Component{
    render (){
        return(
            <React.Fragment>
                <SiteLineComponent options = {this.props.optionss} />
            </React.Fragment>
        )
    }
}

export default SiteChart ;
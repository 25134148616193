import { getURL } from '../../../providers/configProvider';
import { requestGET, requestPOSTtreeView } from '../../../services/extendedService';

export const getNodeData = (params, reqBody) => {
  const url = getURL('monitor', 'getMonitor', params);
  return requestPOSTtreeView(url, reqBody)
}

export const exportData = (params) => {
  const url = getURL('monitor', 'exportData', params);
  const headers = {'Content-Type': 'blob'};
  const config= {method: 'GET', url: url, responseType: 'arraybuffer', headers};
  return requestGET(url,config);
}

export const getPrimaryCurrVoltage = (params, reqBody) => {
  const url = getURL('monitor', 'getCurrentVoltage', params);
  return requestGET(url, reqBody)
}

export const getTransformerLoad = (params, reqBody) => {
  const url = getURL('monitor', 'getTransformerLoad', params);
  return requestGET(url, reqBody)
}
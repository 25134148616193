import { getURL } from '../../../providers/configProvider';
import { requestGET, requestPOST } from '../../../services/extendedService';


export const loadNotificationCategory = () => {
    const url = getURL('notificationtemplate', 'loadcategories', {'apiType':'data'});
    return requestGET(url);
};

export const loadNotificationTemplates = (category, optionsData) => {
    const url = getURL('notificationtemplate', 'loadtemplates', {'apiType':'data', 'CATEGORY':category});
    return requestGET(url, {}, optionsData);
};

export const deleteNotificationTemplates = (params, optionsData) => {
    const url = getURL('notificationtemplate', 'deleteNotificationTemplates', {'apiType':'data'});
    return requestPOST(url, params, {}, optionsData);
};

export const storeNotificationTemplates = (params, optionsData) => {
    const url = getURL('notificationtemplate', 'storetemplate', {'apiType':'data'});
    return requestPOST(url, params, {}, optionsData);
};
import React from 'react';

const BreadCrumb = ({ data, isClickable, handleOnClick, separatorSymble }) => {
    return <span className="breadcrumb-data">
        {data.map((item, key, arr) => {
            const { name } = item;
            return <span key={key} onClick={()=> isClickable ? arr.length >= 2 ? key === (arr.length - 1) ? "" : handleOnClick(key) : "" : undefined } >
                <span className={isClickable ? arr.length >= 2 ? key === (arr.length - 1) ? "" : "hover-underline" : "" : ""}>{name}</span>
                <span>{key === (arr.length - 1) ? "" : separatorSymble}</span>
            </span>
        })}
    </span>
}

export default BreadCrumb;
import React, { createContext } from 'react';
import { getAuditTrailData } from '../services/auditTrailService';
import { dateConvertion } from '../../../services/utilityService';
import { auditTrailData } from '../../../utility/auditTrailUtility';
import { connect } from 'react-redux';

export const AuditTrailContext = createContext();

class AuditTrailProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                startTimestamp: auditTrailData.selectedDataByUser.startTimestamp === "" ? auditTrailData.selectedDataByUser.endTimestamp - ((7 * 1000 * 3600 * 24) - 1) : auditTrailData.selectedDataByUser.startTimestamp,
                endTimestamp: auditTrailData.selectedDataByUser.endTimestamp,
                actionTypes: auditTrailData.selectedDataByUser.actionTypes,
                userIds: auditTrailData.selectedDataByUser.userIds,
                ipAddress: "",
                serialNumber: "",
            },
            userIds: [],
            actionTypes: [],
            tableData: [],
            PAGESIZE: auditTrailData.pageSize,
            pageNo: auditTrailData.pageNo,
            isLoadingData: false,
        }
    }
    componentDidMount() {
        this.getAuditTrailDataDetails(this.state.formData)
    }
    updateReqBody = (data) => {
        let startTimestamp = auditTrailData.selectedDataByUser.startTimestamp !== "" ? dateConvertion(auditTrailData.selectedDataByUser.startTimestamp, 'endDate', this.props.roleAndPermission.timezone) : data.startTimestamp;
        let endTimestamp = auditTrailData.selectedDataByUser.endTimestamp !== "" ? dateConvertion(auditTrailData.selectedDataByUser.endTimestamp, 'endDate', this.props.roleAndPermission.timezone) : data.endTimestamp;
        let userIds = this.getUserIdAndActionType(auditTrailData.selectedDataByUser.userIds.length !== 0 ? auditTrailData.selectedDataByUser.userIds : data.userIds);
        let actionTypes = this.getUserIdAndActionType(auditTrailData.selectedDataByUser.actionTypes.length !== 0 ? auditTrailData.selectedDataByUser.actionTypes : data.actionTypes);
        let finalData = { ...data, startTimestamp: new Date(startTimestamp).setHours(0, 0, 0, 0), endTimestamp: new Date(endTimestamp).setHours(23, 59, 59, 999), userIds: userIds, actionTypes: actionTypes }
        return finalData
    }

    getAuditTrailDataDetails = () => {
        let reqBody = this.updateReqBody(this.state.formData)
        let dataParams = { 'ORGID': this.props.tree[0].id, 'PAGENO': this.state.pageNo, 'PAGESIZE': this.state.PAGESIZE, 'apiType': 'data' }
        this.setState({ isLoadingData: true }, () => {
            getAuditTrailData(dataParams, reqBody)
                .then(res => {
                    let data = res.status !== 'FAIL' ? res : []
                    this.updateData(data)
                }).catch(error => this.setState({ isLoadingData: false }))
        })
    }

    updateData = (resData) => {
        let userIdsArr = [], actionTypesArr = [];
        let auditTrailsData = resData.auditTrails.length !== 0 || resData.auditTrails !== undefined ? resData.auditTrails : [];
        resData.filters.userIds.map(((item, key) => userIdsArr.push({ name: 'userIds', text: item, value: item, keyIndex: key })))
        resData.filters.actionTypes.map(((item, key) => actionTypesArr.push({ name: 'actionTypes', text: item, value: item, keyIndex: key })))
        this.setState({ userIds: userIdsArr, actionTypes: actionTypesArr, tableData: auditTrailsData, totalRowCount: resData.totalCount, isLoadingData: false })
    }

    onChangeHandler = (selectedList, field) => {
        this.setState({ formData: { ...this.state.formData, [field]: selectedList } })
    }

    handleShowAllBtn = (fieldName) => {
        auditTrailData.selectedDataByUser = { ...auditTrailData.selectedDataByUser, [fieldName]: [] }
        this.setState({ formData: { ...this.state.formData, [fieldName]: [] } })
    }

    updateCallenderValue = (field, start, end) => {
        this.setState({ formData: { ...this.state.formData, [field]: start } })
    }

    onPageSizeOptionChange = (pageSize) => {
        auditTrailData.pageSize = pageSize;
        auditTrailData.pageNo = 1;
        this.setState({ PAGESIZE: pageSize, pageNo : 1 }, () => {
            this.getAuditTrailDataDetails();
            //window.scrollTo(0, 0); // for lazy loading, onchange of page size go to top of the page
        })
    }

    onPageChangeHandler = (pageNo) => {
        auditTrailData.pageNo = pageNo + 1;
        this.setState({ pageNo: pageNo + 1 }, () => {
            this.getAuditTrailDataDetails()
            //window.scrollTo(0, 0); // for lazy loading, onchange of page number (next, prv, first and last) go to top of the page
        })
    }

    getUserIdAndActionType = (data) => data.map(item => item.value)

    backupApplyData = (data) => {
        auditTrailData.selectedDataByUser = { startTimestamp: data.startTimestamp, endTimestamp: data.endTimestamp, userIds: data.userIds, actionTypes: data.actionTypes }
    }

    handleClickApplyBtn = () => {
        let data = this.state.formData;
        this.backupApplyData(data)
        this.updateReqBody(this.state.formData)
        auditTrailData.pageNo = 1;
        this.setState({ pageNo: 1, tableData: [], formData: {...data}   }, () => this.getAuditTrailDataDetails())
    }

    render() {
        return (
            <AuditTrailContext.Provider value={{
                state: this.state,
                onChangeHandler: this.onChangeHandler,
                handleShowAllBtn: this.handleShowAllBtn,
                updateCallenderValue: this.updateCallenderValue,
                onPageSizeOptionChange: this.onPageSizeOptionChange,
                onPageChangeHandler: this.onPageChangeHandler,
                getUserIdAndActionType: this.getUserIdAndActionType,
                handleClickApplyBtn: this.handleClickApplyBtn,
            }}>
                {this.props.children}
            </AuditTrailContext.Provider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission,
        tree: state.treeviewData.tree
    }
}

export default connect(mapStateToProps, {})(AuditTrailProvider);
import React from 'react';
import { Form } from 'react-bootstrap';
const Range = (props) => {
    return (
        <Form>
        {/* <Form.Group controlId={`formBasicRangeCustom-${props.keyVal}`}> */}
        <Form.Group>
          <label className="float-left m-0">{props.min}</label> 
          <label className="float-right m-0">{props.max}</label> 
          <Form.Control type="range" value={props.value} min={props.min} max ={props.max} custom onChange={(e) => props.handleOnchange(e.target.value)}/>
        </Form.Group>
      </Form>
       
    )
}
export default Range;
import React, { Component, Fragment } from 'react';
import { FormControl, FormGroup, Col, Form } from 'react-bootstrap';
import { formFields } from '../../../../utility/addDeviceUtility';
import { FormattedMessage } from 'react-intl';
import { updateEditDeviceData } from '../services/deviceService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { DeviceContext } from '../provider/deviceProvider';
import SingleSelect from '../../../../hoc/singleSelect';
import ModalWindow from '../../../../hoc/modelWindow';
import Loader from '../../../../hoc/loader';
import ampleStore from '../../../../store';
import { showAlertToaster } from '../../../../login/actions/loginActionDispatch';
import AlertMessage from '../../../../hoc/alert';

class EditDeviceComponent extends Component {
    static contextType = DeviceContext;
    state = {
        selectedValues: {},
        editDeviceFields: [],
        isDataModified: false,
        errorMessage: ""
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isModalOpen && !prevState.isDataModified) {
            let modifiedData = {};
            const editDeviceFields = formFields.filter(item => item.isEdit && ((nextProps.rowData.deviceType === "UM1" || nextProps.rowData.deviceType === "VC10") ? (item.isUm1) : item.displayType === "common"))
            const reOrder = editDeviceFields.splice(0, 1);
            editDeviceFields.push(reOrder[0]);
            editDeviceFields.forEach(field => {
                if (field.key === "sensorIPAddress") {
                    modifiedData[field.key] = nextProps.rowData.ipAddress;
                } else {
                    modifiedData[field.key] = nextProps.rowData[field.key];
                }
                field.key === "networkProvider" && (nextProps.rowData.deviceType === "UM1" || nextProps.rowData.deviceType === "VC10" || nextProps.rowData.mcpDevice) ? field.isDisabled = true : field.isDisabled = false;
            })
            nextProps.rowData.deviceType === "UM3+" && nextProps.rowData.hardwareVersion === "CU" && editDeviceFields.splice(editDeviceFields.findIndex(e=>e.key==="sensorIPAddress"),1); //code for removing Sensor IP Address for UM3+ compact device
            return {
                selectedValues: modifiedData,
                editDeviceFields: editDeviceFields,
            }
        }
        return null
    }

    inputChangeHandler = (e, key) => {
        this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { [key]: e !== "--select--" && (typeof (e) === "string" || typeof (e) === "number") ? e : e.target && e.target.value ? e.target.value : "" }), isDataModified: true })
    }

    updateData = async () => {
        const updateAPIData = {
            region: this.props.rowData.region,
            substation: this.props.rowData.substation,
            feeder: this.props.rowData.feeder,
            site: null,
            lateral: this.props.rowData.lateral,
            lateralSite: null,
            phase: this.props.rowData.phase,
            conductor1Phase: this.props.rowData.conductor1Phase,
            conductor2Phase: this.props.rowData.conductor2Phase,
            conductor3Phase: this.props.rowData.conductor2Phase,
            deviceState: this.props.rowData.deviceState,
            description: this.props.rowData.description,
            externalID1: this.props.rowData.externalID1,
            externalID2: this.props.rowData.externalID2,
            externalID3: this.props.rowData.externalID3,
            networkProvider: this.state.selectedValues.networkProvider,
            sensorIPAddress: (this.props.rowData.deviceType !== 'UM1' && this.props.rowData.deviceType !== 'VC10') ? this.state.selectedValues.sensorIPAddress && this.state.selectedValues.sensorIPAddress.trim() : this.props.rowData.ipAddress,
            daPort: '',
            fieldNotes: this.state.selectedValues.fieldNotes,
            deviceType: this.props.rowData.deviceType,
            serialNumber: this.props.rowData.serialNumber,
            secured: this.props.rowData.ngsecure, //getting default value 'false' for UM1 device type, need to send that default value, other device need to send true/false.
            mcpDevice:this.props.rowData.mcpDevice,
            hardwareRevision:this.props.rowData.deviceType === "UM3+" ? this.props.rowData.hardwareVersion : null
        }
        if (updateAPIData.lateral !== null && updateAPIData.lateral !== "") {
            updateAPIData.lateralSite = this.props.rowData.site;
        } else {
            updateAPIData.site = this.props.rowData.site;
        }
        if(updateAPIData.deviceType==="UM1" || updateAPIData.deviceType==="VC10"){
            updateAPIData.transformer=this.state.selectedValues.transformer
        }
        const data = await updateEditDeviceData(this.props.orgId, this.props.rowData.id, updateAPIData);
        if (data.status !== "FAIL") {
            this.setState({ selectedValues: {} });
            this.props.onCloseModal()
            if (Object.keys(this.props.selectedNode).length > 0) {
                await this.context.loadNextlevel({ "node": this.props.selectedNode }, this.props.selectedNode.routeParams ? this.props.selectedNode.routeParams : null, null, this.props.selectedNode.name);
                await this.context.getDeviceList()
            }
            window.scrollTo(0,0);
            ampleStore.dispatch(showAlertToaster(<div><strong>{this.props.rowData.serialNumber} </strong> <FormattedMessage id='groupmanagement.success.editdevice'/></div>,'success'))
        }
        else {
            this.setState({ errorMessage: data.message })
        }
    }

    hideErrorMsg = () => {
        this.setState({ errorMessage: "" });
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => btnName === "update" ? this.updateData() : null;

    render() {
        const modalTitle = <div className="popup-title"><FormattedMessage id={`editDevice.popup.title`} />:<span className="text-bolder"> {`${this.props.serialNumber}`}</span> </div>
        const modalBody = <div className="min-vh-25 single-select-dropdown">
            {this.props.loader ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                <Form>
                    {this.state.errorMessage && <><AlertMessage type={'danger'} message={this.state.errorMessage} timer={10000} setVisible={() => this.hideErrorMsg()} position={'w-100'} /><br /></>}
                    <div>
                        {this.state.editDeviceFields.map((field, i) => {
                            let label, template;
                            label = <FormattedMessage id={`groupmanagement.label.${field.key}`} />;
                            if (field.type === "select") {
                                // !field.isDisabled condition is used to remove disabled network provider field and also it will not show any dropdown fields if it is disabled
                                template = !field.isDisabled && <Fragment>
                                    <SingleSelect disabled={field.isDisabled} data={field.options} setType={(e) => this.inputChangeHandler(e, field.key)} 
                                    id = {this.state.selectedValues[field.key]}
                                    value={this.state.selectedValues[field.key] ? field.key==="transformer" ? 
                                    field.options.length > 0 ? 
                                    field.options.find(i => i.value === this.state.selectedValues[field.key])?.label || "--select--" :
                                    "--select--" : 
                                    this.state.selectedValues[field.key] : "--select--"} icon='ok'
                                    />
                                    <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                </Fragment>
                            }
                            else if (field.type === "input") {
                                template = <FormControl id={field.key + "_" + i} type="text" label="Text" defaultValue={this.state.selectedValues[field.key]} className="inpFlSz" onBlur={(e) => this.inputChangeHandler(e, field.key)} />
                            }
                            else if (field.type === "textarea") {
                                template = <textarea rows="3" id={field.key + "_" + i} className="inpFlSz form-control"  value={this.state.selectedValues[field.key]} onChange={(e) => this.inputChangeHandler(e, field.key)} onBlur={(e) => this.inputChangeHandler(e, field.key)} />
                            }
                            let data = label && template ?
                                <FormGroup key={field.key} className="clearfix">
                                    <Col sm={4} xs={4} className={field.isDisabled ? 'float-left mb-2 m-0 p-0 disabled' : "float-left mb-2 m-0 p-0"}>{label}{field.isMandatory ? "*" : ""}</Col>
                                    <Col sm={8} xs={8} className={field.isDisabled ? "float-left mb-2 disabled" : "float-left mb-2"}>{template}</Col>
                                </FormGroup> : ""
                            return (data)
                        })}
                    </div>
                </Form>
        </div>
        const modalFooter = [{ className: "modalSaveBtn", name: <FormattedMessage id={`editDevice.popup.button.update`} />, value: "update" }];
        return (
            <Fragment>
                {this.props.isModalOpen ?
                    <ModalWindow
                        show={this.props.isModalOpen}
                        onHide={() => this.props.onCloseModal()}
                        size={"md"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        )
    }
}
export default EditDeviceComponent;
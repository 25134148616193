import React, { Component } from 'react';
import { menuItems } from '../../utility/contextMenuUtility';

class ContextMenuComponent extends Component {

    updateContextMenuItems = () => {
        menuItems.forEach(menu => {
            if (menu.key !== "details") {
                menu.isDisabled = true;
            }
        });
    }

    render() {
        let menus = null;
        if (Object.keys(this.props.roleAndPermission).length > 0 && (this.props.roleAndPermission.roleId === 3 || this.props.roleAndPermission.roleId === 4)) {
            this.updateContextMenuItems();
        }

        switch (this.props.selectedNode.type) {
            case "ROOTNODE":
            case "REGION":
            case "SUBSTATION":
            case "FEEDER":
            case "LATERAL":
                menus = menuItems.filter(item => item.value === this.props.selectedNode.type.toLowerCase() || item.value === "common" || (item.value === "selected" && item.displayType.includes(this.props.selectedNode.type.toLowerCase())) ? item : "");
                break;
            case "SITE":
            case "LATERAL_SITE":
                menus = menuItems.filter(item => item.value === "common" || (item.value === "selected" && item.displayType.includes(this.props.selectedNode.type.toLowerCase())) ? item : "");
                break;
            default:
                break;
        }

        return (
            <div className="contextMenu" style={{ top: this.props.contextPos + "px", left: this.props.contextPosX + "px" }}>
                <ul>
                    {
                        menus && menus.map((item, i) => {
                            return <li key={i} className={item.isDisabled ? "disabled" : ""} onMouseDown={!item.isDisabled ? (event) => this.props.clicked(event, item.key) : undefined}> {item.displayText} </li>
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default ContextMenuComponent;
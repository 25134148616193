
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Highcharts from "highcharts/highstock";
import HighChartss from "highcharts/highcharts";
import TableMultiSortNumber from '../hoc/tableMultiSortNumber'

const style = {
    fontFamily: "'univers', Arial, sans-serif",
    fontSize: "1.3vh",
    color: "#333333"
};

function intlFormater(value) {
    return <FormattedMessage id={`db.${value}`} />
}

export const dashboardData = {
    rootData: [{ name: intlFormater('common.rootName'), type: "ROOTNODE" }],
    timeFilterData: [{ label: intlFormater('common.day'), value: 1 }, { label: intlFormater('common.week'), value: 7, }, { label: intlFormater('common.month'), value: 31 }],
    typeFilterData: [{ label: intlFormater('type.faultMomentaryInterruption'), value: 'Fault Momentary Interruption',isDisabled:false }, { label: intlFormater('type.disturbance'), value: 'Disturbance',isDisabled:false }],
    faultTypeFilterData: [{ label: intlFormater('faultType.sustainedInterruption'), value: 'Sustained Interruption' }, { label: intlFormater('faultType.momentaryInterruption'), value: 'Momentary Interruption' }, { label: intlFormater('faultType.faultWithoutInterruption'), value: "Fault Without Interruption" }, { label: intlFormater('faultType.interruptionWithoutFault'), value: "Interruption Without Fault" }, { label: intlFormater('type.all'), value: "all" }],
    faultStateFilterData: [{ label: intlFormater('faultState.active'), value: "Active" }, { label: intlFormater('faultState.cleared'), value: "Cleared" }, { label: intlFormater('type.all'), value: "All" }],

    columnsData : [
        { accessor: 'region', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='dm.column.regionName' />} /> },
        { accessor: 'substation', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='dm.column.substationName' />} /> },
        { accessor: 'feeder', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='dm.column.feederName' />} /> },
        { accessor: 'sustainedCount', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.table.fullName.si' /> } />, className: 'clm-style', minWidth:150 },
        { accessor: 'momentaryCount', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.table.fullName.mi' />} />, className: 'clm-style',  minWidth:150 },
        { accessor: 'disturbanceSum', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.table.fullName.dist' />} />, className: 'clm-style' },
        { accessor: 'maxLoad', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.table.fullName.pl' />} />, className: 'clm-style' },
        { accessor: 'maxUnbalance', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.table.fullName.pu' /> }/>, className: 'clm-style' },
      ]
}

export const bubbleChartData = {
    chart: {
        type: 'bubble',
        plotBorderWidth: 1,
        zoomType: 'xy',
        animation: {
            duration: 2000
        },
        events: {
            load() {
              setTimeout(this.reflow.bind(this), 0);
            },
          },
        resetZoomButton: {
            theme: {
                states: {
                    hover: {
                        fill: '#D98C14',
                        stroke: '#BF7400',
                    }
                }
            }
        }
    },
    credits: {
        enabled: false
    },
    title: "",

    exporting: {
        enabled: false
    },

    legend: {
        enabled: false
    },

    xAxis: {
        gridLineWidth: 1,
        title: {
            useHTML: true,
            // text : 'Peak Load A',
            style: style
        },
        labels: {
            format: '{value}',
            style: style
        },
        min: 0,
        maxPadding: 0.05,
        plotLines: [{
            color: '#000',
            dashStyle: 'dot',
            width: 2,
            label: {
                style: style,
                // text: 'Peak Load Threshold',
                align: 'center',
                verticalAlign: 'middle',
            },
            zIndex: 3
        }],
    },

    yAxis: {
        startOnTick: false,
        endOnTick: false,
        height:'100%',
        tickInterval:25,
        title: {
            useHTML: true,
            // text: 'Peak Unbalance (A)',
            style: style
        },
        labels: {
            format: '{value}',
            style: style
        },
        min: 0,
        maxPadding: 0.1,
        plotLines: [{
            color: '#000',
            dashStyle: 'dot',
            width: 2,
            label: {
                style: style,
                // text: 'Peak Load Threshold',
                align: 'center',
                verticalAlign: 'middle',
            },
            zIndex: 3
        }],
    },
    // tooltip: {
    //     useHTML: true,
    //     padding: 10,
    //     headerFormat: '<div class="padding-0-10 padding-b-10"><table>',
    //     pointFormat: '<tr><td colspan="2"><h6 style="font-size: 2vh"><strong>{point.name}</strong></h6></td></tr>' +
    //         `<tr><td style="padding-right: 0.5vh; font-size:1.8vh">Peakload: </td><td>{point.x} A</td></tr>` +
    //         '<tr><td style="padding-right: 0.5vh; font-size:1.8vh">Peak Unbalance: </td><td>{point.y} A</td></tr>' +
    //         '<tr><td style="padding-right: 0.5vh; font-size:1.8vh">Device Count: </td><td>{point.z}</td></tr>',
    //     footerFormat: '</table></div>'
    // },

    plotOptions: {
        bubble: {
            minSize: 20
        },
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                format: '..',
                style: { fontSize: 10, color: 'rgba(67, 67, 72, 0.5)', textOutline: false, fillOpacity: 0.1 },
                color: 'rgba(67, 67, 72, 0.5)'
            },
            cursor: 'pointer',
            point: {
                events: {
                    click: function () {
                        const self = this.series.chart.component;
                        const point = { x: this.x, y: this.y, name: this.name };
                        self.loadPointData(point);
                    }
                }
            }
        }
    },
    series: [{
        data: [],
        name: 'Things',
        colorByPoint: true,
        animation: true,
        marker: {
            fillColor: {
                radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
                stops: [
                    [0, 'rgba(255,255,255,0.5)'],
                    [1, Highcharts.color(Highcharts.getOptions().colors[1]).setOpacity(0.5).get('rgba')]
                ]
            }
        }
    }]
};

export const heatMapData = {
    options: {
        credits: {
            enabled: false,
        },
        chart: {
            events: {
                load() {
                  setTimeout(this.reflow.bind(this), 0);
                },
              },
        },
        exporting: {
            enabled: false
        },
        colorAxis: {
            minColor: '#2ecc71',
            maxColor: '#e74c3c',
            labels: {
                style: style,
            }
        },
        // tooltip: {
        //     useHTML: true,
        //     padding: 10,
        //     headerFormat: '<div class="padding-0-10 padding-b-10"><table>',
        //     pointFormat: '<tr><td colspan="2"><h6 style="font-size:2vh"><strong>{point.name}</strong></h6></td></tr>' +
        //         '<tr><td style="padding-right: 0.5vh; font-size:1.8vh">{point.type} Count: </td><td>{point.colorValue}</td></tr>' +
        //         '<tr><td style="padding-right: 0.5vh; font-size:1.8vh">Device Count: </td><td>{point.value}</td></tr>',
        //     footerFormat: '</table></div>'
        // },
        plotOptions: {
            series: {
                cursor: 'pointer',
                dataLabels: {
                    formatter: function () {
                        var labelStr = this.point.name + ': ' + this.point.colorValue;
                        var allowedLength = (this.point.shapeArgs && this.point.shapeArgs.width) ? Math.round(this.point.shapeArgs.width / 8) : 0;
                        if (allowedLength && (labelStr.length > allowedLength)) {
                            var valueStr = this.point.colorValue + "";
                            var valueLength = valueStr.length;
                            var truncatedStrLength = (allowedLength - (valueLength + 3));
                            if (truncatedStrLength <= 0) {
                                labelStr = this.point.name.substr(0, 1);
                            } else {
                                labelStr = this.point.name.substr(0, truncatedStrLength) + '.. ' + this.point.colorValue;
                            }
                        }
                        return labelStr;
                    },
                    enabled: true,
                    allowOverlap: true,
                    style: {
                        fontFamily: "'univers', Arial, sans-serif",
                        fontSize: "1.3vh",
                        color: "#FFF",
                        stoke: "#FFF"
                    }
                },
                point: {
                    events: {
                        click: function () {
                            const self = this.series.chart.component;
                            const point = { x: this.x, y: this.y, name: this.name, id: this.id };
                            self.loadPointData(point);
                        }
                    }
                }
            }
        },
        series: [{
            type: 'treemap',
            layoutAlgorithm: 'squarified',
            animation: false,
            allowDrillToNode: true,
            dataLabels: {
                enabled: false
            },
            levelIsConstant: false,
            levels: [{
                level: 1,
                dataLabels: {
                    enabled: true
                },
                borderWidth: 2
            }],
            data: []
        }],
        title: {
            text: ''
        }
    }

};


export const feederBarData = {

    colors: ['#ff7474', '#5cd65c', '#7495be'],

    chart: {
        height: 17 + '%',
        type: 'column',
        animation: {
            duration: 2000
        }
    },
    credits: {
        enabled: false
    },
    title: {
        enabled: false,
        text: ''
    },
    subtitle: {
        enabled: false,
        text: ''
    },
    exporting: {
        enabled: false
    },
    xAxis: [{
        categories: [],
        crosshair: true,
        title: {
            useHTML: true,
            style: style
        },
        labels: {
            style: style
        },
        min: 0
    }],
    yAxis: {
        min: 0,
        title: {
            useHTML: true,
            // text: 'Peak Load (A)',
            style: style
        },
        labels: {
            style: style
        }
    },
    tooltip: {
        useHTML: true,
        shared: true,
        padding: 10,
        headerFormat: `<div class="padding-0-10 padding-b-10"><h6><strong>{point.key}</strong></h6><table>`,
        //pointFormat: `<tr><td><span style="width: 10px; height: 10px; display: inline-block; margin-right: 3px; border-radius: 50%; background-color: {series.color};"></span></td><td style="padding-right: 5px;">{series.name}: </td><td>{point.y} ${<FormattedMessage id='common.label.amperes' />}</td></tr>`,
        footerFormat: `</table></div>`
    },
    plotOptions: {
        series: {
            cursor: 'pointer',
            point: {
                events: {
                    click: function () {
                        const self = this.series.chart.component;
                        const point = {category : this.category};
                        self.loadPointData(point);
                    }
                }
            },
            pointPadding: 0,
            borderWidth: 0,
            shadow: false
        }
    },
    series: [{
        // name: 'Phase A',
        cursor: 'pointer',
        data: [],
        color: '#ff7474'

    }, {
        // name: 'Phase B',
        cursor: 'pointer',
        data: [],
        color: '#5cd65c'

    }, {
        // name: 'Phase C',
        data: [],
        cursor: 'pointer',
        color: '#7495be'

    }],
};

export const siteLineData = {

    colors: [],

    options: {
        chart: {
            height: 16 + '%',
            zoomType: 'xy'
        },
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        exporting: {
            enabled: false
        },
        xAxis: [{
            categories: [],
            crosshair: true,
            title: {
                style: style
            },
            labels: {
                style: style
            }
        }],
        yAxis: [{ // Secondary yAxis
            gridLineWidth: 0,
            title: {
                useHTML: true,
                // text: 'Peak Load (A)',
                style: style
            },
            labels: {
                format: '{value}',
                style: style
            }
        }, { // Primary yAxis
            labels: {
                format: '{value}',
                style: style
            },
            title: {
                useHTML: true,
                // text: 'Unbalance (A)',
                style: style
            },
            opposite: true

        }],
        tooltip: {
            useHTML: true,
            shared: true,
            padding: 10,
            headerFormat: '<div class="padding-0-10 padding-b-10"><h6><strong>{point.key}</strong></h6><table>',
            pointFormat: '<tr><td><span style="width: 10px; height: 10px; display: inline-block; margin-right: 3px; border-radius: 50%; background-color: {series.color};"></span></td><td style="padding-right: 5px;">{series.name}: </td><td>{point.y}</td></tr>',
            footerFormat: '</table></div>'
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            x: 80,
            verticalAlign: 'top',
            y: 55,
            floating: true,
            style: style,
            backgroundColor: (HighChartss.theme && HighChartss.theme.legendBackgroundColor) || '#FFFFFF'
        },
        series: [{
            // name: 'Unbalance',
            type: 'column',
            yAxis: 1,
            data: [],
            tooltip: {
                useHTML: true,
                padding: 10,
                //valueSuffix: ' A'
            },
            color: '#ffeead'

        }, {
            // name: 'Phase A',
            type: 'spline',
            data: [],
            tooltip: {
                useHTML: true,
                padding: 10,
                //valueSuffix: ' A'
            },
            color: '#ff7474'
        }, {
            // name: 'Phase B',
            type: 'spline',
            data: [],
            tooltip: {
                useHTML: true,
                padding: 10,
                //valueSuffix: ' A'
            },
            color: '#5cd65c'
        }, {
            // name: 'Phase C',
            type: 'spline',
            data: [],
            tooltip: {
                useHTML: true,
                padding: 10,
                //valueSuffix: ' A'
            },
            color: '#7495be',
        }]
    }
}

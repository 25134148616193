import React, { Fragment, useRef } from 'react';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { fetchBrowserTimeZone } from '../../../../services/utilityService';
import TextField from '../../../../hoc/form/textField';

const EventPrecursorPicker = (props) => {
    const datePickerRef = useRef();

    return (
        <Fragment>
            <span className="float-left col-form-label">
                &nbsp;&nbsp;<FormattedMessage id={`common.label.startDate`} />{fetchBrowserTimeZone('zoneAbbr', props.timezone)} &nbsp;
            </span>
            <span className="float-left col-form-label p-0" style={{ width: '8vw' }}>
                <TextField
                    type={'text'}
                    value={moment(props.startDate).format("MM/DD/YYYY")}
                    size={'md'}
                    className={'p-10 cursor-not-allowed'}
                    readOnly={true}
                    isDisabled={true}
                />
            </span>
            <span className="float-left col-form-label">
                &nbsp;&nbsp;<FormattedMessage id={`common.label.endDate`} />{fetchBrowserTimeZone('zoneAbbr', props.timezone)} &nbsp;
            </span>
            <span className="float-left col-form-label p-0" style={{ width: '8vw' }}>
                <TextField
                    type={'text'}
                    value={moment(props.endDate).format("MM/DD/YYYY")}
                    size={'md'}
                    className={'p-10 cursor-not-allowed'}
                    readOnly={true}
                    isDisabled={true}
                />
            </span>
            <span className="float-left col-form-label p-0 pl-2">
                {
                    (props.endDate !== null && props.startDate !== null) ?
                        <DateRangePicker ref={datePickerRef}
                            initialSettings={{
                                singleDatePicker: false,
                                showDropdowns: true,
                                startDate: moment(props.startDate),
                                endDate: moment(props.endDate),
                                linkedCalendars: false,
                                maxYear: parseInt(moment().format('YYYY'), 10),
                                maxDate: moment(props.maxDate),
                                showISOWeekNumbers: true,
                                autoUpdateInput: true,
                                opens: 'left',
                                autoApply: true, //for display footer section (apply and cancel)
                            }}
                            onCallback={(event, picker) => props.onCallback(event, picker)}
                        >
                            <span className="btn btnStyling date-icon icon-width float-right border-1px m-0 py-1 px-3">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </span>
                        </DateRangePicker> : null
                }
            </span>

        </Fragment>
    );
}

export default EventPrecursorPicker;
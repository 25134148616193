import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getDateConvertedString,dateConvertion } from '../services/utilityService';
import Tooltip from '../hoc/tooltip';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber'
import SubLabel from '../hoc/subLabel';
import Highcharts from "highcharts/highcharts";
import TooltipMessage from '../hoc/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

function intlFormater(value) {
    return <FormattedMessage id={`${value}`} />
}

export const faultDetailsData = {
	columnsData: [
		{ accessor: 'eventState', Header: '', sortedOrder: 0, Cell: (row) => { return row.original.eventState === "Active" && <span>
            <TooltipMessage 
                placement='top' 
                message={intlFormater('events.register.active')} 
                baseContent={<FontAwesomeIcon color={'#E80000'} icon={faExclamation} size="sm" />}
            />
        </span> }, sortable: false, width:5 },
		{ accessor: 'eventDate', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.faultevents.column.eventDate"} />} message={<FormattedMessage id={"events.faultevents.column.tooltip.eventDate"} />} placement={'top'} />} />, Cell: ({ original }) => getDateConvertedString(original.eventDate) },
		{ accessor: 'feeder', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"dm.column.feederName"} />} message={<FormattedMessage id={"dm.column.feederName"} />} placement={'top'} />} /> },
		{ accessor: 'upStreamSite', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"dm.column.siteName"} />} message={<FormattedMessage id={"events.faultevents.column.tooltip.upStreamSite"} />} placement={'top'} />} /> },
		{ accessor: 'eventType',width:100, Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.faultevents.column.eventType"} />} message={<FormattedMessage id={"events.faultevents.column.tooltip.eventType"} />} placement={'top'} />} />, Cell: ({ original }) => getDateEventType(original) },
		{ accessor: 'interruptionDuration', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.faultevents.column.duration"} />} message={<FormattedMessage id={"events.faultevents.column.tooltip.interruptionDuration"} />} placement={'top'} />} />, Cell: ({ original }) => getFormatMSValue(original) },
		{ accessor: 'faultCurrent', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={'Current'} message={<span><FormattedMessage id={"events.faultevents.column.tooltip.FaultCurrent"} />&nbsp;<SubLabel type='Arms' /></span>} placement={'top'} />} />, Cell: ({ original }) => eventTypeFormatter(original) },
		{ accessor: 'phasesAffected',width:75, Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"dm.column.phase"} />} message={<FormattedMessage id={"events.faultevents.column.tooltip.phasesAffected"} />} placement={'top'} />} />, Cell: (props) => props.original.phasesAffected.map((phase, key, phaseAarr) => phaseAarr.length > 1 ? phase.name + (key === phaseAarr.length - 1 ? '' : ',') : phase.name) },
		// { accessor: 'waveformStatus', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={'Waveforms'} message={'Waveform Status'} placement={'top'} />} /> },
	],
	fltDtlsColumnsData: [
		{ accessor: 'eventTime', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id={"events.details.column.eventTime"} />} />, Cell: ({ original }) => <Tooltip baseContent={dateConvertion(original.eventTime,"millsTimeWithAP") } message={dateConvertion(original.eventTime,"maxLongDate")} placement={'top'} /> },
		{ accessor: 'lateral', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id={"dm.column.lateral"} />} /> },
		{ accessor: 'site', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id={"dm.column.siteName"} />} />, textOverflow:'ellipsis',
			Cell: (row) => {
            	return <div>{setEllipseView(row.original.site)}</div>;
        	} 
		},
		{ accessor: 'phase', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id={"dm.column.phase"} />} /> },
		{ accessor: 'message', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id={"events.details.column.message"} />} />, Cell: ({ original }) => updateMessageData(original) },

	],
	filterForm: [
		{ label: <FormattedMessage id={"events.faultevents.filter.eventState"} />, fName: 'faultState', type: 'multiSelect' },
		{ label: <FormattedMessage id={"events.faultevents.filter.eventType"} />, fName: 'faultType', type: 'multiSelect' },
		{ label: <FormattedMessage id={"events.faultevents.filter.faultClassification"} />, fName: 'faultClassification', type: 'multiSelect' },
		{
			label: <FormattedMessage id={"events.faultevents.filter.faultCurrent"} />, fName: 'faultCurrentRange', type: 'rangeTextField',
			range: [
				{ label: 'Min', fName: 'minRange', type: 'text' },
				{ label: 'Max', fName: 'maxRange', type: 'text' }
			]
		},
	],

	selectedDataByUser: {
		startTimestamp: '',
		endTimestamp: new Date().setHours(23, 59, 59, 999),
		faultState: [],
		faultType: [],
		faultClassification: [],
		minRange: '',
		maxRange: ''
	},
}

function updateClass(original) {
	let className = ''
	if (original.eventType === 'Fault Sustained Interruption') className = 'Sustained_Interruption'
	else if (original.eventType === 'Fault Momentary Interruption') className = 'Momentary_Interruption'
	else if (original.eventType === 'Fault No Interruption') className = 'Fault_Without_Interruption'
	else if (original.eventType === 'No Fault Sustained Interruption' || original.eventType === 'No Fault Momentary Interruption') className = 'Interruption_Without_Fault'
	return className
}

function getDateEventType(original) {
	return <span>
		{original.classificationType === 'Lateral Protection Failure' ?
			<Tooltip baseContent={<span className={'classification LPF mr-1'}><span style={{ visibility: 'hidden' }}>.</span></span>} message={original.classificationType} placement={'top'} />
			: ""}
		<Tooltip baseContent={<span className={updateClass(original)}><span style={{ visibility: 'hidden' }}>.</span></span>} message={original.eventType} placement={'top'} />
	</span>
}


function eventTypeFormatter(row) {
	let naValue = notApplicable(row.faultCurrent, 'faultCurrent', row.eventType);
	return (
		<span>
			{row.fault === true && (row.faultCurrent === '' || row.faultCurrent === null) && <span></span>}
			{row.fault === false && (row.faultCurrent === '' || row.faultCurrent === null) && naValue !== 0 && <span>-</span>}
			{naValue !== 'N/A' && !(row.fault === true && (row.faultCurrent === '' || row.faultCurrent === null)) && <span>{naValue} A<sub>rms</sub></span>}
		</span>
	);
};
function notApplicable(input, obj, eventType) {
	var naApplicables = {
		faultCurrent: {
			eventType: ['No Fault Sustained Interruption', 'No Fault Momentary Interruption']
		}
	};
	if (obj === "faultCurrent") {
		if ((input === 0 || input === null) && (naApplicables.faultCurrent.eventType.indexOf(eventType) !== -1)) {
			return 'N/A';
		} else {
			return input;
		}
	}
};

function getFormatMSValue(row) {
	let formatMsValue = formatMs(row.interruptionDuration, 'uptoSecs', row.eventState, row.eventType);
	return <span>
		{(row.interruption === true) && (row.interruptionDuration === '' || row.interruptionDuration === null) && <span></span>}
		{(row.interruption === false) && (row.interruptionDuration === '' || row.interruptionDuration === null) && formatMsValue !== 0 && <span>-</span>}
		{formatMsValue !== 'N/A' && <span>{formatMsValue}</span>}
	</span>
}

export const formatMs = (input, format, eventState, eventType) => {
	if (input === 0 || input === null || input <= 0) {
		if ( input < 0 || eventState.toUpperCase() === 'ACTIVE' || eventType === 'Fault No Interruption') {
			return 'N/A';
		} else {
			return input;
		}
	} else {
		var formatedValue = '';
		var days = Math.floor(input / (24 * 60 * 60 * 1000));
		var daysms = input % (24 * 60 * 60 * 1000);
		var hours = Math.floor((daysms) / (60 * 60 * 1000));
		var hoursms = input % (60 * 60 * 1000);
		var minutes = Math.floor((hoursms) / (60 * 1000));
		var minutesms = input % (60 * 1000);
		var sec = parseFloat(minutesms / 1000);
		if (days) formatedValue += days + 'd ';
		if (hours) formatedValue += hours + 'h ';
		if (minutes) formatedValue += minutes + 'm ';
		if (format && format === "uptoSecs" && sec) formatedValue += sec + 's ';
		return formatedValue;
	}
}

function updateMessageData(row) {
	let length = 33;
	let msg = row.message !== null && row.message !== undefined && row.message !== '' ? row.message : '';
	if (msg.length > length) {
		return row.message && <Tooltip baseContent={<span dangerouslySetInnerHTML={{ __html: updateMsgLength(row.message, length) }}></span>} message={<span dangerouslySetInnerHTML={{ __html: row.message }}></span>} placement={'top'} />
	} else {
		return row.message && <span dangerouslySetInnerHTML={{ __html: row.message }}></span>
	}
}

function updateMsgLength(msg, length) {
	return msg.slice(0, length) + '...'
}

export const keyValue = (data, valueArray, keyName) => {
	var list = [];
	var arrayList = [];
	if (valueArray[0] !== undefined) {
		valueArray.forEach((val, key) => {
			var value = ((typeof (val) === 'object') && (val.id !== undefined)) ? val.id : val;
			arrayList.push(value);
		});
	}
	data.forEach((obj, key) => {
		if (obj[keyName] && (typeof (obj[keyName]) === 'object')) {
			obj[keyName].forEach((val, key) => {
				var value = ((typeof (val) === 'object') && (val.name !== undefined)) ? val.name.toLowerCase() : val;
				if (arrayList.indexOf(value) !== -1) {
					list.push(obj);
				}
			});
		} else {
			var item = keyName === "sitePhaseValue" ? obj[keyName].toLowerCase() : obj[keyName];
			if (arrayList.indexOf(item) !== -1) {
				list.push(obj);
			}
		}
		// if filter is eventType value as null, including into filtered items
		if (keyName === "eventType" && obj[keyName] === null) {
			list.push(obj);
		}
	});
	return list;
};



const style = {
	fontFamily: "'univers', Arial, sans-serif",
	fontSize: 14,
	color: "#333333"
};

var pointInterval = 128;
var siteMinMaxTime = [];

export const updateLegend = (type, event) => {
    var chart = chartData.chartList ? chartData.chartList : event;
    if (chart) {
        chart.forEach(items => {
            var series = items.series;
            if(series){
            series.forEach(function (item, index) {
                let arr = [];
                if (type === 'legendItemClick') {
                    if (event.target.name === item.name) {
                        if (event.target.visible) {
                            item.show();
                        } else {
                            item.hide();
                        }
                    }
                }
            });
        }
        })

    }
}

export const chartData = {
	internalChart: null,
	options: {
		etUnit: "F",
		params: {}, // optional request parameters to specify data
		colors: ['#ff3300', '#00d10e', '#0040ff', '#ff3300', '#00d10e', '#0040ff'], // controls which colors are used. '#ff3300','#6cc66c', '#4b75aa','#ff8566', '#37fb37','#b3d0f4'
		dashStyles: ['Solid', 'ShortDash', 'ShortDot'],
		view: "it",
		phases: ['A', 'B', 'C'],
		dataColumns: ["ait", "bit", "cit", "aet", "bet", "cet"],
		// titles: {	//moved to component
		//     it: 'Current (A)',
		//     et: 'E-field (V/m)'
		// },
		// seriesNames: { //moved to component
		//     a: {
		//         name: "Phase A"
		//     },
		//     b: {
		//         name: "Phase B"
		//     },
		//     c: {
		//         name: "Phase C"
		// 	},
		//     it: {
		// 		// name: $filter('translate')('events.waveform.chart.axis.label.currentValue')
		// 		name: 'Current (A)'
		//     },
		//     et: {
		// 		// name: $filter('translate')('events.waveform.chart.axis.label.eFieldValue')
		// 		name: 'E-field (V/m)'
		//     },
		//     ait:{
		//     	// name:$filter('translate')('events.waveform.phasea.currentValue')
		//     	name: 'Phase A Current (A)'
		//     },
		//     bit:{
		//     	// name:$filter('translate')('events.waveform.phaseb.currentValue')
		//     	name: 'Phase B Current (A)'
		//     },
		//     cit:{
		//     	// name:$filter('translate')('events.waveform.phasec.currentValue')
		//     	name: 'Phase C Current (A)'
		//     },
		//     aet:{
		//     	// name:$filter('translate')('events.waveform.phasea.eFieldValue')
		//     	name: 'Phase A E-field (V/m)'
		//     },
		//     bet:{
		//     	// name:$filter('translate')('events.waveform.phaseb.eFieldValue')
		//     	name: 'Phase B E-field (V/m)'
		//     },
		//     cet:{
		//     	// name:$filter('translate')('events.waveform.phasec.eFieldValue')
		//     	name: 'Phase C E-field (V/m)'
		//     }
		// },
		highcharts: {
			credits: {
				enabled: false
			},
			chart: {
				alignTicks: false, //required for multiple series
				zoomType: 'xy',
				panning: true,
				panKey: 'shift',
				height: 400,
				reflow: true,
				events: {
					load: function (event) {
					},
				},
				resetZoomButton: {
					theme: {
						states: {
							hover: {
								fill: '#D98C15',
								stroke: '#BF7400',
							}
						}
					},
					position: {
						align: 'right', // right by default
						verticalAlign: 'top',
						x: -80,
						y: 10
					},
					relativeTo: 'chart'
				}
			},
			title: {
				text: null,
				style: {
					display: 'none'
				}
			},
			exporting: {
				enabled: true,
				style: { cursor: 'pointer' },
				chartOptions: {
					title: {
						text: null
					}
				},
				// menuItemDefinitions: { //moved to the component ====>
				// 	// Custom definition
				// 	downloadCSV: {
				// 		onclick: function () {
				// 			// $scope.$emit("waveformdownload", "csv");
				// 			// self.waveformdownload("csv");
				// 		},
				// 		text: 'Download CSV'
				// 	},
				// 	downloadXLS: {
				// 		onclick: function () {
				// 			// $scope.$emit("waveformdownload", "excel");
				// 		},
				// 		text: 'Download XLS'
				// 	}
				// },
				buttons: {
					contextButton: {
						menuItems: ["printChart",
							"separator",
							"downloadPNG",
							"downloadJPEG",
							"downloadPDF",
							"downloadSVG",
							"separator",
							"downloadCSV",
							"downloadXLS"]
						//"viewData",
						//"openInCloud"]
					}
				}
			},
			navigation: {
				buttonOptions: {
					enabled: true
				}
			},
			navigator: {
				enabled: true,
				outlineColor: "#ccc",
				adaptToUpdatedData: false,
				height: 45,
				series: {
					type: 'areaspline',
					pointRange: null,
					connectNulls: false,
					dataGrouping: {
						enabled: true,
						forced: true,
						smoothed: true,
						units: [
							['second', [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]]
						],
					},
				},
				xAxis: {
					labels: {
						enabled: false,
						style: style
					},
					type: 'linear',
					ordinal: false
				}
			},
			rangeSelector: {
				selected: 1,
				enabled: false,
			},
			scrollbar: {
				enabled: false,
			},
			tooltip: {
				borderWidth: 1,
				borderColor: '#aeceea',
				backgroundColor: '#fafafa',
				useHTML: false,
				style: style,
				split: false,
				outside: true,
				shared: true,
				// distance: 80,
				positioner: (labelWidth, labelHeight, point) => {
					return { x: point.plotX, y: 80 };
				},
				// formatter: function () { // moved to component=======>
				// 	var dateStr = this.x.toString();
				// 	var stp = parseInt(dateStr.substr(0, 13));
				// 	var micro = parseInt(dateStr.substr(13, 3));

				// 	// var s = '<b>' + $filter('dateConvertion')(stp, 'maxLongDateWithoutAP') + ' ' + micro + ' ' +$filter('dateConvertion')(stp, 'AMPM') +'</b>';
				// 	// var s = '<b>' + stp + ' ' + micro + ' ' + stp +'</b>';
				// 	var s = '<b>' + dateConvertion(stp, 'maxLongDateWithoutAP', 'America/Los_Angeles') + ' ' + micro + ' ' + dateConvertion(stp, 'AMPM', 'America/Los_Angeles') +'</b>';
				// 	var current = {};
				// 	var eField = {};
				// 	// $.each(this.points, function (e) {
				// 		this.points.forEach((e)=> {
				// 		//  var name = this.series.options.phaseName;
				// 		 var name = 'Phase A Current (A)'
				// 		//  var name = "Phase A E-field (V/m)"
				// 		 var splitName = (name.indexOf("Current") != -1) ? ' Current' : ' E-field';
				// 		 if (splitName === ' Current') {
				// 			current[name.split(splitName)[0]] = this.y+'(A)';
				// 		 } else {
				// 			eField[name.split(splitName)[0]] = this.y+'(V/m)';
				// 		 }
				// 	});
				// 	if ((Object.keys(current).length > 0) && (Object.keys(eField).length > 0)) {
				// 		// $.each(current, function (el) {
				// 			Object.entries(current).forEach(([a, el])=> {
				// 			// var eVal = (eField.hasOwnProperty(el)) ? ', '+eField[el] : '';
				// 			// s += '<br/>'+ el +': ' + current[el] + eVal;
				// 			var eVal = (eField.hasOwnProperty(a)) ? ', '+eField[a] : '';
				// 			s += '<br/>'+ a +': ' + el + eVal;
				// 	   });
				// 	//    $.each(eField, function (el) {
				// 		Object.entries(eField).forEach(([a, el])=> {
				// 		   if(!current.hasOwnProperty(el)) {
				// 			// s += '<br/>'+ el +': ' + eField[el];
				// 			s += '<br/>'+ a +': ' + el;
				// 		   }
				// 		});
				// 	} else if(Object.keys(current).length > 0) {
				// 		// $.each(current, function (el) {
				// 			Object.entries(current).forEach(([a, el])=> {
				// 			// s += '<br/>'+ el +': ' + current[el];
				// 			s += '<br/>'+ a +': ' + el;
				// 	   });
				// 	} else if(Object.keys(eField).length > 0) {
				// 		// $.each(eField, function (el) {
				// 			Object.entries(eField).forEach(([a, el])=> {
				// 			// s += '<br/>'+ el +': ' + eField[el];
				// 			s += '<br/>'+ a +': ' + el;
				// 	   });
				// 	}
				//  	return s;
				// }
			},
			xAxis: {
				crosshair: true,
				labels: {
					style: style,
					step: 2,
					formatter: function () {
						var nanoStr = this.value.toString();
						var d = new Date(parseInt(nanoStr.substr(0, 13), 10));
						var dStr = d.getSeconds() + "." + Highcharts.dateFormat('%L', d) + nanoStr.substr(13, 3);
						return dStr + ' s';
					}
				},
				gridLineWidth: 1,
				gridLineDashStyle: 'dot',
				lineColor: "#e7e7e7",
				minPadding: 0,
				maxPadding: 0,
				align: 'left',
				minorTickPosition: 'inside',
				type: 'linear',
				ordinal: false,
				events: {
					//     afterSetExtremes: function (e) { //moved to component
					// 		// $scope.$emit("waveformUpdateXScale", {max:e.max, min:e.min, e: e});
					//     }
				}
			},
			yAxis: {
				allowDecimals: true,
			},
			legend: {
				enabled: true,
				borderRadius: 3,
				borderColor: '#ccc',
				itemStyle: style,
				// labelFormatter: function () {
				// 	if ($scope.options.view != 'a') {
				// 		if (($scope.options.view == 'it') && (this.name.indexOf('Current') != -1)) {
				// 			return this.name;
				// 		} else if (($scope.options.view == 'et') && (this.name.indexOf('E-field') != -1)) {
				// 			return this.name;
				// 		}
				// 	} else {
				// 		return this.name;
				// 	}
				// }
			},
			plotOptions: {
				line: {
					lineWidth: 1,
					animation: {
						duration: 1200
					},
					connectNulls: false,
					marker: {
						enabled: true,
						symbol: 'circle',
						radius: 0
					}
				},
				series: {
					lineWidth: 1,
					animation: {
						duration: 1200
					},
					states: {
						hover: {
							enabled: true,
							halo: {
								size: 2
							}
						}
					},
					connectNulls: false,
					dataGrouping: {
						approximation: 'high',
						enabled: true,
						forced: false,
						smoothed: true,
						units: [
							['second', [1]]
						],
					},
					marker: {
						radius: 0
					},
					events: {
					}
				}
			}
		}
	}
}


const setEllipseView=(value)=>{
    const [isElips, setElips] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [isShowMore, setShowMore] = useState(false)

    return (
        <div style={{display: !isShowMore && 'flex'}}>
            <div ref={ref => {
                    if (!ref) return
                        let isOverflowing = 0;
                        if (!clicked) {
                            isOverflowing = ref.scrollWidth > ref.clientWidth
                            if (isOverflowing) setElips(true)
                            else setElips(false)
                        } else {
                            isOverflowing = ref.offsetHeight < 20
                            if (isOverflowing) {
                                setShowMore(false)
                                setClicked(false)
                            } else setShowMore(true)
                        }
                  }}
            style={{whiteSpace: !clicked ? 'nowrap': '', overflow: !clicked ? 'hidden': '' }}
			>{value}</div>
            {isElips && !isShowMore && <div className='ellips-view'><Tooltip baseContent={<span className='cursor-pointer'>...</span>} message={value} placement={'top'} /></div>}			
        </div>
    )
}
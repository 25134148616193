import { requestPOST } from '../../../services/extendedService';
import { getURL } from '../../../providers/configProvider';

export const getCustomReports = (reportId, pageNo, pageSize, params) => {
    const url = getURL('customreports', 'reportdata', { 'apiType': 'API', 'REPORTID': reportId, 'PAGENO': pageNo, 'PAGESIZE': pageSize }, true);
    return requestPOST(url, params);
}
export const getSitesByParents = (params, orgId) => {
    const url = getURL('customreports', 'sitesbyparents', { 'apiType': 'API', 'ORGID': orgId }, true);
    return requestPOST(url, params);
}
export const exportData = (reportId, params) => {
    const url = getURL('customreports', 'reportdownload', { 'apiType': 'API', 'REPORTID': reportId, 'FORMAT': params.responseType });
    return requestPOST(url, params, { responseType: "arraybuffer" }, { validateSucess: false })
        .then(response => {
            if (typeof response === "object") {
                return response.data;
            }
        })
}
export const scheduleReport = (reportId, params) => {
    const url = getURL('customreports', 'reportschedule', { 'apiType': 'API', 'REPORTID': reportId });
    return requestPOST(url, params);
}

import React, { Component, Fragment } from 'react';
import { getOtapStatusData } from '../services/otapstatusService';
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../../../../hoc/modelWindow';
import { Row, Col } from 'react-bootstrap';
import { getDateConvertedString } from '../../../../services/utilityService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlayCircle, faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import AlertMessage from '../../../../hoc/alert';
import Loader from '../../../../hoc/loader';

class OtapStatusModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenOtapStatusModal: false,
            isLoading: false,
            modalRetryCount: 0,
            modalStatuses: [],
            modalErrorInfo: { type: "", msg: "" }
        }
    }

    getOtapStatusModalData = (e, info) => {
        e.preventDefault();
        this.getOtapModalData(info.serialNumber);
        this.setState({ isOpenOtapStatusModal: true });
    }

    getOtapModalData = (serialNumber) => {
        this.setState({ isLoading: true }, () => {
            var params = { SERIALNUMBER: encodeURIComponent(serialNumber) }
            getOtapStatusData(params, 'otapstatus')
                .then(data => {
                    if (data.status !== 'FAIL') {
                        this.setState({
                            modalRetryCount: (data.retryCount === null || data.retryCount === undefined || data.retryCount === "") ? 0 : data.retryCount,
                            modalStatuses: data.deviceOtapStatusData, isLoading: false
                        });
                    } else {
                        this.setState({ modalErrorInfo: { type: 'danger', msg: data.message } })
                    }
                }).catch(error => {
                    this.setState({
                        modalRetryCount: 0,
                        modalStatuses: [], isLoading: false,
                        modalErrorInfo: { type: 'danger', msg: <FormattedMessage id='groupmanagement.error.adddevice' /> }
                    });
                });
        })
    }

    onCloseModal = () => this.setState({ isOpenOtapStatusModal: false })
    closeAlertMsg = () => this.setState({ modalErrorInfo: { type: "", msg: "" } })

    render() {
        let modalTitle = <div className="popup-title otapStatusCount">
            <FormattedMessage id={"cj.devicestatus.otapstatus.title"} /> :
            <strong>{this.props.data.serialNumber} {this.state.modalRetryCount !== 0 && !this.state.isLoading ? ': Retry #' + this.state.modalRetryCount : ''}</strong></div>;

        let modalBody = <div>
            {this.state.modalErrorInfo.msg !== "" ?
                <Row className="margin-top-2vh">
                    <Col>
                        <AlertMessage type={this.state.modalErrorInfo.type} message={this.state.modalErrorInfo.msg} setVisible={this.closeAlertMsg} />
                    </Col>
                </Row> : ""}
            {this.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
            <div className="otapStatusModal">
                <Row className="allStatus">
                    {this.state.modalStatuses.length > 0 && this.state.modalStatuses.map((item, key) => {
                        return <Fragment>
                            <Col className="statusList" key={key}>
                                <div>{item.name}</div>
                                {item.status === 'Failure' && <div className="error faIcons"><FontAwesomeIcon icon={faTimesCircle} /></div>}
                                {item.status === 'Success' && <div className="success faIcons"><FontAwesomeIcon icon={faCheckCircle} /></div>}
                                {item.status === 'InProgress' && <div className="inprogress faIcons"><FontAwesomeIcon icon={faPlayCircle} /></div>}
                                {item.status === 'Unknown' && <div className="pending faIcons"><FontAwesomeIcon icon={faQuestionCircle} /></div>}
                                <div>{item.createdDateTime && getDateConvertedString(item.createdDateTime)}</div>
                                <div className="redFont">{item.message}</div>
                            </Col>
                            {key !== (this.state.modalStatuses.length - 1) && <div className="line" key={-1}></div>}
                        </Fragment>
                    })}
                </Row>
            </div>
        </div>

        return (
            <Fragment>
                <span onClick={(e) => this.getOtapStatusModalData(e, this.props.data)} className='cursor-pointer active-color'>{this.props.data.otapStatus}</span>
                {this.state.isOpenOtapStatusModal ?
                    <ModalWindow
                        show={this.state.isOpenOtapStatusModal}
                        onHide={this.onCloseModal}
                        size={"lg"}
                        title={modalTitle}
                        dialogClassName={'otapDetailsModal'}
                        modeldata={{ content: modalBody }} /> : ""}
            </Fragment>
        )
    }
}

export default OtapStatusModal;
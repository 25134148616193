import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Alert from '../../../hoc/alert';
import { LicenceContext } from '../provider/licenceProvider'

class LicenseManagementContainer extends Component {
    
    static contextType = LicenceContext;

    reloadApplication = () => window.location.reload();

    render() {
        return (
            <Row>
                <Col>
                    <Alert
                        type={this.context.state.fileUploadRes.type}
                        message={<div>
                            {this.context.state.fileUploadRes.msg}
                            <span>{this.context.state.fileUploadRes.type === 'success' ? <span className="cursor-pointer reload-text-color" onClick={this.reloadApplication}> <FormattedMessage id='db.licMngmnt.clickToReload' /> </span> : ""}</span>
                            <span className="float-right cursor-pointer" onClick={this.context.handleReoveAlert}><FontAwesomeIcon icon={faTimes} size="sm" style={{ color: "#9ca99f" }} /></span>
                        </div>
                        }
                    />
                </Col>
            </Row>
        );
    }
}

export default LicenseManagementContainer;

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DetailsContext } from '../provider/detailsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import SingleSelect from '../../../../hoc/singleSelect';
import { disturbancesData } from '../../../../utility/disturbanceAnalyticsUtility';
import { faultDetailsData } from '../../../../utility/faultDetailsUtility';
import FilterWithBadge from './filterWithBadge';
import MultiSelectDropdown from '../../../manage/details/components/multiselectDropdownComponent';
import TextField from '../../../../hoc/form/textField';
import FaultDetailsPicker from './faultDetailsPicker';

class DetailsAction extends Component {
    static contextType = DetailsContext;

    constructor(props) {
        super(props)
        this.state = {
        }
    }
    
    render() {
        const { filterFormOptionData, formData, selectedDate, formErr } = this.context.state
        return (
            <Row className='details-action'>
                <Col>
                    <FaultDetailsPicker
                        timezone={ this.context.state.timeZone }
                        endDate={selectedDate.endTimestamp}
                        startDate={selectedDate.startTimestamp}
                        onCallback={(event, picker) => this.context.onCalenderCallback(event, picker)}
                    />
                </Col>
                <Col className='filter-right'>
                    <span>
                        <FilterWithBadge
                            dropDownContent={
                                faultDetailsData.filterForm.map((item, k) => {
                                    return <Row key={k} className='mb-2'>
                                        <Col xs={5}>{item.label}</Col>
                                        <Col xs={7}>
                                            {item.type === 'multiSelect' ?
                                                <MultiSelectDropdown
                                                    selectedArrayList={formData[item.fName]}
                                                    optionList={filterFormOptionData[item.fName]}
                                                    onChange={this.context.onChangeHandler}
                                                    name={item.fName}
                                                    multiple={true}
                                                    includeSelectAll={false}
                                                    includeFilter={false}
                                                    open={false}
                                                    valueKey="value"
                                                    labelKey="text"
                                                    keepOpen={true}
                                                    placeholder="Select"
                                                    manySelectedPlaceholder={`%s/${filterFormOptionData[item.fName].length} Checked, ... `}
                                                    allSelectedPlaceholder={`%s/${filterFormOptionData[item.fName].length} Checked, ... `}
                                                    handleShowAllBtn={() => this.context.handleShowAllBtn(item.fName)}
                                                    btmLabel={"Show All"}
                                                />
                                                : ''}
                                            {item.type === 'rangeTextField' ?
                                                <Row>
                                                    {item.range.map((rangeItem, rangeKey) => {
                                                        return <Col key={rangeKey}>
                                                            <TextField
                                                                type={'text'}
                                                                field={rangeItem.fName}
                                                                value={formData[rangeItem.fName]}
                                                                placeholder={rangeItem.label}
                                                                handleOnchange={this.context.onChangeHandler}
                                                                size={'sm'}
                                                                className={formErr[rangeItem.fName] ? 'form-err-border-red' : ""}
                                                            />
                                                            {rangeKey === 0 ? <span className='mintomax'>-</span> : ''}
                                                        </Col>
                                                    })}
                                                </Row>
                                                : ""}
                                        </Col>
                                    </Row>
                                })
                            }
                            badgeNo={this.context.state.totalBadgeCount}
                            isDisableApplyBtn={formErr.minRange || formErr.maxRange ? true : false }
                            isDisableResetBtn={this.context.state.totalBadgeCount > 0 ? false : true}
                            handleClickFilterApply={this.context.handleClickFilterApply}
                            handleClickFilterReset={this.context.handleClickFilterReset}
                        />
                    </span>
                    <span className='single-select-dropdown export-content'>
                        <SingleSelect
                            data={disturbancesData.exportData}
                            setType={this.context.handleExportData}
                            value={<FontAwesomeIcon icon={faShareSquare} color={this.context.state.mainTableData.length===0 ? '#ccc' : ''} />}
                            isDisplayLabel={false}
                            disabled={this.context.state.mainTableData.length===0}
                        />
                    </span>
                </Col>
            </Row>
        )
    }
}

export default DetailsAction;
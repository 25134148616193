import React, { Component } from 'react';
import AddNodeComponent from '../components/addNodeComponent';
import DeleteNodeComponent from '../components/deleteNodeComponent';
import EnableGPSComponent from '../components/enableGPSComponent';
import NodeDetailsComponent from '../components/nodeDetailsComponent';
import EditSiteOrderComponent from '../components/editSiteOrderComponent';
import EditNodeComponent from '../components/editNodeComponent';
import { getNodeRouteParams } from '../containers/treeMethods';
import { getNodeDetails } from '../services/treeService';

class TreeOptions extends Component {
    state = {
        modelShow: false,
        nodeDetails: {},
        loader:false
    };

    componentDidMount() {
        if (this.props.action === "details" || (this.props.action === "edit" && (this.props.clickedNode.node.type === "SITE" || this.props.clickedNode.node.type === "LATERAL_SITE"))) {
            this.setState({ loader: true }, async () => {
                const params = getNodeRouteParams(this.props.clickedNode);
                const res = await getNodeDetails(params);
                if (res && res.status !== "FAIL") {
                    this.setState({ nodeDetails: res }, () => { this.setState({ loader: false }) });
                } else {
                    this.setState({ loader: false });
                }
            });
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.action.includes("add") ? <AddNodeComponent clickedNode={this.props.clickedNode} action={this.props.action} closeModal={this.props.closeModal} orgId={this.props.orgId} isModalOpen={this.props.show} /> :
                        this.props.action === "delete" ? <DeleteNodeComponent clickedNode={this.props.clickedNode} closeModal={this.props.closeModal} isModalOpen={this.props.show} /> :
                            this.props.action === "enableGPS" ? <EnableGPSComponent clickedNode={this.props.clickedNode} closeModal={this.props.closeModal} isModalOpen={this.props.show} /> :
                                this.props.action === "details" ? <NodeDetailsComponent clickedNode={this.props.clickedNode} action={this.props.action} nodeDetails={this.state.nodeDetails} closeModal={this.props.closeModal} isModalOpen={this.props.show} /> :
                                this.props.action === "editSiteOrder" ? <EditSiteOrderComponent clickedNode={this.props.clickedNode} action={this.props.action} nodeDetails={this.state.nodeDetails} closeModal={this.props.closeModal} isModalOpen={this.props.show} /> :
                                    (this.props.action === "edit" && !this.state.loader) ? <EditNodeComponent clickedNode={{...this.props.clickedNode, node:{...this.props.clickedNode.node,...this.state.nodeDetails}}} closeModal={this.props.closeModal} orgId={this.props.orgId} isModalOpen={this.props.show} /> 
                                    : null
                }
            </div>
        )
    }
}

export default TreeOptions;
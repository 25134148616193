import React, { Component, Fragment } from 'react';
import { TreeConsumer } from '../../../treeview/provider/treeProvider';
import DeviceProvider from '../details/provider/deviceProvider';
import BaseMessage from '../../../hoc/baseMessage';
import ManageSummaryContainer from './containers/manageSummaryContainer';

class ManageSummary extends Component {
    render() {
        return (
            <Fragment>
                <TreeConsumer>
                    {(context) => (
                        <React.Fragment>
                            <div className="device-management">
                                <DeviceProvider treeNode={context.state.highlightedNode.node} setDashboardFlag={context.cancelApiCallFromDashboard} dashboardFlag={context.state.dashboardFlag} treeLoader={context.state.loading} setLoadDevicesList={context.setLoadDevicesList} selectedPage="summary"> {
                                        ((!context.state.highlightedNode) || (context.state.highlightedNode && context.state.highlightedNode.node.type.indexOf("SITE") > -1)) ? <BaseMessage className={context.state.highlightedNode && context.state.highlightedNode.node.type.indexOf("SITE") > -1 ? "summary-base-view" : "content-height"} message="manage.summary.baseMessage" /> : <ManageSummaryContainer />
                                    }
                                </DeviceProvider>
                            </div>
                        </React.Fragment>
                    )}
                </TreeConsumer>
            </Fragment>
        );
    }
}

export default ManageSummary;
import { getURL } from '../../../../providers/configProvider';
import { requestGET } from '../../../../services/extendedService';

export const getSummaryData = (params) => {
    const url = getURL('summaryevents', 'getData', params);
    return requestGET(url)
}

export const exportData = (params) => {
    const url = getURL('summaryevents', 'exportData', params);
    const headers = {'Content-Type': 'blob'};
    const config= {method: 'GET', url: url, responseType: 'arraybuffer', headers};
    return requestGET(url,config)
}
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {setLoader, logoutTokenHandler} from '../../login/actions/loginActionsBuilder';

class Logout extends Component {
    clearExpandColumns = () =>{
        ["deviceExpandColumn","otapExpandColumn","configStatusExpandColumn","userManagementExpandColumn","equipmentProfileExpandColumn", "cableProfileExpandColumn"].forEach(e => sessionStorage.clear(e));
    }

    handleLogout = () => {
        this.props.setLoader(true);
        this.props.roleAndPermission.samlEnabled?logoutTokenHandler(true):logoutTokenHandler();
    }

    render() {
        return (
            <div onClick={this.handleLogout} className="noNavigationItemLink">
                <FormattedMessage id={`nav.main.logout`} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (params) => dispatch(setLoader(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout));
import React, { Component, Fragment } from 'react';
import { FormControl, FormGroup, Col, Form } from 'react-bootstrap';
import { formFields } from '../../../../utility/addDeviceUtility';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { getNodes } from '../../../../treeview/containers/treeMethods';
import { compareValues } from '../../../../services/utilityService';
import { moveDevice } from '../services/deviceService';
import { DeviceContext } from '../provider/deviceProvider';
import ToggleSwitch from '../../../../hoc/toggleSwitch';
import SingleSelect from '../../../../hoc/singleSelect';
import ModalWindow from '../../../../hoc/modelWindow';
import Loader from '../../../../hoc/loader';
import AlertMessage from '../../../../hoc/alert';
import ampleStore from '../../../../store';
import { showAlertToaster } from '../../../../login/actions/loginActionDispatch';

class MoveDeviceComponent extends Component {
    static contextType = DeviceContext;
    state = {
        selectedValues: {},
        isDataModified: false,
        moveDeviceFields: [],
        um3DeviceFields: [],
        errorMessage: "",
        warningMessage: "",
        togglePositions: [],
        disabledPositions: [],
        isLoading: false,
        backupSiteOptions: {}
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isModalOpen && !prevState.isDataModified) {
            let moveDeviceFields = formFields.filter(field => field.isMove === true);
            let um3Fields = [];
            if (moveDeviceFields.length > 0) {
                if (nextProps && nextProps.rowData && (["MM3","MM3ai"].includes(nextProps.rowData.deviceType))) {
                    moveDeviceFields = moveDeviceFields.filter(field => field.value === "common" || field.displayType !== "UM3" ? field : "");
                }
                else if (nextProps && nextProps.rowData && nextProps.rowData.deviceType === "ZM1") {
                    moveDeviceFields = moveDeviceFields.filter(field => field.value === "common" || field.value === "ZM1" || field.displayType !== "UM3" ? field : "");
                }
                if (nextProps && nextProps.rowData && nextProps.rowData.deviceType === "UM3+") {
                    um3Fields = moveDeviceFields;
                    moveDeviceFields = moveDeviceFields.filter(field => field.displayType !== "UM3" && field.displayType !== false ? field : "");
                    um3Fields = um3Fields.filter(field => field.displayType === "UM3");
                }
                const index = moveDeviceFields.splice(moveDeviceFields.findIndex(index => index.key === "description"), 1);
                moveDeviceFields.push(index[0]);
                return {
                    moveDeviceFields: moveDeviceFields,
                    um3DeviceFields: um3Fields
                }
            }
        }
        return null;
    }

    async componentDidMount() {
        this.resetDeviceDropdown();
        const list = [{ label: "--select--", value: "--select--" }, { label: "A", value: "A" }, { label: "B", value: "B" }, { label: "C", value: "C" }];
        if (Object.values(this.state.selectedValues).every(val => val === undefined || val === "")) {
            this.setState({ isLoading: true });
        }
        if (Object.keys(this.props.rowData).length > 0) {
            let routeParams = [];
            if (this.props.rowData.region) {
                routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.props.rowData.region, type: "REGION" }];
                await this.apiRequestNodeDropdown(routeParams);
            }
            if (this.props.rowData.substation) {
                routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.props.rowData.region, type: "REGION" }, "SUBSTATION", { name: this.props.rowData.substation, type: "SUBSTATION" }];
                await this.apiRequestNodeDropdown(routeParams);
            }
            if (this.props.rowData.feeder) {
                routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.props.rowData.region, type: "REGION" }, "SUBSTATION", { name: this.props.rowData.substation, type: "SUBSTATION" }, "FEEDER", { name: this.props.rowData.feeder, type: "FEEDER" }];
                await this.apiRequestNodeDropdown(routeParams);
            }
            if (this.props.rowData.lateral) {
                routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.props.rowData.region, type: "REGION" }, "SUBSTATION", { name: this.props.rowData.substation, type: "SUBSTATION" }, "FEEDER", { name: this.props.rowData.feeder, type: "FEEDER" }, "LATERAL", { name: this.props.rowData.lateral, type: "LATERAL" }];
                await this.apiRequestNodeDropdown(routeParams);
            }
            formFields.filter(field => (field.key === "phase" || field.key === "conductor1Phase" || field.key === "conductor2Phase" || field.key === "conductor3Phase") ? field.options = list : "")

            let obj = {};
            obj.region = this.props.rowData.region;
            obj.substation = this.props.rowData.substation;
            obj.feeder = this.props.rowData.feeder;
            obj.site = this.props.rowData.site;
            obj.lateral = this.props.rowData.lateral;
            obj.phase = this.props.rowData.phase;
            obj.externalID1 = this.props.rowData.externalID1;
            obj.externalID2 = this.props.rowData.externalID2;
            obj.externalID3 = this.props.rowData.externalID3;
            obj.description = this.props.rowData.description;
            obj.deviceType = this.props.rowData.deviceType;
            if (this.props.rowData.deviceType === "UM3+") {
                obj.positions = [...this.props.moveRowData.positions];
                if (obj.positions.length > 0) {
                    const totalPos = this.props.rowData.mcpDevice ? [1] : [1, 2, 3, 4];
                    const availablePos = [];
                    const position = [...this.state.togglePositions];
                    obj.positions.forEach(async pos => {
                        pos.switchDisabled = false;
                        availablePos.push(pos.position);
                        position[pos.position - 1] = true;
                        this.setState({ togglePositions: position });
                    })
                    const posToBeAdded = totalPos.filter(x => !availablePos.includes(x));
                    if (posToBeAdded.length > 0) {
                        for (let i = 0; i < posToBeAdded.length; i++) {
                            let posObj = {}
                            posObj.region = "";
                            posObj.substation = "";
                            posObj.feeder = "";
                            posObj.site = "";
                            posObj.lateral = "";
                            posObj.conductor1Phase = "";
                            posObj.conductor2Phase = "";
                            posObj.conductor3Phase = "";
                            posObj.position = posToBeAdded[i];
                            posObj.switchDisabled = true;
                            posObj.description = "";
                            obj.positions.push(posObj);
                        }
                        obj.positions.sort((a, b) => a.position - b.position)
                    }
                }
            }
            this.setState({ selectedValues: obj }, async () => {
                this.state.selectedValues?.positions?.forEach(async (pos) => {
                    let routeParams = [];
                    if (this.state.selectedValues.substation) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.props.rowData.region, type: "REGION" }, "SUBSTATION", { name: this.props.rowData.substation, type: "SUBSTATION" }];
                        await this.setUm3ListOnLoad(routeParams, (pos.position - 1))
                    }
                    if (pos.feeder) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.props.rowData.region, type: "REGION" }, "SUBSTATION", { name: this.props.rowData.substation, type: "SUBSTATION" }, "FEEDER", { name: pos.feeder, type: "FEEDER" }];
                        await this.setUm3ListOnLoad(routeParams, (pos.position - 1))
                    }
                    if (pos.lateral) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.props.rowData.region, type: "REGION" }, "SUBSTATION", { name: this.props.rowData.substation, type: "SUBSTATION" }, "FEEDER", { name: pos.feeder, type: "FEEDER" }, "LATERAL", { name: pos.lateral, type: "LATERAL" }];
                        await this.setUm3ListOnLoad(routeParams, (pos.position - 1))
                    }
                });
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ( Object.keys(prevState.selectedValues).length && JSON.stringify(prevState.selectedValues) !== JSON.stringify(this.state.selectedValues)) {
            this.setState({ isLoading: false });
        }
    }

    //Reset the dropdown fields on Load
    resetDeviceDropdown = () => {
        formFields.filter(field => field.isDisabled ? field.isDisabled = false : "");
        this.setState({ selectedValues: {} })
    }

    setUm3ListOnLoad = async (routeParams, position) => {
        let dropdownData = await getNodes(routeParams);
        dropdownData = dropdownData && dropdownData.length ? dropdownData : [];
        if (dropdownData.length > 0) {
            dropdownData = dropdownData.sort(compareValues("name"));
            let tempOptions = [{ label: "--select--", value: "--select--" }];
            dropdownData.forEach(item => {
                tempOptions.push({ label: item.name, value: item.name, type: item.type, siteDeviceType: item.siteDeviceType })
            })
            let tempAllFieldsData = { ...this.state.selectedValues }
            let backupsite = { ...this.state.backupSiteOptions };

            tempAllFieldsData.positions[position].feederOptions = tempAllFieldsData.positions[position]?.feederOptions?.length > 1 ? tempAllFieldsData.positions[position].feederOptions : [{ label: "--select--", value: "--select--" }, ...tempOptions.filter(e => e.type === "FEEDER")]
            tempAllFieldsData.positions[position].lateralOptions = tempAllFieldsData.positions[position]?.lateralOptions?.length > 1 ? tempAllFieldsData.positions[position].lateralOptions : [{ label: "--select--", value: "--select--" }, ...tempOptions.filter(e => e.type === "LATERAL")]
            tempAllFieldsData.positions[position].siteOptions = [{ label: "--select--", value: "--select--" }, ...tempOptions.filter(e => (e.type === "SITE" || e.type === "LATERAL_SITE") && (this.props.rowData.deviceType === e.siteDeviceType || e.siteDeviceType === null))]
            backupsite["position" + position] = tempAllFieldsData.positions[position].siteOptions;
            this.setState({ selectedValues: tempAllFieldsData, backupSiteOptions: backupsite }, () => { this.UpdateSiteData(); });
        }
    }

    apiRequestNodeDropdown = async (routeParams, position) => {
        let dropdownData = await getNodes(routeParams);
        dropdownData = dropdownData && dropdownData.length ? dropdownData : [];
        this.resetNodeDropdown(routeParams[routeParams.length - 1]);
        if (dropdownData.length > 0) {
            dropdownData = dropdownData.sort(compareValues("name"));
            
            //filtering all the 'mm3', 'mm3ai' and 'null' site type data which will display in site lebel dropdown field for only mm3/mm3ai device type.
            if(["MM3","MM3ai"].includes(this.props.rowData.deviceType)){
                //typeval is set to true if the dropdown data consists type site
                let typeVal= dropdownData.some(x=>x.type==="SITE");
                //removing latreal type from the dropdown data and adding only mm3,mm3ai, null sites to the dropdown data
                if(typeVal) dropdownData = dropdownData.filter((s)=> ["MM3","MM3ai", null].includes(s.siteDeviceType)&&s.type!=="LATERAL")
            }

            dropdownData.forEach(item => {
                if(!["MM3","MM3ai"].includes(this.props.rowData.deviceType)){   // if not a MM3 or MM3ai site
                    formFields.filter(field => (field.key === item.type.toLowerCase() || (field.key === "site" && item.type === "LATERAL_SITE")) ? (item.type === "SITE" || item.type === "LATERAL_SITE") && this.props.rowData.deviceType !== item.siteDeviceType && item.siteDeviceType !== null ? "" : (field.options.length > 0 ? field.options.push({ label: item.name, value: item.name }) : field.options.push({ label: "--select--", value: "--select--" }, { label: item.name, value: item.name })) : "");
                } else { //for mm3 and mm3ai site, need to display both(MM3, MM3ai, null) site data in site lebel dropdown field
                    formFields.filter(field => (field.key === item.type.toLowerCase()) ? (field.options.length > 0 ? field.options.push({ label: item.name, value: item.name }) : field.options.push({ label: "--select--", value: "--select--" }, { label: item.name, value: item.name })) : "");
                }
            });
            
            if (this.props.rowData.deviceType === "UM3+") {
                this.um3PositionOptionsList(routeParams, position, dropdownData);
            }
        }
    }

    um3PositionOptionsList = (routeParams, position, dropdownData) => {
        let tempOptions = [{ label: "--select--", value: "--select--" }];
        dropdownData.forEach(item => {
            tempOptions.push({ label: item.name, value: item.name, type: item.type, siteDeviceType: item.siteDeviceType })
        })
        let tempAllFieldsData = { ...this.state.selectedValues }
        let backupsite = { ...this.state.backupSiteOptions };
        switch (routeParams[routeParams.length - 2]) {
            case 'SUBSTATION':
                tempAllFieldsData?.positions?.forEach(e => {
                    e.feederOptions = tempOptions;
                    e.lateralOptions = [{ label: "--select--", value: "--select--" }];
                    e.siteOptions = [{ label: "--select--", value: "--select--" }];
                });
                break;
            case 'FEEDER':
                if (tempAllFieldsData.positions) {
                    let tempfeeder = [{ label: "--select--", value: "--select--" }];
                    routeParams[routeParams.indexOf("FEEDER") - 1].children?.forEach(e => tempfeeder.push({ "label": e.name, "value": e.name }));
                    tempAllFieldsData.positions[position].feederOptions = tempAllFieldsData.positions[position].feederOptions ? tempAllFieldsData.positions[position].feederOptions : tempfeeder;
                    tempAllFieldsData.positions[position].lateralOptions = [{ label: "--select--", value: "--select--" }, ...tempOptions.filter(e => e.type === "LATERAL")]
                    tempAllFieldsData.positions[position].siteOptions = [{ label: "--select--", value: "--select--" }, ...tempOptions.filter(e => (e.type === "SITE" || e.type === "LATERAL_SITE") && (this.props.rowData.deviceType === e.siteDeviceType || e.siteDeviceType === null))]
                    backupsite["position" + position] = tempAllFieldsData.positions[position].siteOptions;
                }
                break;
            case 'LATERAL':
                if (tempAllFieldsData.positions) {
                    let tempfeeder = [{ label: "--select--", value: "--select--" }];
                    let templateral = [{ label: "--select--", value: "--select--" }];
                    routeParams[routeParams.indexOf("FEEDER") - 1].children?.forEach(e => tempfeeder.push({ "label": e.name, "value": e.name }));
                    tempAllFieldsData.positions[position].feederOptions = tempAllFieldsData.positions[position].feederOptions ? tempAllFieldsData.positions[position].feederOptions : tempfeeder;
                    routeParams[routeParams.indexOf("LATERAL") - 1].children?.forEach(e => e.type === "LATERAL" && templateral.push({ "label": e.name, "value": e.name }));
                    tempAllFieldsData.positions[position].lateralOptions = tempAllFieldsData.positions[position].lateralOptions ? tempAllFieldsData.positions[position].lateralOptions : templateral;
                    tempAllFieldsData.positions[position].siteOptions = [{ label: "--select--", value: "--select--" }, ...tempOptions.filter(e => (e.type === "SITE" || e.type === "LATERAL_SITE") && (this.props.rowData.deviceType === e.siteDeviceType || e.siteDeviceType === null))]
                    backupsite["position" + position] = tempAllFieldsData.positions[position].siteOptions;
                }
                break;
            default:
                break;
        }
        this.setState({ selectedValues: tempAllFieldsData, backupSiteOptions: backupsite }, () => { this.UpdateSiteData(); });
    }

    inputChangeHandler = (e, key) => {
        let value = e.target.value;
        this.setState({ selectedValues: { ...this.state.selectedValues, ...{ [key]: value } }, isDataModified: true });
    }

    ddlValueUpdate = async (e, key) => {
        let value = e !== "--select--" ? e : "";
        let routeParams = [];
        this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { [key]: value }), isDataModified: true });
        switch (key) {
            case "region":
                if (value && value.length !== 0) {
                    routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: value, type: "REGION" }];
                    await this.apiRequestNodeDropdown(routeParams);
                    if (this.state.selectedValues.deviceType === "UM3+") {
                        const positionsList = this.state.selectedValues;
                        positionsList.positions.forEach(pos => {
                            pos.region = value;
                            pos.substation = "";
                            pos.feeder = "";
                            pos.lateral = "";
                            pos.site = "";
                            delete pos.feederOptions;
                            delete pos.lateralOptions;
                            delete pos.siteOptions;
                        });
                        this.setState({ selectedValues: positionsList });
                    }
                    this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "substation": "", "feeder": "", "site": "" }), isDataModified: true });
                } else {
                    this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "region": "", "substation": "", "feeder": "", "site": "" }), isDataModified: true }, () => {
                        this.resetNodeDropdown({ type: "REGION" })
                    });
                }
                break;
            case "substation":
                if (value && value.length !== 0) {
                    routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.selectedValues.region, type: "REGION" }, "SUBSTATION", { name: value, type: "SUBSTATION" }];
                    await this.apiRequestNodeDropdown(routeParams);
                    if (this.state.selectedValues.deviceType === "UM3+") {
                        const positionsList = this.state.selectedValues;
                        positionsList.positions.forEach(pos => {
                            pos.substation = value;
                            pos.feeder = "";
                            pos.lateral = "";
                            pos.site = "";
                            delete pos.lateralOptions;
                            delete pos.siteOptions;
                        });
                        this.setState({ selectedValues: positionsList });
                    }
                    this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "feeder": "", "site": "" }), isDataModified: true });
                } else {
                    this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "substation": "", "feeder": "", "site": "" }), isDataModified: true }, () => {
                        this.resetNodeDropdown({ type: "SUBSTATION" })
                    });

                }
                break;
            case "feeder":
                if (value && value.length !== 0) {
                    routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.selectedValues.region, type: "REGION" }, "SUBSTATION", { name: this.state.selectedValues.substation, type: "SUBSTATION" }, "FEEDER", { name: value, type: "FEEDER" }];
                    await this.apiRequestNodeDropdown(routeParams);
                    this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "site": "", "lateral": "" }), isDataModified: true });
                } else {
                    this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "feeder": "", "site": "" }), isDataModified: true }, () => {
                        this.resetNodeDropdown({ type: "FEEDER" })
                    });
                }
                break;
            case "lateral":
                if (value && value.length !== 0) {
                    routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.selectedValues.region, type: "REGION" }, "SUBSTATION", { name: this.state.selectedValues.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.selectedValues.feeder, type: "FEEDER" }, "LATERAL", { name: value, type: "LATERAL" }];
                    await this.apiRequestNodeDropdown(routeParams);
                    this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "site": "" }), isDataModified: true });
                } else {
                    routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.selectedValues.region, type: "REGION" }, "SUBSTATION", { name: this.state.selectedValues.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.selectedValues.feeder, type: "FEEDER" }];
                    if (this.state.selectedValues.feeder && this.state.selectedValues.feeder !== "") {
                        await this.apiRequestNodeDropdown(routeParams);
                        this.resetNodeDropdown({ type: "LATERAL" })
                    }
                }
                break;
            default:
                break;
        }
    }

    //Reset the dropdown data once the selection is changed 
    resetNodeDropdown = (item) => {
        switch (item.type) {
            case 'REGION':
                this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "substation": "", "feeder": "", "lateral": "", "site": "" }) });
                formFields.filter(field => field.key === "substation" || field.key === "feeder" || field.key === "lateral" || field.key === "site" ? field.options = [{ label: "--select--", value: "--select--" }] : "");
                if (this.state.selectedValues.deviceType === "UM3+") {
                    const positionsList = this.state.selectedValues;
                    positionsList.positions.forEach(pos => {
                        pos.substation = "";
                        pos.feeder = "";
                        pos.lateral = "";
                        pos.site = "";
                        delete pos.feederOptions;
                        delete pos.lateralOptions;
                        delete pos.siteOptions;
                    });
                    this.setState({ selectedValues: positionsList });
                }
                break;
            case 'SUBSTATION':
                this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "feeder": "", "lateral": "", "site": "" }) });
                formFields.filter(field => field.key === "feeder" || field.key === "lateral" || field.key === "site" ? field.options = [{ label: "--select--", value: "--select--" }] : "");
                if (this.state.selectedValues.deviceType === "UM3+") {
                    const positionsList = this.state.selectedValues;
                    positionsList.positions.forEach(pos => {
                        pos.feeder = "";
                        pos.lateral = "";
                        pos.site = "";
                        delete pos.feederOptions;
                        delete pos.lateralOptions;
                        delete pos.siteOptions;
                    });
                    this.setState({ selectedValues: positionsList });
                }
                break;
            case 'FEEDER':
                this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "lateral": "", "site": "" }) });
                formFields.filter(field => field.key === "lateral" || field.key === "site" ? field.options = [{ label: "--select--", value: "--select--" }] : "");
                break;
            case 'LATERAL':
                this.setState({ selectedValues: Object.assign({}, this.state.selectedValues, { "site": "" }) });
                formFields.filter(field => field.key === "site" ? field.options = [{ label: "--select--", value: "--select--" }] : "");
                break;
            default:
                break;
        }
    }

    ddlValuePosUpdate = async (e, key, position) => {
        const value = e !== "--select--" ? e : "";
        let routeParams = [];
        const tempState = { ...this.state.selectedValues };
        let backupsite = { ...this.state.backupSiteOptions };
        tempState.positions[position - 1][key] = value;
        tempState[key] = value;

        switch (key) {
            case "feeder":
                tempState.positions[position-1]["lateral"] = "";
                tempState.positions[position-1]["site"] = "";
                delete tempState.positions[position-1].lateralOptions
                delete tempState.positions[position-1].siteOptions
                if (!value) {
                    tempState["lateral"] = "";
                    tempState["site"] = "";
                    backupsite["position" + (position - 1)] = [{ label: "--select--", value: "--select--" }];
                }
                break;
            case "lateral":
                tempState.positions[position-1]["site"] = "";
                tempState.positions[position-1]["lateral"] = value;
                delete tempState.positions[position-1].siteOptions
                if (!value) {
                    tempState["lateral"] = "";
                }
                break;
            default:
                break;
        }

        this.setState({ selectedValues: tempState, backupSiteOptions: backupsite }, async () => {
            switch (key) {
                case "feeder":
                    if (value && value.length !== 0) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.selectedValues.region, type: "REGION" }, "SUBSTATION", { name: this.state.selectedValues.substation, type: "SUBSTATION" }, "FEEDER", { name: value, type: "FEEDER" }];
                        await this.apiRequestNodeDropdown(routeParams, (position - 1));
                        this.setState({ allFieldsData: Object.assign({}, this.state.selectedValues, { "site": "", "lateral": "" }) });
                    } else {
                        this.UpdateSiteData();
                        this.resetNodeDropdown({ type: "FEEDER" })
                    }
                    break;
                case "lateral":
                    if (value && value.length !== 0) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.selectedValues.region, type: "REGION" }, "SUBSTATION", { name: this.state.selectedValues.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.selectedValues.positions[(position - 1)].feeder, type: "FEEDER" }, "LATERAL", { name: value, type: "LATERAL" }];
                        await this.apiRequestNodeDropdown(routeParams, (position - 1));
                        this.setState({ allFieldsData: Object.assign({}, this.state.selectedValues, { "site": "" }) });
                    } else {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.selectedValues.region, type: "REGION" }, "SUBSTATION", { name: this.state.selectedValues.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.selectedValues.positions[(position - 1)].feeder, type: "FEEDER" }];
                        if (this.state.selectedValues.feeder && this.state.selectedValues.feeder !== "") {
                            await this.apiRequestNodeDropdown(routeParams, (position - 1));
                            this.resetNodeDropdown({ type: "LATERAL" })
                        }
                    }
                    break;
                case "site":
                    if (this.state.selectedValues.positions[(position - 1)].lateral) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.selectedValues.region, type: "REGION" }, "SUBSTATION", { name: this.state.selectedValues.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.selectedValues.positions[(position - 1)].feeder, type: "FEEDER" }, "LATERAL", { name: this.state.selectedValues.positions[(position - 1)].lateral, type: "LATERAL" }];
                        await this.apiRequestNodeDropdown(routeParams, (position - 1));
                    } else if (this.state.selectedValues.positions[(position - 1)].feeder) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.selectedValues.region, type: "REGION" }, "SUBSTATION", { name: this.state.selectedValues.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.selectedValues.positions[(position - 1)].feeder, type: "FEEDER" }];
                        await this.apiRequestNodeDropdown(routeParams, (position - 1));
                    }
                    break;
                default:
                    break;
            }
        });
    }

    UpdateSiteData = () => {
        let backupsite = { ... this.state.backupSiteOptions }
        let allfielddata = { ...this.state.selectedValues }

        allfielddata?.positions?.forEach((item, key) => {
            item.siteOptions = backupsite["position" + key];
        })

        if (allfielddata.hasOwnProperty("positions") && allfielddata.positions.length > 1) {
            const feederData = [];
            const positionFeederData = [];
            allfielddata.positions.map(item => item.feeder && positionFeederData.push(item.feeder));
            const similarData = positionFeederData.filter((item, index) => positionFeederData.indexOf(item) !== index);
            allfielddata.positions.forEach(item => {
               feederData.push({ "feeder": item.feeder, "lateral": item.lateral, "site": item.site })
            })
            similarData.forEach((item) => {
                let sitedata = feederData.filter(item1 => item1.feeder === item).map(e => e.site)
                feederData.forEach((item1, key) => {
                    if (item1.feeder === item) {
                        if (allfielddata.positions[key] && allfielddata.positions[key].siteOptions && allfielddata.positions[key].siteOptions.length) {
                            allfielddata.positions[key].siteOptions = allfielddata.positions[key].siteOptions.filter(e => {
                                return !sitedata.includes(e.value) || (sitedata.includes(e.value) && item1.site === e.value)
                            })
                        }
                    }
                })
            })
            this.setState({ allFieldsData: allfielddata });
        }
    }

    hideErrorMsg = () => {
        this.setState({ errorMessage: "", warningMessage: "" });
    }

    //Validating the positions for UM3+ devices
    validateUM3conductors = (obj) => {
        const result = [];
        let duplicatePhase = [];
        obj.positions && obj.positions.length && obj.positions.forEach((element) => {
            const data = [element.conductor1Phase, element.conductor2Phase, element.conductor3Phase];
            duplicatePhase = data.filter((item, index) => { return item && data.indexOf(item) !== index; });
            const isPresent = duplicatePhase && duplicatePhase.length > 0 && result && result.length > 0 && result.includes(duplicatePhase[0]);
            if (!isPresent) {
                duplicatePhase && duplicatePhase.length && result.push(duplicatePhase[0])
            }
        });
        return result;
    }

    /* collapse or expand um3 positions */
    toggleUm3Positions = (index) => {
        const position = [...this.state.togglePositions];
        position[index] = !position[index];
        this.setState({ togglePositions: position });
    }

    // On Click of Update button
    updateData = async () => {
        this.setState({ isLoading : true })
        const deviceDetails = this.state.selectedValues;
        deviceDetails.mcpDevice = this.props.rowData.mcpDevice;
        const disablePos = [];
        let removedPosData = [];
        if (deviceDetails.deviceType === "UM3+" && deviceDetails.positions.length > 0) {
            deviceDetails.positions.filter(x => disablePos.push(x.switchDisabled === true ? x.switchDisabled : false))
        }
        let infoObj = null;
        if (this.inputValid(deviceDetails)) {
            infoObj = <FormattedMessage id={"gm.form.message.mandatory"} />;
        } else if ((deviceDetails.deviceType === 'UM3+') && (this.CheckConductorExists(deviceDetails))) {
            infoObj = <FormattedMessage id={"gm.form.message.mandatory.conductor"} />;
        } else if ((deviceDetails.deviceType === 'UM3+') && !disablePos.includes(false)) {
            infoObj = <FormattedMessage id="gm.form.message.mandatory.position" />;
        } else if ((deviceDetails.deviceType === 'UM3+') && (this.validateUM3conductors(deviceDetails).length > 0)) {
            infoObj = "Phase <" + this.validateUM3conductors(deviceDetails) + "> cannot be linked to more than one conductor, please correct and try again.";
        } else {
            if (deviceDetails.deviceType === "UM3+") {
                removedPosData = { ...deviceDetails };
                deviceDetails.positions = deviceDetails.positions.filter(x => !x.switchDisabled);
                deviceDetails.positions.forEach(pos => {
                    pos.region = deviceDetails["region"];
                    pos.substation = deviceDetails["substation"];
                    delete pos["switchDisabled"];
                });
                this.state.disabledPositions.forEach(disPos => {
                    deviceDetails.positions.forEach((detail, i) => {
                        if (disPos.position === detail.position) {
                            deviceDetails.positions.splice(i, 1)
                        }
                    })
                })
                this.setState({ selectedValues: deviceDetails, removedPosData: removedPosData }, () => {
                    const arr = ["conductor1Phase", "conductor2Phase", "conductor3Phase", "feeder", "lateral", "site", "phase"]
                    arr.forEach(field => delete deviceDetails[field]);
                });
            }
            if(deviceDetails.deviceType==="ZM1"){
                deviceDetails.lateralSite = deviceDetails.lateral ? deviceDetails.site : "";
                deviceDetails.site = deviceDetails.lateral ? "" : deviceDetails.site;
            }
            await this.moveDeviceAPIRequest(deviceDetails, this.props.orgId, this.props.rowData.id);
        }
        return infoObj !== null ? this.setState({ errorMessage: infoObj, isLoading: false }) : "";
    }

    moveDeviceAPIRequest = async (deviceDetails, orgId, id) => {
        const data = await moveDevice(orgId, id, deviceDetails);
        if (data.status === "FAIL") {
            if (deviceDetails.deviceType === "UM3+") {
                const dataToAppend = this.state.removedPosData;
                dataToAppend.positions.sort((a, b) => a.position - b.position)
                this.setState({ selectedValues: dataToAppend })
            }
            this.setState({ errorMessage: data.message, isLoading: false });
        } else if (data.status === "WARN") {
            if (deviceDetails.deviceType === "UM3+") {
                const dataToAppend = this.state.removedPosData;
                dataToAppend.positions.sort((a, b) => a.position - b.position)
                this.setState({ selectedValues: dataToAppend })
            }
            this.setState({ warningMessage: data.message, isLoading: false });
        } else {
            this.props.onCloseModal()
            this.setState({ selectedValues: {}, isLoading: false });
            if (Object.keys(this.props.selectedNode).length > 0) {
                let searchData = this.updateSearchData( this.state.selectedValues.deviceType ==="UM3+" && !this.props.rowData.mcpDevice ? this.state.allFieldsData : this.state.selectedValues); //preparing search data
                await this.context.selectSearchedNode(searchData, true) //calling auto search api here
                this.context.skipRightSideApiCall(true, searchData.name);   //skipping unnessesary right side api call while moving device
            }
            window.scrollTo(0,0);
            ampleStore.dispatch(showAlertToaster(<div><strong>{this.props.rowData.serialNumber} </strong> <FormattedMessage id='groupmanagement.success.movedevice'/></div>,'success'))

        }
    }

    updateSearchData=(selectedValues)=>{

        let data = {
            id: null,
            name: '',
            type: "",
            regionName:selectedValues.region,
            substationName:selectedValues.substation,
            feederName: '',
            siteName: '',
            lateralName: '',
            lateralSiteName: null
        }

        switch (selectedValues.deviceType) {
            case "MM3ai":
            case "MM3":
                return data = {...data, name: selectedValues.site, type: 'SITE', feederName: selectedValues.feeder, siteName: selectedValues.site };
            case "UM1":
            case "ZM1":
                if(selectedValues.lateral){ //if lateral(optional) value selected, then need to select 'Lateral' node.
                    return data = {...data, name: selectedValues.lateral, type: 'LATERAL', feederName: selectedValues.feeder, siteName: selectedValues.site, lateralName: selectedValues.lateral, lateralSiteName:selectedValues.site }
                } else {    // lateral(optional) not selected but 'Site'(mandatory) selected, then need to select 'Site' node.
                    return data={...data, name: selectedValues.site, type:'SITE', feederName:selectedValues.feeder, siteName:selectedValues.site }
                }
            case "UM3+":
                let selectedPosition = selectedValues?.positions.filter(item=> item.site);  //preparing all the selected Position data. (we have max 4 positions)
                if( selectedPosition.length > 1 ) { //if multiple positions selected
                    let findUniq = selectedPosition.filter((item,index,arr)=>arr.findIndex(value=>(value.feeder===item.feeder)) === index); //finding all the selected uniq feeder.
                    if(findUniq.length >1){ //if multiple feeder selected, then node selection should be 'substation'
                        return data={...data, name:selectedValues.substation, type: 'SUBSTATION' }
                    } else {    //if only one feeder selected for all the selected positions, then need to select 'Feeder' node.
                        if(findUniq?.[0].lateral){
                        let uniqLat = selectedPosition.filter((item, index, arr) => arr.findIndex(value => (value.lateral === item.lateral)) === index)
                        if (uniqLat.length > 1) {
                            return data = { ...data, name: selectedValues?.positions[selectedPosition[0].position - 1]?.feeder, type: 'FEEDER', feederName: selectedValues?.positions[selectedPosition[0].position - 1]?.feeder}
                        } else
                        return data={...data, name:selectedValues?.positions[selectedPosition[0].position - 1]?.lateral, type: 'LATERAL', feederName: selectedValues?.positions[selectedPosition[0].position - 1]?.feeder,lateralName: selectedValues?.positions[selectedPosition[0].position - 1]?.lateral}
                        }else
                        return data = { ...data, name: selectedValues?.positions[selectedPosition[0].position - 1]?.feeder, type: 'FEEDER', feederName: selectedValues?.positions[selectedPosition[0].position - 1]?.feeder}
                    }
                } else {    //if only one position selected
                    if(selectedValues?.positions[selectedPosition[0].position - 1]?.lateral) { //if Lateral(optional) value is selected, then need to select 'Lateral' node point.
                        return data={...data, name: selectedValues?.positions[selectedPosition[0].position - 1]?.lateral, type: 'LATERAL', feederName: selectedValues?.positions[selectedPosition[0].position - 1]?.feeder, siteName: selectedValues?.positions[selectedPosition[0].position - 1]?.site, lateralName: selectedValues?.positions[selectedPosition[0].position - 1]?.lateral,lateralSiteName:selectedValues?.positions[selectedPosition[0].position - 1]?.site}
                    } else {    //if Lateral(optional) is not selected and 'Site' is selected, then need to select 'Site' node.
                        return data={...data, name:selectedValues?.positions[selectedPosition[0].position - 1].site, type:'SITE', feederName: selectedValues?.positions[selectedPosition[0].position - 1]?.feeder, siteName:selectedValues?.positions[selectedPosition[0].position - 1]?.site}
                    }
                    
                }
            default:
                break;
        }
    }


    inputValid = (obj) => {
        let isInputValid = false;
        this.state.moveDeviceFields.forEach(field => {
            if (field.isMandatory) {
                if (!obj[field.key])
                    isInputValid = true;
                else if (obj[field.key] && obj[field.key] === "")
                    isInputValid = true;
            }
        });
        if (obj.deviceType === "UM3+") {
            this.state.um3DeviceFields.forEach(field => {
                obj.positions.forEach(pos => {
                    if (field.isMandatory && !pos.switchDisabled) {
                        if (!pos[field.key])
                            isInputValid = true;
                        else if (pos[field.key] && pos[field.key] === "")
                            isInputValid = true;
                    }
                })
            })
        }
        return isInputValid;
    }

    /* Check atleast one phase exist for each position when adding devices */
    CheckConductorExists = (obj) => {
        let result = false;
        obj.positions && obj.positions.length && obj.positions.forEach(value => {
            if (!value.switchDisabled && value.conductor1Phase === "" && value.conductor2Phase === "" && value.conductor3Phase === "") {
                result = true;
            }
        });
        return result;
    };

    onSwitchChangeHandler = (e, position) => {
        const tempState = this.state.selectedValues;
        if (!tempState.positions[position]["switchDisabled"]) {
            tempState.positions[position]["switchDisabled"] = true;
            this.disablePositions(position, true);
        } else {
            tempState.positions[position]["switchDisabled"] = false;
            this.disablePositions(position, false);
        }
        this.setState({ selectedValues: tempState });
    }

    disablePositions = (position, isDisabled) => {
        let disPos = this.state.disabledPositions;
        let dataToUpdate = this.state.selectedValues;
        if (isDisabled) {
            const dataToBeDisabled = this.state.selectedValues.positions && this.state.selectedValues.positions[position] ? this.state.selectedValues.positions[position] : "";
            disPos.push({ ...dataToBeDisabled });
            Object.keys(dataToUpdate.positions[position]).forEach(key => {
                if (key !== "switchDisabled") {
                    dataToUpdate.positions[position][key] = dataToUpdate.positions[position]["position"] !== dataToUpdate.positions[position][key] ? "" : dataToUpdate.positions[position][key];
                }
            });
            dataToUpdate.positions[position]["switchDisabled"] = true;
        }
        else {
            disPos.forEach(dPos => {
                dataToUpdate.positions.forEach(pos => {
                    if (dPos.feeder === pos.feeder && dPos.site === pos.site) {
                        dPos.site = "";
                    }
                });
                if (dPos.position === position + 1) {
                    Object.keys(dataToUpdate.positions[position]).forEach(key => {
                        if (key === "switchDisabled") {
                            dataToUpdate.positions[position][key] = !dPos[key]
                        } else {
                            dataToUpdate.positions[position][key] = dPos[key]
                        }
                    });
                }
            })
            const index = disPos.findIndex(pos => pos.position === position + 1);
            if (index !== -1) {
                disPos.splice(index, 1);
            }
        }
        this.setState({ disabledPositions: disPos, selectedValues: dataToUpdate },()=>this.UpdateSiteData());
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
        return btnName === "update" ? this.updateData() : null;
    }

    render() {
        const displayPositionList = this.props.rowData.deviceType === "UM3+" ?
            this.state.selectedValues.positions && this.state.selectedValues.positions.map((position, index) => {
                return (
                    <div key={index}>
                        {/* <div>{`----------------------------------- Position - ${position.position} ----------------------------------- `}{!position.switchDisabled ? <label className="switch float-right"><input type="checkbox" defaultChecked={true} onChange={(e) => this.onSwitchChangeHandler(e, index)} id={"customSwitch" + index} /><span className="slider"></span></label> : <label className="switch float-right"><input type="checkbox" defaultChecked={false} onChange={(e) => this.onSwitchChangeHandler(e, index)} id={"customSwitch" + index} /><span className="slider"></span></label>}</div> */}
                        <div className="mb-5 multi-position">
                            <span onClick={() => this.toggleUm3Positions(index)} className={this.state.togglePositions[index] ? "fas fa-chevron-down multi-position-chevron" : "fas fa-chevron-right multi-position-chevron"} ></span>
                            <span onClick={() => this.toggleUm3Positions(index)} className="multi-position-heading">Position - {position.position}</span>
                            {this.props.rowData.mcpDevice ? null : !position.switchDisabled ? <div className="switch float-right  multi-position-switch"><ToggleSwitch toggleSwitchValue={(e) => this.onSwitchChangeHandler(e, index)} value={true} leftValue="On" rightValue="Off" /></div> : <div className="switch float-right"><ToggleSwitch toggleSwitchValue={(e) => this.onSwitchChangeHandler(e, index)} value={false} leftValue="On" rightValue="Off" /></div>}
                        </div>
                        {this.state.togglePositions[index] ? <div className={position.switchDisabled ? "ptr-none" : ""}>
                            {this.state.um3DeviceFields.map((field, i) => {
                                let label, template;
                                label = <FormattedMessage id={`groupmanagement.label.${field.key}`} />;
                                if (field.type === "select") {
                                    if (field.key === "feeder" || field.key === "lateral" || field.key === "site") {
                                        const temp = this.state.selectedValues.positions && this.state.selectedValues.positions[index] && this.state.selectedValues.positions[index][field.key + "Options"];
                                        template = <Fragment>
                                            <SingleSelect disabled={field.isDisabled} data={temp ? temp : [{ label: "--select--", value: "--select--" }]} setType={(e) => this.ddlValuePosUpdate(e, field.key, position.position)} value={this.state.selectedValues.positions && this.state.selectedValues.positions[index] && this.state.selectedValues.positions[index][field.key] ? this.state.selectedValues.positions[index][field.key] : "--select--"} icon='ok' />
                                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                        </Fragment>
                                    } else {
                                        template = <Fragment>
                                            <SingleSelect disabled={field.isDisabled} data={field.options} setType={(e) => this.ddlValuePosUpdate(e, field.key, position.position)} value={this.state.selectedValues.positions && this.state.selectedValues.positions[index][field.key] ? this.state.selectedValues.positions[index][field.key] : "--select--"} icon='ok' />
                                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                        </Fragment>
                                    }
                                }
                                let data = label && template ?
                                    <FormGroup key={field.key} className="clearfix">
                                        <Col sm={4} xs={4} className="float-left mb-2 m-0 p-0">{label}{field.isMandatory ? "*" : ""}</Col>
                                        <Col sm={8} xs={8} className="float-left mb-2">{template}</Col>
                                    </FormGroup> : ""
                                return (data)
                            })
                            }
                        </div> : ""}
                    </div>
                )
            })
            : "";
        const modalTitle = <div className="popup-title"><FormattedMessage id={`moveDevice.popup.title`} />:<span className="text-bolder"> {`${this.props.serialNumber}`} {this.props.rowData.deviceType === "UM3+" ? <span>({`${this.props.rowData.positions.length}`})</span> : ""}</span> </div>;
        const modalBody = <div className="popup-height min-vh-25 single-select-dropdown">
            {this.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
            <Form>
                {this.state.errorMessage && <><AlertMessage type={'danger'} message={this.state.errorMessage} timer={10000} setVisible={() => this.hideErrorMsg()} position={'w-100'} /><br /></>}
                {this.state.warningMessage && <><AlertMessage type={'warning'} message={this.state.warningMessage} timer={10000} setVisible={() => this.hideErrorMsg()} position={'w-100'} /><br /></>}
                    <div className={"scroll-modalbody"}>
                    <div>
                    {this.state.moveDeviceFields.map((field, i) => {
                        let label, template;
                        label = <FormattedMessage id={`groupmanagement.label.${field.key}`} />;
                        if (field.type === "select") {
                            template = <Fragment>
                                <SingleSelect disabled={field.isDisabled} data={field.options} setType={(e) => this.ddlValueUpdate(e, field.key)} value={this.state.selectedValues[field.key] ? this.state.selectedValues[field.key] : "--select--"} icon='ok' />
                                <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                            </Fragment>
                        }
                        else if (field.type === "input") {
                            template = <FormControl id={field.key + "_" + i} type="text" label="Text" defaultValue={this.state.selectedValues[field.key]} className="inpFlSz" onBlur={(e) => this.inputChangeHandler(e, field.key)} />
                        }
                        else if (field.type === "textarea") {
                            template = <textarea id={field.key + "_" + i} rows="3" value={this.state.selectedValues[field.key]} className="inpFlSz form-control" onChange={(e) => this.inputChangeHandler(e, field.key)} />
                        }
                        let data = label && template ?
                            <FormGroup key={field.key} className="clearfix">
                                <Col sm={4} xs={4} className="float-left mb-2 m-0 p-0">{label}{field.isMandatory ? "*" : ""}</Col>
                                <Col sm={8} xs={8} className="float-left mb-2">{template}</Col>
                            </FormGroup> : ""
                        return (data)
                    })
                    }
                </div>
                <div>{displayPositionList}</div>
                </div>
            </Form>
        </div>
        const modalFooter = [{ className: "required-message", name: <FormattedMessage id={`db.common.requiredFields`} />, leftContent: true }, { className: "modalSaveBtn", disabled: this.state.isLoading, name: <FormattedMessage id={`editDevice.popup.button.update`} />, value: "update" }];
        return (
            <Fragment>
                {this.props.isModalOpen ?
                    <ModalWindow
                        show={this.props.isModalOpen}
                        onHide={() => this.props.onCloseModal()}
                        size={"md"}
                        dialogClassName='moveModal'
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        );
    }
}

export default MoveDeviceComponent;
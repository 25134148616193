import React, { Component, Fragment } from 'react';
import MultiSelectDropdown from '../../../manage/details/components/multiselectDropdownComponent';
import { Row, Col } from 'react-bootstrap';
import { RegisterContext } from '../provider/registerProvider';
import { registerData } from '../../../../utility/faultAnalysisUtility';
import TextField from '../../../../hoc/form/textField';
import FilterWithBadge from './filterWithBadge';

class CustomFilter extends Component {
    static contextType = RegisterContext;

    disableResetFilter = function () {
        if ((this.context.state.totalBadgeCount === 1) && JSON.stringify(registerData.selectedData.faultType) === JSON.stringify((registerData.faultTypeList).slice(0, 3))) {
            return true;
        } else {
            return false;
        }
    };
    render() {
        const { filterFormOptionData, formData, formErr } = this.context.state;
        return (
            <Fragment>
                <FilterWithBadge
                    dropDownContent={
                        registerData.customFilterColumns.map((item, index) => {
                            return (
                                <Row key={index} className="mb-2">
                                    <Col xs={5}>{item.label}</Col>
                                    <Col xs={7}>
                                        {item.type === 'multiSelect' ?
                                            <MultiSelectDropdown
                                                selectedArrayList={formData[item.fName]}
                                                optionList={filterFormOptionData[item.fName]}
                                                onChange={this.context.onChangeHandler}
                                                name={item.fName}
                                                multiple={true}
                                                includeSelectAll={false}
                                                includeFilter={false}
                                                open={false}
                                                valueKey="value"
                                                labelKey="text"
                                                keepOpen={true}
                                                placeholder="Select"
                                                manySelectedPlaceholder={`%s/${filterFormOptionData[item.fName].length} Checked, ... `}
                                                allSelectedPlaceholder={`%s/${filterFormOptionData[item.fName].length} Checked, ... `}
                                                handleShowAllBtn={() => this.context.handleShowAllBtn(item.fName)}
                                                btmLabel={"Show All"}
                                            /> :
                                            <Row>
                                                {item.range.map((rangeItem, rangeKey) => {
                                                    return <Col key={rangeKey}>
                                                        <TextField
                                                            type={'text'}
                                                            field={rangeItem.fName}
                                                            maxLength={5}
                                                            value={formData[rangeItem.fName]}
                                                            placeholder={rangeItem.label}
                                                            handleOnchange={this.context.onChangeHandler}
                                                            size={'sm'}
                                                            className={formErr[rangeItem.fName] ? 'form-err-border-red' : ""}
                                                        />
                                                        {rangeKey === 0 ? <span className='mintomax'>-</span> : ''}
                                                    </Col>
                                                })}
                                            </Row>}
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    badgeNo={this.context.state.totalBadgeCount}
                    isDisableApplyBtn={formErr.minRange || formErr.maxRange ? true : false}
                    isDisableResetBtn={this.disableResetFilter()}
                    getOutSideClose={this.context.getOutSideClose}  // received this function from the parent component
                />
            </Fragment>
        )
    }
}

export default CustomFilter;
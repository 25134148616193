import React, { Component } from 'react';
import './index.css';
import NotificationTemplateContainer from './containers/notificationTemplateContainer'
import NotificationTemplateProvider from './provider/notificationTemplateProvider';

class NotificationTemplate extends Component {

    render() {
        return (

            <NotificationTemplateProvider>
                <NotificationTemplateContainer />
            </NotificationTemplateProvider>
        );
    }
}

export default NotificationTemplate;
import * as actionTypes from '../mainContent/faultAnalysis/register/actions/faultAnalysisRegisterActionTypes';

const initialState = {
    filterSelectionData: { eventType: '', eventState: '', classificationType: '', isReDirect: false },
};

const faultAnalysisReducer = function (currentState = initialState, action) {
    
    switch (action.type) {
        case actionTypes.UPDATE_FILTER_SELECTION:
            return {
                ...currentState,
                filterSelectionData: action.payload
            }
        default:
            return currentState
    }
}

export default faultAnalysisReducer;
import React,{Component} from 'react';
import { SiteContext } from '../provider/siteProvider';

class selctAllCB extends Component{
    static contextType = SiteContext;
    handleSelectAll = (e) => this.context.handleSelectAll(e);
    render(){
        return(
            <input onChange={this.handleSelectAll.bind(this)} type="checkbox" checked={this.context.state.selectAll}/>
        )
    }
}
export default selctAllCB;
import React, { useContext } from 'react';
import TextField from '../../../hoc/form/textField';
import {ConfigurePropertiesContext} from '../provider/configurePropertiesProvider';
import { FormattedMessage } from 'react-intl';

const ConfigVDprofileTemplateClass = (props) => {
    const contextData = useContext(ConfigurePropertiesContext);
    
    return (
            props.rowData.original.profileName !== 'Control' &&  
            <>
                <TextField
                    type="text"
                    isDisabled={false}
                    value={(contextData.state.configData.vdProfileSettings[props.rowData.row._index].classValue).toString()}
                    field={''}
                    handleOnchange={(e) => contextData.updateFormData('classValue', e, 'vdProfileSettings', '', props.rowData, 'numberField')}
                    className={contextData.state.formError[props.rowData.original.id] && contextData.state.formError[props.rowData.original.id] ? "form-err-border-red" : ""}
                />
                {contextData.state.formError[props.rowData.original.id] && contextData.state.formError[props.rowData.original.id] && <div className='form-err-color-red'><FormattedMessage id={"vdm.validation.classLength"} />  </div> }
            </>
    );
};

export default ConfigVDprofileTemplateClass;
import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { EProfileContext } from '../provider/eProfileProvider';
import { equipmentProfileData, cableProfileData } from '../../../utility/equimentprofileUtility';
import BlankPage from '../../../hoc/blankPage';
import Loader from '../../../hoc/loader';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Table from '../../../hoc/table';
import Tooltip from '../../../hoc/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt, faPlus, faTh } from '@fortawesome/free-solid-svg-icons';
import DropdownMenuCheckbox from '../../../hoc/dropdownMenuCheckbox';
import { closeAlertToaster } from '../../../login/actions/loginActionDispatch';
import AlertMessage from '../../../hoc/alert';

class CableProfileTable extends Component {
    static contextType = EProfileContext;
    constructor(props) {
        super(props);
        this.state = {
            columnsData: [
                ...cableProfileData.columnsData,
                {
                    accessor: "action", Header: <FormattedMessage id='dm.column.actions' />, sortable: false, maxWidth: 130, Cell: ({ original }) => {
                        return <span>
                            <Tooltip baseContent={<FontAwesomeIcon className='cursor-pointer' onClick={() => this.context.onClickAddOrEditOrDeleteIcon(original, 'edit', equipmentProfileData.profileList[1])} icon={faPencilAlt} size="sm" />} message={<FormattedMessage id='groupmanagement.context.edit' />} placement={'top'} />&nbsp;&nbsp;&nbsp;
                            <Tooltip baseContent={<FontAwesomeIcon className='cursor-pointer' onClick={() => this.context.onClickAddOrEditOrDeleteIcon(original, 'delete', equipmentProfileData.profileList[1])} icon={faTrashAlt} size="sm" />} message={<FormattedMessage id='groupmanagement.context.delete' />} placement={'top'} />
                        </span>
                    }
                },
            ],
        }
    }

    handleMultipleSort = (rowData) => {
        let updateColumnData = this.context.updateSortNumber(rowData, this.state.columnsData)
        this.setState({ columnsData: updateColumnData })
    }

    onPageSizeChange = (sizePerPage, pageNo) => {
        this.setState({
            "pageSize": sizePerPage
        })
    }

    render() {
        return (
            <>
                <Container fluid className='e-profile-table-top'>
                    <Row>
                        <Col xs={3} className="p-0-m-0"><FormattedMessage id='db.title.cableProfiles' /></Col>
                        <Col xs={6} className="p-0-m-0">
                            {(this.props.showAlertToaster && this.context.state.selectedProfile === equipmentProfileData.profileList[1]) ? <div className='d-flex justify-content-center'><AlertMessage type={this.props.msgType} timer={10000} message={this.props.message} setVisible={() => this.props.closeAlertModal()} /></div> : ""}
                        </Col>
                        <Col xs={3} className="p-0-m-0 flex-content">
                            <Tooltip baseContent={
                                <span onClick={(e) => this.context.onClickAddOrEditOrDeleteIcon(e, 'add', equipmentProfileData.profileList[1])} className={"mr-2 btn btn-outline-secondary delete-icon-btn"} >
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>} message={<FormattedMessage id={'db.equipmentprofile.createCProfile'} />} placement={'top'} />

                            <span className={!this.context.state.cableProfileData.length ? 'disabled' : ''}>
                                <DropdownMenuCheckbox
                                    btnContent={
                                        <span>
                                            <span className="filter-icon-btn mr-1"><FontAwesomeIcon icon={faTh} /> </span>
                                        </span>
                                    }
                                    bodyContent={cableProfileData.columnFilter}
                                    selectedCulumns={this.context.state.displayCableProfileColumns}
                                    handleFilterChecked={(e) => this.context.filterProfileColumns(e, equipmentProfileData.profileList[1])}
                                />
                            </span>
                        </Col>
                    </Row>
                </Container>
                <div className={`ep-table-section cprofile-table ${this.context.state.cableProfileData.length === 0 ? 'blankPageborder' : ''}`}>
                    {this.context.state.CableLoader ? <Loader elementClass="tree-ajax-loader center-element" /> : this.context.state.cableProfileData.length !== 0 ?
                        <Table
                            tableColumn={this.state.columnsData.filter(item => this.context.state.displayCableProfileColumns.includes(item.accessor))}
                            tableRowData={this.context.state.cableProfileData}
                        />
                        :
                        !this.context.state.CableLoader ? <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /> : ""
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showAlertToaster: state.loginData.showAlertModal,
        msgType: state.loginData.msgType,
        message: state.loginData.errorMessage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeAlertModal: () => dispatch(closeAlertToaster()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CableProfileTable);

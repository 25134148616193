import React from 'react';
import { VirtualDeviceNameColumn, VirtualDeviceOtherColumn, VirtualDeviceDescription } from '../mainContent/manage/virtualDeviceManagement/components/virtualDeviceColumnComponent';
import AmpleIcon from '../hoc/ampleIcon';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber';
import VDprofileTemplateCheckbox from '../mainContent/manage/virtualDeviceManagement/components/addEditDevice/vdProfileTemplateCheckbox';
import VDProfileTemplateClass from '../mainContent/manage/virtualDeviceManagement/components/addEditDevice/vdProfileTemplateClass';
import LiveViewPointValue from '../mainContent/manage/virtualDeviceManagement/components/liveView/virtualDeviceLiveViewPointValue';
import { formatDateAndTimeWithoutSeconds } from '../filters/dateHelper'; 
import { FormattedMessage } from 'react-intl';

export const tableData = {
    columnsData: [
        { accessor: 'name', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.table.column.virtualDeviceName' />}/>, sortedOrder : 0,
        Cell: (row) => {
            return (<VirtualDeviceNameColumn data={row.original} /> );
        }
        },
        { accessor: 'description', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.table.column.description' />} />, minWidth: 150, sortedOrder : 0, textOverflow:'ellipsis',
        Cell: (row) => {
            return (<VirtualDeviceDescription data={row.original.description} /> );
        }
        },
        { accessor: 'status', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.table.column.status' />} />, sortedOrder : 0,
            Cell: row => (
                `${row.value}` === "ONLINE" ? <AmpleIcon type="fontawesome" name={faCheckCircle} tooltipText={"Online"} /> :
                    `${row.value}` === "OFFLINE" ? <AmpleIcon type="fontawesome" name={faTimesCircle} tooltipText="Offline" /> : row.value
            ),
        },
        { accessor: 'lastStatusUpdateOn', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.table.column.lastCommunicationTime' />} />, minWidth: 110, sortedOrder : 0, isDisplay: true,
            Cell: (row) => {
                return (<VirtualDeviceOtherColumn data={(row.original.lastStatusUpdateOn)} /> );
            }
        },
        { accessor: 'ip', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.table.column.ipAddress' />}/>, sortedOrder : 0, Cell: (row) => {
            return (<VirtualDeviceOtherColumn data={row.original.ip} /> );
        }},
        { accessor: 'port', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.table.column.port' />}/>, sortedOrder : 0, Cell: (row) => {
            return (<VirtualDeviceOtherColumn data={row.original.port} /> );
        }},
        { accessor: 'dnpAddress', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.table.column.dnpAddress' />}/>, sortedOrder : 0, Cell: (row) => {
            return (<VirtualDeviceOtherColumn data={row.original.dnpAddress} /> );
        } },
        { accessor: 'deviceCount', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.table.column.deviceCount' />}/>, sortedOrder : 0, Cell: (row) => {
            return (<VirtualDeviceOtherColumn data={(row.original.deviceCount).toString()} /> );
        }},
        { accessor: 'pointCount', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.table.column.pointCount' />}/>, sortedOrder : 0, Cell: (row) => {
            return (<VirtualDeviceOtherColumn data={(row.original.pointCount).toString()} /> );
        }},
    ],
    deviceLiveViewColumn: [
        {accessor: 'region', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.column.region' />} />, sortedOrder : 0, isDisplay: true},
        {accessor: 'substation', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.column.substation' />} />, sortedOrder : 0, isDisplay: false},
        {accessor: 'feeder', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.column.feeder' />} />, sortedOrder : 0, isDisplay: false},
        {accessor: 'site', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.column.site' />} />, sortedOrder : 0, isDisplay: false},
        {accessor: 'lateral', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.column.lateral' />} />, sortedOrder : 0, isDisplay: false},
        {accessor: 'phase', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.column.phase' />} />, sortedOrder : 0, isDisplay: false, maxWidth:70},
        {accessor: 'serialNumber', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.column.serialNumber' />} />, sortedOrder : 0, isDisplay: false, 
            Cell: row => (
                <>
                    {row.original.deleted ? <span>{row.original.serialNumber}<span style={{fontStyle:'italic', color:'#8c8c8c', fontSize:'12px'}}>(Deleted)</span></span> : row.original.serialNumber}
                </>
            ),
        },
        {accessor: 'pointType', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='vdm.lvtable.column.pointType' /> } />, sortedOrder : 0, isDisplay: true},
        {accessor: 'pointName', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='vdm.lvtable.column.pointName' />} />, sortedOrder : 0, isDisplay: true, minWidth:150},
        {accessor: 'vPointName', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='vdm.lvtable.column.vdPointName' />} />, sortedOrder : 0, isDisplay: true, minWidth:150},
        {accessor: 'pointIndex', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.lvtable.column.pointIndex' />} />, sortedOrder : 0, isDisplay: false, maxWidth:90},
        {accessor: 'value', Header: (original) => <TableMultiSortNumber column={original} label={"Value"} />, sortedOrder : 0, isDisplay: true,
            Cell: row => (
                <LiveViewPointValue rowData={row} />
            ),
        },
        {accessor: 'timeStamp', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.lvtable.column.timeStamp' />} />, sortedOrder : 0, isDisplay: false, 
            Cell: (original) => /^-?\d+$/.test(original.row.timeStamp) ? formatDateAndTimeWithoutSeconds(original.row.timeStamp) : 'N/A'},
    ],

    profileTemplateTable: [
        {accessor: 'profileName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.profileTemplate.profileName' />} />, sortedOrder : 0, 
            Cell: row => (
                <>
                    {row.original.isDisplay !== undefined && !row.original.isDisplay ? '' : row.original.profileName }
                </>
            ),
        },
        {accessor: 'selected', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.profileTemplate.selected' />} />, sortedOrder : 0, 
            Cell: row => (
                <VDprofileTemplateCheckbox rowData={row} />
            ),
        },
        {accessor: 'pointName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.profileTemplate.vdPoint' />} />, sortedOrder : 0},
        {accessor: 'pointType', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.profileTemplate.type' />} />, sortedOrder : 0},
        {accessor: 'classValue', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='vdm.profileTemplate.class' />} />, sortedOrder : 0,
            Cell: row => (
                <VDProfileTemplateClass rowData={row} />
            ),
        }
    ],
    pageSizeOptions: [100, 200, 500, 1000]
}

export const virtualDeviceMngmntData = {
    formData : {
        virtualDeviceConfig : [
                {type:'text', label: <FormattedMessage id='vdm.configform.label.vdName' />, field: 'name', placeHolder: 'Device Name', isDisabled: false, formSectonss:'top', isSeparator: false, min:4, max:40 },
                {type:'textarea', label: <FormattedMessage id='vdm.table.column.description' /> , field: 'description', placeHolder: 'Description', isDisabled: false, formSectonss:'top', isSeparator: false, min:0, max:80 },
                {type:'text', label: <FormattedMessage id='vdm.table.column.ipAddress' /> , field: 'ip', placeHolder: 'IP Address', isDisabled: true, formSectonss:'top', isSeparator: false },
                {type:'select', label: <FormattedMessage id='vdm.table.column.port' /> , field: 'port', placeHolder: 'Port', isDisabled: false, formSectonss:'top', isSeparator: false },
                {type:'number', label: <FormattedMessage id='vdm.table.column.dnpAddress' />, field: 'dnpAddress', placeHolder: 'DNP Address', isDisabled: false, formSectonss:'top', isSeparator: true, min:0, max:65519 },

                { field: 'default.binary.output.spares', type: 'number', label: <FormattedMessage id='vdm.configform.label.binaryOutputSpares' /> , formSectonss:'vdDefaultSettings', isSeparator: false, isDisabled: false, toolTip: <FormattedMessage id='vdm.tooltip.setting.perPhaseBOS' /> },
                { field: 'default.binary.input.spares', type: 'number', label: <FormattedMessage id='vdm.configform.label.binaryInputSpares' /> , formSectonss:'vdDefaultSettings', isSeparator: false, isDisabled: false, toolTip: <FormattedMessage id='vdm.tooltip.setting.perPhaseBIS' /> },
                { field: 'default.analog.output.spares', type: 'number', label: <FormattedMessage id='vdm.configform.label.analogOutputSpares' /> , formSectonss:'vdDefaultSettings', isSeparator: false, isDisabled: false, toolTip: <FormattedMessage id='vdm.tooltip.setting.perPhaseAOS' /> },
                { field: 'default.analog.input.spares', type: 'number', label: <FormattedMessage id='vdm.configform.label.analogInputSpares' /> , formSectonss:'vdDefaultSettings', isSeparator: false, isDisabled: false, toolTip: <FormattedMessage id='vdm.tooltip.setting.perPhaseAIS' /> },
                { field: 'default.counter.spares', type: 'number', label: <FormattedMessage id='vdm.configform.label.counterSpares' /> , formSectonss:'vdDefaultSettings', isSeparator: false, isDisabled: false, toolTip: <FormattedMessage id='vdm.tooltip.setting.perPhaseCS' /> },
                { field: 'default.offline.timer', type: 'number', label: <FormattedMessage id='vdm.configform.label.onlinetoOffline' /> , formSectonss:'vdDefaultSettings', isSeparator: false, isDisabled: false, toolTip: <FormattedMessage id='vdm.tooltip.setting.onlineToOfflineState' /> },
            ],
    }
}

export const searchValue = {
    value : ''
};
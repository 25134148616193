import React, { Component } from 'react';
import { TreeConsumer } from '../../treeview/provider/treeProvider';
import MapProvider from './provider/mapProvider';
import MapContainer from './containers/mapContainer';
import { connect } from 'react-redux';
import './index.css';

class MapView extends Component {

    render() {
        return (
            <React.Fragment>
                <TreeConsumer>
                    {(context) => (
                        <React.Fragment>
                            <div className="map h-100 d-flex">
                                <MapProvider loadNextLevel={context.loadNextLevel} setDashboardFlag={context.cancelApiCallFromDashboard} dashboardFlag={context.state.dashboardFlag} treeNode={context.state.highlightedNode.node} treeLoader={context.state.loading} breadCrumb={context.state.breadCrumb}> {
                                    ((!context.state.highlightedNode) || (context.state.highlightedNode )) ? <MapContainer /> : null
                                }
                                </MapProvider>
                            </div>
                        </React.Fragment>
                    )}
                </TreeConsumer>
            </React.Fragment>
        );
    }
}

 const mapStateToProps = state => {
    return {
        enableSubMenus: state.headerData.enableSubMenus
    };
}

export default connect(mapStateToProps, null)(MapView);
import React, { useContext, useEffect, useState } from 'react';
import { VirtualDeviceContext } from '../../provider/virtualDeviceProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import TextField from '../../../../../hoc/form/textField';
import Tooltip from '../../../../../hoc/tooltip';
import { FormattedMessage } from 'react-intl';
import { negativeNumOptRgx } from '../../../../../filters/commonFunction';

const VirtualDeviceLiveViewPointValue = (props) => {
    const contextData = useContext(VirtualDeviceContext);
    const {liveViewTableDataCopy, clickedRowData, liveViewTableDataForEdit } = contextData.state;
    const {rowData} = props
    const [tabledata, settableData] = useState([])
    const [tabledataCopy, settableDataCopy] = useState([])

    useEffect(()=>{
        settableData(contextData.state.liveViewTableDataForEdit);
        settableDataCopy(contextData.state.liveViewTableDataForEdit)
    },[])

    const handleOnchangeLiveViewPointsEdit=(field, value, rowData)=>{
        contextData.handleOnchangeLiveViewPoints(field, value, rowData);
        let prvDatas = tabledata.map((item)=> Object.assign({}, item) );
            prvDatas.map((item)=>{
                if(item.id === rowData.original.id) {
                    item[field] = value;
                    item.errMsg = item.errMsg ? findIsLiveViewErr(item) : false
                }
            })
            settableData(prvDatas);
    }

    const saveOrCancelLiveViewPointCancel= (rowData, clickedType, event)=>{
        event.stopPropagation();
        let prvValue = liveViewTableDataCopy.find((item)=> item.id === rowData.original.id);
        let prvDatas = liveViewTableDataCopy.map((item)=> Object.assign({}, item) );
            prvDatas.map((item)=>{
                if(item.id === rowData.original.id) {
                    item.value = prvValue.value;
                }
            })
        settableData(prvDatas)
        contextData.saveOrCancelLiveViewPoint(rowData, 'cancel', event)
    }

    const findIsLiveViewErr=(rowData)=>{
        let err = '';
        if(rowData.value === ''){
            err = <FormattedMessage id={"vdm.validation.pointvalEmpty"} />
        } else if ((rowData.pointType === 'BinaryInput' || rowData.pointType === 'BinaryOutput') ){
            if(rowData.value !== 'true' && rowData.value !== 'false' || rowData.value.toUpperCase() === "NA" ){
                err = <FormattedMessage id={"vdm.validation.pointval"} />
            }
        } else if(rowData.pointType !== 'BinaryInput' && rowData.pointType !== 'BinaryOutput' ){
                if(!(negativeNumOptRgx.test(rowData.value))){
                err = <FormattedMessage id={"vdm.validation.pointvalNum"} />
            }
        }
        return err
    }

    const saveOrCancelLiveViewPointSave=(rowData, clickedFrom, event)=>{
        event.stopPropagation();
        let prvValue = tabledata.find((item)=> item.id === rowData.original.id);
        let newRowData = Object.assign({}, rowData);
            newRowData.value = prvValue.value;
            newRowData.original.value = prvValue.value;

        let isLiveViewErr = findIsLiveViewErr(newRowData.original);
        let prvDatas = tabledata.map((item)=> Object.assign({}, item) );
        
        if(isLiveViewErr !== ''){
            prvDatas.map((item)=>{
                if(item.id === rowData.original.id) {
                    item.errMsg = isLiveViewErr
                }
            })
        settableData(prvDatas)
        } else {
            contextData.saveOrCancelLiveViewPoint(newRowData, 'save', event);
        }
    }

    useEffect(()=>{
        settableData(liveViewTableDataForEdit);
        settableDataCopy(liveViewTableDataForEdit)
    },[liveViewTableDataForEdit])

    const showAndHideValue=(color)=>{
       return <span onClick={()=> clickedRowData.locked && rowData.original.pointName !== "Spare" && contextData.handleClickOnLivePointValue(rowData) } style={{color:color, cursor: clickedRowData.locked && rowData.original.pointName !== "Spare" && 'pointer' }}>
                 {rowData.original.isEdit && rowData.original.pointName !== "Spare" ?
                    <>
                        <Row className='pb-2'>
                            <Col className='ml-2 mr-2 pl-2 pr-2'>
                            <TextField 
                                type={'text'}
                                field={'value'}
                                value={tabledata.find(item => item.id === rowData.original.id).value}
                                placeholder={''}
                                handleOnchange={(field)=> handleOnchangeLiveViewPointsEdit('value', field, rowData)}
                                handleOnKeyDown={(value, field, evt)=>(rowData.original.pointType !== 'BinaryInput' && rowData.original.pointType !== 'BinaryOutput') && (( evt.key === '.' || evt.key === '+' ) && evt.preventDefault())}
                                size={'sm'}
                            />
                                <span className='live-view-error'>{tabledata.find(item => item.id === rowData.original.id).errMsg}</span>
                            </Col>
                        </Row>
                        <div className='d-flex justify-content-between'>
                            <div><Button onClick={(e)=> saveOrCancelLiveViewPointCancel(rowData, 'cancel', e)} className='modalCancelBtn  pl-2 pr-2'><FormattedMessage id={"dm.column.cancel"} /></Button></div>
                            <div className='m-0 p-0'> 
                                <Button onClick={(e)=> saveOrCancelLiveViewPointSave(rowData, 'save', e) } className='modalSaveBtn pl-2 pr-2'
                                disabled={(tabledata[rowData.index].value).toString() === (liveViewTableDataCopy.find(item => item.id === rowData.original.id).value).toString()}
                                style={{pointerEvents: (tabledata[rowData.index].value).toString() === (rowData.original.value).toString() && 'painted'}} ><FormattedMessage id={"dm.column.save"} /></Button></div>
                        </div>
                    </>
                    :
                    <>
                        <span> { tabledata.length ? tabledata.find(item => item.id === rowData.original.id).value : ''}</span>
                        &nbsp; &nbsp; &nbsp; &nbsp;{clickedRowData.locked && rowData.original.pointName !== "Spare" && <Tooltip baseContent={ <FontAwesomeIcon icon={faPen} size="sm" color='grey'/> } message={ <FormattedMessage id={"groupmanagement.context.edit"} />} placement={'top'} /> }
                    </>
                }
            </span>
    }

    return (
        <>
            {
                rowData.original.status === "EDITED" ? showAndHideValue('red')
                    :
                rowData.original.pointName === "Spare" ? showAndHideValue('blue')
                    :
                    showAndHideValue('black')
            }
        </>
    );
};

export default VirtualDeviceLiveViewPointValue;
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../hoc/button';
import DropdownMenuCheckbox from '../../../../hoc/dropdownMenuCheckbox';
import { FormattedMessage } from 'react-intl';
import { ConfigStatusContext } from '../provider/configStatusProvider';
import * as currentJobUtility from '../../../../utility/currentJobUtility';

class ConfigStatusActions extends Component {
    static contextType = ConfigStatusContext;

    render() {
        let retryConfig = true;
        let retryAllConfig = true;
        if (Object.keys(this.context.state.roleAndPermission).length > 0) {
            var data = this.context.state.roleAndPermission;
            data.emPermissions.forEach(permission => {
                if (permission.permissionName === "RETRY_CONFIG" && permission.hasPermission === false) {
                    retryConfig = false;
                }
                if (permission.permissionName === "RETRY_ALL_CONFIG" && permission.hasPermission === false) {
                    retryAllConfig = false;
                }
            });
        }
        return (
            <Row noGutters={true} className="top-header">
                <Col xs={6} className="device-heading"><FormattedMessage id='currentjobs.deviceList' /></Col>
                <Col xs={6} className="text-right device-button">
                    <Button handleButtonClick={() => this.props.handleActionItems('retry')} className={"add-btn " + (!retryConfig || !currentJobUtility.tableOptions.enableRetryBtn ? 'disabled' : '')} btnText={<FormattedMessage id='cj.table.button.txt.retry' />} />
                    <Button handleButtonClick={() => this.props.handleActionItems('retryAll')} className={"add-btn " + (!retryAllConfig || !currentJobUtility.tableOptions.enableRetryAllBtn ? 'disabled' : '')} btnText={<FormattedMessage id='cj.table.button.txt.retryall' />} />
                    <span className={'float-right ' + (this.context.state.deviceListTableDataset.length === 0 ? 'disabled' : '')}>
                        <DropdownMenuCheckbox
                            btnContent={<span className="add-btn column-filter"><FontAwesomeIcon icon={faTh} size="sm" /></span>}
                            bodyContent={currentJobUtility.OtapStatusData.ConfigStatusColumnFilter}
                            selectedCulumns={this.props.stateObj.displayColumns}
                            handleFilterChecked={this.props.filterConfigTableColumn}
                        />
                    </span>
                </Col>
            </Row>
        );
    }
}

export default ConfigStatusActions;
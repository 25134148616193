import React, { Component, Fragment } from 'react';
import { Form, FormGroup, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import SingleSelect from '../../../../hoc/singleSelect';
import TreeViewWithCheckbox from '../../../../hoc/treeViewWithCheckbox';
import TextField from '../../../../hoc/form/textField';
import { ReportsContext } from '../provider/reportsProvider';
import ReportPicker from './reportPicker';
import Loader from '../../../../hoc/loader';
import { userPreferences } from '../../../../services/utilityService';

class reportsComponent extends Component {

    static contextType = ReportsContext;

    render() {
        const { reportTypes, reportType, endDate, startDate, treeCheckboxVisible, treeCheckboxDisable, phaseIdList, selectedPhaseId, phasesList, pointsList, amperage, haBelowAmp, fileFormat, fileType, reportFormat, reportSeparator, roleAndPermission, loading, healthPointsList } = this.context.state;
        // conflictReport view
        let conflictReport = phaseIdList.map((list, index) => {
            return (
                <span key={index} className="d-flex align-items-center">
                    <input type="radio" value={list} checked={selectedPhaseId === list} onChange={(e) => this.context.setChange(e.target.value, 'selectedPhaseId')} />
                    &nbsp;<FormattedMessage id={`manage.report.list.${list}`} /> &nbsp;&nbsp;
                </span>
            )
        });

        let phaseView = phasesList.map((list, index) => {
            return (
                <span key={index} className="d-flex align-items-center">
                    <input type="checkbox"
                        defaultChecked={list.value}
                        name={list.shortname}
                        onClick={e => e.stopPropagation()}
                        onChange={(evt) => this.context.updateCheckboxList(evt, this.context.state.phasesList)}
                    />&nbsp;<span dangerouslySetInnerHTML={{__html: list.name}} ></span>&nbsp;&nbsp;
                </span>
            )
        });

        let pointsView = pointsList.map((list, index) => {
            return (
                <Col className={(index%2 === 0) ? 'col-sm-5 col-5 float-left p-0 border-0 d-flex align-items-center' : 'col-sm-7 col-7 float-left p-0 border-0 d-flex align-items-center'} key={index} >
                    <input type="checkbox"
                        defaultChecked={list.value}
                        name={list.shortname}
                        onClick={e => e.stopPropagation()}
                        onChange={(evt) => this.context.updateCheckboxList(evt, this.context.state.pointsList)}
                    />&nbsp;<span dangerouslySetInnerHTML={{__html: list.shortname === 'Conductor_Temperature' ? list.name + `(${userPreferences.tempUnit})` : list.name}} ></span>&nbsp;&nbsp;
                </Col>
            )
        });
        
        let healthReportFormatedData = [{groupName: 'MM3', data: []}, {groupName: 'UM3+', data:[]}, {groupName: 'ZM1', data: []}];
        if(reportType === 'Device Health'){
            healthReportFormatedData.map((item)=>{
                healthPointsList.map((list)=>{
                    if(item.groupName === list.group){
                        item.data.push(list)
                    }
                })
            })   
        }

        let deviceHealthView = healthReportFormatedData.map((list, index)=>{
            return <Col className='col-12' key={index}>
                        <Row> <Col className='pb-1'> <b>{list.groupName}</b></Col></Row>
                        {list.data.length !==0 && list.data.map((item, key)=>{
                            return <Row key={key}>
                                <Col>
                                    <input type="checkbox"
                                        defaultChecked={item.value}
                                        name={item.shortname}
                                        onClick={e => e.stopPropagation()}
                                        onChange={(evt) => this.context.updateCheckboxList(evt, this.context.state.healthPointsList)}
                                        />&nbsp;<span dangerouslySetInnerHTML={{__html: item.name}} ></span>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        })}
                    </Col>
        });
        
        return (
            <Fragment>
                {loading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                <Form>
                    <FormGroup key="report-type" className="clearfix border-1px p-2 m-t-1 m-b-1" xs={12} md={12}>
                        <Row className="pt-1 mx-1 p-h-10">
                            <Col sm={2} xs={2} className="float-left col-form-label p-0 pt-1 text-color">
                                <FormattedMessage id={`manage.report`} />:
                            </Col>
                            <Col sm={2} xs={2} className="float-left form-control single-select-dropdown p-0 border-0">
                                <SingleSelect data={reportTypes} setType={this.context.setReportType} value={reportType} icon='ok' />
                                <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup key="report-filters" className="clearfix border-1px p-2 m-b-1" xs={12} md={12}>
                        <Row className="m-1 p-h-10">
                            <Col sm={2} xs={2} className="float-left col-form-label p-0 m-t-1 font-weight-bold text-color">
                                <FormattedMessage id={`manage.report.filters`} />
                            </Col>
                        </Row>
                        {/*  Data Range view */}
                        {
                            (reportType === 'DNP3 Points') ?
                            <ReportPicker 
                                timezone = {roleAndPermission.timezone} 
                                endDate = {endDate}
                                startDate = {startDate}
                                maxSpan={29}
                                onCallback={(event, picker) => this.context.getCalenderCallback(event, picker)}
                            /> : null
                        }
                        {
                            (reportType === 'Sensors on Low Amp') ?
                            <ReportPicker 
                                timezone = {roleAndPermission.timezone} 
                                endDate = {endDate}
                                startDate = {startDate}
                                onCallback={(event, picker) => this.context.getCalenderCallback(event, picker)}
                            /> : null
                        }
                        <Row className="mx-1 p-10">
                            <Col sm={2} xs={2} className="float-left col-form-label p-0 m-t-2 text-color">
                                <FormattedMessage id={`manage.report.hierarchy`} />:
                            </Col>
                            <Col sm={3} xs={3} className="float-left single-select-dropdown p-0 border-0">
                                <div className="report-tree-list">
                                    <TreeViewWithCheckbox
                                        passUpdatedData={allData =>  this.context.setChange(allData, 'reportTreeData') }
                                        expandUpto={"FEEDER"} //display up to
                                        checkedLableName={"generallist"} //for checkbox true or false by this value
                                        from={'manage/report'} //for api call
                                        checkBoxVisible={treeCheckboxVisible} // visible checkbox level
                                        checkBoxDisable={treeCheckboxDisable} // disable checkbox for dnp3 points
                                        needToPersistence={reportType} // reload the tree data
                                    />
                                </div>
                            </Col>
                        </Row>
                        {/*  conflict Report view */}

                        {reportType === 'Device Health' && 
                            <Row className="m-1 p-10">
                            <Col sm={2} xs={2} className="float-left col-form-label p-0 m-t-2 text-color">
                                <FormattedMessage id={`manage.report.healthPoint`} />:
                            </Col>
                            <Col sm={3} xs={3} className="d-flex float-left p-0 border-0">
                                { deviceHealthView }
                            </Col>                            
                        </Row>
                        }

                        {
                            (reportType === 'Phase ID') ?
                            <Row className="m-1 p-10">
                                <Col sm={2} xs={2} className="float-left col-form-label p-0 m-t-2 text-color">
                                    <FormattedMessage id={`manage.report.list`} />:
                                </Col>
                                <Col sm={3} xs={3} className="d-flex float-left p-0 border-0">
                                    { conflictReport }
                                </Col>                            
                            </Row> : null
                        }
                        {
                            (reportType === 'DNP3 Points') ?
                            <Fragment>
                                <Row className="m-1 p-10">
                                    <Col sm={2} xs={2} className="float-left col-form-label p-0 m-t-2 text-color">
                                        <FormattedMessage id={`manage.report.phase`} />:
                                    </Col>
                                    <Col sm={3} xs={3} className="d-flex float-left p-0 border-0">
                                        { phaseView }
                                    </Col>
                                </Row> 
                                <Row className="m-1 p-10">
                                    <Col sm={2} xs={2} className="float-left col-form-label p-0 m-t-2 text-color">
                                        <FormattedMessage id={`manage.report.points`} />:
                                    </Col>
                                    <Col sm={7} xs={7} className="float-left p-0 border-0">
                                        { pointsView }
                                    </Col>
                                </Row>
                            </Fragment>
                            : null
                        }
                        {
                            (reportType === 'Sensors on Low Amp') ?
                            <Fragment>
                                <Row className="m-1 p-10">
                                    <Col sm={2} xs={2} className="float-left col-form-label p-0 m-t-2 text-color">
                                        <FormattedMessage id={`manage.report.amperage`} />:
                                    </Col>
                                    <Col sm={7} xs={7} className="float-left p-0 border-0">
                                        <Col sm={2} xs={2} className="float-left p-0 border-0 p-r-10">
                                            <TextField
                                                type={'text'}
                                                value={amperage}
                                                field={'amperage'}
                                                placeholder={''}
                                                handleOnchange={this.context.setChange}
                                                size={'lg'}
                                                className={'p-10'}
                                                readOnly={false}
                                                isDisabled={false}
                                            />
                                        </Col>
                                        <Col sm={2} xs={2} className="float-left p-0 border-0 mx-2 m-t-1">
                                            <span>A<sub>rms</sub> (Max. 30)</span>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className="m-1 p-10">
                                    <Col sm={2} xs={2} className="float-left col-form-label p-0 m-t-2 text-color">
                                        <FormattedMessage id={`manage.report.haBelowAmp`} />:
                                    </Col>
                                    <Col sm={7} xs={7} className="float-left p-0 border-0">
                                        <Col sm={2} xs={2} className="float-left p-0 border-0 p-r-10">
                                            <TextField
                                                type={'text'}
                                                value={haBelowAmp}
                                                field={'haBelowAmp'}
                                                placeholder={''}
                                                handleOnchange={this.context.setChange}
                                                size={'lg'}
                                                className={'p-10'}
                                                readOnly={false}
                                                isDisabled={false}
                                            />
                                        </Col>
                                        <Col sm={2} xs={2} className="float-left p-0 border-0 mx-2 m-t-1">
                                            <span>% (Min. 1)</span>
                                        </Col>
                                    </Col>
                                </Row>
                            </Fragment>
                             : null
                        }                        
                    </FormGroup>
                    <FormGroup key="report-format" className="clearfix border-1px p-2 m-b-1" xs={12} md={12}>
                        <Row className="pt-1 mx-1 p-h-10">
                            <Col sm={2} xs={2} className="float-left col-form-label p-0 pt-1 text-color">
                                <FormattedMessage id={`manage.report.format`} />:
                            </Col>
                            <Col sm={2} xs={2} className="float-left form-control single-select-dropdown p-0 border-0">
                                <SingleSelect data={fileFormat} setType={value => this.context.setChange(value, 'reportFormat') } value={reportFormat} icon='ok' />
                                <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                            </Col> &nbsp;&nbsp;&nbsp;&nbsp;
                            {
                                reportFormat === "TEXT" ? 
                                    <Fragment>
                                        <div className="float-left p-1">
                                            <FormattedMessage id={`manage.report.seperator`} />:&nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <Col sm={2} xs={2} className="float-left form-control single-select-dropdown p-0 border-0">
                                            <SingleSelect data={fileType} setType={value => this.context.setChange(value, 'reportSeparator') } value={reportSeparator} icon='ok' />
                                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                        </Col>
                                    </Fragment>
                                : ""
                            }
                        </Row>
                    </FormGroup>
                </Form>
                <Button className="btn-default m-t-1" variant="light" onClick={() => this.context.generateReport()} ><FormattedMessage id={`manage.report.button.generate`} /></Button>
            </Fragment>
        )
        
    }
}

export default reportsComponent;
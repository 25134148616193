import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeErrorWindow } from "../login/actions/loginActionsBuilder";
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ErrorHandler = React.memo((props) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Modal show={open}
      size="md"
      dialogClassName="ErrorHandler_modal modal-dialog-centered"
      onHide={props.closeModal}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-sm">
      <Modal.Header closeButton className="d-block model_header">
        <FontAwesomeIcon icon={faExclamationTriangle} className="danger-color" />&nbsp;
          {(!props.title) ?
            (props.message && props.message.message && props.message.message === 'Network Error') || props.message === 'Network Error' ? <FormattedMessage id={`common.error.network.title`} /> : <FormattedMessage id={`common.error.title`} />
          : props.title}
      </Modal.Header>
      <Modal.Body className="modal_body">
        <p>{(props.message && props.message.message && props.message.message === 'Network Error') || props.message === 'Network Error' ? <FormattedMessage id={`common.error.network`} /> : props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.closeModal} className="modalCancelBtn"> <FormattedMessage id='db.common.close' /> </Button>
      </Modal.Footer>
    </Modal>
  );
});

const mapStateToProps = (state) => {
  return {
    open: state.loginData.showErrorModal,
    title: state.loginData.errorTile,
    message: state.loginData.errorMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeErrorWindow('')),
  };
};

ErrorHandler.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.object,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
	
import React, { Component } from 'react';
import { TreeConsumer } from '../../../treeview/provider/treeProvider';
import BaseMessage from '../../../hoc/baseMessage';
import SiteProvider from '../site/provider/siteProvider';
import SiteContainer from '../site/containers/siteContainer';
import './index.css';

class ManageSite extends Component {
    render() {
        return (
            <React.Fragment>
                <TreeConsumer>
                    {(context) => (
                        <React.Fragment>
                            <div className="sites">
                                <SiteProvider treeNode={context.state.highlightedNode.node} setDashboardFlag={context.cancelApiCallFromDashboard} dashboardFlag={context.state.dashboardFlag} treeLoader={context.state.loading}>{
                                    (!context.state.highlightedNode) ? <BaseMessage className={"content-height"} message="manage.sites.basemsg" /> : <SiteContainer />
                                }
                                </SiteProvider>
                            </div>
                        </React.Fragment>
                    )}
                </TreeConsumer>
            </React.Fragment>
        );
    }
}

export default ManageSite;
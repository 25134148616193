import React, { Component } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../../hoc/tooltip';

class FilterWithBadge extends Component {    
    myRef = React.createRef();
    render() {
        return (
            <span className='dropdown-badge'>
                <Dropdown id="dropdown-custom-filters" className="pr-1" >
                    <Dropdown.Toggle className="btn-outline-secondary btn-group-icons btn-light grp-btn-first m-0" ref={this.myRef}>
                    <Tooltip 
                        placement='top' 
                        message={<FormattedMessage id={`customreports.label.schedule`} />} 
                        baseContent={  <FontAwesomeIcon icon={faClock} size="lg" />}
                    />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-disable-autohide dropdown-filters">
                        {this.props.dropDownContent}
                        <Row className="mb-2">
                            <Col>
                                <button className={'btn float-right btn-default'} disabled={this.props.isDisableApplyBtn}  onClick={()=>{this.props.onScheduleBtnClickHandler(this.myRef)}}> <FormattedMessage id={"customreports.schedule.btn"}/></button>
                            </Col>
                        </Row>
                    </Dropdown.Menu>
                </Dropdown>
            </span>
        )
    }
}

export default FilterWithBadge;
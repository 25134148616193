import React from 'react'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../hoc/tooltip';
import { DGWContext } from '../provider/dgwProvider';
import { FormattedMessage } from 'react-intl';

class DgwUploadCertificate extends React.Component {
    static contextType = DGWContext;
    render() {
        const { roleId } = this.props.roleAndPermission
        return (

            <Tooltip placement="top"  message={<FormattedMessage id={this.context.state.currentTab === 'Ample Certificate'? "db.sg.ample_certificate_upload" : "db.sg.certificate_revocation_list_upload"} />}
                baseContent={
                    <span>
                        <label htmlFor="file-upload" className={ roleId === 1 ? "custom-file-upload" : "custom-file-upload-disabled" }>
                            <FontAwesomeIcon icon={faUpload} size="sm" />
                        </label>
                        <input id="file-upload" type="file"
                            disabled={roleId === 1 ? false : true}
                            onChange={(e) => this.context.uploadCertificateFile(e)}
                            onClick={(event) => event.target.value = null}
                            accept={this.context.state.currentTab === 'Ample Certificate' ? '.zip' : '.pem'}
                        />
                    </span>
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default connect(mapStateToProps, {})(DgwUploadCertificate);
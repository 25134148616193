import * as actionTypes from '../treeview/actions/treeViewActionTypes';

const initialState = {
    tree: [],
    routeParams: [],
    currentNode: 'ROOTNODE',
    treeRefArr: [],
    TreeDataKeyValuePair: [],
    hideAndShowTreeView: false
};

const treeviewReducer = function (currentState = initialState, action) {
    switch (action.type) {
        case actionTypes.LOAD_INITIAL_TREE:
            return {
                ...currentState,
                tree: action.tree
            }
        case actionTypes.UPDATE_SELECTED_TREE:
            return {
                ...currentState,
                tree: action.tree
            }
        case actionTypes.LOAD_DROPPED_TREE:
            return {
                ...currentState,
                tree: action.tree
            }
        case actionTypes.EDIT_NODE:
            const editedTree = findAndUpdateNode(currentState.tree[0], action.newNode, action.clickedNode);
            return {
                ...currentState,
                tree: Object.assign([], currentState.tree, editedTree),
            }
        case actionTypes.SET_ROUTE_PARAMS:
            return {
                ...currentState,
                routeParams: action.routeParams,
            }
        case actionTypes.TREE_REF_ARRAY:
            let currentStateVal = { ...currentState };
            let indexVal = currentStateVal.treeRefArr.length > 0 ? currentStateVal.treeRefArr.findIndex(e => (Object.keys(e)[0] === action.key)) : -1;
            if (indexVal === -1 && action.reference[action.key] !== null) {
                currentStateVal.treeRefArr.push(action.reference);
            }
            else if (indexVal >= -1 && action.reference[action.key] !== null) {
                currentStateVal.treeRefArr.splice(indexVal, 1);
                currentStateVal.treeRefArr.push(action.reference);
            }
            return {
                ...currentStateVal
            }
        case actionTypes.UPDATE_KEYVALPAIR_ARRAY:
            return {
                ...currentState,
                TreeDataKeyValuePair: action.data
            }
        case actionTypes.UPDATE_EXPAND_COLLAPSE:
            return {
                ...currentState,
                hideAndShowTreeView: action.expColVal
            }
        default:
            return currentState
    }
}

const findAndUpdateNode = (newNode, updatedNode, clickedNode) => {
    //Commented By Mani (Based on the usage will uncomment later, once the implementation starts)
    
    // const lowerSiblings = clickedNode.lowerSiblingCounts;
    // let parent = "newNode";
    // for (let i = 1; i < lowerSiblings.length; i++) {
    //     let index = eval(parent).children.length - (lowerSiblings[i] + 1);
    //     parent = parent + ".children[" + index + "]"
    //     if (i === lowerSiblings.length - 1) {
    //         parent = parent + " = updatedNode[0]";
    //     }
    // }
    // return eval(parent);
}

export default treeviewReducer;
import React, { Component, Fragment } from 'react';
import { Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { tableOptions, allDeviceData } from '../../../../utility/deviceManagementUtility';
import { getClassName } from '../../../../utility/treeViewUtility';
import { applyConfig } from '../services/deviceService';
import { DeviceContext } from '../provider/deviceProvider';
import { groupingSelectedRows } from '../../../../services/utilityService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import SingleSelect from '../../../../hoc/singleSelect';
import ModalWindow from '../../../../hoc/modelWindow';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../../../../hoc/loader';

class ConfigureDeviceComponent extends Component {
    static contextType = DeviceContext;
    state = {
        successMessage: "",
        errorMessage: "",
        newProfilePopupErrorMessage: "",
        selectedItems: {},
        selectedNewProfilePopup: false,
        updateConfirmationPopup: false,
        newProfileSelectedData: {},
        groupedData: {},
        newProfileRowsCount: 0,
        isBtnDisabled: false,
        finalSelectedData: [],
        isLoading: false
    }

    ddlValueUpdate = (e, type, fwVersion, hwRevision, profileName) => {
        let modifiedData = this.state.selectedItems;
        if (modifiedData[type]) {
            if ((e !== "--select--" && ( ['mm3', 'um1', 'mm3ai'].includes(type)))) {
                if (modifiedData[type][profileName]) {
                    modifiedData[type][profileName] = e;
                } else {
                    modifiedData[type] = Object.assign({}, this.state.selectedItems[type], {
                        [profileName]: e
                    })
                }
            }
            if (e !== "--select--" && (!['mm3','um1','mm3ai'].includes(type) )) {
                if (modifiedData[type][fwVersion]) {
                    if (modifiedData[type][fwVersion][hwRevision]) {
                        if (modifiedData[type][fwVersion][hwRevision][profileName]) {
                            modifiedData[type][fwVersion][hwRevision][profileName] = e
                        } else {
                            modifiedData[type][fwVersion][hwRevision] = Object.assign({}, this.state.selectedItems[type][fwVersion][hwRevision], {
                                [profileName]: e
                            })
                        }

                    } else {
                        modifiedData[type][fwVersion] = Object.assign({}, this.state.selectedItems[type][fwVersion], {
                            [hwRevision]: {
                                [profileName]: e
                            }
                        })
                    }
                } else {
                    modifiedData[type] = Object.assign({}, this.state.selectedItems[type], {
                        [fwVersion]: {
                            [hwRevision]: {
                                [profileName]: e
                            }
                        }
                    })
                }
            }
        } else {
            if (e !== "--select--" && ( ['mm3', 'um1', 'mm3ai'].includes(type))) {
                modifiedData = Object.assign({}, this.state.selectedItems, {
                    [type]: {
                        [profileName]: e
                    }
                });
            }
            if (e !== "--select--" && ( !['mm3', 'um1', 'mm3ai'].includes(type))) {
                modifiedData = Object.assign({}, this.state.selectedItems, {
                    [type]: {
                        [fwVersion]: {
                            [hwRevision]: {
                                [profileName]: e
                            }
                        }
                    }
                })
            }
        }
        this.setState({ selectedItems: modifiedData })
    }

    onNextBtnClickHandler = () => {
        let finalData = this.state.finalSelectedData;
        if (Object.keys(this.state.selectedItems).length > 0) {
            let rowsCount = this.state.newProfileRowsCount;
            const upCaseGroupdData = Object.fromEntries(Object.entries(this.state.groupedData).map(([k, v]) => [k.toUpperCase(), v]));
            Object.entries(this.state.selectedItems).forEach(([key, value]) => {
                Object.entries(value).forEach(([fwVersion, data]) => {
                    if (['mm3', 'um1', 'mm3ai'].includes(key)) {
                        rowsCount += upCaseGroupdData[key.toUpperCase()][fwVersion].length;
                        const profileId = [];
                        this.props.profileList[key].forEach(dt => dt.name === data ? profileId.push(dt.id) : "")
                        finalData.push(Object.assign({}, {
                            "type": key,
                            "elementIds": upCaseGroupdData[key.toUpperCase()][fwVersion].map(dt => dt.id),
                            "profileId": profileId[0]
                        }));
                    }
                    (!['mm3', 'um1', 'mm3ai'].includes(key)) && Object.entries(data).forEach(([hwRevision, field]) => {
                        Object.entries(field).forEach(([item, res]) => {
                            rowsCount += upCaseGroupdData[key.toUpperCase()][fwVersion][hwRevision][item].length;
                            const profileId = [];
                            this.props.profileList[key][fwVersion + "_" + hwRevision].forEach(dt => dt.name === res ? profileId.push(dt.id) : "")
                            finalData.push(Object.assign({}, {
                                "type": key,
                                "elementIds": upCaseGroupdData[key.toUpperCase()][fwVersion][hwRevision][item].map(dt => dt.id),
                                "profileId": profileId[0]
                            }));
                        });
                    });
                });
            });
            this.setState({ selectedNewProfilePopup: true, newProfileRowsCount: rowsCount, finalSelectedData: finalData, errorMessage: "" })
        } else {
            this.setState({ errorMessage: <FormattedMessage id='configure.popup.newProfileSelection.errorMessage' /> })
        }
    }

    cancelClickHandler = () => {
        this.setState({ selectedNewProfilePopup: false, newProfileRowsCount: 0, finalSelectedData: [] })
    }

    confirmClickHandler = () => {
        this.setState({ updateConfirmationPopup: true })
    }

    updateConfCancelClickHandler = () => {
        this.setState({ updateConfirmationPopup: false, selectedNewProfilePopup: true });
    }

    updateConfOkClickHandler = async () => {
        this.setState({ updateConfirmationPopup: false, selectedNewProfilePopup: true, isBtnDisabled: true, isLoading: true });
        const breadCrumb = getClassName({ node: this.props.selectedNode });
        const popupTitle = <span><FormattedMessage id={`configure.popup.title`} /> [ {this.state.newProfileRowsCount} Device(s)] {breadCrumb} </span>;
        const res = await applyConfig(this.state.finalSelectedData, popupTitle);
        if (res) {
            if (res.data.status !== "OK") {
                this.setState({ newProfilePopupErrorMessage: ReactHtmlParser(res.data.message), isLoading: false });
            } else {
                this.setState({ successMessage: <FormattedMessage id='configure.popup.selectedNewProfilePopup.successMessage' />, isLoading: false });
            }
        } else {
            this.props.closeModal("isConfigDeviceModalOpen");
        }
        if (Object.keys(this.props.selectedNode).length > 0) {
            await this.context.loadNextlevel({ "node": this.props.selectedNode }, this.props.selectedNode.routeParams ? this.props.selectedNode.routeParams : null, null, this.props.selectedNode.name);
            await this.context.getDeviceList()
        }
    }

    componentWillUnmount() {
        allDeviceData["MM3"] = [];
        allDeviceData["UM3+"] = [];
        allDeviceData["ZM1"] = [];
        allDeviceData["UM1"] = [];
        allDeviceData["MM3ai"] = [];
        allDeviceData["VC10"] = [];
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        const mm3Data = groupingSelectedRows(tableOptions.selectedRows.filter(x => ['MM3', 'UM1', 'MM3ai'].includes(x.deviceType) ), ['deviceType', 'profileName']);
        const notMM3Data = groupingSelectedRows(tableOptions.selectedRows.filter(x => !['MM3', 'UM1', 'MM3ai'].includes(x.deviceType) ), ['deviceType', 'softwareVersion', 'hardwareVersion', 'profileName']);
        const groupedData = Object.assign({}, mm3Data, notMM3Data);
        if (this.props.profileList && Object.keys(this.props.profileList).length > 0) {
            const configurePopupData = { ...allDeviceData };
            Object.keys(groupedData).sort().map(label => this.fetchAllDeviceData(groupedData, label, configurePopupData));
            this.setState({ isLoading: false });
        }
        this.setState({ groupedData: groupedData });
    }

    fetchAllDeviceData = (groupedData, label, configurePopupData) => {
        Object.keys(groupedData[label]).map(fwVersion => {
            if ( ['MM3', 'UM1', 'MM3ai'].includes(label)) {
                const obj = {};
                obj.deviceCount = groupedData[label][fwVersion].length;
                obj.currentProfile = fwVersion;
                obj.newProfile = this.newProfileData(label);
                configurePopupData[label].push(obj)
            }
            return ( !['MM3', 'UM1', 'MM3ai'].includes(label)) && Object.keys(groupedData[label][fwVersion]).map(hwRevision => {
                return Object.keys(groupedData[label][fwVersion][hwRevision]).forEach(item => {
                    const obj = {};
                    obj.deviceCount = groupedData[label][fwVersion][hwRevision][item].length;
                    obj.fwVersion = fwVersion;
                    obj.hwRevision = hwRevision;
                    obj.currentProfile = item;
                    obj.newProfile = this.newProfileData(label, fwVersion, hwRevision);
                    configurePopupData[label].push(obj)
                });
            });
        })
    }
    newProfileData = (label, fwVersion, hwRevision) => {
        const configData = [];
        const profileList = fwVersion ? this.props.profileList && this.props.profileList[label.toLowerCase()][fwVersion + "_" + hwRevision] : this.props.profileList && this.props.profileList[label.toLowerCase()]
        profileList && profileList.map(field => {
            return configData.push({ label: field.name, value: field.name });
        });
        configData && configData.sort((a, b) => {
            let x = a.label.toUpperCase(),
                y = b.label.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
        });
        return configData;
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
        return btnName === "next" ? this.onNextBtnClickHandler() :
            btnName === "cancel" ? this.cancelClickHandler() :
                btnName === "confirm" ? this.confirmClickHandler() :
                    btnName === "updateConfCancel" ? this.updateConfCancelClickHandler() :
                        btnName === "ok" ? this.updateConfOkClickHandler() :
                            btnName === "close" ? this.props.closeModal("isConfigDeviceModalOpen") : null;
    }

    componentDidUpdate() {
        const configurePopupData = { ...allDeviceData };
        if ((this.props.profileList && Object.keys(this.props.profileList).length > 0) && configurePopupData["MM3"].length === 0 && configurePopupData["MM3ai"].length === 0 && configurePopupData["UM3+"].length === 0 && configurePopupData["ZM1"].length === 0 &&configurePopupData["UM1"].length === 0 && configurePopupData["VC10"].length === 0) {
            this.setState({ isLoading: true })
            Object.keys(this.state.groupedData).sort().map(label => this.fetchAllDeviceData(this.state.groupedData, label, configurePopupData));
            this.setState({ isLoading: false });
        }
    }

    render() {
        const selectedRowsCount = tableOptions.selectedRows && tableOptions.selectedRows.length;
        const breadCrumb = getClassName({ node: this.props.selectedNode });
        const selectedRowsGroupBy = Object.fromEntries(Object.entries(this.state.groupedData).map(([k, v]) => [k.toUpperCase(), v]));
        let modalTitle, modalBody, modalFooter;
        if (!this.state.updateConfirmationPopup) {
            modalTitle = <div className="popup-title"><FormattedMessage id={`configure.popup.title`} /> [ {!this.state.selectedNewProfilePopup ? selectedRowsCount : this.state.newProfileRowsCount} Device(s)] {breadCrumb} </div>;
            modalBody = <div className="min-vh-25 single-select-dropdown">
                {this.state.isLoading || this.props.isLoadingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                {!this.state.selectedNewProfilePopup ?
                    <Fragment>
                        {this.state.errorMessage ? <span className="text-danger float-right"> {this.state.errorMessage}</span> : ""}
                        {Object.entries(allDeviceData).map(([key, value], i) => {
                            return (
                                <Form key={key + '-' + i}>
                                    {<Fragment>
                                        {value.length > 0 ? <Row className="col-12"><strong><u>{key}</u></strong></Row> : null}
                                        {value.length > 0 && value.map((item, itemKey) => {
                                            return (
                                                <div key={key + '-' + (itemKey + i) + '-' + (Math.floor((Math.random() * 100)))} className="col-12 mt-2 m-0 p-0">
                                                    <Row className="m-0 p-0 mb-2">
                                                        {Object.entries(item).map(([field, data], k) => {
                                                            let dataToBeReturned;
                                                            if (!(['UM3+'].includes(key)) && itemKey === 0) dataToBeReturned = <div key={key + '-' + itemKey + '-' + (Math.floor((Math.random() * 100)))} className={(['MM3', 'MM3ai', 'UM1'].includes(key) && field === "deviceCount" ? "col-6" : field === "currentProfile" || field === "newProfile" ? "col-3" : "col-2") + " m-0 p-0"}><strong><FormattedMessage id={`configure.popup.label.${field}`} /></strong></div>
                                                            if (['UM3+'].includes(key)) {
                                                                dataToBeReturned = field === "deviceCount" && itemKey === 0 ?
                                                                    <Fragment key={key + '-' + (Math.floor((Math.random() * 10)))}>
                                                                        <Row className="col-12"><div className="mt-2 m-0 p-0"><strong><FormattedMessage id={`configure.popup.label.${field}`} /></strong></div></Row>
                                                                        <Row className="col-12"><div className="m-0 p-0">{data}</div></Row>
                                                                    </Fragment> :
                                                                    field === "deviceCount" && itemKey !== 0 ? <Row key={key + '_' + (Math.floor((Math.random() * 10)))} className="col-12"><div className="m-0 p-0">{data}</div></Row> :
                                                                        field === "fwVersion" ?
                                                                            <Fragment key={key + '-' + k + '-' + (Math.floor((Math.random() * 10)))}>
                                                                                <div className="col-2 m-0 p-0"> </div>
                                                                                <div className={"col-2 m-0 p-0"}><strong><FormattedMessage id={`configure.popup.label.${field}`} /></strong></div>
                                                                            </Fragment> : <div key={key + '-' + k + itemKey + '-' + (Math.floor((Math.random() * 10)))} className={(field === "currentProfile" || field === "newProfile" ? "col-3" : "col-2") + " m-0 p-0"}><strong><FormattedMessage id={`configure.popup.label.${field}`} /></strong></div>
                                                            }
                                                            return dataToBeReturned;
                                                        })}
                                                    </Row>
                                                    <Row className="m-0 p-0 mb-3 deviceStatePopup">
                                                        {Object.entries(item).map(([field, data], j) => {
                                                            if (!Array.isArray(data)) {
                                                                return (['UM3+'].includes(key)) && field === "deviceCount" ? <div key={key + '_' + j + '_' + (Math.floor((Math.random() * 100)))} className="col-2 m-0 p-0"></div> :
                                                                    <div key={key + '-' + j + '-' + (Math.floor((Math.random() * 100)))} className={(['MM3', 'MM3ai', 'UM1'].includes(key) && field === "deviceCount" ? "col-6" : field === "currentProfile" || field === "newProfile" ? "col-3" : "col-2") + " m-0 p-0"}>{data !== "" && data !== "null" ? data : "-"}</div>
                                                            } else {
                                                                return (
                                                                    <div key={key + '-' + field + '-' + (Math.floor((Math.random() * 100)))} className="col-3 m-0 p-0">
                                                                        <Fragment>
                                                                            <SingleSelect data={data} setType={(e) => (!['MM3', 'MM3ai'].includes(key)) ? this.ddlValueUpdate(e, key.toLocaleLowerCase(), item.fwVersion, item.hwRevision, item.currentProfile) : this.ddlValueUpdate(e, key.toLocaleLowerCase(), null, null, item.currentProfile)} value={this.state.selectedItems[key.toLowerCase()] ? this.state.selectedItems[key.toLowerCase()][item.fwVersion] ? this.state.selectedItems[key.toLowerCase()][item.fwVersion][item.hwRevision] ? this.state.selectedItems[key.toLowerCase()][item.fwVersion][item.hwRevision][item.currentProfile] ? this.state.selectedItems[key.toLowerCase()][item.fwVersion][item.hwRevision][item.currentProfile] : "--select--" : "--select--" : this.state.selectedItems[key.toLowerCase()][item.currentProfile] ? this.state.selectedItems[key.toLowerCase()][item.currentProfile] : "--select--" : "--select--"} icon='ok' />
                                                                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                                                        </Fragment>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </Fragment>
                                    }
                                </Form>
                            )
                        })}
                    </Fragment> :
                    <Fragment>
                        {!this.state.newProfilePopupErrorMessage && !this.state.successMessage ?
                            Object.entries(this.state.selectedItems).map(([key, value], i) => {
                                return (
                                    <Form key={key + '-' + value + '-' + i}>
                                        <Row className="col-12"><strong><u>{key.toUpperCase()}</u></strong></Row>
                                        <div className="col-12 mt-2 m-0 p-0">
                                            <Row className="m-0 p-0 mb-3">
                                                <div className="col-6 m-0 p-0"><strong><FormattedMessage id='configure.popup.label.devices' /></strong></div>
                                                <div className="col-6 m-0 p-0 pl-2"><strong><FormattedMessage id='configure.popup.label.profile' /></strong></div>
                                            </Row>
                                            {Object.entries(value).map(([fwVersion, data]) => {
                                                if (['mm3', 'um1', 'mm3ai'].includes(key)) {
                                                    return (
                                                        <Row key={fwVersion + '-' + data} className="m-0 p-0 mb-4">
                                                            <div className="col-6 m-0 p-0 newStateDevices">{selectedRowsGroupBy[key.toUpperCase()][fwVersion].map(property => <div key={property.id}>{property.serialNumber}</div>)} </div>
                                                            <div className="col-6 m-0 p-0 pl-2">{data}</div>
                                                        </Row>
                                                    )
                                                }
                                                return (!['mm3', 'um1', 'mm3ai'].includes(key)) && Object.entries(data).map(([hwRevision, field]) => {
                                                    return Object.entries(field).map(([item, data]) => {
                                                        return (
                                                            <Row key={fwVersion + '-' + hwRevision + '-' + item + '-' + data} className="m-0 p-0 mb-4">
                                                                <div className="col-6 m-0 p-0 newStateDevices">{selectedRowsGroupBy[key.toUpperCase()][fwVersion][hwRevision][item].map(property => <div key={property.id}>{property.serialNumber}</div>)}</div>
                                                                <div className="col-6 m-0 p-0 pl-2">{data}</div>
                                                            </Row>
                                                        )
                                                    })
                                                })
                                            })}
                                        </div>
                                    </Form>
                                )
                            }) :
                            <Fragment>
                                {this.state.newProfilePopupErrorMessage ?
                                    <span className="text-danger">{this.state.newProfilePopupErrorMessage}</span> :
                                    <span className="text-success">{this.state.successMessage}</span>}
                            </Fragment>
                        }
                    </Fragment>
                }
            </div>;
            modalFooter = !this.state.selectedNewProfilePopup ? [{ className: "modalSaveBtn", name: <FormattedMessage id='configure.popup.button.next' />, value: "next" }] : !this.state.newProfilePopupErrorMessage && !this.state.successMessage ? [{ className: "modalCancelBtn", name: <FormattedMessage id='configure.popup.button.cancel' />, value: "cancel", disabled: this.state.isBtnDisabled }, { className: "modalSaveBtn", name: <FormattedMessage id='configure.popup.button.confirm' />, value: "confirm", disabled: this.state.isBtnDisabled }] : [{ className: "modalSaveBtn", name: <FormattedMessage id='configure.popup.button.close' />, value: "close" }];
        } else {
            modalTitle = <div className="popup-title"><FormattedMessage id={`configure.updateConfirmation.popup.title`} /></div>;
            modalBody = <div className="min-vh-25">
                <div className="mb-3"><FormattedMessage id='configure.popup.updateConfirmation.message' /></div>
                <div className="mb-3"><FormattedMessage id='configure.popup.updateConfirmation.unRegistered' /></div>
                <div className="mb-3"><FormattedMessage id='configure.popup.updateConfirmation.configInprocess' /></div>
                <div className="mb-3"><FormattedMessage id='configure.popup.updateConfirmation.fwUpgradeInprocess' /></div>
                <div className="mb-3"><FormattedMessage id='configure.popup.updateConfirmation.wanttoContinue' /></div>
            </div>
            modalFooter = [{ className: "modalCancelBtn", name: <FormattedMessage id='configure.popup.button.cancel' />, value: "updateConfCancel" }, { className: "modalSaveBtn", name: <FormattedMessage id='configure.popup.button.ok' />, value: "ok" }]
        }
        return (
            <Fragment>
                {this.props.isConfigDeviceModalOpen ?
                    <ModalWindow
                        show={this.props.isConfigDeviceModalOpen}
                        onHide={() => this.props.closeModal("isConfigDeviceModalOpen")}
                        size={"lg"}
                        dialogClassName={'pingModal'}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        )
    }
}
export default ConfigureDeviceComponent;
import React from 'react';
import { Form } from 'react-bootstrap';

const CheckBox = ({ data, label, field, checked, disabled, displayInline, handleOnchange }) => {
    return (
        <Form>
            {!Array.isArray(data) ?
                <Form.Check
                    type='checkbox'
                    label={<span style={{fontSize:"1.5vh"}}>{label}</span>}
                    checked={checked}
                    disabled={disabled ? true : false}
                    inline={displayInline ? true : false}
                    onChange={(e) => handleOnchange(field, e.target.checked)}
                />
                : data.map((item, key) => {
                    return <Form.Check
                        key={key}
                        type='checkbox'
                        disabled={item.disabled ? true : false}
                        checked={item.value}
                        label={item.label}
                        inline={item.displayInline ? true : false}
                        onChange={(e) => handleOnchange(field, e.target.checked)}
                    />
                })
            }
        </Form>
    )
}

export default CheckBox;
//data = [{label:"lablename", value: true}]
import React, { Component } from 'react'
import UserManagementContainer from './containers/userContainer'
import { Row, Col, Container } from 'react-bootstrap';
import './index.css'
import { FormattedMessage } from 'react-intl';
import UserProvider from './provider/userProvider';

class UserManagement extends Component {

    render() {
        return (
            <Container fluid className="user-mngmnt">
                <Row>
                    <Col className="p-0 m-0">
                        <div style={{ paddingBottom: "10px" }}><FormattedMessage id='db.common.setting' /> : <FormattedMessage id='nav.main.usermanagement' /></div>
                        <UserProvider>
                            <UserManagementContainer />
                        </UserProvider>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default UserManagement;
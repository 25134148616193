import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { OtapStatusContext } from '../provider/otapStatusProvider';
import JobStatus from '../components/jobStatusComponent';
import OtapDeviceListTable from '../components/otapDeviceListTableComponent';
import OtapStatusActions from '../components/otapStatusActionsComponent';
import Loader from '../../../../hoc/loader';
import BlankPage from '../../../../hoc/blankPage';
import { postOtapStatusData } from '../services/otapstatusService';
import * as currentJobUtility from '../../../../utility/currentJobUtility';

class JobDetailsContainer extends Component {
    static contextType = OtapStatusContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            displayColumns: currentJobUtility.selectedColumn.data,
        }
    }

    /* Device list table columns filter */
    filterOtapTableColumn = (value) => {
        let prvData = this.state.displayColumns;
        let filterData = [];
        if (prvData.includes(value)) filterData = prvData.filter(item => item !== value)
        else filterData = [...prvData, value]
        currentJobUtility.selectedColumn.data = filterData;
        sessionStorage.setItem("otapExpandColumn",  JSON.stringify(filterData));
        this.setState({ displayColumns: filterData })
    }

    handleOnChangeCheckBox = (event, device) => {
        event.target.checked ? currentJobUtility.tableOptions.selectedRows.push(device) : currentJobUtility.tableOptions.selectedRows.splice(currentJobUtility.tableOptions.selectedRows.indexOf(device), 1)
        if (currentJobUtility.tableOptions.selectedRows.length > 0) {
            this.enableDisableGroupIcon(currentJobUtility.tableOptions.selectedRows, 'abort');
            this.enableDisableGroupIcon(currentJobUtility.tableOptions.selectedRows, 'retry');
        } else {
            // reset the group icon 
            currentJobUtility.tableOptions.enableAbortBtn = false;
            currentJobUtility.tableOptions.enableRetryBtn = false;
        }
    }

    enableDisableGroupIcon = (value, type) => {
        for (var i = 0; i < value.length; i++) {
            let d = value[i];
            if (type === 'abort' && (currentJobUtility.checkUpgradeJobsAbort(d) || currentJobUtility.configStatusCheck(d, 'configStatus'))) {
                currentJobUtility.tableOptions.enableAbortBtn = false;
                return;
            } else if (type === 'retry' && (currentJobUtility.otapStatusCheckUpgradeJobsRetry(d) || currentJobUtility.configStatusCheck(d, 'configStatus'))) {
                currentJobUtility.tableOptions.enableRetryBtn = false;
                return;
            }
        }
        type === 'abort' ? currentJobUtility.tableOptions.enableAbortBtn = true : currentJobUtility.tableOptions.enableRetryBtn = true;

    };

    handleActionItems = (type) => {
        var deviceId = [];
        var failureDevices = this.context.state.failureDevices;

        switch (type) {
            case "abort":
                currentJobUtility.tableOptions.enableAbortBtn = false;
                currentJobUtility.tableOptions.selectedRows.length > 0 && currentJobUtility.tableOptions.selectedRows.forEach((device) => {
                    deviceId.push(device.id);
                })
                break;
            case "retry":
                currentJobUtility.tableOptions.enableRetryBtn = false;
                currentJobUtility.tableOptions.selectedRows.length > 0 && currentJobUtility.tableOptions.selectedRows.forEach((device) => {
                    deviceId.push(device.id + "");
                })
                break;
            case "retryAll":
                currentJobUtility.tableOptions.enableRetryAllBtn = false;
                break;
            default:
                break;
        }
        var devices = (type === 'retryAll') ? failureDevices : deviceId;
        this.makeActionItemsAPiCall(type, devices);
    }
    makeActionItemsAPiCall = (type, devices) => {
        var params = { 'JOBNAME': this.context.state.selectedJobName };
        this.setState({ isLoading: true }, () => {
            postOtapStatusData(params, devices, type)
                .then(data => {
                    if (data.status !== 'FAIL') {
                        this.context.fetchJobList();
                    }
                    this.setState({ isLoading: false });
                }).catch(error => {
                    this.setState({ isLoading: false });
                });
        })
    }
    componentDidMount = async () => {
        !sessionStorage.getItem("otapExpandColumn") ? sessionStorage.setItem("otapExpandColumn", JSON.stringify(this.state.displayColumns)) : this.setState({displayColumns:[...JSON.parse(sessionStorage.getItem("otapExpandColumn"))]});
    }
    render() {
        return (
            <Fragment >
                <JobStatus />
                <OtapStatusActions handleActionItems={(type) => this.handleActionItems(type)} filterOtapTableColumn={this.filterOtapTableColumn} stateObj={this.state} />
                {this.context.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                <div className="device-table">{this.context.state.deviceListTableDataset.length > 0 ?
                    <OtapDeviceListTable handleOnChangeCheckBox={(evt, device) => (this.handleOnChangeCheckBox(evt, device))} stateObj={this.state} /> :
                    <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
                </div>

            </Fragment>
        )
    }
}

export default JobDetailsContainer;
import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DGWContext } from '../provider/dgwProvider';
import DgwUploadCertificateModal from './dgwUploadCertificateModal';
import DgwPrivateKeyModal from './dgwPrivateKeyModal';


class DgwUploadCertificateContainer extends Component {
    static contextType = DGWContext;

    render() {
        return (
            <Row className='sg-upload-certificates'>
                <Col>
                    <span className="sensor-getway-btn" onClick={this.context.openDisplayCertificateModal}><FontAwesomeIcon icon={faPlus} size="sm" /></span>
                </Col>
                {this.context.state.isOpenUploadCertModal ? <DgwUploadCertificateModal /> : ""}
                {this.context.state.isOpenPkeyModal ? <DgwPrivateKeyModal /> : ""}                
            </Row>
        );
    }
}

export default DgwUploadCertificateContainer;
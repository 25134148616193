import React, { Fragment } from 'react';
import { Table, Col, Row } from 'react-bootstrap';
import NoDataAvailableComponent from '../../../../hoc/noDataAvailableComponent';
import { FormattedMessage } from 'react-intl';

const TabList = (props) => Object.entries(props.tabData).sort().map(([key, value], index) => {
    return (
        <tr key={index}>
            <td>{key}</td>
            <td>{value}</td>
        </tr>
    )
});

let content;
const ManageSummaryComponent = (props) => {
    const summaryDataExist = props.summaryData && Object.keys(props.summaryData).length > 0 && props.summaryData.map(key => { return Object.keys(key.details).length > 0 ? true : false}).includes(true);
    if (summaryDataExist) {
        content = props.summaryData.map((summary, index) => {
            return (
                <Fragment key={index}>
                    <Col className="summary-widget float-left ml-0 pl-0" key={index} xs={6} md={6}>
                        <div className="text-center summary-title "><FormattedMessage id={`dm.column.deviceSummary`} /> <FormattedMessage id={`dm.column.title.${summary.type}`} /></div>
                        <Table className="tableBodyScroll" bordered hover >
                            <thead>
                                <tr>
                                    <th className="text-bold"><FormattedMessage id={`dm.column.${summary.type}`} /></th>
                                    <th className="text-bold"><FormattedMessage id={`dm.column.deviceCount`} /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(summary.details).length === 0 ? <div className="inner-table-data"><NoDataAvailableComponent /></div>: <TabList tabData={summary.details} />}
                            </tbody>
                        </Table>
                    </Col>
                    {index === 1 ? <div className="clear"></div> : null}
                </Fragment>
            )
        }
        );
    } else {
        content = !props.loading ? <div className="wid-summary"><NoDataAvailableComponent /></div> : ""
    }

    return (
        <Row className="show-grid summary-table m-0 p-0 mt-4">
            <Col xs={12} md={12} className="m-0 p-0">
                {content}
            </Col>
        </Row>
    )
}

export default ManageSummaryComponent;
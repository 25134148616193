import React, { Component, Fragment } from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { CustomReportContext } from '../provider/customReportProvider';
import CustomReportTree from '../../../hoc/customReportTree';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../hoc/tooltip';
import MultiSelectDropdown from '../../manage/details/components/multiselectDropdownComponent';


class CustomFilter extends Component {
    static contextType = CustomReportContext;
    state = {
        selectedReportObj : this.context.state.selectedReportObj,
       // reloadTree : true
    }
    componentDidUpdate(prevProps){
       if(this.state.selectedReportObj.id !== this.context.state.selectedReportObj.id){
          this.setState({selectedReportObj :  this.context.state.selectedReportObj})
       }
    }

    render() {
        const filterColumns = this.context.state.filters.map((column, index) => {
            return ((column.type === "Select" && !column.additionalInfo.hierarchyDetails.multiSelect) ?
                <div key={index} className="row m-0 p-0 mb-2 single-select-dropdown">
                    <Col xs={4}>
                        <label className="customFilterStyle m-0 p-0"><FormattedMessage id={`customreports.label.${column.name}`} /></label>
                    </Col>
                    <Col xs={8} >
                         <MultiSelectDropdown
                                                selectedArrayList = {column.selection.list.filter(e => e.text === column.selection.selected)}
                                                optionList={[...column.selection.list]}
                                                onChange={(e) => { this.context.updateSingleSelectVal(e, column.name) }}
                                                name={column.name}
                                                multiple={false}
                                                includeSelectAll={false}
                                                includeFilter={false}
                                                open={false}
                                                valueKey="value"
                                                labelKey="text"
                                                keepOpen={true}
                                                placeholder="Select"
                                            /> 
                        <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                    </Col>
                </div> : column.type === "Hierarchy" ?
                    <div key={index} className="row m-0 p-0 mb-2">
                        <Col xs={4}>
                            <label className="customFilterStyle m-0 p-0"><FormattedMessage id={`customreports.label.${column.name}`} /></label>
                        </Col>
                        <Col xs={8}>
                            <CustomReportTree
                                passUpdatedData={ this.context.receiveUpdatedData}
                                expandUpto={column.additionalInfo.hierarchyDetails.maxHierarchyLevel} //display up to //column.additionalInfo.hierarchyDetails.maxHierarchyLevel
                                checkedLableName={"subscribedForWatchlist"} //for checkbox true or false by this value
                                from={'reports/custom'} //for api call
                                checkBoxVisible={'all'} // visible checkbox level
                                needToPersistence={true} // reload the tree data
                            /> 
                        </Col>
                    </div>
                    :  column.type === "Text" ? 
                    <div key={index} className="row m-0 p-0 mb-2">
                        <Col xs={5}>
                            <label className="fullwidth m-0 p-0">{column.label}<span>{column.name === "percentVal" ? '*' : ""}</span></label>
                        </Col>
                        <Col xs={7}>
                            <input type="text" className='inpcls fullwidth' value={column.value} onChange={(e)=>{this.context.updateTextFieldVal(e,column.name)}}/>
                            {column.name === "percentVal" && this.context.state.filterPercentErr && <div className='text-right mt-1 form-err-color-red'>{this.context.state.filterPercentErr}</div>}
                        </Col>
                    </div>
                    :
                    null
            )
        });
        const content = !this.context.state.loader ?  this.state.selectedReportObj.id !== this.context.state.selectedReportObj.id ? null : filterColumns : filterColumns
        return(
            <Fragment>
            <Dropdown id="dropdown-custom-filters" className={this.context.state.filters.length === 0 ? 'disabled pr-1' : 'pr-1'} >
                <Dropdown.Toggle className="btn-outline-secondary btn-group-icons btn-light grp-btn-first m-0"  ref={(ref) => (this.customReportFilterRef = ref)} disabled={false}>
                    <Tooltip 
                                placement='top' 
                                message={<FormattedMessage id={`customreports.label.filters`} />} 
                                baseContent={   <FontAwesomeIcon icon={faFilter} />}/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-disable-autohide dropdown-filters">
                     {content}
                    {/* {filterButtons}   */}
                </Dropdown.Menu>
            </Dropdown>
            {/* <span className="badge badge-notify">{this.filterLength > 0 ? this.filterLength : ""}</span> */}
        </Fragment>
        )
    }}

export default CustomFilter;
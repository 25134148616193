import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { notificationTemplatesData } from '../../../utility/notificationTemplateUtility';
import ModalWindow from '../../../hoc/modelWindow';
import NotificationTemplateForm from '../components/notificationTemplateForm';
import { NotificationTemplateContext } from '../provider/notificationTemplateProvider';
import Loader from '../../../hoc/loader';

class NotificationTemplateContainer extends Component {
    static contextType = NotificationTemplateContext;
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: true
        }
    }

    closeNotificationTemplateModal = () => {
        this.context.resetDataOnCloseModal()
        notificationTemplatesData.isOpenModal = false;
        this.setState({ isOpenModal: true })
    }

    render() {
        return (
            <Row>
                <Col>
                    {notificationTemplatesData.isOpenModal ?
                        <ModalWindow
                            show={this.state.isOpenModal}
                            onHide={this.closeNotificationTemplateModal}
                            size={'lg'}
                            title={<FormattedMessage id={"settings.notificationTemplates.text.title"}/>}
                            dialogClassName={'notification-templates-modal'}
                            modeldata={{
                                content:
                                    <React.Fragment>
                                        {this.context.state.isLoadingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                                        <NotificationTemplateForm />
                                    </React.Fragment>
                            }}
                        />
                        : ""}
                </Col>
            </Row>
        );
    }
}

export default NotificationTemplateContainer;
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber'

export const tableData = {
    // pageNo:1,
    // pageSize: 10,
    // pageSizeOptions: [10, 25, 50, 75],
    // previousText: <FormattedMessage id='db.common.pgntn.previous' />,
    // nextText: <FormattedMessage id='db.common.pgntn.next' />,
    // pageText: <FormattedMessage id='db.common.pgntn.rowsPerPage' />,
    // ofText: <FormattedMessage id='db.common.pgntn.of' />,
    // rowsText: <FormattedMessage id='db.common.pgntn.rows' />,
    // firstText: <FormattedMessage id='db.common.pgntn.first' />,
    // lastText: <FormattedMessage id='db.common.pgntn.last' />,

    columnsData: [
        { accessor: 'email', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.common.email' />} />, minWidth: 200, sortedOrder : 0 },
        { accessor: 'roleName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.common.role' />}/>, sortedOrder : 0 },
        { accessor: 'firstName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.common.firstName' />}/>, sortedOrder : 0 },
        { accessor: 'middleName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.common.middleName' />}/>, sortedOrder : 0 },
        { accessor: 'lastName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.common.lastName' />}/>, sortedOrder : 0 },
        { accessor: 'enabled', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.common.enabled' />}/>, sortedOrder : 0 },
        { accessor: 'resetPasswordRequired', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.common.resetPassword' />}/>, sortedOrder : 0 },
        { accessor: 'timezone', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.common.timeZone' />}/>, sortedOrder : 0, minWidth: 160 },
    ],
}

export const userMngmntData = {
    isOpenAboutModal : false,
    isOpenUpdateProfileModal: false,
    isOpenEmailSubscriptionsModal : false,
    currentTab: 'general',
    culumnFilter: [
        { label: <FormattedMessage id='db.common.email' />, value: "email" },
        { label: <FormattedMessage id='db.common.role' />, value: "roleName" },
        { label: <FormattedMessage id='db.common.firstName' />, value: "firstName" },
        { label: <FormattedMessage id='db.common.middleName' />, value: "middleName" },
        { label: <FormattedMessage id='db.common.lastName' />, value: "lastName" },
        { label: <FormattedMessage id='db.common.enabled' />, value: "enabled" },
        { label: <FormattedMessage id='db.common.resetPassword' />, value: "resetPasswordRequired" },
        { label: <FormattedMessage id='db.common.timeZone' />, value: "timezone" }
    ],
    allTabs: [{ label: <FormattedMessage id='db.common.general' />, value: 'general' }, { label: <FormattedMessage id='db.common.preferences' />, value: 'preferences' }],
    formFields: {
        general: [
            { label: "Email", value: "email", type: "text", isMandatory: true },
            { label: "First Name", value: "firstName", type: "text", isMandatory: false },
            { label: "Middle Name", value: "middleName", type: "text", isMandatory: false },
            { label: "Last Name", value: "lastName", type: "text", isMandatory: false },
            { label: "Password", value: "newPassword", type: "password", isMandatory: true },
            { label: "Confirm Password", value: "confirmPassword", type: "password", isMandatory: true },
            { label: "Role", value: "role", type: "select", options: [], isMandatory: true },
        ],
        preferences: [
            { label: <FormattedMessage id='db.common.timeZone' />, value: "timezone", type: "select", options: [], isMandatory: true },
            { label: <FormattedMessage id='db.common.temperatureUnit' />, value: "temperaturePreference", type: "select", options: [{ label: "Fahrenheit", value: "F" }, { label: "Celsius", value: "C" }], isMandatory: true },
            { label: <FormattedMessage id='db.common.treeOrder' />, value: "treeOrder", type: "select", options: [{ label: "Alphabetical", value: "Alphabetical" }, { label: "Custom", value: "Custom" }], isMandatory: true }
        ]
    }
}

export const changePasswordData = {
    isOpenChangePasswordeModal: false,
    formFields: [
        { label: <FormattedMessage id='db.changepassword.label.oldPassword' />, placeholder: 'db.changepassword.placeholder.oldPassword', value: "currentPassword", type: "password", isMandatory: true },
        { label: <FormattedMessage id='db.changepassword.label.newPassword' />, placeholder: 'db.changepassword.placeholder.newPassword', value: "newPassword", type: "password", isMandatory: true },
        { label: <FormattedMessage id='db.changepassword.label.confirmNewPassword' />, placeholder: 'db.changepassword.placeholder.confirmNewPassword', value: "confirmNewPassword", type: "password", isMandatory: true },
    ]
}

export const selectedColumn = {
    data: ['checkBox', 'email', 'roleName', 'firstName', 'middleName', 'lastName', 'enabled', 'resetPasswordRequired', 'action']
}


// function handleOnChangeCheckBox(e, rowInfo) {
//     let preArr = userMngmntData.tableData.allChecked;
//     let filterArr = [];
//     if (e.target.checked) filterArr = [...userMngmntData.tableData.allChecked, rowInfo.principalId]   //if checked true then append principalId to this.state.allChecked 
//     else filterArr = preArr.filter(item => item !== rowInfo.principalId)    // if checked false remove that principalId from this.state.allChecked 

//     let rowData = {};   // when selected only one row, store that row details for Update(edit) user data
//     if (filterArr.length === 1) {
//         rowData = userMngmntData.tableData.usersList.find(item => item.principalId === filterArr[0])
//         userMngmntData.tableData.rowData = rowData;
//     }

//     userMngmntData.tableData.allChecked = filterArr;
// }
import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../index.css'

var BaseMessage = (props) => {
    return (
        <div className={"col-xs-12 col-md-12 padding-0 bg-grey " + props.className}>
            <div className="help-texts">
                <FormattedMessage id={`${props.message}`} /> <br />
                {
                    props.enableSubLink ? <span className="alink" onClick={props.onClickHandler}><FormattedMessage id="events.summary.feederBaseMessage" /> {props.nodeName}</span> : ""
                }
            </div>
        </div>
    )
}

export default BaseMessage
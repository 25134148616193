import React, { Component } from 'react';
import './index.css';
import EmailSubscriptionContainer from './containers/emailSubscriptionContainer'

class EmailSubscription extends Component {

    render() {
        return (
            <React.Fragment>
                <EmailSubscriptionContainer />
            </React.Fragment>
        );
    }
}

export default EmailSubscription;
import React, { PureComponent } from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { FormattedMessage } from 'react-intl';
import Pagination from '../hoc/customPagination';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

class Table extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tableColumnData: props.tableColumn,
            sortOptions: [],
            tableRowData: [],
            pageNum: 1,
            pageSize: 10,
            scrolled: false,
        }
        this.sortChanged = false;
        this.columnsToSort = [];
        this.isClickedSort = false;
        this.initialSize = props.lazyLoadConfigData !== undefined ? props.lazyLoadConfigData.initialSize : "";  //first time display number of table row data, if lazyLoadConfigData is defined
        this.updatedSize = props.lazyLoadConfigData !== undefined ? props.lazyLoadConfigData.updatedSize : ""; //it will increase based on scroll
    }

    componentWillMount() {
        if (this.props.initialSortingColumn !== undefined && !this.isClickedSort) { //first time before rendor checking if initialSortingColumn available, then updating the sortnumber on table header
            this.sortChanged = true;
            this.columnsToSort = this.props.initialSortingColumn;
        }
    }

    componentDidMount() {
        // updating table row data for lazy loading at first time and based on 'this.initialSize' display table row data: start code
        let data = [];
        let sizePerPage = this.props.pageSize !== undefined ? this.props.pageSize : this.state.pageSize;
        if (this.updatedSize < sizePerPage && this.props.lazyLoadConfigData !== undefined) {
            data = this.props.tableRowData.length > this.initialSize ? this.props.tableRowData.slice(0, this.initialSize) : this.props.tableRowData;
        } else data = this.props.tableRowData
        // updating table row data for lazy loading at first time: end code

        this.setState({ //after immediate render updating these data
            tableRowData: data,
            tableColumnData: this.props.tableColumn,
            pageSize: this.props.pageSize !== undefined ? this.props.pageSize : this.state.pageSize    //if pageSize props is undefined then pagesize = 10
        })
    }

    componentWillReceiveProps(nextProps) {
        // updating table row data for lazy loading if props chenged: start code
        let data = [];
        if (nextProps.lazyLoadConfigData !== undefined) {
            this.updatedSize = this.updatedSize !== nextProps.lazyLoadConfigData.updatedSize ? this.updatedSize : nextProps.lazyLoadConfigData.initialSize;
            data = nextProps.tableRowData.slice(0, this.updatedSize)
        } else data = nextProps.tableRowData;
        // updating table row data for lazy loading if props chenged: end code

        if (nextProps.tableRowData.length !== 0) {
            if (this.sortChanged) {
                data.sort((a, b) => {
                    const sortStr1 =this.trimSortValues(a,b);
                    const sortStr2 =this.trimSortValues(b,a);
                    return sortStr1.localeCompare(sortStr2,'en',{numeric:true});
                });
            }
            this.setState({
                tableRowData: data,
                pageSize: nextProps.pageSize !== undefined ? nextProps.pageSize : this.state.pageSize,
                tableColumnData: nextProps.tableColumn
            })
        }
    }

    //for sorting values a,b are objects
    trimSortValues = (a, b) => {
        const splitKeyChar = '~';
        let sortval = this.columnsToSort.reduce((str, key) => {
            a[key.id] = typeof (a[key.id]) === "string" ? a[key.id].trim() : a[key.id];
            b[key.id] = typeof (b[key.id]) === "string" ? b[key.id].trim() : b[key.id];
            return !key.desc ? str + splitKeyChar + a[key.id] : str + splitKeyChar + b[key.id];
        }, '');
        return sortval;
    }

    loadData = async ({ previousPosition, currentPosition, event }) => { // on scroll the page, updating 'this.updatedSize' value, after that updating table row data
        let nextProps = this.props;
        let sizePerPage = nextProps.pageSize !== undefined ? nextProps.pageSize : this.state.pageSize;
        if (this.updatedSize < sizePerPage && previousPosition !== 'above') {
            this.updatedSize += this.initialSize;
            let data = nextProps.tableRowData.slice(0, this.updatedSize);
            this.setState({ tableRowData: data })
            if(this.isClickedSort)
                this.fetchData()
        }
    }

    componentWillUnmount = () => {
        this.sortChanged = false;
        this.columnsToSort = [];
        this.isClickedSort = false;
        this.state.tableColumnData.map(items => delete items.sortedOrder)   //removing sort number on the table header, 
        this.initialSize = "";
        this.updatedSize = "";
    }

    fetchData = () => {
        if (this.sortChanged) {
            let sortedData = [...this.props.tableRowData];
            sortedData.sort((a, b) => {
                const sortStr1 = this.trimSortValues(a,b);
                const sortStr2 = this.trimSortValues(b,a);
                return sortStr1.localeCompare(sortStr2,'en',{numeric:true});
            });
            if (this.columnsToSort.length === 0) {
                let temp = this.state.tableColumnData.find(x => x.sortedOrder > 0);
                if (temp) {
                    temp.sortedOrder = 0
                }
            }
            this.columnsToSort.forEach((sortedCol, i) => {
                this.state.tableColumnData.forEach(col => {
                    var accessorOrId = col.id !== undefined ? col.id : col.accessor
                    if (sortedCol.id === accessorOrId) {
                        col.sortedOrder = this.props.multiSort !== undefined && !this.props.multiSort ? 0 : (i + 1);
                    }
                    else if (!this.columnsToSort.some(sort => sort.id === accessorOrId) && col.sortedOrder > 0) {
                        col.sortedOrder = 0;
                    }
                })
            })
            this.setState({ tableRowData: Object.assign([], this.state.tableRowData, sortedData), sortOptions: this.columnsToSort },()=>{
                this.props.getTableSortedData !== undefined && this.props.getTableSortedData(this.state.tableRowData) //return sorted table row data if getTableSortedData is defined
                
            })
            
        }
    }

    onSortChangeHandler = (e) => {
        this.sortChanged = true;
        this.columnsToSort = e;
        this.isClickedSort = true
    }

    handleMultipleSort = (data) => {
        this.onSortChangeHandler(data);
        this.fetchData();
    }

    updatePaginationNumber = (pageNo) => {
        this.setState({ pageNum: pageNo + 1 }, () => this.props.lazyLoadConfigData !== undefined ? this.updateLazyLoadData() : "")
    }

    onPageSizeOptionChange = pageSize => {
        this.setState({ pageSize: pageSize , pageNum : 1}, () => this.props.lazyLoadConfigData !== undefined ? this.updateLazyLoadData() : "")
    }

    updateLazyLoadData = () => {
        //if normal table updating lazy loading based on onchange value and next or prv page
        this.updatedSize = this.props.lazyLoadConfigData.initialSize;
        let data = this.props.tableRowData.length > this.initialSize ? this.props.tableRowData.slice(0, this.initialSize) : this.props.tableRowData;
        this.setState({ tableRowData: data })
    }

    render() {
        const { pageNo, totalSize, isManualTable, pageSizeOptions, resizable, showPagination, loading, multiSort, sortable, lazyLoadConfigData, rowSelectionCallback, isDisplayPageRows, getTdProps, sorted } = this.props;
        return (
            <React.Fragment>
                <ReactTable
                    columns={this.state.tableColumnData}
                    data={this.state.tableRowData}
                    pageSizeOptions={pageSizeOptions !== undefined ? pageSizeOptions : [10, 25, 50, 75]}
                    onPageSizeChange={this.props.onPageSizeChange !== undefined ? this.props.onPageSizeChange : this.onPageSizeOptionChange}   //onchange pagesize option and update the pageSize. If 'onPageSizeChange' props availabe then 'pagesize' props is required 
                    pageSize={this.state.pageSize}  //display all the table row data based on pagesize,
                    onPageChange={isManualTable !== undefined && isManualTable ? this.props.onPageChange : this.updatePaginationNumber}  //for pagination next, prv, last n first onclick then update the 'page' (pageNo)
                    pageText={<FormattedMessage id='db.common.pgntn.rowsPerPage' />}
                    ofText={<FormattedMessage id='db.common.pgntn.of' />}
                    rowsText={<FormattedMessage id='db.common.pgntn.rows' />}
                    noDataText='No rows found'
                    previousText={<FormattedMessage id='db.common.pgntn.previous' />}
                    nextText={<FormattedMessage id='db.common.pgntn.next' />}
                    firstText={<FormattedMessage id='db.common.pgntn.first' />}
                    lastText={<FormattedMessage id='db.common.pgntn.last' />}
                    totalSize={totalSize !== undefined ? (totalSize > 0 ? totalSize : 0) : this.state.tableRowData.length}
                    showPagination={showPagination !== undefined ? showPagination : true}
                    PaginationComponent={Pagination}
                    canNextFromData={true}
                    loading={loading !== undefined ? loading : false}
                    sortable={sortable !== undefined ? sortable : true}
                    multiSort={multiSort !== undefined ? multiSort : true}
                    minRows={0}
                    className="-striped -highlight"
                    showQuickJumper={false}
                    resizable={resizable !== undefined ? resizable : true}
                    page={isManualTable !== undefined && isManualTable ? pageNo - 1 : this.state.pageNum - 1}
                    manual={isManualTable !== undefined && isManualTable ? true : false}
                    onFetchData={this.fetchData}
                    onSortedChange={isManualTable !== undefined && isManualTable ? this.props.onSortedChange : (data) => this.handleMultipleSort(data)}
                    sorted={sorted ? sorted : this.state.sortOptions}
                    getTrProps={rowSelectionCallback}
                    isDisplayPageRows={isDisplayPageRows !== undefined ? isDisplayPageRows : true}
                    getTdProps={getTdProps !== undefined ? getTdProps : () => ""}
                />
                {lazyLoadConfigData !== undefined ?
                    <Waypoint
                        scrollableAncestor={lazyLoadConfigData.scrollableAncestor !== undefined ? lazyLoadConfigData.scrollableAncestor : window}
                        onEnter={this.loadData}
                        topOffset="120%"
                    /> : null}

            </React.Fragment>
        );
    }
}

Table.propTypes = {
    tableColumn: PropTypes.array,
    tableRowData: PropTypes.array,
    pageNo: PropTypes.number,
    totalSize: PropTypes.number,
    isManualTable: PropTypes.bool,
    pageSizeOptions: PropTypes.array, //[10,20,30,50,100]
    pageSize: PropTypes.number,
    resizable: PropTypes.bool,
    showPagination: PropTypes.bool,
    initialSortingColumn: PropTypes.array, //[{id: "userName", desc: true/false}]
    onPageSizeChange: PropTypes.func,
    onPageChange: PropTypes.func,
    multiSort: PropTypes.bool,
    sortable: PropTypes.bool,
    lazyLoadConfigData: PropTypes.object, //lazyLoadConfigData={{initialSize : 50, updatedSize : 50}}, initialSize should be minimum 30 and both properties(initialSize, updatedSize) are mandatory
    getTrProps: PropTypes.func,
    isDisplayPageRows: PropTypes.bool, //for show or hide page size change section
    getTdProps: PropTypes.func, //for row column onclick function, like onclick of column redirect to other page
    getTableSortedData: PropTypes.func //for send all table row sorted data
}

export default Table;

/*
lazyLoadConfigData={{
    initialSize : 50, (mandatory || initialSize should be minimum 30 and)
    updatedSize : 50,  (mandatory)
    scrollableAncestor : null/window, ( optional || required null value if not a window scroll, this is for indivisual div scroll )
    }}
*/
import React from 'react';

const containsSubString = (string, searchedChar) => {
    if (string && (string.toLowerCase()).indexOf((searchedChar).toLowerCase()) !== -1)
        return true;
    else
        return false;
}
const SearchBar = (props) => {
    return (
        <div className={"dropdown " + (props.showDropdown ? "show" : "")} onBlur={props.onBlur}>
            <input type="text" className="node-search form-control" value={props.searchedString} placeholder="Search" onChange={(evt) => props.inputChangedHandler(evt)} />
            {
                props.showDropdown ?
                    <ul className={"dropdown-menu search-dropdown " + (props.showDropdown ? "d-block" : "")}>
                        {
                            props.searchResults.length > 0 ? props.searchResults.map((x, key) => {
                                return <li key={key} name={x.jobName}>
                                    {
                                        <div className="ddl-items" tabIndex="-1" onMouseDown={() => props.selectSearchedJob(x)}>
                                            {containsSubString(x.serialNumber, props.searchedString) ?
                                                <span className="margin-l-5">{x.serialNumber} - {x.jobName}</span> :
                                                <span className="margin-l-5">{x.jobName}</span>}
                                        </div>
                                    }
                                </li>
                            }) : <li className="text-center">No Data Available</li>
                        }
                    </ul> : ""
            }
        </div>
    )
}

export default SearchBar;
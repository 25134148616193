import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getDateConvertedString } from '../services/utilityService';
import TooltipMessage from '../hoc/tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

function intlFormater(value) {
    return <FormattedMessage id={`${value}`} />
}

export const registerData = {
    startDate: '',
    endDate: new Date().setHours(23, 59, 59, 999),
    displayRecordsCount: 25,
    intervalTime: 30000,
    selectedZoomRange: 7,
    minRange: null,
    maxRange: null,
    notificationCount: 1,
    faultTypeList: [
        { id: 'Fault&nbsp;&nbsp;Sustained Interruption&nbsp;&nbsp;', name: 'Fault Sustained Interruption', text: 'Fault Sustained Interruption', value: 'Fault Sustained Interruption', keyIndex: 0 },
        { id: 'Fault&nbsp;&nbsp;Momentary Interruption&nbsp;&nbsp;', name: 'Fault Momentary Interruption', text: 'Fault Momentary Interruption', value: 'Fault Momentary Interruption', keyIndex: 1 },
        { id: 'Fault&nbsp;&nbsp;No Interruption&nbsp;&nbsp;', name: 'Fault No Interruption', text: 'Fault No Interruption', value: 'Fault No Interruption', keyIndex: 2 },
        { id: 'No Fault&nbsp;&nbsp;Sustained Interruption&nbsp;&nbsp;', name: 'No Fault Sustained Interruption', text: 'No Fault Sustained Interruption', value: 'No Fault Sustained Interruption', keyIndex: 3 },
        { id: 'No Fault&nbsp;&nbsp;Momentary Interruption&nbsp;&nbsp;', name: 'No Fault Momentary Interruption', text: 'No Fault Momentary Interruption', value: 'No Fault Momentary Interruption', keyIndex: 4 },
    ],
    faultStatusList: [
        { id: 'Active', name: 'Active', text: 'Active', value: 'Active', keyIndex: 0 },
        { id: 'Cleared', name: 'Cleared', text: 'Cleared', value: 'Cleared', keyIndex: 1 }
    ],
    zoomRange: [
        { label: intlFormater('common.label.zoom.1'), value: 1 },
        { label: intlFormater('common.label.zoom.7'), value: 7, }
    ],

    selectedData: {
            faultState: [],
            faultType: [
                { id: 'Fault&nbsp;&nbsp;Sustained Interruption&nbsp;&nbsp;', name: 'Fault Sustained Interruption', text: 'Fault Sustained Interruption', value: 'Fault Sustained Interruption', keyIndex: 0 },
                { id: 'Fault&nbsp;&nbsp;Momentary Interruption&nbsp;&nbsp;', name: 'Fault Momentary Interruption', text: 'Fault Momentary Interruption', value: 'Fault Momentary Interruption', keyIndex: 1 },
                { id: 'Fault&nbsp;&nbsp;No Interruption&nbsp;&nbsp;', name: 'Fault No Interruption', text: 'Fault No Interruption', value: 'Fault No Interruption', keyIndex: 2 },
            ],
            minRange: '',
            maxRange: ''
        },

    columnsData: [
        { accessor: 'eventState', Header: '', sortedOrder: 0, Cell: (row) => { return row.original.eventState === "Active" && <span>
            <TooltipMessage 
                placement='top' 
                message={intlFormater('events.register.active')} 
                baseContent={<FontAwesomeIcon color={'#E80000'} icon={faExclamation} size="sm" />}
            />
        </span> }, sortable: false, width:5 },
        { accessor: 'eventType', Header: intlFormater('events.register.column.event'), sortedOrder: 0, Cell: (row) => { return eventTypeFormatter(row.original) }, sortable: false },
        { accessor: 'eventDate', Header: intlFormater('events.register.column.eventDate'), sortedOrder: 0, Cell: (row) => { return getDateConvertedString(row.original.eventDate) } }
    ],
    customFilterColumns: [
        { label: 'Event State:', fName: 'faultState', type: 'multiSelect' },
        { label: 'Event Type:', fName: 'faultType', type: 'multiSelect' },
        {
            label: 'Fault current range:', fName: 'faultCurrentRange', type: 'rangeTextField',
            range: [
                { label: 'Min', fName: 'minRange', type: 'text' },
                { label: 'Max', fName: 'maxRange', type: 'text' }
            ]
        },
    ]
}

function eventTypeFormatter(row) {
    let naValue = notApplicable(row.faultCurrent, 'faultCurrent', row.eventType);
    let formatMsValue = formatMs(row.interruptionDuration, 'uptoSecs', row.eventState, row.eventType);
    return (
        <span>
            {row.eventType && <span dangerouslySetInnerHTML={{ __html: row.eventType }}></span>}
            {naValue !== 'N/A' && naValue !== null && <span>{naValue}A<sub>rms</sub>&nbsp;</span>}
            {formatMsValue !== 'N/A' && formatMsValue !== null && <span>{formatMsValue}</span>}
            {row.phasesAffected !== '' && row.phasesAffected !== null && <span>on Phases {row.phasesAffected.map((value, key) => { return <span key={key}>{(key !== (row.phasesAffected.length - 1)) ? value.name + ', ' : value.name}</span> })}   </span>}
        </span>
    );
};
function notApplicable(input, obj, eventType) {
    var naApplicables = {
        faultCurrent: {
            eventType: ['No Fault Sustained Interruption', 'No Fault Momentary Interruption']
        }
    };
    if (obj === "faultCurrent") {
        if ((input === 0 || input === null) && (naApplicables.faultCurrent.eventType.indexOf(eventType) !== -1)) {
            return 'N/A';
        } else {
            return input;
        }
    }
};
function formatMs(input, format, eventState, eventType) {
    if (input === 0 || input === null || input <= 0) {
        if (eventState.toUpperCase() === 'ACTIVE' || eventType === 'Fault No Interruption' || input < 0) {
            return 'N/A';
        } else {
            return input;
        }
    } else {
        var formatedValue = '';
        var days = Math.floor(input / (24 * 60 * 60 * 1000));
        var daysms = input % (24 * 60 * 60 * 1000);
        var hours = Math.floor((daysms) / (60 * 60 * 1000));
        var hoursms = input % (60 * 60 * 1000);
        var minutes = Math.floor((hoursms) / (60 * 1000));
        var minutesms = input % (60 * 1000);
        var sec = parseFloat(minutesms / 1000);
        if (days) formatedValue += days + 'd ';
        if (hours) formatedValue += hours + 'h ';
        if (minutes) formatedValue += minutes + 'm ';
        if (format && format === "uptoSecs" && sec) formatedValue += sec + 's ';
        return formatedValue;
    }
};

export const keyValue = (data, valueArray, keyName) => {
    var list = [];
    var arrayList = [];
    if (valueArray[0] !== undefined) {
        valueArray.forEach((val, key) => {
            var value = ((typeof (val) === 'object') && (val.id !== undefined)) ? val.id : val;
            arrayList.push(value);
        });
    }
    data.forEach((obj, key) => {
        if (obj[keyName] && (typeof (obj[keyName]) === 'object')) {
            obj[keyName].forEach((val, key) => {
                var value = ((typeof (val) === 'object') && (val.name !== undefined)) ? val.name.toLowerCase() : val;
                if (arrayList.indexOf(value) !== -1) {
                    list.push(obj);
                }
            });
        } else {
            var item = keyName === "sitePhaseValue" ? obj[keyName].toLowerCase() : obj[keyName];
            if (arrayList.indexOf(item) !== -1) {
                list.push(obj);
            }
        }
        // if filter is eventType value as null, including into filtered items
        if (keyName === "eventType" && obj[keyName] === null) {
            list.push(obj);
        }
    });
    return list;
};

export const closeUnusedTreeNodes = (obj,type) => {
    //method to set expand close for treenode to close the expanded tree node exept the current selected tree node
    if (type === "REGION")
        obj.routeParams[1].children.forEach(e => { e.expanded = false; e.arrow = "right" });
    else if (type === "SUBSTATION") {
        obj.routeParams[1].children.forEach(e => {
            if (e.name !== obj.routeParams[3].name) { e.expanded = false; e.arrow = "right"; }
            e.children && e.children.forEach(e1 => { e1.expanded = false; e1.arrow = "right" })
        }
        );
    }
    else if (type === "FEEDER") {
        obj.routeParams[1].children.forEach(e => {
            if (e.name !== obj.routeParams[3].name) {
                e.expanded = false; e.arrow = "right";
            }
            e.children && e.children.forEach(e1 => {
                if (e1.id !== obj.routeParams[5].id) {
                    e1.expanded = false; e1.arrow = "right"
                }
                e1.children && e1.children.forEach(e2 => {
                    if (e2.id !== obj.routeParams[7].id) {
                        e2.expanded = false; e2.arrow = "right"
                    }
                });
            }
            )
        });
    }
    return obj;
}

import React, { Component, Fragment } from 'react';
import DetailsContainer from './containers/detailsContainer';
import { TreeConsumer } from '../../../treeview/provider/treeProvider';
import DetailsProvider from './provider/detailsProvider';
import BaseMessage from '../../../hoc/baseMessage';
import './index.css'

class FaultDetails extends Component {
    render() {
        return (
            <Fragment>
                <TreeConsumer>
                    {(context) => (
                        <React.Fragment>
                            {
                                context.state.highlightedNode.node !== undefined && context.state.highlightedNode.node.type !== 'ROOTNODE' && context.state.highlightedNode.node.type !== 'REGION' ?
                                    <DetailsProvider updatedTreeData={context.state.tree[0]} setDashboardFlagState={context.setDashboardFlagState} setDashboardFlag={context.cancelApiCallFromDashboard} dashboardFlag={context.state.dashboardFlag} loadNextLevel={context.loadNextLevel} selectSearchedNode={context.selectSearchedNode} treeNode={context.state.highlightedNode.node} treeLoader={context.state.loading}>
                                        <div className="events-details">
                                            <DetailsContainer />
                                        </div>
                                    </DetailsProvider>
                                    : <BaseMessage className={"content-height"} message="events.details.baseMessage" />
                            }
                        </React.Fragment>
                    )}
                </TreeConsumer>
            </Fragment >
        );
    }
}


export default FaultDetails;
import React, { Component, Fragment } from 'react';
import { RegisterContext } from '../provider/registerProvider';
import { registerData } from '../../../../utility/faultAnalysisUtility';
import { faultDetailsData } from '../../../../utility/faultDetailsUtility';
import Table from '../../../../hoc/table';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateSelectedTreeFromDashboard } from '../../../dashboard/actions/index';
import { updateFilterData } from '../../../faultAnalysis/register/actions/index';
import { startDateDuration,endDate } from '../../../../filters/dateHelper';

class RegisterTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnData: [
                ...registerData.columnsData,
                { accessor: 'location', Header: <FormattedMessage id={'events.register.column.location'} />, Cell: (row) => { return this.locationFormatter(row.original) }, sortable: false }
            ]
        }
    }

    locationFormatter = (row) => {
        return (
            <span className="alink" onClick={()=>this.redirectToDetails(row)}>
                {row.region && <span>{row.region}</span>}
                {row.substation && <span>&nbsp;&#8594;&nbsp;{row.substation}</span>}
                {row.feeder && <span>&nbsp;&#8594;&nbsp;{row.feeder}</span>}
                {row.upStreamSite && <span>&nbsp;&#8594;&nbsp;{row.upStreamSite}</span>}
            </span>
        );
    }
    redirectToDetails = (row) => {
        sessionStorage.setItem("faultTimeStamp",JSON.stringify({startTimestamp:startDateDuration(this.context.state.selectedZoomRange),endTimestamp:endDate()}));
        const filterDetails = {  eventState: row.eventState, eventType: registerData.faultTypeList.find(obj => obj.id === row.eventType).value, isReDirect: true}
        const feederDetails = { feederName: row.feeder, name: row.feeder, regionName: row.region, substationName: row.substation, type: "FEEDER" }
        this.props.updateSelectedTreeFromDashboard(feederDetails);
        this.props.updateFilterData(filterDetails);
        this.props.history.push("/events/details");
    }
    render() {
        return (
            <Fragment>
                <Table
                    tableColumn={this.state.columnData}
                    tableRowData={this.context.state.filteredTableDataset}
                    showPagination={false}
                    pageSize={this.context.state.filteredTableDataset.length}
                />
                {this.context.state.showLoadMore && <button className="load-more" onClick={() => {this.context.loadMoreFilteredTableData()}}><FormattedMessage id={"events.register.loadMore"} /></button>}
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedTreeFromDashboard: (data) => dispatch(updateSelectedTreeFromDashboard(data)),
        updateFilterData: (data) => dispatch(updateFilterData(data))
    }
}
export default withRouter(connect(null, mapDispatchToProps)(RegisterTable));
RegisterTable.contextType = RegisterContext;
import React, { Component } from 'react';
import { ProfileContext } from '../provider/profilesProvider';
import CommonTabContent from './commonTabContent';

class MM3ProfileConfig extends Component {

    static contextType = ProfileContext;
   
    render(){
        return(
            <div>
            {
                this.context.state.currentTab === "cfciData" ?
                    <CommonTabContent 
                            contentArrParent={this.context.state.profile.selectedProfile.cfci} 
                            updateFormattedMessage={this.props.updateFormattedMessage} 
                            rules={this.context.state.profile.selectedProfile.cfciRules}
                            isBooleanValue={this.props.isBooleanValue}
                            isTimeoffset = {this.props.isTimeoffset}
                            handleOnchangeForms = {this.props.handleOnchangeForms}
                            parentObj = 'cfci'
                            disabled = {false}
                            formError={this.context.state.formError}
                            headerContent = {true}
                            showZM1CfciFields = {this.props.showZM1CfciFields}
                            deviceType={this.props.deviceType}
                            /> 
                    : null
            }
            {
                this.context.state.currentTab === "nonCfciData" ?
                    <div>
                        <CommonTabContent 
                            contentArrParent={this.context.state.profile.selectedProfile.nonCfci} 
                            updateFormattedMessage={this.props.updateFormattedMessage} 
                            rules={this.context.state.profile.selectedProfile.nonCfciRules}
                            isBooleanValue={this.props.isBooleanValue}
                            isTimeoffset = {this.props.isTimeoffset}
                            handleOnchangeForms = {this.props.handleOnchangeForms}
                            parentObj = 'nonCfci'
                            disabled = {false}
                            formError={this.context.state.formError}
                            headerContent = {false}
                            tempDnptimeObj = {this.context.state.tempDnptimeObj}
                            showZM1CfciFields = {this.props.showZM1CfciFields}
                            deviceType={this.props.deviceType}
                            /> 
                </div> : null
            }
            {
                this.context.state.currentTab === "logiData" ?
                    <div>
                         <CommonTabContent 
                            contentArrParent={this.context.state.profile.selectedProfile.logi} 
                            updateFormattedMessage={this.props.updateFormattedMessage} 
                            rules={this.context.state.profile.selectedProfile.logiRules}
                            isBooleanValue={this.props.isBooleanValue}
                            isTimeoffset = {this.props.isTimeoffset}
                            handleOnchangeForms = {this.props.handleOnchangeForms}
                            parentObj = 'logi'
                            disabled = {false}
                            formError={this.context.state.formError}
                            headerContent = {false}
                            showZM1CfciFields = {this.props.showZM1CfciFields}
                            deviceType={this.props.deviceType}
                            /> 
                </div> : null
            }
            {
                this.context.state.currentTab === "anomalyData" ?
                    <div>
                        <CommonTabContent 
                            contentArrParent={this.context.state.profile.selectedProfile.anomaly} 
                            updateFormattedMessage={this.props.updateFormattedMessage} 
                            rules={this.context.state.profile.selectedProfile.anomalyRules}
                            isBooleanValue={this.props.isBooleanValue}
                            isTimeoffset = {this.props.isTimeoffset}
                            handleOnchangeForms = {this.props.handleOnchangeForms}
                            parentObj = 'anomaly'
                            disabled = {!this.context.state.profile.selectedProfile.anomaly?.AedEnableLatch}
                            formError={this.context.state.formError}
                            headerContent = {false}
                            showZM1CfciFields = {this.props.showZM1CfciFields}
                            deviceType={this.props.deviceType}
                            /> 
                </div> : null
            }
            </div>
        )
    }
}
    export default MM3ProfileConfig;
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import TimeFilter from '../../../hoc/timeFilter';
import { connect } from 'react-redux';
import * as dashboardBuilderAction from '../actions/index';
import { dashboardData, heatMapData } from '../../../utility/dashboardUtility';
import { getMomentaryORDisturbance } from '../services/dashboardService';
import HeatMapComponent from '../components/heatMapComponent';
import BreadCrumb from '../../../hoc/breadCrumb';
import Loader from '../../../hoc/loader';
import SingleSelect from '../../../hoc/singleSelect';
import Button from '../../../hoc/button';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { updateFilterData } from '../../faultAnalysis/register/actions/index';
import { disturbancesData } from '../../../utility/disturbanceAnalyticsUtility';
import { dateConvertion } from '../../../services/utilityService';
import BlankPage from '../../../hoc/blankPage';
import { isGreaterThanTodaysDate } from '../../../filters/dateHelper';

class HeatMapChartContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: this.props.heatmapDateRange,
            heatMapOptions: heatMapData.options,
            payload: [...[{'name' : this.props.rootNodeName, type : 'ROOTNODE'}], ...this.props.hitmapPayload.filter(item => item.type !== "ROOTNODE")], // this will cover both payload and bredcrumb data
            requestParams: this.props.requestParams,
            type: this.props.heatmapFilterType,
            series: [],
            isResDataBlank: false,
        }
    }
    componentDidMount() {
        this.getHeatMapChartData();
        this.props.roleAndPermission.licenseData.disturbances.visible && !isGreaterThanTodaysDate(this.props.roleAndPermission.licenseData.disturbances.expiresOn) &&dashboardData.typeFilterData.map((e)=>e.value==='Disturbance'?e.isDisabled=true:e.isDisabled=false);
    }

    getHeatMapChartData = () => {
        this.setState({ isLoding: true })
        let typeVal = this.state.type === 'Fault Momentary Interruption' ? 'momentary' : this.state.type
        let initialPayloadData = { 'orgId': this.props.orgId, 'range': this.state.dateRange, type: typeVal.toLowerCase() };
        let removeElementFromPayload = this.state.payload.filter(item => item.type === 'feeder' ? item.type !== 'ROOTNODE' && item.type !== 'feeder' : item.type !== 'ROOTNODE'); // from this.state.payload checking condition if feeder data available then remove feeder with Rootnode else only remove rootnode. because these data we are not sending to backend
        let arrToObj = Object.fromEntries(removeElementFromPayload.map(item => [item.type, item.name])); //removeElementFromPayload is an array, prepairing array to object and sending to backend. 
        getMomentaryORDisturbance(this.state.requestParams, { ...initialPayloadData, ...arrToObj })
            .then(data => {
                if (data.dataset && data.dataset.length !== 0) {
                var size = 200;
                for (var i = 0; i < data.dataset.length; i += size) {
                    this.handleHeatMapChart(data, data.dataset.slice(i, i + size));
                }
                this.setState({ isLoding: false ,isResDataBlank :false})
            }else
                this.setState({ isLoding: false, isResDataBlank :true })
            }).catch(error => {
                this.setState({ isLoding: false,isResDataBlank :true })
            })
    }
    handleHeatMapChart = (data, filterData) => {
        var processedData = this.processHeatMap(filterData, data.parents.type, data);
        this.updateHeatMapChart(processedData);
    }

    processHeatMap = (dataset, type, data) => {
        var chartData = [];
        var newChart = { ...this.state.heatMapOptions };
        var displayType = type ? type.toLowerCase().charAt(0).toUpperCase() + type.toLowerCase().slice(1) : 'momentary';

        var minMetric = 0, maxMetric = 0;

        for (var i = 0; i < dataset.length; i++) {
            var metricCount = dataset[i][type + 'Count'];
            if (!metricCount || metricCount == null) metricCount = 0;
            if (metricCount < minMetric) minMetric = metricCount;
            if (metricCount > maxMetric) maxMetric = metricCount;
            chartData.push({
                // id: dataset[i] + "",
                name: dataset[i].name,
                value: dataset[i].totalDevicesCount,
                colorValue: metricCount,
                type: displayType,
            });
        }

        newChart.colorAxis.maxColor = (!minMetric && !maxMetric) ? '#2ecc71' : '#e74c3c';
        return chartData;
    };

    updateHeatMapChart(data) {
        this.setState({
            data: [...this.state.series, ...data]
        }, () => {
            var chartE1 = { ...this.state.heatMapOptions };
            chartE1.series[0].data = data;
            this.setState({
                heatMapOptions: chartE1
            })
        })
    }

    removeElement = (prevData) => {
        return prevData.filter(item => item.type === 'feeder' ? item.type !== 'ROOTNODE' && item.type !== 'feeder' : item.type !== 'ROOTNODE');
    }

    handleHeatMapPointClick = (data) => {
        let prevData = this.state.payload;
        prevData.forEach(item => {
            if (item.type !== 'substation' && item.type !== 'feeder' && prevData.length === 1) {
                prevData = [...this.state.payload, ...[{ name: data.name, type: 'region' }]];
                this.setState({ payload: prevData, series: [] }, () => this.getHeatMapChartData())
            }
            if (item.type === 'region' && prevData.length === 2) {
                prevData = [...this.state.payload, ...[{ name: data.name, type: 'substation' }]]
                this.setState({ payload: prevData, series: [] }, () => this.getHeatMapChartData())
            }
            if (item.type === 'substation' && prevData.length === 3) {
                prevData = [...this.state.payload, ...[{ name: data.name, type: 'feeder' }]]
                let arrToObj = Object.fromEntries(prevData.map(items => [items.type, items.name]));
                this.setState({ payload: prevData, series: []}, () => {
                    this.props.updateSelectedTreeFromDashboard({ feederName: arrToObj.feeder, name: arrToObj.feeder, regionName: arrToObj.region, substationName: arrToObj.substation, type: "FEEDER" });
                    let currentDate = new Date().getTime() - (1000 * 3600 * 24);
                    if (this.state.type === 'Fault Momentary Interruption') {
                        let endDateTimestampFault = dateConvertion(currentDate, 'endDate');
                        let startDateTimestampFault = endDateTimestampFault + 1 - (this.state.dateRange * 1000 * 60 * 60 * 24)
                        sessionStorage.setItem("faultTimeStamp",JSON.stringify({startTimestamp:dateConvertion(startDateTimestampFault, 'short'),endTimestamp:dateConvertion(endDateTimestampFault, 'short')}))
                        this.props.roleAndPermission.licenseData.events.visible && isGreaterThanTodaysDate(this.props.roleAndPermission.licenseData.events.expiresOn) ? this.props.history.push("/events/details") : window.location.href = `${window.location.origin}/amplemanage/licenseManagement`; 
                    } else {
                        let endDateTimestamp = dateConvertion(currentDate, 'endDate', this.props.roleAndPermission.timezone);
                        let startDateTimestamp = endDateTimestamp + 1 - (this.state.dateRange * 1000 * 60 * 60 * 24)
                        disturbancesData.selectedDataByUser = {
                            startTimestamp: dateConvertion(startDateTimestamp, 'short', this.props.roleAndPermission.timezone),
                            endTimestamp: dateConvertion(endDateTimestamp, 'short', this.props.roleAndPermission.timezone),
                            interval: this.state.dateRange === 31 ? 'Weekly' : 'Daily'
                        }
                        this.props.roleAndPermission.licenseData.disturbances.visible && isGreaterThanTodaysDate(this.props.roleAndPermission.licenseData.disturbances.expiresOn) ? this.props.history.push("/disturbances") : window.location.href = `${window.location.origin}/amplemanage/licenseManagement`; 
                    }
                })
                if(this.state.type === 'Fault Momentary Interruption')
                this.props.updateFilterData({eventState: "", eventType: "Fault Momentary Interruption", isReDirect: true});
            }
        })
        let filterElement = this.removeElement(prevData);
        this.props.updateHeatmapPayload(filterElement);
    }

    handleOnClickTimeFilter = (value) => {
        if (this.state.dateRange !== value) {
            this.props.updateHeatmapDateRangeData(value);
            this.setState({ dateRange: value, series: [] }, () => this.getHeatMapChartData())
        }
    }
    handleBredCrumbOnClick = (key) => {
        let prvArr = this.state.payload;
        let filterArr = prvArr.slice(0, key + 1);
        let filterElement = this.removeElement(filterArr);
        this.props.updateHeatmapPayload(filterElement);
        this.setState({ payload: filterArr, series: [] }, () => this.getHeatMapChartData())
    }
    setType = (value) => {
        this.props.updateHeatmapDefaultType(value);
        this.props.updateHeatmapPayload([{'name' : this.props.rootNodeName, type : 'ROOTNODE'}]);
        this.props.updateHeatmapDateRangeData(1);
        this.setState({ type: value, dateRange:1,series: [], payload: [{'name' : this.props.rootNodeName, type : 'ROOTNODE'}] }, () => this.getHeatMapChartData())
    }
    handleClickTopButton = () => {
        let prvArr = this.state.payload;
        prvArr.pop();
        this.props.updateHeatmapPayload(prvArr);
        this.setState({ payload: prvArr, series: [] }, () => this.getHeatMapChartData())
    }
    render() {
        return (
            <React.Fragment>
                <Col>
                    <Row className="breadcrumb-background">
                        <Col className="pad-left-top">
                            {/* <Row className="width100P">
                                <Col xs={5} className="dashboard-chart-title"> <FormattedMessage id='db.column.eventCounts' /> </Col>
                                <Col xs={7} className="dashboard-chart-timeFilter">
                                    <Row className="float-right">
                                        <span className="float-right" style={{ paddingRight: "5px" }}> <SingleSelect data={dashboardData.typeFilterData} setType={this.setType} value={this.state.type} icon='fa-check' /> </span>
                                        <span className="float-right" style={{ paddingRight: "15px" }} > <TimeFilter data={dashboardData.timeFilterData} handleOnClick={this.handleOnClickTimeFilter} selected={this.state.dateRange} /> </span>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="bubble-chart-bredcrumb">
                                <Col>
                                    <BreadCrumb data={this.state.payload.length >= 4 ? this.state.payload.slice(0, this.state.payload.length - 1) : this.state.payload} isClickable={true} separatorSymble={' > '} handleOnClick={this.handleBredCrumbOnClick} />
                                </Col>
                            </Row> */}
                            <div className="float-left m-1">
                                <div className="dashboard-chart-title"><FormattedMessage id='db.column.eventCounts' /></div>
                                <div className="bubble-chart-bredcrumb mt-1">
                                    <BreadCrumb data={this.state.payload.length >= 4 ? this.state.payload.slice(0, this.state.payload.length - 1) : this.state.payload} isClickable={true} separatorSymble={' > '} handleOnClick={this.handleBredCrumbOnClick} />
                                </div>
                            </div>
                            <div className="float-right dashboard-chart-timeFilter mt-1">
                                <span className="float-left single-select-dropdown option-list" style={{ marginRight: "0.5vh" }}> <SingleSelect data={dashboardData.typeFilterData} setType={this.setType} value={this.state.type} icon='fa-check' /> </span>
                                <span className="float-left"> <TimeFilter data={dashboardData.timeFilterData} handleOnClick={this.handleOnClickTimeFilter} selected={this.state.dateRange} /> </span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="chart-Section">
                        <div className="container-fluid px-0" >
                            {this.state.isLoding === undefined || this.state.isLoding ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                            {!this.state.isResDataBlank ?
                            <HeatMapComponent options={this.state.heatMapOptions} handleHeatMapPointClick={this.handleHeatMapPointClick} />
                            : <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
                            {this.state.payload.length > 1 ?
                                <span className="top-btn-treemap">
                                    <Button handleButtonClick={this.handleClickTopButton} className="top-button" btnText={this.state.payload[this.state.payload.length - 1].name} />
                                </span>
                                : ""}
                        </div>
                    </Row>
                </Col>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedTreeFromDashboard: (data) => dispatch(dashboardBuilderAction.updateSelectedTreeFromDashboard(data)),
        updateHeatmapDateRangeData: (data) => dispatch(dashboardBuilderAction.updateHeatmapDateRangeData(data)),
        updateHeatmapDefaultType: (data) => dispatch(dashboardBuilderAction.updateHeatmapDefaultType(data)),
        updateHeatmapPayload: (data) => dispatch(dashboardBuilderAction.updateHeatmapPayload(data)),
        updateFilterData: (data) => dispatch(updateFilterData(data))
    }
}

const mapStateToProps = state => {
    return {
        requestParams: state.dashboardData.requestParams,
        heatmapFilterType: state.dashboardData.hitmapFilterType,
        heatmapDateRange: state.dashboardData.heatMapDateRange,
        hitmapPayload: state.dashboardData.hitmapPayload,
        roleAndPermission: state.loginData.roleAndPermission
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeatMapChartContainer));

import React, { useContext } from 'react';
import { DetailsContext } from '../provider/detailsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../../hoc/loader';
import BlankPage from '../../../../hoc/blankPage';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../hoc/button';

const FaultLine = () => {
    const contextData = useContext(DetailsContext);
    return (
        <React.Fragment>
            <div className='btn-div'><Button isDisabled={(contextData.state.userRole > 2)} handleButtonClick={() => contextData.reCalculateFaultLocation()} className="float-right btn mt-1 mr-1" btnText={<FormattedMessage id='events.details.recalculate' />} /></div>
            <div className="overflow-auto pb-3 faultLineParentDiv">
                <div className='faultLineDiv d-flex justify-content-between'>
                    {
                        contextData.state.faultLineloading ? <Loader elementClass="tree-ajax-loader center-element" /> : contextData.state.faultDataLineDisplay.length > 0 ?
                            contextData.state.faultDataLineDisplay.map((element, index, allElm) => {
                                return (
                                    <React.Fragment key={`${element.site}-${index}`}>
                                        <div className='img-div d-flex flex-column align-items-center'>
                                            <img src={require(`../../../../resources/images/${element.markerIconName}.png`)} className='img' alt="icons" title={element.site} />
                                            <span className='name' title={element.site}>{element.site}</span>
                                        </div>
                                        {
                                            (index != (allElm.length - 1)) ?
                                                <div className='connection-div d-flex flex-column align-items-center'>
                                                    <span className={(allElm.findIndex(item => item.site === "N.O") > -1) && (index >= allElm.findIndex(item => item.site === "N.O")) ? (allElm[index + 1].faultdistanceLeft !== null && allElm[index + 1].faultDistanceRight !== null) ? "sp-distance d-flex" : "sp-distance d-flex invisible" : (element?.faultdistanceLeft !== null && element?.faultDistanceRight !== null) ? "sp-distance d-flex" : "sp-distance d-flex invisible"}><span className={allElm.findIndex(item => item.site === "N.O") > -1 && (index >= allElm.findIndex(item => item.site === "N.O")) ? "avg-dis-arrow-left" : "avg-dis-arrow-right"}></span></span>
                                                    <span className={allElm.findIndex(item => item.site === "N.O") > -1 && (index >= allElm.findIndex(item => item.site === "N.O")) ? allElm[index + 1].faultdistanceLeft !== null && allElm[index + 1].faultDistanceRight !== null ? "sp-distance d-flex w-90" : "sp-distance d-flex invisible" : element.faultdistanceLeft !== null && element.faultDistanceRight !== null ? "sp-distance d-flex w-90" : "sp-distance d-flex invisible"}><span className='avg-value-parent-span'>{allElm.findIndex(item => item.site === "N.O") > -1 && (index >= allElm.findIndex(item => item.site === "N.O")) ? allElm[index + 1].faultdistanceLeft ? Number(Number(allElm[index + 1].faultdistanceLeft).toFixed(3)) : 0 : element.faultDistanceRight ? Number(Number(element.faultDistanceRight).toFixed(3)) : 0}ft<span className='vertical-child-span'>|</span></span><span className='w-100'></span><span className='avg-value-parent-span'>{allElm.findIndex(item => item.site === "N.O") > -1 && (index >= allElm.findIndex(item => item.site === "N.O")) ? allElm[index + 1].faultDistanceRight ? Number(Number(allElm[index + 1].faultDistanceRight).toFixed(3))  : 0 : element.faultdistanceLeft ? Number(Number(element.faultdistanceLeft).toFixed(3)) : 0}ft<span className='vertical-child-span'>|</span></span></span>
                                                    <span className={allElm.findIndex(item => item.site === "N.O") > -1 && (index >= allElm.findIndex(item => item.site === "N.O")) ? allElm[index + 1].fault ? 'baselineconn sp-red' : allElm[index + 1].interruption ? 'baselineconn sp-orange' : 'baselineconn sp-green' : element.fault ? 'baselineconn sp-red' : element.interruption ? 'baselineconn sp-orange' : 'baselineconn sp-green'}></span>
                                                    <span className={allElm.findIndex(item => item.site === "N.O") > -1 && (index >= allElm.findIndex(item => item.site === "N.O")) ? allElm[index + 1].faultDistance !== null ? 'sp-icon-dis d-flex flex-column align-items-center' : 'invisible' : element.faultDistance !== null ? 'sp-icon-dis d-flex flex-column align-items-center' : 'invisible'}><FontAwesomeIcon icon={faBolt} size="lg" />
                                                        {/* <span className='d-flex faultarrow'><span className={(allElm.findIndex(item => item.site === "N.O") > -1) && (index >= allElm.findIndex(item => item.site === "N.O")) ? 'sp-black-before' : 'sp-black-after'}></span>&nbsp;<span>{(allElm.findIndex(item => item.site === "N.O") > -1) && (index >= allElm.findIndex(item => item.site === "N.O")) ? allElm[index + 1].faultDistance : element.faultDistance}ft</span></span> */}
                                                        { (allElm.findIndex(item => item.site === "N.O") > -1) && (index >= allElm.findIndex(item => item.site === "N.O")) ? <span className='d-flex faultarrow justify-content-end'><span>{Number(Number(allElm[index + 1].faultDistance).toFixed(3))}ft</span>&nbsp;<span className='sp-black-before'></span></span> : <span className='d-flex faultarrow'><span className='sp-black-after'></span>&nbsp;<span>{Number(Number(element.faultDistance).toFixed(3))}ft</span></span>}
                                                    </span>
                                                </div>
                                                : ""
                                        }
                                    </React.Fragment>
                                )
                            })
                            : <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default FaultLine;
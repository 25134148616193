import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import Button from '../../../hoc/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import DropdownMenuCheckbox from '../../../hoc/dropdownMenuCheckbox'
import { userMngmntData } from '../../../utility/userManagementUtility'
import { FormattedMessage } from 'react-intl';
import { UserContext } from '../provider/userProvider';
import { connect } from 'react-redux';

class UserAction extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    addEditOrDelete(addorEdit) {
        this.context.handleAddEditOrDeleteUser(addorEdit)
    }
    render() {
        const { samlEnabled, ssoEnabled } = this.props.roleAndPermission;
        return (
            <Row noGutters={true} className="top-header">
                <Col xs={6} className="my-auto"><FormattedMessage id='nav.main.usermanagement' /></Col>
                <Col xs={6} className="text-right">
                    <Button handleButtonClick={this.context.state.allChecked.length === 1 ? () => this.addEditOrDelete('edit') : (e) => e} className={`${samlEnabled || ssoEnabled ? "d-none" : this.context.state.allChecked.length === 1 ? "edit-btn" : "edit-btn edit-btn-disable"}`} btnText={<FormattedMessage id='groupmanagement.context.edit' />} />
                    <Button handleButtonClick={this.context.state.allChecked.length >= 1 ? () => this.addEditOrDelete('delete') : (e) => e} className={`${samlEnabled || ssoEnabled ? "d-none" : this.context.state.allChecked.length >= 1 ? "dlt-btn" : "dlt-btn dlt-btn-disable"}`} btnText={<FormattedMessage id='db.common.delete' />} />
                    <Button handleButtonClick={() => this.addEditOrDelete('add')} className={"add-btn " + (samlEnabled || ssoEnabled ? "d-none" : "")} btnText={<FormattedMessage id='db.common.addUser' />} />
                    <span className={this.context.state.usersList.length === 0 ? 'float-right my-1 disabled': 'float-right my-1'}>
                        <DropdownMenuCheckbox
                            btnContent={<span className="add-btn" style={{ padding: ".75vh 1.5vh .75vh", fontSize: "1.8vh" }}><FontAwesomeIcon icon={faTh} /></span>}
                            bodyContent={samlEnabled ? userMngmntData.culumnFilter.filter(col => !["enabled", "resetPasswordRequired"].includes(col.value)) : userMngmntData.culumnFilter}
                            selectedCulumns={this.context.state.displayColumns}
                            handleFilterChecked={this.context.filterUserTableColumn}
                        />
                    </span>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default connect(mapStateToProps, null)(UserAction);
import React, { Component } from 'react';
import ReportsProvider from './provider/reportsProvider';
import ReportsContainer from './containers/reportsContainer';
import './index.css';

class ManageReports extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="device-management">
                    <ReportsProvider>
                        { 
                            <ReportsContainer />
                        }
                    </ReportsProvider>
                </div>
            </React.Fragment>
        );
    }
}

export default ManageReports;
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import EmailSubscription from '../../mainContent/emailSubscription/index';
import { userMngmntData } from '../../utility/userManagementUtility'

class OpenEmailSubscriptionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false
        }
    }

    handleOnCLick = () => {
        userMngmntData.isOpenEmailSubscriptionsModal = true;
        this.setState({ isOpenModal: true })
    }

    render() {
        return (
            <React.Fragment>
                <div onClick={this.handleOnCLick} className="noNavigationItemLink">
                    <FormattedMessage id={`nav.main.slertsubscriptions`} />
                </div>
                <div>
                    {this.state.isOpenModal ? <EmailSubscription /> : ""}
                </div>
            </React.Fragment>
        );
    }
}

export default OpenEmailSubscriptionModal;
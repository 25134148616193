import React from 'react';

const SortNumber = ({ columnData, data }) => {
    return (
        <span>
            {columnData.column.sortNumber !== undefined && columnData.column.sortNumber !== "" ? <span className="sort-number" >{columnData.column.sortNumber}</span> : ""}&nbsp;
            {data}
        </span>
    )
}

export default SortNumber;

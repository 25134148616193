import React, { useContext } from 'react';
import {ConfigurePropertiesContext} from '../provider/configurePropertiesProvider';

const ConfigVDprofileTemplateCheckbox = (props) => {
    const contextData = useContext(ConfigurePropertiesContext);

    return (
        <input 
            type="checkbox" 
            key={Math.random()}
            checked={contextData.state.configData.vdProfileSettings[props.rowData.row._index].selected}
            onChange={(e) => contextData.updateFormData('selected', e.target.checked, 'vdProfileSettings', '', props.rowData, 'checkBox')}
        />
    );
};

export default ConfigVDprofileTemplateCheckbox;
import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as headerActions from '../header/actions';
import HomeComponent from '../home';
import CustomReport from '../mainContent/customReport/index';

import Manage from '../mainContent/manage';
import Dashboard from '../mainContent/dashboard'; 
import MapView from '../mainContent/mapView'; 
import Monitor from '../mainContent/monitor'; 
import FaultAnalysis from '../mainContent/faultAnalysis'; 
import Disturbances from '../mainContent/disturbances'; 
import DeviceGateway from '../mainContent/deviceGateway';
import SystemAdmin from '../mainContent/systemAdmin'; 
import UserManagement from '../mainContent/userManagement'; 
import LicenseManagement from '../mainContent/licenseManagement'; 
import AuditTrail from '../mainContent/auditTrail';
import ManageSchedules from '../mainContent/manageSchedules'; 
import EquipmentProfile from '../mainContent/equipmentProfile';
import CustomReportsManagement from '../mainContent/customReportsManagement';
import { FormattedMessage } from 'react-intl';
import { openErrorWindow } from "../login/actions/loginActionsBuilder";
import Insights from '../mainContent/insights'; 

class Routes extends React.PureComponent {

    componentDidMount() {
        this.onRouteChanged();
        if (this.props.roleAndPermission?.licenseData?.daysRemaining <= 31) {
            let message = 'Your license ' + (this.props.roleAndPermission?.licenseData?.daysRemaining <= 0 ? 'expired.' : 'expires in ' + this.props.roleAndPermission?.licenseData?.daysRemaining + ' day(s).') + ' Please consult with your system administrator for a new Ample license.';
            this.props.openErrorModalWindow(message, <FormattedMessage id='common.label.warning' />)
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.props.onRouteChange(this.props.location.pathname, this.props.disableTree.includes(this.props.location.pathname.slice(1)))
    }

    WrapperComponent = () => {
        let urlSearchParams = new URLSearchParams(this.props.location.search);
        const lObj = {
            id : urlSearchParams.get("id"),
            name : urlSearchParams.get("name")
        }
       return <CustomReport selectedReportObj = {lObj}/>
    }

    render() {
        const {roleId} = this.props.roleAndPermission
        return (
            <React.Fragment>
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route path="/mapView" component={MapView} />
                    <Route path="/monitor" component={Monitor} />
                    <Route path="/events" component={FaultAnalysis} />
                    <Route path="/disturbances" component={Disturbances} />
                    <Route path="/insights" component={Insights} />
                    <Route exact path="/deviceGateway" render={(roleId === 3 || roleId === 4) ? () => (<Redirect to='/manage/details' />) : () => (<DeviceGateway />)} />
                    <Route exact path="/systemAdmin" render={(roleId === 3 || roleId === 4) ? () => (<Redirect to='/manage/details' />) : () => (<SystemAdmin />)} />
                    <Route exact path="/userManagement" render={(roleId === 3 || roleId === 4) ? () => (<Redirect to='/manage/details' />) : () => (<UserManagement />)} />
                    <Route exact path="/licenseManagement" component={LicenseManagement} />
                    <Route exact path="/auditTrail" render={(roleId === 3 || roleId === 4) ? () => (<Redirect to='/manage/details' />) : () => (<AuditTrail />)} />
                    <Route exact path="/equipmentProfile" render={(roleId === 3 || roleId === 4) ? () => (<Redirect to='/manage/details' />) : () => (<EquipmentProfile />)} />
                    <Route exact path="/customReportsManagement" render={(roleId === 2 || roleId === 3 || roleId === 4) ? () => (<Redirect to='/manage/details' />) : () => (<CustomReportsManagement />)} />
                    <Route path='/samlLogout' component={HomeComponent} />
                    <Route path='/samlFailed' component={HomeComponent} />
                    <Route path="/customReportManageSchedules" render={(roleId === 3 || roleId === 4) ? () => (<Redirect to='/manage/details' />) : () => (<ManageSchedules />)} />
                    {['/', '/manage', '/amplemanage'].map(path => (<Route exact path={path} render={() => (<Redirect to='/manage/details' />)} />))}
                    <Route path='/manage' component={Manage} />
                    <Route exact path='/reports/custom' render={(roleId > 2) ? () => (<Redirect to='/manage/details' />) : () => (this.WrapperComponent())} />
                    {<Redirect from="*" to="/manage/details" />}
                </Switch>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        disableTree: state.headerData.disableTree,
        roleAndPermission: state.loginData.roleAndPermission,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onRouteChange: (route, isTreeDisable) => dispatch(headerActions.onRouteChange(route, isTreeDisable)),
        openErrorModalWindow: (message, title) => dispatch(openErrorWindow(message, title))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
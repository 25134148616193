import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { enableGPSOperations } from '../services/treeService';
import { getNodeRouteParams } from '../containers/treeMethods';
import ModalWindow from '../../hoc/modelWindow';

class EnableGPSComponent extends Component {
    state = {
        errorMessage: "",
        successMessage: ""
    }

    onConfirmClickHandler = async () => {
        const routeParams = getNodeRouteParams(this.props.clickedNode);
        const res = await enableGPSOperations(routeParams);
        if (res && res.status !== "FAIL") {
            this.setState({ successMessage: res.message });
        } else {
            this.setState({ errorMessage: res.message });
        }
    }

    hideErrorMsg = () => {
        this.setState({ errorMessage: "", successMessage: "" });
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
        return btnName === "confirm" ? this.onConfirmClickHandler() :
            btnName === "cancel" || btnName === "errorCancelBtn" ? this.props.closeModal() : null;
    }

    render() {
        const modalTitle = <div className="popup-title"><FormattedMessage id='groupmanagement.context.enableGPS' /></div>;
        const modalBody = <div className="min-vh-25">
            {!this.state.successMessage ?
                <Form>
                    <div className={this.state.errorMessage ? "alert alert-danger" : "alert alert-danger dspNone"} role="alert">
                        {this.state.errorMessage ? <div>{this.state.errorMessage}<FontAwesomeIcon className="msgClsIcon" icon={faTimes} onClick={this.hideErrorMsg} /></div> : ""}
                    </div>
                    <FormattedMessage id='groupmanagement.context.enableGPS.body' />
                </Form> :
                <Form>
                    <div className={this.state.successMessage ? "alert alert-success" : "alert alert-success dspNone"} role="alert">
                        {this.state.successMessage ? <div>{this.state.successMessage}<FontAwesomeIcon className="msgClsIcon" icon={faTimes} onClick={this.hideErrorMsg} /></div> : ""}
                    </div>
                </Form>
            }
        </div>
        const modalFooter = !this.state.successMessage ? [{ className: "modalCancelBtn", name: <FormattedMessage id={`groupmanagement.context.enableGPS.cancel.button`} />, value: "cancel" }, { className: "modalSaveBtn", name: <FormattedMessage id={`groupmanagement.context.enableGPS.confirm.button`} />, value: "confirm" }] : [{ className: "modalCancelBtn", name: <FormattedMessage id={`groupmanagement.context.enableGPS.cancel.button`} />, value: "errorCancelBtn" }];
        return (
            <Fragment>
                {this.props.isModalOpen ?
                    <ModalWindow
                        show={this.props.isModalOpen}
                        onHide={() => this.props.closeModal()}
                        size={"md"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        )
    }

}

export default EnableGPSComponent;
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class Footer extends Component{
    render(){
        return(
            <p className="float-right mb-0 p-0"><FormattedMessage id="footer.copyrightTextFirst" /> &copy; {new Date().getFullYear()} <FormattedMessage id="footer.copyrightTextLast" /></p>
        );
    }
}

export default Footer;
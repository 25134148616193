import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ConfigForm from './configForm';
import { ConfigurePropertiesContext } from '../provider/configurePropertiesProvider';
import { FormattedMessage } from 'react-intl';
import AlertMessage from '../../../hoc/alert';
import Loader from '../../../hoc/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { configPropertiesData } from '../../../utility/configurePropertiesUtility';
import ModalWindow from '../../../hoc/modelWindow';

class ConfigModalContainer extends Component {
    static contextType = ConfigurePropertiesContext;
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: true
        }
    }

    onBtnClickHandlera = (value) => {
        const { isDisableSaveBtn } = this.context.state;
        if (value === 'close') this.context.closeConfigModal();
        else if (value === 'cancel') this.context.closeConfigModal();
        else if (value === 'save') {
            if (isDisableSaveBtn) {
                return null
            } else this.context.updateConfigProperties()
        }
    }

    render() {
        const { alertMessage, isloadingData, isDisableSaveBtn } = this.context.state;
        return (
            <Row>
                <Col>
                    {configPropertiesData.isOpenModal ?
                        <ModalWindow
                            show={this.state.isOpenModal}
                            size="lg"
                            dialogClassName={"config-prop-modal"}
                            title={
                                alertMessage.msg !== undefined && alertMessage.type === "success" ?
                                    <React.Fragment> <FontAwesomeIcon icon={faCheck} size="sm" className='icon' />&nbsp;&nbsp; <span className='title'><FormattedMessage id='pingDevice.popup.success.title'/></span></React.Fragment>
                                    : <FormattedMessage id='nav.main.configureproperties' />
                            }
                            onBtnClickHandler={this.onBtnClickHandlera}
                            onHide={this.context.closeConfigModal}
                            modeldata={{
                                content: <React.Fragment>
                                    {isloadingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                                    {alertMessage.msg &&    //if type 'success' then no need to display close(x) button on alert message.
                                        <>{alertMessage.type === 'success' ? <AlertMessage message={alertMessage.msg} type={alertMessage.type} position="w-100" /> : <AlertMessage message={alertMessage.msg} type={alertMessage.type} setVisible={this.context.closeAlertMessage} position="w-100" />}</>
                                      }
                                    {alertMessage.msg !== undefined && alertMessage.type === "success" ? "" : <ConfigForm />}
                                </React.Fragment>
                            }}
                            footer={
                                alertMessage.msg !== undefined && alertMessage.type === "success" ?
                                    [{ className: "modalCancelBtn", name: <FormattedMessage id='db.common.close' />, value: "close" }]
                                    :
                                    [{ className: "modalCancelBtn", name: <FormattedMessage id='dm.column.cancel' />, value: "cancel" },
                                    { className: "modalSaveBtn", name: <FormattedMessage id='dm.column.save' />, value: "save", disabled: isDisableSaveBtn }]
                            }
                        />
                        : ""}
                </Col>
            </Row>
        );
    }
}

export default ConfigModalContainer;
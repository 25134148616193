import React from 'react'
import { DropdownButton } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DropdownMenuWithCheckbox = ({ btnContent, bodyContent, handleFilterChecked, selectedCulumns, dropdownPosition, isBtnDisabled }) => {
    return (
        <DropdownButton title={btnContent} drop={dropdownPosition} disabled={isBtnDisabled}>
            {bodyContent.length !== 0 ? bodyContent.map((item, key) => {
                    return <div onClick={(e) => handleFilterChecked(item.value, e.target.checked)} key={key} className="cursor-pointer inline-center-item">
                        <input readOnly checked={selectedCulumns.includes(item.value) ? true : false} type="checkbox" />
                    <span className="checkbox-label-span">{item.label}</span>
                    </div>
                }) : ""
            }
        </DropdownButton>
    )
}

DropdownMenuWithCheckbox.propTypes = {
    btnContent: PropTypes.object,
    bodyContent: PropTypes.array,
    handleFilterChecked: PropTypes.func,
    selectedCulumns: PropTypes.array,
    dropdownPosition: PropTypes.string,
    isBtnDisabled: PropTypes.bool
}

export default DropdownMenuWithCheckbox;

//drop props || dropdownPosition props
// 'up', 'down', 'left', 'right'

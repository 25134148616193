import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap'
import Tabs from '../../../../hoc/tabs';
import Loader from '../../../../hoc/loader';
import { ProfileContext } from '../provider/profilesProvider';
import BlankPage from '../../../../hoc/blankPage';
import { FormattedMessage } from 'react-intl';
import { profileData,minMaxInfo,zm1CfciArr } from '../../../../utility/manageProfileUtility';
import TextField from '../../../../hoc/form/textField';
import TextArea from '../../../../hoc/form/textArea';
import MM3ProfileConfig from './mm3Profile-config';
import Zm1ProfileConfig from './zm1Profile-config';
import Tooltip from '../../../../hoc/tooltip';
import UM3ProfileConfig from './um3Profile-config';
import UM1ProfileConfig from './um1Profile-config';
import VC10ProfileConfig from './vc10Profile-config';
import SingleSelect from '../../../../hoc/singleSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import SubLabel from '../../../../hoc/subLabel';

class ProfileDetails extends Component {

    static contextType = ProfileContext;
    state = {
        initialFields: profileData.initialFields,
        selectedDeviceType: this.context.state.deviceTypeList.selected
    }
    showAedFields = (key) =>{
       return ((key === 'At-Disturbance-Detector') || (this.context.state.profile.selectedProfile.zmAed['At-Disturbance-Detector'] && ((key === 'At-Disturbance-Detector') || (key === 'At-Doc-threshold'))) || (this.context.state.profile.selectedProfile.zmAed['At-Doc-threshold']));
    }
    showZM1CfciFields = (value) => {
        return zm1CfciArr.includes(value);
    }
    isTimeoffset = (value)=>{
        return ((value === 'DnpDSTTimeOffset') || (value === 'DnpUTCTimeOffset') || (value === 'LocalTimeOffset'));
    };
    isBooleanValue =  (value) =>{
        return (typeof (value) === 'boolean');
    };
    handleOnchangeForms = (from,field, value,child,identifier) => {
        this.context.handleOnchangeForm(from, field, value,child,identifier)
    }
    updateFormattedMessage = (label, rulesData, pLabel) =>{
        let minMax = "";
        let info ="";
        let offsetSymbol='-';
        if (label !== "") {
            if (rulesData !== null && rulesData[label] !== null && rulesData[label] !==undefined && rulesData[label].min !== null) {   //getting all the min and max if rulesData is available
                if(label==="DnpDSTTimeOffset"||label==="DnpUTCTimeOffset"||label==="LocalTimeOffset") offsetSymbol = 'to';
                minMax = label !== "DnpStaleStackTime" ? (`${rulesData[label].min} ${offsetSymbol} ${rulesData[label].max}`) : (`${rulesData[label].min} , 3600 - ${rulesData[label].max}`) ;
            } else if (rulesData.min !== null && rulesData.min !== undefined && rulesData.max !== null && rulesData.max !== undefined) {
                minMax = (`${rulesData.min} - ${rulesData.max}`);
            }
            info = minMaxInfo[label] ? minMaxInfo[label] : "";
        }
        info = info === "A" ?  <SubLabel type="Arms" /> : info === "C" || info === "F" ? this.context.state.userTemp: info === "mA" ? <SubLabel type="mArms" /> : info
        //returning final label
        return <span><FormattedMessage id={`manage.profiles.${pLabel ? pLabel+'.' :''}${label}`} /> { minMax ? <label>({minMax} {info})</label> : ""} </span>
    };
    updateStateAfterPageLoad = (deviceType) =>{
        // Select FW version first and HW revision next for ZM1
        // Select HW revision first and FW version next for VC10 & UM3+ compact
        let initField = deviceType === 'ZM1' ? [...profileData.zm1InitialFields] : [...profileData.initialFields];
            this.setState({
                initialFields: initField,
                selectedDeviceType: this.context.state.deviceTypeList.selected
            });
    }
    componentDidMount = () => {
        this.updateStateAfterPageLoad();
    }
    componentDidUpdate = (prevProps, prevState,) =>{
        if(prevState.selectedDeviceType !== this.context.state.deviceTypeList.selected){
            this.updateStateAfterPageLoad(this.context.state.deviceTypeList.selected);
        }
    }
    render() {  
        return (
            <div>
                {this.context.state.rightContentLoader ? <Loader elementClass="tree-ajax-loader center-element" /> : null}
                {this.context.state.profileDetails.tabs.length > 0 ?
                 //  {this.context.state.rightContentLoader ? <Loader elementClass="tree-ajax-loader center-element" /> : this.context.state.profileDetails.tabs.length > 0 ? 
                   <Row>
                        <Col >
                            <Tabs
                                // currentTab={this.context.state.profileDetails.tabs[0].label}
                                currentTab={this.context.state.currentTab}
                                handleTabSelect={this.context.handleTabSelect}
                                allTabs={this.context.state.profileDetails.tabs}
                                tabBody={
                                    <div>
                                        {
                                            this.context.state.currentTab === "Profile Name" ?
                                                <div>
                                                    {this.state.initialFields.map((item, key) => {
                                                        return <div className="form-section d-flex" key={key}>
                                                            {(item.type === 'singleselect' && (["MM3","MM3ai","UM1"].includes(this.context.state.deviceTypeList.selected))) ? null:
                                                            <span className="form-section-content-l">
                                                                <Tooltip placement="top" message= {item.label} baseContent= {item.label}/>
                                                            </span>
                                                            }
                                                            <span className="form-section-content-r"> 
                                                                {item.type === 'textarea' ?
                                                                    <TextArea
                                                                        type="textarea"
                                                                        rows={ 5 }
                                                                        value={this.context.state.profile.selectedProfile[item.value] ? this.context.state.profile.selectedProfile[item.value] : ""}
                                                                        field={item.value}
                                                                        handleOnchange={(e)=>{this.handleOnchangeForms("general",item.value,e)}}
                                                                    /> : ""}
                                                                {item.type === 'text' ?
                                                                    <TextField
                                                                        type="text"
                                                                        isDisabled={item.isDisabled !== undefined && item.isDisabled === true ? true : false}
                                                                        value={this.context.state.profile.selectedProfile[item.value] ? this.context.state.profile.selectedProfile[item.value] : ""}
                                                                        field={item.value}
                                                                        handleOnchange={(e)=>{this.handleOnchangeForms("general",item.value,e)}}
                                                                        className={this.context.state.formError[item.value] !== undefined && this.context.state.formError[item.value] === true ? "form-err-border-red" : ""}
                                                                    /> : ""}
                                                                {item.type === 'singleselect' && (!(["MM3","MM3ai","UM1"].includes(this.context.state.deviceTypeList.selected)))?
                                                                    // <SelectField
                                                                    // field={[]}
                                                                    // defaultValue={this.context.state[item.value].selected}
                                                                    // handleOnchange={(e)=>this.context.onChangeSelect(e,item.value)}
                                                                    // optionData={this.context.state[item.value].options}
                                                                    // /> 
                                                                    <div className="single-select-dropdown">
                                                                    <SingleSelect
                                                                        value={this.context.state[item.value].selected}
                                                                        setType={(e)=>this.context.onChangeSelect(e,item.value)}
                                                                        data={this.context.state[item.value].options}
                                                                        icon='ok'
                                                                    />
                                                                    <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                                                    </div>
                                                                    : ""
                                                                    }
                                                            </span>
                                                        </div>
                                                    })}
                                                </div> : null
                                        }
                                        {["MM3","MM3ai"].includes(this.context.state.deviceTypeList.selected) ? <MM3ProfileConfig updateFormattedMessage={this.updateFormattedMessage} isBooleanValue={this.isBooleanValue} showZM1CfciFields={this.showZM1CfciFields} isTimeoffset={this.isTimeoffset} handleOnchangeForms={this.handleOnchangeForms} deviceType={this.context.state.deviceTypeList.selected}/> : null}
                                        {this.context.state.deviceTypeList.selected === "ZM1" ? <Zm1ProfileConfig updateFormattedMessage={this.updateFormattedMessage} isBooleanValue={this.isBooleanValue} showZM1CfciFields={this.showZM1CfciFields} isTimeoffset={this.isTimeoffset} handleOnchangeForms={this.handleOnchangeForms} deviceType={this.context.state.deviceTypeList.selected} showAedFields={this.showAedFields}/> : null}
                                        {this.context.state.deviceTypeList.selected === "UM3+"? <UM3ProfileConfig updateFormattedMessage={this.updateFormattedMessage} isBooleanValue={this.isBooleanValue} showZM1CfciFields={this.showZM1CfciFields} isTimeoffset={this.isTimeoffset} handleOnchangeForms={this.handleOnchangeForms} deviceType={this.context.state.deviceTypeList.selected}/> : null}
                                        {this.context.state.deviceTypeList.selected === "UM1"? <UM1ProfileConfig updateFormattedMessage={this.updateFormattedMessage} isBooleanValue={this.isBooleanValue} showZM1CfciFields={this.showZM1CfciFields} isTimeoffset={this.isTimeoffset} handleOnchangeForms={this.handleOnchangeForms} deviceType={this.context.state.deviceTypeList.selected}/> : null}
                                        {this.context.state.deviceTypeList.selected === "VC10"? <VC10ProfileConfig updateFormattedMessage={this.updateFormattedMessage} isBooleanValue={this.isBooleanValue} handleOnchangeForms={this.handleOnchangeForms} deviceType={this.context.state.deviceTypeList.selected}/> : null}
                                    </div>
                                }
                            />
                        </Col>
                    </Row> :
                    <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                }
            </div>
        );
    }
}

export default ProfileDetails;
import React, { Component, Fragment } from 'react';
import DeviceDetailsModal from './deviceDetailsModal';
import { DeviceContext } from '../../provider/deviceProvider';

class DeviceDetails extends Component {
    static contextType = DeviceContext;
    state = {
        isOpenDeviceDetailsModal: false,
    }

    getDeviceDetailsData = async (e, info) => {
        e.preventDefault();
        this.context.getDeviceDetailsTabData(info)
        this.setState({ isOpenDeviceDetailsModal: true })
    }

    onCloseModal = () => this.setState({ isOpenDeviceDetailsModal: false })

    render() {
        return (
            <Fragment>
                <span onClick={(e) => this.getDeviceDetailsData(e, this.props.data)} className='cursor-pointer active-color'>{this.props.data.serialNumber}</span>
                {this.state.isOpenDeviceDetailsModal ? <DeviceDetailsModal onCloseModal={this.onCloseModal} /> : ""}
            </Fragment>
        )
    }
}

export default DeviceDetails;
import React, { createContext } from 'react';
import { getCurrentUser } from '../../userManagement/services/userService';
import { connect } from 'react-redux';
import { reloadTreeview } from '../../../utility/treeViewUtility';
export const UpdateProfileContext = createContext();

class UpdateProfileProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            formDataCopy: {},
            isDisableSaveBtn: true,
            isNeedAPICall: true
        }
    }

    componentDidMount() {
        this.getCurrentUserDetails();
    }

    componentWillReceiveProps(){
        if( this.state.isNeedAPICall )  this.getCurrentUserDetails()
    }

    getCurrentUserDetails = () => {
        this.setState({ isLoading: true }, () => {
            getCurrentUser()
                .then(data => {
                    this.setState({ isLoading: false, formData : data, formDataCopy : data, isNeedAPICall : false })
                }).catch(error => {
                })
        })
    }

    handleOnchangeForm=(value, field)=>{
        this.setState({ formData: {...this.state.formData, [field]: value} },()=>{
            this.setState({ isDisableSaveBtn: JSON.stringify(this.state.formDataCopy) === JSON.stringify(this.state.formData)})
        })
    }

    reloadtree = () => {
        if (["mapView", "summary", "details", "site", "monitor", "events", "disturbances"].some(item => location.href.includes(item))) {
            let obj = reloadTreeview(this.props.contextData.state);
           this.props.contextData.autoSelectFromDashboard(obj);
        } 
    }
    
    closeModal=()=>{
        this.setState({ isNeedAPICall : true })
    }

    render() {
        return (
            <UpdateProfileContext.Provider value={{
                state: this.state,
                handleOnchangeForm: this.handleOnchangeForm,
                timezone:this.props.timezone,
                contextData: this.props.contextData,
                reloadtree:this.reloadtree,
                closeModal: this.closeModal
            }}>
                {this.props.children}
            </UpdateProfileContext.Provider>
        )
    }
}
const mapStateToProps = state => {
    return {
        timezone: state.loginData.roleAndPermission.timezone
    };
}
export default connect(mapStateToProps, {})(UpdateProfileProvider);

import React, { Component, Fragment } from 'react';
import { Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { tableOptions, allDeviceData } from '../../../../utility/deviceManagementUtility';
import { getClassName } from '../../../../utility/treeViewUtility';
import { applyUpgrade } from '../services/deviceService';
import { DeviceContext } from '../provider/deviceProvider';
import { groupingSelectedRows, sortFirmwareversions } from '../../../../services/utilityService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import SingleSelect from '../../../../hoc/singleSelect';
import ModalWindow from '../../../../hoc/modelWindow';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../../../../hoc/loader';

class FirmwareUpgradeDeviceComponent extends Component {
    static contextType = DeviceContext;
    state = {
        successMessage: "",
        errorMessage: "",
        newUpgradePopupErrorMessage: "",
        selectedItems: {},
        selectedNewUpgradePopup: false,
        updateConfirmationPopup: false,
        newUpgradeSelectedData: {},
        groupedData: {},
        newUpgradeRowsCount: 0,
        isBtnDisabled: false,
        finalSelectedData: [],
        isLoading: false
    }
    ddlValueUpdate = (e, type, firmware,hwRevision) => {
        type = type === "um3+" && hwRevision ==="CU" ? type+"c": type;
        let modifiedData = this.state.selectedItems;
        if (modifiedData[type]) {
            if (modifiedData[type][firmware]) {
                modifiedData[type][firmware] = e
            } else {
                modifiedData[type] = Object.assign({}, this.state.selectedItems[type], {
                    [firmware]: e
                });
            }
        } else {
            modifiedData = Object.assign({}, this.state.selectedItems, {
                [type]: {
                    [firmware]: e
                }
            });
        }
        this.setState({ selectedItems: modifiedData });
    }
    onNextBtnClickHandler = () => {
        let dispObj = {};
        Object.entries(this.state.selectedItems).forEach(([e, val], i) => {
            if (!["um3+c", "um3+"].includes(e))
                dispObj[e] = val
            else {
                if (!dispObj["um3+"])
                dispObj["um3+"] = {}
                dispObj["um3+"][e === "um3+" ? "C" : "CU"] = val
            }
        })
        const finalData = this.state.finalSelectedData;
        if (Object.keys(this.state.selectedItems).length > 0) {
            let rowsCount = this.state.newUpgradeRowsCount;
            const upCaseGroupdData = Object.fromEntries(Object.entries(this.state.groupedData).map(([k, v]) => [k.toUpperCase(), v]));
            Object.entries(this.state.selectedItems).forEach(([key, value]) => {
                Object.entries(value).forEach(([item, res]) => {
                    if (['mm3', 'um1', 'mm3ai','vc10',"zm1"].includes(key)) {
                        rowsCount += upCaseGroupdData[key.toUpperCase()][item].length;
                        finalData.push(Object.assign({}, {
                            "type": key,
                            "elementIds": upCaseGroupdData[key.toUpperCase()][item].map(dt => dt.id),
                            "softwareId": res
                    }))
                    }else{
                        rowsCount += key === "um3+c" ? upCaseGroupdData[key.split("c")[0].toUpperCase()][item]["CU"].length :  upCaseGroupdData[key.toUpperCase()][item]["C"].length
                        finalData.push(Object.assign({}, {
                            "type": key,
                            "elementIds": key === "um3+c" ? upCaseGroupdData[key.split("c")[0].toUpperCase()][item]["CU"].map(dt => dt.id) : upCaseGroupdData[key.toUpperCase()][item]["C"].map(dt => dt.id),
                            "softwareId": res
                    }))
                    }
                })
            });
            this.setState({ selectedNewUpgradePopup: true, newUpgradeRowsCount: rowsCount, finalSelectedData: finalData, errorMessage: "", dspDataObj : dispObj });
        } else {
            this.setState({ errorMessage: <FormattedMessage id='fwUpgrade.popup.newUpgradeSelection.errorMessage' /> })
        }
    }
    onCancelClickHandler = () => this.setState({ selectedNewUpgradePopup: false, newUpgradeRowsCount: 0, finalSelectedData: [] });
    onConfirmClickHandler = () => this.setState({ updateConfirmationPopup: true });
    updateConfCancelClickHandler = () => this.setState({ updateConfirmationPopup: false, selectedNewUpgradePopup: true });
    updateConfOkClickHandler = async () => {
        this.setState({ updateConfirmationPopup: false, selectedNewUpgradePopup: true, isBtnDisabled: true });
        const breadCrumb = getClassName({ node: this.props.selectedNode });
        const popupTitle = <span><FormattedMessage id={`fwUpgrade.popup.title`} /> [ {this.state.newUpgradeRowsCount} Device(s)] {breadCrumb} </span>;
        const res = await applyUpgrade(this.state.finalSelectedData, popupTitle);
        if (res) {
            if (res.data.status !== "OK") {
                this.setState({ newUpgradePopupErrorMessage: ReactHtmlParser(res.data.message) });
            } else {
                this.setState({ successMessage: <FormattedMessage id='fwUpgrade.popup.selectedNewUpgradePopup.successMessage' /> });
            }
        } else {
            this.props.closeModal("isUpgradeDeviceModalOpen")
        }
        if (Object.keys(this.props.selectedNode).length > 0) {
            await this.context.loadNextlevel({ "node": this.props.selectedNode }, this.props.selectedNode.routeParams ? this.props.selectedNode.routeParams : null, null, this.props.selectedNode.name);
            await this.context.getDeviceList()
        }
    }

    componentWillUnmount() {
        allDeviceData["MM3"] = [];
        allDeviceData["MM3ai"] = [];
        allDeviceData["UM3+"] = [];
        allDeviceData["ZM1"] = [];
        allDeviceData["UM1"] = [];
        allDeviceData["VC10"] = [];
    }
    fetchAllDeviceData = (groupedData, label, firmwareUpgradePopupData) => {
        Object.keys(groupedData[label]).map(fwVersion => {
            if ( ['MM3', 'UM1', 'MM3ai',"VC10","ZM1"].includes(label)) {
                const obj = {};
                obj.deviceCount = groupedData[label][fwVersion].length;
                obj.currentFirmware = fwVersion;
                obj.newFirmware = this.newFirmwareData(label, fwVersion);
               return firmwareUpgradePopupData[label].push(obj)
            }
            else{Object.keys(groupedData[label][fwVersion]).map(hwRevision => {
                    const obj = {};
                    obj.deviceCount = groupedData[label][fwVersion][hwRevision].length;
                    obj.hwRevision = hwRevision;
                    obj.currentFirmware = fwVersion;
                    obj.newFirmware = this.newFirmwareData(label, fwVersion, hwRevision);
                   return firmwareUpgradePopupData[label].push(obj)
            });
        }
        })
    }
    newFirmwareData = (label, fwVersion,hwRevision) => {
        label = label === "UM3+" && hwRevision === "CU" ? label+"c" : label;
        let firmwareData = [];
        fwVersion && this.props.firmwareVersionList && this.props.firmwareVersionList[label.toLowerCase()][fwVersion] && this.props.firmwareVersionList[label.toLowerCase()][fwVersion].sort(sortFirmwareversions).map(field => {
            return firmwareData.push({ label: field, value: field });
        });
        return firmwareData;
    }
    componentDidMount() {
        this.setState({ isLoading: true });
        const mm3Data = groupingSelectedRows(tableOptions.selectedRows.filter(x => ['MM3', 'UM1', 'MM3ai','VC10',"ZM1"].includes(x.deviceType) ), ['deviceType', 'softwareVersion']);
        const notMM3Data = groupingSelectedRows(tableOptions.selectedRows.filter(x => !['MM3', 'UM1', 'MM3ai','VC10',"ZM1"].includes(x.deviceType) ), ['deviceType',"softwareVersion", 'hardwareVersion']);
        const groupedData = Object.assign({}, mm3Data, notMM3Data);
        const firmwareUpgradePopupData = { ...allDeviceData };
        if (this.props.firmwareVersionList && Object.keys(this.props.firmwareVersionList).length > 0) {
            Object.keys(groupedData).sort().map(label => this.fetchAllDeviceData(groupedData, label, firmwareUpgradePopupData));
            this.setState({ isLoading: false });
        }
        this.setState({ groupedData: groupedData });
    }
    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
        return btnName === "next" ? this.onNextBtnClickHandler() :
            btnName === "cancel" ? this.onCancelClickHandler() :
                btnName === "confirm" ? this.onConfirmClickHandler() :
                    btnName === "close" ? this.props.closeModal("isUpgradeDeviceModalOpen") :
                        btnName === "updateCancel" ? this.updateConfCancelClickHandler() :
                            btnName === "ok" ? this.updateConfOkClickHandler() : null
    }
    componentDidUpdate() {
        const firmwareUpgradePopupData = { ...allDeviceData };
        if ((this.props.firmwareVersionList && Object.keys(this.props.firmwareVersionList).length > 0) && firmwareUpgradePopupData["MM3"].length === 0 && firmwareUpgradePopupData["UM3+"].length === 0 && firmwareUpgradePopupData["ZM1"].length === 0 && firmwareUpgradePopupData["UM1"].length === 0 && firmwareUpgradePopupData["MM3ai"].length === 0 && firmwareUpgradePopupData["VC10"].length === 0) {
            this.setState({ isLoading: true });
            Object.keys(this.state.groupedData).sort().map(label => this.fetchAllDeviceData(this.state.groupedData, label, firmwareUpgradePopupData));
            this.setState({ isLoading: false });
        }
    }
    render() {
        const selectedRowsCount = tableOptions.selectedRows && tableOptions.selectedRows.length;
        const breadCrumb = getClassName({ node: this.props.selectedNode });
        const selectedRowsGroupBy = Object.fromEntries(Object.entries(this.state.groupedData).map(([k, v]) => [k.toUpperCase(), v]));
        let modalTitle, modalBody, modalFooter;
        if (!this.state.updateConfirmationPopup) {
            modalTitle = <div className="popup-title"><FormattedMessage id={`fwUpgrade.popup.title`} /> [ {!this.state.selectedNewUpgradePopup ? selectedRowsCount : this.state.newUpgradeRowsCount} Device(s)] {breadCrumb} </div>;
            modalBody = <div className="min-vh-25 single-select-dropdown">
                {this.state.isLoading || this.props.isLoadingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                {!this.state.selectedNewUpgradePopup ?
                    <Fragment>
                        {this.state.errorMessage ? <span className="text-danger float-right">{this.state.errorMessage}</span> : ""}
                        {Object.entries(allDeviceData).sort().map(([key, value], i) => {
                            return (
                                <Form key={key + '-' + i}>
                                    {<Fragment>
                                        {value.length > 0 ? <Row className="col-12"><strong><u>{key}</u></strong></Row> : null}
                                        {value.length > 0 && value.map((item, itemKey) => {
                                            return (
                                                <div key={key + '-' + (itemKey) + '-' + (Math.floor((Math.random() * 100)))} className="col-12 mt-2 m-0 p-0">
                                                    <Row className="m-0 p-0 mb-2">{Object.keys(item).map((field, j) => itemKey === 0 ? <div key={key + '-' + (itemKey + j) + '-' + (Math.floor((Math.random() * 1000)))} className={`${key !== "UM3+" && field === "deviceCount" ? "col-6" : "col-3"} m-0 p-0`}><strong><FormattedMessage id={`fwUpgrade.popup.label.${field}`} /></strong></div> : "")}</Row>
                                                    <Row className="m-0 p-0 mb-3 deviceStatePopup">
                                                        {Object.entries(item).map(([field,data], k) => {
                                                            if (!Array.isArray(data)) {
                                                                return <div key={key + '_' + k + '-' + (Math.floor((Math.random() * 1000)))} className={`${key !== "UM3+" && field === "deviceCount" ? "col-6" : "col-3"} m-0 p-0`}>{data}</div>
                                                            } else {
                                                                return (
                                                                    <div key={key + '-' + k + '-' + (Math.floor((Math.random() * 1000)))} className="col-3 m-0 p-0">
                                                                        <Fragment>
                                                                            <SingleSelect data={data} setType={(e) => this.ddlValueUpdate(e, key.toLocaleLowerCase(), item.currentFirmware,item.hwRevision)} value={this.state.selectedItems[key ==="UM3+" && item.hwRevision ==="CU" ? key.toLowerCase()+"c" : key.toLowerCase()] ? this.state.selectedItems[key ==="UM3+" && item.hwRevision ==="CU" ? key.toLowerCase()+"c" : key.toLowerCase()][item.currentFirmware] ? this.state.selectedItems[key ==="UM3+" && item.hwRevision ==="CU" ? key.toLowerCase()+"c" : key.toLowerCase()][item.currentFirmware] : "--select--" : "--select--"} icon='ok' />
                                                                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                                                        </Fragment>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </Fragment>
                                    }
                                </Form>
                            )
                        })
                        }
                    </Fragment> :
                    <Fragment>
                        {!this.state.newUpgradePopupErrorMessage && !this.state.successMessage ?
                            Object.entries(this.state.dspDataObj).map(([key, value], i) => {
                                    return (
                                        <Form key={key + '-' + value + '-' + i}>
                                            <Row className="col-12"><strong><u>{key.toUpperCase()}</u></strong></Row>
                                            <div className="col-12 mt-2 m-0 p-0">
                                                <Row className="m-0 p-0 mb-3">
                                                    <div className="col-6 m-0 p-0"><strong><FormattedMessage id='fwUpgrade.popup.label.devices' /></strong></div>
                                                    <div className="col-6 m-0 p-0 pl-2"><strong><FormattedMessage id='fwUpgrade.popup.label.firmware' /></strong></div>
                                                </Row>
                                                {Object.entries(value).map(([item, data], j) => {
                                                    if (['mm3', 'um1', 'mm3ai',"vc10","zm1"].includes(key)) {
                                                        return (
                                                            <Row key={item + '-' + data + '-' + j} className="m-0 p-0 mb-4">
                                                                <div className="col-6 m-0 p-0 newStateDevices">{selectedRowsGroupBy[key.toUpperCase()][item].map(property => <div key={property.id}>{property.serialNumber}</div>)}</div>
                                                                <div className="col-6 m-0 p-0 pl-2">{data}</div>
                                                            </Row>)
                                                    }else{
                                                        return Object.entries(data).map(([val, datas], j)=>{
                                                            return (
                                                                <Row key={item + '-' + datas + '-' + j} className="m-0 p-0 mb-4">
                                                                    <div className="col-6 m-0 p-0 newStateDevices">{selectedRowsGroupBy[key.toUpperCase()][val][item].map(property => <div key={property.id}>{property.serialNumber}</div>)}</div>
                                                                    <div className="col-6 m-0 p-0 pl-2">{datas}</div>
                                                                </Row>)
                                                        })
                                                    }
                                                })}
                                            </div>
                                        </Form>)
                                }) 

                            :
                            <Fragment>
                                {this.state.newUpgradePopupErrorMessage ?
                                    <span className="text-danger">{this.state.newUpgradePopupErrorMessage}</span> :
                                    <span className="text-success">{this.state.successMessage}</span>}
                            </Fragment>
                        }
                    </Fragment>
                }
            </div>
            modalFooter = !this.state.selectedNewUpgradePopup ? [{ className: "modalSaveBtn", name: <FormattedMessage id='fwUpgrade.popup.button.next' />, value: "next" }] : !this.state.newUpgradePopupErrorMessage && !this.state.successMessage ? [{ className: "modalCancelBtn", name: <FormattedMessage id='fwUpgrade.popup.button.cancel' />, value: "cancel", disabled: this.state.isBtnDisabled }, { className: "modalSaveBtn", name: <FormattedMessage id='fwUpgrade.popup.button.confirm' />, value: "confirm", disabled: this.state.isBtnDisabled }] : [{ className: "modalSaveBtn", name: <FormattedMessage id='fwUpgrade.popup.button.close' />, value: "close" }];
        } else {
            modalTitle = <div className="popup-title"><FormattedMessage id={`fwUpgrade.updateConfirmation.popup.title`} /></div>
            modalBody = <div className="min-vh-25">
                <div className="mb-2"><FormattedMessage id='fwUpgrade.popup.updateConfirmation.message' /></div>
                <div className="mb-2"><FormattedMessage id='fwUpgrade.popup.updateConfirmation.unRegistered' /></div>
                <div className="mb-2"><FormattedMessage id='fwUpgrade.popup.updateConfirmation.configInprocess' /></div>
                <div className="mb-2"><FormattedMessage id='fwUpgrade.popup.updateConfirmation.fwUpgradeInprocess' /></div>
                <div className="mb-2"><FormattedMessage id='fwUpgrade.popup.updateConfirmation.wanttoContinue' /></div>
            </div>
            modalFooter = [{ className: "modalCancelBtn", name: <FormattedMessage id='fwUpgrade.popup.button.cancel' />, value: "updateCancel" }, { className: "modalSaveBtn", name: <FormattedMessage id='fwUpgrade.popup.button.ok' />, value: "ok" }]

        }
        return (
            <Fragment>
                {this.props.isUpgradeDeviceModalOpen ?
                    <ModalWindow
                        show={this.props.isUpgradeDeviceModalOpen}
                        onHide={() => this.props.closeModal("isUpgradeDeviceModalOpen")}
                        size={"lg"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        )
    }
}

export default FirmwareUpgradeDeviceComponent;
import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { notificationTemplatesData } from '../../../utility/notificationTemplateUtility';
import SingleSelect from '../../../hoc/singleSelect';
import ToggleSwitch from '../../../hoc/toggleSwitch';
import TextField from '../../../hoc/form/textField';
import TextArea from '../../../hoc/form/textArea';
import AlertMessage from '../../../hoc/alert';
import { NotificationTemplateContext } from '../provider/notificationTemplateProvider';
import NotifiTempValidationModal from './notifiTempValidationModal';
import TagVariablesComponent from './tagVariablesComponent';
import { FormattedMessage } from 'react-intl';

class NotificationTemplateForm extends Component {
    static contextType = NotificationTemplateContext;
    countRef = React.createRef(0);
    nameFocusRef = React.createRef(null);
    componentWillReceiveProps(){
        if(this.context.state.currentBtnName === "createTemplate"){
            setTimeout(()=>{this.countRef.current < 1 && this.nameFocusRef && this.nameFocusRef.current && this.nameFocusRef.current.focus();
                this.countRef.current+=1;
            },700)
        }  
    }
    render() {
        return (
            <React.Fragment>
                <Row className="m-0 flex-center border-color" style={{ padding: '1vh' }}>
                    <Col>
                        <Row className="single-select-dropdown option-list">
                            {notificationTemplatesData.formFields.topForm.map((item, key) => {
                                return <Col key={key}>
                                    <Row className="flex-center">
                                        <Col className="p-0 m-0" xs={5}>{item.label}</Col>
                                        <Col xs={7}>
                                            <SingleSelect
                                                data={this.context.state[item.value]}
                                                setType={(val) => this.context.updateFormData(val, item.value,)}
                                                value={this.context.state.formData[item.value]}
                                                icon={true}
                                                field={item.value}
                                                isDisplayLabel={true}
                                                isLabelRightIcon={item.value === 'notificationTemplate' ? true : false}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            })}
                        </Row>
                    </Col>
                    <Col className="p-0 m-0 text-right" xs={2}>
                        <Button className='modalCancelBtn' onClick={() => this.context.handleClickButton('createTemplate')}> <FormattedMessage id={'settings.notificationTemplates.text.createtemplate'}/></Button>
                    </Col>
                </Row>

                {this.context.state.validationMsg.msg !== "" && this.context.state.validationMsg.for === undefined ?
                    <Row style={{ marginTop: '1.2vh' }}> <Col className='d-flex justify-content-center'>
                        <AlertMessage message={this.context.state.validationMsg.msg} type={this.context.state.validationMsg.type} setVisible={this.context.closeAlertMessage} />
                    </Col></Row>
                    : ''}

                <Row className="nt-template-area">
                    <Col>
                        <Row className="p-0 m-0">
                            <Col className='template-action'>
                                <span className="col-8 p-0 word-break-all">{this.context.state.formData.name}</span>
                                <span>
                                    {this.context.state.currentBtnName !== 'createTemplate' && this.context.state.currentBtnName !== 'create' ?
                                        <React.Fragment>
                                            <Button className='modalCancelBtn each-btn-gap' onClick={() => this.context.handleClickButton('save')}> <FormattedMessage id={"dm.column.save"}/> </Button>
                                            <Button className='modalCancelBtn each-btn-gap' onClick={() => this.context.handleClickButton('saveAs')}> <FormattedMessage id={"manage.profiles.saveas"}/></Button>
                                            <Button disabled={this.context.state.formData.systemDefined ? true : false} className={this.context.state.formData.systemDefined ? 'disabledBtn each-btn-gap' : 'modalCancelBtn each-btn-gap'} onClick={() => this.context.handleClickButton('delete')}> <FormattedMessage id={"db.common.delete"}/> </Button>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <Button className='modalCancelBtn each-btn-gap' onClick={() => this.context.handleClickButton('create')}><FormattedMessage id={"db.common.create"}/></Button>
                                        </React.Fragment>
                                    }
                                </span>
                            </Col>
                        </Row>

                        <Row className="p-0 m-0">
                            <Col className="border-color template-form-area">
                                {notificationTemplatesData.formFields.btmForm.map((item, key) => {
                                    return <Row key={key} className="flex-center template-form-each-row-gap">
                                        <Col xs={4}>{item.label}</Col>
                                        <Col xs={8}>
                                            {item.type === 'text' ?
                                                <span>
                                                    <TextField
                                                        type={item.type}
                                                        value={this.context.state.formData[item.value]}
                                                        refVal={(item.value === "name" && !this.context.state.formData.systemDefined) ? this.nameFocusRef : null}
                                                        isDisabled={this.context.state.formData.systemDefined}
                                                        field={item.value}
                                                        handleOnchange={this.context.updateFormData}
                                                        size="sm"
                                                        className={item.isErr ? 'form-err-border-red' : ""}
                                                        handleOnKeyDown={this.context.handleOnKeyDown}
                                                    />
                                                    {this.context.state['isOpenTagSection' + item.value] ? <TagVariablesComponent fieldName={item.value} /> : ""}
                                                </span>
                                                : ""}
                                            {item.type === 'textarea' ?
                                                <span>
                                                    <TextArea
                                                        type={item.type}
                                                        value={(this.context.state.formData[item.value]).replace(/\s?(<br\s?\/?>)\s?/g, "\r\n")}
                                                        handleOnchange={this.context.updateFormData}
                                                        size="sm"
                                                        rows={6}
                                                        field={item.value}
                                                        isDisabled={this.context.state.formData.systemDefined}
                                                        className={item.isErr ? 'form-err-border-red' : ""}
                                                        handleOnKeyDown={this.context.handleOnKeyDown}
                                                    />
                                                    {this.context.state['isOpenTagSection' + item.value] ? <TagVariablesComponent fieldName={item.value} /> : ""}
                                                </span>
                                                : ""}
                                            {item.type === 'switch' ?
                                                <Row>
                                                    <Col xs={6}>
                                                        <ToggleSwitch
                                                            toggleSwitchValue={(e) => this.context.updateFormData(this.context.state.formData[item.value] === true ? false : true, item.value)}
                                                            value={this.context.state.formData[item.value]}
                                                            field={item.value}
                                                            leftValue='On'
                                                            rightValue='Off'
                                                        />
                                                    </Col>
                                                </Row>
                                                : ""}
                                            {item.type === 'normal' ?
                                                <div>{this.context.state.formData[item.value].map((data, key) => <span key={key}>{data.label} ({data.place_holder}){key !== this.context.state.formData[item.value].length - 1 && `,`} </span>)}</div>
                                                : ""}
                                        </Col>
                                    </Row>
                                })}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.context.state.isOpenValidationModal ? <NotifiTempValidationModal /> : ""}
            </React.Fragment>
        );
    }
}

export default NotificationTemplateForm;
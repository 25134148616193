import React, { Component, Fragment } from 'react';
import { Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ToggleSwitch from '../../hoc/toggleSwitch';
import { getNodeRouteParams, updateTreeOnEditNode } from '../containers/treeMethods';
import { nodeOperations } from '../services/treeService';
import { TreeContext } from '../provider/treeProvider';
import ModalWindow from '../../hoc/modelWindow';
import Loader from '../../hoc/loader';
import * as treeBuilderActions from './../actions/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AlertMessage from '../../hoc/alert';

class EditNodeComponent extends Component {
    static contextType = TreeContext;
    state = {
        successMessage: "",
        errorMessage: "",
        disabled: false,
        enteredText: "",
        siteData: {},
        messageDisplay: "",
        toggleSwitchStatus: false,
        isLoading: false,
    }

    onChangeHandler = (e) => {
        if (e.target.value.length > 0) {
            if (!(/^(?!.*[\\\\/;$(#?%).].*)/).test(e.target.value)) {
                this.setState({ disabled: true, enteredText: e.target.value, messageDisplay: <FormattedMessage id='groupmanagement.context.message.name.invalid' /> });
            } else {
                this.setState({ disabled: false, enteredText: e.target.value, messageDisplay: "" });
            }
        } else {
            this.setState({ disabled: true, enteredText: "", messageDisplay: <FormattedMessage id='groupmanagement.addNode.error.label' /> });
        }
    }

    toggleSwitch = () => {
        this.setState({
            siteData: {
                ...this.state.siteData,
                "overrideGps": !this.state.toggleSwitchStatus
            },
            toggleSwitchStatus: !this.state.toggleSwitchStatus
        })
    }

    onSiteDataChangeHandler = (e) => {
        this.setState({
            siteData: {
                ...this.state.siteData,
                [e.target.title]: e.target.value
            }
        });
    }

    componentDidMount() {
        if (this.props.clickedNode.node.type === "SITE" || this.props.clickedNode.node.type === "LATERAL_SITE") {
            this.setState({
                siteData: {
                    address: this.props.clickedNode.node.address,
                    poleLocation: this.props.clickedNode.node.poleLocation,
                    lat: this.props.clickedNode.node.lat,
                    lon: this.props.clickedNode.node.lon,
                    overrideGps: this.props.clickedNode.node.overrideGps
                },
                toggleSwitchStatus: this.props.clickedNode.node.overrideGps,
                enteredText: this.props.clickedNode.node.name
            })
        } else {
            this.setState({
                enteredText: this.props.clickedNode.node.name
            })
        }
    }

    saveEditedNodeData = async () => {
        let nodeDetails = {};
        let infoObj = null;
        //eslint-disable-next-line
        const latLongRegEx = /^\-?\d+(\.\d+)?$/;

        if (this.props.clickedNode.node.type !== "SITE" && this.props.clickedNode.node.type !== "LATERAL_SITE") {
            nodeDetails.type = this.props.clickedNode.node.type;
            nodeDetails.name = this.state.enteredText;
        } else {
            nodeDetails = this.state.siteData;
            nodeDetails.name = this.state.enteredText;
            nodeDetails.type = this.props.clickedNode.node.type;
            nodeDetails.address = nodeDetails.address ? nodeDetails.address : "";
            nodeDetails.poleLocation = nodeDetails.poleLocation ? nodeDetails.poleLocation : "";
            nodeDetails.overrideGps = nodeDetails.overrideGps ? nodeDetails.overrideGps : false;
            nodeDetails.lat = nodeDetails.lat ? nodeDetails.lat : "";
            nodeDetails.lon = nodeDetails.lon ? nodeDetails.lon : "";
            if (nodeDetails.overrideGps === false) {
                nodeDetails.lat = "";
                nodeDetails.lon = "";
            }
        }

        if (nodeDetails.overrideGps && (nodeDetails.lat === "" || nodeDetails.lon === "")) {
            infoObj = <FormattedMessage id='groupmanagement.context.message.latlong.required' />
        } else if (nodeDetails.overrideGps && (((!latLongRegEx.test(nodeDetails.lat)) || (!latLongRegEx.test(nodeDetails.lon))))) {
            infoObj = <FormattedMessage id='groupmanagement.context.message.latlong.invalid' />
        }
        else {
            this.setState({ isLoading: true}, async () => {
                const routeParams = getNodeRouteParams(this.props.clickedNode);
                const res = await nodeOperations(routeParams, 'editNode', nodeDetails);
                if (res) {
                    if (res.status !== "FAIL") {
                        this.setState({ isLoading: false}); 
                        this.props.closeModal();

                         //const reDirect = this.context.state.highlightedNode && this.context.state.highlightedNode.node && (this.props.clickedNode.node.type === this.context.state.highlightedNode.node.type) && (this.props.clickedNode.node.name === this.context.state.highlightedNode.node.name) ? "editNode" : "";
                         //this.context.loadNextLevel({ "node": this.props.clickedNode.parentNode }, this.props.clickedNode.parentNode.routeParams, null, reDirect);
                            let data = updateTreeOnEditNode(this.context.state, this.props.clickedNode, nodeDetails);
                            this.props.updateSelectedTree(data);
                            this.context.state.highlightedNode && this.context.loadNextLevel({ "node": this.context.state.highlightedNode.node }, this.context.state.highlightedNode.node.routeParams, null, 'editNode');
                            this.context.setLoadDevicesList(true);
                    } else {
                        this.setState({ errorMessage: res.message, isLoading: false });
                    }
                } else {
                    this.setState({ isLoading: false}); 
                }
            })
        }
        return infoObj !== null ? this.setState({ errorMessage: infoObj }) : "";
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
       return btnName === "save" ? this.saveEditedNodeData() : null;
    }
    closeAlertMessage = () => {
        this.setState({ errorMessage: ''})
      }

    render() {
        const modalTitle = <div className="popup-title"><FormattedMessage id='groupmanagement.context.edit' /> <FormattedMessage id={`groupmanagement.context.${this.props.clickedNode.node.type.toLowerCase()}`} /> : {this.props.clickedNode.node.name}</div>;
        const modalBody = <div className="min-vh-25">
            {!this.state.successMessage ?
                <Form>
                    {this.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                    {
                        this.state.errorMessage ? <AlertMessage position={"align-alert-center mb-2"} type={'danger'} timer={10000} message={this.state.errorMessage} setVisible={() => this.closeAlertMessage()} /> : ""
                    }
                    <div>
                        <Row className="m-0 p-0">
                            <label className="col-4 m-0 p-0"><FormattedMessage id={`groupmanagement.context.${this.props.clickedNode.node.type.toLowerCase()}`} /> <FormattedMessage id='groupmanagement.context.name' /></label>
                            <input className="rounded col-8 p-2 border-ccc" title="name" value={this.state.enteredText} onChange={this.onChangeHandler} type="text" placeholder={`Please specify the ${this.props.clickedNode.node.type.toLowerCase()} name`} />
                            <span className={"col-8 offset-4 text-right txt-color mt-1 p-0"}>{this.state.messageDisplay}</span>
                        </Row>
                        {this.props.clickedNode.node.type === "SITE" || this.props.clickedNode.node.type === "LATERAL_SITE" ?
                            <div className="mt-3">
                                <Row className="m-0 p-0 mb-3">
                                    <label className="col-4 m-0 p-0"><FormattedMessage id='groupmanagement.context.siteLocation' /></label>
                                    <input className="rounded col-8 p-2 border-ccc" value={this.state.siteData.poleLocation} title="poleLocation" onChange={this.onSiteDataChangeHandler} type="text" placeholder="Pole Location" />
                                </Row>
                                <Row className="m-0 p-0 mb-3">
                                    <label className="col-4 m-0 p-0"><FormattedMessage id='groupmanagement.context.streetAddress' /></label>
                                    <input className="rounded col-8 p-2 border-ccc" value={this.state.siteData.address} title="address" onChange={this.onSiteDataChangeHandler} type="text" placeholder="Street Address" />
                                </Row>
                                <Row className="m-0 p-0 mb-3">
                                    <label className="col-4 m-0 p-0"><FormattedMessage id='groupmanagement.context.overrideGps' /></label>
                                    <span className="col-4 m-0 p-0"><ToggleSwitch toggleSwitchValue={this.toggleSwitch} value={this.state.toggleSwitchStatus} leftValue="On" rightValue="Off" /></span>
                                </Row>
                                <Row className="m-0 p-0 mb-3">
                                    <label className="col-4 m-0 p-0"><FormattedMessage id='groupmanagement.context.latitude' /></label>
                                    <input className={"rounded col-8 p-2 border-ccc " + (!this.state.toggleSwitchStatus ? "disabled-field" : "")} value={this.state.siteData.lat} disabled={!this.state.toggleSwitchStatus} title="lat" onChange={this.onSiteDataChangeHandler} type="text" placeholder="Latitude" />
                                </Row>
                                <Row className="m-0 p-0 mb-3">
                                    <label className="col-4 m-0 p-0"><FormattedMessage id='groupmanagement.context.longitude' /></label>
                                    <input className={"rounded col-8 p-2 border-ccc " + (!this.state.toggleSwitchStatus ? "disabled-field" : "")} value={this.state.siteData.lon} disabled={!this.state.toggleSwitchStatus} title="lon" onChange={this.onSiteDataChangeHandler} type="text" placeholder="Longitude" />
                                </Row>
                            </div> : ""
                        }
                    </div>
                </Form> : null
            }
        </div>;
        const modalFooter = [{ className: "modalSaveBtn", name: <FormattedMessage id='groupmanagement.context.save' />, value: "save", disabled: this.state.disabled }];
        return (
            <Fragment>
                {this.props.isModalOpen ?
                    <ModalWindow
                        show={this.props.isModalOpen}
                        onHide={() => this.props.closeModal()}
                        size={"md"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
      updateSelectedTree: (tree) => dispatch(treeBuilderActions.updateSelectedTree(tree))
    }
  }


export default withRouter(connect(null, mapDispatchToProps)(EditNodeComponent));
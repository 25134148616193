import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import { getReportSettings, generateReportData, getReportFile } from '../services/reportsService';
import { dateConvertion } from '../../../../services/utilityService';
import { manageReportconfig } from '../../../../utility/deviceManagementUtility';
import {numberOnlyRegex} from '../../../../filters/commonFunction';

export const ReportsContext = React.createContext();

class ReportsProvider extends Component {


  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      reportTypes: [{label: '--select--', value: '--select--'}],
      reportType: '',
      reportFormat: 'HTML',
      reportSeparator: 'COMMA',  
      roleAndPermission: this.props.roleAndPermission,
      isOpenModal: false,
      errorMsgObj: {type : '', msg: ''},
      ...JSON.parse(JSON.stringify(manageReportconfig))
    }
    this.dnp3EndDate = dateConvertion(new Date().getTime(), 'endDate', this.props.roleAndPermission.timezone)
    this.dnp3StartDate = this.dnp3EndDate + 1 - (7*1000*60*60*24)
    this.lowAmpEndDate = dateConvertion((new Date().getTime() - (1*1000*60*60*24)), 'endDate', this.props.roleAndPermission.timezone)
    this.lowAmpStartDate = this.lowAmpEndDate + 1 - (15*1000*60*60*24)
  }

  ReportList = (data) => {
    let reportTypes = [];
    data.forEach(obj => {
      if (this.props.roleAndPermission.roleId <= obj.userRole) {       // Filter reports to display based on the role permissions
        reportTypes.push({label: obj.reportName, value: obj.reportName});
      }
    });
    this.setState({ reportTypes: reportTypes }, () => this.updateReportType(reportTypes[0].value));
  }

  fetchReportSettings = async () => {
    this.setState({
      loading: true
    });
    getReportSettings()
    .then(data => {
      this.setState({ loading: false });
      if (data && data.length > 0) this.ReportList(data);
      else this.setState({ reportTypes: [{label: '--select--', value: '--select--'}], reportType:"--select--" });
    }).catch(err => {
      this.setState({ loading: false });
    });

  }

  componentDidMount = async () => {
    this.fetchReportSettings();
  }

  updateReportType = (selectedType) => {
    let defaultState = Object.assign({}, this.state, JSON.parse(JSON.stringify(manageReportconfig)));
    if (this.state.reportType !== selectedType) {
      this.setState({ 
        ...defaultState,
        reportType: selectedType, 
        treeCheckboxVisible: (selectedType === 'DNP3 Points') ? 'FEEDER' : 'all',
        endDate: (selectedType === 'DNP3 Points') ? dateConvertion(this.dnp3EndDate, 'short', this.props.roleAndPermission.timezone) : (selectedType === 'Sensors on Low Amp') ? dateConvertion(this.lowAmpEndDate, 'short', this.props.roleAndPermission.timezone) : '',
        startDate: (selectedType === 'DNP3 Points') ? dateConvertion(this.dnp3StartDate, 'short', this.props.roleAndPermission.timezone) : (selectedType === 'Sensors on Low Amp') ? dateConvertion(this.lowAmpStartDate, 'short', this.props.roleAndPermission.timezone) : ''
      });  
    }
  }

  onCalenderCallback = (start, end) => {
    this.setState({ 
      startDate: moment(new Date(start)).format('MM/DD/YYYY'), 
      endDate: moment(new Date(end)).format('MM/DD/YYYY')
    });
  }

  onCheckboxChange = (evt, list) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i].shortname === evt.target.name) {
            list[i].value = !list[i].value;
        }
    }
  }

  onValChange = (value, field) => {
    this.setState({ [field]: value }, () => {
      // disable all other feeder checkbox if any one feeder is checked / selected
      if (this.state.reportType === 'DNP3 Points') {
        let feederList = this.getSelectedTreeData(this.state.reportTreeData);
        this.setState({ treeCheckboxDisable: feederList.feederIds && feederList.feederIds.length === 1 });
      }
    });
    if (field === 'reportFormat') this.setState({ 'reportSeparator': 'COMMA' });
  }

  getSelectedTreeData = (subscribedNode) => {
    let treeData = {
      hierIds: [],
      feederIds: [],
      selectedObj:[]
    };
    subscribedNode.forEach(function (obj) {
      if (obj.regionSelected) {
        treeData.selectedObj.push(obj.regionName);
        let children = obj.hasOwnProperty("substations") ? obj.substations : [];
        if (children.length > 0) {
          children.forEach(function (subobj) {
            if (subobj.substationSelected) {
              let feederChildren = subobj.hasOwnProperty("feeders") ? subobj.feeders : [];
              if (feederChildren.length > 0) {
                feederChildren.forEach(function (feederobj) {
                  if (feederobj.feederSelected) {
                    treeData.feederIds.push(feederobj.id);
                  }
                });
              } else {
                treeData.hierIds.push(subobj.id);
              }
            }
          });
        }
      }
    });
    return treeData;
  }

  getSelectedList = (list, propertyName) => {
    let s = [];
    list.forEach(function (val) { if(val.value) s.push((propertyName) ? val[propertyName]: val.name) });
    return s;
  }

  setWarning = (validationMsgKey) => {
    this.setState({
      loading: false,
      showInlineMsg: true,
      errorMsgObj: {type : 'danger', msg: <FormattedMessage id={`${validationMsgKey}`} /> },
    });
    return false;
  }

  ValidatePayload = (stateData) => {
    let reportPayload = this.getSelectedTreeData(stateData.reportTreeData);
    reportPayload['reportType'] = stateData.reportType;
    reportPayload['reportFormat'] = stateData.reportFormat;
    reportPayload['reportSeparator'] = (stateData.reportFormat === 'TEXT') ? stateData.reportSeparator : '';
    reportPayload['amperage'] = stateData.amperage;
    reportPayload['haBelowAmp'] = stateData.haBelowAmp;
    reportPayload['conflictReport'] = (stateData.selectedPhaseId === 'conflictReport') ? true : false;

    if ((stateData.startDate !== '') && (stateData.startDate !== null)) {
        let startDateStr = dateConvertion(stateData.startDate+' 00:00:00:000', 'offsetTimestamp', this.props.roleAndPermission.timezone);
        reportPayload['fromDate'] = dateConvertion(startDateStr, 'serviceFormat', this.props.roleAndPermission.timezone);
    }
    if ((stateData.endDate !== '') && (stateData.endDate !== null)) {
        let endDateStr = dateConvertion(stateData.endDate+' 23:59:59:999', 'offsetTimestamp', this.props.roleAndPermission.timezone);
        reportPayload['toDate'] = dateConvertion(endDateStr, 'serviceFormat', this.props.roleAndPermission.timezone);
    }
    if(reportPayload.selectedObj.length === 0) return this.setWarning('manage.report.error.msg.empty.hierarchy');
    if (stateData.reportType === "DNP3 Points") {
      reportPayload['phases'] = this.getSelectedList(stateData.phasesList, 'name');
      reportPayload['reportGeneratorInputColumns'] = this.getSelectedList(stateData.pointsList, 'shortname');
      if(reportPayload.phases.length === 0) return this.setWarning('manage.report.error.msg.empty.phase');
      if(reportPayload.reportGeneratorInputColumns.length === 0) return this.setWarning('manage.report.error.msg.empty.points');
    } else if(stateData.reportType === "Device Health"){
      reportPayload['reportGeneratorInputColumns'] = this.getSelectedList(stateData.healthPointsList, 'shortname');
      if(reportPayload.reportGeneratorInputColumns.length === 0) return this.setWarning('manage.report.error.msg.empty.points');
    } else if (stateData.reportType === "Sensors on Low Amp") {
      if(stateData.amperage === null || stateData.amperage+'' === "") return this.setWarning('manage.report.error.msg.empty.threshold');
      if((stateData.haBelowAmp === null) || (stateData.haBelowAmp+'' === "")) return this.setWarning('manage.report.error.msg.empty.time');
      if((stateData.amperage < 1 || stateData.amperage > 30)) return this.setWarning('manage.report.error.msg.empty.limit.threshold');
      if(!(numberOnlyRegex.test(stateData.amperage))) return this.setWarning('manage.report.error.msg.wrong.limit.threshold');
      if(!(numberOnlyRegex.test(stateData.haBelowAmp))) return this.setWarning('manage.report.error.msg.wrong.limit.time');
      if((stateData.haBelowAmp < 1 || stateData.haBelowAmp > 100)) return this.setWarning('manage.report.error.msg.empty.limit.time');
    }
    return reportPayload;
  }
  
  getGeneratedReport = () => {
    this.setState({
      loading: true,
      errorMsgObj: { type: '', msg: '' },
      showInlineMsg: false }, () => {
    let reportPayload = this.ValidatePayload(this.state)
    if (reportPayload) {
      generateReportData(reportPayload, <FormattedMessage id='manage.report.error.title' />) // Generate report API
        .then(fileData => {
          fileData?.data?.status === "FAIL" ? this.setState({ loading: false, showInlineMsg: true, errorMsgObj: { type: 'danger', msg: fileData.data.message } }) :
            getReportFile({ fileName: fileData.data, reportFormat: reportPayload.reportFormat, separator: reportPayload.reportSeparator, reportType: reportPayload.reportType }, (reportPayload.reportFormat === "HTML") ? {} : { responseType: "arraybuffer" }) // Download generated file
              .then(data => {
                let fileName = reportPayload['reportType'].split(" ").join("_") + "_Report_" + moment(new Date()).format("MM_DD_YYYY");
                let type = "application/octet-stream";
                if (reportPayload.reportFormat === "EXCEL") {
                  fileName = fileName + ".xls";
                } else if (reportPayload.reportFormat === "HTML") {
                  fileName = fileName + ".html";
                } else if (reportPayload.reportFormat === "TEXT" && reportPayload.reportSeparator === "COMMA") {
                  fileName = fileName + ".csv";
                } else if (reportPayload.reportFormat === "TEXT" && reportPayload.reportSeparator === "TAB") {
                  fileName = fileName + ".tsv";
                } else {
                  fileName = fileName + ".txt";
                }

                // Open the HTML report in a new tab/window and download others
                if (reportPayload.reportFormat === "HTML") {
                  var newWindow = window.open();
                  newWindow.document.write(data.data);
                  newWindow.document.title = fileName;
                } else {
                  let blob = new Blob([data.data], { type: type });
                  saveAs(blob, fileName);
                }
                this.setState({ loading: false });
              }).catch(err => {
                this.setState({ loading: false });
              });
        }).catch(err => {
          this.setState({ loading: false });
        });
    }
  })
  }

  closeConfigModal = () => {
    this.setState({ isOpenModal: false });
  }

  render() {
    return (
      <ReportsContext.Provider value={{
        state: this.state,
        getReportSettings: this.fetchReportSettings,
        setReportType: this.updateReportType,
        getCalenderCallback: this.onCalenderCallback,
        updateCheckboxList: this.onCheckboxChange,
        setChange: this.onValChange,
        generateReport: this.getGeneratedReport,
        closeConfigModal: this.closeConfigModal
      }}>
        {this.props.children}
      </ReportsContext.Provider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    roleAndPermission: state.loginData.roleAndPermission
  }
}

export default withRouter(connect(mapStateToProps)(ReportsProvider));

export const ReportsConsumer = ReportsContext.Consumer;
import { getURL } from '../../../providers/configProvider';
import { requestPOST, requestGET, requestQAll } from '../../../services/extendedService';

export const getAllLicenseData = () => {
    var requestParams = { 'UPLOADTYPE': 'LICENSE', 'apiType': 'data' }
    const url = getURL('licensemanagement', 'getlicenselist', requestParams, true);
    return requestGET(url);
};

export const uploadLicenseURL = (data) => {
    const url = getURL('licensemanagement', 'importlicensedata', {'apiType':'data'});
    return requestPOST(url, data)
};

export const resetLicenseData = () => {
    var requestParams = {'apiType':'data'};
    var namePath = ['disturbancelicensereset', 'devicemanagementlicensereset', 'faultslicensereset', 'powerqualitylicensereset', 'csclientlicensereset', 'usermanagementlicensereset'];
    var urls = [];
    namePath.forEach(urlName => {
        urls.push(getURL('licensemanagement', urlName, requestParams));
    });
    return requestQAll(urls);
};
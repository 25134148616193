import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import * as deviceManagementUtility from '../../../../utility/deviceManagementUtility';
import AddDeviceComponent from '../components/addDeviceComponent';
import { connect } from 'react-redux';
import { DeviceContext } from '../provider/deviceProvider';
import DeviceActionsComponent from '../components/deviceActionsComponent';
import { loadDeviceTypeList, deviceStateList, loadProfileList, loadFirmwareversionList, integrityPollDevices } from '../services/deviceService';
import DeleteDeviceComponent from '../components/deleteDeviceComponent';
import DeviceStateChange from '../components/deviceStateChange';
import ConfigureDeviceComponent from '../components/ConfigureDeviceComponent';
import FirmwareUpgradeDeviceComponent from '../components/firmwareUpgradeDeviceComponent';
import { configStatusCheck, otapStatusCheck, deviceStatusCheck } from '../../../../services/validationService';
import { closeAlertToaster } from '../../../../login/actions/loginActionDispatch';
import AlertMessage from '../../../../hoc/alert';
import { showAlertToaster } from "../../../../login/actions/loginActionDispatch";
import ampleStore from '../../../../store';

class DeviceActionsContainer extends Component {
    static contextType = DeviceContext;
    state = {}; //Don't remove this as it has dynamic data handling
    closeModal = (modalToClose) => {
        this.setState({ [modalToClose]: false });
    }
    addDevice = () => {
        this.setState({ isAddDeviceModalOpen: true, isLoadingData: true }, async () => {
            let deviceTypes = [{ label: "--select--", value: '--select--' }];
            let deviceTypeList = await loadDeviceTypeList();
            if (this.props.nodeType === "LATERAL" || this.props.nodeType === "LATERAL_SITE") {
                deviceTypeList = deviceTypeList.filter(e => !["MM3","MM3ai"].includes(e));
            }
            if(this.props.nodeType==="SITE"&&["MM3","MM3ai"].includes(this.props.selectedNode.siteDeviceType)){
                deviceTypeList = deviceTypeList.filter(e => ["MM3","MM3ai"].includes(e));
            }
            if (deviceTypeList) {
                deviceTypeList.sort();
                deviceTypeList.forEach(type => {
                    deviceTypes.push({ label: type, value: type });
                })
            }
            this.setState({ deviceTypes: deviceTypes, isLoadingData: false });
        });
    }

    deleteDevice = () => {
        this.setState({ isDeleteDeviceModalOpen: true });
    }

    integrityPollDevice = () => {
        const slNos = [];
        deviceManagementUtility.tableOptions.selectedRows.forEach(device => { 
            if(device.deviceType !== "ZM1" && device.deviceType !== "UM1" && device.deviceState !== "RMA")
            slNos.push(device.serialNumber) 
        });
        const deleteDevicesObj = slNos
        this.setState({ disableButton: true }, async () => {
            const data = await integrityPollDevices(deleteDevicesObj);
            if (data.status !== "FAIL") {
                this.setState({ disableButton: false });
                deviceManagementUtility.tableOptions.selectedRows = [];
                if (Object.keys(this.props.selectedNode).length > 0) await this.context.getDeviceList();
                ampleStore.dispatch(showAlertToaster(<FormattedMessage id='groupmanagement.success.integrityPoll' />, 'success'))
            } else {
                this.setState({ errorMessage: data.message, disableButton: false });
            }
        });
    }

    onStateChangeHandler = () => {
        this.setState({ isDeviceStateModalOpen: true, isLoadingData: true }, async () => {
            const deviceStateData = await deviceStateList();
            this.setState({ deviceStateData: deviceStateData, isLoadingData: false });
        });
    }

    onConfigDeviceClickHandler = () => {
        this.setState({ isConfigDeviceModalOpen: true, isLoadingData: true }, async () => {
            const data = deviceManagementUtility.tableOptions.selectedRows;
            const distinctFWVersions = [...new Set(data.map(x => x.deviceType === "ZM1" ? x.softwareVersion : ""))].filter(fwVersion => fwVersion !== "");
            const fwVersionData = distinctFWVersions.join();
            let profileList = await loadProfileList(fwVersionData);
            this.setState({ profileList: profileList, isLoadingData: false });
        });
    }

    onUpgradeDeviceClickHandler = () => {
        this.setState({ isUpgradeDeviceModalOpen: true, isLoadingData: true }, async () => {
            const firmwareVersionList = await loadFirmwareversionList();
            this.setState({ firmwareVersionList: firmwareVersionList, isLoadingData: false });
        });
    }

    clearFilterHandler = () => {
        deviceManagementUtility.tableOptions.pageNo = deviceManagementUtility.tableOptions.pageNo > 1 ? 1 : deviceManagementUtility.tableOptions.pageNo;
        deviceManagementUtility.tableOptions.tableColumns.forEach(column => {
            if (column.selectedItems && column.selectedItems.length > 0) {
                column.selectedItems = [];
                column.items = [];
            }
            if (column.enteredText && column.enteredText !== "") {
                column.enteredText = "";
            };
        });
        this.context.state.enableFilterBtn = false;
        this.context.getDeviceList(this.context.state.treeNode)
    }

    //To Enable/Disable the action 
    moveEnable = (device) => {
        const isConfigStatusCheck = configStatusCheck(device, "configStatus");
        const isOtapStatusCheck = otapStatusCheck(device, 'otapJobQueueStatus', 'otapStatus');
        return (isConfigStatusCheck || isOtapStatusCheck) ? false : true;
    }

    // Conditions check for all the selected devices for state change action enable
    isSelectedDeviceMove = (list) => {
        for (let i = 0; i < list.length; i++) {
            const device = list[i];
            const status = this.moveEnable(device);
            if (!status) {
                return false;
            }
        }
        return list && list.length ? true : false;
    };

    // Condition check for all the selected devices for config action to enable
    isDevicesConfig = (list) => {
        for (let i = 0; i < list.length; i++) {
            const device = list[i];
            const status = this.configureStatusEnable(device);
            if (!status) {
                return false;
            }
        }
        return list && list.length ? true : false;
    }

    //To Enable/Disable the action
    configureStatusEnable = (device) => {
        const isDeviceStatusCheck = deviceStatusCheck(device, "status")
        const isConfigStatusCheck = configStatusCheck(device, "configStatus");
        const isOtapStatusCheck = otapStatusCheck(device, 'otapJobQueueStatus', 'otapStatus');
        return (isDeviceStatusCheck || isConfigStatusCheck || isOtapStatusCheck) ? false : true;
    }

    render() {
        return (
            <Fragment>
                <DeviceActionsComponent
                    clearFilterHandlerMethod={this.clearFilterHandler}
                    addDeviceHandler={() => this.addDevice()}
                    deleteDeviceHandler={() => this.deleteDevice()}
                    integrityPollDeviceHandler={() => this.integrityPollDevice()}
                    onStateChangeHandler={() => this.onStateChangeHandler()}
                    onConfigDeviceClickHandler={() => this.onConfigDeviceClickHandler()}
                    onUpgradeDeviceClickHandler={() => this.onUpgradeDeviceClickHandler()}
                    selectedColumnsData={this.props.displayColumns}
                    stateValues={this.state}
                    devicesData={this.props.devices}
                    enableFilterBtnData={this.props.enableFilterBtn}
                    nodeType={this.props.nodeType}
                    onExportBtnClick={(clickedMenu) => this.props.onExportBtnClick(clickedMenu)}
                    roleAndPermission={this.props.roleAndPermission}
                    isSelectedDeviceMove={(list) => this.isSelectedDeviceMove(list)}
                    isDevicesConfig={(list) => this.isDevicesConfig(list)}
                    getEnabledColumn={(value) => this.props.getEnabledColumn(value)}
                />
                {
                    (this.props.showAlertToaster) ? <div className='d-flex justify-content-center'><AlertMessage type={this.props.msgType} timer={10000} message={this.props.message} setVisible={() => this.props.closeAlertModal()} /></div> : ""
                }
                {
                    this.state.isAddDeviceModalOpen ? <AddDeviceComponent nodeType={this.props.nodeType} selectedNode={this.props.selectedNode} orgId={deviceManagementUtility.tableOptions.orgId} deviceTypes={this.state.deviceTypes} closeModal={this.closeModal} isAddDeviceModalOpen={this.state.isAddDeviceModalOpen} isLoadingData={this.state.isLoadingData} /> :
                        this.state.isDeviceStateModalOpen ? <DeviceStateChange deviceStateData={this.state.deviceStateData} selectedNode={this.props.selectedNode} orgId={deviceManagementUtility.tableOptions.orgId} closeModal={this.closeModal} isDeviceStateModalOpen={this.state.isDeviceStateModalOpen}  isLoadingData={this.state.isLoadingData} /> :
                            this.state.isDeleteDeviceModalOpen ? <DeleteDeviceComponent selectedNode={this.props.selectedNode} closeModal={this.closeModal} isDeleteDeviceModalOpen={this.state.isDeleteDeviceModalOpen} /> :
                                this.state.isConfigDeviceModalOpen ? <ConfigureDeviceComponent profileList={this.state.profileList} selectedNode={this.props.selectedNode} closeModal={this.closeModal} isConfigDeviceModalOpen={this.state.isConfigDeviceModalOpen}  isLoadingData={this.state.isLoadingData} /> :
                                    this.state.isUpgradeDeviceModalOpen ? <FirmwareUpgradeDeviceComponent firmwareVersionList={this.state.firmwareVersionList} selectedNode={this.props.selectedNode} closeModal={this.closeModal} isUpgradeDeviceModalOpen={this.state.isUpgradeDeviceModalOpen}  isLoadingData={this.state.isLoadingData} /> : ""
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission,
        showAlertToaster: state.loginData.showAlertModal,
        message: state.loginData.errorMessage,
        msgType: state.loginData.msgType
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        closeAlertModal: () => dispatch(closeAlertToaster()),
      };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceActionsContainer);
import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { EProfileContext } from '../provider/eProfileProvider';

class EProfileTableTop extends Component {
    static contextType = EProfileContext;
    render() {
        return (
            <Container fluid className='e-profile-table-top'>
                <Row>
                    <Col xs={12} className="p-0-m-0">
                        <FormattedMessage id='db.common.setting' />&nbsp;:&nbsp;<FormattedMessage id='db.equipmentprofile' />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default EProfileTableTop;
import React, { Fragment, PureComponent } from 'react';
import { Row, Col, Button, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { DeviceContext } from '../../../provider/deviceProvider';
import { getLoggerFilesData, generateDiagnostics } from '../../../services/deviceService';
import Loader from '../../../../../../hoc/loader';
import { detailsModalData } from '../../../../../../utility/deviceManagementUtility';
import ConfirmRebootModal from './confirmRebootModal';
import TableHoc from '../../../../../../hoc/table';
import Alert from '../../../../../../hoc/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { setDownloadCSV } from '../../../../../../filters/commonFunction';

class DiagnosticsTab extends PureComponent {
    static contextType = DeviceContext;
    state = {
        isLoding: false,
        columnsData: detailsModalData.generatedLogsData,
        isOpenConfirmRebootModal: false,
        errMsg: { type: "", msg: "" }
    }

    downloadLoggerFilesReport(value) {
        this.setState({ isLoding: true }, () => {
            getLoggerFilesData(value)
                .then((data) => {
                    setDownloadCSV(data)
                    this.setState({ isLoding: false })
                }).catch((err) => { })
        })
    }

    generateDiagnostics = () => {
        this.setState({ isLoding: true }, () => {
            generateDiagnostics(this.props.deviceId)
                .then((res) => {
                    if (res.status !== "FAIL") {
                        this.context.updateSystemLogData(res)
                    } else {
                        this.setState({ errMsg: { type: "danger", msg: res.message } })
                    }
                    this.setState({ isLoding: false })
                }).catch((err) => {
                })
        })
    }
    closeAlertMsg = () => this.setState({ errMsg: { type: "", msg: "" } })

    openRebootConfirmModal = () => this.setState({ isOpenConfirmRebootModal: true })

    onCloseConfirmRebootModal = () => this.setState({ isOpenConfirmRebootModal: false })

    render() {
        const { deviceDetailsData } = this.props;
        return (
            <div className='diagnostics-tab'>
                {this.state.isLoding ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                {this.state.errMsg.msg ? <Alert type={this.state.errMsg.type} message={<div className='inline-center-item'> {this.state.errMsg.msg} <span className="float-right cursor-pointer" onClick={this.closeAlertMsg}><FontAwesomeIcon icon={faTimes} size="sm" style={{ color: "#9ca99f" }} /></span></div>} /> : ""}
                {deviceDetailsData.systemlogs !== undefined ?
                    <Fragment>
                        <Row className='systemlog-row'>
                            <Col className="text-right">
                                <Button onClick={this.generateDiagnostics} className="tab-download-btn" disabled={!deviceDetailsData.systemlogs.canGenerateDiagnostics}> <FormattedMessage id={"dm.tab.diagnostics.systemlogs.generateDiagnostics"} /> </Button>
                                &nbsp;&nbsp;
                                <Button onClick={this.openRebootConfirmModal} className="tab-download-btn" disabled={!deviceDetailsData.systemlogs.canDeviceReboot}> <FormattedMessage id={"dm.tab.diagnostics.systemlogs.reboot"} /> </Button>
                            </Col>
                        </Row>
                        {deviceDetailsData.systemlogs.generatedLogs.length !== 0 ?
                            <Row>
                                <Col>
                                    <TableHoc 
                                        tableColumn={this.state.columnsData}
                                        tableRowData={deviceDetailsData.systemlogs.generatedLogs}
                                        pageSize={deviceDetailsData.systemlogs.generatedLogs.length}
                                        showPagination={false}
                                        multiSort={false}
                                    />
                                </Col>
                            </Row> : ""}
                        {this.state.isOpenConfirmRebootModal ? <ConfirmRebootModal onCloseConfirmRebootModal={this.onCloseConfirmRebootModal} /> : ""}
                    </Fragment>
                    :
                    <Fragment>
                        <Table bordered size="sm" className="bs-table">
                            <thead className="bs-table-thead">
                                <tr>
                                    <th> <FormattedMessage id={"dm.tab.diagnostics.loggerFiles.logFile"} /> </th>
                                    <th> <FormattedMessage id={"db.common.action"} /> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(deviceDetailsData.loggerFiles).sort().map(([properties, value], key) => {
                                    return <tr key={key}>
                                        <td>{properties}</td>
                                        <td><Button onClick={this.downloadLoggerFilesReport.bind(this, value)} className="tab-download-btn"> <FormattedMessage id={"db.sysAdmin.download"} /> </Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Fragment>
                }
            </div>
        )
    }
}

export default DiagnosticsTab;
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import SingleSelect from '../../../hoc/singleSelect';
import { disturbancesData } from '../../../utility/disturbanceAnalyticsUtility'
import { DisturbanceAnalyticsContext } from '../provider/disturbanceAnalyticsProvider';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';


class ExportDetails extends Component {

    static contextType = DisturbanceAnalyticsContext;

    updateBreadCrumbName = () => {
        let breadCrumb = this.context.state.breadCrumbName.split('-')
        breadCrumb.shift();
        return breadCrumb.join('-')
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col sm={6} className='p-0 m-0'>
                        <p><FormattedMessage id={"disturbances.details.total_count_for"} /><b>&nbsp;{this.context.state.isValidationErr ? "" : this.updateBreadCrumbName()} : {this.context.state.countersData.totalDisturbanceCount && !this.context.state.isValidationErr ? this.context.state.countersData.totalDisturbanceCount : 0}</b></p>
                    </Col>
                    <Col sm={6}>
                        <Row className='center-alighn-text'>
                            {this.context.state.treeNode.type !== 'REGION' && this.context.state.treeNode.type !== 'SITE' && this.context.state.currentTabVal === 'countersCharts' ?
                                <Col xs={10} className='text-right p-0'>
                                    <span className='phase-A active phase-size'></span> <FormattedMessage id={"db.feederBar.phaseA"} /> &nbsp;
                                    <span className='phase-B active phase-size'></span> <FormattedMessage id={"db.feederBar.phaseB"} />&nbsp;
                                    <span className='phase-C active phase-size'></span> <FormattedMessage id={"db.feederBar.phaseC"} /> &nbsp;
                                </Col>
                                : ""}
                            <Col className='text-right p-0'>
                                <span className='single-select-dropdown option-list'>
                                    <SingleSelect
                                        data={disturbancesData.exportData}
                                        setType={this.context.handleExportData}
                                        value={<FontAwesomeIcon icon={faShareSquare} />}
                                        isDisplayLabel={false}
                                        disabled={this.context.state.tableRowData.length === 0} />
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.context.state.isValidationErr ? "" :
                    <Row>
                        <Col className='p-0 m-0'>
                            {!this.context.state.countersData.deviceSupportFlag ? <p><FormattedMessage id={"disturbances.details.aednoncompatibilitymessage"} /></p> : ""}
                            {this.context.state.countersData.hasZM1Devices ? <p><FormattedMessage id={"disturbances.details.zm1noncompatibilitymessage"} /></p> : ""}
                        </Col>
                    </Row>
                }
            </React.Fragment>
        );
    }
}


export default ExportDetails;
import React, { Component } from 'react';
import '../userManagement/index.css';
import UpdateProfileContainer from './containers/updateProfileContainer'
import UpdateprofileProvider from './provider/updateProfileProvider';
import {TreeConsumer} from '../../treeview/provider/treeProvider'

class ConfigureProperties extends Component {
    render() {
        return (
                <TreeConsumer>
                    {(context) => (
                        <>
                        <UpdateprofileProvider contextData={context}>
                            <UpdateProfileContainer />
                        </UpdateprofileProvider>
                        </>
                    )}
                </TreeConsumer>
        );
    }
}

export default ConfigureProperties;
import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../../../../hoc/modelWindow';
import {VirtualDeviceContext} from '../provider/virtualDeviceProvider';
import AddVirtualDevice from './addEditDevice/addVirtualDevice';


const ViewVirtualDeviceModal=()=>{
    const contextData = useContext(VirtualDeviceContext)

    const onBtnClickHandler=(value)=>{
        if (value === 'cancel') contextData.onCloseVirtualDeviceModal();
        else contextData.onCloseVirtualDeviceModal();
    }

    return(
        <Row>
            <ModalWindow
                show={true}
                size="xl"
                dialogClassName={"custom-v-device-dialog modal-dialog-centered"}
                title={<div> <FormattedMessage id="vdm.modal.viewDevice" /> - {contextData.state.clickedRowData.name}</div>}    //need to add tapan
                onBtnClickHandler={(val)=>onBtnClickHandler(val)}
                onHide={() => contextData.onCloseVirtualDeviceModal()}
                modeldata={{
                    content: <AddVirtualDevice />
                }}
                footer={
                    [
                        { className: "modalCancelBtn", name: <FormattedMessage id="db.common.close" />, value: "cancel" }, 
                    ]
                }
            />
        </Row>
    )
}

export default ViewVirtualDeviceModal;
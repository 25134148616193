import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

const SubLabel = (props) => {
    return (
        <Fragment>
            {
                props.type === "Arms" ? <label><FormattedMessage id="common.label.amp" /><sub><FormattedMessage id="common.label.rms" /></sub></label> :
                    props.type === "mArms" ? <label><FormattedMessage id="manage.profiles.curremtLowThresholdUnits" /><sub><FormattedMessage id="common.label.rms" /></sub></label> : ""
            }
        </Fragment>
    )
}

export default SubLabel;
import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const AlertMessage = (props) => {
    useEffect(() => {
        if (props.timer && props.type !== 'danger') setTimeout(() => { props.setVisible(false) }, props.timer)
    }, [])

    return (
        <Alert
            variant={props.type}
            key={props.type}
            className={props.setVisible ? `m-0 ${props.position}` : `m-0 remove-close-icon ${props.position}`}
            onClose={() => props.setVisible(false)}
            dismissible >
            {props.message}
        </Alert>
    )
}

AlertMessage.propTypes = {
    type: PropTypes.string.isRequired, /* 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark' */
    message: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
    ]),
    setVisible: PropTypes.func,
    timer: PropTypes.number,
    position: PropTypes.string // css classnames
}

export default AlertMessage;
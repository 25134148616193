import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../../../../hoc/modelWindow';
import Loader from '../../../../hoc/loader'
import {VirtualDeviceContext} from '../provider/virtualDeviceProvider';


const VirtualDeviceDeleteModal=()=>{
    const contextData = useContext(VirtualDeviceContext)
    const roleName = contextData.roleAndPermission.roleName;

    const onBtnClickHandler=(value)=>{
        if (value === 'cancel') contextData.onCloseModal('cancelDelete');
        else contextData.onCloseModal('confirmDelete');
    }

    return(
        <Row>
                <ModalWindow
                    show={true}
                    size="md"
                    dialogClassName={"modal-dialog-centered delete-dnpc-modal"}
                    title={<div> <FormattedMessage id="vdm.delete.modal.label" /> </div>}
                    onBtnClickHandler={(val)=>onBtnClickHandler(val)}
                    onHide={() => contextData.onCloseModal()}
                    modeldata={{
                        content: <Row className='pt-5 pb-5'>
                                    {contextData.state.isLoadingDeleteLoader && <Loader elementClass="tree-ajax-loader center-element" /> }
                                    <Col>
                                    <FormattedMessage id={"vdm.delete.confirm"} /> {contextData.state.clickedRowData.deviceName} ?
                                    </Col>
                                </Row>
                    }}
                    footer={
                        [
                            { className: "modalCancelBtn", name: <FormattedMessage id="dm.column.cancel" />, value: "cancel" }, 
                            { className: "modalSaveBtn", name: <FormattedMessage id="configure.popup.button.confirm" />, value: "save", disabled : (roleName !== 'ROLE_ADMIN' && roleName !== 'ROLE_ORG_ADMIN') && true  }
                        ]
                    }
                />
            </Row>
    )
}

export default VirtualDeviceDeleteModal;
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ConfigStatusContext } from '../provider/configStatusProvider';
import Loader from '../../../../hoc/loader';
import BlankPage from '../../../../hoc/blankPage';
import Table from '../../../../hoc/table';
import * as currentJobUtility from '../../../../utility/currentJobUtility';

class DeviceParameters extends Component {
    static contextType = ConfigStatusContext;

    render() {
        return (
            <Fragment >
                <div className="top-header"><div className="device-heading"><FormattedMessage id='cj.statustable.title' /></div></div>
                {this.context.state.isParameterLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                <div className="device-table align-table">{this.context.state.deviceParameters.length > 0 ?
                    <Table
                        tableColumn={currentJobUtility.configStatus.parametersColumnData}
                        tableRowData={this.context.state.deviceParameters}
                        showPagination={false}
                        sortable={false}
                        pageSize={this.context.state.deviceParameters.length}
                        lazyLoadConfigData={{initialSize : 50, updatedSize : 50, scrollableAncestor: null}}
                    /> :
                    <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
                </div>
            </Fragment>
        )
    }
}

export default DeviceParameters;
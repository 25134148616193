import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DetailsContext } from '../provider/detailsProvider';
import EventsBreadcrumbs from '../../../../hoc/eventsBreadcrumbs'

class DetailsBreadCrumb extends Component {
    static contextType = DetailsContext;
    render() {
        return (
            <Row>
                <Col>
                    <EventsBreadcrumbs updatedTreeData={this.context.state.updatedTreeData} nodeData={this.context.state.treeNode} handleOnClick={this.context.loadData} />
                </Col>
            </Row>
        )
    }
}

export default DetailsBreadCrumb;
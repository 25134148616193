import React, { Component } from 'react';
import { ProfileContext } from '../provider/profilesProvider';
import CommonTabContent from './commonTabContent';
import Tooltip from '../../../../hoc/tooltip';
import PopOverMessage from '../../../../hoc/popOver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import AlertMessage from '../../../../hoc/alert';
import ToggleSwitch from '../../../../hoc/toggleSwitch';
import TextField from '../../../../hoc/form/textField';
import Range from '../../../../hoc/range';
import UM1TabContent from './um1TabContent';


class Zm1ProfileConfig extends Component {

    static contextType = ProfileContext;
   
    render(){
        return(
            <div>
                {
                this.context.state.currentTab === "zmNetworkData" ?
                    <div>
                      
                            <UM1TabContent 
                            contentArrParent={this.context.state.profile.selectedProfile.zmNetwork} 
                            updateFormattedMessage={this.props.updateFormattedMessage} 
                            rules={this.context.state.profile.selectedProfile.zmNetworkRules}
                            isBooleanValue={this.props.isBooleanValue}
                            handleOnchangeForms = {this.props.handleOnchangeForms}
                            parentObj = 'zmNetwork'
                            disabled = {false}
                            popOver = {true}
                            formError={this.context.state.formError}
                            deviceType={this.props.deviceType}
                        /> 
                </div>
               
                 : null
            }
            {
                this.context.state.currentTab === "zmCfciData" ?
                    <div>
                        {/* added the section to handle the toggle switch for feeder or lateral which will not be present as part of rules */}
                       
                        <div className="form-section d-flex" key={`manage.profiles.zmMode`}>
                            <span className="form-section-content-l-50">
                            <Tooltip placement="top" message={<FormattedMessage id={`manage.profiles.zmMode.tooltip`} />} baseContent={<FormattedMessage id={`manage.profiles.zmMode`} />}/>&nbsp;
                                <PopOverMessage trigger="focus" keyVal={`manage.profiles.zmMode-popover`} placement="right" popoverBody={
                                    <div className="m-2"><FormattedMessage id={`manage.profiles.zmMode-popOverContent`} /></div>
                                } displayPopOver={
                                    <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
                                } />
                            </span>
                                <span className="form-section-content-r-50">
                                    <ToggleSwitch
                                        value={this.context.state.profile.selectedProfile.zmMode === true ? true : false}
                                        toggleSwitchValue={(e) => this.props.handleOnchangeForms("zmCfci", "zmMode", this.context.state.profile.selectedProfile.zmMode === true ? false : true)}
                                        leftValue="Feeder"
                                        rightValue="Lateral"
                                    />
                                </span>
                        </div>
                       
                        {/* dynamic rendering of components whcih have rules */}
                         <CommonTabContent 
                            contentArrParent={this.context.state.profile.selectedProfile.zmCfci} 
                            updateFormattedMessage={this.props.updateFormattedMessage} 
                            rules={this.context.state.profile.selectedProfile.zmCfciRules}
                            isBooleanValue={this.props.isBooleanValue}
                            isTimeoffset = {this.props.isTimeoffset}
                            handleOnchangeForms = {this.props.handleOnchangeForms}
                            parentObj = 'zmCfci'
                            disabled = {false}
                            formError={this.context.state.formError}
                            headerContent = {false}
                            popOver = {true}
                            showZM1CfciFields = {this.props.showZM1CfciFields}
                            deviceType={this.props.deviceType}
                            /> 
                </div>
               
                 : null
                }{
                    this.context.state.currentTab === "zmDisturbanceAedData" ?
                        <div className="form-section">
                            <div><AlertMessage type={'warning'} message={<FormattedMessage id={`manage.profiles.AEDWarning`} />} /></div><br/>
                            {
                               this.context.state.profile.selectedProfile.zmAed !== undefined && Object.keys(this.context.state.profile.selectedProfile.zmAed).length > 0 ? Object.entries(this.context.state.profile.selectedProfile.zmAed).map(([label, value], key) => {
                                    return (
                                        <div key={key} className="pt-1 d-flex">
                                            {!this.props.isTimeoffset(label) && this.props.showAedFields(label) ?
                                                <span className={"form-section-content-r-50"}>
                                                    <Tooltip placement="top" message={label} baseContent={this.props.updateFormattedMessage(label, this.context.state.profile.selectedProfile.zmAedRules[label])} />
                                                    <PopOverMessage trigger="focus" keyVal={`${key}-${label}`} placement="right" popoverBody={
                                                        <div class="m-3"><FormattedMessage id={`manage.profiles.${label}-popOverContent`} /></div>
                                                    } displayPopOver={
                                                        <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
                                                    } />
                                                </span>
                                                : null}
                                            {!this.props.isTimeoffset(label) && this.props.showAedFields(label) ?
                                                <span className={"form-section-content-r-50"}>
                                                    {
                                                        !this.props.isBooleanValue(value) ? 
                                                            <span className="rangeCls">
                                                                <span className="sz-49 float-left"> <Range keyVal={`${label}-${key}`} min={this.context.state.profile.selectedProfile.zmAedRules[label].min} max={this.context.state.profile.selectedProfile.zmAedRules[label].max} value={value ? value : "0"} handleOnchange={(e) => this.props.handleOnchangeForms("zmAed", label, e)} /></span>
                                                                <span className="sz-49 float-right mb-4">
                                                                    <TextField
                                                                        type="text"
                                                                        isDisabled={this.props.disabled}
                                                                        value={value ? value : ""}
                                                                        field={value}
                                                                        handleOnchange={(e) => this.props.handleOnchangeForms("zmAed", label, e)}
                                                                        className={this.context.state.formError[label] !== undefined && this.context.state.formError[label] === true ? "form-err-border-red ml-2p" : ""}
                                                                    />
                                                                </span>
                                                            </span>
                                                        : 
                                                            <ToggleSwitch
                                                                value={value === true ? true : false}
                                                                toggleSwitchValue={(e) => this.props.handleOnchangeForms("zmAed", label, value === true ? false : true)}
                                                                leftValue={<FormattedMessage id={`manage.profiles.toggle.on.${label}`}/>}
                                                                rightValue={<FormattedMessage id={`manage.profiles.toggle.off.${label}`}/>}
                                                            />
                                                    }
                                                </span>
                                                : null}
                                        </div>
                                    )
                                }) : null
                            }
                        </div>
                        : null
                }
            </div>
        )
    }
}

export default Zm1ProfileConfig;
import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ConfigStatusContext } from '../provider/configStatusProvider';
import NoDataAvailableComponent from '../../../../hoc/noDataAvailableComponent';
import Loader from '../../../../hoc/loader';
import Tooltip from '../../../../hoc/tooltip';
import SearchBar from '../components/searchBar';

class JobListContainer extends Component {
    static contextType = ConfigStatusContext;

    constructor(props) {
        super(props);
        this.state = {
            showSearchDropdown: false,
            selectedJobName: "",
            searchedString: "",
            searchResult: []
        }
    }

    onJobclicked = (item) => { this.context.updateSelectedJobName(item) };

    /* filter and show searched jobs to the dropdown */
    searchJobs = (evt) => {
        if (!evt.target.value) {
            this.setState({ searchResult: [], searchedString: "" });
            return false;
        }
        let data = [...this.context.state.jobList];
        let filteredResult = this.jobsSearchFilter(data, evt.target.value);
        this.setState({ searchedString: evt.target.value, searchResult: filteredResult, showSearchDropdown: true });
    }

    /* Compare with serial numbers and display the searched jobs */
    jobsSearchFilter = (jobsList, input) => {
        let filteredJobs = [];
        if (input) {
            jobsList && jobsList.length > 0 && jobsList.forEach((jobItem) => {
                var key = Object.assign({}, jobItem);
                var jobName = jobItem.jobName ? jobItem.jobName.toLowerCase() : '';
                var isDevice = false;
                if (key.serialNumbers) {
                    var serialNumbers = key.serialNumbers;
                    serialNumbers && serialNumbers.length > 0 && serialNumbers.forEach((slno) => {
                        var key = Object.assign({}, jobItem)
                        if ((slno.toLowerCase()).indexOf((input).toLowerCase()) !== -1) {
                            isDevice = true;
                            key['serialNumber'] = JSON.parse(JSON.stringify(slno));
                            filteredJobs.push(key);
                        }
                    })
                }
                if (!isDevice) {
                    if (jobName.indexOf((input.toLowerCase())) !== -1) {
                        key['serialNumber'] = null;
                        filteredJobs.push(key);
                    }
                }

            })
        }
        return filteredJobs;
    };

    /* Highlight the selected job and fetch job details */
    selectSearchedJob = (job) => {
        this.context.state.selectedJobName === job.jobName ? this.context.initialDeviceSelection(job) : this.context.updateSelectedJobName(job);
        this.setState({ searchedString: job.jobName, showSearchDropdown: false });

        var name = this.convertSpecialChar(job.jobName);
        var selectedSection = document.getElementsByClassName(name);
        var t = setInterval(() => {
            if (selectedSection.length > 0) {
                selectedSection[0].scrollIntoView({ behavior: "smooth" });
                clearInterval(t);
            }
        }, 1)
    }

    /* Hide search dropdown list on outside click */
    onBlur = () => {
        this.setState({ showSearchDropdown: false });
    }

    convertSpecialChar = (text) => {
        return text.replace(/[^a-zA-Z0-9]/g, '_');
    }

    render() {
        return (
            <Fragment>
                <SearchBar
                    searchResults={this.state.searchResult}
                    showDropdown={this.state.showSearchDropdown}
                    searchedString={this.state.searchedString}
                    inputChangedHandler={(evt) => this.searchJobs(evt)}
                    selectSearchedJob={(job) => this.selectSearchedJob(job)}
                    onBlur={this.onBlur}
                />
                <div className="title"><FormattedMessage id='currentjobs.joblist.title' /> </div>
                <div className="section-filters grouping-data margin-0">
                    <div className="listview-ol">
                        {this.context.state.jobList.length > 0 ? this.context.state.jobList.map((item, key) => {
                            return <Row key={key} onClick={() => this.onJobclicked(item)}
                                className={`listview-li ${item.jobName === this.context.state.selectedJobName ? 'item-active ' : ' '} ${(key % 2 === 0) ? 'odd-row ' : 'even-row '} ${this.convertSpecialChar(item.jobName)}`}>
                                <Col className="pl-0 pr-0">
                                    <span className="icons"><Tooltip placement='top' message={<FormattedMessage id={`currentjobs.tooltip.jobstatus.${this.context.getJobListStatus(item)}`} />} baseContent={<div className={`job-status-${this.context.getJobListStatus(item)}`}></div>} /></span>
                                    <span className="name">{item.jobName}</span>
                                </Col>
                            </Row>
                        }) :
                            this.context.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : <NoDataAvailableComponent />}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default JobListContainer;
import React from 'react';
import SystemAdminContainer from './containers/systemAdminContainer';
import { Row, Col, Container } from 'react-bootstrap';
import './index.css';
import SystemAdminProvider from './provider/systemAdminProvider';

const SystemAdmin = () => {
    return (
        <Container fluid className='system-admin'>
            <Row>
                <Col className="p-0 m-0">
                    <SystemAdminProvider>
                        <div><SystemAdminContainer /></div>
                    </SystemAdminProvider>
                </Col>
            </Row>
        </Container>
    );
}

export default SystemAdmin;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faGlobe, faCodeBranch, faSlidersH, faArrowsAltH, faRandom } from '@fortawesome/free-solid-svg-icons'
import ReactSVG from 'react-svg'
import Mdark from '../resources/images/m-dark.svg'
import Zdark from '../resources/images/z-dark.svg'
import Udark from '../resources/images/u-dark.svg'
import Vdark from '../resources/images/v-dark.svg'
import { numberOnlyRegex, alphaNumericwithOptDeciRegex, numWithOptDeciRgx } from '../filters/commonFunction';
import { FormattedMessage } from 'react-intl';
import Tooltip from "../hoc/tooltip";

export const getIcon = (clickedNode) => {
    return clickedNode.node.type === "ROOTNODE" ? <FontAwesomeIcon icon={faHome} /> :
        clickedNode.node.type === "REGION" ? <FontAwesomeIcon icon={faGlobe} /> :
            clickedNode.node.type === "SUBSTATION" ? <FontAwesomeIcon icon={faCodeBranch} /> :
                clickedNode.node.type === "FEEDER" ? <FontAwesomeIcon icon={faSlidersH} /> :
                    clickedNode.node.type === "LATERAL" ? <FontAwesomeIcon icon={faRandom} /> :
                        clickedNode.node.type === "SITE" ? <Tooltip placement='top' message={clickedNode.node.siteDeviceType || 'MM3'} baseContent={<ReactSVG className="svg-node" src={["MM3","MM3ai","",null].includes(clickedNode.node.siteDeviceType) ? Mdark : clickedNode.node.siteDeviceType === "ZM1" ? Zdark : clickedNode.node.siteDeviceType === "UM3+" || clickedNode.node.siteDeviceType === "UM1" ? Udark : clickedNode.node.siteDeviceType === "VC10" ? Vdark : ""} />} />:
                            clickedNode.node.type === "LATERAL_SITE" ? <Tooltip placement='top' message={clickedNode.node.siteDeviceType || 'ZM1'} baseContent={<ReactSVG className="svg-node" src={!clickedNode.node.siteDeviceType || clickedNode.node.siteDeviceType === "ZM1" ? Zdark : clickedNode.node.siteDeviceType === "UM3+" || clickedNode.node.siteDeviceType === "UM1" ? Udark : clickedNode.node.siteDeviceType === "VC10" ? Vdark : ""} />} /> :
                                clickedNode.node.type === "CUSTOM_GROUPS" ? <FontAwesomeIcon icon={faArrowsAltH} /> :
                                    clickedNode.node.type === "UNASSIGNED_DEVICES" ? <FontAwesomeIcon icon={faArrowsAltH} /> : ""
}

export const getClassName = (clickedNode) => {
    let className = "";
    if (clickedNode && (clickedNode.routeParams || (clickedNode.node && clickedNode.node.routeParams))) {
        const node = clickedNode.node && clickedNode.node.routeParams ? clickedNode.node.routeParams : clickedNode.routeParams;
        for (let i = 1; i <= node.length; i += 2) {
            className += node[i].name.replace(/ /g, ' ') + " - ";
        }
    }
    else {
        className = clickedNode.node ? clickedNode.node.name.replace(/ /g, ' ') + " - " : clickedNode.name.replace(/ /g, ' - ') + " - ";
    }
    return className.slice(0, className.length - 2);
}

export const columnData = [
    {displayName: 'Site Name', type: 'none', className: 'site-name', fieldName: 'siteName', regExp: null, errorMessage: ''},
    {displayName: 'Current Direction', type: 'icon', className: 'current-direction', fieldName: 'currentDirection', regExp: null, errorMessage: ''},
    {displayName: 'Section Id', type: 'text', className: 'section-id', fieldName: 'sectionId', regExp: numberOnlyRegex, errorMessage: <FormattedMessage id={`common.error.numberOnly`} />},
    {displayName: 'Cable Profile', type: 'dropdown', className: 'cable-profile', fieldName: 'cableProfile', regExp: null, errorMessage: <FormattedMessage id={`common.error.cableProfileEmpty`} />},
    {displayName: 'Cable Length to next site (ft)', type: 'text', className: 'cable-length', fieldName: 'cableLengthToNextSite', regExp: numberOnlyRegex, errorMessage: <FormattedMessage id={`common.error.numberOnly`} />},
    {displayName: `Resistance (${'\u2126'}/mi)`, type: 'text', className: 'resistance-impedance', fieldName: 'resistanceImpedance', regExp: numWithOptDeciRgx, errorMessage: <FormattedMessage id={`common.error.decimalOnly`} />},
    {displayName: `Reactance (${'\u2126'}/mi)`, type: 'text', className: 'reactance', fieldName: 'reactance', regExp: alphaNumericwithOptDeciRegex, errorMessage: <FormattedMessage id={`common.error.alfaNumeric`} />},
    {displayName: 'Number of downstream customers', type: 'text', className: 'no-of-customers', fieldName: 'noOfDownstreamCustomers', regExp: numberOnlyRegex, errorMessage: <FormattedMessage id={`common.error.numberOnly`} />}
]

export const editableFields = ['sectionId', 'cableProfile', 'cableLengthToNextSite', 'resistanceImpedance', 'reactance', 'noOfDownstreamCustomers']

export const reloadTreeview= (param)=>{
    let obj = {}
    if (param.highlightedNode) {    
        param.highlightedNode.node.routeParams && param.highlightedNode.node.routeParams.forEach(
            e => {
                if (typeof (e) === "object" && e.type) {
                    let element = e.type.includes("_") ? e.type.split("_")[0].toLowerCase() + e.type.split("_")[1].charAt(0) + e.type.split("_")[1].slice(1).toLowerCase() + "Name" : e.type.toLowerCase() + "Name";
                    obj[element] = e.name;
                }
            }
        );
    } else {
        obj = param.tree.length > 0 && param.tree[0]
    }
    return obj;
}

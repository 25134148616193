import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import * as actionBuilder from "../actions";
import * as homeActionBuilder from "../../home/actions/homeActionsBuilder";
import FormComponent from '../components/loginFormComponent';
import { getURL } from '../../providers/configProvider';
import Footer from '../../footer';
import { FormattedMessage } from 'react-intl';
import CustomImgIcon from '../../hoc/customImgIcon';
import TextField from '../../hoc/form/textField';
import Button  from "../../hoc/button";
import AlertMessage from '../../hoc/alert';
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from "../../hoc/tooltip";
import { getCookie, emailRegex } from '../../filters/commonFunction';
import { setLoader } from "../actions/loginActionsBuilder";

class LoginView extends Component {

  state = {
    password: '',
    samlLogout: false,
    samlFailed: false,
    email: "",
    emailError:false,
    accountIdError:false,
    errorMessage: "",
    accountId: "",
  };

  componentDidMount(){
    this.setState({
      email: getCookie('EMAIL') || "",
      accountId: getCookie('ACCOUNTID') || "",
      errorMessage:this.props.formData.errorMessage ? this.props.formData.errorMessage : (location.pathname.includes('failed') && !(location.pathname.includes('samlfailed')))  ? <FormattedMessage id={`login.failed`} /> : location.pathname.includes('disabled') ? <FormattedMessage id={`login.disabled`} /> : location.pathname.includes('invalidlicense') ? <FormattedMessage id={`login.invalidlicense`} /> : "",
      samlFailed: location.pathname.includes('samlfailed')
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props.formData.errorMessage !== prevProps.formData.errorMessage) {
      this.setState({errorMessage:this.props.formData.errorMessage})
    }
  }
  onChangeEmail = (item) =>{
    document.cookie = "EMAIL =" + item+"; path=/";

    const { emailError } = this.state;
    //    //Validation of the email field occurs when the 'Next' button is clicked for the first time. If an error was previously detected in the 'email' field, it will be validated again whenever this field is modified.
    const validEmail = emailError ? this.emailvalidationCheck(item) : true;

    this.setState({
      email: item,
      emailError: emailError && !validEmail
    });
  }

  onChangeAccountId = (item) => {
    document.cookie = "ACCOUNTID =" + item+"; path=/";

    const { accountIdError } = this.state;
    //Validation of the accountID field occurs when the 'Next' button is clicked for the first time. If an error was previously detected in the accountID field, it will be validated again whenever this field is modified.
    const validAccountId = accountIdError ? Boolean(Number(item)) : true;

    this.setState({
      accountId: item,
      accountIdError: accountIdError && !validAccountId
    });
  }

  emailvalidationCheck = (item) =>{
    return emailRegex.test(item || this.state.email);
  }
  onClickNext = () =>{
    const validEmail = this.emailvalidationCheck();
    const validAccountId = this.state.accountId && Number(this.state.accountId)
    this.setState({...this.state, emailError : !validEmail, accountIdError: !validAccountId})
    if(validEmail && validAccountId) {
      this.props.onCheckSSO(this.state.accountId, this.state.email);
    }
  }

  closeAlertMessage = () => {
    this.setState({ emailError: false, accountIdError: false, errorMessage: "" });
    this.props.updateFormLogin(this.props.formData.formLogin, "", false);
  }

  render() {
    return (
      <React.Fragment>
        <div className="login-view">
          <div className="login-content">
            <div className="login-wrap">
            <div className="text-right logo"><CustomImgIcon icon="login_logo.png" width='12rem' height='8.5rem' /></div>
            <h3 className="login-title"><FormattedMessage id='login.gridanalytics' /></h3>
              {!this.props.formData.formLogin && !this.props.samlLogout && !this.state.samlFailed && !window.location.pathname.includes('samlLogout') ? 
                (<React.Fragment>
                  <Form className="margin-top-4vh" onSubmit={(e)=>e.preventDefault()}>
                  {(this.state.emailError || this.state.accountIdError || this.state.errorMessage) && <AlertMessage type={'danger'} timer={10000} position={'w-100'} message={this.state.errorMessage ? this.state.errorMessage : (this.state.accountIdError && this.state.emailError) ? <FormattedMessage id={`login.forgotpassword.textfield.invalid`} /> : this.state.accountIdError ? <FormattedMessage id={`login.forgotpassword.accountId.invalid`} /> : this.state.emailError ? <FormattedMessage id={`login.forgotpassword.email.invalid`} /> : ""} setVisible={this.closeAlertMessage}/>}
                    <div className="input-with-icon">
                      <TextField
                        autocomplete="accountid"
                        type="text"
                        isDisabled={false}
                        value={this.state.accountId}
                        placeholder={"Account Id"}
                        autoFocus={true}
                        handleOnchange={(e) => { this.onChangeAccountId(e) }}
                        className={this.state.accountIdError ? "form-err-border-red account-textbox" : "account-textbox"}
                      />
                      <span className="account-help">
                        <Tooltip
                          placement='top'
                          message={<FormattedMessage id={`login.form.help`} />}
                          baseContent={<FontAwesomeIcon icon={faQuestionCircle} size="sm" />} />
                      </span>
                    </div>
                    <br />
                  <TextField
                    autocomplete="email"
                    type="text"
                    isDisabled={false}
                    value={this.state.email}
                    placeholder={"Email"}
                    handleOnchange={(e) => { this.onChangeEmail(e) }}
                    className={this.state.emailError !== undefined && this.state.emailError ? "form-err-border-red" : ""}
                  />
                  <Button btnText = "Next" className="btn btn-warning btn-block mt-3" handleButtonClick = {()=>{this.onClickNext()}}/>
                  </Form>
                </React.Fragment>)
               
               :
                <FormComponent
                  loginURL={getURL('login', 'checkSession', [], true)}
                  username={getCookie('EMAIL')}
                  password={this.state.password}
                  accountId={getCookie('ACCOUNTID')}
                  changePassword={(evt) => this.setState({ password: evt.target.value })}
                  clicked={(evt) => evt.preventDefault & this.props.onLogIn(this.state)}
                  samlFailed= {this.state.samlFailed}
                />
              }
            </div>
          </div>
        </div>
        <div className="login-footer">
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.loginData.loggedIn,
    formData: state.loginData,
    samlLogout: state.headerData.samlLogout,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogIn: (state) => dispatch(actionBuilder.logIn(state)),
    onCheckSSO: (accountid, email) => dispatch(homeActionBuilder.ssoLogin(accountid,email)),
    updateFormLogin: (bool, errorMsg, hideForgotPassword) => dispatch(homeActionBuilder.updateLogin(bool, errorMsg, hideForgotPassword)),
    setLoader: (params) => dispatch(setLoader(params))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginView));
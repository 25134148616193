import { getURL } from '../../../../providers/configProvider';
import { requestGET, requestPOST } from '../../../../services/extendedService';


export const getFaultsData = (params) => {
    const url = getURL('detailsevents', 'data', params );
    return requestGET(url);
  };

export const getFaultsDetails = (params) => {
    const url = getURL('detailsevents', 'details', params );
    return requestGET(url);
};

export const exportData = (params) => {
  const url = getURL('detailsevents', 'exportData', params );
  const headers = {'Content-Type': 'blob'};
  const config= {method: 'GET', url: url, responseType: 'arraybuffer', headers};
  return requestGET(url,config);
};

export const getWaveformData = (params, reqBody) => {
  const url = getURL('detailsevents', 'getwaveform', params );
  return requestPOST(url, reqBody);
};

export const downloadSegmentData = (params) => {
  const url = getURL('detailsevents', 'downloadsegmentdata', params );
  return requestGET(url);
};

export const getWaveformDownload = (params) => {
  const url = getURL('detailsevents', 'getwaveformdownload', params );
  return requestGET(url);
};

export const getMapData = (params) => {
  const url = getURL('detailsevents', 'mapdata', params );
  return requestGET(url);
};

export const getDistancetData = (params) => {
  const url = getURL('detailsevents', 'estimatedfaultlocation', params );
  return requestGET(url);
};

export const reCalculateFaultLocation = (params) => {
  const url = getURL('detailsevents', 'reCalculate', params );
  return requestGET(url);
};
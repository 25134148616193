import React, { Component } from 'react'
import 'react-table-6/react-table.css';
import EProfileTable from './eProfileTable';
import CableProfileTable from './cableProfileTable';
import EProfileTableTop from '../components/equipmentProfileTableTop';
import AddOrEditEProfilesModal from '../components/addOrEditProfilesModal';
import DeleteEProfilesModal from '../components/DeleteEProfilesModal';
import { EProfileContext } from '../provider/eProfileProvider'

class EquipmentProfileContainer extends Component {
    static contextType = EProfileContext;
    render() {
        return (
            <>
                <EProfileTableTop />
                <EProfileTable />
                <br />
                <CableProfileTable />
                <AddOrEditEProfilesModal />
                <DeleteEProfilesModal />
            </>
        );
    }
}

export default EquipmentProfileContainer;

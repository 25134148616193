import React, { Component, Fragment } from 'react';
import { Form, FormGroup, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import SingleSelect from '../../../hoc/singleSelect';

import TimeFilter from '../../../hoc/timeFilter';
import MultiSelectDropdown from '../../manage/details/components/multiselectDropdownComponent';
import MonitorPicker from './monitorPicker';
import { MonitorContext } from '../provider/monitorProvider';

class MonitorFilter extends Component {

    static contextType = MonitorContext;

    render() {
        let { zoomRange, selectedZoomRange, startDate, endDate, filtersSection, sitesList, exportOptions, exportLogi, disableExport, roleAndPermission,maxDate } = this.context.state;
        filtersSection = (["SITE","LATERAL_SITE"].includes(this.context.state.treeNode.type) && this.context.state.treeNode.siteDeviceType === "UM1") ? 
        filtersSection.filter(e => e.fName !== "phase") :  (["SITE","LATERAL_SITE"].includes(this.context.state.treeNode.type) && this.context.state.treeNode.siteDeviceType === "VC10") ? filtersSection.filter(e => !["sites","logIPoints","phase"].includes(e.fName)) :filtersSection.filter(e => !["secondaryLogIPoints","secondaryPhase"].includes(e.fName));
        return (
            <Fragment>
                <Form>
                    <FormGroup key="Rangg-filter" className="clearfix m-0 p-2 border-1px" xs={12} md={12}>
                        <span className="float-left col-form-label p-0">
                            <span className="mt-1">&nbsp;&nbsp;<FormattedMessage id={`common.label.zoom`} /> &nbsp;</span>
                            <TimeFilter data={ zoomRange } handleOnClick={ this.context.zoomOnChange } selected={ selectedZoomRange } />
                        </span>
                        <span className="float-left col-form-label p-0 ml-2">
                            <MonitorPicker 
                                timezone = {roleAndPermission.timezone} 
                                endDate = {endDate}
                                startDate = {startDate}
                                onCallback={(event, picker) => this.context.onCalenderCallback(event, picker)}
                                maxDate={maxDate}
                            />
                        </span>
                    </FormGroup>
                    <FormGroup key="selection" className={"clearfix m-0 p-2"} xs={12} md={12}>
                        {filtersSection.map((item, key) => {
                            return <span key={key} className={ (item.fName === 'sites' && (sitesList.length === 0)) ? 'd-none' : 'float-left col-form-label p-0 d-inline-flex'}>
                                <span className="m-auto">&nbsp;&nbsp; {["SITE","LATERAL_SITE"].includes(this.context.state.treeNode.type) && this.context.state.treeNode.siteDeviceType === "UM1" && item.fName === 'logIPoints' ? 
                                <FormattedMessage id={`monitor.label.measurements`} /> : ["SITE","LATERAL_SITE"].includes(this.context.state.treeNode.type) && ["UM1","VC10"].includes(this.context.state.treeNode.siteDeviceType) && item.fName === 'secondaryPhase' ? null :
                                item.label} &nbsp;</span>
                                { (item.type === 'multiSelect') ?
                                    <span className="ml-1" key={item.type}>
                                        <MultiSelectDropdown
                                            selectedArrayList={this.context.state[item.fName]}
                                            optionList={this.context.state[item.list]}
                                            onChange={(e, name)=>this.context.setValChange(e, name)}
                                            name={[item.fName]}
                                            multiple={true}
                                            includeSelectAll={false}
                                            includeFilter={false}
                                            open={false}
                                            valueKey="value"
                                            labelKey="text"
                                            id={[item.fName]}
                                            keepOpen={true}
                                            placeholder="Select"
                                            manySelectedPlaceholder={`%s/${this.context.state[item.list].length} Checked, ...`}
                                            allSelectedPlaceholder={`%s/${this.context.state[item.fName].length} Checked, ...`}
                                            handleShowAllBtn={()=>this.context.setValChange([],item.fName)}
                                            btmLabel={"Show All"}
                                        />
                                    </span> : null
                                }
                                {
                                    (item.type === 'btnGroupCheckBox') ?
                                    <ToggleButtonGroup key={item.type} 
                                        type="checkbox" 
                                        defaultValue={this.context.state[item.fName]} 
                                        onChange={(event) => this.context.setValChange(event, item.fName)}
                                        className="mt-1"
                                        >
                                        {this.context.state[item.list].map((checkBox, idx) => (
                                            <ToggleButton
                                                key={checkBox.keyIndex}
                                                variant="light"
                                                name={checkBox.name}
                                                className={'mr-1 btn-default phase-'+ checkBox.name}
                                                value={checkBox.value}
                                            >
                                                {checkBox.name}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                    : null
                                }
                            </span>
                        })}
                        <span key='export' className='float-right col-form-label p-0 d-inline-flex'>
                            <span className='single-select-dropdown option-list'>
                                <SingleSelect 
                                    data={exportOptions} 
                                    setType={this.context.onExportClick} 
                                    value={ <FontAwesomeIcon icon={faShareSquare} /> } 
                                    isDisplayLabel={false} 
                                    disabled={!exportLogi || disableExport || (["SITE", "LATERAL_SITE"].includes(this.context.state.treeNode.type) && ["UM1","VC10"].includes(this.context.state.treeNode.siteDeviceType))} />
                            </span>
                        </span>
                    </FormGroup>
                </Form>
            </Fragment>
        )
        
    }
}

export default MonitorFilter;
import React, { Component, Fragment } from 'react';
import { Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ToggleSwitch from '../../hoc/toggleSwitch';
import { getNodeRouteParams } from '../containers/treeMethods';
import { nodeOperations, nodeSearch } from '../services/treeService';
import { TreeContext } from '../provider/treeProvider';
import ModalWindow from '../../hoc/modelWindow';
import Loader from '../../hoc/loader';

class AddNodeComponent extends Component {
    static contextType = TreeContext;
    state = {
        successMessage: "",
        errorMessage: "",
        disabled: true,
        enteredText: "",
        siteData: {},
        messageDisplay: false,
        toggleSwitchStatus: false,
        isLoading: false,
    }

    //On closing the error message
    hideErrorMsg = () => {
        this.setState({ errorMessage: "" });
    }

    onChangeHandler = (e) => {
        if (e.target.value.length > 0) {
            this.setState({ disabled: false, enteredText: e.target.value, messageDisplay: false });
        } else {
            this.setState({ disabled: true, enteredText: "", messageDisplay: true });
        }
    }

    onSiteDataChangeHandler = (e) => {
        this.setState({
            siteData: {
                ...this.state.siteData,
                [e.target.title]: e.target.value
            }
        });
    }

    toggleSwitch = () => {
        this.setState({
            siteData: {
                ...this.state.siteData,
                "overrideGps": !this.state.toggleSwitchStatus
            },
            toggleSwitchStatus: !this.state.toggleSwitchStatus
        })
    }

    addNode = async () => {
        let nodeDetails = {};
        let infoObj = null;
        //eslint-disable-next-line
        const latLongRegEx = /^\-?\d+(\.\d+)?$/;
        
        if (this.props.action.split("add")[1] !== "Site") {
            nodeDetails.type = this.props.action.split("add")[1];
            nodeDetails.name = this.state.enteredText;
        } else {
            nodeDetails = this.state.siteData;
            nodeDetails.name = this.state.enteredText;
            nodeDetails.type = this.props.action.split("add")[1];
            nodeDetails.address = nodeDetails.address ? nodeDetails.address : "";
            nodeDetails.poleLocation = nodeDetails.poleLocation ? nodeDetails.poleLocation : "";
            nodeDetails.overrideGps = nodeDetails.overrideGps ? nodeDetails.overrideGps : false;
            nodeDetails.lat = nodeDetails.lat ? nodeDetails.lat : "";
            nodeDetails.lon = nodeDetails.lon ? nodeDetails.lon : "";
            if (nodeDetails.overrideGps === false) {
                nodeDetails.lat = "";
                nodeDetails.lon = "";
            }
        }

        if (nodeDetails.overrideGps && (nodeDetails.lat === "" || nodeDetails.lon === "")) {
            infoObj = <FormattedMessage id='groupmanagement.context.message.latlong.required' />
        } else if (nodeDetails.overrideGps && (((!latLongRegEx.test(nodeDetails.lat)) || (!latLongRegEx.test(nodeDetails.lon))))) {
            infoObj = <FormattedMessage id='groupmanagement.context.message.latlong.invalid' />
        } else {
            this.setState({ isLoading: true}, async () => {
                const routeParams = getNodeRouteParams(this.props.clickedNode);
                const apiName = (nodeDetails.type === 'Lateral') ? 'addLateral' : 'addNode';
                const res = await nodeOperations(routeParams, apiName, nodeDetails);
                if (res) {
                    if (res.status !== "FAIL") {
                        this.setState({ isLoading: false}); 
                        this.props.closeModal();
                        const reDirect = this.context.state.highlightedNode && this.context.state.highlightedNode.node && (this.props.clickedNode.node.type === this.context.state.highlightedNode.node.type) && (this.props.clickedNode.node.name === this.context.state.highlightedNode.node.name) ? "addNode" : "";
                        this.context.loadNextLevel(this.props.clickedNode, this.props.clickedNode.node.routeParams, null, reDirect);
                    } else {
                        this.setState({ errorMessage: res.message, isLoading: false });
                    }
                } else {
                    this.setState({ isLoading: false}); 
                }
            });
        }
        return infoObj !== null ? this.setState({ errorMessage: infoObj }) : "";
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
        return btnName === "add" ? this.addNode() : null;
    }

    render() {
        const modalTitle = <div className="popup-title"><FormattedMessage id='groupmanagement.context.add' /> {this.props.action.split("add")[1]}</div>;
        const modalBody = <div className="min-vh-25">
            {!this.state.successMessage ?
                <Form onSubmit={(e)=>e.preventDefault()}>
                    {this.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                    <div className={this.state.errorMessage ? "alert alert-danger" : "alert alert-danger dspNone"} role="alert">
                        {this.state.errorMessage ? <div>{this.state.errorMessage}<FontAwesomeIcon className="msgClsIcon" icon={faTimes} onClick={this.hideErrorMsg} /></div> : ""}
                    </div>
                    <div>
                        <Row className="m-0 p-0">
                            <label className="col-4 m-0 p-0">{this.props.action.split("add")[1]} <FormattedMessage id='groupmanagement.context.name' /></label>
                            <input className="rounded col-8 p-2 border-ccc" title="name" onChange={this.onChangeHandler} type="text" placeholder={`Please specify the ${this.props.action.split("add")[1]} name`} />
                        </Row>
                        <span className={"text-right txt-color mt-1 " + (this.state.messageDisplay ? "d-block" : "d-none")}><FormattedMessage id='groupmanagement.addNode.error.label' /></span>
                        {this.props.action.split("add")[1] === "Site" ?
                            <div className="mt-3">
                                <Row className="m-0 p-0 mb-3">
                                    <label className="col-4 m-0 p-0"><FormattedMessage id='groupmanagement.context.siteLocation' /></label>
                                    <input className="rounded col-8 p-2 border-ccc" title="poleLocation" onChange={this.onSiteDataChangeHandler} type="text" placeholder="Pole Location" />
                                </Row>
                                <Row className="m-0 p-0 mb-3">
                                    <label className="col-4 m-0 p-0"><FormattedMessage id='groupmanagement.context.streetAddress' /></label>
                                    <input className="rounded col-8 p-2 border-ccc" title="address" onChange={this.onSiteDataChangeHandler} type="text" placeholder="Street Address" />
                                </Row>
                                <Row className="m-0 p-0 mb-3">
                                    <label className="col-4 m-0 p-0"><FormattedMessage id='groupmanagement.context.overrideGps' /></label>
                                    <span className="col-4 m-0 p-0"><ToggleSwitch toggleSwitchValue={this.toggleSwitch} value={this.state.toggleSwitchStatus} leftValue="On" rightValue="Off" /></span>
                                </Row>
                                <Row className="m-0 p-0 mb-3">
                                    <label className="col-4 m-0 p-0"><FormattedMessage id='groupmanagement.context.latitude' /></label>
                                    <input className={"rounded col-8 p-2 border-ccc " + (!this.state.toggleSwitchStatus ? "disabled-field" : "")} disabled={!this.state.toggleSwitchStatus} title="lat" onChange={this.onSiteDataChangeHandler} type="text" placeholder="Latitude" />
                                </Row>
                                <Row className="m-0 p-0 mb-3">
                                    <label className="col-4 m-0 p-0"><FormattedMessage id='groupmanagement.context.longitude' /></label>
                                    <input className={"rounded col-8 p-2 border-ccc " + (!this.state.toggleSwitchStatus ? "disabled-field" : "")} disabled={!this.state.toggleSwitchStatus} title="lon" onChange={this.onSiteDataChangeHandler} type="text" placeholder="Longitude" />
                                </Row>
                            </div> : ""}
                    </div>
                </Form> : null
            }
        </div>;
        const modalFooter = [{ className: "modalSaveBtn", name: <FormattedMessage id='groupmanagement.context.add' />, value: "add", disabled: this.state.disabled }];
        return (
            <Fragment>
                {this.props.isModalOpen ?
                    <ModalWindow
                        show={this.props.isModalOpen}
                        onHide={() => this.props.closeModal()}
                        size={"md"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        )
    }
}
export default AddNodeComponent;
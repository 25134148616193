import React, { Component } from 'react';
import { Form, FormGroup, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { formFields } from '../../../../utility/addDeviceUtility';
import SingleSelect from '../../../../hoc/singleSelect';
import MM3DeviceComponent from './mm3DeviceComponent';
import ZM1DeviceComponent from './zm1DeviceComponent';
import UM3DeviceComponent from './um3DeviceComponent';
import UM1DeviceComponent from './um1DeviceComponent';
import { getNodes } from '../../../../treeview/containers/treeMethods';
import { compareValues, sortFirmwareversions } from '../../../../services/utilityService';
import { DeviceContext } from '../provider/deviceProvider';
import { loadDeviceStateList, loadNetworkProviderList, loadSoftwareVersionList, addDevice, gethardwareRevisionData } from '../services/deviceService';
import { getProfileData } from '../../../equipmentProfile/services/eProfileService';
import ModalWindow from '../../../../hoc/modelWindow';
import Loader from '../../../../hoc/loader';
import ampleStore from '../../../../store';
import { showAlertToaster } from '../../../../login/actions/loginActionDispatch';
import AlertMessage from '../../../../hoc/alert';

class AddDeviceComponent extends Component {
    static contextType = DeviceContext;
    state = {
        errorMessage: "",
        allFieldsData: {},
        disabledPositions: [],
        isDeviceTypeDisabled: false,
        removedPosData: [],
        disableAddButton: false,
        setSecured: false,
        backupSiteOptions:{},
        hardwareRevision: null, //for New UM3+ device storing onchange value for hardware revision field
        hardwareRevisionList: [], //for New UM3+ device storing all the hadardware list, which ia coming from API after select Device type as UM3+
    }

    //To set the selected device type 
    setDeviceType = async (evt) => {
        if(evt !== this.state.allFieldsData.deviceType){
            this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "deviceType": evt !== "--select--" ? evt : "", serialNumber:'', description:'', fieldNotes:'', sensorIPAddress:'', externalID1:'', externalID2:'', externalID3:'' }) , hardwareRevision: null} ,async (evt)=>{
            
                if(this.state.allFieldsData.deviceType === 'UM3+') { // on select of Device type as UM3+, calling a Get request API to get all the hardware revision data for old and new UM3+ device
                    this.getAllHardwareRevisionList(this.state.allFieldsData.deviceType)
                }
    
                if (Object.keys(this.state.allFieldsData).length > 0 && this.state.allFieldsData.deviceType === evt) {
                    return null;
                }
                await this.resetDropdownDefault(this.state.allFieldsData.deviceType);
                if (evt !== "--select--" && this.state.allFieldsData.deviceType !== 'UM3+') {  // if user select UM3+ then no need to call 'loadNetworkProviderList', for other device need to call
                    await this.loadNetworkProviderList(this.state.allFieldsData.deviceType, this.state.hardwareRevision);
                }
            });
        }
    }

    getAllHardwareRevisionList= async (deviceType)=>{
        const list = [{ label: "--select--", value: "--select--" }];
        const hardwareData = await gethardwareRevisionData(deviceType);
        if (hardwareData.length > 0) {
            hardwareData.forEach(item => {
                list.push({ label: item, value: item })
            })
        }
        this.setState({ hardwareRevisionList : list })
    }

    setHardwareVersion= (val)=>{
        if(val !== this.state.hardwareRevision){
            this.setState({ hardwareRevision: val, allFieldsData: Object.assign({}, this.state.allFieldsData, { networkProvider: '', positions: this.state.allFieldsData.positions.slice(0,1), serialNumber:'', description:'', fieldNotes:'', sensorIPAddress:'', externalID1:'', externalID2:'', externalID3:''}), setSecured: false }, async ()=> {// Storing hardwarerevision value and then calling loadNetworkProviderList API to get the list hardware list
                await this.resetDropdownDefault(this.state.allFieldsData.deviceType)
                this.state.hardwareRevision && this.state.hardwareRevision !== '--select--' && await this.loadNetworkProviderList(this.state.allFieldsData.deviceType, this.state.hardwareRevision);
            })
        }
    }

    //Load the network provider list
    loadNetworkProviderList = async (deviceType, hdrv) => {
        const list = [{ label: "--select--", value: "--select--" }];
        const networkList = await loadNetworkProviderList(deviceType, hdrv);
        if (networkList.length > 0) {
            networkList.sort()
            networkList.forEach(item => {
                list.push({ label: item, value: item })
            })
            formFields.filter(x => x.key === "networkProvider" ? x.options = list : "")
        }
        this.forceUpdate();
    }

    loadTransformerList = async () => {
        const list = [{ label: "--select--", value: "--select--" }];
        const transformerList = await getProfileData("getTransformerProfileData", this.props.orgId)
        if (transformerList.length > 0) {
            transformerList.forEach(item => { item.transformerProfileName && list.push({ label: item.transformerProfileName, value: item.id }) })
            list.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
            formFields.filter(x => x.key === "transformer" ? x.options = list : "")
        }
    }

    //Method to manage data on load 
    resetDropdownDefault = async (deviceType) => {
        this.resetDeviceDropdown(deviceType);
        const routeParams = this.context.state.treeNode.routeParams ? this.context.state.treeNode.routeParams : [this.context.state.treeNode.type, this.context.state.treeNode];
        if (this.props.nodeType !== "SITE" && this.props.nodeType !== "LATERAL_SITE") {
            await this.apiRequestNodeDropdown(routeParams, deviceType);
        }
        this.loadDefaultNodeValues(routeParams, deviceType);
        this.loadDeviceStateList();
        (deviceType === 'UM1' || deviceType === 'VC10') && this.loadTransformerList();

    }

    //Reset the dropdown fields on Load
    resetDeviceDropdown = (deviceType) => {
        formFields.forEach(field => {
            if (field.isDisabled) {
                field.isDisabled = false;
                field.options = [];
            } else {
                field.options = [];
            }
        });
        this.setState({ allFieldsData: { deviceType: deviceType } });
    }

    //Fetch the dropdown data on selection of the each dropdown
    apiRequestNodeDropdown = async (routeParams, type, position) => {
        let dropdownData = await getNodes(routeParams);
        dropdownData = dropdownData && dropdownData.length ? dropdownData : [];
        this.resetNodeDropdown(routeParams[routeParams.length - 1]);
    
        //filtering all the 'mm3', 'mm3ai' and 'null' site type data which will display in site level dropdown field.
        if(["MM3","MM3ai"].includes(this.state.allFieldsData.deviceType)){
            //typeval is set to true if the dropdown data consists type
            let typeVal= dropdownData.some(x=>x.type==="SITE");
            //removing latreal type from the dropdown data and adding only mm3,mm3ai sites to the dropdown data
            if(typeVal) dropdownData = dropdownData.filter((s)=> ["MM3","MM3ai", null].includes(s.siteDeviceType)&&s.type!=="LATERAL")
        }

        if (dropdownData.length > 0) {
            dropdownData = dropdownData.sort(compareValues("name"));
            const deviceType = this.state.allFieldsData.deviceType ? this.state.allFieldsData.deviceType : type;
            dropdownData.forEach(item => {
                //for mm3 and mm3ai site, need to display both(MM3, MM3ai, null) site data in site lebel dropdown field
                if(!["MM3","MM3ai"].includes(this.state.allFieldsData.deviceType)){
                    formFields.filter(field => (field.key === item.type.toLowerCase() || (field.key === "site" && item.type === "LATERAL_SITE")) ? (item.type === "SITE" || item.type === "LATERAL_SITE") && deviceType !== item.siteDeviceType && item.siteDeviceType !== null ? "" : (field.options.length > 0 ? field.options.push({ label: item.name, value: item.name }) : field.options.push({ label: "--select--", value: "--select--" }, { label: item.name, value: item.name })) : "");
                }else{
                    formFields.filter(field => (field.key === item.type.toLowerCase()) ?  
                    (field.options.length > 0 ?
                         field.options.push({ label: item.name, value: item.name }) : 
                         field.options.push({ label: "--select--", value: "--select--" }, { label: item.name, value: item.name })) : 
                         "");
                }
            });
            if (deviceType === "UM3+") {
                this.um3PositionOptionsList(routeParams, position, dropdownData,deviceType);
            }
        }
    }

    um3PositionOptionsList = async (routeParams, position, dropdownData,deviceType) => {
        let tempOptions = [{ label: "--select--", value: "--select--" }];
        dropdownData?.forEach(item => {
            tempOptions.push({ label: item.name, value: item.name, type: item.type, siteDeviceType: item.siteDeviceType })
        })
        let tempAllFieldsData = { ...this.state.allFieldsData }
        let backupsite = { ...this.state.backupSiteOptions };
        let children = [];
        switch (routeParams[routeParams.length - 2]) {
            case 'SUBSTATION':
                tempAllFieldsData?.positions?.forEach(e => {
                    e.feederOptions = tempOptions;
                    e.lateralOptions = [{ label: "--select--", value: "--select--" }];
                    e.siteOptions = [{ label: "--select--", value: "--select--" }];
                });
                break;
            case 'FEEDER':
                if (tempAllFieldsData.positions) {
                    let tempfeeder = [{ label: "--select--", value: "--select--" }];
                    children = await getNodes(routeParams.slice(0, routeParams.length - 2));
                    children?.forEach(e => tempfeeder.push({ "label": e.name, "value": e.name }));
                    tempAllFieldsData.positions[position].feederOptions = tempAllFieldsData.positions[position]?.feederOptions ? tempAllFieldsData.positions[position]?.feederOptions : tempfeeder;
                    tempAllFieldsData.positions[position].lateralOptions = [{ label: "--select--", value: "--select--" }, ...tempOptions.filter(e => e.type === "LATERAL")]
                    tempAllFieldsData.positions[position].siteOptions = [{ label: "--select--", value: "--select--" }, ...tempOptions.filter(e => (e.type === "SITE" || e.type === "LATERAL_SITE") && (deviceType === e.siteDeviceType || e.siteDeviceType === null))]
                    backupsite["position" + position] = tempAllFieldsData.positions[position].siteOptions;
                }
                break;
            case 'LATERAL':
                if (tempAllFieldsData.positions) {
                    let tempfeeder = [{ label: "--select--", value: "--select--" }];
                    let templateral = [{ label: "--select--", value: "--select--" }];
                    children = await getNodes(routeParams.slice(0, routeParams.length - 4));
                    children?.forEach(e => tempfeeder.push({ "label": e.name, "value": e.name }));
                    tempAllFieldsData.positions[position].feederOptions = tempAllFieldsData.positions[position].feederOptions ? tempAllFieldsData.positions[position].feederOptions : tempfeeder;
                    children = await getNodes(routeParams.slice(0, routeParams.length - 2));
                    children?.forEach(e => e.type === "LATERAL" && templateral.push({ "label": e.name, "value": e.name }));
                    tempAllFieldsData.positions[position].lateralOptions = tempAllFieldsData.positions[position].lateralOptions ? tempAllFieldsData.positions[position].lateralOptions : templateral;
                    tempAllFieldsData.positions[position].siteOptions = [{ label: "--select--", value: "--select--" }, ...tempOptions.filter(e => (e.type === "SITE" || e.type === "LATERAL_SITE") && (deviceType === e.siteDeviceType || e.siteDeviceType === null))]
                    backupsite["position" + position] = tempAllFieldsData.positions[position].siteOptions;
                }
                break;
            default:
                break;
        }
        this.setState({ allFieldsData: tempAllFieldsData, backupSiteOptions: backupsite }, () => { this.UpdateSiteData(); });
    }

    //Load the default dropdown values on device type selection
    loadDefaultNodeValues = (routeParams, deviceType) => {
        let dataToFill = {};
        const list = [{ label: "--select--", value: "--select--" }, { label: "A", value: "A" }, { label: "B", value: "B" }, { label: "C", value: "C" }];
        const tierList = [{ label: '--select--', value: '--select--' }, { label: 'Basic', value: 'Basic' }, { label: 'Intermediate', value: 'Intermediate' }, { label: 'Advanced', value: 'Advanced' }]
        const controlModeList = [{label: '--select--', value: '--select--'}, { label: 'Fixed Setpoint Mode (FSP)', value: 'Fixed Setpoint Mode (FSP)' }, { label: 'Power Factor Correction Mode (PFC)', value: 'Power Factor Correction Mode (PFC)' }, { label: 'Flicker Mitigation Mode (FMM)', value: 'Flicker Mitigation Mode (FMM)' }, { label: 'Auto Mode (AM)', value: 'Auto Mode (AM)' } ]
        routeParams.forEach(item => {
            if (typeof item === "object" && item !== null) {
                formFields.forEach(async (field) => {
                    if ((field.key === item.type.toLowerCase()) || (deviceType && field.key === "site" && item.type === "LATERAL_SITE")) {
                        field.options = field.options.length > 0 ? [{ label: item.name, value: item.name }] : [{ label: "--select--", value: "--select--" }, { label: item.name, value: item.name }];
                        field.isDisabled = true;
                        dataToFill = Object.assign({}, dataToFill, { [field.key]: item.name });
                        if (deviceType === "UM3+" && (field.key === "substation" || field.key === "feeder" || field.key === "site" || field.key === "lateral")) {
                            if (field.key === "feeder" || field.key === "site" || field.key === "lateral") field.isDisabled = false;
                            if (!routeParams.includes("SITE") && !routeParams.includes("LATERAL_SITE")) {
                                await this.apiRequestNodeDropdown(routeParams, deviceType, 0);
                            }
                            if ((routeParams.includes("SITE") || routeParams.includes("LATERAL_SITE")) && field.key === "site") {
                                let modifiedRouteParams = [...routeParams];
                                modifiedRouteParams = modifiedRouteParams.splice(0, modifiedRouteParams.length - 2)
                                await this.apiRequestNodeDropdown(modifiedRouteParams, deviceType, 0);
                            }
                        }
                    }
                    if (field.key === "phase" || field.key === "conductor1Phase" || field.key === "conductor2Phase" || field.key === "conductor3Phase") {
                        field.options = list;
                    }
                    if( deviceType === 'VC10' && field.key === 'controlMode') field.options = controlModeList;
                    if (deviceType === 'UM1' && field.key === "tier") field.options = tierList;
                    if(deviceType === "UM3"){
                        field.options = field.options.length > 0 ? [{ label: item.name, value: item.name }] : [{ label: "--select--", value: "--select--" }, { label: item.name, value: item.name }];
                    }
                })
            }
        });
        if (deviceType === "UM3+") {
            dataToFill["position"] = 1;
            const data = { ...dataToFill };
            dataToFill.positions = [];
            dataToFill.positions.push(data);
        }
        this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, dataToFill) });
    }

    //Fetch Device State list
    loadDeviceStateList = async () => {
        const list = [];
        const data = await loadDeviceStateList();
        if (data.length > 0) {
            data.sort();
            data.forEach(item => {
                list.push({ label: item, value: item });
            });
            formFields.filter(field => field.key === "deviceState" ? field.options = list : "");
            this.setState({ allFieldsData: { ...this.state.allFieldsData, ...{ "deviceState": data[0] } } })
        }
    }

    //Reset the dropdown data once the selection is changed 
    resetNodeDropdown = (item) => {
        switch (item.type) {
            case 'REGION':
                this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "substation": "", "feeder": "", "lateral": "", "site": "" }) });
                formFields.filter(field => field.key === "substation" || field.key === "feeder" || field.key === "lateral" || field.key === "site" ? field.options = [] : "");
                if (this.state.allFieldsData.deviceType === "UM3+") {
                    const positionsList = this.state.allFieldsData;
                    positionsList.positions && positionsList.positions.forEach(pos => {
                        pos.substation = "";
                        pos.feeder = "";
                        pos.lateral = "";
                        pos.site = "";
                        delete pos.feederOptions;
                        delete pos.lateralOptions;
                        delete pos.siteOptions;
                    });
                    this.setState({ allFieldsData: positionsList });
                }
                break;
            case 'SUBSTATION':
                this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "feeder": "", "lateral": "", "site": "" }) });
                formFields.filter(field => field.key === "feeder" || field.key === "lateral" || field.key === "site" ? field.options = [] : "");
                if (this.state.allFieldsData.deviceType === "UM3+") {
                    const positionsList = this.state.allFieldsData;
                    positionsList.positions && positionsList.positions.forEach(pos => {
                        pos.feeder = "";
                        pos.lateral = "";
                        pos.site = "";
                        delete pos.feederOptions;
                        delete pos.lateralOptions;
                        delete pos.siteOptions;
                    });
                    this.setState({ allFieldsData: positionsList });
                }
                break;
            case 'FEEDER':
                this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "lateral": "", "site": "" }) });
                formFields.filter(field => field.key === "lateral" || field.key === "site" ? field.options = [] : "");
                break;
            case 'LATERAL':
                this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "site": "" }) });
                formFields.filter(field => field.key === "site" ? field.options = [] : "");
                break;
            default:
                break;
        }
    }

    //Handling the textbox data change
    inputChangeHandler = (e, key) => {
        let value = e.target.value;
        this.setState({ allFieldsData: { ...this.state.allFieldsData, ...{ [key]: value } } });
    }

    //secured toggle switch handler
    onToggleSecured = () => {
        this.setState({ setSecured: !this.state.setSecured });
    }

    //Handling the dropdown value change
    ddlValueUpdate = async (e, key) => {
        let value = e.value && e.value !== "--select--" ? e.value : e !== "--select--" ? e : "";
        let routeParams = [];
        this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { [key]: value }) })
        switch (key) {
            case "region":
                if (value && value.length !== 0) {
                    routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: value, type: "REGION" }];
                    await this.apiRequestNodeDropdown(routeParams);
                    if (this.state.allFieldsData.deviceType === "UM3+") {
                        const positionsList = this.state.allFieldsData;
                        positionsList.positions && positionsList.positions.forEach(pos => {
                            pos.region = value;
                            pos.substation = "";
                            pos.feeder = "";
                            pos.lateral = "";
                            pos.site = "";
                            delete pos.feederOptions;
                            delete pos.lateralOptions;
                            delete pos.siteOptions;
                        });
                        this.setState({ allFieldsData: positionsList });
                    }
                    this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "substation": "", "feeder": "", "site": "" }) });
                } else {
                    this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "region": "", "substation": "", "feeder": "", "site": "" }) }, () => {
                        this.resetNodeDropdown({ type: "REGION" })
                    });
                }
                break;
            case "substation":
                if (value && value.length !== 0) {
                    routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.allFieldsData.region, type: "REGION" }, "SUBSTATION", { name: value, type: "SUBSTATION" }];
                    await this.apiRequestNodeDropdown(routeParams);
                    if (this.state.allFieldsData.deviceType === "UM3+") {
                        const positionsList = this.state.allFieldsData;
                        positionsList.positions && positionsList.positions.forEach(pos => {
                            pos.substation = value;
                            pos.feeder = "";
                            pos.lateral = "";
                            pos.site = "";
                            delete pos.lateralOptions;
                            delete pos.siteOptions;
                        });
                        this.setState({ allFieldsData: positionsList });
                    }
                    this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "feeder": "", "site": "" }) });
                } else {
                    this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "substation": "", "feeder": "", "site": "" }) }, () => {
                        this.resetNodeDropdown({ type: "SUBSTATION" })
                    });
                }
                break;
            case "feeder":
                if (value && value.length !== 0) {
                    routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.allFieldsData.region, type: "REGION" }, "SUBSTATION", { name: this.state.allFieldsData.substation, type: "SUBSTATION" }, "FEEDER", { name: value, type: "FEEDER" }];
                    await this.apiRequestNodeDropdown(routeParams);
                    this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "site": "", "lateral": "" }) });
                } else {
                    this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "feeder": "", "site": "" }) }, () => {
                        this.resetNodeDropdown({ type: "FEEDER" })
                    });
                }
                break;
            case "lateral":
                if (value && value.length !== 0) {
                    routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.allFieldsData.region, type: "REGION" }, "SUBSTATION", { name: this.state.allFieldsData.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.allFieldsData.feeder, type: "FEEDER" }, "LATERAL", { name: value, type: "LATERAL" }];
                    await this.apiRequestNodeDropdown(routeParams);
                    this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "site": "" }) });
                } else {
                    routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.allFieldsData.region, type: "REGION" }, "SUBSTATION", { name: this.state.allFieldsData.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.allFieldsData.feeder, type: "FEEDER" }];
                    if (this.state.allFieldsData.feeder && this.state.allFieldsData.feeder !== "") {
                        await this.apiRequestNodeDropdown(routeParams);
                        this.resetNodeDropdown({ type: "LATERAL" })
                    }
                }
                break;
            case "networkProvider":
                if (value && value.length !== 0) {
                    await this.loadSoftwareVersionList(this.state.allFieldsData.deviceType, value);
                    this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "softwareVersion": "" }) })
                }
                break;
            default:
                break;
        }
    }

    //Updating the position value on selection of the data for the UM3+ device only
    ddlPositionValueUpdate = async (e, key, position) => {
        const value = e !== "--select--" ? e : "";
        let routeParams = [];
        this.state.allFieldsData.positions = this.state.allFieldsData.positions ? this.state.allFieldsData.positions : [];
        const tempState = { ...this.state.allFieldsData };
        let backupsite = { ...this.state.backupSiteOptions };
        tempState.positions[position] = tempState.positions[position] ? tempState.positions[position] : { "region": tempState.region, "substation": tempState.substation };
        tempState.positions[position][key] = value;
        tempState.positions[position]["position"] = position + 1;
        tempState[key] = value;
        switch (key) {
            case "feeder":
                tempState.positions[position]["lateral"] = "";
                tempState.positions[position]["site"] = "";
                delete tempState.positions[position].lateralOptions
                delete tempState.positions[position].siteOptions
                if (!value) {
                    tempState["lateral"] = "";
                    tempState["site"] = "";
                    backupsite["position" + position] = [{ label: "--select--", value: "--select--" }];
                }
                break;
            case "lateral":
                tempState.positions[position]["site"] = "";
                tempState.positions[position]["lateral"] = value;
                delete tempState.positions[position].siteOptions
                if (!value) {
                    tempState["lateral"] = "";
                }
                break;
            default:
                break;
        }

        this.setState({ allFieldsData: tempState, backupSiteOptions: backupsite }, async () => {
            switch (key) {
                case "feeder":
                    if (value && value.length !== 0) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.allFieldsData.region, type: "REGION" }, "SUBSTATION", { name: this.state.allFieldsData.substation, type: "SUBSTATION" }, "FEEDER", { name: value, type: "FEEDER" }];
                        await this.apiRequestNodeDropdown(routeParams, this.state.allFieldsData.deviceType, position);
                        this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "site": "", "lateral": "" }) });
                    } else {
                        this.UpdateSiteData();
                        this.resetNodeDropdown({ type: "FEEDER" })
                    }
                    break;
                case "lateral":
                    if (value && value.length !== 0) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.allFieldsData.region, type: "REGION" }, "SUBSTATION", { name: this.state.allFieldsData.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.allFieldsData.positions[position].feeder, type: "FEEDER" }, "LATERAL", { name: value, type: "LATERAL" }];
                        await this.apiRequestNodeDropdown(routeParams, this.state.allFieldsData.deviceType, position);
                        this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "site": "" }) });
                    } else {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.allFieldsData.region, type: "REGION" }, "SUBSTATION", { name: this.state.allFieldsData.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.allFieldsData.positions[position].feeder, type: "FEEDER" }];
                        if (this.state.allFieldsData.feeder && this.state.allFieldsData.feeder !== "") {
                            await this.apiRequestNodeDropdown(routeParams, this.state.allFieldsData.deviceType, position);
                            this.resetNodeDropdown({ type: "LATERAL" })
                        }
                    }
                    break;
                case "site":
                    if (this.state.allFieldsData.positions[position].lateral) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.allFieldsData.region, type: "REGION" }, "SUBSTATION", { name: this.state.allFieldsData.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.allFieldsData.positions[position].feeder, type: "FEEDER" }, "LATERAL", { name: this.state.allFieldsData.positions[position].lateral, type: "LATERAL" }];
                        await this.apiRequestNodeDropdown(routeParams, this.state.allFieldsData.deviceType, position);
                    } else if (this.state.allFieldsData.positions[position].feeder) {
                        routeParams = ["ROOTNODE", { id: this.props.orgId }, "REGION", { name: this.state.allFieldsData.region, type: "REGION" }, "SUBSTATION", { name: this.state.allFieldsData.substation, type: "SUBSTATION" }, "FEEDER", { name: this.state.allFieldsData.positions[position].feeder, type: "FEEDER" }];
                        await this.apiRequestNodeDropdown(routeParams, this.state.allFieldsData.deviceType, position);
                    }
                    break;
                default:
                    break;
            }
        });
    }

    UpdateSiteData = () => {
        let backupsite = { ... this.state.backupSiteOptions }
        let allfielddata = { ...this.state.allFieldsData }

        allfielddata?.positions?.forEach((item, key) => {
            item.siteOptions = backupsite["position" + key];
        })

        if (allfielddata.hasOwnProperty("positions") && allfielddata.positions.length > 1) {
            const feederData = [];
            const positionFeederData = [];
            allfielddata.positions.map(item => item.feeder && positionFeederData.push(item.feeder));
            const similarData = positionFeederData.filter((item, index) => positionFeederData.indexOf(item) !== index);
            allfielddata.positions.forEach(item => {
               feederData.push({ "feeder": item.feeder, "lateral": item.lateral, "site": item.site })
            })
            similarData.forEach((item) => {
                let sitedata = feederData.filter(item1 => item1.feeder === item).map(e => e.site)
                feederData.forEach((item1, key) => {
                    if (item1.feeder === item) {
                        if (allfielddata.positions[key] && allfielddata.positions[key].siteOptions && allfielddata.positions[key].siteOptions.length) {
                            allfielddata.positions[key].siteOptions = allfielddata.positions[key].siteOptions.filter(e => {
                                return !sitedata.includes(e.value) || (sitedata.includes(e.value) && item1.site === e.value)
                            })
                        }
                    }
                })
            })
            this.setState({ allFieldsData: allfielddata });
        }
    }

    //Fetch the software version list
    loadSoftwareVersionList = async (deviceType, provider) => {
        const list = [{ label: "--select--", value: "--select--" }];
        const data = await loadSoftwareVersionList(deviceType, provider);
        if (data && data.length > 0) {
            data.sort(sortFirmwareversions);
            data.forEach(item => {
                list.push({ label: item, value: item });
            });
            formFields.filter(field => field.key === "softwareVersion" ? field.options = list : "");
        }
    }

    //Tracking the position on switch toggle 
    disablePositions = (position, isDisabled) => {
        let disPos = this.state.disabledPositions;
        let dataToUpdate = this.state.allFieldsData;
        if (isDisabled) {
            const dataToBeDisabled = this.state.allFieldsData.positions && this.state.allFieldsData.positions[position] ? this.state.allFieldsData.positions[position] : "";
            disPos.push({ ...dataToBeDisabled });
            Object.keys(dataToUpdate.positions[position]).forEach(key => {
                dataToUpdate.positions[position][key] = dataToUpdate.positions[position]["position"] !== dataToUpdate.positions[position][key] && !key.includes('Options') ? "" : dataToUpdate.positions[position][key];
            });
            dataToUpdate.positions[position]["switchDisabled"] = true;
        }
        else {
            disPos.forEach(dPos => {
                dataToUpdate.positions.forEach(pos => {
                    if (dPos.feeder === pos.feeder && dPos.site === pos.site) {
                        dPos.site = "";
                    }
                });
                if (dPos.position === position + 1) {
                    Object.keys(dataToUpdate.positions[position]).forEach(key => {
                        dataToUpdate.positions[position][key] = dPos[key]
                    });
                    delete dataToUpdate.positions[position]["switchDisabled"];
                }
            })
            const index = disPos.findIndex(pos => pos.position === position + 1);
            if (index !== -1) {
                disPos.splice(index, 1);
            }
        }
        this.setState({ disabledPositions: disPos, allFieldsData: dataToUpdate },()=>this.UpdateSiteData());
    }

    addPositionDetails = async (positionVal) => {
        const tempState = { ...this.state.allFieldsData };
        let backupsite = { ...this.state.backupSiteOptions };
        let params = [...this.props.selectedNode.routeParams];
        let children = [];
        
        if (this.props.nodeType === "FEEDER" || this.props.nodeType === "LATERAL" || this.props.nodeType === "SITE" || this.props.nodeType === "LATERAL_SITE") {
            let Obj = { lateralOptions: [{ label: "--select--", value: "--select--" }], siteOptions: [{ label: "--select--", value: "--select--" }] };
            switch (this.props.nodeType) {
                case "FEEDER":
                    Obj.lateral = "";
                    Obj.site = "";
                    children = await getNodes(params);
                    Obj.lateralOptions = [...Obj.lateralOptions, ...children.filter(e => e.type === "LATERAL").map(d => { return { "label": d.name, "value": d.name } })];
                    Obj.siteOptions = [...Obj.siteOptions, ...children.filter(e => e.type === "SITE" && (tempState.deviceType === e.siteDeviceType || e.siteDeviceType === null)).map(d => { return { "label": d.name, "value": d.name } })];
                    break;
                case "LATERAL":
                    Obj.feeder = params[7].name;
                    Obj.site = "";
                    children = await getNodes(params.slice(0, params.length - 2));
                    Obj.lateralOptions = [...Obj.lateralOptions, ...children.filter(e => e.type === "LATERAL").map(d => { return { "label": d.name, "value": d.name } })];
                    children = await getNodes(params);
                    Obj.siteOptions = [...Obj.siteOptions, ...children.filter(e => e.type === "LATERAL_SITE" && (tempState.deviceType === e.siteDeviceType || e.siteDeviceType === null)).map(d => { return { "label": d.name, "value": d.name } })];
                    break;
                case "SITE":
                    Obj.lateral = "";
                    Obj.feeder = params[7].name;
                    let tempFlagSite = false;
                    tempState.positions.forEach(e => {
                        if(e.site === this.props.selectedNode.name && e.feeder === params[7].name)
                        tempFlagSite = true;
                    })
                    Obj.site = tempFlagSite ? "" : this.props.selectedNode.name;

                    children = await getNodes(params.slice(0, params.length - 2));
                    Obj.lateralOptions = [...Obj.lateralOptions, ...children.filter(e => e.type === "LATERAL").map(d => { return { "label": d.name, "value": d.name } })];
                    Obj.siteOptions = [...Obj.siteOptions, ...children.filter(e => e.type === "SITE" && (tempState.deviceType === e.siteDeviceType || e.siteDeviceType === null)).map(d => { return { "label": d.name, "value": d.name } })];
                    break;
                case "LATERAL_SITE":
                    Obj.feeder = params[7].name;
                    Obj.lateral = params[9].name;
                    
                    children = await getNodes(params.slice(0, params.length - 4));
                    Obj.lateralOptions = [...Obj.lateralOptions, ...children.filter(e => e.type === "LATERAL").map(d => { return { "label": d.name, "value": d.name } })];
                    
                    children = await getNodes(params.slice(0, params.length - 2));
                    Obj.siteOptions = [...Obj.siteOptions, ...children.filter(e => e.type === "LATERAL_SITE" && (tempState.deviceType === e.siteDeviceType || e.siteDeviceType === null)).map(d => { return { "label": d.name, "value": d.name } })];
                    let tempFlagLatSite = false;
                    tempState.positions.forEach(e => {
                        if(e.site === this.props.selectedNode.name && e.feeder === params[7].name && e.lateral === params[9].name)
                        tempFlagLatSite = true;
                    })
                    Obj.site = tempFlagLatSite ? "" : this.props.selectedNode.name;
                    break;
                default:
                    break;
            }
            tempState.positions.push({
                ...this.state.allFieldsData.positions[0], 
                region: tempState.region,
                substation: tempState.substation,
                site: "", 
                conductor1Phase: "", 
                conductor2Phase:"", 
                conductor3Phase:"", 
                'position': positionVal,
                [this.props.selectedNode.type.toLowerCase()]: this.props.selectedNode.name,
                ...Obj
            });
            backupsite["position" + (positionVal - 1)] = Obj.siteOptions;
        } else tempState.positions.push({ feederOptions: tempState.positions[0].feederOptions ? [...tempState.positions[0].feederOptions] : [{ label: "--select--", value: "--select--" }] , 'position': positionVal, 'region' : tempState.region, 'substation' : tempState.substation });

        this.setState({ allFieldsData: tempState, backupSiteOptions: backupsite }, () => {
            this.UpdateSiteData()
        });
    }

    //On Click of the add button 
    addDevice = async () => {
        const deviceDetail = this.state.allFieldsData;
        const disablePos = [];
        const removedPosData = [];
        if (deviceDetail.deviceType === "UM3+" && deviceDetail.positions.length > 0) {
            deviceDetail.positions.filter(x => disablePos.push(x.switchDisabled === true ? x.switchDisabled : false))
        }
        delete deviceDetail["conductor1Phase"];
        delete deviceDetail["conductor2Phase"];
        delete deviceDetail["conductor3Phase"];

        let infoObj = null;

        if (this.inputValid(deviceDetail)) {
            infoObj = <FormattedMessage id={"gm.form.message.mandatory"} />;
        } else if (deviceDetail.networkGroupName === "") {
            infoObj = infoObj = <FormattedMessage id={"dm.configuration.error.selectgroupnode"} />;
        } else if ((deviceDetail.deviceType !== 'UM3+') && (deviceDetail.phase === '')) {
            infoObj = <FormattedMessage id={"gm.form.message.mandatory"} />;
        } else if ((deviceDetail.deviceType === 'UM3+') && (this.CheckConductorExists(deviceDetail))) {
            infoObj = <FormattedMessage id={"gm.form.message.mandatory.conductor"} />;
        } else if ((deviceDetail.deviceType === 'UM3+') && !disablePos.includes(false)) {
            infoObj = <FormattedMessage id="gm.form.message.mandatory.position" />;
        } else if ((deviceDetail.deviceType === 'UM3+') && (this.validateUM3conductors(deviceDetail).length > 0)) {
            infoObj = "Phase <" + this.validateUM3conductors(deviceDetail) + "> cannot be linked to more than one conductor, please correct and try again.";
        } else {
            deviceDetail.lateralSite = deviceDetail.lateral ? deviceDetail.site : "";
            deviceDetail.sensorIPAddress = deviceDetail.sensorIPAddress && deviceDetail.sensorIPAddress.trim();
            if (deviceDetail.deviceType === "UM3+") {
                deviceDetail.phase = "";
                deviceDetail.site = "";
                this.state.disabledPositions.forEach(disPos => {
                    deviceDetail.positions.forEach((detail, i) => {
                        if (disPos.position === detail.position) {
                            removedPosData.push(detail);
                            deviceDetail.positions.splice(i, 1)
                        }
                    })
                })
                this.setState({ allFieldsData: deviceDetail, removedPosData: removedPosData });
            }

            if (deviceDetail.deviceType !== "UM1") {
                deviceDetail.secured = this.state.setSecured;
            }
            await this.addDeviceAPIRequest(this.props.orgId, {...deviceDetail, hardwareRevision: this.state.hardwareRevision}); // 'hardwareRevision' property is required for all device type, For UM3+ device hardwareRevision value should be (CU/C), Other device value should be 'null'
        }
        return infoObj !== null ? this.setState({ errorMessage: infoObj }) : "";
    }

    //Checking the validation
    inputValid = (obj) => {
        let isInputValid = false;
        if (!obj.deviceType) {
            isInputValid = true;
        }
        formFields.forEach(field => {
            if ((["MM3","MM3ai"].includes(obj.deviceType)) && (obj.deviceType === field.value || field.value === "common") && (obj.deviceType !== "ZM1") && (obj.deviceType !== "UM3+" || field.displayType === "common")) {
                if (field.isMandatory) {
                    if (!obj[field.key])
                        isInputValid = true;
                    else if (obj[field.key] && obj[field.key] === "")
                        isInputValid = true;
                }
            }
            else if (obj.deviceType === "ZM1" && (obj.deviceType === field.value || field.value === "common" || field.value === "ZM1") && (obj.deviceType === "ZM1") && (obj.deviceType !== "UM3+" || field.displayType === "common")) {
                if (field.isMandatory) {
                    if (!obj[field.key])
                        isInputValid = true;
                    else if (obj[field.key] && obj[field.key] === "")
                        isInputValid = true;
                }
            }
            else if ((obj.deviceType === "UM3+" || field.deviceType === "UM3") && ((obj.deviceType === field.value || field.value === "common") && (obj.deviceType !== "ZM1"))) {
                if(this.state.hardwareRevision === 'CU'){ // for new UM3+
                    if (field.isMandatory &&  !["phase","site", "sensorIPAddress", "softwareVersion" ].includes(field.key)) {
                        if (!obj[field.key])
                            isInputValid = true;
                        else if (obj[field.key] && obj[field.key] === "")
                            isInputValid = true;
                    }
                } else { //for old UM3+
                    if (field.isMandatory && field.key !== "phase" && field.key !== "site") {
                        if (!obj[field.key])
                            isInputValid = true;
                        else if (obj[field.key] && obj[field.key] === "")
                            isInputValid = true;
                    }
                }
                obj.positions && obj.positions.length && obj.positions.forEach(value => {
                    if (!value.switchDisabled && (!value.feeder || !value.site)) isInputValid = true;
                });
            }
            else if ((obj.deviceType === "UM1" || (obj.deviceType === "VC10" && field.key !== 'tier')) && field.um1) {
                if (field.isMandatory) {
                    if (!obj[field.key])
                        isInputValid = true;
                    else if (obj[field.key] && obj[field.key] === "")
                        isInputValid = true;
                }
            }
        });
        return isInputValid;
    }

    /* Check atleast one phase exist for each position when adding devices */
    CheckConductorExists = (obj) => {
        let result = false;
        obj.positions && obj.positions.length && obj.positions.forEach(value => {
            if (!value.hasOwnProperty("switchDisabled") && !value.conductor1Phase && !value.conductor2Phase && !value.conductor3Phase) {
                result = true;
            }
        });
        return result;
    };

    //Validating the positions for UM3+ devices
    validateUM3conductors = (obj) => {
        const result = [];
        let duplicatePhase = [];
        obj.positions && obj.positions.length && obj.positions.forEach((element) => {
            const data = [element.conductor1Phase, element.conductor2Phase, element.conductor3Phase];
            duplicatePhase = data.filter((item, index) => { return item && data.indexOf(item) !== index; });
            const isPresent = duplicatePhase && duplicatePhase.length > 0 && result && result.length > 0 && result.includes(duplicatePhase[0]);
            if (!isPresent) {
                duplicatePhase && duplicatePhase.length && result.push(duplicatePhase[0])
            }
        });
        return result;
    }

    //Getting the response based on the selected values of the add device
    addDeviceAPIRequest = (orgId, deviceDetail) => {
        this.setState({ disableAddButton: true }, async () => {
            let payload = {...deviceDetail}
            payload.site = payload.lateral ? "" : payload.site;
            const data = await addDevice(orgId, payload);
            if (data) {
                if (data.status !== "FAIL") {
                    this.props.closeModal("isAddDeviceModalOpen")
                    ampleStore.dispatch(showAlertToaster(<div><strong>{this.state.allFieldsData.serialNumber}</strong> <FormattedMessage id='groupmanagement.success.adddevice' /> </div>, 'success'))
                    this.setState({ allFieldsData: {}, disableAddButton: false })
                    if (Object.keys(this.props.selectedNode).length > 0) {
                        await this.context.loadNextlevel({ "node": this.props.selectedNode }, this.props.selectedNode.routeParams ? this.props.selectedNode.routeParams : null, null, this.props.selectedNode.name,true);
                        await this.context.getDeviceList()
                    }

                }
                else {
                    if (this.state.allFieldsData.deviceType === "UM3+") {
                        const dataToAppend = this.state.allFieldsData;
                        dataToAppend.positions = dataToAppend.positions.concat(this.state.removedPosData);
                        dataToAppend.positions.sort((a, b) => a.position - b.position)
                        this.setState({ allFieldsData: dataToAppend })
                    }
                    this.setState({ errorMessage: data.message, disableAddButton: false });
                }
            }
        })
    }

    //On closing the error message
    hideErrorMsg = () => {
        this.setState({ errorMessage: "" });
    }

    componentDidMount =  () => {
        if (this.props.nodeType === "SITE" || this.props.nodeType === "LATERAL_SITE") {
            this.setState({ allFieldsData: Object.assign({}, this.state.allFieldsData, { "deviceType": this.props.selectedNode.siteDeviceType }), isDeviceTypeDisabled: (this.props.selectedNode.siteDeviceType ? !["MM3","MM3ai"].includes(this.props.selectedNode.siteDeviceType) : false) },async()=>{
                await this.resetDropdownDefault(this.props.selectedNode.siteDeviceType);
                if(this.state.allFieldsData.deviceType === "UM3+") {
                    this.getAllHardwareRevisionList(this.state.allFieldsData.deviceType)
                } else await this.loadNetworkProviderList(this.props.selectedNode.siteDeviceType);

            });
        }
    }

    render() {
        let componentToRender, modalTitle, modalBody, modalFooter, footerClassname;
       componentToRender = this.state.allFieldsData.deviceType && (["MM3","MM3ai"].includes(this.state.allFieldsData.deviceType) ? <MM3DeviceComponent state={this.state.allFieldsData} switchSecuredState={this.state.setSecured} onToggleSecured={()=>this.onToggleSecured()} inputChangeHandler={(e, key) => this.inputChangeHandler(e, key)} ddlValueUpdate={(e, key) => this.ddlValueUpdate(e, key)} />
            : this.state.allFieldsData.deviceType === "ZM1" ? <ZM1DeviceComponent state={this.state.allFieldsData} switchSecuredState={this.state.setSecured} onToggleSecured={()=>this.onToggleSecured()} inputChangeHandler={(e, key) => this.inputChangeHandler(e, key)} ddlValueUpdate={(e, key) => this.ddlValueUpdate(e, key)} nodeType={this.props.nodeType} />
                : this.state.allFieldsData.deviceType === "UM3+" ? <UM3DeviceComponent state={this.state.allFieldsData} switchSecuredState={this.state.setSecured} onToggleSecured={()=>this.onToggleSecured()} inputChangeHandler={(e, key) => this.inputChangeHandler(e, key)} ddlValueUpdate={(e, key) => this.ddlValueUpdate(e, key)} ddlPositionValueUpdate={(e, key, position) => this.ddlPositionValueUpdate(e, key, position)} disablePositions={(pos, bool) => this.disablePositions(pos, bool)} um3PositionData={this.state.um3PositionData} addPositionDetails={(pos) => this.addPositionDetails(pos)} hardwareRevision={this.state.hardwareRevision} hardwareRevisionList={this.state.hardwareRevisionList} setHardwareVersion={this.setHardwareVersion} />
                    : (this.state.allFieldsData.deviceType === "UM1" || this.state.allFieldsData.deviceType === "VC10") ? <UM1DeviceComponent state={this.state.allFieldsData} inputChangeHandler={(e, key) => this.inputChangeHandler(e, key)} ddlValueUpdate={(e, key) => this.ddlValueUpdate(e, key)} nodeType={this.props.nodeType} /> : <span></span>)
        footerClassname = this.state.disableAddButton ? "modalSaveBtn disabled" : "modalSaveBtn"

        modalTitle = <div className="popup-title" id="contained-modal-title-sm"><FormattedMessage id={`addDevice.popup.title`} /></div>
        modalBody = <div className="min-vh-25 single-select-dropdown">
            {this.props.isLoadingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
            <Form>
                {this.state.errorMessage && <><AlertMessage type={'danger'} message={this.state.errorMessage} timer={10000} setVisible={() => this.hideErrorMsg()} position={'w-100'} /><br /></>}
                <div className={(this.state.allFieldsData.deviceType) ? (( [null, "--select--" ].includes(this.state.hardwareRevision)) && this.state.allFieldsData.deviceType === "UM3+") ? '' : "scroll-modalbody" : ""}>
                    <FormGroup key="device-type" className="clearfix">
                        <Col sm={4} xs={4} className="float-left mb-1 m-0 p-0">
                            <FormattedMessage id={`dm.column.deviceType`} />*
                        </Col>
                        <Col sm={8} xs={8} className="float-left mb-1">
                            <SingleSelect disabled={this.state.isDeviceTypeDisabled} data={this.props.deviceTypes ? this.props.deviceTypes : []} setType={(evt) => this.setDeviceType(evt)} value={this.state.allFieldsData.deviceType ? this.state.allFieldsData.deviceType : "--select--"} icon='ok' />
                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                        </Col>
                    </FormGroup>
                    {componentToRender}
                </div>
            </Form>
        </div>
        modalFooter = [{ className: "required-message", name: <FormattedMessage id={`db.common.requiredFields`} />, leftContent: true }, { className: footerClassname, name: <FormattedMessage id='addDevice.button.add' />, value: "add" }];
        return (
            <div>
                {this.props.isAddDeviceModalOpen ?
                    <ModalWindow
                        show={this.props.isAddDeviceModalOpen}
                        onHide={() => this.props.closeModal("isAddDeviceModalOpen")}
                        size={"md"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.addDevice} /> : ""}
            </div>
        )
    }
}

export default AddDeviceComponent;
import React from 'react';
import FeederComponent from '../components/feederbarChartComponent';
import { feederBarData } from '../../../utility/dashboardUtility';

class FeederChart extends React.Component{
    state = {
        barOptions: feederBarData
    }
    render (){
        return(
            <React.Fragment>
                <FeederComponent options = {this.props.options} handleFeederClick = {this.props.handleFeederClick}/>
            </React.Fragment>   
        )
    };
}
export default FeederChart ;

import React, { Component, createContext } from 'react';
import { getVdConfigurations, createOrUpdateVirtualDevice, getVirtualDeviceList, downloadVirtualDevice, deleteVdDevice, calculateDevicePoint, lockOrUnlock, getLiveView, updateLiveView, getOnlineStatus, uploadVDMPointsFile, integrityPollTrigger, exportLiveView } from '../services/virtualDeviceService';
import { connect } from 'react-redux';
import { formatDateAndTimeWithoutSeconds } from '../../../../filters/dateHelper';
import { searchValue, virtualDeviceMngmntData } from '../../../../utility/virtualDevicerManagementUtility';
import { getBrowserTimeZone} from '../../../../services/utilityService';
import { FormattedMessage } from 'react-intl';
import {negativeNumOptRgx, setDownloadCSV} from '../../../../filters/commonFunction';

export const VirtualDeviceContext = createContext()
class VirtualDeviceMngmntProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpenAddDevicePage: false,
            isDisplayDeviceLiveView: false,
            clickedDeviceName: '',
            allVirtualDeviceData: [],
            allVirtualDeviceDataCopy:[],
            isLoadingVirtualTable: false,
            mainFormData: { name: '', description: '', ip: '', port: '', dnpAddress: '', vdDefaultSettings: {}, vdProfileSettings : [], phases:[] },
            mainFormDataCopy: { name: '', description: '', ip: '', port: '', dnpAddress: '', vdDefaultSettings: {}, vdProfileSettings : [], phases:[] },
            portList:[{label: "--select--", value: "--select--"}],
            mainFormDataErr: { name: {isErr : false, errMsg:''}, description: {isErr : false, errMsg:''}, port: {isErr : false, errMsg:''}, dnpAddress: {isErr : false, errMsg:''} },
            isOpenedDeviceViewModal: false,
            isOpenDeleteModal: false,
            isLoadingDeleteLoader:false,
            isClickedContinueBtn: false,
            clickedRowData: {},
            viewOnlyProfilePoints: [],
            liveViewTableData: [],
            liveViewTableDataCopy: [],
            liveViewTableDataForEdit: [],
            isLoadingLiveViewData: false,
            isLockedDevice: true,
            liveTableRowData: {},
            allConfigProperty: {},
            alertMessage:{type:'', message:'', for:''},
            isLoadingVDdownloadLoader: false,
            deviceTableSearchValue: '',
            devicePoint:{devices: '', points:'', recreate: ''},
            isloadingDevicePointData: false,
            lastTimeStamp:'',
            callCount: '',
            OSName: 'other',
            allEditedLiveViewData: [],
            file: null,
            isLoadingLVExport: false
        }
        this.copyProfilePointData = []
    }

    componentDidMount(){
        this.getAllVirtualDeviceData();
    }
   
    getStatusDataTest=()=>{
        this.setState({callCount: Number(this.state.callCount) + 1})
    }

    getAllVirtualDeviceData=(isFromAdd)=>{   //get all existing vertual device
        this.setState({ isLoadingVirtualTable: true, treeData: []}, () => {
            getVirtualDeviceList()
                .then((res) => {
                    let resData = res && Array.isArray(res) && res.length ? res.map((item)=> ({...item, 'lastStatusUpdateOn': item.lastStatusUpdateOn ? formatDateAndTimeWithoutSeconds(item.lastStatusUpdateOn) : "N/A" }) ) : [];
                        this.setState({
                            isLoadingVirtualTable: false,
                            allVirtualDeviceData: resData,
                            allVirtualDeviceDataCopy: resData,
                            oldVirtualDevice: this.state.allVirtualDeviceData,
                        },()=> {
                            searchValue.value !== '' && this.handleSearchDeviceTable(searchValue.value);  //for constant search while loading the page.
                             if(isFromAdd) this.getStatusOnline()
                        })
                }).catch((err) => {
                    this.setState({ isLoadingVirtualTable: false })
                })
        })
    }

    getStatusOnline=()=>{
        let oldListDevice = this.state.oldVirtualDevice;
        let newListDevice = this.state.allVirtualDeviceData;
        this.setState({ callCount: 0 },()=>{
            let allOldArr = []
            oldListDevice.map((item)=> allOldArr.push(item.id));
            let findNewlyCreatedDevice= [];
            if(newListDevice > oldListDevice){
                findNewlyCreatedDevice = newListDevice.filter((item=> allOldArr.indexOf(item.id) === -1 ));
            }

            let setStatusInter = setInterval(() => {  //calling api max 30 times to get the VD status, it will only work if newly device added else
                if(Number(this.state.callCount) < 30 && findNewlyCreatedDevice.length ) {
                    this.getStatusData(findNewlyCreatedDevice);
                } else clearInterval(setStatusInter) //clearing the interval function once receive the response
                },2000 )
        })
    }

    getStatusData=(newDevice)=>{ 
        this.setState({callCount: Number(this.state.callCount)+1 },()=>{    //number of call counting here
            getOnlineStatus(newDevice[0].id)    //api calling here with newly created id
            .then((res) => {
                if(res.data.status === "OK" && res.data.message === "success" && res.data.data.status === "ONLINE" ) {  //if respnse is success and device status is ONLINE then
                    let allDevice = this.state.allVirtualDeviceData.map((item)=> Object.assign({}, item));
                    for(var i=0; i<allDevice.length; i++){  //updating the status
                        if(allDevice[i].id === res.data.data.id){
                            allDevice[i].status = res.data.data.status;
                            allDevice[i].lastStatusUpdateOn = res.data.data.lastStatusUpdateOn ? formatDateAndTimeWithoutSeconds(res.data.data.lastStatusUpdateOn) : "N/A";
                        }
                    }
                    this.setState({
                        callCount: 30,  
                        allVirtualDeviceData: allDevice,
                        allVirtualDeviceDataCopy: allDevice
                    })
                }
            }).catch((err) =>  err )
        })
    }

    onCloseModal=(val)=>{   // for delete modal - cancel or confirm delete
        if(val === 'confirmDelete'){
            this.setState({ isLoadingDeleteLoader: true, alertMessage:{type:'', message:'', for: ''} },()=>{
                deleteVdDevice(this.state.clickedRowData.id)
                .then((res) => {
                    if(res.status === 'OK' && res.message === "success"){
                        this.setState({
                            isOpenDeleteModal: false,
                            alertMessage:{type:'success', message: <><b>{this.state.clickedRowData.name}</b> <FormattedMessage id={"vdm.device.delete"} /></> , for: 'deleteDevice'},
                            isLoadingDeleteLoader: false
                        },()=> this.getAllVirtualDeviceData())
                    } else this.setState({
                        isOpenDeleteModal: false,
                        alertMessage:{type:'danger', message: res.data!== null && res.data, for: 'deleteDevice'},
                        isLoadingDeleteLoader: false
                    })
                }).catch((err) => this.setState({ isOpenDeleteModal: false } ))
            })
        } else this.setState({ isOpenDeleteModal: false })
    }

    onCloseVirtualDeviceModal=()=>{ //for 'view' modal
        this.setState({ isOpenedDeviceViewModal : false, treeData:[] })
    }

    handleAddVirtualDevice=(val, clickedType, filedata )=>{    // for add, edit, view, confirm, delete add cancel. || 3rd parameter "filedata" only coming while upload the file.
        this.setState({ clickedType : clickedType, clickedRowData : val, alertMessage: {type: '', message:'', for:'' } });
        switch (clickedType) {
            case 'View':
                this.setState({ isOpenedDeviceViewModal : true, isDisplayDeviceLiveView: false, },()=>this.getViewApiCall(val))
            break;
            
            case 'delete':
                this.setState({ isOpenDeleteModal : true, isDisplayDeviceLiveView: false})
            break;
            
            case 'confirm':
                this.setState({ isOpenAddDevicePage: val})
            break;
            
            case 'Add':
                this.setState({ isOpenAddDevicePage: val, isDisplayDeviceLiveView: false }, ()=> {
                    this.getAddApiCallForDefaultData()
                    })
            break;

            case 'Edit':
                this.setState({ isOpenAddDevicePage: val, isDisplayDeviceLiveView: false}, ()=> this.getEditApiCall())
            break;

            case 'cancel':
                this.setState({
                    isOpenAddDevicePage: val,
                    isDisplayDeviceLiveView: false,
                    isClickedContinueBtn : false,
                    mainFormData: { name: '', description: '', ip: '', port: '', dnpAddress: '', vdDefaultSettings: { }, vdGlobalSettingsData:{}, vdProfileSettings : [], phases:[] },
                    mainFormDataErr: { name: {isErr : false, errMsg:''}, description: {isErr : false, errMsg:''}, port: {isErr : false, errMsg:''}, dnpAddress: {isErr : false, errMsg:''} },
                    treeData:[],
                    devicePoint: {devices: '', points:'', recreate: ''}
                })
            break;

            case 'download':
                this.handleDownload()
            break;

            case 'uploadFile':
                this.handleUploadPointFile(filedata)
            break;

            case 'IntegrityPoll':
                this.getIntegrityApiCall()
            break;
            
            default:
                break;
        }
    }

    getAddApiCallForDefaultData= ()=>{   // on click of "add" icon (create new device)
        this.setState({ isloadingGetDefaultData: true, treeData: [], mainFormData: {name: '', description: '', ip: '', port: '', dnpAddress: '', vdDefaultSettings: {}, vdProfileSettings : [], phases:[]}, portList:[{label: "--select--", value: "--select--"}] }, () => {
            getVdConfigurations()
                .then((res) => {
                    if(Object.keys(res).length !== 0){
                        this.copyProfilePointData = res.vdProfileSettings.map((item)=> Object.assign({}, item));    //storing copy of profile template data
                        let updateGroupData = res.vdProfileSettings && Array.isArray(res.vdProfileSettings) && res.vdProfileSettings.length !== 0 ? this.updateprofiledata(res.vdProfileSettings) : [];
                        let tmpArrForPortList = this.state.portList.map((item)=> Object.assign({}, item))
                        res?.vdPortRange && res.vdPortRange.length && res.vdPortRange.map((item)=> tmpArrForPortList.push({label: item, value: item.toString()}))
                        this.setState({
                            mainFormData: { name: '', description: '', ip: res.vdIP, port: this.state.portList[0]?.value, dnpAddress: '', vdDefaultSettings: res.vdDefaultSettings.vdPropertyDatas, vdGlobalSettings: res.vdGlobalSettings.vdPropertyDatas, vdProfileSettings: updateGroupData, phases:[] }, 
                            mainFormDataCopy: { name: '', description: '', ip: res.vdIP, port: this.state.portList[0]?.value, dnpAddress: '', vdDefaultSettings: res.vdDefaultSettings.vdPropertyDatas, vdGlobalSettings: res.vdGlobalSettings.vdPropertyDatas, vdProfileSettings: updateGroupData, phases:[] },
                            mainFormDataErr: { name: {isErr : false, errMsg:''}, description: {isErr : false, errMsg:''}, port: {isErr : false, errMsg:''}, dnpAddress: {isErr : false, errMsg:''}},
                            isloadingGetDefaultData: false,
                            allConfigProperty: res,
                            portList: tmpArrForPortList
                        })
                    } else this.setState({ isloadingGetDefaultData: false })
                }).catch((err) => {
                    this.setState({ isloadingGetDefaultData: false })
                })
        })
    }


    getViewApiCall=()=>{    // on click of "View" icon
        this.setState({ isloadingGetDefaultData: true, isloadingDevicePointData: true, treeData: [], mainFormData:{ name: '', description: '', ip: '', port: '', dnpAddress: '', vdDefaultSettings: {}, vdProfileSettings : [], phases:[] }, devicePoint: {devices: '', points:'', recreate: ''} }, () => {
            getVdConfigurations(this.state.clickedType, this.state.clickedRowData.id)
                    .then((res) => {
                        if(Object.keys(res).length !== 0){
                            let updateGroupData = res.vdProfileSettings && Array.isArray(res.vdProfileSettings) && res.vdProfileSettings.length ? this.updateprofiledata(res.vdProfileSettings.filter((item)=> item.selected)) : [];
                            this.setState({
                                mainFormData: { name: this.state.clickedRowData.name, description: this.state.clickedRowData.description, ip: this.state.clickedRowData.ip, port: this.state.clickedRowData.port, dnpAddress: this.state.clickedRowData.dnpAddress, vdDefaultSettings: res.vdDefaultSettings.vdPropertyDatas, vdGlobalSettings: res.vdGlobalSettings.vdPropertyDatas, vdProfileSettings: updateGroupData, phases: this.state.mainFormData.phases }, 
                                viewOnlyProfilePoints: updateGroupData,
                                isloadingGetDefaultData: false,
                                isloadingDevicePointData: false,
                                devicePoint: {devices: res.virtualDeviceInfo.devices, points: res.virtualDeviceInfo.points, recreate: res.virtualDeviceInfo.recreate}
                            })
                        } else this.setState({ isloadingGetDefaultData: false, isloadingDevicePointData: false, })
                    }).catch((err) => {
                        this.setState({ isloadingGetDefaultData: false, isloadingDevicePointData: false })
                    })
            })
    }

    updateprofiledata=(data)=>{ // adding an 'isDisplay' property by default value 'true', and checking condition if available duplicate 'profile name' then isDisplay value 'false'. Based on this 'isDisplay' property value showing profile template name
        for(let i=0; i<data.length; i++){
            if(i === 0) data[i].isDisplay = true;
            for(let j=i+1; j<data.length; j++){
                data[j].isDisplay = data[i].profileName !== data[j].profileName
            }
        }
        return data
    }

    getEditApiCall=()=>{ //on click of edit icon
        this.setState({ isloadingGetDefaultData: true, treeData: []}, () => {
            getVdConfigurations(this.state.clickedType, this.state.clickedRowData.id)
                .then((res) => {
                    if(Object.keys(res).length !== 0){
                        this.copyProfilePointData = res.vdProfileSettings.map((item)=> Object.assign({}, item));
                        let updateGroupData = res.vdProfileSettings && Array.isArray(res.vdProfileSettings) && res.vdProfileSettings.length ? this.updateprofiledata(res.vdProfileSettings) : [];

                        this.setState({
                            mainFormData: { name: this.state.clickedRowData.name, description: this.state.clickedRowData.description, ip: this.state.clickedRowData.ip, port: this.state.clickedRowData.port, dnpAddress: this.state.clickedRowData.dnpAddress, vdDefaultSettings: res.vdDefaultSettings.vdPropertyDatas, vdGlobalSettings: res.vdGlobalSettings.vdPropertyDatas, vdProfileSettings: updateGroupData, phases: this.state.mainFormData.phases }, 
                            mainFormDataErr: { name: {isErr : false, errMsg:''}, description: {isErr : false, errMsg:''}, port: {isErr : false, errMsg:''}, dnpAddress: {isErr : false, errMsg:''} },
                            isloadingGetDefaultData: false,
                            allConfigProperty: res
                        })
                    } else this.setState({ isloadingGetDefaultData: false })
                }).catch((err) => {
                    this.setState({ isloadingGetDefaultData: false })
                })
        })
    }

    getIntegrityApiCall=()=>{
        this.setState({isLoadingVDdownloadLoader: true}, ()=>{
            integrityPollTrigger(this.state.clickedRowData.id)
                .then((res) => {
                    if (res.data.status !== "FAIL") {
                        this.setState({
                            alertMessage: {type: 'success', message: <FormattedMessage id={"vdm.applyIntegrityPollSucMsg"} />, for:'integrityPoll' },
                            isLoadingVDdownloadLoader: false
                        })
                    } else {
                        this.setState({ alertMessage: {type: 'danger', message: res.data.data ? res.data.data : <FormattedMessage id={"vdm.applyIntegrityPollFailMsg"} />, for:'integrityPoll' }, isLoadingVDdownloadLoader: false })
                    }

                }).catch((err) => {
                    this.setState({ isLoadingVDdownloadLoader: false})
                })
        })      
    }

    generateDynamicIdForLiveView=()=> Date.now().toString(36) + Math.random().toString(36).substr(2); // creating an uniq ID and this is required to call the first api for live view

    // on closing of live view modal
    handleCloseDeviceLiveViewModal=()=> this.setState({ isDisplayDeviceLiveView: false, clickedDeviceName: '', clickedRowData: {}, liveViewTableData: [], liveViewTableDataCopy: [], uniqId:'', callCount: '' })

    handleOnclickVirtualDevice=(e, rowInfo)=>{ // open live view details modal on click of device name
        e.preventDefault();
        this.setState({
            isDisplayDeviceLiveView: true,
            clickedDeviceName: rowInfo.name,
            clickedRowData: rowInfo,
            isLoadingLiveViewData : true,
        },()=> this.getLiveViewFirstCall() )   // calling first api for live view data
    }

    getLiveViewFirstCall=()=>{
       this.setState({ uniqId : this.generateDynamicIdForLiveView(), callCount : 0},()=>{  // creating an uniq ID
        getLiveView(this.state.clickedRowData.id, this.state.uniqId)    // calling first api here with an uniq ID 
            .then((res) => {
                  if(res.data.status === "OK" && res.data.message === "success" ){  //if first api success then need to call 2nd api continuesly upto 30 times maximum.
                   let setInter = setInterval(() => {  
                        if(this.state.callCount !== '' && this.state.callCount <= 29 && this.state.liveViewTableData.length === 0 ) {
                            this.getLiveViewSecondCall();
                        } else clearInterval(setInter) //clearing the interval function once receive the response
                        }, 2000 )
                    } else this.setState({ isLoadingLiveViewData : false, liveViewTableData: [], liveViewTableDataCopy: [], lastTimeStamp: '' })
            }).catch((err) => this.setState({ isLoadingLiveViewData : false, liveViewTableData: [], liveViewTableDataCopy: [], lastTimeStamp: '' }) )
       })
    }

    getLiveViewSecondCall=()=>{ //calling the second api for liveview data, this is depends on the first api response status. it may call up to 10 times
        this.setState({callCount: this.state.callCount+1 },()=>{    //no of call counting here
            getLiveView(this.state.clickedRowData.id, this.state.uniqId)
            .then((res) => {
                if(res.data.status === "OK" && res.data.message === "success" ) {
                    let resData = res.data.data.map((item)=> ({...item, isEdit : false, pointIndex : Number(item.pointIndex), errMsg:'', value: item.value === 'true' || item.value === 'false' || item.value === 'NA' ? item.value : Number(item.value) }))
                    
                    this.setState({
                        liveViewTableData: resData,
                        liveViewTableDataCopy: resData.map((item)=> Object.assign({}, item) ),
                        liveViewTableDataForEdit: resData.map((item)=> Object.assign({}, item) ), // for edit value comuln storing this value, to prevent unnessesary sorting ehile onchage the value
                        allEditedLiveViewData:[], // storing all value column edited data 
                        isLoadingLiveViewData: resData.length !== 0 || this.state.callCount >=30 ? false : true,
                        lastTimeStamp: resData.length !== 0 || this.state.callCount >= 30  ? formatDateAndTimeWithoutSeconds(new Date().getTime()) : ''
                    })
                } else this.setState({ isLoadingLiveViewData: false, liveViewTableData: [], liveViewTableDataCopy: [], lastTimeStamp: '' })
            }).catch((err) => err )
        })
    }

    findIsErrAvailable=(data)=>{ //checking condition is error available, (clicking 'continue' button / onChange (if any error available) )
        let isErr = false;
        for (const key of Object.keys(data)) {
                for(const subKey of Object.keys(data[key])){
                    if(data[key][subKey] === true ) isErr = true
            }
        }
        return isErr
    }

    handleMainFormData=(value, field, formSection, fieldName)=>{    //form onchange add or edit vd device
        if(formSection === 'top' ){ //for device name, description, IP address, port, and DNP Address field
            this.setState({ mainFormData: { ...this.state.mainFormData, [field]: value }, isExiestCurrentValue :{ name: field === 'name' ? false : this.state.isExiestCurrentValue?.name } },()=>{
                let isErr = this.findIsErrAvailable(this.state.mainFormDataErr) // finding if any required field error
                isErr && this.handleClickContinue(false, 'vdConfiguration') // if error (after click continue button), then checking the validation
            })
        } else if(formSection === 'vdProfileSettings'){ //for profile assignment section
                let prvData = this.state.mainFormData.vdProfileSettings;
                prvData.map((item)=> {
                    if(item.profileName === field.original.profileName && item.pointName === field.original.pointName ){
                        item[fieldName] = value
                    }
                })
            this.setState({ mainFormData: { ...this.state.mainFormData, vdProfileSettings : prvData }},()=>{
                let isErr = this.findIsErrAvailable(this.state.mainFormDataErr)
                isErr && this.handleClickContinue(false, 'vdProfileSettings' )
            }) 
        } else {    //for middle section of device configuration
            this.setState({ mainFormData: { ...this.state.mainFormData, vdDefaultSettings : {...this.state.mainFormData.vdDefaultSettings, [field]: {...this.state.mainFormData.vdDefaultSettings[field], propertyValue:value }}}},()=>{
                let isErr = this.findIsErrAvailable(this.state.mainFormDataErr)
                isErr && this.handleClickContinue(false, 'vdConfiguration');
            })
        }
    }

    checkDuplicateDeviceNameOrIP=(value, field)=>{ // checking duplicate device name and it will start validate on focus out
        let addOrEdit = this.state.clickedType;
        let clickedDeviceName = this.state.clickedRowData;
        let prevVDeviceList = addOrEdit === "Add" ? this.state.allVirtualDeviceData : this.state.allVirtualDeviceData.filter((item)=> item.name !== clickedDeviceName.name );
        
        let isExiestCurrentValue = {[field]: false};
            prevVDeviceList.forEach((item)=>{
                if((item[field]).toLowerCase() === (value).toLowerCase()){
                    isExiestCurrentValue = {...isExiestCurrentValue, [field]: true}
                }
            })
        this.setState({ isExiestCurrentValue: {...this.state.isExiestCurrentValue, [field]: isExiestCurrentValue[field]}},()=>{
            let isErr = this.findIsErrAvailable(this.state.mainFormDataErr)
            isErr && this.handleClickContinue(false, 'vdConfiguration')
        })
    }
    
    handleClickContinue=(value, section, editBack)=>{   // onClick of continue OR if any error available then onChange of value calling this function to validate.

        if(editBack){  //need to re-update the profile template while click the 'Edit' button(after continue before confirm for add and edit screen).
            let updateGroupData = this.updateprofiledata(this.state.mainFormData.vdProfileSettings);
                this.setState({
                    mainFormData: { ...this.state.mainFormData, vdProfileSettings: updateGroupData}, 
                    mainFormDataErr : {}, 
                    isClickedContinueBtn : value, 
                    isErrorOnValidation: false
                })
        } else {
            let errData = this.state.mainFormDataErr;
            // ------------------------
            // section value = 'All' coming from onclick of 'Continue' button -: If "All" then validating all the section (  Virtual Device Configuration, site selection and profile assignement)
            // section value = 'vdConfiguration', coming from VD configuration section onChange time (left section)
            // section value = 'siteSelection' coming from site selection onChange time (middle section)
            // section value = 'vdProfileSettings' coming from profile assignment section onChange of checkbox and text field (right section)
            if(section === 'vdConfiguration' || section === 'All' ){
                let rulesForDefaultData = this.state.allConfigProperty.vdDefaultSettings.vdPropertyRules
                // declaring all the required validation information here.
                let arr = [ 
                    { name: 'name', emptyErrMsg: <FormattedMessage id={"vdm.validation.deviceNameEmpty"} /> , minMaxErrMsg: <FormattedMessage id={"vdm.validation.deviceNameLength"} /> , duplicateErr: <FormattedMessage id={"vdm.validation.deviceNameExits"} /> , min: this.findMinMaxFromUtilityForm('name')?.min, max: this.findMinMaxFromUtilityForm('name')?.max, section: this.state.mainFormData.name},
                    { name: 'description', emptyErrMsg: '', minMaxErrMsg: <FormattedMessage id={"vdm.validation.descriptionLength"} /> , min:this.findMinMaxFromUtilityForm('description')?.min, max:this.findMinMaxFromUtilityForm('description')?.max, section: this.state.mainFormData.description},
                    { name: 'ip', emptyErrMsg: <FormattedMessage id={"vdm.validation.ipAddressEmpty"} /> , section: this.state.mainFormData.ip},
                    { name: 'port', emptyErrMsg: <FormattedMessage id={"vdm.validation.portEmpty"} /> , section: this.state.mainFormData.port},   //note: we are getting min and max value as array. 1st and 2nd index should be min and max
                    { name: 'dnpAddress', emptyErrMsg: <FormattedMessage id={"vdm.validation.dnpAddressEmpty"} /> , minMaxErrMsg: <FormattedMessage id={"vdm.validation.dnpAddressLength"} /> , min: this.findMinMaxFromUtilityForm('dnpAddress')?.min, max: this.findMinMaxFromUtilityForm('dnpAddress')?.max, section: this.state.mainFormData.dnpAddress },
                    { name: ['default.binary.output.spares'], emptyErrMsg: <FormattedMessage id={"vdm.validation.bosEmpty"} /> , minMaxErrMsg: <FormattedMessage id={"vdm.validation.bosLength"} /> , min: rulesForDefaultData['default.binary.output.spares'].min, max: rulesForDefaultData['default.binary.output.spares'].max, section:this.state.mainFormData.vdDefaultSettings['default.binary.output.spares'].propertyValue },
                    { name: ['default.binary.input.spares'], emptyErrMsg: <FormattedMessage id={"vdm.validation.bisEpty"} /> , minMaxErrMsg: <FormattedMessage id={"vdm.validation.bisLength"} /> , min: rulesForDefaultData['default.binary.input.spares'].min, max:rulesForDefaultData['default.binary.input.spares'].max, section:this.state.mainFormData.vdDefaultSettings['default.binary.input.spares'].propertyValue },
                    { name: ['default.analog.output.spares'], emptyErrMsg: <FormattedMessage id={"vdm.validation.aosEmpty"} /> , minMaxErrMsg: <FormattedMessage id={"vdm.validation.aosLength"} /> , min: rulesForDefaultData['default.analog.output.spares'].min, max:rulesForDefaultData['default.analog.output.spares'].max, section:this.state.mainFormData.vdDefaultSettings['default.analog.output.spares'].propertyValue },
                    { name: ['default.analog.input.spares'], emptyErrMsg: <FormattedMessage id={"vdm.validation.aisEmpty"} /> , minMaxErrMsg: <FormattedMessage id={"vdm.validation.aisLength"} /> , min: rulesForDefaultData['default.analog.input.spares'].min, max:rulesForDefaultData['default.analog.input.spares'].max, section:this.state.mainFormData.vdDefaultSettings['default.analog.input.spares'].propertyValue },
                    { name: ['default.counter.spares'], emptyErrMsg: <FormattedMessage id={"vdm.validation.csEmpty"} /> , minMaxErrMsg: <FormattedMessage id={"vdm.validation.csLength"} /> , min: rulesForDefaultData['default.counter.spares'].min, max: rulesForDefaultData['default.counter.spares'].max, section:this.state.mainFormData.vdDefaultSettings['default.counter.spares'].propertyValue },
                    { name: ['default.offline.timer'], emptyErrMsg: <FormattedMessage id={"vdm.validation.onlineToOffLineEmpty"} /> , minMaxErrMsg: <FormattedMessage id={"vdm.validation.onlineToOffLineLength"} /> , min: rulesForDefaultData['default.offline.timer'].min, max:rulesForDefaultData['default.offline.timer'].max, section:this.state.mainFormData.vdDefaultSettings['default.offline.timer'].propertyValue },
                ]

                arr.forEach((item)=>{
                    if(item.name === 'port'){
                        if(item.section === '--select--'){
                            errData = {...errData, [item.name] : { isErr : true, errMsg: item.emptyErrMsg }}
                        } else errData = {...errData, [item.name] : { isErr : false, errMsg: '' }}

                    } else {
                        let checkWithlengthOrNot = item.name === 'name' || item.name === 'description' ? item.section.length : item.section;
                        if (item.section === '' && item.name !== 'description' ){
                            errData = {...errData, [item.name] : { isErr : true, errMsg: item.emptyErrMsg }}
                        } else if (item.section !== '' && Number(checkWithlengthOrNot) < item.min || Number(checkWithlengthOrNot) > item.max ){
                            errData = {...errData, [item.name] : { isErr : true, errMsg: item.name === 'description' ? item.minMaxErrMsg : <>{item.minMaxErrMsg} {item.min}-{item.max}</> }}
                        } else errData = {...errData, [item.name] : { isErr : false, errMsg: '' }}

                        if (item.name === 'name' &&  item.section !== '' && Number(checkWithlengthOrNot) > item.min && Number(checkWithlengthOrNot) < item.max && this.state.isExiestCurrentValue?.[item.name] ) {
                            errData = {...errData, [item.name] : { isErr : true, errMsg: item.duplicateErr }}
                        }
                    }
                })
            } 

            if( section === 'vdProfileSettings' || section === 'All' ){
                //for profile template class error // should be 0-3
                let allProfileTemplateArr = this.state.mainFormData.vdProfileSettings;
                allProfileTemplateArr.map((item)=>{
                    if((item.classValue !== -1 && (Number(item.classValue) < 0 || Number(item.classValue) > 3 || item.classValue === '')) ){
                        errData = {...errData, [item.id] : { isErr : true, errMsg: <FormattedMessage id={"vdm.validation.classLength"} /> }}
                    } else errData = {...errData, [item.id] : { isErr : false, errMsg: '' }}
                })

                //for profile template checkbox error - mandatory to select one profile
                let allCheckedValue = [];
                allProfileTemplateArr.map((item)=> item.selected && allCheckedValue.push(item.selected))
                if(allCheckedValue.length === 0) {
                    errData = {...errData, emptyCheckboxErr : { isErr : true, errMsg: <FormattedMessage id={"vdm.validation.profileSelectionEmpty"} /> }}
                } else errData = {...errData, emptyCheckboxErr : { isErr : false, errMsg: ''}}
            } 
        
            if(section === 'siteSelection' || section === 'All' ){
                //for site selection validation error - mandatory to select one site
                let allSiteSelectionArr = this.state.mainFormData.phases?.filter((item)=> item.regionSelected ===true );
                if((allSiteSelectionArr?.length !== 0)){
                    errData = {...errData, siteSelectionErr : { isErr : false, errMsg: '' }}
                } else errData = {...errData, siteSelectionErr : { isErr : true, errMsg: <FormattedMessage id={"vdm.validation.siteSelectionEmpty"} /> }}
            }

            this.setState({ mainFormDataErr : errData, isClickedContinueBtn : value, isErrorOnValidation: value === true && this.findIsErrAvailable(errData) },()=>{
                if(!this.state.isErrorOnValidation && this.state.isClickedContinueBtn ){ // if no error
                    //for get device count and point count
                    this.getDeviceAndPointCountDetails()
                    
                    //for view only profile poitns -: only selected profile points data to display.
                    let profilePointsData = this.state.mainFormData.vdProfileSettings;
                    let filterSelected = profilePointsData.filter((item)=> item.selected === true);
                    let addIdInProfileData = this.updateprofiledata(filterSelected);
                    this.setState({
                        viewOnlyProfilePoints: addIdInProfileData
                    })
                }
            })
        }
    }

    findMinMaxFromUtilityForm=(fieldName)=> virtualDeviceMngmntData.formData.virtualDeviceConfig.find((fItem)=> fItem.field === fieldName)
    
    getDeviceAndPointCountDetails=()=>{
        let allproperty =  this.state.allConfigProperty;
        let mainFormData = this.state.mainFormData;
        let finaldata = {...mainFormData, id: this.state.clickedType === 'Edit' ? this.state.clickedRowData.id : null, vdDefaultSettings: {vdPropertyDatas: mainFormData.vdDefaultSettings, vdPropertyRules: allproperty.vdDefaultSettings.vdPropertyRules}} //required format need to send
        delete finaldata.vdGlobalSettings   //deleting extra property before sending data to backend.
        this.setState({ isloadingDevicePointData: true, devicePoint: { devices : '', points: '', recreate: '' }}, ()=>{
            calculateDevicePoint(finaldata) //api calling for display device and point if no error
            .then((res) => {
                if(res.data.status === "OK" && res.data.message === "success" ){
                    this.setState({
                        isloadingDevicePointData: false,
                        devicePoint: { devices : res.data.data.devices, points: res.data.data.points, recreate: res.data.data.recreate }
                    })
                } else this.setState({ isloadingDevicePointData: false })
            }).catch((err) => this.setState({ isloadingDevicePointData : false }))
        })
    }

    handleClickResetButton=(from)=>{
        let isErrorOnValidation = this.findIsErrAvailable(this.state.mainFormDataErr)
        if(from === 'profilePoints'){
            let addIdInProfileData = this.copyProfilePointData.map((item)=> Object.assign({}, item));
            this.setState({ mainFormData : {...this.state.mainFormData, vdProfileSettings: this.updateprofiledata(addIdInProfileData)  } },()=> {
                (this.state.isClickedContinueBtn || isErrorOnValidation) && this.handleClickContinue(false, 'vdProfileSettings')
            })
        } else {
            this.setState({ mainFormData : {...this.state.mainFormData, vdDefaultSettings: this.state.mainFormDataCopy.vdDefaultSettings } },()=> {
                (this.state.isClickedContinueBtn || isErrorOnValidation) && this.handleClickContinue(false, 'vdConfiguration')
            })
        }
    }
    
    saveTreedata=(data, selectedData)=>{
        this.setState({ treeData: data, mainFormData: {...this.state.mainFormData, phases: selectedData} }, ()=>{
            let isErr = this.findIsErrAvailable(this.state.mainFormDataErr)
                isErr && this.handleClickContinue(false, 'siteSelection');
        })
    }

    handleClickConfirm=()=>{ // create or edit api calling here
        let allproperty =  this.state.allConfigProperty;
        let mainFormData = this.state.mainFormData;
        let finaldata = {...mainFormData, vdDefaultSettings: {vdPropertyDatas: mainFormData.vdDefaultSettings, vdPropertyRules: allproperty.vdDefaultSettings.vdPropertyRules}} //required format need to send
        delete finaldata.vdGlobalSettings   //deleting extra property before sending data to backend.

        this.setState({isLoadingConfirmLoader: true, alertMessage: {type: '', message:'', for:'' }}, () => {
            createOrUpdateVirtualDevice(finaldata, this.state.clickedType, this.state.clickedRowData.id)
                .then((res) => {
                        if(res.data.status === "OK" && res.data.message === "success"){
                            this.setState({
                                isOpenAddDevicePage: false,
                                isDisplayDeviceLiveView: false,
                                mainFormData: { name: '', description: '', ip: '', port: '', dnpAddress: '', vdDefaultSettings: {}, vdProfileSettings : [], phases:[] },
                                mainFormDataCopy: { name: '', description: '', ip: '', port: '', dnpAddress: '', vdDefaultSettings: {}, vdProfileSettings : [], phases:[] },
                                mainFormDataErr: { name: {isErr : false, errMsg:''}, description: {isErr : false, errMsg:''}, port: {isErr : false, errMsg:''}, dnpAddress: {isErr : false, errMsg:''} },
                                isClickedContinueBtn: false,
                                isLoadingConfirmLoader: false,
                                isNewlyAdded: this.state.clickedType === 'Add' ? true : false,
                                alertMessage: {type: 'success', message: <span><b>{this.state.clickedType === 'Edit' ? this.state.clickedRowData.name : this.state.mainFormData.name}</b> {this.state.clickedType === 'Add' ? <FormattedMessage id={"vdm.success.createMsg"} /> : <FormattedMessage id={"vdm.success.updateMsg"} />} </span>, for:'createOrUpdate' }
                            },()=>{
                                this.getAllVirtualDeviceData(this.state.isNewlyAdded)
                                })
                        } else {
                            this.setState({ isLoadingConfirmLoader: false, alertMessage: {type: 'danger', message:res.data.message!== null ? res.data.message : <FormattedMessage id={"vdm.err.updateOrCreate"} /> , for:'createOrUpdateError'}})
                        }
                }).catch((err) => {
                    this.setState({ isLoadingConfirmLoader : false })
                })
        })
    }

    handleExportLiveView=()=>{
        this.setState({isLoadingLVExport: true},()=>{
            exportLiveView(this.state.clickedRowData.id, this.state.uniqId, getBrowserTimeZone())
                .then((data) => {
                    setDownloadCSV(data);
                    this.setState({ isLoadingLVExport: false })
                }).catch((err) => this.setState({ isLoadingLVExport: false }))
        })
    }

    handleRefreshLiveView=()=>{
        this.setState({ isLoadingLiveViewData : true, liveViewTableData: [] })
        this.getLiveViewFirstCall();
    }

    handleLockVirtualDevice=(val)=>{    //for lock and unlock virtual device
        this.setState({ isLoadingLockResData: true },()=>{
            lockOrUnlock(this.state.clickedRowData)
                .then((res) => {
                    if(res.status !== "FAIL"){
                        let prvDataLiveData = this.state.liveViewTableData;
                        prvDataLiveData.map((item)=>item.isEdit = false)
                        let prvAllVirtualDeviceData = this.state.allVirtualDeviceData;
                        prvAllVirtualDeviceData.map((item)=> item.id === this.state.clickedRowData.id ? item.locked = val : item  )

                        this.setState({
                            isLoadingLockResData: false,
                            clickedRowData: {...this.state.clickedRowData, locked : val},
                            liveViewTableData: prvDataLiveData,
                            allVirtualDeviceData: prvAllVirtualDeviceData
                        })
                    } else this.setState({ isLoadingLockResData: false })
                }).catch((err) => this.setState({ isLoadingLockResData: false }) )
        })
    }

    handleClickOnLivePointValue=(rowData)=>{    //onclick of edit for live view table
        let prvData = this.state.liveViewTableData;
            prvData.map((item)=>{
                if(item.id === rowData.original.id) {
                    item.isEdit = true;
                }
            })
        this.setState({
            liveViewTableData : prvData,
        })
    }


    saveOrCancelLiveViewPoint=(rowData, saveOrCance, event)=>{
        event.stopPropagation();
        if(saveOrCance === 'cancel' ){
            let allEditeddata = this.state.allEditedLiveViewData;
            let prvValue = this.state.liveViewTableDataCopy.find((item)=> item.id === rowData.original.id);
            let prvDatas = this.state.liveViewTableData.map((item)=> Object.assign({}, item) );
            prvDatas.map((item)=>{
                if(item.id === rowData.original.id) {
                    item.value = prvValue.value;
                    item.isEdit = false;
                    item.errMsg = ''
                }
                if(item.isEdit){
                    allEditeddata.map((eItem)=>{
                        if(eItem.id === item.id){
                            item.value = eItem.value
                        }
                    })
                }
            })
            this.setState({liveViewTableData : prvDatas, liveViewTableDataForEdit: prvDatas })
        } else {    //if error
        
                this.setState({ isLoadingLiveViewPointUpdate: true },()=>{
                    updateLiveView(this.state.clickedRowData.id, rowData.original.id, rowData.original.value)
                    .then((res) => {
                        if(res.data.status === "OK" && res.data.message === "success" ){
                            let numOnlyRegex = negativeNumOptRgx;
                            let allEditeddata = this.state.allEditedLiveViewData;
                            let prvDatas = this.state.liveViewTableData.map((item)=> Object.assign({}, item) );
                            prvDatas.map((item)=>{  //updating the same edited data if success, not calling any API to get updated data here
                                if(item.id === rowData.original.id) {
                                    item.isEdit = false;
                                    item.status = "EDITED";
                                    item.value = !numOnlyRegex.test(rowData.original.value) ?  rowData.original.value : Number(rowData.original.value);
                                    item.errMsg = '';
                                    item.timeStamp = new Date().getTime();
                                }
                                if(item.isEdit){
                                    allEditeddata.map((eItem)=>{
                                        if(eItem.id === item.id){
                                            item.value = eItem.value
                                        }
                                    })
                                }
                            })

                            let prvDatasCopy = this.state.liveViewTableDataCopy.map((item)=> Object.assign({}, item) );

                            prvDatasCopy.map((item)=>{  //updating the copy data
                                if(item.id === rowData.original.id) {
                                    item.isEdit = false;
                                    item.status = "EDITED";
                                    item.errMsg = '';
                                    item.value = !numOnlyRegex.test(rowData.original.value) ?  rowData.original.value : Number(rowData.original.value)
                                    item.timeStamp = new Date().getTime();
                                }
                            })

                            this.setState({ liveViewTableData : prvDatas, liveViewTableDataForEdit: prvDatas, liveViewTableDataCopy: prvDatasCopy,  isLoadingLiveViewPointUpdate : false, alertMessage: {type: 'success', message: <FormattedMessage id={"vdm.success.lvUpdate"} />, for:'updateLiveview' } })
                        } else this.setState({isLoadingLiveViewPointUpdate : false, alertMessage: {type: 'danger', message: res.data.message , for:'updateLiveview' } })
                    }).catch((err) => this.setState({isLoadingLiveViewPointUpdate : false }) )
                })
        }
    }

    getTableSortedData=(data)=>{
        this.setState({
            sortedData : data
        },()=>{
            let prvDatas = this.state.liveViewTableData.map((item)=> Object.assign({}, item) );
            let isAvailEditedField = prvDatas.some((item)=> item.isEdit === true)
            if(isAvailEditedField){
                let numOnlyRegex = negativeNumOptRgx;
                let allEditeddata = this.state.allEditedLiveViewData;
                prvDatas.map((item)=>{
                    if(item.isEdit){
                        allEditeddata.map((eItem)=>{
                            if(eItem.id === item.id){
                                item.value = !numOnlyRegex.test(eItem.value) ?  eItem.value : Number(eItem.value) 
                            }
                        })
                    }
                })
            }
            this.setState({ liveViewTableDataForEdit: prvDatas, liveViewTableData: prvDatas })
        })
    }

    handleOnchangeLiveViewPoints=(field, value, rowData)=>{
        let tmpArr = this.state.allEditedLiveViewData.length ? this.state.allEditedLiveViewData.map((item)=> Object.assign({}, item)) : [];
        let data = { field: field, value: value, id: rowData.original.id }
        if(tmpArr.length){
            let findIsExist = tmpArr.filter((item)=> item.id === rowData.original.id);
            if(findIsExist.length){
                for(var i=0; i<tmpArr.length; i++){
                    if(tmpArr[i].id === rowData.original.id){
                        tmpArr[i] = data
                    }
                }
            } else {
                let newArr = [...tmpArr, ...[data]]
                    tmpArr = newArr
            }
        } else tmpArr.push(data)
        this.setState({ allEditedLiveViewData : tmpArr })
    }

    handleDownload=()=>{
        this.setState({isLoadingVDdownloadLoader: true},()=>{
            downloadVirtualDevice(this.state.clickedRowData.id)
                .then((data) => {
                    setDownloadCSV(data);
                    this.setState({ isLoadingVDdownloadLoader: false })
                }).catch((err) => this.setState({ isLoadingVDdownloadLoader: false }))
        })
    }

    handleUploadPointFile=(e)=>{
        if (e.target.files.length !== 0) {
            this.setState({ file: e.target.files[0], fileName: e.target.files[0].name, isLoadingVDdownloadLoader: true}, () => {
                let formData = new FormData();
                formData.append('fileData', this.state.file);
                uploadVDMPointsFile(this.state.clickedRowData.id, formData)
                .then(res => {
                    if (res.data.status !== "FAIL") {
                        this.setState({
                            alertMessage: {type: 'success', message: res.data.data ? res.data.data : <FormattedMessage id={"vdm.pointMapUploadedSuccessfully"} />, for:'uploadFile' },
                            isLoadingVDdownloadLoader: false,
                            file: null
                        },()=>  this.getAllVirtualDeviceData())
                    } else {
                        this.setState({ alertMessage: {type: 'danger', message: res.data.data ? res.data.data : <FormattedMessage id={"vdm.fileUploadingFailed"} />, for:'uploadFile' }, isLoadingVDdownloadLoader: false })
                    }
                }).catch(err => this.setState({ isLoadingVDdownloadLoader: false }))
            })
        }
    }

    isAvailSearchValue=(item, searchValue)=> ['name', 'description', 'ip', 'port', 'dnpAddress', 'deviceCount', 'pointCount', 'lastStatusUpdateOn'].some((n)=> (item[n].toString().toLowerCase()).includes(searchValue.toLowerCase()))

    handleSearchDeviceTable=(value)=>{
        searchValue.value = value;
        let tmpArr = [];
        if(value.length){
            this.state.allVirtualDeviceDataCopy.forEach((item)=>{
                if((this.isAvailSearchValue(item, value))) tmpArr.push(item)  //calling a function and checking if search value available in required column then pushing that to tmpArr
            })} else  tmpArr = this.state.allVirtualDeviceDataCopy
        this.setState({
            allVirtualDeviceData: tmpArr,
            deviceTableSearchValue: searchValue.value
        })
    }

    closeAlertMessage=()=>{
        this.setState({ alertMessage: {type: '', message: '', for:'' }})
    }

    render() {
        return (
            <VirtualDeviceContext.Provider value={{
                state: this.state,
                roleAndPermission: this.props.roleAndPermission,
                onCloseModal: this.onCloseModal,
                onCloseVirtualDeviceModal: this.onCloseVirtualDeviceModal,
                handleAddVirtualDevice: this.handleAddVirtualDevice,
                handleOnclickVirtualDevice: this.handleOnclickVirtualDevice,
                handleCloseDeviceLiveViewModal: this.handleCloseDeviceLiveViewModal,
                handleMainFormData: this.handleMainFormData,
                checkDuplicateDeviceNameOrIP: this.checkDuplicateDeviceNameOrIP,
                handleClickContinue: this.handleClickContinue,
                handleClickResetButton: this.handleClickResetButton,
                handleClickConfirm: this.handleClickConfirm,
                handleRefreshLiveView: this.handleRefreshLiveView,
                handleSearchDeviceTable: this.handleSearchDeviceTable,
                handleLockVirtualDevice: this.handleLockVirtualDevice,
                handleClickOnLivePointValue: this.handleClickOnLivePointValue,
                saveOrCancelLiveViewPoint: this.saveOrCancelLiveViewPoint,
                handleOnchangeLiveViewPoints: this.handleOnchangeLiveViewPoints,
                saveTreedata: this.saveTreedata,
                closeAlertMessage: this.closeAlertMessage,
                getTableSortedData: this.getTableSortedData,
                handleExportLiveView: this.handleExportLiveView
            }}>
                {this.props.children}
            </VirtualDeviceContext.Provider>
        );
    }
}

const mapStateToProps = state => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    };
}

export default connect(mapStateToProps, {})(VirtualDeviceMngmntProvider);
import { object } from 'prop-types';
import React from 'react';
import SingleSelect from '../hoc/singleSelect';

const EventsBreadcrumbs = ({updatedTreeData, nodeData, handleOnClick }) => {

    const addChildrenIntoSelectedParams=(type)=> {
        let prvData = Object.assign({}, nodeData);
        if(prvData.routeParams){
            if ( type=== "ROOTNODE" ) {
                if(prvData.routeParams[1].id === updatedTreeData.id) {
                    prvData.routeParams[1].children = updatedTreeData.children
                }
            } else if (type === 'REGION' && prvData.routeParams.length > 2 ) {
                updatedTreeData.children.forEach((item)=> {
                    if(item.tempId === prvData.routeParams[3].tempId) {
                        prvData.routeParams[3].children = item.children
                    }
                })
            } else if(type === 'SUBSTATION' && prvData.routeParams.length > 4 ) {
                updatedTreeData.children.forEach((item)=> {
                    if(item.tempId === prvData.routeParams[3].tempId) {
                        if(item.children) {
                            item.children.forEach((sItem)=> {
                                if(sItem.tempId ===  prvData.routeParams[5].tempId ){
                                    prvData.routeParams[5].children = sItem.children
                                }
                            })
                        }
                    }
                })
            }
        }
    }
    
    const getData = (type) => {
        let data = [];
        let defaultObj = [{ 'label': 'Select', 'value': 'Select' }];
        
        addChildrenIntoSelectedParams(type);   //adding children data to selected node routeParmas

        switch (type) {
            case 'ROOTNODE':
                nodeData && nodeData.routeParams && nodeData.routeParams.length > 1 ? data = [{ 'label': nodeData.routeParams[1].name, 'value': nodeData.routeParams[1].name }] : nodeData && nodeData.name ? data = [{ 'label': nodeData.name, 'value': nodeData.name }] : data = defaultObj;
                break;
            case 'REGION':
                nodeData && nodeData.routeParams && nodeData.routeParams.length > 1 && nodeData.routeParams[1].children && nodeData.routeParams[1].children.length ? data = prepareObj(nodeData.routeParams[1].children) : nodeData && nodeData.children && nodeData.children.length ? data = prepareObj(nodeData.children) : data = defaultObj;
                break;
            case 'SUBSTATION':
                nodeData && nodeData.routeParams && nodeData.routeParams.length > 3 && nodeData.routeParams[3].children && nodeData.routeParams[3].children.length ? data = prepareObj(nodeData.routeParams[3].children) : data = defaultObj;
                break;
            case 'FEEDER':
                nodeData && nodeData.routeParams && nodeData.routeParams.length > 5 && nodeData.routeParams[5].children && nodeData.routeParams[5].children.length ? data = prepareObj(nodeData.routeParams[5].children) : data = defaultObj;
                break;
            default:
                data = defaultObj
                break;
        }
        return data;
    }
    const prepareObj = (children) => {
        let data = [];
        children.forEach(item => { data.push({ 'label': item.name, 'value': item.name }) })
        return data;
    }
    const getValue = (type) => {
        let value = 'Select';
        (nodeData && nodeData.routeParams && nodeData.routeParams.length) ? nodeData.routeParams.forEach(item => { if (item.type && type === item.type) value = item.name; }) : type === 'ROOTNODE' ? value = nodeData.name : value = 'Select';
        return value;
    }

    let nodeList = ['ROOTNODE', 'REGION', 'SUBSTATION', 'FEEDER'];
    return <span className="events-breadcrumb">
        {nodeData && nodeList.map((item, key) => {
            return <span key={key} className='single-select-dropdown option-list mr-2'>
                <SingleSelect
                    data={getData(item)}
                    setType={(value) => handleOnClick(nodeData, value, item)}
                    value={getValue(item)}
                    icon='fa-check' />
            </span>
        })}
    </span>
}

export default EventsBreadcrumbs;
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber';
import ConfigVDprofileTemplateCheckbox from '../mainContent/configureProperties/containers/configVDprofileTemplateCheckbox';
import ConfigVDprofileTemplateClass from '../mainContent/configureProperties/containers/configVDprofileTemplateClass'

export const configPropertiesData = {
    isOpenModal: false,

    formFields: [
        { label: <FormattedMessage id={"configure.title.security"} />, type: 'title', category: 'asecurity', isDisplay : true, data: [
                { label: 'login.failure.attempts', type: 'text'},
                { label: 'login.failure.duration', type: 'text'},
            ]
        },
        { label: <FormattedMessage id={"configure.title.faults"} />, type: 'title', category: 'cfaults',  isDisplay : false, data: [
                { label: 'default.feeder.fault.grouping.time.in.minutes', type: 'text' },
                { label: 'default.timeinseconds.event.classification', type: 'text' },
                { label: 'wait.timeinminutes.before.sending.event.email', type: 'text'  },
                { label: 'faults.eventlog.download.enable', type: 'switch'  },

            ]
        },
        { label: <FormattedMessage id={"configure.title.deviceManagement"} />, type: 'title', category: 'deviceManagement',  isDisplay : false, data: [
                { label: 'device.gps.deviation.tolerance.in.meters', type: 'text' },
                { label: 'online.status.time.in.mins', type: 'text' },
                { label: 'intermittent.status.time.in.mins', type: 'text' },
                { label: 'default.mtf.upload', type: 'switch' },
            ]
        },
        { label: <FormattedMessage id={"configure.title.lateralProtectionFailure"} />, type: 'title', category: 'lateralProtectionFailure',  isDisplay : false, data: [
                { label: 'default.lpf.enable', type: 'switch' },
                { label: 'default.lpf.fault.threshold', type: 'text' },
                { label: 'default.lpf.fsi.enable', type: 'switch' },
                { label: 'default.lpf.fmi.enable', type: 'switch' },
            ]
        },
        { label: <FormattedMessage id={"configure.title.groundFault"} />, type: 'title', category: 'groundFault',  isDisplay : false, data: [
                { label: 'default.gf.enable', type: 'switch' },
            ]
        },
        {
            label: <FormattedMessage id={"configure.title.schedulerTime"} />, type: 'title', category: 'schedulerTime', isDisplay: false, data: [
                { label: 'scheduler.fastpoll', type: 'text' },
                { label: 'scheduler.integritypoll', type: 'text' }
            ]
        },
        {
            label: <FormattedMessage id={"configure.title.transformerSeasonalThreshold"} />, type: 'title', category: 'transformerSeasonalThreshold', isDisplay: false, data: [
                { label: 'default.seasonal.loading.threshold.summer', type: 'text' },
                { label: 'default.seasonal.loading.threshold.winter', type: 'text' }
            ]
        },
        {
            label: 'Virtual Devices' , category: 'virtualDevices', type: 'title', isDisplay: false, isSubLabel : true,
            data: [
                    {
                        label: 'Default Setting' , type: 'title', category: 'vdDefaultSettings', isDisplay: false, data: [
                            { label: 'default.binary.output.spares', type: 'text', mainDataName: 'vdDefaultSettings', subDataName: 'vdPropertyDatas', rulesCategory: 'vdPropertyRules', displayLabel: <FormattedMessage id={"vdm.configform.label.binaryOutputSpares"} />, toolTip: <FormattedMessage id='vdm.tooltip.setting.perPhaseBOS' /> },
                            { label: 'default.binary.input.spares', type: 'text', mainDataName: 'vdDefaultSettings', subDataName: 'vdPropertyDatas', rulesCategory: 'vdPropertyRules', displayLabel: <FormattedMessage id={"vdm.configform.label.binaryInputSpares"} />, toolTip: <FormattedMessage id='vdm.tooltip.setting.perPhaseBIS' /> },
                            { label: 'default.analog.output.spares', type: 'text', mainDataName: 'vdDefaultSettings', subDataName: 'vdPropertyDatas', rulesCategory: 'vdPropertyRules', displayLabel: <FormattedMessage id={"vdm.configform.label.analogOutputSpares"} />, toolTip: <FormattedMessage id='vdm.tooltip.setting.perPhaseAOS' /> },
                            { label: 'default.analog.input.spares', type: 'text', mainDataName: 'vdDefaultSettings', subDataName: 'vdPropertyDatas', rulesCategory: 'vdPropertyRules', displayLabel: <FormattedMessage id={"vdm.configform.label.analogInputSpares"} />, toolTip: <FormattedMessage id='vdm.tooltip.setting.perPhaseAIS' /> },
                            { label: 'default.counter.spares', type: 'text', mainDataName: 'vdDefaultSettings', subDataName: 'vdPropertyDatas', rulesCategory: 'vdPropertyRules', displayLabel: <FormattedMessage id={"vdm.configform.label.counterSpares"} />, toolTip: <FormattedMessage id='vdm.tooltip.setting.perPhaseCS' /> },
                            { label: 'default.offline.timer', type: 'text', mainDataName: 'vdDefaultSettings', subDataName: 'vdPropertyDatas', rulesCategory: 'vdPropertyRules', displayLabel: <FormattedMessage id={"vdm.configform.label.onlinetoOffline"} />, toolTip: <FormattedMessage id='vdm.tooltip.setting.onlineToOfflineState' /> },
                        ]
                    },
                    {
                        label: 'Profile Template' , type: 'title', category: 'profileTemplate', isDisplay: false, data: [
                        { label: 'test1', type: 'text' },
                        ]
                    },
            ],
        },
    ],

    profileTemplateTable: [
        {accessor: 'profileName', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id={"vdm.profileTemplate.profileName"} /> } />, sortedOrder : 0, 
            Cell: row => (
                <>
                    {row.original.isDisplay !== undefined && !row.original.isDisplay ? '' : row.original.profileName }
                </>
            ),
        },
        {accessor: 'selected', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id={"vdm.profileTemplate.selected"} /> } />, sortedOrder : 0, 
            Cell: row => (
                <ConfigVDprofileTemplateCheckbox rowData={row} />
            ),
        },
        {accessor: 'pointName', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id={"vdm.profileTemplate.vdPoint"} /> } />, sortedOrder : 0},
        {accessor: 'pointType', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id={"vdm.profileTemplate.type"} /> } />, sortedOrder : 0},
        {accessor: 'classValue', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id={"vdm.profileTemplate.class"} /> } />, sortedOrder : 0,
            Cell: row => (
                <ConfigVDprofileTemplateClass rowData={row} />
            ),
        }
    ]
}
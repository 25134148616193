import React, {useState} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const TooltipMessage = (props) => {
    const [value, setValue] = useState(false);
    const handleOnEnter =()=>{
        setValue(true)
    }
    const handleOnHide=()=>{
        setValue(false)
    }
    return (
        <OverlayTrigger
            placement= {props.placement}
            onEnter={handleOnEnter}
            onExit={handleOnHide}
            overlay={
                <Tooltip className={value ? "tooltip d-block" : "tooltip d-none"} >
                    <span>{props.message}</span>
                </Tooltip>
            }
        >
            <span>{props.baseContent}</span>
        </OverlayTrigger>
    )
}

export default TooltipMessage;

// placement = 'top', 'right', 'bottom', 'left

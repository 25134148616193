import React, { Component } from 'react';
import { Dropdown, DropdownItem } from 'react-bootstrap';
import { NotificationTemplateContext } from '../provider/notificationTemplateProvider';

class TagVariablesComponent extends Component {
    static contextType = NotificationTemplateContext;

    render() {
        return (
            <Dropdown className="tag-variable-container" >
                {this.context.state.formData.placeHolders.map((data, key) => {
                    return <DropdownItem key={key} onClick={() => this.context.handleTagVariables(this.props.fieldName, data.place_holder)}>{data.label} ({data.place_holder})</DropdownItem>
                })}
            </Dropdown>
        );
    }
}

export default TagVariablesComponent;
import React from 'react';
import DGWContainer from './containers/dgwContainer';
import { Row, Col, Container } from 'react-bootstrap';
import './index.css';
import DGWProvider from './provider/dgwProvider';

const DeviceGateway = () => {
    return (
        <Container fluid className='dgw-container'>
            <Row>
                <Col className="p-0 m-0">
                    <DGWProvider>
                        <div><DGWContainer /></div>
                    </DGWProvider>
                </Col>
            </Row>
        </Container>
    );
}

export default DeviceGateway;
import React, { Component} from 'react';
import { Col } from 'react-bootstrap';
import Loader from '../../../hoc/loader';
import NoDataAvailable from '../../../hoc/noDataAvailableComponent';

import { MonitorContext } from '../provider/monitorProvider';
import MonitorFilter from '../components/monitorFilter';
import MonitorView from '../components/monitorView';

class MonitorContainer extends Component {

  static contextType = MonitorContext;
  
  render() {
    let LoaderData = (this.context.state.loading && !this.context.state.graphData.length) ? <div className="loader-wrap"><Loader elementClass="device-management-ajax-loader" /></div> : null
    return (
      <React.Fragment>
        {LoaderData}
        <Col className="content-right pad-rgt-lef mt-3 m-0 p-0 pt-3" xs={12} md={12}>
          <MonitorFilter />
          {
            (!this.context.state.loading && this.context.state.emptyGraphData) ? <NoDataAvailable/> : <MonitorView />
          }
        </Col>
      </React.Fragment>
    );
  }
}


export default MonitorContainer;
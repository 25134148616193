import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import NotificationTemplate from '../../mainContent/notificationTemplate/index';
import {notificationTemplatesData} from '../../utility/notificationTemplateUtility'

class OpenNotificationTemplateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false
        }
    }

    handleOnCLick = () => {
        notificationTemplatesData.isOpenModal = true;
        this.setState({ isOpenModal: true })
    }

    render() {
        return (
            <React.Fragment>
                <div onClick={this.handleOnCLick} className="noNavigationItemLink">
                    <FormattedMessage id={`nav.main.notificationtemplates`} />
                </div>
                <div>
                    {notificationTemplatesData.isOpenModal ? <NotificationTemplate /> : ""}
                </div>
            </React.Fragment>
        );
    }
}

export default OpenNotificationTemplateModal;
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const navigationItem = (props) => {
    let componentToRender = (
        <Nav.Item>
            <LinkContainer to={props.isDisabled ? "/licenseManagement" : props.link} activeClassName={!props.isDisabled && props.menuType === 'mainMenu' ? 'activeClass' : 'active'}>
                <Nav.Link active={false} className={`${props.extraClass}` + (props.isDisabled ? ' disabledClass op-half cursor-not-allowed pointernone' : "")} href="javascript.void(0)">
                    {props.children}
                </Nav.Link>
            </LinkContainer>
        </Nav.Item >)

    return (
        <>
            {props.menuType && props.isDisabled ?
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="mainMenu"><FormattedMessage id='groupmanagement.menus.tooltip' /></Tooltip>}>
                    {componentToRender}
                </OverlayTrigger> :
                <>{componentToRender}</>
            }
        </>

    )
}

export default withRouter(navigationItem)
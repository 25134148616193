import React, { Component, Fragment } from 'react';
import EventPrecursorPicker from './eventPrecursorPicker';
import { Form, FormGroup, Row, Col } from 'react-bootstrap';
import { EventPrecursorContext } from '../provider/eventPrecursorProvider';
import { FormattedMessage } from 'react-intl';
import TimeFilter from '../../../../hoc/timeFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopOverMessage from '../../../../hoc/popOver';
import { faQuestionCircle,faExclamationTriangle,faShareSquare,faCircle } from '@fortawesome/free-solid-svg-icons';
import FilterWithBadge from './filterWithBadge';
import MultiSelectDropdown from '../../../manage/details/components/multiselectDropdownComponent';
import SingleSelect from '../../../../hoc/singleSelect';
import { eventPrecursorData } from '../../../../utility/eventPrecursorUtility';
// import { faShareSquare } from '@fortawesome/free-solid-svg-icons';

class EventPrecursorFilter extends Component {
    static contextType = EventPrecursorContext;
    render() {
        let { zoomRange, selectedZoomRange, startTimestamp, endTimestamp, filtersSection, sitesList, exportOptions, exportLogi, disableExport, roleAndPermission, maxDate } = this.context.state;
        return (
            <Fragment>
                <Form>
                    <FormGroup key="filter-1" className="clearfix m-0 p-2 border-1px d-flex align-items-center" xs={12} md={12}>
                        <span className="float-left col-form-label p-0">
                            <span className="mt-1">&nbsp;&nbsp;<FormattedMessage id={`common.label.zoom`} /> &nbsp;</span>
                            <TimeFilter data={zoomRange} handleOnClick={this.context.onZoomOnChange} selected={selectedZoomRange} />
                        </span>
                        <span className="float-left col-form-label p-0 ml-2">
                            <EventPrecursorPicker
                                timezone={roleAndPermission.timezone}
                                maxDate={endTimestamp}
                                startDate={startTimestamp}
                                endDate={endTimestamp}
                                onCallback={(event, picker) => this.context.onCalenderCallback(event, picker)}
                            />
                        </span>
                        <span className='ml-auto d-flex align-items-center'>
                            <span className='single-select-dropdown export-content mr-2'>
                                <SingleSelect
                                    data={eventPrecursorData.exportData}
                                    setType={this.context.downloadData}
                                    value={<FontAwesomeIcon icon={faShareSquare} color={this.context.state.eventPreCursorTableData.length === 0 ? '#ccc' : ''} />}
                                    isDisplayLabel={false}
                                    disabled={this.context.state.eventPreCursorTableData.length === 0}
                                />
                            </span>
                            <span>
                                <FilterWithBadge
                                    dropDownContent={
                                        <Row className='mb-2'>
                                            <Col xs={5}><FormattedMessage id={`events.eventPrecursor.column.escalationType`} /></Col>
                                            <Col xs={7}>
                                                <MultiSelectDropdown
                                                    selectedArrayList={this.context.state.escalationTypeDD.selected}
                                                    optionList={this.context.state.escalationTypeDD.options}
                                                    onChange={(e) => { this.context.onValChange(e) }}
                                                    name='escalationType'
                                                    multiple={true}
                                                    includeSelectAll={false}
                                                    includeFilter={false}
                                                    open={false}
                                                    valueKey="value"
                                                    labelKey="text"
                                                    keepOpen={true}
                                                    placeholder="Select"
                                                    manySelectedPlaceholder={`%s/${this.context.state.escalationTypeDD.options.length} Checked, ... `}
                                                    allSelectedPlaceholder={`%s/${this.context.state.escalationTypeDD.options.length} Checked, ... `}
                                                    handleShowAllBtn={() => { this.context.onValChange([]) }}
                                                    btmLabel={"Show All"}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    badgeNo={this.context.state.totalBadgeCount}
                                    handleClickFilterApply={this.context.onHandleClickFilterApply}
                                    handleClickFilterReset={this.context.onHandleClickFilterReset}
                                />
                            </span>
                            <span className="event-precursor-helptext" onClick={(e) => e.preventDefault()}> <PopOverMessage trigger="focus" keyVal={`event-precursor-helptext`} placement="left" popoverBody={
                                <div className="m-3"><strong><FormattedMessage id={`events.eventPrecursor.helpText.heading`} /></strong><br /><br /><FormattedMessage id={`events.eventPrecursor.helpText.body`} /><br /><br /><FontAwesomeIcon icon={faCircle} size="sm" style={{ 'color': '#508D4E' }} /> <strong><FormattedMessage id={`events.eventPrecursor.helpText.body.good`} /> </strong><FormattedMessage id={`events.eventPrecursor.helpText.body.good.content`} /><br /><br /><FontAwesomeIcon icon={faExclamationTriangle} size="sm" style={{ 'color': '#0288d1' }} /> <strong><FormattedMessage id={`events.eventPrecursor.helpText.body.watch`} /> </strong><FormattedMessage id={`events.eventPrecursor.helpText.body.watch.content`} /><br /><br /><FontAwesomeIcon icon={faExclamationTriangle} size="sm" style={{ 'color': '#ffca28' }} /> <strong><FormattedMessage id={`events.eventPrecursor.helpText.body.warning`} /> </strong><FormattedMessage id={`events.eventPrecursor.helpText.body.warning.content`} /><br /><br /><FontAwesomeIcon icon={faExclamationTriangle} size="sm" style={{ 'color': '#e20f00' }} /> <strong><FormattedMessage id={`events.eventPrecursor.helpText.body.alert`} /> </strong><FormattedMessage id={`events.eventPrecursor.helpText.body.alert.content`} /></div>
                            } displayPopOver={
                                <FontAwesomeIcon icon={faQuestionCircle} size="lg" onClick={(e) => e.preventDefault()} />
                            } /></span>
                        </span>
                    </FormGroup>
                </Form>
            </Fragment>
        )
    }
}
export default EventPrecursorFilter;
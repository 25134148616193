import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faRetweet} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import {VirtualDeviceContext} from '../../provider/virtualDeviceProvider';
import TreeViewWithCheckboxSiteLevel from '../../../../../hoc/treeViewWithCheckboxSiteLevel';
import Tooltip from '../../../../../hoc/tooltip';
import AddOrEditVirtualDeviceConfig from './addOrEditVirtualDeviceConfig';
import AddorEditProfileTemplate from './addOrEditProfileTemplate';
import Loader from '../../../../../hoc/loader';
import Alert from '../../../../../hoc/alert';
import { FormattedMessage } from 'react-intl';
import BlankPage from '../../../../../hoc/blankPage'

const AddVirtualDevice=()=>{
    const contextData = useContext(VirtualDeviceContext);
    const [maxPointCount] = useState(65000)

    const receiveUpdatedData = (selectedData, treeData) => {
        contextData.saveTreedata(treeData, selectedData)
    }

    const addTopSection=(label, section, tooltipMsg)=>{
        return <div className='content-top'>
            <FontAwesomeIcon icon={faCopy} size="lg" /> &nbsp; {label}
            { section !== '' && (contextData.state.isErrorOnValidation || !contextData.state.isClickedContinueBtn) && contextData.state.clickedType !== 'View' && contextData.state.clickedType !== 'Edit' && <span className='float-right'>
                <Tooltip baseContent={
                <Button variant="outline-secondary" size="sm" onClick={ ()=> contextData.handleClickResetButton(section) } ><FontAwesomeIcon icon={faRetweet} size="sm" /></Button>
                } message={tooltipMsg} placement={'top'} />
            </span>}
        </div>
    }

    return (
            <div className='add-virtual-device'>
                 {contextData.state.clickedType === 'View' ? '' : 
                  <Row className='pt-2 pb-3 d-flex'>
                    <Col>
                       {contextData.state.alertMessage.for ==='createOrUpdateError' && contextData.state.alertMessage.type ==='danger' &&  
                        <Alert type={'danger'} message={contextData.state.alertMessage?.message} setVisible={()=>contextData.closeAlertMessage()} position={'position-center'} timer={10000}/>
                       }
                      {contextData.state.clickedType} <FormattedMessage id={"vdm.name.virtualDevice"} /> {contextData.state.clickedType === 'Edit' && <> - {contextData.state.clickedRowData.name} </>}
                    </Col>
                  </Row>}
                 
                 {contextData.state.isLoadingConfirmLoader && <Loader elementClass="tree-ajax-loader center-element" /> }
                
                <Row className='add-edit-device-container'>
                    <Col xs={3}>
                        {addTopSection(<FormattedMessage id={"vdm.config.vdConfiguration"} />, 'globalSettings', <FormattedMessage id={"vdm.tooltip.resetDeviceConfiguration"} /> )}
                        <div className='section-content-body'>

                          {contextData.state.isloadingGetDefaultData ? <Loader elementClass="tree-ajax-loader center-element" /> :
                            Object.keys(contextData.state.mainFormData?.vdDefaultSettings).length ? <AddOrEditVirtualDeviceConfig /> : <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                          }

                        </div>
                    </Col>
                    <Col xs={3}>
                        {addTopSection(<FormattedMessage id={"vdm.config.vdSiteSelection"} />, '', '')}
                        <div className='section-content-body'>
                            {contextData.state.mainFormDataErr.siteSelectionErr?.isErr && <Row className='pl-4 pt-3 error-color'><Col>{contextData.state.mainFormDataErr.siteSelectionErr?.errMsg}</Col></Row>}
                            <Row className={ contextData.state.mainFormDataErr.siteSelectionErr?.isErr ? 'pt-2' : 'pt-4'}>
                                <Col className='vd-tree-view'>
                                    <TreeViewWithCheckboxSiteLevel
                                        passUpdatedData={(data, treeData)=>receiveUpdatedData(data, treeData)}
                                        expandUpto={"LATERAL_SITE"} //display up to
                                        checkedLableName={"subscribedForVirtualDevice"} //for checkbox true or false by this value
                                        from={'vertualDeviceManagement'} //for api call
                                        checkBoxVisible={'all'} // visible checkbox level
                                        resData = {[]}
                                        isAvailaTreeData={contextData.state.treeData}
                                        clickedType={contextData.state.clickedType}
                                        rowdata={contextData.state.clickedRowData}
                                        isDisabledCheckBox={(contextData.state.isErrorOnValidation || !contextData.state.isClickedContinueBtn) && contextData.state.clickedType !== 'View' ? false : true}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col>
                    
                        {addTopSection(<FormattedMessage id={"vdm.config.vdProfileAssignment"} /> , 'profilePoints', <FormattedMessage id={"vdm.tooltip.resetProfileAssignment"} /> )}
                        <div className='section-content-body'>
                          
                          {contextData.state.isloadingGetDefaultData ? <Loader elementClass="tree-ajax-loader center-element" /> : 
                            contextData.state.mainFormData?.vdProfileSettings.length ? <AddorEditProfileTemplate /> : <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                          }

                        </div>
                    </Col>
                </Row>
                
                {(contextData.state.isErrorOnValidation || !contextData.state.isClickedContinueBtn) && contextData.state.clickedType !== 'View' ? '' :
                  <Row className='point-count-section'>
                      <Col>
                        <Row> {contextData.state.isloadingDevicePointData && <Loader elementClass="tree-ajax-loader center-element" /> }
                          <Col className='text-center device-count-section'> 
                            <FormattedMessage id={"db.bubbleChart.deviceCount"} />: {contextData.state.devicePoint.devices} &nbsp;&nbsp;&nbsp;&nbsp; <FormattedMessage id={"vdm.table.column.pointCount"} /> : {contextData.state.devicePoint.points}
                            {contextData.state.devicePoint.points > maxPointCount && <div className='error-color font-weight-normal'><FormattedMessage id={"vdm.validation.maxPointCount"} /></div>}
                          </Col>
                        </Row>
                      </Col>
                  </Row>
                }

                {/* need to show below line if points are uploaded from file and uploaded status is true */}
                {contextData.state.clickedRowData?.uploaded && <FormattedMessage id={"vdm.view.modal.displayingPointDataFromtheUploadedFile"} /> }

                <Row className={contextData.state.clickedType !== 'View' && 'pt-4 pb-4 mb-2'}>
                    <Col className='text-right'>
                         {contextData.state.clickedType === 'View' ? '' :
                            <>
                              <Button variant="outline-secondary" className='modalCancelBtn' onClick={() => contextData.handleAddVirtualDevice(false, 'cancel')}> <FormattedMessage id={"dm.column.cancel"} /> </Button> &nbsp;&nbsp;
                              {contextData.state.isErrorOnValidation || !contextData.state.isClickedContinueBtn  ?
                              <>
                                  <Button className='modalSaveBtn' variant="outline-secondary" onClick={()=>contextData.handleClickContinue(true, 'All' )}><FormattedMessage id={"db.common.continue"} /></Button>  &nbsp;&nbsp;
                              </>
                            : 
                              <>
                                { contextData.state.devicePoint && contextData.state.devicePoint.recreate === 'true' &&  
                                    <span className='position-center'><span className='point-info-msg'> <FormattedMessage id={"vdm.info.willReCreatePointMap"} /></span></span>}
                                  <Button variant="outline-secondary" className='modalCancelBtn' onClick={()=>contextData.handleClickContinue(false, '', 'editback')}><FormattedMessage id={"groupmanagement.context.edit"} /></Button>&nbsp;&nbsp;
                                  <Button className='modalSaveBtn' disabled={contextData.state.devicePoint.points > maxPointCount} variant="outline-secondary" onClick={()=>contextData.handleClickConfirm('confirm')}><FormattedMessage id={"deviceStateChange.popup.button.confirm"} /></Button>&nbsp;&nbsp;
                              </>
                            }
                        </> }
                    </Col>
                </Row>
            </div>
    )
}

export default AddVirtualDevice;
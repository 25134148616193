import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../../../hoc/modelWindow';
import { ManageSchedulesContext } from '../provider/manageSchedulesProvider';

class ManageScheduleModal extends Component {
    static contextType = ManageSchedulesContext;
    onBtnClickHandler = (value) => {
        return value === "ok" ? this.context.onDeleteReportClickHandler() :
            value === "cancel" ? this.context.onCloseModal() : ""
    }
    render() {
        return (
            <Row>
                <ModalWindow
                    show={this.context.state.isModalOpen}
                    size="md"
                    title={<FormattedMessage id="customReports.manageSchedule.delete.title" />}
                    onBtnClickHandler={this.onBtnClickHandler}
                    onHide={() => this.context.onCloseModal()}
                    modeldata={{
                        content: <FormattedMessage id="customReports.manageSchedule.delete.message" />
                    }}
                    footer={
                        [
                            { className: "modalCancelBtn", name: <FormattedMessage id="dm.column.cancel" />, value: "cancel" },
                            { className: "modalSaveBtn", name: <FormattedMessage id="deleteDevice.popup.button.ok" />, value: "ok" }
                        ]
                    }
                />
            </Row>
        )
    }
}
export default ManageScheduleModal;
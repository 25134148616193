import React, { Component, createContext } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { summaryFilterData } from '../../../../utility/eventsSummaryUtility';
import { getBrowserTimeZone, getNodePath } from '../../../../services/utilityService';
import { getSummaryData, exportData } from '../services/summaryServices';
import { setDownloadCSV } from '../../../../filters/commonFunction';

export const SummaryContext = createContext()

class SummaryProvider extends Component {
    state = {
        treeNode: this.props.treeNode,
        loading: false,
        faultData: [],
        roleAndPermission: this.props.roleAndPermission,
        startDate: summaryFilterData.startDate ? summaryFilterData.startDate : summaryFilterData.endDate - ((30 * 1000 * 3600 * 24) - 1),
        endDate: summaryFilterData.endDate,
        groupBy: summaryFilterData.groupBy,
        maxCount: summaryFilterData.maxCount,
        notificationCount: summaryFilterData.notificationCount,
        filteredData: []
    }

    fetchSummaryData = async (treeNode) => {
        if (!treeNode) {
            treeNode = this.props.treeNode
        }
        if (treeNode.type.indexOf("FEEDER") === -1) {
            this.setState({ loading: true });
            const timezone = getBrowserTimeZone();
            const endDate = summaryFilterData.endDate;
            const startDate = summaryFilterData.startDate ? summaryFilterData.startDate : endDate - ((30 * 1000 * 3600 * 24) - 1);
            const groupBy = summaryFilterData.groupByList.filter(e => e.text === this.state.groupBy)[0].value;
            const routeParams = treeNode.type === "ROOTNODE" ? ["ROOTNODE", treeNode.id] : treeNode.routeParams;
            const params = { 'NODEPATH': getNodePath(routeParams, false, true), "STARTDATE": startDate, "ENDDATE": endDate, "TIMEZONE": timezone, "GROUPBY": groupBy, 'apiType': 'data' };
            getSummaryData(params)
                .then(data => {
                    if (data) {
                        this.setState({
                            loading: false,
                            faultData: data,
                            startDate: startDate,
                            endDate: endDate
                        });
                    } else {
                        this.setState({
                            loading: false,
                            faultData: [],
                            startDate: startDate,
                            endDate: endDate
                        });
                    }
                })
        }
    }
    calenderCallback = (start, end) => {
        summaryFilterData.startDate = moment(start).valueOf();
        summaryFilterData.endDate = moment(end).valueOf()
        this.fetchSummaryData();
    }
    handleGroupBy = (selectedData) => {
        this.setState({ groupBy: selectedData.text })
    }
    onChangeHandler = (value) => {
        const re = /^[0-9.]+$/;
        if (value === "" || re.test(value))
            this.setState({ maxCount: value })
    }
    handleMaxCountsApply = () => {
        this.setState({ loading: true });
        const data = [];
        let notificationCount = 0;
        if (!isNaN(Number(this.state.maxCount)) && this.state.maxCount) {
            notificationCount = notificationCount + 1;
            let isGreater = false;
            this.state.faultData.forEach(dt => {
                const val = Object.values(dt);
                isGreater = false;
                for (let i = 0; i < val.length; i++) {
                    if (!isNaN(Number(val[i])) && val[i] && (val[i] > this.state.maxCount)) {
                        isGreater = true;
                        break;
                    }
                }
                if (!isGreater) data.push(dt);
            });
            this.setState({ filteredData: data, loading: false, notificationCount: notificationCount });
        } else {
            this.setState({ filteredData: this.state.faultData, loading: false, notificationCount: notificationCount })
        }
        summaryFilterData.notificationCount = notificationCount;
        summaryFilterData.maxCount = this.state.maxCount;
    }
    handleMaxCountsReset = () => {
        this.setState({ notificationCount: 0, maxCount: '' }, () => {
            this.handleMaxCountsApply();
        });
    }
    handleExportData = (type) => {
        this.setState({ loading: true }, () => {
            const routeParams = this.state.treeNode.routeParams ? this.state.treeNode.routeParams : ["ROOTNODE", this.state.treeNode.id];
            const groupBy = summaryFilterData.groupByList.filter(e => e.text === this.state.groupBy)[0].value;
            const requestParams = {
                'FORMAT': type,
                'TIMEZONE': getBrowserTimeZone(),
                'NODEPATH': getNodePath(routeParams, false, true),
                'STARTDATE': this.state.startDate,
                'ENDDATE': this.state.endDate,
                'GROUPBY': groupBy,
                'apiType': 'data'
            }
            exportData(requestParams)
                .then((data) => {
                    setDownloadCSV(data);
                    this.setState({ loading: false });
                        
                }).catch((err) => this.setState({ loading: false }))
        })
    }
    componentDidMount = async () => {
        if (this.state.treeNode && Object.keys(this.state.treeNode).length > 0 && (!this.props.treeLoader)) {
            !this.props.dashboardFlag && this.fetchSummaryData(this.state.treeNode);
        }
    };
    componentDidUpdate(prevProps) {
        if ((prevProps.treeNode && this.props.treeNode) || (prevProps.treeNode === undefined && this.props.treeNode)) {
            if (((prevProps.treeNode === undefined && this.props.treeNode) || (prevProps.treeNode['name'] !== this.props.treeNode['name']) || (prevProps.treeNode['type'] !== this.props.treeNode['type']) || (prevProps.treeNode["id"] !== this.props.treeNode["id"]))) {
                this.setState({
                    treeNode: this.props.treeNode,
                    loading: false
                }, () => {
                    !this.props.dashboardFlag && this.fetchSummaryData(this.props.treeNode);
                });
            }
        }
    }
    componentWillUnmount() {
        this.props.dashboardFlag && this.props.setDashboardFlag()
    }

    render() {
        return (
            <SummaryContext.Provider value={{
                state: this.state,
                fetchSummaryData: this.fetchSummaryData,
                onCalenderCallback: this.calenderCallback,
                handleGroupBy: this.handleGroupBy,
                handleExportData: this.handleExportData,
                onChangeHandler: this.onChangeHandler,
                handleMaxCountsApply: this.handleMaxCountsApply,
                handleMaxCountsReset: this.handleMaxCountsReset
            }}>
                {this.props.children}
            </SummaryContext.Provider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default connect(mapStateToProps, null)(SummaryProvider);

export const SummaryConsumer = SummaryContext.Consumer;
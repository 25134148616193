import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import JobListContainer from './containers/jobListContainer';
import JobDetailsContainer from './containers/jobDetailsContainer';
import ConfigStatusProvider from './provider/configStatusProvider';
import '../otapstatus/otapstatus.css';
import './configStatus.css';

class ConfigStatus extends Component {

    render() {
        return (
            <ConfigStatusProvider>
                <Container fluid className="currentJobs">
                    <Row>
                        <Col xs={2} className="jobs-list">
                            <JobListContainer />
                        </Col>
                        <Col xs={10} className="Details">
                            <JobDetailsContainer />
                        </Col>
                    </Row>
                </Container>
            </ConfigStatusProvider>
        );
    }
}

export default ConfigStatus;
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber'

export const summaryFilterData = {
    startDate: '',
    endDate: new Date().setHours(23, 59, 59, 999),
    groupByList: [
        {
            value: 'Feeder',
            text: 'Feeder'
        },
        {
            value: 'UpStreamFeederSite',
            text: 'Feeder UpStream Site'
        },
        {
            value: 'UpStreamFeederAndLateralSite',
            text: 'Feeder + Lateral UpStream Site'
        }
    ],
    groupBy: 'Feeder',
    maxCount: '',
    notificationCount: 0,
    exportData: [{ label: <FormattedMessage id='common.text.download.excel' />, value: 'excel' }, { label: <FormattedMessage id='common.text.download.csv' />, value: 'csv' }],
}

function getHeaderName(original) {
    return <TableMultiSortNumber column={original} label={<FormattedMessage id={`events.summary.colum.header.${original.column.id}`} />} />
}

const accessorValues = ['region', 'substation', 'feeder', 'lateral', 'upStreamSite', 'faultSustainedInterruption', 'faultMomentaryInterruption', 'faultNoInterruption', 'noFaultSustainedInterruption', 'noFaultMomentaryInterruption']

const tableColumnsArr = accessorValues.map(e => {
    return ({
        Header: getHeaderName,
        accessor: e,
        isDisplay: true,
        className: e === "substation" || e === "feeder" ? 'clm-style' : "",
        sortedOrder: 0, //For displaying Sorted Order
    })
});


export const tableOptions = {
    tableColumns: tableColumnsArr
}
export const configStatusCheck = (device, key) => {
    return ((device[key] === 'In Progress') || (device[key] === "INPROGRESS") || (device[key] === "REQUEST_QUEUED") || (device[key] === "IN_PROGRESS") || (device[key] === "PENDING")) ? true : false;
}

export const otapStatusCheck = (device, jobStatusKey, otapStatusKey) => {
    return ((device[otapStatusKey] === 'SCHEDULED') || (device[otapStatusKey] === 'PENDING') || (device[otapStatusKey] === 'INPROGRESS') || (device[otapStatusKey] === 'REQUEST_QUEUED') || (device[otapStatusKey] === 'UNRELIABLE_CONNECTION' && device[jobStatusKey] !== 'FAILED') || (device[otapStatusKey] === 'INSUFFICIENT_ENERGY') || (device[otapStatusKey] === 'In Progress')) ? true : false;
}

export const deviceStatusCheck = (device, key) => {
    return (device[key] === "UNREGISTERED") ? true : false;
}
import React, { Component } from "react";
import ResetPasswordForm from '../components/resetPasswordComponent';
import Footer from '../../footer';
import { validateResetPasswordToken, resetPassword, forceResetPassword } from '../../home/services/homeService';
import { FormattedMessage } from 'react-intl';

class ResetPasswordView extends Component {

  state = {
    submited: false,
    isLoading: false,
    forceReset: false,
    forceResetMessage: false,
    passwordsNotMatch: false,
    resetPasswordSuccess: true,
    validatingToken: true,
    forced: false,
    validationMessage: "",
    userInfoObj: { newPassword: "", confirmPassword: "", currentPassword: "" },
    statusMessage: { type: '', message: '' },
  };

  componentDidMount() {
    this.setState({forced : new URLSearchParams(window.location.search).get('forced') || false},()=>{
      this.initLoad();
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({forced : new URLSearchParams(window.location.search).get('forced') || false},()=>{
        this.initLoad();
      })
    }
  }

  initLoad = () => {
    if (this.state.forced) {
      this.setState({ forceReset: true, forceResetMessage: <FormattedMessage id='resetpassword.forcereset' /> ,validatingToken: false});
    } else {
      this.setState({ forceReset: false, forceResetMessage: false });
      this.validateToken();
    }
  }

  validateToken = () => {
    const id = new URLSearchParams(window.location.search).get('id') || '';
    const token = new URLSearchParams(window.location.search).get('token') || '';
    this.setState({ isLoading: true }, () => {
      validateResetPasswordToken(id, token).then(successResp => {
        this.setState({ validatingToken: false,isLoading: false });
        if (successResp.data.status !== 'OK') this.setState({ validationMessage: successResp.data.message});
      })
    })
  }

  isValidForm = () => {
    if (this.state.userInfoObj.currentPassword.length > 0 && this.state.userInfoObj.newPassword.length >= 8 &&
      this.state.userInfoObj.newPassword.length <= 20 && this.testValidation(/^[a-zA-Z0-9]*(?=.*[!@#$%^&+=]).*$/, this.state.userInfoObj.newPassword) &&
      this.state.userInfoObj.confirmPassword.length > 0) {
      return true;
    }else if(this.state.userInfoObj.newPassword.length >= 8 &&
      this.state.userInfoObj.newPassword.length <= 20 && this.testValidation(/^[a-zA-Z0-9]*(?=.*[!@#$%^&+=]).*$/, this.state.userInfoObj.newPassword) &&
      this.state.userInfoObj.confirmPassword.length > 0){
        return true;
    } else {
      return false;
    }
  }

  testValidation = (regex, value) => regex.test(value)

  // change / update newPassword, currentPassword, confirmPassword fields
  changePassword = (evt, type) => {
    const obj = {...this.state.userInfoObj};
    obj[type] = evt;
    this.setState({ userInfoObj: obj },()=>{
      if(this.state.userInfoObj.newPassword !== this.state.userInfoObj.confirmPassword)
      this.setState({ passwordsNotMatch: true }) 
      else this.setState({ passwordsNotMatch: false });
    })
  }

  // reset password
  resetPassword = () => {
    if (this.state.forced) {
      this.forceResetPassword();
    } else {
      this.setState({ submited: true }, () => {
        if (this.isValidForm()) {
          // Check for password match
          if (this.state.userInfoObj.newPassword !== this.state.userInfoObj.confirmPassword) {
            this.setState({ passwordsNotMatch: true });
          } else {
            // send reset password email
            const params = { password: this.state.userInfoObj.newPassword, token: new URLSearchParams(window.location.search).get('token') || '' };
            const id = new URLSearchParams(window.location.search).get('id') || '';
            this.setState({ isLoading: true }, () => {
              resetPassword(id, params).then((successResp) => {
                this.setState({ isLoading: false, forceResetMessage: false });
                if (successResp.data.status === 'OK') {
                  this.setState({
                    resetPasswordSuccess: false,
                    submited: false,
                    passwordsNotMatch: false,
                    userInfoObj: { newPassword: '', confirmPassword: '' },
                    statusMessage: { type: 'success', message: <FormattedMessage id='resetpassword.update.success' /> }
                  });
                } else if (successResp.data.status === 'FAIL') {
                  this.setState({ statusMessage: { type: 'danger', message: successResp.data.message } });
                } else {
                  this.setState({ statusMessage: { type: 'danger', message: successResp.data.message ? successResp.data.message : <FormattedMessage id='common.error.server' /> } })
                }
              });
            })
          }
        }
      })
    }
  }

  // force reset password
  forceResetPassword = () => {
    this.setState({ submited: true }, () => {
      const id = new URLSearchParams(window.location.search).get('id') || '';
      if (this.isValidForm()) {
        // Check for password match
        if (this.state.userInfoObj.newPassword !== this.state.userInfoObj.confirmPassword) {
          this.setState({ passwordsNotMatch: true });
        } else {
          // send reset password email
          var params = { newPassword: this.state.userInfoObj.newPassword, confirmNewPassword: this.state.userInfoObj.confirmPassword, currentPassword: this.state.userInfoObj.currentPassword, principalId: id };
          this.setState({ isLoading: true }, () => {
            forceResetPassword(params).then((successResp) => {
              this.setState({ isLoading: false, forceResetMessage: false });
              if (successResp.data.status === 'OK') {
                this.setState({
                  resetPasswordSuccess: false,
                  submited: false,
                  passwordsNotMatch: false,
                  userInfoObj: { newPassword: '', confirmPassword: '', currentPassword: '' },
                  statusMessage: { type: 'success', message: <FormattedMessage id='resetpassword.update.success' /> }
                });
              } else if (successResp.data.status === 'FAIL') {
                this.setState({ statusMessage: { type: 'danger', message: successResp.data.message } });
              } else {
                this.setState({ statusMessage: { type: 'danger', message: successResp.data.message ? successResp.data.message : <FormattedMessage id='common.error.server' /> } })
              }
            });
          })
        }
      }

    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="login-view">
          <div className="login-content">
            <div className="login-wrap">
              <ResetPasswordForm
                stateObj={this.state}
                testValidation={this.testValidation}
                onResetPassword={() => this.resetPassword()} 
                onChangePassword={this.changePassword}/>
            </div>
          </div>
        </div>
        <div className="login-footer">
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default ResetPasswordView;
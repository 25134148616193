import React from 'react';
import { FormattedMessage } from 'react-intl';
import OtapStatusModal from '../mainContent/manage/otapstatus/components/otapStatusModal';
import { getDateConvertedString } from '../services/utilityService';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber';
import { getTempUnit } from '../services/utilityService';
import Tooltip from '../hoc/tooltip';
import { Row, Col } from 'react-bootstrap';

function um3PositionData(row, type) {
    return (<div>{row.deviceType === 'UM3+' ?
        (row.positions.sort((a, b) => a.position - b.position)).map((item, key) => {
            return <Row key={key}>
                <Col><span className={item[type] ? '' : 'visibile-hide'}>{item[type] ? item[type] : '-'}</span></Col>
            </Row> 
        }) : <span>{row[type]}</span>}</div>);
}

export const tableOptions = {

    selectedRows: [],
    enableAbortBtn: false,
    enableRetryBtn: false,
    enableRetryAllBtn: false,

    columnsData: [
        { accessor: 'regionName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.regionName' />} />, sortedOrder: 0 },
        { accessor: 'substationName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.substationName' />} />, sortedOrder: 0 },
        { accessor: 'feederName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.feederName' />} />, sortedOrder: 0 },
        { accessor: 'lateralName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.lateralName' />} />, sortedOrder: 0 },
        { accessor: 'siteName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.siteName' />} />, sortedOrder: 0 },
        { accessor: 'serialNumber', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.serialNumber' />} />, sortedOrder: 0 },
        { accessor: 'deviceStatus', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.deviceStatus' />} />, sortedOrder: 0 },
        { accessor: 'softwareVersion', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.softwareVersion' />} />, sortedOrder: 0 },
        { accessor: 'jobStatus', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.jobStatus' />} />, sortedOrder: 0 },
        { accessor: 'otapStatus', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.otapStatus' />} />, sortedOrder: 0, Cell: (row) => <OtapStatusModal data={row.original} /> },
        { accessor: 'deviceType', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.deviceType' />} />, sortedOrder: 0 },
        { accessor: 'lastModifiedTime', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.lastModifiedTime' />} />, sortedOrder: 0, Cell: (row) => getDateConvertedString(row.original.lastModifiedTime) },
        { accessor: 'ipAddress', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.ipAddress' />} />, sortedOrder: 0 }
    ],

    ConfigStatusColumnsData: [
        { accessor: 'region', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.regionName' />} />, sortedOrder: 0 },
        { accessor: 'substation', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.substationName' />} />, sortedOrder: 0 },
        { accessor: 'feeder', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.feederName' />} />, sortedOrder: 0, Cell: (row) => um3PositionData(row.original, 'feeder') },
        { accessor: 'lateral', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.lateralName' />} />, sortedOrder: 0, Cell: (row) => um3PositionData(row.original, 'lateral') },
        { accessor: 'site', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.siteName' />} />, sortedOrder: 0, Cell: (row) => um3PositionData(row.original, 'site') },
        { accessor: 'serialNumber', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.serialNumber' />} />, sortedOrder: 0 },
        { accessor: 'deviceStatus', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.deviceStatus' />} />, sortedOrder: 0 },
        { accessor: 'softwareVersion', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.softwareVersion' />} />, sortedOrder: 0 },
        { accessor: 'jobStatus', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.jobStatus' />} />, sortedOrder: 0 },
        { accessor: 'deviceType', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.deviceType' />} />, sortedOrder: 0 },
        { accessor: 'lastModifiedTime', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.lastModifiedTime' />} />, sortedOrder: 0, Cell: (row) => getDateConvertedString(row.original.lastModifiedTime) },
        { accessor: 'ipAddress', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='ot.column.ipAddress' />} />, sortedOrder: 0 }
    ]
}
export const OtapStatusData = {
    columnFilter: [
        { label: <FormattedMessage id='ot.column.regionName' />, value: "regionName" },
        { label: <FormattedMessage id='ot.column.substationName' />, value: "substationName" },
        { label: <FormattedMessage id='ot.column.feederName' />, value: "feederName" },
        { label: <FormattedMessage id='ot.column.lateralName' />, value: "lateralName" },
        { label: <FormattedMessage id='ot.column.siteName' />, value: "siteName" },
        { label: <FormattedMessage id='ot.column.serialNumber' />, value: "serialNumber" },
        { label: <FormattedMessage id='ot.column.deviceStatus' />, value: "deviceStatus" },
        { label: <FormattedMessage id='ot.column.softwareVersion' />, value: "softwareVersion" },
        { label: <FormattedMessage id='ot.column.jobStatus' />, value: "jobStatus" },
        { label: <FormattedMessage id='ot.column.otapStatus' />, value: "otapStatus" },
        { label: <FormattedMessage id='ot.column.deviceType' />, value: "deviceType" },
        { label: <FormattedMessage id='ot.column.lastModifiedTime' />, value: "lastModifiedTime" },
        { label: <FormattedMessage id='ot.column.ipAddress' />, value: "ipAddress" }
    ],

    ConfigStatusColumnFilter: [
        { label: <FormattedMessage id='ot.column.regionName' />, value: "region" },
        { label: <FormattedMessage id='ot.column.substationName' />, value: "substation" },
        { label: <FormattedMessage id='ot.column.feederName' />, value: "feeder" },
        { label: <FormattedMessage id='ot.column.lateralName' />, value: "lateral" },
        { label: <FormattedMessage id='ot.column.siteName' />, value: "site" },
        { label: <FormattedMessage id='ot.column.serialNumber' />, value: "serialNumber" },
        { label: <FormattedMessage id='ot.column.deviceStatus' />, value: "deviceStatus" },
        { label: <FormattedMessage id='ot.column.softwareVersion' />, value: "softwareVersion" },
        { label: <FormattedMessage id='ot.column.jobStatus' />, value: "jobStatus" },
        { label: <FormattedMessage id='ot.column.deviceType' />, value: "deviceType" },
        { label: <FormattedMessage id='ot.column.lastModifiedTime' />, value: "lastModifiedTime" },
        { label: <FormattedMessage id='ot.column.ipAddress' />, value: "ipAddress" }
    ]
}

export const selectedColumn = {
    data: ['checkBox', 'siteName', 'serialNumber', 'deviceStatus', 'softwareVersion', 'jobStatus', 'otapStatus'],
    configData: ['checkBox', 'site', 'serialNumber', 'deviceStatus', 'softwareVersion', 'jobStatus']
}

export const jobStatus = {
    statusList: [
        { label: <FormattedMessage id='currentjobs.status' />, class: '' },
        { label: <FormattedMessage id='currentjobs.tooltip.jobstatus.SUCCESS' />, class: 'device-status-SUCCESS' },
        { label: <FormattedMessage id='currentjobs.tooltip.jobstatus.INPROGRESS' />, class: 'device-status-INPROGRESS' },
        { label: <FormattedMessage id='currentjobs.tooltip.jobstatus.TRANSFER_COMPLETED' />, class: 'device-status-TRANSFER_COMPLETED' },
        { label: <FormattedMessage id='currentjobs.tooltip.jobstatus.FAILURE' />, class: 'device-status-FAILURE' }
    ],
    jobStatusDetails: [
        { label: <FormattedMessage id='cj.joboverviewtitle.createdBy' />, value: "createdBy" },
        { label: <FormattedMessage id='cj.joboverviewtitle.createdat' />, value: "createdOn" },
        { label: <FormattedMessage id='cj.joboverviewtitle.numberOfDevices' />, value: "numberOfDevices" },
        { label: <FormattedMessage id='cj.joboverviewtitle.inProgress' />, value: "inProgress" },
        { label: <FormattedMessage id='cj.joboverviewtitle.success' />, value: "success" },
        { label: <FormattedMessage id='cj.joboverviewtitle.failure' />, value: "failure" }
    ]
}

export const configStatus = {
    parametersColumnData: [
        { accessor: 'id', Header: <span><FormattedMessage id='cj.devicestatus.column.id' /></span>, Cell: (row) => { return row.index + 1 }, minWidth: 25 },
        { accessor: 'name', Header: <span><FormattedMessage id='cj.devicestatus.column.name' /></span>, Cell: ({ row }) => { return <Tooltip placement={'top'} baseContent={((row.name === 'CndTempThresh') || (row.name === 'LogITempThreshold1') || (row.name === 'LogITempThreshold2')) ? row.name + '(' + getTempUnit('value') + ')' : row.name} message={((row.name === 'CndTempThresh') || (row.name === 'LogITempThreshold1') || (row.name === 'LogITempThreshold2')) ? row.name + '(' + getTempUnit('value') + ')' : row.name} /> } },
        { accessor: 'value', Header: <span><FormattedMessage id='cj.devicestatus.column.value' /></span>, minWidth: 40 },
        { accessor: 'status', Header: <span><FormattedMessage id='cj.devicestatus.column.status' /></span>, minWidth: 30, Cell: ({ row }) => <Tooltip placement={'top'} baseContent={<span className={`status-icons device-status-${row.status}`}><span className='visibile-hide'>.</span></span>} message={<FormattedMessage id={`currentjobs.tooltip.jobstatus.${row.status}`} />} /> }
    ]
}

/* Common methods for Otap and Config status */
export const configStatusCheck = (device, key) => {
    return ((device[key] === 'In Progress') || (device[key] === "INPROGRESS") || (device[key] === "REQUEST_QUEUED") || (device[key] === "IN_PROGRESS") || (device[key] === "PENDING")) ? true : false;
};

export const deviceStatusCheck = (device, key) => {
    return (device[key] === "UNREGISTERED") ? true : false;
};

export const otapStatusCheck = (device, jobStatusKey, otapStatusKey) => {
    if ((device[otapStatusKey] === 'SCHEDULED') || (device[otapStatusKey] === 'PENDING') || (device[otapStatusKey] === 'INPROGRESS') || (device[otapStatusKey] === 'REQUEST_QUEUED') || (device[otapStatusKey] === 'UNRELIABLE_CONNECTION' && device[jobStatusKey] !== 'FAILED') || (device[otapStatusKey] === 'INSUFFICIENT_ENERGY') || (device[otapStatusKey] === 'In Progress')) {
        return true;
    } else {
        return false;
    }
};

export const otapStatusCheckUpgradeJobsAbort = (key) => {
    if ((key.otapStatus === 'PENDING') || (key.otapStatus === 'REQUEST_QUEUED') || (key.otapStatus === 'UNRELIABLE_CONNECTION') || (key.otapStatus === 'INSUFFICIENT_ENERGY') || (key.otapStatus === '')) {
        return true;
    } else {
        return false;
    }
};

export const jobStatusCheckUpgradeJobsAbort = (key, isDeviceMgt) => {
    var jobStatus = isDeviceMgt ? 'jobQueueStatus' : 'jobStatus';
    if ((key[jobStatus] === "ABORT") || (key[jobStatus] === "ABORTING") || (key[jobStatus] === "") || (key[jobStatus] === "COMPLETED")) {
        return true;
    } else {
        return false;
    }
};

export const otapStatusCheckUpgradeJobsRetry = (key, isDeviceMgt) => {
    var jobStatus = isDeviceMgt ? 'jobQueueStatus' : 'jobStatus';
    if ((key.otapStatus !== "FAILED") && (key.otapStatus !== "FAILURE") && (key.otapStatus !== "ABORTED")) {
        if (((key.otapStatus === "UNRELIABLE_CONNECTION") && (key[jobStatus] === "COMPLETED"))) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};

export const checkUpgradeJobsAbort = (key, isDeviceMgt) => {
    var jobStatus = isDeviceMgt ? 'jobQueueStatus' : 'jobStatus';
    if ((key[jobStatus] === "REQUEST_QUEUED") || (key[jobStatus] === "PENDING")) {
        return false;
    } else {
        return true;
    }

};
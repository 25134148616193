import { getURL } from '../../../providers/configProvider';
import { requestPOST, requestGET } from '../../../services/extendedService';

export const getMasterFileHistoryURL = (params)=> {
    const url = getURL('systemadmin', 'masterfilehistory', params);
    return requestGET(url);
};

export const getCertificateFileHistoryURL = (params)=> {
    const url = getURL('systemadmin', 'certificatefilehistory',params);
    return requestGET(url);
};

export const getMenufacturingFile = (requestParams) => {
    const url = getURL('systemadmin', 'manufacturingfilehistory', requestParams, true);
    return requestGET(url);
};

export const getFirmwareFile = (requestParams) => {
    const url = getURL('systemadmin', 'firmwarefilehistory', requestParams, true);
    return requestGET(url);
};

export const getGridMapingFile = (requestParams) => {
    const url = getURL('systemadmin', 'gridmappingfilehistory', requestParams, true);
    return requestGET(url);
};

export const exportMenufacturingFile = (uploadId, from) => {
    var config = "";
    if (from === 'menuFacFilestatus') config = 'exportmanerrsuccfile'   //if clicked 'status' from menufacturing file section
    else if (from === 'gridmapStatus') config = 'exportGridMappingFile' //if clicked 'status' from gridmapping file section
    else if (from === 'inventoryResult') config = 'exportResultFile' //if clicked 'result file' from inventory master data file section
    else config = 'exportManufacturingFile' //on click of 'jwtfile' from menufacturing file section
    var requestParams = { "uploadId": uploadId, 'apiType': 'data' }
    const url = getURL('systemadmin', config, requestParams);
    return requestGET(url)
};

export const downloadSampleFile = (requestParams, from) => {
    let configVal = "";
    if (from === 'gridMapingSampleFile') configVal = 'samplegridmapingfile'   //from grid maping sample file download
    else configVal = 'exportMtfData';  //from 'Import Inventory (Master Data) File' sample file download
    const url = getURL('systemadmin', configVal, requestParams);
    return requestGET(url)
};

export const getGridUnmappedCount = (requestParams) => {
    const url = getURL('systemadmin', 'gridUnmappedCount', requestParams);
    return requestGET(url)
};

export const uploadManufacturingAPIRequest = (requestParams, data) => {
    const url = getURL('systemadmin', 'importmanufacturingdata', requestParams);
    return requestPOST(url, data)
};

export const uploadFirmwareAPIRequest = (requestParams, data) => {
    const url = getURL('systemadmin', 'importfirmwaredata', requestParams);
    return requestPOST(url, data)
};

export const getImportGridMappingDataURL = (requestParams, data) => {
    const url = getURL('systemadmin', 'importgriddata', requestParams);
    return requestPOST(url, data)
};

export const getImportCertificateDataURL = (params, data) => {
    const url = getURL('systemadmin', 'importcertificatedata', params);
    return requestPOST(url, data);
};

export const getImportMasterDataURL = (params, data) => {
    const url = getURL('systemadmin', 'importmasterdata', params);
    return requestPOST(url, data);
}
import * as actionTypes from './dashboardActionTypes';

export const updateSelectedTreeFromDashboard = (data) => {
    return {
        type: actionTypes.UPDATE_SELECTED_TREE_FROM_DASHBOARD,
        data: data
    };
};

export const updateHeatmapDateRangeData = (data) => {
    return {
        type: actionTypes.UPDATE_HEATMAP_DATE_RANGE_DATA,
        range: data
    };
};

export const updateHeatmapDefaultType = (data) => {
    return {
        type: actionTypes.UPDATE_HEATMAP_DEFAULT_TYPE,
        filterType: data
    };
};
export const updateHeatmapPayload = (data) => {
    return {
        type: actionTypes.UPDATE_HEATMAP_PAYLOAD_DATA,
        payloadData: data
    };
};

export const updateBubbleDateRangeData = (data) => {
    return {
        type: actionTypes.UPDATE_BUBBLE_DATE_RANGE_DATA,
        range: data
    };
};

export const updateBubblePayload = (data) => {
    return {
        type: actionTypes.UPDATE_BUBBLE_PAYLOAD_DATA,
        payloadData: data
    };
};

export const updateWatchlistDateRangeData = (data) => {
    return {
        type: actionTypes.UPDATE_WATCHLIST_DATE_RANGE_DATA,
        range: data
    };
};

export const updateElogDateRangeData = (data) => {
    return {
        type: actionTypes.UPDATE_ELOG_DATE_RANGE_DATA,
        range: data
    };
};

export const updateElogStateFilterData = (data) => {
    return {
        type: actionTypes.UPDATE_ELOG_STATE_FILTER_DATA,
        data: data
    };
};

export const updateElogTypeFilterData = (data) => {
    return {
        type: actionTypes.UPDATE_ELOG_TYPE_FILTER_DATA,
        data: data
    };
};
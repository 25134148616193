import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModalWindow from '../../../../../hoc/modelWindow';
import GoogleMapReact from 'google-map-react';
import MarkerClusterer from '@google/markerclusterer';
import { eventPrecursorMapData } from '../../services/eventPrecursorService';
import Loader from '../../../../../hoc/loader';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { isGreaterThanTodaysDate } from '../../../../../filters/dateHelper';
import { updateSelectedTreeFromDashboard } from '../../../../dashboard/actions/index';
let previousInfoWindow;

class SiteViewModal extends Component {
    state = {
        viewSiteLoader: false,
        mapZoom: 13,
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        mapData: []
    }
    getMapOptions = (maps) => {
        return {
            streetViewControl: false,
            styles: [{
                featureType: "poi.business",
                elementType: "labels",
                stylers: [{
                    visibility: "off"
                }]
            }],
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID,
                    maps.MapTypeId.TERRAIN
                ]
            },
        };
    }
    setGoogleMapRef = async (map, maps) => {
        this.setState({ "loader": true })
        let mapData = await eventPrecursorMapData({
            "REGION": this.props.content.region,
            "SUBSTATION": this.props.content.substation,
            "FEEDER": this.props.content.feeder,
            "STARTTIMESTAMP": this.props.content.status_start_utc,
            'apiType': "data"
        });
        mapData = mapData?.feedersites?.map(e => {
            return {
                ...e,
                'lat': Number(e.lat),
                'lng': Number(e.lon),
                'region': mapData.region,
                'substation': mapData.substation,
                'feeder': mapData.feeder,
            }
        });
        this.setState({ "loader": false })
        this.googleMapRef = map
        this.googleRef = maps
        let markers = [];
        window.siteViewModalMapScope = this;
        this.navigatePage = (pData) => {
            let path = {
                regionName: this.props.content.region, substationName: this.props.content.substation, feederName: this.props.content.feeder, siteName: this.props.content.feedersite, type: "SITE"
            }
            this.props.updateSelectedTreeFromDashboard(path);
            this.props.history.push(`/${pData}`)
        }
        mapData && this.setState({ center: mapData[0] });
        mapData && mapData.forEach((location) => {
            let marker = new this.googleRef.Marker({
                position: location,
                infowindow: new google.maps.InfoWindow({
                    content: `<div class = "ep-map-tooltip-title"><span class='fas'>&#xf0da;</span><span>${location.region} - ${location.substation} - ${location.feeder} - ${location.feedersite}</span></div>
                    <fieldset>
                        <table class="ep-map-table">
                            <thead>
                                <tr>
                                    <th>Status Date</th>
                                    <th>Current Status</th>
                                    <th>Phase</th>
                                    <th>Probable Cause - Likelihood</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>${moment(location.status_start_utc).format('DD/MM/YYYY HH:mm')}</td>
                                    <td>${location.current_status}</td>
                                    <td>${location.phase}</td>
                                    <td>${location.probable_cause}</td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>
                        ${this.props.roleAndPermission.licenseData.manage.visible ? isGreaterThanTodaysDate(this.props.roleAndPermission.licenseData.manage.expiresOn) ? '<span class="tooltip-links txt-color" onclick="javascript:siteViewModalMapScope.navigatePage(\'manage/details\')">Device Details</span>' : '<span class="tooltip-links" title="Available to License"><span class="pointer-events-visible cursor-not-allowed">Device Details</span></span>' : null}
                        ${this.props.roleAndPermission.licenseData.monitor.visible ? isGreaterThanTodaysDate(this.props.roleAndPermission.licenseData.monitor.expiresOn) ? '<span class="tooltip-links txt-color" onclick="javascript:siteViewModalMapScope.navigatePage(\'monitor\')">Log-I</span>' : '<span class="tooltip-links" title="Available to License"><span class="pointer-events-visible cursor-not-allowed">Log-I</span></span>' : null}
                        ${this.props.roleAndPermission.licenseData.events.visible ? isGreaterThanTodaysDate(this.props.roleAndPermission.licenseData.events.expiresOn) ? location.device_type !== "VC10" ? '<span class="tooltip-links txt-color" onclick="javascript:siteViewModalMapScope.navigatePage(\'events/details\')">Fault Events</span>' : '<span class="tooltip-links" title="Not applicable for VC10"><span class="disabled pointer-events-visible cursor-not-allowed">Fault Events</span></span>' : '<span class="tooltip-links" title="Available to License"><span class="pointer-events-visible cursor-not-allowed">Fault Events</span></span>' : null}
                        <a class='ep-map-link' target="_blank" href='https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}'>Get Directions</a>
                    </fieldset>
                    `
                    // content: `<div>Region : ${location.region}</div><div>Substation : ${location.substation}</div><div>Feeder : ${location.feeder}</div><div>Site : ${location.feedersite}</div><div>Latitude : ${location.lat}</div><div>Longitude : ${location.lng}</div><div>Status : ${location.current_status}</div>`
                }),
                icon: {
                    url: require(`../../../../../resources/images/${location.current_status.toLowerCase()}${this.props.content.feedersite === location.feedersite ? '-halo' : ''}.png`),
                    scaledSize: new google.maps.Size(25, 35),
                    size: new google.maps.Size(25, 35)
                },
            })
            google.maps.event.addListener(marker, 'mouseover', function () {
                if (previousInfoWindow) {
                    previousInfoWindow.close();
                }
                this['infowindow'].open(map, this);
                previousInfoWindow = this.infowindow;
            });
            // google.maps.event.addListener(marker, 'mouseout', function () {
            //     this['infowindow'].close(map, this);
            // });
            markers.push(marker)
        })

        let markerCluster = new MarkerClusterer(map, markers, {
            styles: [
                {
                    textColor: '#000',
                    url: require(`../../../../../resources/images/markercluster.png`),
                    height: 56,
                    width: 56
                }
            ],
            gridSize: 10,
            minimumClusterSize: 500000
        })
    }
    render() {
        let modelData = <Col className="h-80">
            {this.state.loader ? <Loader elementClass="tree-ajax-loader center-element" /> : null}
            <GoogleMapReact
                center={this.state.center}
                defaultZoom={this.state.mapZoom}
                options={this.getMapOptions}
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{ key: `AIzaSyCQJt0udeazaJKwRMvMBm3a4I21WOT7KQA` }}
                onGoogleApiLoaded={({ map, maps }) => this.setGoogleMapRef(map, maps)}
            />
        </Col>
        return (
            <Row>
                <ModalWindow
                    show={this.props.data.showModal}
                    size="lg"
                    dialogClassName={"site-event-precursor-modal modal-dialog-centered"}
                    title={"Site Details"}
                    onBtnClickHandler={this.onBtnClickHandler}
                    onHide={() => this.props.onCloseModal()}
                    modeldata={{
                        content: modelData
                    }}
                    footer={
                        []
                    }
                />
            </Row>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedTreeFromDashboard: (data) => dispatch(updateSelectedTreeFromDashboard(data))
    }
}
const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission,
        tree: state.treeviewData.tree
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteViewModal));
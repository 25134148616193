import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ToggleSwitch = (props) => {
    return (
        <div className={props.disabled ? "toggle-switch disabled-toggle" :"toggle-switch"} onClick={props.toggleSwitchValue}>
            <div className={ props.value ? 'switch-on toggle-switch-animate' : 'switch-off toggle-switch-animate'}>
                <span className="switch-left span">{props.leftValue ? props.leftValue : <FormattedMessage id='db.common.on' />}</span>
                <span className="switch-mdl span">&nbsp;</span>
                <span className="switch-right span">{props.rightValue ? props.rightValue : <FormattedMessage id='db.common.off' />}</span>
            </div>
        </div>
    )
}

ToggleSwitch.propTypes = {
    toggleSwitchValue: PropTypes.func,
    value: PropTypes.bool.isRequired,
    leftValue: PropTypes.oneOfType([PropTypes.string.isRequired ,PropTypes.object]),
    rightValue: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object])
}

export default ToggleSwitch;
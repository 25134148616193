import React, { Component } from 'react';
import { ProfileContext } from '../provider/profilesProvider';
import UM1TabContent from './um1TabContent';

class UM1ProfileConfig extends Component {

    static contextType = ProfileContext;
   
    render(){
        return(
            <div>
            {
                this.context.state.currentTab === "cfciDataUM1" ?
                    <UM1TabContent 
                        contentArrParent={this.context.state.profile.selectedProfile.cfci} 
                        updateFormattedMessage={this.props.updateFormattedMessage} 
                        rules={this.context.state.profile.selectedProfile.cfciRules}
                        isBooleanValue={this.props.isBooleanValue}
                        handleOnchangeForms = {this.props.handleOnchangeForms}
                        parentObj = 'cfci'
                        disabled = {false}
                        popOver = {true}
                        formError={this.context.state.formError}
                        deviceType={this.props.deviceType}
                    /> : null
            }
            {
                this.context.state.currentTab === "nonCfciDataUM1" ?
                    <div>
                        <UM1TabContent 
                            contentArrParent={this.context.state.profile.selectedProfile.nonCfci} 
                            updateFormattedMessage={this.props.updateFormattedMessage} 
                            rules={this.context.state.profile.selectedProfile.nonCfciRules}
                            isBooleanValue={this.props.isBooleanValue}
                            handleOnchangeForms = {this.props.handleOnchangeForms}
                            parentObj = 'nonCfci'
                            disabled = {false}
                            popOver = {true}
                            formError={this.context.state.formError}
                            deviceType={this.props.deviceType}
                        /> 
                </div> : null
            }
            {
                this.context.state.currentTab === "anomalyDataUM1" ?
                    <div>
                        <UM1TabContent 
                            contentArrParent={this.context.state.profile.selectedProfile.anomaly} 
                            updateFormattedMessage={this.props.updateFormattedMessage} 
                            rules={this.context.state.profile.selectedProfile.anomalyRules}
                            isBooleanValue={this.props.isBooleanValue}
                            handleOnchangeForms = {this.props.handleOnchangeForms}
                            parentObj = 'anomaly'
                            disabled = {false}
                            popOver = {true}
                            formError={this.context.state.formError}
                            deviceType={this.props.deviceType}
                        /> 
                </div> : null
            }
            {
                this.context.state.currentTab === "networkDataUM1" ?
                    <div>
                         <UM1TabContent 
                            contentArrParent={this.context.state.profile.selectedProfile.um1Network} 
                            updateFormattedMessage={this.props.updateFormattedMessage} 
                            rules={this.context.state.profile.selectedProfile.um1NetworkRules}
                            isBooleanValue={this.props.isBooleanValue}
                            handleOnchangeForms = {this.props.handleOnchangeForms}
                            parentObj = 'um1Network'
                            disabled = {false}
                            popOver = {true}
                            formError={this.context.state.formError}
                            deviceType={this.props.deviceType}
                        /> 
                </div> : null
            }
            </div>
        )
    }
}
    export default UM1ProfileConfig;
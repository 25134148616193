import React from 'react';

const NoDataAvailable = (props) => {
    return (
        <div className="clr-fix d-table text-center no-disp-text">
            <span>No Data Available</span>
        </div>
    )
}

export default NoDataAvailable;
import React, { Component, Fragment } from 'react';
import { Col } from 'react-bootstrap';
import NoDataAvailableComponent from '../../../../hoc/noDataAvailableComponent';
import Table from '../../../../hoc/table';
import * as siteUtility from '../../../../utility/siteUtility';
//import SelctAllDeviceCheckbox from '../../../containers/selctAllDeviceCheckbox';
import { SiteContext } from '../provider/siteProvider';
import ReactTablePropType from '../../../../hoc/reactTablePropType';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../../hoc/tooltip';
import SelectAllCB from './selectAllCB';

class SiteTable extends Component {
    static contextType = SiteContext;
    constructor(props) {
        super(props);
        this.state = {
            options: {
                ...siteUtility.tableOptions,
                tableColumns: [
                    {
                        Header: <SelectAllCB/>,
                        accessor: "checkbox",
                        Cell: row => (<input type="checkbox" onChange={(e) => this.onCBChangeHandler(e, row)} checked={this.context.state.selectedSiteIds.includes(row.original.siteId) ? true : false} />),
                        maxWidth: 45,
                        sortable: false,
                        filterable: false
                    },
                    ...siteUtility.tableOptions.tableColumns,
                    {
                        accessor: 'action', Header: <FormattedMessage id='dm.column.actions' />, sortable: false, Cell: ({ original }) => {
                            return <span className="curptr">   
                            <Tooltip baseContent={<FontAwesomeIcon onClick={(e)=>this.context.editSite(original)} icon={faPencilAlt} size="sm" />} message={<FormattedMessage id='groupmanagement.context.edit' />} placement={'top'}/>
                                {/* <Tooltip baseContent={<span className={this.checkUserAccess(original, this.context.currentUserDetails) ? "cursor-not-allowed test" : "cursor-pointer test"} onClick={this.checkUserAccess(original, this.context.currentUserDetails) ? (e) => e.preventDefault() : this.handleDisableOrResetPasswordUser.bind(this, 'resetpassword', original)} size="md" ><CustomImgIcon icon="glyphicons-force-reset.png"/></span>} message={<FormattedMessage id='db.common.resetPassword' />} placement={'top'} /> */}
                            </span>;
                        }
                    }
                ]
            },
        }
    }

     onCBChangeHandler = (e, row) => { this.context.onCBChangeHandler(e, row) };

    // onPageChange = (pageNo, sizePerPage) => {
    //     window.scrollTo(0, 0);
    //     this.props.updateDeviceMngmntUtility(pageNo, sizePerPage);
    // }

    // onPageSizeChange = (sizePerPage, pageNo) => {
    //     window.scrollTo(0, 0);
    //     this.props.updateDeviceMngmntUtility(pageNo, sizePerPage);
    // }

    render() {
        let content = null;
        if (this.props.sites) {
            content =
                <Col xs={12} md={12} className={"clr-fix pad-rgt-lef m-0 p-0 siteList " + this.props.className}>
                    <ReactTablePropType
                        reactTableContainer={
                            <Table
                                tableColumn={this.state.options.tableColumns}
                                tableRowData={this.props.sites}
                                pageNo={siteUtility.tableOptions.pageNo}
                                pageSize={siteUtility.tableOptions.pageSize}
                                totalSize={this.props.sites.length}
                                onPageChange={this.onPageChange}
                                onPageSizeChange={this.onPageSizeChange}
                                pageSizeOptions={siteUtility.tableOptions.pageSizeOptions}
                                isManualTable={true}
                                lazyLoadConfigData={{ initialSize: 50, updatedSize: 50 }} />
                        } />
                </Col>
        }
        else {
            content = !this.props.loading && !this.props.sites ? <NoDataAvailableComponent /> : null
        }

        return (
            <Fragment>
                {content}
            </Fragment>
        )
    }
}

export default SiteTable;
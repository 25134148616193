import React, { Component } from 'react';

import Login from './containers/loginContainer';
import './index.css';

class Loginview extends Component {

  render() {
    return (
      <Login />
    );
  }
}

export default Loginview;
import React, { Component, Fragment } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getHealthData } from '../../../services/deviceService';
import Loader from '../../../../../../hoc/loader';
import { formatDateAndTimeWithoutSeconds } from "../../../../../../filters/dateHelper";
import { setDownloadCSV } from '../../../../../../filters/commonFunction';

class HealthReportTab extends Component {
    state = {
        timezone: this.props.roleAndPermission.timezone,
        isLoding: false
    }

    downloadHealthReport(value) {
        this.setState({ isLoding: true }, () => {
            getHealthData(value)
                .then((data) => {
                    this.setState({ isLoding: false })
                    setDownloadCSV(data)
                }).catch((err) => { })
        })
    }

    render() {
        const { deviceDetailsData } = this.props;
        return (
            <Fragment>
                {this.state.isLoding ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                { Object.entries(deviceDetailsData.healthReport).sort().reverse().map(([properties, value]) =>
                    <Row className="row-padding">
                        <Col> {formatDateAndTimeWithoutSeconds(properties)} </Col>
                        <Col>
                            <Button onClick={this.downloadHealthReport.bind(this, value)} className="tab-download-btn"> <FormattedMessage id={'db.sysAdmin.download'} /> </Button>
                        </Col>
                    </Row>
                )}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default connect(mapStateToProps, {})(HealthReportTab);
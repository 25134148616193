import React, { Component, Fragment } from 'react';
import { Form, FormGroup, Dropdown, Button } from 'react-bootstrap';
import SummaryPicker from './summaryPicker';
import { SummaryContext } from '../provider/summaryProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faShareSquare, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { summaryFilterData } from '../../../../utility/eventsSummaryUtility';
import SingleSelect from '../../../../hoc/singleSelect';
import MultiSelectDropdown from '../../../manage/details/components/multiselectDropdownComponent';
import { FormattedMessage } from 'react-intl';
import TextField from '../../../../hoc/form/textField'

class SummaryFilter extends Component {
    static contextType = SummaryContext;
    myRef = React.createRef();
    myFilterRef = React.createRef();
    handleGroupByApply = () => {
        summaryFilterData.groupBy = this.context.state.groupBy;
        this.context.fetchSummaryData(this.context.state.treeNode);
        this.myRef.current.click();
    }

    maxCountsApply=()=>{
        this.context.handleMaxCountsApply();
        this.myFilterRef.current.click();
    }

    maxCountsReset=()=>{
        this.context.handleMaxCountsReset();
        this.myFilterRef.current.click();
    }

    render() {
        const { startDate, endDate, roleAndPermission } = this.context.state;
        return (
            <Fragment>
                <Form>
                    <FormGroup className="clearfix m-0 p-2 border-1px" xs={12} md={12}>
                        <span className="float-left col-form-label p-0 ml-2">
                            <SummaryPicker
                                timezone={roleAndPermission.timezone}
                                endDate={endDate}
                                startDate={startDate}
                                onCallback={(event, picker) => this.context.onCalenderCallback(event, picker)}
                            />
                        </span>
                        <span className="float-right dropdown-badge">
                            <Fragment>
                                <Dropdown id="dropdown-custom-filters" className="float-left mr-3 mt-1">
                                    <Dropdown.Toggle className="btn-outline-secondary btn-group-icons btn-light grp-btn-first m-0" ref={this.myFilterRef}>
                                        <FontAwesomeIcon icon={faFilter} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-disable-autohide dropdown-filters">
                                        <div className="float-left col-12 mb-2 m-0 p-0 mb-3 mt-1 d-flex align-items-center justify-content-between">
                                            <div className="float-left col-6 m-0 p-0 groupByLabel"><FormattedMessage id="events.summary.maxCounts.label" /></div>
                                            <div className="Text-right col-5 m-0 p-0">
                                                <TextField
                                                    type={'text'}
                                                    field={''}
                                                    value={this.context.state.maxCount}
                                                    placeholder={'Number'}
                                                    maxLength={4}
                                                    handleOnchange={this.context.onChangeHandler}
                                                    size={'md'}
                                                    className= {'col-12 p-2 m-0 maxCountTxtbox'}
                                                    handleOnBlur={this.context.onChangeHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <Button className="btn btn-default" onClick={this.maxCountsApply}><FormattedMessage id="events.summary.apply.btn" /></Button>
                                            <Button className="btn btn-default" disabled={this.context.state.notificationCount === 0 ? true : false} onClick={this.maxCountsReset}><FormattedMessage id="events.summary.reset.btn" /></Button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                               {this.context.state.notificationCount !== 0 ?  <span className="badge-position">{this.context.state.notificationCount}</span> : ''}
                            </Fragment>

                            <Fragment>
                                <Dropdown id="dropdown-groupBy-filters" className="float-left mr-3 mt-1">
                                    <Dropdown.Toggle className="btn-outline-secondary btn-group-icons btn-light grp-btn-first m-0" ref={this.myRef}>
                                        <FontAwesomeIcon icon={faLayerGroup} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-disable-autohide dropdown-filters">
                                        <div className="float-left col-12 mb-2 m-0 p-0 mb-3 mt-1 d-flex align-items-center justify-content-between">
                                            <div className="float-left col-3 m-0 p-0 groupByLabel"><FormattedMessage id="events.summary.groupBy.label" /></div>
                                            <div className="float-left col-8 m-0 p-0">
                                                <span className="single-select-dropdown">
                                                    <MultiSelectDropdown
                                                        selectedArrayList={this.context.state.groupBy}
                                                        optionList={summaryFilterData.groupByList}
                                                        onChange={(e) =>{this.context.handleGroupBy(e)}}
                                                        name={""}
                                                        multiple={false}
                                                        includeSelectAll={false}
                                                        includeFilter={false}
                                                        open={false}
                                                        valueKey="value"
                                                        labelKey="text"
                                                        keepOpen={true}
                                                        placeholder="Select"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <Button className="btn btn-default mb-2" onClick={this.handleGroupByApply}><FormattedMessage id="events.summary.apply.btn" /></Button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Fragment>

                            <span className='single-select-dropdown float-left'>
                                <SingleSelect
                                    data={summaryFilterData.exportData}
                                    setType={this.context.handleExportData}
                                    value={<FontAwesomeIcon icon={faShareSquare} color={this.context.state.faultData.length === 0 ? '#ccc' : ''} />}
                                    isDisplayLabel={false}
                                    disabled={this.context.state.faultData.length===0}
                                />
                            </span>
                        </span>
                    </FormGroup>
                </Form>
            </Fragment>
        )
    }
}

export default SummaryFilter;
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModalWindow from '../../../../hoc/modelWindow';
import Loader from '../../../../hoc/loader';
import BlankPage from '../../../../hoc/blankPage';
import { DetailsContext } from '../provider/detailsProvider';
import { chartData } from '../../../../utility/faultDetailsUtility';
import FaultChart from './faultChart';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

class FaultChartModal extends Component {
    static contextType = DetailsContext;

    handleBtnClick = (val) => this.context.updateChartData(val)

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <Row className='fault-chart'>
                <Col className='p-0'>
                    <ModalWindow
                        show={true}
                        size="lg"
                        dialogClassName={"fault-analysis-modal"}
                        title={
                            <Row>
                                <Col>
                                    {this.context.state.waveformList.length !== 0 ?
                                        <React.Fragment >
                                            <button className={`btn btn-default ${this.context.state.typeVal.indexOf('it') !== -1 ? 'active-clr' : ''}`} onClick={this.handleBtnClick.bind(this, "it")}> <FormattedMessage id={'events.details.waveforms.modal.current'} /> </button>
                                            <button className={`btn btn-default ${this.context.state.typeVal.indexOf('et') !== -1 ? 'active-clr' : ''}`} onClick={this.handleBtnClick.bind(this, "et")}> <FormattedMessage id={'events.details.waveforms.modal.e_field'} /></button>
                                        </React.Fragment>
                                        : ""}
                                </Col>
                            </Row>
                        }
                        onHide={() => this.context.handleOpenOrCloseChartModal(false)}
                        modeldata={{
                            content: <Row className='fault-chart m-0 p-0' style={{ position: 'relative' }} >
                                {this.context.state.isLoadingChartData ? <Loader elementClass="tree-ajax-loader center-element" /> : "" }
                                <Col>
                                    {this.context.state.waveformList.length !== 0 ? this.context.state.waveformList.map((item, key) => {
                                        return <span key={key}>
                                            <FaultChart
                                                waveformList={item}
                                                options={{
                                                    ...chartData.options,
                                                    titles: {
                                                        it: formatMessage({ id: "events.details.waveform.chart.axis.label.currentValue" }),
                                                        et: formatMessage({ id: "events.details.waveform.chart.axis.label.eFieldValue" })
                                                    },
                                                    seriesNames: {
                                                        a: {
                                                            name: formatMessage({ id: "db.feederBar.phaseA" })
                                                        },
                                                        b: {
                                                            name: formatMessage({ id: "db.feederBar.phaseB" })
                                                        },
                                                        c: {
                                                            name: formatMessage({ id: "db.feederBar.phaseC" })
                                                        },
                                                        it: {
                                                            name: formatMessage({ id: "events.details.waveform.chart.axis.label.currentValue" })
                                                        },
                                                        et: {
                                                            name: formatMessage({ id: "events.details.waveform.chart.axis.label.eFieldValue" })
                                                        },
                                                        ait:{
                                                            name: formatMessage({ id: "events.details.waveform.phasea.currentValue" })
                                                        },
                                                        bit:{
                                                            name: formatMessage({ id: "events..detailswaveform.phaseb.currentValue" })
                                                        },
                                                        cit:{
                                                            name: formatMessage({ id: "events.details.waveform.phasec.currentValue" })
                                                        },
                                                        aet:{
                                                            name: formatMessage({ id: "events.details.waveform.phasea.eFieldValue" })
                                                        },
                                                        bet:{
                                                            name: formatMessage({ id: "events.details.waveform.phaseb.eFieldValue" })
                                                        },
                                                        cet:{
                                                            name: formatMessage({ id: "events.details.waveform.phasec.eFieldValue" })
                                                        }
                                                    },
                                                }}
                                                phases={['a', 'b', 'c']}
                                                timezone={this.context.state.timeZone}
                                            />
                                        </span>
                                    }) : !this.context.state.isLoadingChartData && <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
                                </Col>
                            </Row>
                        }}
                    />
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = () => {
    return {}
}

export default injectIntl(connect(mapStateToProps, {})(FaultChartModal));
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { dashboardData, bubbleChartData } from '../../../utility/dashboardUtility';
import * as dashboardBuilderAction from '../actions/index';
import BubbleComponent from '../components/bubbleComponent';
import { makeBubbleChartAPIRequest } from '../services/dashboardService';
import { numberToDate } from '../../../services/utilityService';
import TimeFilter from '../../../hoc/timeFilter';
import BreadCrumb from '../../../hoc/breadCrumb';
import PopModal from '../components/popupModal';
import Loader from '../../../hoc/loader';
import BlankPage from '../../../hoc/blankPage';
import { FormattedMessage } from 'react-intl';

class BubbleMapChartContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bubbleOptions: bubbleChartData,
            dateRange: this.props.bubbleDateRange,
            payload: [...[{'name' : this.props.rootNodeName, type : 'ROOTNODE'}], ...this.props.bubblePayload], // this will cover both payload and bredcrumb data
            requestParams: this.props.requestParams,
            popModalShow: false,
            isResDataBlank: false,
            series: []
        }
    }
    componentDidMount() {
        this.getBubbleChartData();
    }

    getBubbleChartData = () => {
        this.setState({ isLoding: true });
        let initialPayloadData = { 'orgId': this.props.orgId, 'range': this.state.dateRange };
        let removeElementFromPayload = this.state.payload.filter(item => item.type === 'feeder' ? item.type !== 'ROOTNODE' && item.type !== 'feeder' : item.type !== 'ROOTNODE'); // from this.state.payload checking condition if feeder data available then remove feeder with Rootnode else only remove the rootnode. because these data we are not sending to backend
        let arrToObj = Object.fromEntries(removeElementFromPayload.map(item => [item.type, item.name])); //removeElementFromPayload is an array, prepairing array to object and sending to backend. 
        makeBubbleChartAPIRequest(this.state.requestParams, { ...initialPayloadData, ...arrToObj })
            .then(data => {
                if (data.dataset && data.dataset.length !== 0) {
                    var size = 200;
                    for (var i = 0; i < data.dataset.length; i += size) {
                        this.processData(data, data.dataset.slice(i, i + size));
                    }
                    this.setState({ isResDataBlank: false, isLoding: false })
                } else {
                    this.setState({ isResDataBlank: true, isLoding: false })
                }
            }).catch(error => {
                this.setState({ isLoding: false,isResDataBlank :true })
            })
    }

    processData(data, filterArr) {
        var points = filterArr;
        var series = [];
        var timeStamp = [];
        for (var i = 0; i < points.length; i++) {
            for (var j = 0; j < points[i].points.length; j++) {
                var pointObj = {};
                pointObj['color'] = 'rgba(67, 67, 72, 0.5)';
                pointObj['fillColor'] = "rgba(67, 67, 72, 0.5)";
                pointObj['x'] = points[i].points[j].maxLoad;
                pointObj['y'] = points[i].points[j].maxUnbalance;
                pointObj['z'] = points[i].totalDevicesCount;
                pointObj['name'] = points[i].name;
                pointObj['breadcrumb'] = "bubblechart"
                if (points[i] === 0) {
                    if ((this.state.dateRange === '1' && points[i].points[j] === 0) || this.state.dateRange === '7' || this.state.dateRange === '31') {
                        timeStamp.unshift(numberToDate(points[i].fromDate) + ' - ' + numberToDate(points[i].toDate))
                    }
                    else {
                        timeStamp.unshift(numberToDate(points[i].fromDate));
                    }
                }
                series.push(pointObj);
            }
        }
        this.updateChart(series, data)
    }

    updateChart = (series, data) => {
        this.setState({
            series: [...this.state.series, ...series]
        }, () => {
            let chartE1 = this.state.bubbleOptions;
            chartE1.series[0].data = this.state.series
            chartE1.xAxis.plotLines[0].value = data.maxLoadThreshold;
            chartE1.yAxis.plotLines[0].value = data.maxUnbalanceThreshold;
            chartE1.xAxis['softMax'] = data.maxLoadThreshold + ((data.maxLoadThreshold * 20) / 100); // 20% of maxloadthreshold
            chartE1.yAxis['softMax'] = data.maxUnbalanceThreshold + ((data.maxUnbalanceThreshold * 40) / 100); // 40% of maxUnbalanceThreshold
            // chartE1.xAxis.plotLines[0].label.text = data.maxLoadThreshold;
            // chartE1.yAxis.plotLines[0].label.text = data.maxUnbalanceThreshold;

            this.setState({
                bubbleOptions: { ...this.state.bubbleOptions, ...chartE1 },
            })
        })
    }

    removeElement = (prevData) => {
        return prevData.filter(item => item.type === 'feeder' ? item.type !== 'ROOTNODE' && item.type !== 'feeder' : item.type !== 'ROOTNODE');
    }

    handleBubblePointClick = (data) => {
        let prevData = this.state.payload;
        prevData.forEach(item => {
            if (item.type !== 'substation' && item.type !== 'feeder' && prevData.length === 1) { //if this.state.payload length only one then add region data.
                prevData = [...this.state.payload, ...[{ name: data.name, type: 'region' }]]
            }
            if (item.type === 'region' && prevData.length === 2) { //if region available add substation data.
                prevData = [...this.state.payload, ...[{ name: data.name, type: 'substation' }]]
            }
            if (item.type === 'substation' && prevData.length === 3) { //if substation available add feeder data.
                prevData = [...this.state.payload, ...[{ name: data.name, type: 'feeder' }]]
                this.setState({ payload: prevData })
                this.handleOpenModal();
            }
            if (prevData.length > 3) {  //if feeder data availablein this.state.payload then only update the feeder data.
                prevData[3].name = data.name;
                prevData[3].type = 'feeder'
                this.handleOpenModal();
            }
        })
        let filterElement = this.removeElement(prevData);
        this.props.updateBubblePayload(filterElement);
        this.setState({ payload: prevData, series: [] }, () => prevData.length > 3 ? "" : this.getBubbleChartData())
    }

    handleOnClickTimeFilter = (value) => {
        if (this.state.dateRange !== value) {
            this.props.updateBubbleDateRangeData(value);
            this.setState({ dateRange: value, series: [] }, () => this.getBubbleChartData())
        }
    }

    handleBredCrumbOnClick = (key) => {
        let prvArr = this.state.payload;
        let filterArr = prvArr.slice(0, key + 1); // removing all the elemennt from clicked element
        let filterElement = this.removeElement(filterArr);
        this.props.updateBubblePayload(filterElement);
        this.setState({ payload: filterArr, series: [] }, () => this.getBubbleChartData())
    }

    handleCloseModal = () => {
        this.setState({ popModalShow: false })
    }
    handleOpenModal = () => {
        this.setState({ popModalShow: true })
    }

    render() {
        return (
            <Row>
                <Col>
                    <Row className="breadcrumb-background">
                        <Col className="pad-left-top">
                            {/* <Row>
                                <Col xs={6} className="dashboard-chart-title"> <FormattedMessage id='db.column.loadUnbalance' /> </Col>
                                <Col xs={6} className="dashboard-chart-timeFilter">
                                    <TimeFilter data={dashboardData.timeFilterData} handleOnClick={this.handleOnClickTimeFilter} selected={this.state.dateRange} />
                                </Col>
                            </Row>
                            <Row className="bubble-chart-bredcrumb">
                                <Col>
                                    <BreadCrumb data={this.state.payload.length >= 4 ? this.state.payload.slice(0, this.state.payload.length - 1) : this.state.payload} isClickable={true} separatorSymble={' > '} handleOnClick={this.handleBredCrumbOnClick} />
                                </Col>
                            </Row> */}
                            <div className="float-left mt-1">
                                <div className="dashboard-chart-title"><FormattedMessage id='db.column.loadUnbalance' /></div>
                                <div className="bubble-chart-bredcrumb mt-1">
                                    <BreadCrumb data={this.state.payload.length >= 4 ? this.state.payload.slice(0, this.state.payload.length - 1) : this.state.payload} isClickable={true} separatorSymble={' > '} handleOnClick={this.handleBredCrumbOnClick} />
                                </div>
                            </div>
                            <div className="float-right dashboard-chart-timeFilter mt-1">
                                <TimeFilter data={dashboardData.timeFilterData} handleOnClick={this.handleOnClickTimeFilter} selected={this.state.dateRange} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="chart-Section">
                        <div className="container-fluid px-0">
                            {this.state.isLoding === undefined || this.state.isLoding ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                            {!this.state.isResDataBlank ?
                                <BubbleComponent options={this.state.bubbleOptions} handleBubblePointClick={this.handleBubblePointClick} isResDataBlank={this.state.isResDataBlank} />
                                : !this.state.isLoding && <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                            }
                        </div>
                    </Row>
                    {this.state.popModalShow ? <PopModal show={true} onHide={this.handleCloseModal} breadcrumbData={this.state.payload} dateRange={this.state.dateRange} orgId={this.props.orgId} /> : ""}
                </Col>
            </Row>
        );
    }
}
const mapStateToProps = state => {
    return {
        requestParams: state.dashboardData.requestParams,
        bubbleDateRange: state.dashboardData.bubbleDateRange,
        bubblePayload: state.dashboardData.bubblePayload,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateBubbleDateRangeData: (data) => dispatch(dashboardBuilderAction.updateBubbleDateRangeData(data)),
        updateBubblePayload: (data) => dispatch(dashboardBuilderAction.updateBubblePayload(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps )(BubbleMapChartContainer);
import React, { Component } from 'react';
import ReactTablePropType from '../../../hoc/reactTablePropType';
import Table from '../../../hoc/table';
import { Col } from 'react-bootstrap';
import { CustomReportContext } from '../provider/customReportProvider';
import NoDataAvailableComponent from '../../../hoc/noDataAvailableComponent';


class CustomReportsTable extends Component {
    static contextType = CustomReportContext;
    // state = {
    //     pageNo : this.context.state.pageNo,
    //     pageSize : this.context.state.pageSize,
    //     pageSizeOptions: this.context.state.pageSizeOptions,
    // }
    onPageSizeChange = (sizePerPage, pageNo) => {
           this.context.onPageSizeOptionChange(sizePerPage);
        }
    onPageChange = (pageNo, pageSize) => {
            this.context.onPageChange(pageNo + 1,pageSize);
         }
    render() {
        return(
            <Col xs={12} md={12} className={"clr-fix pad-rgt-lef m-0 p-0 custReportList " + this.props.className}>
                {this.context.state.tableData.length > 0 ?
                    <ReactTablePropType
                        reactTableContainer={
                            <Table
                                tableColumn={this.context.state.tableHeader.filter(item => this.context.state.tableColumnFilter.selectedItems.includes(item.accessor))}
                                tableRowData={this.context.state.tableData}
                                pageNo={this.context.state.pageNo}
                                pageSize={this.context.state.pageSize}
                                totalSize={this.context.state.data.reportCount}
                                onPageChange={this.onPageChange}
                                onPageSizeChange={this.onPageSizeChange}
                                pageSizeOptions={this.context.state.pageSizeOptions}
                                isManualTable={true}
                                />
                        } /> : <NoDataAvailableComponent/>
                    }
                </Col>
        )
    }
}

export default CustomReportsTable;
import React, { useContext } from 'react';
import { SystemAdminContext } from '../provider/systemAdminProvider';
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../../../hoc/modelWindow';

const SysAdminFileUploadErrModal =()=> {
    const contextData = useContext(SystemAdminContext);
    const {openUploadErrDetailsModal, errorModalData } =  contextData.state;
    return (
            <ModalWindow
                show={openUploadErrDetailsModal}
                onHide={()=>contextData.handleUploadErrorDetailsClick(false)}
                size={'lg'}
                title={errorModalData.title}
                onBtnClickHandler={()=>contextData.onBtnClickHandleBtn()}
                dialogClassName={"sysAdminErrModal"}
                modeldata={{content:<div>{errorModalData.body}</div>}}
                footer={[ { className: "modalSaveBtn", name: <FormattedMessage id="db.sysAdmin.download" />, value: "download" }]}
            />
        );
}

export default SysAdminFileUploadErrModal;
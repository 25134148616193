import React from 'react';
import HighchartsReact from 'highcharts-react-official'
import HighCharts from "highcharts/highcharts";
import { injectIntl } from 'react-intl';

class CountersChart extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            chartSeries: {},
        }
    }
    componentDidMount() {
        this.processChartData(this.props.countersData, this.props.options);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.countersData && this.props.countersData && (prevProps.countersData !== this.props.countersData)) {
            this.processChartData(this.props.countersData, this.props.options);
        }
    }
    // Process and parse graph data
    processChartData = (data, dataOptions) => {
        let mapData = data ? data.map : [];
        let series = this.buildSeries(dataOptions);

        //flush the previous data before pushing new ones
        for (let p1 = 0; p1 < dataOptions.dataColumns.length; p1++) {
            let colName1 = dataOptions.dataColumns[p1];
            let seriesData = series[colName1];
            seriesData['data'] = [];
            seriesData['tooltip'] = [];
            seriesData['stack'] = '';
            if (this.props.treeNode && (this.props.treeNode.type !== 'REGION')) {
                if (p1 > 2) {
                    if (seriesData['name'].indexOf('-A') > 1) {
                        seriesData['color'] = dataOptions.colors[0];
                    } else if (seriesData['name'].indexOf('-B') > 1) {
                        seriesData['color'] = dataOptions.colors[1];
                    } else {
                        seriesData['color'] = dataOptions.colors[2];
                    }
                }
            }
        }
        Object.entries(mapData).forEach(([name, value]) => {
            Object.entries(value).forEach(([date, values]) => {
                if ((values !== null) && (typeof (values) === 'object')) {
                    Object.entries(values).forEach(([phase, val],index) => {
                        let colName = name + '-' + phase.toUpperCase();
                        series[colName].data.push([date, val]);
                        series[colName].stack = (this.props.treeNode && (this.props.treeNode.type !== 'SITE')) ? name : phase.toUpperCase();
                        series[colName].showInLegend = this.props.treeNode.type !== 'SITE' ? index === 0 : true;
                    });
                } else {
                    let colName = name;
                    let vaues = values ? values : 0;
                    series[colName].data.push([date, vaues]);
                    series[colName].stack = colName;
                }
            });
        });
        this.setState({ chartSeries: series });
    };

    prepareDataColumns = (data) => {
        let columns = [];
        let child = data && data.nodes ? data.nodes : [];
        if (this.props.treeNode && (this.props.treeNode.type !== 'REGION')) {
            child.forEach((name) => {
                columns.push(name + '-A');
                columns.push(name + '-B');
                columns.push(name + '-C');
            });
        }
        return (columns && (columns.length > 0)) ? columns : child;
    };

    buildSeries = (options) => {
        let cols = options.dataColumns;
        let optionChart = options.highcharts.chart;
        let series = {};
        for (let i = 0; i < cols.length; i++) {
            let columnName = cols[i];
            let hasFeaturesOptions = options.hasOwnProperty('features');
            if (hasFeaturesOptions && (i > 0)) {
                let sensor = columnName.slice(1);
                series[columnName] = {};
                Object.assign(series[columnName], options.features[sensor]);
                series[columnName].data = [];
                series[columnName].visible = false;
                series[columnName].id = columnName;
            } else if (!hasFeaturesOptions) {
                series[columnName] = {};
                series[columnName].data = [];
                series[columnName].tooltip = [];
                series[columnName].visible = true;
                series[columnName].id = columnName;
                series[columnName].color = options.colors[i];
                if (optionChart.type === 'column') {
                    series[columnName].name = columnName;
                    series[columnName].stack = '';
                }
            }
        }
        return series;
    };

    render() {
        const { treeNode, countersData, options } = this.props;
        const { chartSeries } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <span className='set-width-height'>
                <HighchartsReact
                    highcharts={HighCharts}
                    options={{
                        ...this.props.options,
                        dataColumns: {
                            ...this.props.options.dataColumns = this.prepareDataColumns(countersData)
                        },
                        ...this.props.options.highcharts,
                        series: Object.entries(chartSeries).map(([id, s]) => {
                            s.name = id;
                            s.id = id;
                            s.yAxis = 0;
                            s.dashStyle = options.dashStyles[0];
                            return s;
                        }),
                        tooltip: {
                            ...this.props.options.highcharts.tooltip,
                            shared: (treeNode && (treeNode.type !== 'REGION') && (treeNode.type !== 'SITE')) ? false : true,
                            formatter: function () {
                                let pointsArr = this.points ? this.points : [this.point];
                                let s = '<div class="bar-chart-tooltip">';
                                s += `<strong>${formatMessage({ id: "disturbances.chart.tooltip.title" })}</strong>`;
                                let barCounts = '';
                                if (treeNode.type && (treeNode.type !== 'REGION') && (treeNode.type !== 'SITE')) {
                                    let tooltipContent = pointsArr[0].series.userOptions;
                                    s += '<p>' + pointsArr[0].name + '</p>';
                                    s += '<table>';
                                    Object.entries(chartSeries).forEach(([key, val]) => {
                                        if (key.indexOf(tooltipContent.stack) > -1) {
                                            val.data.forEach((vals) => {
                                                if (vals[0] === pointsArr[0].name) {
                                                    let nameSplit = val.name.split('-');
                                                    barCounts += '<tr><td style="color:' + val.color + ';">Phase ' + nameSplit[nameSplit.length - 1] + '</td><td>' + vals[1] + '</td></tr>';
                                                }
                                            });
                                        }
                                    });
                                    s += '<tr><td>' + tooltipContent.stack + ' (Total)</td><td>' + pointsArr[0].total + '</td></tr>';
                                    s += barCounts;
                                    s += '</table></div>';
                                    return pointsArr[0].total > 0 ? s : false;

                                } else {
                                    s += '<p>' + pointsArr[0].key + '</p>';
                                    s += '<table>';
                                    let total = 0;
                                    pointsArr.forEach((point) => {
                                        total += point.total;
                                        let tooltipContent = point.series.userOptions;
                                        let nameSplit = tooltipContent.name.split('-');
                                        let name = nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : nameSplit[0];
                                        barCounts += '<tr><td style="color:' + tooltipContent.color + ';">' + name + '</td><td>' + point.y + '</td></tr>';
                                    });
                                    s += '<tr><td>' + treeNode['name'] + ' (Total)</td><td>' + total + '</td></tr>';
                                    s += barCounts;
                                    s += '</table></div>';
                                    return total > 0 ? s : false;
                                }
                            }
                        },
                        legend: {
                            ...this.props.options.highcharts.legend,
                            symbolHeight: (this.props.treeNode && (this.props.treeNode.type !== 'REGION') && (this.props.treeNode.type !== 'SITE')) ? .001 : 10,
                            symbolWidth: (this.props.treeNode && (this.props.treeNode.type !== 'REGION') && (this.props.treeNode.type !== 'SITE')) ? .001 : 10,
                            symbolRadius: (this.props.treeNode && (this.props.treeNode.type !== 'REGION') && (this.props.treeNode.type !== 'SITE')) ? .001 : 3,

                            labelFormatter: function () {
                                return this.options.stack;
                            }
                        },
                        plotOptions: {
                            column: {
                                ...this.props.options.highcharts.plotOptions.column,
                                events: {
                                    legendItemClick: function (event) {
                                        let stackKey = (event.target.stackKey).replace(/,/g, '');
                                        Object.entries(event.target.chart.series).forEach(([i, serie]) => {
                                            if ((serie.stackKey).replace(/,/g, '') === stackKey) {
                                                serie.visible ? serie.hide() : serie.show();
                                            }
                                        });
                                        return false;
                                    }
                                },
                            }
                        },
                        ...this.props.options.highcharts.yAxis.stackLabels.enabled = (this.props.treeNode && (this.props.treeNode.type !== 'REGION') && (this.props.treeNode.type !== 'SITE')) ? true : false
                    }}
                />
            </span>
        )
    }
}

export default injectIntl(CountersChart);
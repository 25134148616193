import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { BrowserRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import MainContent from '../mainContent';
import Login from '../login';
import * as actionBuilder from "./actions";
import * as treeBuilderActions from '../treeview/actions';
import Loader from '../hoc/loader';
import ForgotPassword from '../login/containers/forgotPasswordContainer';
import ResetPassword from '../login/containers/resetPasswordContainer';
import SelectOrganization from '../login/containers/selectOrganization';
import { handleAuthApi, setLoader } from "../login/actions/loginActionsBuilder";
import { getToken } from '../login/actions/loginActionsBuilder';
import { skipSetSession } from './actions/homeActionsBuilder';
import { setAuthToken } from '../utility/authTokenUtility';
import Authorize from '../login/components/authorize';

class HomeComponent extends Component {
    componentDidMount() {
    localStorage.getItem("token")
      ? this.handleReloadWithToken(localStorage.getItem("token"))
      : this.handleFreshLoad();
    // this.props.onCheckSession({ samlLogout : this.props.samlLogout, samlFailed: this.props.samlFailed });
    }

    componentWillReceiveProps(newProps) {
      if (newProps.loggedIn !== this.props.loggedIn) {
      if (newProps.sessionData === "undefined" || !newProps.sessionData) {
        // this.props.onCheckSession()
        const [searchParams] = new URLSearchParams(window.location.search);
        (searchParams?.[0] === "code" || !localStorage.getItem("token")) || window.location.pathname.split("/")[2] === "authorize"
          ? this.props.skipSessionApi()
          : this.props.onCheckSession({
              samlLogout: this.props.samlLogout,
              samlFailed: this.props.samlFailed,
            });
            } else {
                this.props.getAllRoleAndUserPermission();
                this.props.onInitTree(newProps.sessionData.data);
                localStorage.removeItem("loader")
            }
        }
    }

    componentDidUpdate() { 
        const [searchParams] = new URLSearchParams(window.location.search);
    searchParams?.[0] === "code" &&
      !localStorage.getItem("token") &&
      this.handleAuthorizedEndpointparam();
    }

    handleAuthorizedEndpointparam = () => {
        const [searchParams] = new URLSearchParams(window.location.search);
        const code = searchParams?.[1];
    this.props.getToken(code);
  };

  handleReloadWithToken = (params) => {
    const token = JSON.parse(params);
    setAuthToken(token.access_token);
    this.props.onCheckSession({
      samlLogout: this.props.samlLogout,
      samlFailed: this.props.samlFailed,
    });
  };

  handleFreshLoad = () => {
    //this method will handle intial load of the application when there is no token, below conditions are added to skip the orgdetails api call when redirects are occured
    const [searchParams] = new URLSearchParams(window.location.search);
    (searchParams?.[0] === "code" || !localStorage.getItem("token")) || window.location.pathname.split("/")[2] === "authorize"
      ? this.props.skipSessionApi()
      : this.props.onCheckSession({
          samlLogout: this.props.samlLogout,
          samlFailed: this.props.samlFailed,
        });
  };

    render() {
        let content = null;
        if (this.props.loading||localStorage.getItem('loader')) {
      content = <Loader elementClass="tree-ajax-loader center-element" />
        } else if (this.props.loggedIn) {
      content = 
            <React.Fragment>
                <Container fluid className="p-0">
                    <div className="main">
                        {/* <Row className="p-0 m-0"><Col sm={12} className="p-0 m-0"><Header /></Col></Row> */}
                        <MainContent />
                    </div>
                </Container>
                <div className="position-relative footer clearfix mb-0 p-0">
                    <Footer />
                </div>
            </React.Fragment>          
        } else {
            content =
                <Switch>
                    <Route exact path='/' render={() => (<Redirect to='/login' />)} />
                    <Route path="/forgotPassword"><ForgotPassword /></Route>
                    <Route path="/selectOrganization"><SelectOrganization /></Route>
                    <Route path="/reset-password"><ResetPassword /></Route>
                    {['/login', '/login/disabled', '/login/failed', '/login/invalidlicense', '/samlLogout', '/login/samlFailed'].map(path => <Route exact strict path={path} key={path}><Login /></Route>)}
                    <Route path="/authorize" ><Authorize/></Route>
                    <Redirect from="*" to="/login" />
                </Switch>
        }

        return (
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                {content}
            </BrowserRouter>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loggedIn: state.loginData.loggedIn,
        sessionData: state.loginData.sessionData,
        loading: state.loginData.loading,
        samlLogout: state.headerData.samlLogout,
        samlFailed: state.headerData.samlFailed
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // onCheckSession: (formRef,inputRef) => dispatch(actionBuilder.setSession(this.state.formRef,this.state.inputRef)),
        onCheckSession: (samlInfo) => dispatch(actionBuilder.setSession(samlInfo)),
        onInitTree: (data) => dispatch(treeBuilderActions.initTree(data)),
        getAllRoleAndUserPermission: () => dispatch(actionBuilder.getAllRoleAndUserPermission()),
        setLoader: (params) => dispatch(setLoader(params)),
        getToken: (params) => dispatch(getToken(params)),
        skipSessionApi: () => dispatch(skipSetSession())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeComponent));
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber';
import { formatDateAndTimeWithoutSeconds } from '../filters/dateHelper';
import { fetchBrowserTimeZone } from '../services/utilityService';

export const systemAdminData = {
    pageSize: 10,
    previousText: <FormattedMessage id='db.common.pgntn.previous' />,
    nextText: <FormattedMessage id='db.common.pgntn.next' />,
    firstText: <FormattedMessage id='db.common.pgntn.first' />,
    lastText: <FormattedMessage id='db.common.pgntn.last' />,

    manufacturingData: [
        { accessor: 'fileName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.fileName' />} />},
        { accessor: 'uploadedBy', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.uploadedBy' />}/>},
        { accessor: 'importedOn', Header: (original) => <TableMultiSortNumber column={original} label={<><FormattedMessage id='db.sysAdmin.importedOn' />{fetchBrowserTimeZone()}</>} />, Cell: (original) => formatDateAndTimeWithoutSeconds(original.row.importedOn) },
    ],
    firmwareData: [
        { accessor: 'fileName', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.sysAdmin.fileName' /> } /> },
        { accessor: 'importedOn', Header: (original) => <TableMultiSortNumber column={original} label={<><FormattedMessage id='db.sysAdmin.importedOn' />{fetchBrowserTimeZone()}</>} />, Cell: (original) => formatDateAndTimeWithoutSeconds(original.row.importedOn) },
        { accessor: 'status', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.status' />} /> }
    ],
    gridMapingData: [
        { accessor: 'fileName', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.sysAdmin.fileName' /> } />, },
        { accessor: 'uploadedBy', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.sysAdmin.uploadedBy' /> } />, },
        { accessor: 'importedOn', Header: (original) => <TableMultiSortNumber column={original} label={<><FormattedMessage id='db.sysAdmin.importedOn' />{fetchBrowserTimeZone()}</> } />, Cell: (original) => formatDateAndTimeWithoutSeconds(original.row.importedOn) },
        // { accessor: 'status', Header: 'Status' }
    ],
    inventoryMasterData: [
        { accessor: 'fileName', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.sysAdmin.fileName' /> } />, },
        { accessor: 'uploadedBy', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.sysAdmin.uploadedBy' /> } />, },
        { accessor: 'importedOn', Header: (original) => <TableMultiSortNumber column={original} label={<> <FormattedMessage id='db.sysAdmin.importedOn' /> {fetchBrowserTimeZone()}</>} />, Cell: (original) => formatDateAndTimeWithoutSeconds(original.row.importedOn) },
        { accessor: 'status', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.status' />} /> }
    ],
    certificateFile: [
        { accessor: 'fileName', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.sysAdmin.fileName' /> } /> },
        { accessor: 'uploadedBy', Header: (original) => <TableMultiSortNumber column={original} label={ <FormattedMessage id='db.sysAdmin.uploadedBy' />} /> },
        { accessor: 'importedOn', Header: (original) => <TableMultiSortNumber column={original} label={<> <FormattedMessage id='db.sysAdmin.importedOn' />{fetchBrowserTimeZone()}</>} />, Cell: (original) => formatDateAndTimeWithoutSeconds(original.row.importedOn) },
        { accessor: 'status', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.status' />} /> }
    ],
}
import React, { Component } from 'react';
import './index.css';
import ConfigModalContainer from './containers/configModalContainer'
import ConfigurePropertiesProvider from './provider/configurePropertiesProvider';

class ConfigureProperties extends Component {

    render() {
        return (

            <ConfigurePropertiesProvider>
                <ConfigModalContainer />
            </ConfigurePropertiesProvider>
        );
    }
}

export default ConfigureProperties;
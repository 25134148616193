import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber';
import { alphaNumericRegex, numberOnlyRegex, numWithOptDeciRgx } from '../filters/commonFunction';

export const equipmentProfileData = {
    columnsData: [
        { accessor: 'transformerProfileName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.equipmentprofile.transformerProfileName' />} />, sortedOrder: 0 },
        { accessor: 'manufacturerName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.equipmentprofile.manufacturerName' />} />, sortedOrder: 0 },
        { accessor: 'hvRating', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.equipmentprofile.hvRating' />} />, sortedOrder: 0 },
        { accessor: 'lvRating', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.equipmentprofile.lvRating' />} />, sortedOrder: 0 },
        { accessor: 'kvaRating', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.equipmentprofile.kvaRating' />} />, sortedOrder: 0 },
        { accessor: 'turnsRatio', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.equipmentprofile.turnsRatio' />} />, sortedOrder: 0, Cell: (original) => Math.round(original.row.turnsRatio) },
        { accessor: 'impedancePercent', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.equipmentprofile.impedancePercent' />} />, sortedOrder: 0, Cell: (original) => Number(original.row.impedancePercent).toFixed(2) },
    ],
    columnFilter: [
        { label: <FormattedMessage id='db.equipmentprofile.transformerProfileName' />, value: "transformerProfileName" },
        { label: <FormattedMessage id='db.equipmentprofile.manufacturerName' />, value: "manufacturerName" },
        { label: <FormattedMessage id='db.equipmentprofile.hvRating' />, value: "hvRating" },
        { label: <FormattedMessage id='db.equipmentprofile.lvRating' />, value: "lvRating" },
        { label: <FormattedMessage id='db.equipmentprofile.kvaRating' />, value: "kvaRating" },
        { label: <FormattedMessage id='db.equipmentprofile.turnsRatio' />, value: "turnsRatio" },
        { label: <FormattedMessage id='db.equipmentprofile.impedancePercent' />, value: "impedancePercent" },
    ],
    selectedColumn: ['transformerProfileName', 'hvRating', 'lvRating', 'kvaRating', 'impedancePercent', 'action'],
    profileList: ['transformerProfiles', 'cableProfiles'],
    formFields: [
        { label: "Transformer Profile Name", type: "text", value: "transformerProfileName", isMandatory: true, regEx: alphaNumericRegex, errorMessage: <FormattedMessage id='db.equipmentprofile.alphanumericError' /> },
        { label: "Manufacturer Name", type: "text", value: "manufacturerName", isMandatory: false },
        { label: "HV Winding Rating (V)", type: "text", value: "hvRating", isMandatory: true, regEx: numberOnlyRegex, errorMessage: <FormattedMessage id='db.equipmentprofile.numberError' /> },
        { label: "LV Winding Rating (V)", type: "text", value: "lvRating", isMandatory: true, regEx: numberOnlyRegex, errorMessage: <FormattedMessage id='db.equipmentprofile.numberError' /> },
        { label: "KVA Rating (kVA)", type: "text", value: "kvaRating", isMandatory: true, regEx: numberOnlyRegex, errorMessage: <FormattedMessage id='db.equipmentprofile.numberError' /> },
        { label: "Turns Ratio", type: "text", value: "turnsRatio", isMandatory: true, regEx: numberOnlyRegex, errorMessage: <FormattedMessage id='db.equipmentprofile.numberError' /> },
        { label: "% Impedance", type: "text", value: "impedancePercent", isMandatory: true, regEx: numWithOptDeciRgx, errorMessage: <FormattedMessage id='db.equipmentprofile.decimalError' /> },
    ]
}

export const cableProfileData = {
    columnsData: [
        { accessor: 'cableProfileName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.cableprofile.cableProfileName' />} />, sortedOrder: 0 },
        { accessor: 'cableType', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.cableprofile.cableType' />} />, sortedOrder: 0 },
        { accessor: 'nominalRating', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.cableprofile.nominalRating' />} />, sortedOrder: 0 },
        { accessor: 'summerEmergency', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.cableprofile.summerEmergency' />} />, sortedOrder: 0 },
        { accessor: 'winterEmergency', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.cableprofile.winterEmergency' />} />, sortedOrder: 0 },
        { accessor: 'conductorMaterial', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.cableprofile.conductorMaterial' />} />, sortedOrder: 0 },
        { accessor: 'conductorSize', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.cableprofile.conductorSize' />} />, sortedOrder: 0 },
        { accessor: 'insulationType', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.cableprofile.insulationType' />} />, sortedOrder: 0 },
    ],
    columnFilter: [
        { label: <FormattedMessage id='db.cableprofile.cableProfileName' />, value: "cableProfileName" },
        { label: <FormattedMessage id='db.cableprofile.cableType' />, value: "cableType" },
        { label: <FormattedMessage id='db.cableprofile.nominalRating' />, value: "nominalRating" },
        { label: <FormattedMessage id='db.cableprofile.summerEmergency' />, value: "summerEmergency" },
        { label: <FormattedMessage id='db.cableprofile.winterEmergency' />, value: "winterEmergency" },
        { label: <FormattedMessage id='db.cableprofile.conductorMaterial' />, value: "conductorMaterial" },
        { label: <FormattedMessage id='db.cableprofile.conductorSize' />, value: "conductorSize" },
        { label: <FormattedMessage id='db.cableprofile.insulationType' />, value: "insulationType" },
    ],
    selectedColumn: ['cableProfileName', 'cableType', 'nominalRating', 'conductorMaterial', 'conductorSize', 'action'],
    formFields: [
        { label: "Cable Profile Name", type: "text", value: "cableProfileName", isMandatory: true, regEx: alphaNumericRegex, errorMessage: <FormattedMessage id='db.equipmentprofile.alphanumericError' /> },
        { label: "Cable Type", type: "text", value: "cableType", isMandatory: true },
        { label: "Nominal Rating (A)", type: "text", value: "nominalRating", isMandatory: true, regEx: numberOnlyRegex, errorMessage: <FormattedMessage id='db.equipmentprofile.numberError' /> },
        { label: "Summer Emergency (A)", type: "text", value: "summerEmergency", isMandatory: true, regEx: numberOnlyRegex, errorMessage: <FormattedMessage id='db.equipmentprofile.numberError' /> },
        { label: "Winter Emergency (A)", type: "text", value: "winterEmergency", isMandatory: true, regEx: numberOnlyRegex, errorMessage: <FormattedMessage id='db.equipmentprofile.numberError' /> },
        { label: "Conductor Material", type: "text", value: "conductorMaterial", isMandatory: false },
        { label: "Conductor Size (AWG)", type: "text", value: "conductorSize", isMandatory: false, regEx: numberOnlyRegex, errorMessage: <FormattedMessage id='db.equipmentprofile.numberError' /> },
        { label: "Insulation Type", type: "text", value: "insulationType", isMandatory: false },
    ]
}
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DisplayTextEllipsis = (props) => {
    const [isElips, setElips] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [isShowMore, setShowMore] = useState(false)

    const handleOnclick = (e) => {
        setClicked(!clicked)
        setShowMore(!isShowMore)
    }

    return (
        <>
            <div ref={ref => {
                if (!ref) return
                let isOverflowing = 0;
                if (!clicked) {
                    isOverflowing = ref.scrollWidth > ref.clientWidth
                    if (isOverflowing) setElips(true)
                    else setElips(false)
                } else {
                    isOverflowing = ref.offsetHeight < 19
                    if (isOverflowing) {
                        setShowMore(false)
                        setClicked(false)
                    } else setShowMore(true)
                }
            }}
                style={{ whiteSpace: !clicked ? 'nowrap' : '', overflow: !clicked ? 'hidden' : '', }}
                className={isElips && !isShowMore ? 'cursor-pointer active-color text-truncate' : "text-break"}
                onClick={() => isElips && !isShowMore ? handleOnclick() : () => { }}
            >
                {props.data}
            </div>
            {isShowMore && <a onClick={(e) => handleOnclick(e)} href="javascript:void(0);"> Show Less </a>}
        </>
    )
}

DisplayTextEllipsis.propTypes = {
    data: PropTypes.string
}

export default DisplayTextEllipsis;
import React from 'react';
import HighchartsReact from 'highcharts-react-official'
import HighCharts from "highcharts/highcharts";
import { injectIntl } from 'react-intl';

class FeederComponent extends React.Component {
    constructor(props) {
        super(props);
        this.chart = null;
    }
    componentDidMount() {
        this.chart = this.refs.feederChart.chart;
        this.chart.component = this;
    }

    loadPointData = (pointData) => {
        this.props.handleFeederClick(pointData.category)
    }
    render() {
        const { formatMessage } = this.props.intl;
        return (
            <HighchartsReact
                highcharts={HighCharts}
                // options = {this.props.options} //without format message for all options data
                options={{ //with format message
                    ...this.props.options,
                    ...this.props.options.series[0].name = formatMessage({ id: "db.feederBar.phaseA" }),
                    ...this.props.options.series[1].name = formatMessage({ id: "db.feederBar.phaseB" }),
                    ...this.props.options.series[2].name = formatMessage({ id: "db.feederBar.phaseC" }),
                    ...this.props.options.yAxis.title.text = formatMessage({ id: "db.bubbleChart.peakLoadA" }),
                    tooltip: {
                        ...this.props.options.tooltip,
                        pointFormat: `<tr><td><span style="width: 10px; height: 10px; display: inline-block; margin-right: 3px; border-radius: 50%; background-color: {series.color};"></span></td><td style="padding-right: 5px;">{series.name}: </td><td>{point.y} ${formatMessage({ id: 'common.label.amperes' })}</td></tr>`,
                    },
                }}
                ref={"feederChart"}
            />
        )
    }
}

export default injectIntl(FeederComponent);
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { downloadLogs } from '../../../services/deviceService';
import { FormattedMessage } from 'react-intl';
import { setDownloadCSV } from '../../../../../../filters/commonFunction';

class SystemLogsTableAction extends Component {
    state = {
        isLoading: false
    }

    downloadSystemLogFile = (e, info) => {
        e.preventDefault();
        this.setState({ isLoading: true }, () => {
            downloadLogs(info.sysLogId)
                .then((data) => {
                    setDownloadCSV(data)
                    this.setState({ isLoding: false })
                }).catch((err) => { })
        })
    }

    render() {
        return (
            <span onClick={(e) => this.downloadSystemLogFile(e, this.props.data)}>
                { this.props.data.status === 'Completed' ? <Button className='tab-download-btn'> <FormattedMessage id={"db.sysAdmin.download"}/> </Button> : ""}
            </span >
        )
    }
}

export default SystemLogsTableAction;
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import SubNavigations from '../../header/navigation/subNavigations';
import Menus from '../../config/menus.json';
import EventsRoutes from '../../routes/eventsRoutes';
import { connect } from 'react-redux';
import './index.css';
import { isGreaterThanTodaysDate } from '../../filters/dateHelper';

class FaultAnalysis extends Component {
    updateMenuItems = (data) => {
        // Show or disable sub menus based on license visibility and expiresOn date
        Menus.eventsSubMenu.forEach(submenu => {
            const parent = submenu['link'].includes('manage') ? 'manage' : submenu['link'].includes('events') ? 'events' : 'insights'
            Object.keys(data.licenseData).length && data.licenseData[parent]?.childNodes.forEach(child => {
                if (child.name === submenu.name && child.visible) {
                    submenu.show = true;
                    if (isGreaterThanTodaysDate(child.expiresOn)) submenu.isDisabled = false;
                }
            })
        });
    }

    render() {
        if (Object.keys(this.props.roleAndPermission).length > 0) {
            this.updateMenuItems(this.props.roleAndPermission)
        }
        return (
            <div className="secondary-navbar col-md-12 col-12 p-0 fault-analysis">
                <SubNavigations menu={Menus['eventsSubMenu']} />
                <EventsRoutes />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default withRouter(connect(mapStateToProps, {})(FaultAnalysis));
import React from 'react';

const TimeFilter =({data, selected, handleOnClick})=>{
    return <span className="time-filter">
                { data.map((item, key, arr)=>{
                    const { label, value } = item;
                    return <span key={key}
                                className="time-filter-btn" 
                                style={{ borderRadius : (key === (arr.length - 1) ? "0px 4px 4px 0px" : "") || (key === 0  ? "4px 0px 0px 4px" : "0px" ), borderRight : key === (arr.length-1) ? "" : '1px solid #ccc', background : selected === value ? '#d98c14' : '' }} 
                                onClick={()=>handleOnClick(value)}
                            > 
                                {label} 
                            </span>
                })}
            </span>
}

export default TimeFilter;
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const disturbancesData = {
    currentTab: 'countersTable',
    formFields: [
        { label: <FormattedMessage id='common.label.startDate' />, value: 'startTimestamp', type: 'date' },
        { label: <FormattedMessage id='common.label.endDate' />, value: 'endTimestamp', type: 'date' },
        { label: <FormattedMessage id='common.text.interval' />, value: 'interval', type: 'singleSelect' },
    ],
    intervalData: [{ label: <FormattedMessage id='common.text.daily' />, value: 'Daily' }, { label: <FormattedMessage id='common.text.weekly' />, value: 'Weekly' }, { label: <FormattedMessage id='common.text.monthly' />, value: 'Monthly' }],
    exportData: [{ label: <FormattedMessage id='common.text.download.excel' />, value: 'excel' }, { label: <FormattedMessage id='common.text.download.csv' />, value: 'csv' }],
    tabsData: [{ label: <FormattedMessage id='disturbances.label.countersTable' />, value: 'countersTable' }, { label: <FormattedMessage id='disturbances.label.countersChart' />, value: 'countersCharts' }],
    selectedDataByUser: {
        startTimestamp: '',
        endTimestamp: '',
        interval: 'Daily',
    }
}
const style = {
    fontFamily: "'univers', Arial, sans-serif",
    fontSize: '1.4vh',
    color: "#333333"
};

export const countersChartData = {
    colors: ['#ff7474', '#5cd65c', '#7495be', '#96ceb4', '#ffeead', '#ffcc5c', '#ff6f69', '#588c7e', '#f2e394', '#f2ae72', '#d96459', '#eeac99', '#a2836e'], // controls which colors are used.
    dashStyles: ['Solid', 'ShortDash', 'ShortDot'],
    highcharts: {
        credits: {
            enabled: false
        },
        chart: {
            type: 'column',
            zoomType: 'none',
            alignTicks: false, //required for multiple series
            events: {
                load() {
                  setTimeout(this.reflow.bind(this), 0);
                },
              },
        },
        title: {
            text: ''
        },
        exporting: {
            enabled: false
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                /*  */
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: 2
                }
            },
            line: {
                connectNulls: false,
                animation: {
                    duration: 1200
                },
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: 0
                }
            },
            series: {
                animation: {
                    duration: 1200
                },
                marker: {
                    radius: 0,
                    states: {
                        hover: {
                            radius: 2
                        }
                    }
                }
            },
            flags: {
                shape: "squarepin",
                useHTML: true,
                fillColor: "#fff",
                states: {
                    hover: {
                        fillColor: '#666'
                    }
                },
                y: -40
            },
            dataGrouping: {
                enabled: false
            }
        },
        legend: {
            enabled: true,
            borderRadius: 0,
            borderColor: '#fff',
            symbolHeight: 0,
            symbolWidth: 0,
            symbolRadius: 0,
            useHTML: true,
            itemStyle: {
                fontFamily: style.fontFamily,
                fontSize: style.fontSize,
                fontWeight: 'bold',
                color: style.color
            }
        },
        navigator: {
            enabled: false
        },
        dataGrouping: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        tooltip: {
            borderWidth: 1,
            borderColor: '#aeceea',
            backgroundColor: '#fafafa',
            useHTML: true,
            crosshairs: true,
            shared: true,
            headerFormat: '',
            valueDecimals: 0,
            zoomType: 'x',
            style: style
        },
        yAxis: {
            allowDecimals: false,
            stackLabels: {
                enabled: true,
                allowOverlap: true,
                style: {
                    fill: 'red',
                    zIndex: '1'
                },
                rotation: -45,
                y: 10,
                verticalAlign: 'bottom',
                useHTML: true,
                formatter: function () {
                    let name = this.stack;
                    if (this.total !== 0) {
                        return '<span class="inline-block chart-stack-Label-top column' + name + '" title="' + name + '">' + name + '</span>';
                    } else {
                        return '';
                    }
                }
            },
            labels: {
                style: style,
                x: -1,
                align: 'right'
            },
            title: {
                text: 'Disturbance Counts',
                style: style
            },
            gridLineWidth: 1,
            gridLineDashStyle: 'dot',
            lineWidth: 1,
            opposite: false
        },
        xAxis: {
            labels: {
                style: style
            },
            title: {
                text: 'Time',
                style: style
            },
            gridLineWidth: 1,
            gridLineDashStyle: 'dot',
            type: 'category',
            lineColor: "#ddd",
            ordinal: false,
            crosshair: {
                color: "#FFFFFF"
            }
        }
    }
};
import React, { createContext } from 'react';
import { DGWData } from '../../../utility/dgwUtility';
import { getAllDGWData,certHistoryOrCrlHistory,uploadCertificate,displayCertificate } from '../services/dgwService';
import { getCookie } from '../../../filters/commonFunction';

export const DGWContext = createContext();

class DGWProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dgwTableData: [],
            isLoadingTblData: false,
            isloadingData: false,
            isloadingCertModalData:false,
            isOpenUploadCertModal:false,
            uploadCertTableData:[],
            isOpenPkeyModal:false,
            pKeyAlertMessage:{},
            pKeypassword:"",
            certFile:"",
            OSName: 'other',
            alertMessage: {},
            currentTab: DGWData.currentTab,
            isCertificateModalDisplay:false,
            certificateData:''
        }
    }

    componentDidMount() {
        this.getDGWData();
        this.checkOperatingSystem();
    }

    checkOperatingSystem = () => {
        if (navigator.appVersion.indexOf("Mac") !== -1) this.setState({ OSName: 'MacOS' })
    }

    //open modal btn
    openDisplayCertificateModal = () => {
        this.getAmpleCertOrRevocationList(this.state.currentTab);
        this.setState({ isOpenUploadCertModal: true, isOpenNetworkGroupModal: false, alertMessage: {} })
    }

    //close modal
    closeDisplayCertificateModal = () => this.setState({ isOpenUploadCertModal: false, alertMessage: {} })

    //tab selected
    handleTabSelect = (crntVal) => {
        if (crntVal !== this.state.currentTab) {
            this.setState({ currentTab: crntVal, uploadCertTableData: [], alertMessage: {} }, () => {
                this.getAmpleCertOrRevocationList(this.state.currentTab);
            })
        }
    }
    
    getAmpleCertOrRevocationList = (currentTab) => {
        let apiType = currentTab === 'Ample Certificate' ? 'certHistory' : 'crlHistory';
        this.setState({ isloadingCertModalData: true }, () => {
            certHistoryOrCrlHistory(apiType)
                .then((data) => {
                    this.setState({ uploadCertTableData: data, isloadingCertModalData: false })
                }).catch((err) => err)
        })
    }

    uploadCertificateFile = (e) => {
        if (this.state.currentTab === "Certificate Revocation List") {
            if (e.target.files.length !== 0) {
                this.setState({ crlFile: e.target.files[0] }, () => {
                    let formData = new FormData();
                    formData.append('fileData', this.state.crlFile);
                    uploadCertificate('crlHistoryUpload', formData)
                        .then(res => {
                            let resType = res.indexOf("successfully") !== -1 ? 'success' : 'danger'
                            this.setState({ alertMessage: { msg: res, type: resType }, crlFile: null }, () => {
                                this.getAmpleCertOrRevocationList(this.state.currentTab)
                            })
                        }).catch(err => err)
                })
            }
        } else 
            if (e.target.files.length !== 0) this.setState({ certFile: e.target.files[0], isOpenPkeyModal: true, pKeypassword: "" })
        
    }

    closePkeyModal = () => this.setState({ isOpenPkeyModal: false, pKeyAlertMessage: {} })
    onChangePkeyPassword = (value) =>{  console.log(value); this.setState({ pKeypassword: value }) }   //Ampacity is the proper password

    clickContinueBtnToUploadCertificate = () => {
        if (this.state.pKeypassword === "") {
            this.setState({ pKeyAlertMessage: { msg: "Please enter the certificate bundle password", type: "danger" } })
        } else {
            this.setState({ isOpenPkeyModal: false, pKeyAlertMessage: {} }, () => {
                let formData = new FormData();
                let data = { "password": this.state.pKeypassword }
                formData.append('fileData', this.state.certFile);
                formData.append("data", JSON.stringify(data));
                uploadCertificate('certHistoryUpload', formData)
                    .then(res => {
                        let resType = res.indexOf("successfully") !== -1 ? 'success' : 'danger'
                        this.setState({ alertMessage: { msg: res, type: resType }, certFile: null }, () => {
                            this.getAmpleCertOrRevocationList(this.state.currentTab)
                        })
                    }).catch(err => err)
            })
        }
    }
    
    handleRemoveAlertMsg = () => this.setState({ alertMessage: {}, pKeyAlertMessage: {} })

    getDGWData = () => {
        this.setState({ isLoadingTblData: true }, () => {
            getAllDGWData()
                .then((data) => {
                    if (data && data.length > 0) {
                        data.forEach(function (element) { element.status = "RUNNING"; });
                    }
                    this.setState({
                        dgwTableData: data && data.length > 0 ? data : [],
                        isLoadingTblData: false
                    })
                }).catch((err) => {
                    console.log(err);
                })
        });
    }

    /* this function handles the display of ample ceritificate */
    handleCertDisp = () => {
        this.setState({ isCertificateModalDisplay: !this.state.isCertificateModalDisplay })
    }

    veiwAmpleCertificate = () => {
        let accountId = getCookie('ACCOUNTID') || "";
        displayCertificate("ampleCert", { 'ORGID': accountId })
            .then((data) => this.setState({ certificateData: data }))
            .catch(err => console.log(err))
    }

    render() {
        return (
            <DGWContext.Provider value={{
                state: this.state,
                openDisplayCertificateModal:this.openDisplayCertificateModal,
                closeDisplayCertificateModal:this.closeDisplayCertificateModal,
                handleTabSelect: this.handleTabSelect,
                uploadCertificateFile: this.uploadCertificateFile,
                closePkeyModal: this.closePkeyModal,
                onChangePkeyPassword: this.onChangePkeyPassword,
                clickContinueBtnToUploadCertificate: this.clickContinueBtnToUploadCertificate,
                handleRemoveAlertMsg: this.handleRemoveAlertMsg,
                handleCertDisp: this.handleCertDisp,
                veiwAmpleCertificate: this.veiwAmpleCertificate
            }}>
                {this.props.children}
            </DGWContext.Provider>
        )
    }
}
export default DGWProvider;

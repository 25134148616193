import { getURL } from '../../../../providers/configProvider';
import { requestPOST, requestGET } from '../../../../services/extendedService';

export const getAllDeviceTypes = () => {
    let requestParams = { 'apiType': 'data' }
    const url = getURL('profile', 'getDeviceTypes', requestParams, true);
    return requestGET(url);
};

export const getAllProfiles = (params) => {
    let requestParams = { 'DEVICETYPE': params, 'apiType': 'data' }
    const url = getURL('profile', 'getAllProfiles', requestParams, true);
    return requestGET(url);
};

export const getProfileById = (params) => {
    let requestParams = {'id': params.profileId, 'DEVICETYPE':encodeURIComponent(params.profileType), 'FIRMWARE': params.selectedFwVersion, 'HARDWARE': params.selectedHWRevision, 'EXISTID': params.existingProfileId, 'apiType':'data'};
    const url = getURL('profile', 'profiledetail', requestParams, true);
    return requestGET(url);
};

export const deleteProfileById = (id, params) => {
    const url = getURL('profile', 'delete', { 'id':id,'apiType': 'data' });
    return requestPOST(url, params);
};
export const saveProfile = (profileType, profileData) => {
    var params = {'DEVICETYPE':profileType, 'apiType':'data'};
    const url = getURL('profile', 'save', params);
    return requestPOST(url, profileData);
};


import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import CustomReportsManagementModal from '../components/customReportsManagementModal';
import { CustomReportsManagementContext } from '../provider/customReportsManagementProvider';
import CustomReportsManagementTable from '../components/customReportsManagementTable';

class CustomReportsManagementContainer extends Component {
    static contextType = CustomReportsManagementContext;
    render() {
        return (
            <Row className="p-0 m-0">
                <Col className="p-0 m-0">
                    <CustomReportsManagementTable />
                    {this.context.state.isOpenModal && <CustomReportsManagementModal />}
                </Col>
            </Row>
        );
    }
}

export default CustomReportsManagementContainer
import React, { useContext ,useEffect, useRef} from 'react';
import { Row, Col } from 'react-bootstrap';
import {VirtualDeviceContext} from '../../provider/virtualDeviceProvider';
import TextField from '../../../../../hoc/form/textField';
import TextArea from '../../../../../hoc/form/textArea';
import SingleSelect from '../../../../../hoc/singleSelect';
import { virtualDeviceMngmntData } from '../../../../../utility/virtualDevicerManagementUtility';
import PopOverMessage from '../../../../../hoc/popOver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const AddOrEditVitualDeviceConfig=(props)=>{

    const contextData = useContext(VirtualDeviceContext);
    const vDeviceNameRef = useRef(null);
    const handleOnchangeForm=(value, field, from)=>{
        contextData.handleMainFormData(value, field, from)
    }

    const handleOnKeyDown=(item, evt)=>{
        let allDisableVal = ['e','E','+','-'];
        if(item.type === 'number'){
            if(item.field === 'default.offline.timer' && allDisableVal.some((isAvail)=> isAvail.includes(evt.key)) ){ // only decimal is required for 'online to offline' field
               evt.preventDefault()
            } else if( item.field !== 'default.offline.timer' && [...allDisableVal, '.'].some((isAvail)=> isAvail.includes(evt.key)) ){
                evt.preventDefault()
            }
        }
    }
    useEffect(()=>{
        setTimeout(()=>{
            vDeviceNameRef?.current?.focus();
        },300);
    },[])
    return (
            <Row className='p-3'>
                <Col>
                    {virtualDeviceMngmntData.formData.virtualDeviceConfig.map((item, key)=>{
                        return <React.Fragment key={key}>
                                <Row className={ contextData.state.mainFormDataErr[item.field]?.isErr || item.isSeparator ? '' : 'pb-4'} >
                                        <Col xs={(contextData.state.isErrorOnValidation || !contextData.state.isClickedContinueBtn) && contextData.state.clickedType !== 'View' ? 6: 7}> 
                                        <>
                                        {item.label}{(contextData.state.isErrorOnValidation || !contextData.state.isClickedContinueBtn) && contextData.state.clickedType !== 'View' ? item.formSectonss !== 'vdGlobalSettings' && item.field !== 'description' && '*' : ' :' }&nbsp;
                                           {item.formSectonss === 'vdDefaultSettings' && <PopOverMessage trigger="focus" keyVal={key} placement="top" popoverBody={
                                                    <div className="m-2"> {item.toolTip} </div>
                                                } displayPopOver={
                                                    <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
                                                }
                                            />
                                            }
                                        </>
                                        </Col>
                                        <Col>
                                            { (contextData.state.isErrorOnValidation || !contextData.state.isClickedContinueBtn) && contextData.state.clickedType !== 'View' ?
                                                <React.Fragment>
                                                    {item.type === 'text' || item.type === 'number' ?
                                                        <TextField
                                                            refVal = {item.field === 'name' ? vDeviceNameRef : null}
                                                            type={item.type}
                                                            field={item.field}
                                                            value={ item.formSectonss === 'top' ? contextData.state.mainFormData[item.field] : contextData.state.mainFormData[item.formSectonss]?.[item.field]?.propertyValue}
                                                            placeholder={item.placeholder}
                                                            handleOnchange={(value, field)=>handleOnchangeForm(value, field, item.formSectonss)}
                                                            size={'sm'}
                                                            isDisabled={contextData.state.clickedType === "Edit" ? ['ip', 'dnpAddress'].some((disableItem)=> disableItem.includes(item.field)) : item.isDisabled}   //on edit need to disable 3 fields ('ip', 'port', 'dnpAddress')
                                                            className= {contextData.state.mainFormDataErr[item.field]?.isErr && 'form-err-border-red'}
                                                            handleOnBlur={(value, field)=> (item.field === 'name') && contextData.checkDuplicateDeviceNameOrIP(value, field, item.formSectonss)}
                                                            // handleOnKeyDown={(value, field, evt)=>( item.type === 'number' ) && ((evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-' ) && evt.preventDefault())}
                                                            handleOnKeyDown={(value, field, evt)=> handleOnKeyDown(item, evt) }
                                                        /> : 
                                                        item.type === 'textarea' ?
                                                            <TextArea
                                                                type={item.type}
                                                                field={item.field}
                                                                value={item.formSectonss === 'top' ? contextData.state.mainFormData[item.field] : contextData.state.mainFormData[item.formSectonss]?.[item.field]?.propertyValue}
                                                                placeholder={item.placeholder}
                                                                handleOnchange={(field, val)=>handleOnchangeForm(field, val, item.formSectonss)}
                                                                size={'sm'}
                                                                rows={3}
                                                                isDisabled={item.isDisabled}
                                                                className= {contextData.state.mainFormDataErr[item.field]?.isErr && 'form-err-border-red'}
                                                        />
                                                        :   item.type === 'select' ? 
                                                            <div className='single-select-dropdown'>
                                                                <SingleSelect
                                                                    data={contextData.state.portList || [] }
                                                                    setType={(val)=>handleOnchangeForm(val, item.field, item.formSectonss)}
                                                                    value={ contextData.state.mainFormData[item.field] }
                                                                    disabled= {contextData.state.clickedType === "Edit" ? true : false}
                                                                    icon={'ok'}
                                                                />
                                                            </div>
                                                        : ''
                                                    }
                                                </React.Fragment> 
                                            :
                                                <React.Fragment>
                                                <div className='word-break-all'>
                                                    {item.formSectonss === 'top' ? (contextData.state.mainFormData[item.field] ? contextData.state.mainFormData[item.field] : '' ) : (contextData.state.mainFormData[item.formSectonss]?.[item.field]?.propertyValue ? contextData.state.mainFormData[item.formSectonss]?.[item.field]?.propertyValue : '')}
                                                </div>
                                                </React.Fragment>
                                            }
                                        </Col>
                                    </Row>
                                    {contextData.state.mainFormDataErr[item.field]?.isErr && <Row className='text-right mt-1 mb-1 error-color error-color'><Col>{contextData.state.mainFormDataErr[item.field]?.errMsg}</Col></Row>}
                                    {item.isSeparator && <hr className='mt-4 pb-2'/>}
                            </React.Fragment>
                    })}
                </Col>
            </Row>
    )
}

export default AddOrEditVitualDeviceConfig;
import React, { createContext } from 'react';
import { getCommServerDetails } from '../../manage/details/services/deviceService';
import { updateConfigureProperties } from '../services/configurePropertiesService';
import { FormattedMessage } from 'react-intl';
import { configPropertiesData } from '../../../utility/configurePropertiesUtility';
import * as configurePropertiesBuilderAction from '../actions/index';
import { connect } from 'react-redux';
import {numberOnlyRegex, numWithOptDeciRgx} from '../../../filters/commonFunction'

export const ConfigurePropertiesContext = createContext();

class ConfigurePropertiesProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configData: {},
            configDataCopy: {},
            formError: {},
            alertMessage: {},
            isloadingData: false,
            isDisableSaveBtn: true,
        }
    }

    componentDidMount() { this.getConfigData() }
    componentWillReceiveProps() { this.getConfigData() }

    updateprofiledata=(allData)=>{
       let data = allData;
        for(let i=0; i<data.length; i++){
            for(let j=i+1; j<data.length; j++){
                if(data[i].profileName === data[j].profileName){
                    data[j].isDisplay = false;
                }
            }
        }
        return data
    }

    getConfigData = () => {
        this.setState({ isloadingData: true }, () => {
            getCommServerDetails('configureinfo', { 'apiType': 'data' })
                .then((res) => {
                    let updateProfileGroupData = res.vdProfileSettings && Array.isArray(res.vdProfileSettings) && res.vdProfileSettings.length !== 0 ? this.updateprofiledata(res.vdProfileSettings) : [];
                    this.setState({formError : {}, configData: {...res, vdProfileSettings: updateProfileGroupData}, configDataCopy: {...res, vdProfileSettings: updateProfileGroupData.map((item)=> Object.assign({}, item) ) }, isloadingData: false })

                }).catch((err) => {
                    this.setState({ isloadingData: false })
                })
        })
    }

    updateFormData = (field, value, mainCat, subCat, rulesCat, from) => {
        let numOnlyRegex = numberOnlyRegex;
        if (mainCat !== undefined && mainCat === 'vdProfileSettings') {   //------------ for dnpc profile template onchange starts
            let prvData = this.state.configData.vdProfileSettings;

            //for both selected column (check box) and class column
              //for selected column (checkbox) => need to update value as true/false
              //for class => user can write anything (number, characters/special characters/empty field) but only accept the number from 0-3.
            prvData.map((item) => {
                if (item.profileName === rulesCat.original.profileName && item.pointName === rulesCat.original.pointName) {
                    item[field] = from === "checkBox" || value === "" || !numOnlyRegex.test(value) ?  value : Number(value)
                }
            })
            this.setState({
                alertMessage: {},
                configData: {
                    ...this.state.configData, vdProfileSettings: prvData
                }
            }, () => {
                let formErr = from === 'numberField' ? (Number(value) < 0 || Number(value) > 3 || value === '' || !numOnlyRegex.test(value === "true" && value === "false" ? "" : value)) ? { ...this.state.formError, [rulesCat.original.id]: true } : { ...this.state.formError, [rulesCat.original.id]: false } : this.state.formError
                this.setState({
                    isDisableSaveBtn: JSON.stringify(this.state.configDataCopy) === JSON.stringify(this.state.configData),
                    formError: formErr
                })
            })
        } else if (from !== undefined && from === 'virtualDeviceGlobalSettings') {    //------------ for dnpc global settings onchange starts
            this.setState({
                alertMessage: {},
                configData: {
                    ...this.state.configData, [mainCat]: { ...this.state.configData[mainCat], [subCat]: { ...this.state.configData[mainCat][subCat], [field]: { ...this.state.configData[mainCat][subCat][field], propertyValue: value } } }
                }
            }, () => {
                let dataFrom = this.state.configData[mainCat];
                let isRulesAvail = dataFrom[rulesCat] !== null ? dataFrom[rulesCat] : null;
                let regex = field === 'default.offline.timer' ? numWithOptDeciRgx : numOnlyRegex ;
                this.setState({
                    isDisableSaveBtn: JSON.stringify(this.state.configDataCopy) === JSON.stringify(this.state.configData),
                    formError: 
                    (isRulesAvail !== null && isRulesAvail[field].min !== null && Number(dataFrom[rulesCat][field].min) > Number(dataFrom[subCat][field].propertyValue))
                        || (isRulesAvail !== null && isRulesAvail[field].max !== null && Number(dataFrom[rulesCat][field].max) < Number(dataFrom[subCat][field].propertyValue))
                        || dataFrom[subCat][field].propertyValue === ""
                        || !regex.test(value === "true" && value === "false" ? "" : value)
                        ? { ...this.state.formError, [field]: true } : { ...this.state.formError, [field]: false }
                })
            })

        } else {
            this.setState({
                alertMessage: {}, configData: { ...this.state.configData, comPropertyGeneralData: { ...this.state.configData.comPropertyGeneralData, comPropertyDatas: { ...this.state.configData.comPropertyGeneralData.comPropertyDatas, [field]: { ...this.state.configData.comPropertyGeneralData.comPropertyDatas[field], propertyValue: value } } } }
            }, () => {
                let dataFrom = this.state.configData.comPropertyGeneralData;
                let isRulesAvail = dataFrom.comPropertyRules !== null ? dataFrom.comPropertyRules : null;
                if (field === "scheduler.fastpoll" || field === "scheduler.integritypoll") {
                    this.setState({
                        isDisableSaveBtn: JSON.stringify(this.state.configDataCopy) === JSON.stringify(this.state.configData),
                        formError: {
                            ...this.state.formError,
                            "scheduler.fastpoll": !(Number(dataFrom.comPropertyDatas["scheduler.fastpoll"].propertyValue) >= Number(dataFrom.comPropertyRules["scheduler.fastpoll"].min) && Number(dataFrom.comPropertyDatas["scheduler.fastpoll"].propertyValue) <= Number(dataFrom.comPropertyRules["scheduler.fastpoll"].max)) || !numOnlyRegex.test(dataFrom.comPropertyDatas["scheduler.fastpoll"].propertyValue),
                            "scheduler.integritypoll": Number(dataFrom.comPropertyDatas["scheduler.fastpoll"].propertyValue) >= Number(dataFrom.comPropertyDatas["scheduler.integritypoll"].propertyValue) || !(Number(dataFrom.comPropertyDatas["scheduler.integritypoll"].propertyValue) >= Number(dataFrom.comPropertyRules["scheduler.integritypoll"].min) && Number(dataFrom.comPropertyDatas["scheduler.integritypoll"].propertyValue) <= Number(dataFrom.comPropertyRules["scheduler.integritypoll"].max)) || !numOnlyRegex.test(dataFrom.comPropertyDatas["scheduler.integritypoll"].propertyValue),
                        }
                    })
                } else {
                    this.setState({
                        isDisableSaveBtn: JSON.stringify(this.state.configDataCopy) === JSON.stringify(this.state.configData),
                        formError: (isRulesAvail !== null && isRulesAvail[field].min !== null && Number(dataFrom.comPropertyRules[field].min) > Number(dataFrom.comPropertyDatas[field].propertyValue))
                            || (isRulesAvail !== null && isRulesAvail[field].max !== null && Number(dataFrom.comPropertyRules[field].max) < Number(dataFrom.comPropertyDatas[field].propertyValue))
                            || dataFrom.comPropertyDatas[field].propertyValue === ""
                            || !numOnlyRegex.test(value === "true" || value === "false" ? "" : value)
                            ? { ...this.state.formError, [field]: true } : { ...this.state.formError, [field]: false }
                    })
                }
            })
        }
    }

    checkInputsError = () => {
        let tmpArr = [];
        Object.values(this.state.formError).includes(true) ?  tmpArr.push(true) : tmpArr = [];
        return tmpArr
    }

    checkVirtualDeviceProfileError=()=>{
        let allProfileTemplateArr = this.state.configData.vdProfileSettings !== null ? this.state.configData.vdProfileSettings : [];
         let allCheckedValue = [];
         allProfileTemplateArr.length !==0 && allProfileTemplateArr.map((item)=> item.selected && allCheckedValue.push(item.selected) )
         return allCheckedValue;
    }

    updateConfigProperties = () => {
        let isInputErrAvail = this.checkInputsError();
        let isEmptyDnpcProfile = this.checkVirtualDeviceProfileError();
        if (isInputErrAvail.length !== 0) {
            this.setState({
                alertMessage: { msg: <FormattedMessage id='db.common.pleaseCorrectTheErrors' />, type: 'danger' }
            })
        } else if(isEmptyDnpcProfile.length === 0 ){  
            this.setState({
                alertMessage: { msg: <FormattedMessage id='vdm.validation.selectOneDeviceProfile' /> , type: 'danger' }
            })
        } else {
            this.setState({ isloadingData: true }, () => {
                updateConfigureProperties(this.state.configData)
                    .then((res) => {
                        if (res.status === "FAIL") this.setState({ alertMessage: { msg: res.message, type: 'danger' }, isloadingData: false })
                        else {
                            this.setState({ alertMessage: { msg: <FormattedMessage id='configureproperty.message.saveconfigure.success' />, type: 'success' }, isloadingData: false })
                            this.props.updateOldMtfUploadViewData(this.state.configData.comPropertyGeneralData.comPropertyDatas['default.mtf.upload'].propertyValue);
                        }
                    }).catch((err) => { this.setState({ isloadingData : false }) })
            })
        }
    }
    closeConfigModal = () => {
        configPropertiesData.isOpenModal = false;
        let allFormFields = configPropertiesData.formFields;
        allFormFields.map((item, key) => key === 0 ? item.isDisplay = true : item.isDisplay = false)
        this.setState({ alertMessage: {}, configData: {}, isDisableSaveBtn: true, formError:{} })
    }

    closeAlertMessage = () => {
        this.setState({ alertMessage: {} })
    }

    render() {
        return (
            <ConfigurePropertiesContext.Provider value={{
                state: this.state,
                updateFormData: this.updateFormData,
                updateConfigProperties: this.updateConfigProperties,
                closeConfigModal: this.closeConfigModal,
                closeAlertMessage: this.closeAlertMessage
            }}>
                {this.props.children}
            </ConfigurePropertiesContext.Provider>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateOldMtfUploadViewData: (data) => dispatch(configurePropertiesBuilderAction.updateOldMtfUploadViewData(data))
    }
}

export default connect(null, mapDispatchToProps)(ConfigurePropertiesProvider);
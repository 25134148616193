export const menuItems = [
    { key: "addRegion", value: "rootnode", displayText: "Add Region", isDisabled: false },
    { key: "addSubstation", value: "region", displayText: "Add Substation", isDisabled: false },
    { key: "addFeeder", value: "substation", displayText: "Add Feeder", isDisabled: false },
    { key: "addSite", value: "selected", displayText: "Add Site", displayType: ["lateral", "feeder"], isDisabled: false },
    { key: "addLateral", value: "feeder", displayText: "Add Lateral", isDisabled: false },
    { key: "enableGPS", value: "selected", displayText: "Enable GPS", displayType: ["region", "substation", "feeder"], isDisabled: false },
    { key: "edit", value: "selected", displayText: "Edit",displayType: ["region", "substation", "feeder", "site", "lateral_site"], isDisabled: false },
    { key: "delete", value: "selected", displayText: "Delete", displayType: ["region", "substation", "feeder", "lateral", "site", "lateral_site"], isDisabled: false },
    { key: "details", value: "common", displayText: "Details", isDisabled: false },
    { key: "editSiteOrder", value: "lateral", displayText: "Edit Site Order", isDisabled: false }
];

export const getNodeDetailsField = {
    "ROOTNODE": ["regionCount", "subStationCount", "feederCount", "feederSiteCount", "lateralCount", "lateralSiteCount", "elementCount"],
    "REGION": ["subStationCount", "feederCount", "feederSiteCount", "lateralCount", "lateralSiteCount", "elementCount"],
    "SUBSTATION": ["feederCount", "feederSiteCount", "lateralCount", "lateralSiteCount", "elementCount"],
    "FEEDER": ["feederSiteCount", "lateralCount", "lateralSiteCount", "elementCount"],
    "SITE": ["address", "deviceLocation", "elementCount"],
    "LATERAL": ["lateralSiteCount", "elementCount"],
    "LATERAL_SITE": ["address", "deviceLocation", "elementCount"]
}
import { getURL } from '../../../../providers/configProvider';
import { requestPOST, requestGET } from '../../../../services/extendedService';

export const getConfigStatusData = (params, type) => {
    params['apiType'] = 'config';
    const url = getURL('currentjobs', type, params);
    return requestGET(url);
}

export const postConfigStatusData = (params, devices, type) => {
    params['apiType'] = 'config';
    const url = getURL('currentjobs', type, params);
    return requestPOST(url, devices);
}
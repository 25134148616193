import { checkSession, checkSSOCheckLogin, roleAndPermission, getIP, audirTrailPostLogin } from '../services/homeService';
import * as homeDispatch from './homeActionDispatch';
import * as loginDispatch from '../../login/actions/loginActionDispatch';
import { samlLogout, samlFailed } from '../../header/actions/headerActionDispatch';
import {userPreferences} from '../../services/utilityService';

export const setSession = (samlInfo) => {
    return dispatch => {
        checkSession()
            .then(response => {
                if (response.data.data !== undefined) {
                    const res = [];
                    response['loggedIn'] = true;
                    auditTrailLogin(dispatch, response);
                } else {
                    dispatch(homeDispatch.setSession({"loggedIn": false, "sessionData": null}));
                    const [searchParams] = new URLSearchParams(window.location.search);
                    searchParams?.[0] === "code" ? window.location.href = `${window.location.origin}/amplemanage/login`:'';
                }
            })
            .catch(function (error) {
                //  dispatch(homeDispatch.setSession(error)); commenting because it is causing redirecting error when 401 response
                const [searchParams] = new URLSearchParams(window.location.search);
                if(searchParams?.[0] === "code"||error.response?.status===401){
                    localStorage.clear();
                    window.location.href = `${window.location.origin}/amplemanage/login`;
                }
            });
    };
}

export const skipSetSession = () =>{
    return dispatch =>{
        dispatch(homeDispatch.setSession({"loggedIn": false, "sessionData": null}));
    }
}

const auditTrailLogin = (dispatch, sessionResponse) => {
    getIP().then(response => {
        if (response && response.data && response.data.data) {
            let params = { 'apiType': 'data', 'IPADDRESS': response.data.data };
            let reqBody = {
                "id": '',
                "userId": "",
                "ipAddress": "",
                "action": "login",
                "timeOfAction": new Date().getTime(),
                "serialNumber": "",
                "sitePhase": "",
                "additionalInfo": ""
            };
            audirTrailPostLogin(params, reqBody).then(res => {
                dispatch(homeDispatch.setSession(sessionResponse));
                dispatch(samlLogout(false));
                dispatch(samlFailed(false));
            }).catch(error => {
                console.log(" error while updating audit trail information", error);
            });

        }
    }).catch(error => { });
}

export const ssoLogin = (accountid, email) => {
    return dispatch => {
        checkSSOCheckLogin(accountid, email).then(resp => {
            localStorage.setItem('orgId', accountid); //setting this for token api call
            if (resp.data.data && resp.data.data !== 'ssouser') window.location.href = resp.data.data; 
            else if (resp.status === 200 && resp.data.status !== "FAIL") dispatch(loginDispatch.updateFormLogin(true, "", resp.data?.data === 'ssouser' ));
            else dispatch(loginDispatch.updateFormLogin(false, resp.data.message, false));
        });
    }
}

export const updateLogin = (bool, errMsg) => {
    return dispatch => {
        dispatch(loginDispatch.updateFormLogin(bool, errMsg, false));
    };
};

export const getAllRoleAndUserPermission = () => {
    return dispatch => {
        roleAndPermission()
            .then(response => {
                let data = response.data.message === "Success" ? response.data.data : {}
                userPreferences.tempUnit = response.data.data.principalData.temperaturePreference;
                userPreferences.treeOrder = response.data.data.principalData.treeOrder;
                dispatch(homeDispatch.getAllRoleAndUserPermission(data));
            })
            .catch(function (error) {
            });
    }
}
import React, { createContext } from 'react';
import { selectedColumn } from '../../../utility/userManagementUtility';
import { getUsersList, getCurrentUser, getAllTimeZoleList } from '../services/userService';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
export const UserContext = createContext();

let currentUserDetails = {};

class UserProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColumns: selectedColumn.data,
            isLoading: false,
            usersList: [],
            isOpenModal: false,
            addOrEdit: 'add',
            allChecked: [],
            rowData: {},
            formData: {  firstName: "", middleName: "", lastName: "", email: "", newPassword: "", confirmPassword: "", role: 3, timezone: "(UTC-08:00) PST", temperaturePreference: "F" },
            selectAll:false,
            allTimeZoneData: []
        }
    }

    static getDerivedStateFromProps(nextProps) {
        if (Object.keys(nextProps.roleAndPermission).length > 0 && nextProps.roleAndPermission.samlEnabled) {
            const finalColumns = selectedColumn.data.filter(col => !["enabled", "resetPasswordRequired"].includes(col));
            return {
                displayColumns: finalColumns
            }
        }
        return null;
    }

    componentDidMount() {
        this.getCurrentUserDetails();
        !sessionStorage.getItem("userManagementExpandColumn") ? sessionStorage.setItem("userManagementExpandColumn", JSON.stringify(this.state.displayColumns)) : this.setState({displayColumns:[...JSON.parse(sessionStorage.getItem("userManagementExpandColumn"))]});
    }

    getCurrentUserDetails = () => {
        this.setState({ isLoading: true }, () => {
            getCurrentUser()
                .then(data => {
                    // this.setState({ isLoading: false })
                    currentUserDetails = data;
                    this.getAllUsersList();
                }).catch(error => {
                    this.setState({ isLoading: false })
                })
        })
    }

    getAllUsersList = () => {
        getUsersList()
            .then(data => {
                let newData = data.length !== 0 ? data : [];
                let updateValue = newData.map((obj, key) => ({ ...obj, 'enabled': obj.enabled.toString()[0].toUpperCase()+obj.enabled.toString().slice(1), resetPasswordRequired: obj.resetPasswordRequired.toString()[0].toUpperCase()+ obj.resetPasswordRequired.toString().slice(1)}))
                this.setState({ usersList: updateValue, isOpenModal: false, allChecked: [] , isLoading: false })
            }).catch(error => {
                this.setState({ isLoading: false })
            })
    }

    filterUserTableColumn = (value) => {
        let prvData = this.state.displayColumns;
        let filterData = [];
        if (prvData.includes(value)) filterData = prvData.filter(item => item !== value)
        else filterData = [...prvData, value]
        selectedColumn.data = filterData;
        sessionStorage.setItem("userManagementExpandColumn",  JSON.stringify(filterData));
        this.setState({ displayColumns: filterData })
    }

    handleAddEditOrDeleteUser = addEditOrDelete => {
        this.setState({ isOpenModal: true, addOrEdit: addEditOrDelete }, async () => {
            if (this.state.addOrEdit === 'edit') {
                this.setState({
                    formData: this.updateFormDataOnClickEdit(this.state.addOrEdit, this.state.rowData)
                })
            } else {
                let getallTmZone = this.state.allTimeZoneData.length === 0 && this.state.addOrEdit === 'add' ? await getAllTimeZoleList() : this.state.allTimeZoneData ;
                let displayTimeZone = this.updateTimeZone(getallTmZone)
                this.setState({
                    formData: { firstName: "", middleName: "", lastName: "", email: "", newPassword: "", confirmPassword: "", role: 3, timezone: displayTimeZone !== "" ? displayTimeZone :  "(UTC-08:00) PST", temperaturePreference: "F" }
                })
            }
        })
    };

    updateTimeZone=(allTimeZone)=>{
        this.setState({ allTimeZoneData : allTimeZone })
        let tmz = ""
        allTimeZone.forEach(item=> {
            var split = item.split(" ");
            if(this.props.timezone === split[1] ) tmz = item
        })
        return tmz
    }

    updateFormDataOnClickEdit = (addOrEdit, rowData) => {
        return {
            firstName: rowData.firstName,
            middleName: rowData.middleName,
            lastName: rowData.lastName,
            email: rowData.email,
            role: addOrEdit === 'edit' ? rowData.role : 3,
            timezone: addOrEdit === 'edit' ? rowData.timezone : this.state.formData.timezone,
            temperaturePreference: addOrEdit === 'edit' ? rowData.temperaturePreference : this.state.formData.temperaturePreference
        }
    }

    onCloseModal = () => this.setState({ isOpenModal: false });

    handleOnChangeCheckBox = (rowInfo, e) => {
        let preArr = this.state.allChecked;
        let filterArr = [];
        if (e.target.checked) filterArr = [...this.state.allChecked, rowInfo.principalId]   //if checked true then append principalId to this.state.allChecked 
        else{
            filterArr = preArr.filter(item => item !== rowInfo.principalId)    // if checked false remove that principalId from this.state.allChecked 
            if(filterArr.length !== this.state.usersList.length){
                this.setState({selectAll : false});
            }else{
                this.setState({selectAll : true});
            }
        } 

        let rowData = {};   // when selected only one row, store that row details for Update(edit) user data
        if (filterArr.length === 1) {
            rowData = this.state.usersList.find(item => item.principalId === filterArr[0])
        }

        this.setState({ allChecked: filterArr, rowData: rowData })
    }

    handleSelectAll = (e) => {
        let prvArr = this.state.usersList.filter((item, key) => item.principalId !== currentUserDetails.principalId && item.role >= currentUserDetails.role );
        let allCheckedData = [];
        let selectAll = false;
        if (e.target.checked){
            prvArr.map(item => allCheckedData.push(item.principalId));
            selectAll = true;
        } 
        this.setState({ allChecked: allCheckedData,selectAll : selectAll})
    }

    handleDisableUser = (clickedIcon, data) => {
        this.setState({ isOpenModal: true, addOrEdit: clickedIcon, rowData: data })
    }

    handleOnchangeForm=(value, field)=>{
        this.setState({ formData: { ...this.state.formData, [field]: value }})
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <UserContext.Provider value={{
                state: this.state,
                filterUserTableColumn: this.filterUserTableColumn,
                handleAddEditOrDeleteUser: this.handleAddEditOrDeleteUser,
                onCloseModal: this.onCloseModal,
                handleOnChangeCheckBox: this.handleOnChangeCheckBox,
                handleSelectAll: (e) => this.handleSelectAll(e,formatMessage),
                handleDisableUser: this.handleDisableUser,
                getAllUsersList: this.getAllUsersList,
                handleOnchangeForm: this.handleOnchangeForm,
                currentUserDetails: currentUserDetails

            }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

const mapStateToProps = state => {
    return {
        timezone: state.loginData.roleAndPermission.timezone,
        roleAndPermission: state.loginData.roleAndPermission
    };
}

export default connect(mapStateToProps, {})(injectIntl(UserProvider));
// export const UserConsumer = GlobalContext.Consumer;
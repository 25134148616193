import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEventLogData } from '../services/registerService';
import { getNodePath } from '../../../../services/utilityService';
import { registerData, keyValue, closeUnusedTreeNodes } from '../../../../utility/faultAnalysisUtility';
import { endDate, startDateDuration } from '../../../../filters/dateHelper';

export const RegisterContext = React.createContext();

class RegisterProvider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            treeLoading: false,
            treeNode: this.props.treeNode,
            updatedTreeData: this.props.updatedTreeData,
            timeZone: this.props.roleAndPermission.timezone,
            isLoadingData: false,
            lastModifiedTime: 0,
            nodeData: null,
            tableDataset: [],
            filteredTableDataset: [],
            orgDetails: this.props.tree[0],
            totalBadgeCount: 0,
            formErr: {},
            filterFormOptionData: { faultState: [], faultType: [] },
            formData: { faultState: registerData.selectedData.faultState, faultType: registerData.selectedData.faultType, minRange: registerData.selectedData.minRange, maxRange: registerData.selectedData.maxRange },
            ...registerData,
            showLoadMore: false,
            pageNumber: 1,
            allFilteredTableDataset: [],
            pageSize: 25
        }
    }
    componentDidMount() {
        this.generateFaultFilters();
        !this.props.dashboardFlag && this.loadData(this.state.treeNode);
        this.interval = setInterval(() => this.getRegisterData(), this.state.intervalTime);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
        this.props.dashboardFlag && this.props.setDashboardFlag()
    }

    static getDerivedStateFromProps(nextProps) {
        return {
            treeNode: nextProps.treeNode,
            breadCrumbName: nextProps.breadCrumbName,
            treeLoading: nextProps.treeLoader
        };
    }
    componentDidUpdate(prevProps) {
        if (!this.state.treeLoading && JSON.stringify(this.state.breadCrumbName) !== JSON.stringify(prevProps.breadCrumbName)) {
            !this.props.dashboardFlag && this.loadData(this.state.treeNode);
        }
    }
    getNodeName = (nodeData, type) => {
        let text = null;
        nodeData && nodeData.routeParams && nodeData.routeParams.length && nodeData.routeParams.forEach(item => {
            if (item.type && type === item.type) {
                text = item.name;
            }
        })
        return text;
    }
    loadData = (nodeData, nodeValue, type) => {
        if (nodeValue && type !== 'ROOTNODE') {
            const index = this.state.treeNode.routeParams && this.state.treeNode.routeParams.findIndex(item => item === type);
            let obj = this.state.treeNode.routeParams ? index !== -1 ? this.state.treeNode.routeParams[index - 1].children.find(item => item.name === nodeValue) :
                this.state.treeNode.children.find(item => item.name === nodeValue) :
                this.state.treeNode.children.find(item => item.type === type && item.name === nodeValue);
                obj = closeUnusedTreeNodes(obj,type);
            obj && this.props.loadNextLevel({ "node": obj }, obj.routeParams, null, obj.name);
        } else if (!nodeData || (nodeValue && type === 'ROOTNODE')) {
            this.props.loadNextLevel({ "node": this.state.orgDetails }, null, null, this.state.orgDetails.name);
        } else if (nodeData && nodeData.name) {
            if (nodeData && ((nodeData.type !== 'SITE') && (nodeData.type !== 'LATERAL') && (nodeData.type !== 'LATERAL_SITE'))) {
                this.setState({ lastModifiedTime: 0 }, () => this.getRegisterData());
            } else {
                // Lateral and sites are disabled so redirect to feeder node
                let obj = nodeData.routeParams && nodeData.routeParams.length && nodeData.routeParams.find(item => item.type === "FEEDER")
                obj && this.props.loadNextLevel({ "node": obj }, obj.routeParams, null, obj.name);
            }
        }
    };
    zoomChange = (value) => {
        if (this.state.selectedZoomRange !== value) {
            registerData.selectedZoomRange = value;
            this.setState({
                selectedZoomRange: value,
                lastModifiedTime: 0
            }, () => this.getRegisterData());
        }
    }

    getRegisterData = () => {
        let endDateTimestamp = endDate();
        let startDateTimestamp = startDateDuration(this.state.selectedZoomRange);
        const params = {
            'STARTTIMESTAMP': new Date(startDateTimestamp).setHours(0, 0, 0, 0),
            'ENDTIMESTAMP': new Date(endDateTimestamp).setHours(23, 59, 59, 999),
            'LASTMODIFIEDTIME': this.state.lastModifiedTime,
            'NODEPATH': (this.state.treeNode && this.state.treeNode.routeParams) ? getNodePath(this.state.treeNode.routeParams, true, true) : this.state.orgDetails.id,
            'apiType': 'data'
        };
        this.setState({ isLoadingData: true });
        getEventLogData(params)
            .then(data => {
                if (data && data.status !== 'FAIL') {
                    if (((this.state.lastModifiedTime != 0) && (data.hasOwnProperty('tableData'))) || (this.state.lastModifiedTime === 0)) {
						var tableData = data && data.tableData ? data.tableData : [];
                        this.setState({
                                tableDataset: tableData, 
                                filteredTableDataset: this.filterTableDataSet([...tableData]).slice(0,this.state.pageSize),
                                allFilteredTableDataset: this.filterTableDataSet([...tableData]),
                                lastModifiedTime: data && data.lastModified ? data.lastModified : 0,
                                showLoadMore: this.filterTableDataSet([...tableData]).length > this.state.pageSize
                        })
					}
                    this.setState({ isLoadingData : false })

                } else {
                    this.setState({ tableDataset: [], filteredTableDataset: [], allFilteredTableDataset: [], lastModifiedTime: 0, isLoadingData: false });
                }
            },
                function (error) {
                    // $rootScope.showError('common.error.downloaddata', 'common.error.requestfailed', false, error);
                    this.setState({ isLoadingData: false })
                }
            );
    }

    loadMoreFilteredTableData = () => {
        this.setState({pageNumber: this.state.pageNumber + 1}, () => {
            this.setState({ filteredTableDataset: this.state.allFilteredTableDataset.slice(0,(this.state.pageNumber * this.state.pageSize)) }, () => {
                (this.state.allFilteredTableDataset.length === this.state.filteredTableDataset.length) && this.setState({showLoadMore: false});
            })
        })
      }

    filterTableDataSet = (dataList) => {
        let appliedFilterData = registerData.selectedData;  //Only applied filters value we are taking here, if user changed the filter value and not applied then we are not considering that value
        dataList = appliedFilterData.faultType.length > 0 ? keyValue(dataList, appliedFilterData.faultType, "eventType") : dataList;
        dataList = appliedFilterData.faultState.length > 0 ? keyValue(dataList, appliedFilterData.faultState, "eventState") : dataList;
        dataList = dataList && dataList.length > 0 ? dataList.filter((obj) => {
            if ((!isNaN(Number(appliedFilterData.minRange)) && appliedFilterData.minRange) && (!isNaN(Number(appliedFilterData.maxRange)) && appliedFilterData.maxRange)) {
                return obj['faultCurrent'] >= appliedFilterData.minRange && obj['faultCurrent'] <= appliedFilterData.maxRange;
            } else if (!isNaN(Number(appliedFilterData.minRange)) && appliedFilterData.minRange) {
                return obj['faultCurrent'] >= appliedFilterData.minRange;
            } else if (!isNaN(Number(appliedFilterData.maxRange)) && appliedFilterData.maxRange) {
                return obj['faultCurrent'] <= appliedFilterData.maxRange;
            } else {
                return true;
            }
        }) : dataList;
        return dataList;
    }

    generateFaultFilters = () => {
        this.setState({
            filterFormOptionData: {
                faultState: this.state.faultStatusList,
                faultType: this.state.faultTypeList
            }
        });
        this.updateBadgeData();
    }

    onChangeHandler = (selectedList, field) => {
        if ((field === 'minRange' || field === 'maxRange') && isNaN(selectedList)) {
            this.setState({ formData: { ...this.state.formData, [field]: '' } })
        } else this.setState({ formData: { ...this.state.formData, [field]: selectedList } }, () => {
            if (field === 'minRange' || field === 'maxRange') this.checkFormValidation(field)
        })
    }
    checkFormValidation = (field) => {
        if (field === 'minRange' && this.state.formData.minRange !== "" && this.state.formData.maxRange !== "" && Number(this.state.formData.minRange) > Number(this.state.formData.maxRange)) {
            this.setState({ formErr: { ...this.state.formErr, [field]: true } })
        } else if (field === 'maxRange' && this.state.formData.maxRange !== "" && this.state.formData.minRange !== "" && Number(this.state.formData.maxRange) < Number(this.state.formData.minRange)) {
            this.setState({ formErr: { ...this.state.formErr, [field]: true } })
        } else this.setState({ formErr: { minRange: false, maxRange: false } })    
    }

    handleShowAllBtn = (fieldName) => this.setState({ formData: { ...this.state.formData, [fieldName]: [] } })

    handleClickFilterApply = () => {
        registerData.selectedData = this.state.formData
        this.updateBadgeData();
    }
    updateBadgeData = () => {
        let num = 0;
        for (var key of Object.keys(this.state.formData)) {
            if (this.state.formData[key] !== '' && this.state.formData[key].length !== 0) num = num + 1;
        }
        if (this.state.formData.minRange !== "" && this.state.formData.maxRange !== "") num = num - 1
        if (this.state.tableDataset.length > 0 ) {
            this.setState({
                filteredTableDataset: this.filterTableDataSet([...this.state.tableDataset]).slice(0,this.state.pageSize),
                allFilteredTableDataset: this.filterTableDataSet([...this.state.tableDataset]),
                showLoadMore: this.filterTableDataSet([...this.state.tableDataset]).length > this.state.pageSize,
                totalBadgeCount: num
            })
        } else {
            this.setState({
                filteredTableDataset: [],
                allFilteredTableDataset: [],
                showLoadMore: false,
                totalBadgeCount: num
            })
        }
    }
    handleClickFilterReset = () => {
        this.setState({ formData: { faultState: [], faultType: (registerData.faultTypeList).slice(0, 3), faultClassification: [], minRange: '', maxRange: '' } }, () => {
            registerData.selectedData.faultState = [];
            registerData.selectedData.maxRange = '';
            registerData.selectedData.minRange = '';
            registerData.selectedData.faultType=(registerData.faultTypeList).slice(0, 3);
            this.updateBadgeData()
        })
    }

    getOutSideClose = () => {   //filter dropwdown close from outside click and update the state (formData) value to the previous applied filter data
        this.setState({ formData: registerData.selectedData })
    }

    render() {
        return (
            <RegisterContext.Provider value={{
                state: this.state,
                zoomOnChange: this.zoomChange,
                loadData: this.loadData,
                onChangeHandler: this.onChangeHandler,
                handleShowAllBtn: this.handleShowAllBtn,
                handleClickFilterApply: this.handleClickFilterApply,
                handleClickFilterReset: this.handleClickFilterReset,
                loadMoreFilteredTableData: this.loadMoreFilteredTableData,
                getOutSideClose: this.getOutSideClose,
            }}>
                {this.props.children}
            </RegisterContext.Provider>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission,
        tree: state.treeviewData.tree
    }
}
export default connect(mapStateToProps, {})(RegisterProvider)

export const RegisterConsumer = RegisterContext.Consumer;
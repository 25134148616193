import moment from 'moment-timezone';

export const getDateInLocalTime = (value) => {
    let d = new Date(Number(value))
    let dateAndTime = d.toLocaleString()
    return dateAndTime
}

export const formatDateAndTime = (value) => {
    let d = new Date(Number(value))
    let dateAndTime = d.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).replace(/,/g, "");
    return dateAndTime;
}

export const formatDateAndTimeWithoutSeconds = (value) => {
    let d = new Date(Number(value))
    let dateAndTime = d.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true }).replace(/,/g, "");
    return dateAndTime;
}

export const formatUptime = (input) => {
    var formatedValue = '';
    var oneHour = 60 * 60 * 1000;
    var d = Math.floor(input / (oneHour * 24));
    var h = Math.floor(input / oneHour) % 24;
    var m = Math.floor((input % oneHour) / (60 * 1000));
    if (d) formatedValue += d + 'd ';
    if (d > 0 || h) formatedValue += h + 'h ';
    if (m) formatedValue += m + 'm ';
    return formatedValue;
}

export const endDate = () => {
    return new Date().setHours(23, 59, 59, 999);
}

export const startDateDuration = (duration) => {
    return new Date().setHours(23, 59, 59, 999) - ((duration * 1000 * 3600 * 24) - 1);
}

export const momentFormatDateAndTime = (value) => {
    return moment(value).format("MM/DD/YYYY hh:mm:ss A")
}

export const isGreaterThanTodaysDate = (somedate) => {
    return somedate && somedate !== 'Not Visible' && somedate !== 'Available to License' && moment(new Date(somedate)) >= moment(new Date())
}
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModalWindow from '../../../../hoc/modelWindow';
import { EventPrecursorContext } from '../provider/eventPrecursorProvider';
import { eventPrecursorData } from '../../../../utility/eventPrecursorUtility';
import Table from '../../../../hoc/table';
import { viewEventPrecursor } from '../services/eventPrecursorService';
import Loader from '../../../../hoc/loader';
import NoDataAvailable from '../../../../hoc/noDataAvailableComponent';
class ViewEventPrecursorModal extends Component {
  static contextType = EventPrecursorContext;
  state = {
    modalTitle: <span>Event Precursors : <span className='font-weight-normal small'>{`${this.context.state.epTableSelectedRow.region} - ${this.context.state.epTableSelectedRow.substation} - ${this.context.state.epTableSelectedRow.feeder} - ${this.context.state.epTableSelectedRow.feedersite}`}</span></span>,
    viewEventPrecursorTableColumn: [
      ...eventPrecursorData.viewEventPrecursorTableColumn,
    ],
    tableLoader: false,
    viewEventPrecursorTableData: []
  }
  componentDidMount() {
    eventPrecursorData.viewEventPrecursorTableOptions.pageNo = 1;
    eventPrecursorData.viewEventPrecursorTableOptions.pageSize = 5;
    this.setState({
      tableLoader: true
    }, () => {
      this.getTableData();
    });
  }
  getTableData = () => {
    viewEventPrecursor({
      'apiType': "data",
      'REGION': this.context.state.epTableSelectedRow.region,
      'SUBSTATION': this.context.state.epTableSelectedRow.substation,
      'FEEDER': this.context.state.epTableSelectedRow.feeder,
      'FEEDERSITE': this.context.state.epTableSelectedRow.feedersite,
      'PAGENO': eventPrecursorData.viewEventPrecursorTableOptions.pageNo,
      'PAGESIZE': eventPrecursorData.viewEventPrecursorTableOptions.pageSize
    })
      .then(data => {
        if (data) {
          this.setState({
            totalDevicesCount: data.count,
            viewEventPrecursorTableData: data.inspection_details,
            tableLoader: false
          })
        } else {
          this.setState({
            viewEventPrecursorTableData: [],
            tableLoader: false
          })
        }
      }).catch(err => {
        console.log(err)
      });
  }
  onPageChange = (pageNo, sizePerPage) => {
    //window.scrollTo(0, 0);
    if (pageNo !== undefined) {
      eventPrecursorData.viewEventPrecursorTableOptions.pageNo = pageNo + 1;
    }
    this.setState({
      tableLoader: true
    }, () => {
      this.getTableData();
    });
  }

  onPageSizeChange = (sizePerPage, pageNo) => {
    if (sizePerPage !== undefined) {
      eventPrecursorData.viewEventPrecursorTableOptions.pageSize = sizePerPage;
      eventPrecursorData.viewEventPrecursorTableOptions.pageNo = 1;
    }
    this.setState({
      tableLoader: true
    }, () => {
      this.getTableData();
    });
  }
  render() {
    let modalBody = <React.Fragment>
      {
        this.state.tableLoader ? <Loader elementClass="tree-ajax-loader center-element" /> :
          <Row className={`event-precursor-table`}>
            <Col className='m-1 p-1'>
              {
                this.state.viewEventPrecursorTableData.length > 0 ?
                  <Table
                    tableColumn={this.state.viewEventPrecursorTableColumn}
                    tableRowData={this.state.viewEventPrecursorTableData}
                    pageNo={eventPrecursorData.viewEventPrecursorTableOptions.pageNo}
                    pageSize={eventPrecursorData.viewEventPrecursorTableOptions.pageSize}
                    totalSize={this.state.totalDevicesCount}
                    showPagination={true}
                    pageSizeOptions={eventPrecursorData.viewEventPrecursorTableOptions.pageSizeOptions}
                    isManualTable={true}
                    onPageChange={this.onPageChange}
                    onPageSizeChange={this.onPageSizeChange}
                    sortable={false}
                  /> :
                  <NoDataAvailable />
              }

            </Col>
          </Row>
      }
    </React.Fragment>

    return (
      <Row>
        <ModalWindow
          show={this.context.state.showModal}
          size="lg"
          dialogClassName={"event-precursor-modal modal-dialog-centered"}
          title={this.state.modalTitle}
          onBtnClickHandler={this.onBtnClickHandler}
          onHide={() => this.context.onCloseModal()}
          modeldata={{
            content: modalBody
          }}
          footer={
            []
          }
        />
      </Row>
    )
  }
}
export default ViewEventPrecursorModal;
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Loader from '../../../hoc/loader';
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../../../hoc/modelWindow';
import ToggleSwitch from '../../../hoc/toggleSwitch'
import { CustomReportsManagementContext } from '../provider/customReportsManagementProvider';
import * as CustomReportsManagementUtility from '../../../utility/customReportsManagementUtility';

class CustomReportsManagementModal extends Component {
    static contextType = CustomReportsManagementContext;

    getModalText = (titleorFooter, action) => {
        let title = "";
        (action === "edit") ?
            (title = titleorFooter === "title" ? <span><FormattedMessage id='db.common.editReport' />: {this.context.state.selectedRow.reportName}</span> : <FormattedMessage id='db.common.update' />) :
            (title = titleorFooter === "title" ?<FormattedMessage id='db.common.deleteReport' /> : <FormattedMessage id='db.common.ok' />);
        return title;
    }

    onBtnClickHandler = (value) => {
        value === 'update' ? this.context.updateCustomReport() : value === 'delete' ? this.context.deleteCustomReport() : this.context.onCloseModal()
    }

    render() {
        return (
            <Row>
                <ModalWindow
                    show={this.context.state.isOpenModal}
                    size="md"
                    dialogClassName={`${this.context.state.editOrDelete === 'edit' ? "modal-dialog-centered modal-height-md" : "modal-dialog-centered modal-height-sm"}`} 
                    title={this.getModalText('title', this.context.state.editOrDelete)}
                    onBtnClickHandler={this.onBtnClickHandler}
                    onHide={() => this.context.onCloseModal()}
                    modeldata={{
                        content: <React.Fragment>
                            {this.context.state.modalLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                            <div>{this.context.state.editOrDelete === 'delete' ? <FormattedMessage id='customReports.manageSchedule.deleteconfirmation' /> :
                                CustomReportsManagementUtility.tableOptions.editFields.map((items, keys) => {
                                    return <Row key={keys} className="pt-2">
                                        <Col xs={7}>
                                            <span>{<FormattedMessage id={`customReports.manageReports.column.header.${items.label}`} />}:</span>
                                        </Col>
                                        <Col xs={5}>
                                            <ToggleSwitch
                                                value={this.context.state.selectedRow[items.label] === "true" ? true : false}
                                                toggleSwitchValue={(e) => this.context.onToggleSwitchChange(items.label, this.context.state.selectedRow[items.label] === "true" ? "false" : "true")} />
                                        </Col>
                                    </Row>
                                })}
                            </div>
                        </React.Fragment>
                    }}
                    footer={
                        this.context.state.editOrDelete === 'edit' ?
                            [{ className: "modalSaveBtn", name: <FormattedMessage id='db.common.update' />, value: "update" }]
                            :
                            [{ className: "modalCancelBtn", name: <FormattedMessage id="dm.column.cancel" />, value: "cancel" },
                            { className: "modalSaveBtn", name: this.getModalText('footer', this.context.state.editOrDelete), value: "delete" }]
                    }
                />

            </Row>
        );
    }
}

export default CustomReportsManagementModal;
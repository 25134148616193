import React, { Component, Fragment } from 'react';
import { Col } from 'react-bootstrap';
import NoDataAvailableComponent from '../../../../hoc/noDataAvailableComponent';
import * as deviceManagementUtility from '../../../../utility/deviceManagementUtility';
import SelctAllDeviceCheckbox from '../containers/selctAllDeviceCheckbox';
import { DeviceContext } from '../provider/deviceProvider';
import Table from '../../../../hoc/table';
import ReactTablePropType from '../../../../hoc/reactTablePropType';

class deviceListTable extends Component {
    static contextType = DeviceContext;
    constructor(props) {
        super(props);
        this.state = {
            options: {
                ...deviceManagementUtility.tableOptions,
                tableColumns: [
                    {
                        Header: <SelctAllDeviceCheckbox />,
                        accessor: "checkbox",
                        Cell: row => (<input type="checkbox" onChange={(e) => this.onChangeHandler(e, row)} checked={deviceManagementUtility.tableOptions.selectedRows.includes(row.original) ? true : false} />),
                        maxWidth: 45,
                        sortable: false,
                        filterable: false
                    },
                    ...deviceManagementUtility.tableOptions.tableColumns,
                ]
            },
        }
    }

    onChangeHandler = (e, row) => { this.context.onChangeHandler(e, row) };

    onPageChange = (pageNo, sizePerPage) => {
        window.scrollTo(0, 0);
        this.props.updateDeviceMngmntUtility(pageNo, sizePerPage);
    }

    onPageSizeChange = (sizePerPage, pageNo) => {
        window.scrollTo(0, 0);
        this.props.updateDeviceMngmntUtility(pageNo, sizePerPage);
    }

    render() {
        let content = null;
        if (this.props.devices.length > 0) {
            content =
                <Col xs={12} md={12} className={"deviceList clr-fix pad-rgt-lef m-0 p-0 " + this.props.className}>
                    <ReactTablePropType
                        reactTableContainer={
                            <Table
                                tableColumn={this.state.options.tableColumns.filter(item => this.context.state.displayColumns.includes(item.accessor))}
                                tableRowData={this.props.devices}
                                pageNo={deviceManagementUtility.tableOptions.pageNo}
                                pageSize={deviceManagementUtility.tableOptions.pageSize}
                                totalSize={this.props.devicesCount}
                                onPageChange={this.onPageChange}
                                onPageSizeChange={this.onPageSizeChange}
                                pageSizeOptions={deviceManagementUtility.tableOptions.pageSizeOptions}
                                isManualTable={true}
                                lazyLoadConfigData={{ initialSize: 50, updatedSize: 50 }} />
                        } />
                </Col>
        }
        else {
            content = !this.props.loading ? <NoDataAvailableComponent /> : ""
        }

        return (
            <Fragment>
                {content}
            </Fragment>
        )
    }
}

export default deviceListTable;
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { eventPrecursorData } from '../../../../utility/eventPrecursorUtility';
import { closeUnusedTreeNodes } from '../../../../utility/faultAnalysisUtility';
import { dateConvertion, getNodePath } from '../../../../services/utilityService';
export const EventPrecursorContext = React.createContext();
import { getNodeData, eventPrecursorChartData, donloadFile } from '../services/eventPrecursorService';
import { setDownloadCSV } from '../../../../filters/commonFunction';

class EventPrecursorProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalDevicesCount: 0,
      selectedZoomRange: eventPrecursorData.selectedZoomRange,
      zoomRange: eventPrecursorData.zoomRange,
      treeNode: this.props.treeNode,
      updatedTreeData: this.props.updatedTreeData,
      roleAndPermission: this.props.roleAndPermission,
      //startTimestamp: eventPrecursorData.endTimestamp - ((30 * 1000 * 3600 * 24) - 1),
      startTimestamp: dateConvertion(eventPrecursorData.endTimestamp - (1000 * 3600 * 24), 'endDate', this.props.roleAndPermission.timezone) + 1 - (7 * 1000 * 60 * 60 * 24),
      endTimestamp: eventPrecursorData.endTimestamp - (1000 * 3600 * 24),
      eventPreCursorTableData: [],
      eventPreCursorChartData: [],
      epTableSelectedRow: {},
      epTableSelectedRowId: '',
      escalationTypeDD: {
        options: [{ name: "Alert", text: "Alert", value: "Alert", keyIndex: 0 }, { name: "Warning", text: "Warning", value: "Warning", keyIndex: 1 }, { name: "Watch", text: "Watch", value: "Watch", keyIndex: 2 }, { name: "Good", text: "Good", value: "Good", keyIndex: 3 }],
        selected: [{ name: "Alert", text: "Alert", value: "Alert", keyIndex: 0 }, { name: "Warning", text: "Warning", value: "Warning", keyIndex: 1 }, { name: "Watch", text: "Watch", value: "Watch", keyIndex: 2 }],
      },
      chartLoader: false,
      tableLoader: false,
      showModal: false,
      actionValue: "",
      totalBadgeCount: 1,
      chartFinalEndTimestamp: dateConvertion(eventPrecursorData.endTimestamp - (1000 * 3600 * 24), 'endDate', this.props.roleAndPermission.timezone),
      chartEndTimestamp: dateConvertion(eventPrecursorData.endTimestamp - (1000 * 3600 * 24), 'endDate', this.props.roleAndPermission.timezone),
      chartStartTimestamp: dateConvertion(eventPrecursorData.endTimestamp - (1000 * 3600 * 24), 'endDate', this.props.roleAndPermission.timezone) + 1 - (30 * 1000 * 60 * 60 * 24),
      message: {
        status: false,
        msg: "",
        type: ""
      },
    }
  }

  calenderCallback = (start, end) => {
    this.setState({
      ...this.state,
      startTimestamp: moment(new Date(start)), endTimestamp: moment(new Date(end))
    }, () => {
      this.getEventPrecursorTableData();
    })
  }
  zoomChange = (value) => {
    if (this.state.selectedZoomRange !== value) {
      this.setState({
        selectedZoomRange: value,
        endTimestamp: null,
        startTimestamp: null
      }, () => {
        this.setDateByRange(value)
      });
    }
  }
  setDateByRange = (range) => {
    let currentDate = (range !== 1) ? new Date().getTime() - (1000 * 3600 * 24) : new Date().getTime();
    let endDateTimestamp = dateConvertion(currentDate, 'endDate', this.props.roleAndPermission.timezone);
    let startDateTimestamp = endDateTimestamp + 1 - (range * 1000 * 60 * 60 * 24);
    this.setState({
      endTimestamp: dateConvertion(endDateTimestamp, 'short', this.props.roleAndPermission.timezone),
      startTimestamp: dateConvertion(startDateTimestamp, 'short', this.props.roleAndPermission.timezone),
    }, () => {
      this.getEventPrecursorTableData();
    });
  }
  componentDidMount() {
    let lValue = this.state.treeNode.type === "ROOTNODE" ? [this.state.treeNode.id] : getNodePath(this.state.treeNode.routeParams, true, true);
    if (lValue) {
      window.history.replaceState(null, null, location.origin + location.pathname + `?node=ROOTNODE/${lValue}`)
    }
    eventPrecursorData.tableOptions.ordering = [];
    this.getEventPrecursorTableData();
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.treeNode, ["id", "name", "type"]) !== JSON.stringify(prevProps.treeNode, ["id", "name", "type"])) {
      this.getEventPrecursorTableData();
    }
  }
  static getDerivedStateFromProps(nextProps) {
    return {
      treeNode: nextProps.treeNode,
      treeLoading: nextProps.treeLoader,
      selectSearchedNode: nextProps.selectSearchedNode
    };
  }
  handleRowOnClick = (row) => {
    if (row.event_id != this.state.epTableSelectedRow.event_id) {
      this.setState({
        epTableSelectedRow: row,
        chartLoader: true,
        chartEndTimestamp: dateConvertion(eventPrecursorData.endTimestamp - (1000 * 3600 * 24), 'endDate', this.props.roleAndPermission.timezone),
        chartStartTimestamp: dateConvertion(eventPrecursorData.endTimestamp - (1000 * 3600 * 24), 'endDate', this.props.roleAndPermission.timezone) + 1 - (30 * 1000 * 60 * 60 * 24),
      }, () => {
        this.getEventPrecursorChartData();
      });
    }
  }
  onValChange = (value) => {
    this.setState({
      escalationTypeDD: {
        ...this.state.escalationTypeDD,
        selected: value
      }
    });
  }
  updateActionValue = (pValue) => {
    this.setState({
      showModal: true,
      actionValue: pValue,
      message: {
        status: false,
        msg: "",
        type: ""
      }
    })
  }
  closeModal = () => {
    this.setState({
      showModal: false,
      actionValue: ""
    })
  }
  closeAlertMessage = () => {
    this.setState({
      message: {
        status: false,
        msg: "",
        type: ""
      }
    })
  }
  getEventPrecursorTableData = (treeNode = this.props.treeNode) => {
    this.setState({
      tableLoader: true
    }, () => {
      let endDateStr = dateConvertion(moment(this.state.endTimestamp).format('MM/DD/YYYY') + ' 23:59:59:999', 'offsetTimestamp', this.props.roleAndPermission.timezone);
      const params = {
        "REGION": treeNode.type !== "ROOTNODE" && treeNode.routeParams[3]?.name ? treeNode.routeParams[3].name : null,
        "SUBSTATION": treeNode.type !== "ROOTNODE" && treeNode.routeParams[5]?.name ? treeNode.routeParams[5].name : null,
        "FEEDER": treeNode.type !== "ROOTNODE" && treeNode.routeParams[7]?.name ? treeNode.routeParams[7].name : null,
        "PAGENO": eventPrecursorData.tableOptions.pageNo,
        "PAGESIZE": eventPrecursorData.tableOptions.pageSize,
        "ESCALATIONTYPE": this.state.escalationTypeDD.selected.length > 0 ? this.state.escalationTypeDD.selected.map(e => e.name).toString() : this.state.escalationTypeDD.options.map(e => e.name).toString(),
        "ENDTIMESTAMP": moment(dateConvertion(endDateStr, 'serviceFormat', this.props.roleAndPermission.timezone)).format("YYYY-MM-DDTHH:mm:ss"),
        "ORDERING": eventPrecursorData.tableOptions.ordering.length > 0 ? eventPrecursorData.tableOptions.ordering.map(e => e.desc ? `-${e.id}` : `${e.id}`).toString() : null,
        'apiType': "data"
      };
      getNodeData(params)
        .then(data => {
          if (data) {
            this.setState({
              totalDevicesCount: data.count,
              eventPreCursorTableData: data.event_precursors ? data.event_precursors : [],
              epTableSelectedRow: data.event_precursors ? data.event_precursors[0] : {},
              eventPreCursorChartData: [],
              tableLoader: false,
              chartLoader: data.event_precursors ? true : false
            }, () => {
              data.event_precursors && this.getEventPrecursorChartData();
            })
          } else {
            this.setState({
              eventPreCursorTableData: [],
              totalDevicesCount: 0,
              epTableSelectedRow: {},
              tableLoader: false,
            })
          }
        }).catch(err => {
          console.log(err)
        });
    })
  }
  getEventPrecursorChartData = () => {
    let startDateStr = dateConvertion(moment(this.state.chartStartTimestamp).format('MM/DD/YYYY') + ' 00:00:00:000', 'offsetTimestamp', this.props.roleAndPermission.timezone);
    let endDateStr = dateConvertion(moment(this.state.chartEndTimestamp).format('MM/DD/YYYY') + ' 23:59:59:999', 'offsetTimestamp', this.props.roleAndPermission.timezone);
    eventPrecursorChartData({
      'apiType': "data",
      'REGION': this.state?.epTableSelectedRow?.region,
      'SUBSTATION': this.state?.epTableSelectedRow?.substation,
      'FEEDER': this.state?.epTableSelectedRow?.feeder,
      'FEEDERSITE': this.state?.epTableSelectedRow?.feedersite,
      'ENDTIMESTAMP': moment(dateConvertion(endDateStr, 'serviceFormat', this.props.roleAndPermission.timezone)).format("YYYY-MM-DDTHH:mm:ss"),
    })
      .then(data => {
        if (data) {
          this.formatEventPrecursorChartData(data);
        } else this.setState({ chartLoader: false })
      }).catch(err => {
        this.setState({ chartLoader: false })
      });

  }
  formatEventPrecursorChartData = (pData) => {
    pData.filter(e => e.name === "Status")[0].lineWidth = 0;
    pData.filter(e => e.name === "Status")[0].showInLegend = false;
     let status = ''
     pData.filter(e => e.name === "Status")[0].data.forEach(e1 => {
      if(e1.status !== status){
      e1.y = Math.max(...pData[pData.findIndex(e => e.name === "Precipitation")].data.map(e => e.y)) + 5;
      e1.marker = e1.status === "Warning" ? {
        symbol: `url(${require('../../../../resources/images/warning-yellow.svg.png')})`,
        width: 16,
        height: 16,
      } : e1.status === "Alert" ? {
        symbol: `url(${require('../../../../resources/images/warning-red.svg.png')})`,
        width: 16,
        height: 16,
      } : e1.status === "Watch" ? {
        symbol: `url(${require('../../../../resources/images/warning-blue.svg.png')})`,
        width: 16,
        height: 16,
      } : {
        //symbol: `url(${require('../../../../resources/images/warning-good-new-svg.svg')})`,
        symbol: `url(${require('../../../../resources/images/warning-green-new-svg.png')})`,
        width: 13,
        height: 13,
      };
      status = e1.status;
    }
    });
    let yAxisObj = { "disturbance": 0, "weather": 2, "precipitation": 1 };
    let chartObj = {
      "Anomalies": {
        "color": "#A28B55",
        "type": "column"
      },
      "Temperature": {
        "color": "#FFA823",
        "type": "spline"
      },
      "Precipitation": {
        "color": "#3DC2EC",
        "type": "spline"
      },
      "Operational": {
        "color": "#C8ACD6",
        "type": "column",
        "visible": false
      },
      "Max Wind Gust": {
        "color": "#2F3645",
        "type": "spline"
      },
      "Bad Data": {
        "color": "#7FA1C3",
        "type": "column",
        "visible": false
      },
      "Detrimental": {
        "color": "#694F8E",
        "type": "column",
        "visible": false
      },
      "Status": {
        "type": "scatter"
      }
    }
    let finalData = pData.map(e => {
      return {
        ...e, "yAxis": e.name === "Status" ? 1 : yAxisObj[e.partOf], "color": chartObj[e.name]?.color, visible: chartObj[e.name].visible, "type": chartObj[e.name]?.type, "zIndex": ["spline", "scatter"].includes(chartObj[e.name]?.type) ? 1 : 0

      }
    });
    finalData.forEach(e => {
      e.data.forEach(e1 => e1.x = new Date(e1.x))
    })
    this.setState({
      ...this.state,
      eventPreCursorChartData: finalData,
      chartLoader: false
    })
  }
  handleClickFilterReset = () => {
    this.setState({
      escalationTypeDD: {
        ...this.state.escalationTypeDD,
        selected: this.state.escalationTypeDD.options
      },
      totalBadgeCount: 1
    }, () => {
      this.getEventPrecursorTableData();
    })
  }
  handleClickFilterApply = () => {
    this.setState({
      totalBadgeCount: this.state.escalationTypeDD.selected.length > 0 ? 1 : 0
    }, () => {
      this.getEventPrecursorTableData();
    });
  }
  prevNextMonthSelection = (pVal) => {
    let chartStartTimestamp, chartEndTimestamp;
    if (pVal === "prev") {
      chartStartTimestamp = this.state.chartStartTimestamp - (30 * 1000 * 60 * 60 * 24);
      chartEndTimestamp = this.state.chartEndTimestamp - (30 * 1000 * 60 * 60 * 24);
    }
    else {
      chartStartTimestamp = this.state.chartStartTimestamp + (30 * 1000 * 60 * 60 * 24);
      chartEndTimestamp = this.state.chartEndTimestamp + (30 * 1000 * 60 * 60 * 24);
    }
    this.setState({
      chartStartTimestamp: chartStartTimestamp,
      chartEndTimestamp: chartEndTimestamp,
      chartLoader: true
    }, () => {
      setTimeout(() => {
        this.getEventPrecursorChartData();
      }, 1000)
    });
  }
  updateEventPrecursorUtilityValues = (pageNo, sizePerPage) => {
    if (pageNo !== undefined) {
      eventPrecursorData.tableOptions.pageNo = pageNo + 1;
    }
    if (sizePerPage !== undefined) {
      eventPrecursorData.tableOptions.pageSize = sizePerPage;
      eventPrecursorData.tableOptions.pageNo = 1;
    }
    eventPrecursorData.tableOptions.selectedRows = [];
    this.setState({ tableLoader: true }, () => {
      setTimeout(() => {
        this.getEventPrecursorTableData();
        this.setState({
          tableLoader: false
        })
      }, 2000)
    })
  }
  setMessage = (status, type, msg) => {
    this.setState({
      message: {
        msg: msg,
        status: status,
        type: type
      }
    });
  }
  tableSortChange = () => {
    this.getEventPrecursorTableData();
  }
  loadData = (nodeData, nodeValue, type) => {
    if (nodeValue && type !== 'ROOTNODE') {
      const index = this.state.treeNode.routeParams && this.state.treeNode.routeParams.findIndex(item => item === type);
      let obj = this.state.treeNode.routeParams ? index !== -1 ? this.state.treeNode.routeParams[index - 1].children.find(item => item.name === nodeValue) :
        this.state.treeNode.children.find(item => item.name === nodeValue) :
        this.state.treeNode.children.find(item => item.type === type && item.name === nodeValue);
      obj = closeUnusedTreeNodes(obj, type);
      obj && this.props.loadNextLevel({ "node": obj }, obj.routeParams, null, obj.name);
    }
  }
  downloadData = (pType, treeNode = this.state.treeNode) => {
    let endDateStr = dateConvertion(moment(this.state.endTimestamp).format('MM/DD/YYYY') + ' 23:59:59:999', 'offsetTimestamp', this.props.roleAndPermission.timezone);
    const params = {
      "REGION": treeNode.type !== "ROOTNODE" && treeNode.routeParams[3]?.name ? treeNode.routeParams[3].name : null,
      "SUBSTATION": treeNode.type !== "ROOTNODE" && treeNode.routeParams[5]?.name ? treeNode.routeParams[5].name : null,
      "FEEDER": treeNode.type !== "ROOTNODE" && treeNode.routeParams[7]?.name ? treeNode.routeParams[7].name : null,
      "ESCALATIONTYPE": this.state.escalationTypeDD.selected.length > 0 ? this.state.escalationTypeDD.selected.map(e => e.name).toString() : this.state.escalationTypeDD.options.map(e => e.name).toString(),
      "ENDTIMESTAMP": moment(dateConvertion(endDateStr, 'serviceFormat', this.props.roleAndPermission.timezone)).format("YYYY-MM-DDTHH:mm:ss"),
      'apiType': "data",
      "TYPE": pType
    };
    donloadFile(params)
      .then(data => {
        if (data) {
          setDownloadCSV(data)
         }
      }).catch(err => {
        console.log(err)
      });
  }
  render() {
    return (
      <EventPrecursorContext.Provider value={{
        state: this.state,
        onCalenderCallback: this.calenderCallback,
        onZoomOnChange: this.zoomChange,
        handleRowOnClick: this.handleRowOnClick,
        onValChange: this.onValChange,
        onUpdateActionValue: this.updateActionValue,
        onCloseModal: this.closeModal,
        onHandleClickFilterApply: this.handleClickFilterApply,
        onHandleClickFilterReset: this.handleClickFilterReset,
        onPrevNextMonthSelection: this.prevNextMonthSelection,
        updateEventPrecursorUtilityValues: this.updateEventPrecursorUtilityValues,
        setMessage: this.setMessage,
        onCloseAlertMessage: this.closeAlertMessage,
        onTableSortChange: this.tableSortChange,
        loadData: this.loadData,
        downloadData: this.downloadData
      }}>
        {this.props.children}
      </EventPrecursorContext.Provider>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    roleAndPermission: state.loginData.roleAndPermission
  }
}

export default withRouter(connect(mapStateToProps, {})(EventPrecursorProvider));

export const MonitorConsumer = EventPrecursorContext.Consumer;
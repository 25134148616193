import React, { Component, Fragment } from 'react';
import { ConfigStatusContext } from '../provider/configStatusProvider';
import { tableOptions } from '../../../../utility/currentJobUtility';
import * as currentJobUtility from '../../../../utility/currentJobUtility';
import Table from '../../../../hoc/table';

class ConfigDeviceListTable extends Component {
    static contextType = ConfigStatusContext;

    constructor(props) {
        super(props);
        this.state = {
            columnData: [{
                accessor: "checkBox",
                Cell: row => (<input type="checkbox" onChange={(evt) => this.props.handleOnChangeCheckBox(evt, row.original)} className={(!currentJobUtility.deviceStatusCheck(row.original, 'deviceStatus')) && ((row.original.jobStatus === 'FAILURE') || (row.original.jobStatus === 'ABORTED') || (row.original.jobStatus === 'TIMEOUT')) ? '' : 'disabled'} checked={currentJobUtility.tableOptions.selectedRows.includes(row.original) ? true : false} />),
                maxWidth: 45,
                sortable: false,
                filterable: false
            },
            ...tableOptions.ConfigStatusColumnsData,
            ]
        }
    }

    /* Highlight the row on click */
    rowSelection = (state, rowInfo) => {
        if (rowInfo && rowInfo.original) {
            return {
                onClick: e => { 
                    rowInfo.original.serialNumber && this.context.initialDeviceSelection(rowInfo.original);
                    rowInfo.original.id && this.context.fetchDeviceParametersData(rowInfo.original.id);
                 },
                style: {
                    background: rowInfo.original.serialNumber === this.context.state.rowDevice ? "#F1D7AD" : ""
                }
            };
        } else { return {}; }
    }

    render() {
        return (
            <Fragment >
                <Table
                    tableColumn={this.state.columnData.filter(item => this.props.stateObj.displayColumns.includes(item.accessor))}
                    tableRowData={this.context.state.deviceListTableDataset}
                    showPagination={(this.context.state.deviceListTableDataset && this.context.state.deviceListTableDataset.length) > 10 ? true : false}
                    rowSelectionCallback={this.rowSelection}
                />
            </Fragment>
        )
    }
}

export default ConfigDeviceListTable;
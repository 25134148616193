import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as headerActions from '../header/actions';
import Summary from '../mainContent/manage/summary';
import Details from '../mainContent/manage/details';
import VirtualDeviceManagement from '../mainContent/manage/virtualDeviceManagement';
import Profiles from '../mainContent/manage/profiles';
import Site from '../mainContent/manage/site';
import OtapStatus from '../mainContent/manage/otapstatus';
import ConfigStatus from '../mainContent/manage/configstatus';
import ClassAssignment from '../mainContent/manage/classAssignment';
import Reports from '../mainContent/manage/reports';

class ManageRoutes extends React.PureComponent {
    
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.props.onRouteChange(this.props.location.pathname, this.props.disableTree.includes(this.props.location.pathname.slice(1)))
    }
    
    render() {
        const {roleId} = this.props.roleAndPermission
        return (
            <Switch>
                <Route path='/manage/summary'  component={Summary} />
                <Route path='/manage/details' component={Details} />
                <Route path='/manage/virtualDevicemanagement' component={VirtualDeviceManagement} />
                <Route path='/manage/profiles' render={(roleId === 3 || roleId === 4) ? () => (<Redirect to='/manage/details' />) : () => (<Profiles />)} />
                <Route path='/manage/site' component={Site} />
                <Route path='/manage/otapstatus' component={OtapStatus} />
                <Route path='/manage/configstatus' component={ConfigStatus} />
                <Route path='/manage/classAssignment' component={ClassAssignment} />
                <Route path='/manage/reports' component={Reports} />
            </Switch>
        );
    }
}

const mapStateToProps = state => {
    return {
        disableTree: state.headerData.disableTree,
        roleAndPermission: state.loginData.roleAndPermission,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onRouteChange: (route, isTreeDisable) => dispatch(headerActions.onRouteChange(route, isTreeDisable))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageRoutes));
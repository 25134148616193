import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { DGWContext } from '../provider/dgwProvider';
import TextField from '../../../hoc/form/textField';
import Alert from '../../../hoc/alert';
import ModalWindow from '../../../hoc/modelWindow';

class DgwPrivateKeyModal extends Component {
    static contextType = DGWContext;

    onBtnClickHandlera=(value)=>{
        if (value === 'cancel') this.context.closePkeyModal();
        else if(value === 'continue') this.context.clickContinueBtnToUploadCertificate();
    }
    render() {
        return (
            <Row>
                <Col>
                <ModalWindow 
                    show={this.context.state.isOpenPkeyModal}
                    size="lg"
                    dialogClassName={"pkey-modal"}
                    title={<FormattedMessage id='db.common.password' />}
                    onBtnClickHandler={this.onBtnClickHandlera}
                    onHide={this.context.closePkeyModal}
                    modeldata={{
                        content: <React.Fragment>
                            {this.context.state.pKeyAlertMessage.msg !== undefined && this.context.state.pKeyAlertMessage.msg !== "" ?
                                <Row>
                                    <Col>
                                        <Alert
                                            type={this.context.state.pKeyAlertMessage.type}
                                            message={ this.context.state.pKeyAlertMessage.msg}
                                            setVisible={this.context.handleRemoveAlertMsg} />
                                    </Col>
                                </Row>
                                : ""}
                            <Row>
                                <Col xs={4}> <FormattedMessage id='db.sg.private_key_password' /></Col>
                                <Col xs={8}>
                                    <TextField type='password' value={this.context.state.pKeypassword} placeholder='' handleOnchange={this.context.onChangePkeyPassword} size='sm' />
                                </Col>
                            </Row>
                        </React.Fragment>
                    }}
                    footer={
                        [{ className: "modalCancelBtn", name: <FormattedMessage id='dm.column.cancel' />, value: "cancel" },{ className: "modalSaveBtn", name: <FormattedMessage id='db.common.continue' />, value: "continue" }]
                    }
                />
                </Col>
            </Row>
        );
    }
}

export default DgwPrivateKeyModal;
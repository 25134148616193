import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DetailsContext } from '../provider/detailsProvider';
import GoogleMapReact from 'google-map-react';
import StreetViewModal from '../../../mapView/components/streetViewModal';
import Loader from '../../../../hoc/loader';

class FaultMap extends PureComponent {
    static contextType = DetailsContext;

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { mapZoom, center, isOpenStreetViewModal, streetViewLabel, isLodingStreet, streetViewAvailable } = this.context.state;
        return (
            <Row className='m-0 p-0'>
                <Col className='m-0 p-0'>
                <div className="map-view">
                {this.context.state.loading ? <div className="loader-wrap"><Loader elementClass="tree-ajax-loader center-element" /></div> : ""}
                    <GoogleMapReact
                        center={center}
                        defaultZoom={mapZoom}
                        options={this.context.getOptions}
                        onGoogleApiLoaded={({map, maps}) => this.context.googleApiLoaded(map, maps)}
                        yesIWantToUseGoogleMapApiInternals
                        bootstrapURLKeys={{key: `AIzaSyCQJt0udeazaJKwRMvMBm3a4I21WOT7KQA`}}
                    />
                    </div>

                    {isOpenStreetViewModal ?
                    <StreetViewModal
                        onCloseModal={this.context.closeStreetViewModal}
                        streetViewLabel={streetViewLabel}
                        isLodingStreet={isLodingStreet}
                        streetViewAvailable={streetViewAvailable}
                    /> : ""}
                </Col>
            </Row>
        )
    }
}

export default FaultMap;
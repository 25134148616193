import React from 'react';
//import HighCharts from '../../../resources/libs/highCharts/highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighCharts from "highcharts/highcharts";
import addHeatmap from "highcharts/modules/heatmap";
import addTreemap from "highcharts/modules/treemap";
import { injectIntl } from 'react-intl';

addHeatmap(HighCharts);
addTreemap(HighCharts);

class HeatMapComponent extends React.Component {
    constructor(props){
        super(props);
        this.chart = null;
    }
    componentDidMount(){
        this.chart = this.refs.chart.chart;
        this.chart.component = this;
    }
    loadPointData=(pointData)=>{
        this.props.handleHeatMapPointClick(pointData);
    }
    render(){
        const { formatMessage } = this.props.intl;
    return (
            <span className='set-width-height'>
                
                <HighchartsReact 
                    highcharts = {HighCharts}
                    // options = {this.props.options} //without format message for all options data
                    options={{ //with format message
                        ...this.props.options,
                        ...{
                            tooltip: {
                                useHTML: true,
                                padding: 10,
                                headerFormat: '<div class="padding-0-10 padding-b-10"><table>',
                                pointFormat: '<tr><td colspan="2"><h6><strong>{point.name}</strong></h6></td></tr>' +
                                    `<tr><td style="padding-right: 0.5vh">{point.type} ${formatMessage({ id: "db.heatMap.device" })}: </td><td>{point.colorValue}</td></tr>` +
                                    `<tr><td style="padding-right: 0.5vh">${formatMessage({ id: "db.bubbleChart.deviceCount" })}: </td><td>{point.value}</td></tr>`,
                                footerFormat: '</table></div>'
                            },
                        }
                    }}
                    ref={"chart"}
                />
            </span>
            )
    }
}

export default injectIntl(HeatMapComponent);

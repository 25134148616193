import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { loadNotification } from '../services/emailSubscriptionService';
import Loader from '../../../hoc/loader';
import BlankPage from '../../../hoc/blankPage';

class NotificationTypeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationData: [],
            isloadingNotifiDataData: false,
        }
    }
    componentDidMount() {
        this.getSubscribedNotificationData();
    }

    componentWillReceiveProps() {
        this.getSubscribedNotificationData();
        this.setState({ alertMessage: {}, notificationData: [] })
    }

    getSubscribedNotificationData = () => {
        this.setState({ isloadingNotifiDataData: true }, () => {
            loadNotification({ 'ORGID': this.props.tree[0].id, 'apiType': 'data' })
                .then((res) => {
                    let resData = res && res.length !== 0 ? res : [];
                    this.setState({ notificationData: resData, isloadingNotifiDataData: false },()=>{this.props.handleOnCheckNotification(this.state.notificationData)})
                }).then((err) => { })
        })
    }
    handleOnCheckNotificationData=(id, notificationData)=>{
        let prvData = notificationData;
        prvData.forEach(item => {
            if (item.id === id) {
                item.flag = !item.flag
            }
        })
        this.setState({ notificationData: prvData }, this.props.handleOnCheckNotification(this.state.notificationData))
    }
    render() {
        const { notificationData, isloadingNotifiDataData } = this.state;

        return (
            <div className="notification-type-container">
                <div className="notification-main-title"><FormattedMessage id='db.es.selectNotificationTypes' /></div>
                {isloadingNotifiDataData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                <div className="notification">
                    {notificationData.length !== 0 ?
                        <React.Fragment>
                            <div className="notification-title"><FormattedMessage id='db.es.deviceNotifications' /></div>
                            <div>
                                {notificationData.filter(data => data.category === "device").map((item, key) => {
                                    return <div className="data-row" key={key}>
                                        {item.key === 'device_offline' || item.key === 'lat/lon_out_of_bounds' ? <span className="star float-left">*</span> : ""}{item.type}
                                        <span>
                                            <input
                                                type="checkbox"
                                                checked={item.flag}
                                                onClick={e => e.stopPropagation()}
                                                onChange={()=>this.handleOnCheckNotificationData(item.id, this.state.notificationData)}
                                                className="float-right"
                                            />
                                        </span>
                                    </div>
                                })}
                            </div>

                            <div className="notification-title"><FormattedMessage id='db.es.eventNotifications' /></div>
                            <div>
                                {notificationData.filter(data => data.category === "event").map((item, key) => {
                                    return <div className="data-row" key={key}>
                                        {item.type}
                                        <span className="float-right">
                                            <input
                                                type="checkbox"
                                                checked={item.flag}
                                                onClick={e => e.stopPropagation()}
                                                // onChange={() => this.context.handleOnCheckNotification(item.id)}
                                                onChange={() => this.handleOnCheckNotificationData(item.id, this.state.notificationData)}
                                            />
                                        </span>
                                    </div>
                                })}
                            </div>
                            <div className="notification-title"><FormattedMessage id='db.es.insights' /></div>
                            <div>
                                {notificationData.filter(data => data.category === "insights").map((item, key) => {
                                    return <div className="data-row" key={key}>
                                        {item.type}
                                        <span className="float-right">
                                            <input
                                                type="checkbox"
                                                checked={item.flag}
                                                onClick={e => e.stopPropagation()}
                                                onChange={() => this.handleOnCheckNotificationData(item.id, this.state.notificationData)}
                                            />
                                        </span>
                                    </div>
                                })}
                            </div>
                        </React.Fragment>
                        : !isloadingNotifiDataData && <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tree: state.treeviewData.tree,
    };
}

export default connect(mapStateToProps, {})(NotificationTypeContainer);
import React, { Component, Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../hoc/loader';
import ManageSchedulesTableView from '../components/manageSchedulesTableView';
import { ManageSchedulesContext } from '../provider/manageSchedulesProvider';
import ManageScheduleModal from '../components/manageScheduleModal';
import DropdownMenuCheckbox from '../../../hoc/dropdownMenuCheckbox';
import { mangeScheduleColumFilterData } from '../../../utility/manageSchedulesUtility';

class ManageSchedulesContainer extends Component {
    static contextType = ManageSchedulesContext;
    render() {
        const LoaderData = (this.context.state.loading) ? <div className="loader-wrap"><Loader elementClass="device-management-ajax-loader" /></div> : null
        return (
            <Fragment>
                {LoaderData}
                <Row className="p-0 m-0">
                    <FormattedMessage id={`db.common.setting`} />&nbsp;:&nbsp;<FormattedMessage id='customReports.manageSchedule.breadcrumb' />
                </Row>
                <Row className="btn-outline-secondary btn-group-icons btn-light grp-btn-last m-0 p-0 mb-3 float-right">
                    <DropdownMenuCheckbox
                        btnContent={<span className="add-btn column-filter"><FontAwesomeIcon icon={faTh} size="sm" /></span>}
                        bodyContent={mangeScheduleColumFilterData.columFilter}
                        selectedCulumns={this.context.state.displayColumns}
                        handleFilterChecked={this.context.setColumnStatus}
                        isBtnDisabled={this.context.state.reportData.length > 0 ? false : true}
                    />
                </Row>
                <Row className="content-right clr-fix pad-rgt-lef mt-3 m-0 p-0" xs={12} md={12}>
                    <ManageSchedulesTableView />
                </Row>
                <Col>
                    {this.context.state.isModalOpen ?
                        <ManageScheduleModal />
                        : ""}
                </Col>
            </Fragment>
        )
    }
}
export default ManageSchedulesContainer;
import React, { Component } from 'react';
import { ProfileContext } from '../provider/profilesProvider';
import VC101TabContent from './vc10TabContent';

class VC10ProfileConfig extends Component {
    static contextType = ProfileContext;

    filterControlObject = (controlObj) => { //filtering and showing moda data based on the "VAR Control Operating Mode" dropdown selection.
        const filtered = Object.keys(controlObj)
            .filter(key => {
                if (this.context.state.profile.selectedProfile.control.varControlEnable) {
                    switch((this.context.state.profile.selectedProfile.control.varControlOperatingMode).toString()) {
                        case '1':
                            return !['autoMode', 'powerFactorCorrectionMode', 'flickerMitigationMode'].includes(key)
                          break;
                          case '2':
                            return !['fixedSetPointMode', 'autoMode', 'flickerMitigationMode'].includes(key)
                          break;
                          case '3':
                            return !['fixedSetPointMode', 'powerFactorCorrectionMode', 'flickerMitigationMode'].includes(key)
                          break;
                          case '4':
                            return !['fixedSetPointMode', 'powerFactorCorrectionMode', 'autoMode'].includes(key)
                          break;
                        default:
                          break;
                      }

                } else return ['varControlEnable'].includes(key)
            })
            .reduce((obj, key) => {
                obj[key] = controlObj[key];
                return obj;
            }, {});
        return filtered
    }
    render() {
        return (
            <div>
                {
                    this.context.state.currentTab === "faultDetectionMCP" ?
                        <div>
                            <VC101TabContent
                                contentArrParent={this.context.state.profile.selectedProfile.faultDetection}
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.faultDetectionRules}
                                isBooleanValue={this.props.isBooleanValue}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='faultDetection'
                                disabled={false}
                                popOver={true}
                                formError={this.context.state.formError}
                                deviceType={this.props.deviceType}
                            />
                        </div> : null
                }

                {
                    this.context.state.currentTab === "visualIndicatorMCP" ?
                        <div>
                            <VC101TabContent
                                contentArrParent={this.context.state.profile.selectedProfile.visualIndicator}
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.visualIndicatorRules}
                                isBooleanValue={this.props.isBooleanValue}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='visualIndicator'
                                disabled={false}
                                popOver={true}
                                formError={this.context.state.formError}
                                deviceType={this.props.deviceType}
                            />
                        </div> : null
                }

                {
                    this.context.state.currentTab === "loggingMCP" ?
                        <div>
                            <VC101TabContent
                                contentArrParent={this.context.state.profile.selectedProfile.logging}
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.loggingRules}
                                isBooleanValue={this.props.isBooleanValue}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='logging'
                                disabled={false}
                                popOver={true}
                                formError={this.context.state.formError}
                                deviceType={this.props.deviceType}
                            />
                        </div> : null
                }
                {
                    this.context.state.currentTab === "advanceSettingsMCP" ?
                        <div>
                            <VC101TabContent
                                contentArrParent={this.context.state.profile.selectedProfile.advanceSettings}
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.advanceSettingsRules}
                                isBooleanValue={this.props.isBooleanValue}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='advanceSettings'
                                disabled={false}
                                popOver={true}
                                formError={this.context.state.formError}
                                deviceType={this.props.deviceType}
                            />
                        </div> : null
                }

                {
                    this.context.state.currentTab === "controlMCP" ?
                        <div>
                            <VC101TabContent
                                contentArrParent={this.filterControlObject(this.context.state.profile.selectedProfile.control || {})} //passing all the filterd data based on "VAR Control Operating Mode" dropdown selection.
                                updateFormattedMessage={this.props.updateFormattedMessage}
                                rules={this.context.state.profile.selectedProfile.controlRules}
                                isBooleanValue={this.props.isBooleanValue}
                                handleOnchangeForms={this.props.handleOnchangeForms}
                                parentObj='control'
                                disabled={false}
                                popOver={true}
                                formError={this.context.state.formError}
                                deviceType={this.props.deviceType}
                            />
                        </div> : null
                }
            </div>
        )
    }
}
export default VC10ProfileConfig;
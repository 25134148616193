import React from 'react';
import EProfileContainer from './containers/eProfileContainer';
import { Row, Col, Container } from 'react-bootstrap';
import './index.css';
import EProfileProvider from './provider/eProfileProvider';

const EquipmentProfile = () => {
    return (
        <div className='e-profile'>
            <EProfileProvider>
                <div><EProfileContainer /></div>
            </EProfileProvider>

        </div>
    );
}

export default EquipmentProfile;
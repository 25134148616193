import React, { Component } from 'react';
import TextField from '../../../../hoc/form/textField';
import ToggleSwitch from '../../../../hoc/toggleSwitch';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../../../hoc/tooltip';
import PopOverMessage from '../../../../hoc/popOver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ProfileDataOrder, getSortedList } from '../../../../utility/manageProfileUtility';
import Range from '../../../../hoc/range';


class CommonTabContent extends Component {
    render(){
        return(
            <div>
            { this.props.headerContent ? 
            this.props.contentArrParent !== undefined && Object.entries(getSortedList(this.props.contentArrParent, ProfileDataOrder[this.props.deviceType][this.props.parentObj]["headers"])).map(([label, value], key) => {
                return (
                    <div key={key}>
                        <div className="form-section">
                            <span className="form-section-content-l form-header">{<FormattedMessage id={`manage.profiles.${label}`} />}</span>
                        </div>
                        {
                            Object.entries(getSortedList(this.props.contentArrParent[label],ProfileDataOrder[this.props.deviceType][this.props.parentObj][label], this.props.deviceType)).map(([label1, value1], key1) => {
                                return (
                                    <div className="form-section d-flex" key={key1}>
                                        <span className={this.props.deviceType === "ZM1" ? "form-section-content-l-50":"form-section-content-l-70"}>
                                        <Tooltip placement="top" message={label1} baseContent={this.props.updateFormattedMessage(label1, this.props.rules[label])}/>
                                            </span>
                                        <span className={this.props.deviceType === "ZM1" ? "form-section-content-r-50":"form-section-content-r-30"}>{
                                            this.props.isBooleanValue(value1) ?
                                                <ToggleSwitch
                                                    value={value1 === true ? true : false}
                                                    toggleSwitchValue={(e)=>this.props.handleOnchangeForms(this.props.parentObj,label1,value1 === true ? false : true,label)}
                                                    leftValue="On"
                                                    rightValue="Off"
                                                />
                                                : <TextField
                                                    type="text"
                                                    isDisabled={false}
                                                    value={value1 ? value1 : ""}
                                                    field={value1}
                                                    handleOnchange={(e)=>this.props.handleOnchangeForms(this.props.parentObj,label1,e,label)}
                                                    className={this.props.formError[label1] !== undefined && this.props.formError[label1] === true ? "form-err-border-red" : ""}
                                                />
                                        }</span>
                                    </div>
                                )
                            })
                        }
                    </div>

                )
            })
            : 
            <div>
                {this.props.contentArrParent !== undefined && Object.keys(this.props.contentArrParent).length > 0 && this.props.parentObj === 'logi' && 
                <div className="form-section">
                <div className="form-section-content-l form-header LogIThreshold">{<FormattedMessage id={`manage.profiles.LogIThresholdConfiguration`} />}</div>
                </div>}
                {this.props.contentArrParent !== undefined && Object.keys(this.props.contentArrParent).length > 0 ?  Object.entries(getSortedList(this.props.contentArrParent,ProfileDataOrder[this.props.deviceType][this.props.parentObj], this.props.deviceType)).map(([label, value], key) => {
                    if(!Object.keys(this.props.rules).includes(label)){
                        return null
                    }
                    else{
                    return(
                      <div className="form-section d-flex" key={key}>
                      <span className={this.props.deviceType === "ZM1" ? "form-section-content-l-50":"form-section-content-l-70"}> 
                        {(label!=="Track-Restoration") ?
                        <span>
                            <Tooltip placement="top" message={label} baseContent={this.props.updateFormattedMessage(label, this.props.rules)}/>
                                {this.props.popOver ? <PopOverMessage trigger="focus" keyVal={`${key}-${label}`} placement="right" popoverBody={
                                    <div className="m-2"><FormattedMessage id={`manage.profiles.${label}-popOverContent`} /></div>
                                } displayPopOver={
                                    <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
                                } /> : null}
                                </span>
                                :null
                            }

                      </span>
                         
                      <span className={this.props.deviceType === "ZM1" ? "form-section-content-r-50":"form-section-content-r-30"}>{
                                <span>
                                    {
                                        this.props.isBooleanValue(value) ? <ToggleSwitch
                                            value={value === true ? true : false}
                                            toggleSwitchValue={(e) => this.props.handleOnchangeForms(this.props.parentObj, label, value === true ? false : true)}
                                            leftValue="On"
                                            rightValue="Off"
                                        />
                                            : null
                                    }
                                    {
                                        this.props.isTimeoffset(label) ?
                                            <span className="dispInline">
                                                <TextField
                                                    type="text"
                                                    isDisabled={this.props.disabled}
                                                    value={this.props.tempDnptimeObj[label][0]}
                                                    field={value}
                                                    handleOnchange={(e) => this.props.handleOnchangeForms(this.props.parentObj, label, e,null,"HH")}
                                                    className={this.props.formError[label] !== undefined && this.props.formError[label] === true ? "form-err-border-red sz-49" : "sz-49"}
                                                />
                                                <TextField
                                                    type="text"
                                                    isDisabled={this.props.disabled}
                                                    value={this.props.tempDnptimeObj[label][1]}
                                                    field={value}
                                                    handleOnchange={(e) => this.props.handleOnchangeForms(this.props.parentObj, label, e,null,"MM")}
                                                    className={this.props.formError[label] !== undefined && this.props.formError[label] === true ? "form-err-border-red sz-49 ml-2p" : "sz-49 ml-2p"}
                                                />
                                            </span>
                                            : null
                                    }
                                    {
                                        !this.props.isBooleanValue(value) && !this.props.isTimeoffset(label)&&!this.props.showZM1CfciFields(label) && label!=="Track-Restoration" ? <TextField
                                            type="text"
                                            isDisabled={this.props.disabled}
                                            value={value ? value : ""}
                                            field={value}
                                            handleOnchange={(e) => this.props.handleOnchangeForms(this.props.parentObj, label, e)}
                                            className={this.props.formError[label] !== undefined && this.props.formError[label] === true ? "form-err-border-red" : ""}
                                        /> : null
                                    }
                                    {
                                        this.props.showZM1CfciFields(label) ?
                                            <span className="rangeCls">
                                                <span className="sz-49 float-left"> <Range keyVal = {`${label}-${key}`} min={this.props.rules[label].min} max={this.props.rules[label].max} value={value ? value : "0" } handleOnchange={(e) => this.props.handleOnchangeForms(this.props.parentObj, label, e)}/></span>
                                                <span className="sz-49 float-right mb-4">
                                                    <TextField
                                                        type="text"
                                                        isDisabled={this.props.disabled}
                                                        value={value}
                                                        field={value}
                                                        handleOnchange={(e) => this.props.handleOnchangeForms(this.props.parentObj, label, e)}
                                                        className={this.props.formError[label] !== undefined && this.props.formError[label] === true ? "form-err-border-red ml-2p" : ""}
                                                    />
                                                </span>
                                            </span>
                                            : null
                                    }
                                </span>
                      }
                      </span>
                  </div>

                    )
                    }
                }) : ""            
                }
            </div>
    }
            </div>
        )
    }
}

export default CommonTabContent;
import { FormattedMessage } from 'react-intl';
import React, { Fragment } from 'react';

function intlFormater(column) {
    return (
        <Fragment>
            {
                column.dataField ? <FormattedMessage id={`dm.column.${column.dataField}`} /> :
                    <Fragment>
                        <FormattedMessage id={`dm.column.${column.column.id}`} /> <span className={column.column.sortedOrder > 0 ? "disp-sortedOrder" : ""}>{column.column.sortedOrder > 0 ? `${column.column.sortedOrder}` : ""}</span>
                    </Fragment>
            }
        </Fragment>
    );
}

const accessorValues = ['region','substation','feeder','lateral','site','siteDeviceType','groundFaultThreshold']

const  tableColumnsArr =   accessorValues.map(e => {
    return({
        Header: intlFormater, 
        accessor: e,
        onFilterClicked: false,
        data: [],
        selectedItems: [], //Saved Filters
        items: [], //Persistance Filter Items
        sortedOrder: 0, //For displaying Sorted Order
    })
});

export const tableOptions = {
    pageNo: 1,
    pageSize: 100,
    pageSizeOptions: [100, 200, 500, 1000],
    paginationSize: 4,
    firstPageText: 'First',
    prePageText: 'Prev',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Prev page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    disableFilter: false, // This flag is used to enable/disable the column filter
    filterSelectionCount: 0,
    summaryFilterSelectionCount: 0,
    selectedRows: [],
    tableColumns:tableColumnsArr,
    customFilterColumns: [
        {
            dataField: 'deviceType',
            hidden: false,
            headerFormatter: intlFormater,
            type: 'dropdown',
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            length: 0
        },
        {
            dataField: 'groundfaultThreshold',
            hidden: false,
            headerFormatter: intlFormater,
            type: 'dropdown',
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            length: 0
        }
    ],
    customFilterButtons: [
        {
            dataField: 'apply',
            hidden: false,
            headerFormatter: intlFormater,
            action: 'apply'
        },
        {
            dataField: 'resetAll',
            hidden: false,
            headerFormatter: intlFormater,
            action: 'reset'
        }
    ]
}

    
import { requestGET, requestPOST } from '../../../services/extendedService';
import { getURL } from '../../../providers/configProvider';

export const getManageSchedulesReportData = () => {
    const url = getURL('reportschedulesmanagement', 'list', { 'apiType': 'data' });
    return requestGET(url);
}

export const deleteSchedule = (scheduleId) => {
    const url = getURL('reportschedulesmanagement', 'delete', { 'REPORTID': encodeURIComponent(scheduleId), 'apiType': 'data' });
    return requestPOST(url, {});
}
import React, { Component } from 'react';
import DetailsTable from '../components/detailsTable';
import MapAndTable from '../components/mapAndTable';
import DetailsAction from '../components/detailsAction';
import { Row, Col } from 'react-bootstrap';
import Loader from '../../../../hoc/loader';
import { DetailsContext } from '../provider/detailsProvider';
import DetailsBreadCrumb from '../components/detailsBreadCrumb';

class DetailsContainer extends Component {
    static contextType = DetailsContext;
    render() {
        return (
            <React.Fragment>
                {this.context.state.isLoadingData ? <div className="loader-wrap"><Loader elementClass="tree-ajax-loader center-element" /></div> : ""}
                <Row className='m-0 mb-2'>
                    <Col className='p-0'>
                        <DetailsBreadCrumb/>
                    </Col>
                </Row>
                <Row className='m-0 mb-2 border-1px'>
                    <Col>
                        <DetailsAction/>
                    </Col>
                </Row>
                <Row className='m-0'>
                    <Col className='mr-1'> <DetailsTable /></Col>
                    <Col className='ml-1'> <MapAndTable /></Col>
                </Row>
            </React.Fragment>

        )
    }
}

export default DetailsContainer;
import React, { Component, Fragment } from 'react';
import { Col } from 'react-bootstrap';
import Loader from '../../../../hoc/loader';
import SummaryFilter from '../components/summaryFilter';
import SummaryTableView from '../components/summaryTableView';
import { SummaryContext } from '../provider/summaryProvider';

class SummaryContainer extends Component {
    static contextType = SummaryContext;
    render() {
        const LoaderData = (this.context.state.loading) ? <div className="loader-wraps"><Loader elementClass="device-management-ajax-loader" /></div> : null
        return (
            <Fragment>
                <Col className="content-right pad-rgt-lef m-0 p-0" xs={12} md={12}>
                    <SummaryFilter />
                    <SummaryTableView />
                    {LoaderData}
                </Col>
            </Fragment>
        )
    }
}
export default SummaryContainer;
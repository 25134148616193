import React, { useContext } from 'react';
import TextField from '../../../../../hoc/form/textField';
import {VirtualDeviceContext} from '../../provider/virtualDeviceProvider';
import { FormattedMessage } from 'react-intl';

const VDProfileTemplateClass = (props) => {
    const contextData = useContext(VirtualDeviceContext);
    const {isClickedContinueBtn, isErrorOnValidation, clickedType, mainFormData, mainFormDataErr, viewOnlyProfilePoints} = contextData.state;

   const updateRowData=(rowId)=>{
        let val = ''
        viewOnlyProfilePoints.length &&
            viewOnlyProfilePoints.map((item)=> item.id === rowId ? val = item.classValue : '' )
            return val
    }

    return (
        <>
            {(isErrorOnValidation || !isClickedContinueBtn) && clickedType !== 'View' ? 
                props.rowData.original.profileName !== 'Control' &&
                    <TextField
                        type="number"
                        isDisabled={false}
                        value={mainFormData.vdProfileSettings[props.rowData.row._index].classValue.toString()}
                        field={'vdProfileSettings'}
                        size={'sm'}
                        handleOnchange={(val) => contextData.handleMainFormData(val, props.rowData, 'vdProfileSettings', 'classValue')}
                        className={mainFormDataErr[props.rowData.original.id]?.isErr !== undefined && mainFormDataErr[props.rowData.original.id]?.isErr === true ? "form-err-border-red" : ""}
                        handleOnKeyDown={(value, field, evt)=>((evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '+' ) && evt.preventDefault())}
                    />
            : props.rowData.original.profileName !== 'Control' && updateRowData(props.rowData.row._original.id) }
            {mainFormDataErr[props.rowData.original.id]?.isErr !== undefined && mainFormDataErr[props.rowData.original.id]?.isErr === true && props.rowData.original.classValue !== -1 && <div style={{color:'#a94442'}}> <FormattedMessage id={"vdm.validation.classLength"} /> </div> }
        </>
    );
};

export default VDProfileTemplateClass;
import React, { Component } from 'react';
import Tooltip from '../../../../hoc/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { SiteContext } from '../provider/siteProvider';
import CustomFilter from '../components/customFilter';

class SiteActionContainer extends Component {
    static contextType = SiteContext;
    render() {
        return (
            <div className="float-right d-flex actionDiv">
                <CustomFilter/>
                <Tooltip baseContent={
                    <span onClick={(e) =>  this.context.editSite()}  className={ this.context.state.selectedSiteIds.length > 0 ? "btn btn-outline-secondary grp-btn-first" : "btn btn-outline-secondary grp-btn-first disabled"}>
                        <FontAwesomeIcon icon={faPencilAlt} size="sm" />
                    </span>} message={"Edit"} placement={'top'} />
            </div>
        );
    }
}

export default SiteActionContainer;
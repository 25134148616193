import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ConfigureProperties from '../../mainContent/configureProperties/index.js';
import {configPropertiesData} from '../../utility/configurePropertiesUtility'

class OpenConfigPropModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false
        }
    }

    handleOnCLick = () => {
        configPropertiesData.isOpenModal = true;
        this.setState({ isOpenModal: true })
    }

    render() {
        return (
            <React.Fragment>
                <div onClick={this.handleOnCLick} className="noNavigationItemLink">
                    <FormattedMessage id={`nav.main.configureproperties`} />
                </div>
                <div>
                    {configPropertiesData.isOpenModal ? <ConfigureProperties /> : ""}
                </div>
            </React.Fragment>
        );
    }
}

export default OpenConfigPropModal;
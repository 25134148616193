import { changeRoute, samlLogout, samlFailed } from './headerActionDispatch';

export const onRouteChange = (route, isTreeDisable) => {
    return dispatch => {
        dispatch(changeRoute(route, isTreeDisable));
    };
};

export const onSamlLogout = (isSamlLogout) => {
    return dispatch => {
        dispatch(samlLogout(isSamlLogout));
    };
};

export const onSamlFailed = (isSamlFailed) => {
    return dispatch => {
        dispatch(samlFailed(isSamlFailed));
    };
};
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const notificationTemplatesData = {
    isOpenModal: false,
    formFields: {
        topForm: [
            { label: <FormattedMessage id={'settings.notificationTemplates.text.notifyCategory'}/>, value: 'category', type: 'singleSelect' },
            { label: <FormattedMessage id={'settings.notificationTemplates.text.notifyTemplate'}/>, value: 'notificationTemplate', type: 'singleSelect' }
        ],
        // actionBtn: ['Save', 'Save As', 'Delete', Create ],
        btmForm :[
            {label: <FormattedMessage id={'settings.notificationTemplates.text.name'}/>, value:'name', type:'text', isMandatory : true},
            {label: <FormattedMessage id={'settings.notificationTemplates.text.subject'}/>, value:'subject', type:'text', isMandatory : true},
            {label: <FormattedMessage id={'settings.notificationTemplates.text.body'}/>, value:'emailBody', type:'textarea', isMandatory : true},
            {label: <FormattedMessage id={'settings.notificationTemplates.text.keywords'}/>, value:'placeHolders', type:'normal'},
            {label: <FormattedMessage id={'settings.notificationTemplates.text.isActive'}/>, value:'active', type:'switch'},
        ]
    }
}
import React, { Component, Fragment } from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getDateConvertedString } from '../../../../services/utilityService';
import { initiatePing } from '../services/deviceService';
import ModalWindow from '../../../../hoc/modelWindow';
import Loader from '../../../../hoc/loader';
import ampleStore from '../../../../store';
import { showAlertToaster } from '../../../../login/actions/loginActionDispatch';
import { DeviceContext } from '../provider/deviceProvider';

class NetworkTestComponent extends Component {

    static contextType= DeviceContext;
    constructor(props) {
        super(props);
        this.state = {
            networkTestClickEnabled: true,
        }
    }

    onNetworkClickHandler = async () => {
        this.setState({networkTestClickEnabled: false});
        await initiatePing(this.props.rowData.id).then(res=>{
                if (res) {
                    this.props.onCloseModal()
                    this.setState({networkTestClickEnabled: true});
                    window.scrollTo(0,0);
                    res.status !== "FAIL" ? ampleStore.dispatch(showAlertToaster(<div><strong>{this.props.rowData.serialNumber}</strong>: {res}</div>, 'success')) : ampleStore.dispatch(showAlertToaster(<div><strong>{this.props.rowData.serialNumber}</strong>: {res.message}</div>, 'danger'))
                }
                this.context.getDeviceList(this.context.state.treeNode);
            }
        );   
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
        return btnName === "networkTest" ? this.onNetworkClickHandler() :
            btnName === "close" || btnName === "cancel" ? this.props.onCloseModal() : null;
    }

    render() {
        let modalTitle, modalBody, modalFooter;
            modalTitle = <div className="popup-title"><FormattedMessage id={`pingDevice.popup.title`} />:<span className="text-bolder"> {`${this.props.serialNumber}`}</span> </div>
            modalBody = <Fragment>
                {this.props.loader&&<Loader elementClass="tree-ajax-loader center-element" />}
                <div className="col-12 device-block">
                    <Row className="dark-gray-row p-1 font-weight-bold"><FormattedMessage id={`pingDevice.popup.device.title`} /></Row>
                    <Row className="gray-row p-1 font-weight-bold">
                        <div className="col-3 offset-2"><FormattedMessage id={`pingDevice.popup.device.status.title`} /></div>
                        <div className="col-3"><FormattedMessage id={`pingDevice.popup.device.state.title`} /></div>
                        <div className="col-4"><FormattedMessage id={`pingDevice.popup.device.lastCommunicationTime.title`} /></div>
                    </Row>
                    <Row className="p-1">
                        <div className="col-3 offset-2">{this.props.rowData.status}</div>
                        <div className="col-3">{this.props.rowData.deviceState}</div>
                        <div className="col-4">{getDateConvertedString(this.props.rowData.lastSuccess)}</div>
                    </Row>
                </div>
                {this.props.pingStatus.hostNameLookUp ?
                    <div className="col-12 hostnameLookup-block mt-3">
                        <Row className="dark-gray-row p-1 font-weight-bold"><FormattedMessage id={`pingDevice.popup.hostnameLookUp.title`} /></Row>
                        <Row className="gray-row p-1 font-weight-bold">
                            <div className="col-3 offset-2"> <FormattedMessage id={`pingDevice.popup.hostnameLookup.hostname.title`} /> </div>
                            <div className="col-3"><FormattedMessage id={`pingDevice.popup.hostnameLookup.status.title`} /></div>
                            <div className="col-4"><FormattedMessage id={`pingDevice.popup.hostnameLookup.value.title`} /></div>
                        </Row>
                        <Row className="p-1">
                            <div className="col-3 offset-2">{this.props.pingStatus.hostNameLookUp.hostName}</div>
                            <div className="col-3">{this.props.pingStatus.hostNameLookUp.status}</div>
                            <div className="col-4">{this.props.pingStatus.hostNameLookUp.ipAddress}</div>
                        </Row>
                    </div> : ""}
                <div className="col-12 packets-block mt-3">
                    <Row className="dark-gray-row p-1 font-weight-bold"><FormattedMessage id={`pingDevice.popup.packets.title`} /> </Row>
                    <Row className="gray-row p-1 font-weight-bold">
                        <div className="col-3 offset-2"><FormattedMessage id={`pingDevice.popup.packets.sent.title`} /></div>
                        <div className="col-3"><FormattedMessage id={`pingDevice.popup.packets.received.title`} /></div>
                        <div className="col-4"><FormattedMessage id={`pingDevice.popup.packets.lost.title`} /></div>
                    </Row>
                    {/* <Row className="p-1">      AS-2977 : # FM [Ample] MM3 Network Test
                        <div className="col-2 font-weight-bold"><FormattedMessage id={`pingDevice.popup.icmp.title`} /></div>
                        <div className="col-3">{this.props.pingStatus.icmp && this.props.pingStatus.icmp.packets ? this.props.pingStatus.icmp.packets.sent : ""}</div>
                        <div className="col-3">{this.props.pingStatus.icmp && this.props.pingStatus.icmp.packets ? this.props.pingStatus.icmp.packets.received : ""}</div>
                        <div className="col-4">{this.props.pingStatus.icmp && this.props.pingStatus.icmp.packets ? this.props.pingStatus.icmp.packets.lost ? this.props.pingStatus.icmp.packets.lost : 0 : ""}</div>
                    </Row> */}
                    <Row className="p-1 gray-row">
                        <div className="col-2 font-weight-bold"><FormattedMessage id={`pingDevice.popup.dnp3.title`} /></div>
                        <div className="col-3">{this.props.pingStatus.ping && this.props.pingStatus.ping.length > 0 ? this.props.pingStatus.ping[0].sent : ""}</div>
                        <div className="col-3">{this.props.pingStatus.ping && this.props.pingStatus.ping.length > 0 ? this.props.pingStatus.ping[0].received : ""}</div>
                        <div className="col-4">{this.props.pingStatus.ping && this.props.pingStatus.ping.length > 0 ? this.props.pingStatus.ping[0].lost ? this.props.pingStatus.ping[0].lost : 0 : ""}</div>
                    </Row>
                </div>
                {/* <div className="col-12 rtt-block mt-3">     AS-2977 : # FM [Ample] MM3 Network Test
                    <Row className="dark-gray-row p-1 font-weight-bold"><FormattedMessage id={`pingDevice.popup.rtt.title`} /></Row>
                    <Row className="gray-row p-1 font-weight-bold">
                        <div className="col-3 offset-2"><FormattedMessage id={`pingDevice.popup.rtt.min.title`} /></div>
                        <div className="col-3"><FormattedMessage id={`pingDevice.popup.rtt.max.title`} /></div>
                        <div className="col-4"><FormattedMessage id={`pingDevice.popup.rtt.avg.title`} /></div>
                    </Row>
                    <Row className="p-1">
                        <div className="col-2 font-weight-bold"><FormattedMessage id={`pingDevice.popup.icmp.title`} /></div>
                        <div className="col-3">{this.props.pingStatus.icmp && this.props.pingStatus.icmp.roundTrip ? this.props.pingStatus.icmp.roundTrip.min ? this.props.pingStatus.icmp.roundTrip.min + "ms" : "" : ""}</div>
                        <div className="col-3">{this.props.pingStatus.icmp && this.props.pingStatus.icmp.roundTrip ? this.props.pingStatus.icmp.roundTrip.max ? this.props.pingStatus.icmp.roundTrip.max + "ms" : "" : ""}</div>
                        <div className="col-4">{this.props.pingStatus.icmp && this.props.pingStatus.icmp.roundTrip ? this.props.pingStatus.icmp.roundTrip.average ? this.props.pingStatus.icmp.roundTrip.average + "ms" : "" : ""}</div>
                    </Row>
                    <Row className="p-1 gray-row">
                        <div className="col-2 font-weight-bold"><FormattedMessage id={`pingDevice.popup.dnp3.title`} /></div>
                        <div className="col-3">-</div>
                        <div className="col-3">-</div>
                        <div className="col-4">{this.props.pingStatus.ping && this.props.pingStatus.ping.length > 0 ? this.props.pingStatus.ping[0].average + "ms" : ""}</div>
                    </Row>
                </div> */}
            </Fragment>
            modalFooter = [{ className: "modalSaveBtn", name: <FormattedMessage id={`pingDevice.popup.button.networkTest`} />, value: "networkTest", disabled: this.props.pingStatus.status === "PENDING" || !this.state.networkTestClickEnabled? true : false }, { className: "modalCancelBtn", name: <FormattedMessage id={`deleteDevice.popup.button.cancel`} />, value: "cancel" }];
        return (
            <Fragment>
                {this.props.isModalOpen ?                
                    <ModalWindow
                        show={this.props.isModalOpen}
                        onHide={() => this.props.onCloseModal()}
                        size={"lg"}
                        dialogClassName='pingModal'
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        )
    }
}

export default NetworkTestComponent;
import React from 'react';
import { getIcon } from '../../utility/treeViewUtility';

const search = (props) => (
  <div className={"dropdown " + (props.showDropdown ? "show" : "")} onBlur={props.onBlur}>
    <input onChange={(evt) => props.inputChangedHandler(evt)} className="col-xs-12 col-12 node-search form-control" type="text" value={props.searchedString} placeholder="Search" />
    {
      props.showDropdown ?
        <ul className={"dropdown-menu search-dropdown " + (props.showDropdown ? "d-block" : "") }>
          {
            props.searchResults.length ? props.searchResults.map((x, key) => {
              return <li key={key} name={x.name}>
                {
                  <div className="ddl-items" tabIndex="-1" onMouseDown ={() => props.selectSearchedNode(x)}>
                    <span>{x.type !== "SITE" && x.type !== 'LATERAL' && x.type !== 'LATERAL_SITE' ? getIcon({"node": {"type": x.type}}) : ""}</span>
                    <span className="margin-l-5">
                      {x.type === "SITE" || x.type === 'LATERAL' ? (x.feederName + " : " + x.name) :
                        x.type === "LATERAL_SITE" ? (x.lateralName + " : " + x.name) :
                          x.type !== "SITE" && x.type !== 'LATERAL' && x.type !== 'LATERAL_SITE' ? x.name : ""}
                    </span>
                  </div>
                }
              </li>
            }) : <li className="text-center">No Data Available</li>
          }
        </ul> : ""
    }

    {/*   <Form inline>
       <FormGroup controlId="formInlineName">
          <Button disabled={!props.searchFoundCount} onClick={props.selectPrevMatch}>&lt;</Button>
            <Button disabled={!props.searchFoundCount} onClick={props.selectNextMatch}>&gt;</Button>
            <span>
                &nbsp;
              {props.searchFoundCount > 0 ? props.searchFocusIndex + 1 : 0}
                &nbsp;/&nbsp;
              {props.searchFoundCount || 0}
            </span> 
     </FormGroup>
    </Form> */}
  </div>
)

export default search;
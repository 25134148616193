import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { OtapStatusContext } from '../provider/otapStatusProvider';
import { getDateConvertedString, fetchBrowserTimeZone } from '../../../../services/utilityService';
import * as currentJobUtility from '../../../../utility/currentJobUtility';

class JobStatus extends Component {
    static contextType = OtapStatusContext;

    render() {
        return (
            <Fragment >
                <div className="title"><FormattedMessage id='currentjobs.upgrade.title' /></div>
                <div className="title-header">
                    <div className="job-name"><FormattedMessage id='currentjobs.job.name' /><span>&nbsp;{this.context.state.selectedJobName}</span></div>
                    <div className="job-legends">
                        {currentJobUtility.jobStatus.statusList.filter((fItem)=> fItem.label.props.id.indexOf("TRANSFER_COMPLETED") === -1).map((item, i) => {  //for OTAP status page "TRANSFER_COMPLETED" status no need to display, only required for "Configutaion status" Page. "currentJobUtility.jobStatus.statusList" is common code for both page, so filtering "TRANSFER_COMPLETED" here.
                            return <div className="list" key={i}>
                                {i === 0 ? <b>{item.label}</b> : <span><span className={"device-status " + item.class}></span>{item.label}</span>}
                            </div>
                        })}
                    </div>
                </div>

                {/* Job Status Details */}
                <Row className="job-status">
                    {currentJobUtility.jobStatus.jobStatusDetails.map((item, i) => {
                        return <Col xs={2} md={2} key={i} className="status-tems pr-0">
                            <label>{item.label} {item.value === 'createdOn' && <span>{fetchBrowserTimeZone()} :</span>}</label>
                            {(item.value === 'inProgress' || item.value === 'success' || item.value === 'failure') ?
                                <span className={item.value}>&nbsp;{this.context.state.jobStatus.statusDetails && this.context.state.jobStatus.statusDetails[item.value]}</span> :
                                item.value === 'createdOn' ? <span>&nbsp;{this.context.state.jobStatus[item.value] && getDateConvertedString(this.context.state.jobStatus[item.value])}</span> :
                                    <span className='word-break-all'>&nbsp;{this.context.state.jobStatus[item.value]}</span>}
                        </Col>
                    })}
                </Row>
            </Fragment>
        )
    }
}

export default JobStatus;
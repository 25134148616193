import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { DGWContext } from '../provider/dgwProvider';
import Tabs from '../../../hoc/tabs';
import {DGWData} from '../../../utility/dgwUtility'
import Loader from '../../../hoc/loader';
import BlankPage from '../../../hoc/blankPage';
import Tooltip from '../../../hoc/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Alert from '../../../hoc/alert'
import Table from '../../../hoc/table';
import DgwUploadCertificateBtn from '../components/DgwUploadCertificateBtn'

class DgwUploadCertificateModal extends Component {
    static contextType = DGWContext;
    
    constructor(props) {
        super(props);
        this.state = {
            ampCertClmData: DGWData.ampleCertTable,
            certRvcClmData: DGWData.certRevocationListTable,
        }
    }

    render() {
        const { isloadingCertModalData, currentTab, alertMessage, uploadCertTableData, OSName } = this.context.state
        return (
            <Row className="sg-upload-cert-modal">
                <Col>
                    <div className="action-button">
                        <DgwUploadCertificateBtn/>
                        <span onClick={this.context.closeDisplayCertificateModal}><Tooltip placement='top' message={<FormattedMessage id='db.common.close' />} baseContent={<span className='modal-close cert-close-icon'> <FontAwesomeIcon icon={faTimes} size="sm" /> </span>} /></span>
                    </div>
                    <Tabs
                        currentTab={currentTab}
                        handleTabSelect={this.context.handleTabSelect}
                        allTabs={DGWData.allTabsList}
                        tabBody={
                            <React.Fragment>
                                 {alertMessage.msg !== undefined && alertMessage.msg !== "" ? <Alert type={alertMessage.type} message={alertMessage.msg} setVisible={this.context.handleRemoveAlertMsg} /> : ""}
                                 {isloadingCertModalData ? <Loader elementClass="tree-ajax-loader center-element" /> : uploadCertTableData && uploadCertTableData.length !== 0 ?
                                    <div className={ OSName === 'MacOS' || uploadCertTableData.length <=5 ? "certificate-table-full" : "certificate-table"}>
                                        <Table 
                                             tableColumn={currentTab === 'Ample Certificate' ? this.state.ampCertClmData : this.state.certRvcClmData}
                                             tableRowData={uploadCertTableData}
                                             showPagination={false}
                                            //  multiSort={false}
                                             pageSize={uploadCertTableData.length}
                                        />
                                    </div> :
                                    <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
                            </React.Fragment>
                        }
                    />
                </Col>
            </Row>
        );
    }
}

export default DgwUploadCertificateModal;
import React, { Component, Fragment } from 'react';
import { Col } from 'react-bootstrap';
import NoDataAvailableComponent from '../../../hoc/noDataAvailableComponent';
import { ManageSchedulesContext } from '../provider/manageSchedulesProvider';
import Table from '../../../hoc/table';
import ReactTablePropType from '../../../hoc/reactTablePropType';
import { tableOptions } from '../../../utility/manageSchedulesUtility';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../hoc/tooltip';

class ManageSchedulesTableView extends Component {
    static contextType = ManageSchedulesContext;
    state = {
        tableColumns: [
            ...tableOptions.tableColumns,
            {
                accessor: 'action',
                Header: <FormattedMessage id='dm.column.actions' />,
                sortable: false,
                Cell: ({ original }) => {
                    return <span className="curptr">
                        <Tooltip baseContent={<FontAwesomeIcon onClick={(e) => this.context.handleDeleteIconClick(original)} icon={faTrash} size="sm" />} message={<FormattedMessage id='groupmanagement.context.delete' />} placement={'top'} />
                    </span>;
                }
            }
        ]
    }
    render() {
        let content = null;
        if (this.context.state.reportData.length > 0) {
            content =
                <Col xs={12} md={12} className={"clr-fix pad-rgt-lef m-0 p-0 siteList " + this.props.className}>
                    <ReactTablePropType
                        reactTableContainer={
                            <Table
                                tableColumn={this.state.tableColumns.filter(item => this.context.state.displayColumns.includes(item.accessor))}
                                tableRowData={this.context.state.reportData}
                            />
                        } />
                </Col>
        }
        else {
            content = !this.context.state.loading ? <NoDataAvailableComponent /> : null
        }

        return (
            <Fragment>
                {content}
            </Fragment>
        )
    }
}

export default ManageSchedulesTableView;
import React from 'react';
import { Form } from 'react-bootstrap';

class SearchWithSelectField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listdata: props.optionData,
            listdataCopy: props.optionData,
            defaultValue: props.defaultValue,
            isDisplayList: false,
            onchangeValue: ""
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.closeListDataOnOustsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.closeListDataOnOustsideClick, false);
    }

    onClickofTextField = () => {
        // if (!this.state.isDisplayList) {
        //     document.addEventListener("click", this.closeListDataOnOustsideClick, false);
        // } else {
        //     document.removeEventListener("click", this.closeListDataOnOustsideClick, false);
        // }
        this.setState({ isDisplayList: true, isSelected: true })
    }

    closeListDataOnOustsideClick = e => {
        if (this.node !== null && this.node !== undefined && !this.node.contains(e.target)) {
            this.setState({ isDisplayList: false, isSelected: false, onchangeValue: "", listdata: this.state.listdataCopy })
        }
    }

    onSelectListItem = (e) => {
        this.setState({ isDisplayList: false, defaultValue: e.target.value, isSelected: false, onchangeValue: "" }, () => {
            this.props.handleOnchange(this.state.defaultValue, this.props.field)
        })
    }

    onPressEnterKey = (e) => {
        if (e.key === 'Enter') {
            this.setState({ isDisplayList: false, defaultValue: e.target.value, isSelected: false, onchangeValue: "" }, () => {
                this.props.handleOnchange(this.state.defaultValue, this.props.field)
            })
        }
    }

    onKeyPressed = (e) => {
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            const active = document.activeElement;
            active.nextSibling.focus(); //add focus on list item
            // this.nameInput.focus();
        }
    }

    handleOnchangeItem = (e) => {
        let prvData = this.state.listdataCopy;
        let filterData = [];
        if (e.target.value !== "") filterData = prvData.filter(item => item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
        else filterData = prvData;
        this.setState({ onchangeValue: e.target.value, listdata: filterData })
    }

    render() {
        const { isDisplayList, defaultValue, onchangeValue, isSelected } = this.state
        return (
            <div ref={node => this.node = node} className="search-and-select">
                {/* {isDisplayList !== true ?
                    <span className="dropdown-icon" onClick={this.onClickofTextField}> <FontAwesomeIcon icon={faAngleDown} size="sm" /> </span>
                    : ""} */}
                <span className= {isDisplayList && this.state.listdata.length !== 0 ? '' : 'dropdown-icon' }>
                    <Form.Control
                        type='text'
                        onFocus={this.onClickofTextField}
                        onKeyDown={this.onKeyPressed}
                        size={'sm'}
                        onChange={this.handleOnchangeItem}
                        value={isSelected === true ? onchangeValue : defaultValue}
                        style={{ cursor: "pointer" }}
                    // ref={(input) => { this.nameInput = input; }} 
                    />
                </span>
                {isDisplayList && this.state.listdata.length !== 0 ?
                    <select className="select-list-section"
                        size={this.state.listdata.length}
                        onClick={this.onSelectListItem}
                        onKeyDown={this.onPressEnterKey}
                        defaultValue={defaultValue}
                    >
                        {this.state.listdata.map((item, key) => {
                            return <option key={key} className="select-list-item"> {item.label} </option>
                        })}
                    </select>
                    : ""}
            </div>
        )
    }
}

export default SearchWithSelectField


//  props required
//  optionData : [arr with label and value format]
//  defaultValue : string
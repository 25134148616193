import axios from 'axios';
import { getURL } from '../../../providers/configProvider';
import { requestPOST, requestGET, requestQAll } from '../../../services/extendedService';

export const getCurrentUser = () => {
    const url = getURL('login', 'userinfo', {}, true);
    return requestGET(url);
};

export const getUsersList = () => {
    let params = { 'apiType': 'data' }
    const url = getURL('usermanagement', 'list', params, true);
    return requestGET(url);
};

export const getAllRoleList = () => {
    let params = { 'apiType': 'data' }
    const url = getURL('usermanagement', 'roles', params, true);
    return requestGET(url);
};
export const getAllTimeZoleList = () => {
    let params = { 'apiType': 'data' }
    const url = getURL('usermanagement', 'timezones', params, true);
    return requestGET(url);
};

export const createNeworUpdateUser = (reqBody, addOrEdit) => {
    let createorUpdate = addOrEdit === 'add' ? 'create' : 'update';
    let params = { 'apiType': 'data' };
    if (addOrEdit === 'edit') params = { ...params, ...{ 'principalId': reqBody.principalId } }
    const url = getURL('usermanagement', createorUpdate, params, true);
    return requestPOST(url, reqBody.formData);
}

export const createNewUser = (createData) => {
    let params = { 'apiType': 'data' }
    const url = getURL('usermanagement', 'create', params, true);
    return requestPOST(url, createData);
};
export const updateUser = (userId, createData) => {
    var params = { 'apiType': 'data', 'principalId': userId };
    const url = getURL('usermanagement', 'update', params, true);
    return requestPOST(url, createData);
};

export const deleteUsers = (userId) => {
    var params = { 'apiType': 'data' };
    const url = getURL('usermanagement', 'delete', params, true);
    return requestPOST(url, userId);
};


export const userStateOrresetPassword = (params, reqFor) => {
    const url = getURL('usermanagement', reqFor, params, true);
    return requestGET(url);
}

export const userState = (params) => {
    const url = getURL('usermanagement', 'userState', params, true);
    return requestGET(url);
};

export const resetPassword = (params) => {
    const url = getURL('usermanagement', 'resetpassword', params, true);
    return requestGET(url);
};

export const updateLoggedinUserPassword = (payload) => {
    const url = getURL('login', 'resetpassword', {}, true );
    return requestPOST(url, payload, {}, { validateSuccess: true });
};

export const logout = () => {
    var moduleNames = ['devicemanagement', 'disturbance', 'faults', 'powerquality'];
    var urls = [];
    moduleNames.forEach(moduleName => {
        urls.push(getURL('login', 'clearSession', {'MODULENAME': moduleName}));
    });
    return requestQAll(urls);
};

export const getProductInfo = (params) => {
    const url = getURL('login', 'productInfo', {});
    return requestGET(url);
};

export const logoutToken = () => {
    const token = JSON.parse(localStorage.getItem('token'));
    const url = getURL('login', 'logoutToken', {'REFRESH_TOKEN': token.refresh_token});
    return axios.get(url);
}
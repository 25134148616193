import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../hoc/loader';
import Tabs from '../../../hoc/tabs';
import AlertMessage from '../../../hoc/alert';
import { userMngmntData } from '../../../utility/userManagementUtility';
import ModalForm from '../../userManagement/components/modalForm';
import { UpdateProfileContext } from '../provider/updateProfileProvider';
import { getAllTimeZoleList, createNeworUpdateUser } from '../../userManagement/services/userService';
import ModalWindow from '../../../hoc/modelWindow';
import {userPreferences} from '../../../services/utilityService';
import BlankPage from '../../../hoc/blankPage'
import { emailRegex } from '../../../filters/commonFunction';

class UpdateProfileContainer extends Component {
    static contextType = UpdateProfileContext;
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: true,
            currentTab: userMngmntData.currentTab,
            errMsg: "",
            type: '',
            isLoading: false,
            generalData: userMngmntData.formFields.general,
            preferencesData: userMngmntData.formFields.preferences,
        }
    }

    componentDidMount() {
        let timezoneData = this.state.preferencesData.find(item => item.value === 'timezone');
        if (timezoneData.options.length === 0) this.getTimeZoneList();
    }

    getTimeZoneList = () => {
        getAllTimeZoleList()
            .then(data => {
                let newData = data.length !== 0 ? data : [];
                let tmpArr = [];
                newData.map(item => tmpArr.push({ label: item, value: item }));
                this.updateResponseData('preferencesData', tmpArr, 'timezone')
            }).catch(error => {
            })
    }

    updateResponseData = (stateName, tmpArr, field) => {
        this.state.preferencesData.find(item => item.value === field).options = tmpArr;
        this.setState({ [stateName]: this.state[stateName] })
    }

    closeConfigModal = () => {
        userMngmntData.isOpenUpdateProfileModal = false;
        this.setState({ isOpenModal: true, currentTab: 'general', errMsg: "", type: "" })
        this.state.generalData.map(item=> delete item.isErr)    //clearing all the error
        this.context.closeModal()
    }

    handleTabSelect = crntVal => this.setState({ currentTab: crntVal })

    handleOnchangeForm = async (value, field) => {
        await this.context.handleOnchangeForm(value, field)
        let checkAllValidate = this.onChangeValidation(field, this.context.state.formData, this.state.generalData)
        this.setState({ generalData: checkAllValidate })
    }

    onChangeValidation = (field, formData, generalData) => {
        let value = null;
        if (field === 'email') value = this.testValidation(emailRegex, formData.email)
        const updateIsErrData = generalData.map(item => {
            if (item.value === field) return { ...item, isErr: value }
            else return item
        })
        return updateIsErrData
    }

    testValidation = (regex, value) => !regex.test(value)

    updateProfileDetails = () => {
        
        
        let findIsErrAvail = this.state.generalData.filter(item => item.isErr !== undefined && item.isErr === true)
        if (findIsErrAvail.length !== 0) {
            this.setState({ errMsg: this.context.state.formData.email === "" ? <span><FormattedMessage id='db.common.email' /> <FormattedMessage id='db.common.isRequired' /> </span> : <FormattedMessage id='db.common.invalidEmailId' />, type: 'warning', isLoading: false })
        } else {
            this.setState({ isLoading: true }, () => {
                let reqBodyData = { formData: this.context.state.formData, principalId: this.context.state.formData.principalId }
                reqBodyData.formData.timezone = reqBodyData.formData.timezone ? reqBodyData.formData.timezone :
                this.state.preferencesData.find(e => e.value === "timezone").options.find(e => e.value.split(" ")[1] === this.context.timezone).value ? this.state.preferencesData.find(e => e.value === "timezone").options.find(e => e.value.split(" ")[1] === this.context.timezone).value : this.state.preferencesData.find(e => e.value.split(" ")[1] === "PST").value;
                createNeworUpdateUser(reqBodyData, 'edit')
                    .then(data => {
                        if (data.status === "OK" && data.message === "Success"){ 
                            this.setState({ errMsg: 'Your profile have been updated successfully.', type: 'success', isLoading: false });
                            userPreferences.tempUnit = reqBodyData.formData.temperaturePreference;
                            if(userPreferences.treeOrder !== reqBodyData.formData.treeOrder){
                                userPreferences.treeOrder = reqBodyData.formData.treeOrder;
                                this.context.reloadtree();
                            }
                        }
                        else this.setState({ errMsg: data.message, type: 'warning', isLoading: false })
                    }).catch(error => {
                    })
            })
        }
    }

    onBtnClickHandlera=(value)=>{
        if(value === 'cancel') this.closeConfigModal()
        else if(value === 'update') this.updateProfileDetails()
    }

    render() {
        return (
            <Row>
                <Col>
                    {userMngmntData.isOpenUpdateProfileModal ?
                        <ModalWindow
                            show={this.state.isOpenModal}
                            size="sm"
                            dialogClassName={"userModal"}
                            title={<FormattedMessage id='db.updateprofile.label.updateUserProfile' />}
                            onBtnClickHandler={this.onBtnClickHandlera}
                            onHide={this.closeConfigModal}
                            modeldata={{
                                content: 
                                    <React.Fragment>
                                        {this.context.state.isLoading || this.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                                        <div className='alert-gap'>{this.state.errMsg !== "" ? <AlertMessage type={this.state.type} message={this.state.errMsg} /> : ""}</div>
                                        {this.state.type === 'success' ? ""
                                        : 
                                    this.context.state.formData ?
                                        <Tabs
                                            currentTab={this.state.currentTab}
                                            handleTabSelect={this.handleTabSelect}
                                            allTabs={userMngmntData.allTabs}
                                            tabBody={
                                                <ModalForm
                                                    currentTab={this.state.currentTab}
                                                    generalData={this.state.generalData.filter(item => item.type !== 'password' && item.value !== 'role')}
                                                    preferencesData={this.state.preferencesData}
                                                    formData={this.context.state.formData}
                                                    handleOnchangeForm={this.handleOnchangeForm}
                                                    addOrEdit={'edit'}
                                                    timezone = {this.context.timezone}
                                                />
                                            }
                                        />
                                    : !this.context.state.isLoading && <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                                        }
                                    </React.Fragment>
                            }}
                            footer={
                                this.state.type === 'success' ? [] :
                                [{ className: "modalCancelBtn", name: <FormattedMessage id='dm.column.cancel' />, value: "cancel" }, 
                                { className: "modalSaveBtn", name: <FormattedMessage id='db.common.update' />, value: "update", disabled : this.context.state.isDisableSaveBtn }]
                            }
                        />
                        : ""}
                </Col>
            </Row>
        );
    }
}

export default UpdateProfileContainer;
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap'
import ProfilesList from '../components/profileList';
import AddProfile from "../components/addProfile";
import DeviceType from '../components/devicetypeComponent';
import { ProfileContext } from '../provider/profilesProvider';
import ProfileActions from '../components/profileActions';
import ProfileDetails from '../components/profileDetails';
import ModalWindow from '../../../../hoc/modelWindow';

class ProfilesContainer extends Component {
    static contextType = ProfileContext;
    render() {
        return (
            <Row className="profiles m-0">
                <Col className="col-2 pl-1">
                    <div className="title-div1"><AddProfile /></div>
                    <div><DeviceType /></div>
                    <div className="profile-list-div mt-2"> <ProfilesList /></div>
                </Col>
                <Col className="col-10 pl-0 pr-2">
                    <div><ProfileActions/></div>
                    <div><ProfileDetails/></div>
                </Col>
                {
                    this.context.state.modal.showModal ?
                    // <ModalWindow show={this.context.state.modal.showModal} modeldata={this.context.state.modal.modalContent} size={"lg"} onHide={this.context.modalClose} dialogClassName={""} />
                        <ModalWindow
                            show={this.context.state.modal.showModal}
                            onHide={this.context.modalClose}
                            size={"md"}
                            dialogClassName={""}
                            onBtnClickHandler={this.context.modelClickHandler}
                            title={this.context.state.modal.title}
                            modeldata={this.context.state.modal.modeldata}
                            footer={this.context.state.modal.footer} 
                            currentProfile={this.context.state.modal.currentProfile}
                            onChangeSelectObj={this.context.state.modal.onChangeSelectObj}
                        />
                    : null
                }
            </Row>

        );
    }
}

export default ProfilesContainer;
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ModalDetailsTable from './modalDetailsTable';

const TableSection=(props)=>{
    const [isVisibleicon, setInVisibleicon] = useState(false)
    const [isExpand, setisExpand] = useState(false)

    const handleExpandProperty=(value)=>{
        setInVisibleicon(value)
    }

   const handleExpandCollapse=(val)=>{
        setisExpand(val);
    }
    
    useEffect(()=>{
        props.resizeChartSection();
    },[isExpand])

    return (
        <>
            {!isExpand && <span onClick={() => handleExpandCollapse(true)} className='collapse-btn'> <FontAwesomeIcon icon={faChevronLeft} size="lg" /> </span>}
            {isExpand &&
                <Col xs={3} style={{ position: 'relative' }} onMouseEnter={() => handleExpandProperty(true)} onMouseLeave={() => handleExpandProperty(false)}>
                    <Row>
                        <Col className={'m-0 p-0'}>
                            <ModalDetailsTable
                                tabData = {props.tabValues}
                                tableData={props.data.waveformList.details}
                                details = {props.data.waveformList.details}
                            />
                        </Col>
                    </Row>
                    {isVisibleicon && <span onClick={() => handleExpandCollapse(false)} className='expand-btn'> <FontAwesomeIcon icon={faChevronRight} size="lg" />  </span>}
                </Col>}
        </>
    )
}

export default TableSection;
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Form, FormControl } from 'react-bootstrap';
import { getURL } from './providers/configProvider';
import Home from './home';
import ampleStore from './store';

class AmpleApp extends Component {
    render() {
        return <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Provider store={ampleStore}>
                <React.Fragment>
                    <Form action={getURL('login', 'autoFormURL', [], true)} method="POST" hidden id="autoLoginForm">
                        <FormControl
                            type="hidden"
                            value=""
                            id="autoLoginInput" />
                    </Form>
                    <Home />
                </React.Fragment>
            </Provider>
        </BrowserRouter>
    }
}
export default AmpleApp;


import React, { Component, Fragment} from 'react';
import { Row, Col } from 'react-bootstrap';
import AlertMessage from '../../../../hoc/alert';
import { ReportsContext } from '../provider/reportsProvider';
import ReportsComponent from '../components/reportsComponent';

class ReportsContainer extends Component {

  static contextType = ReportsContext;
  
  render() {
    let errorMsg = (!this.context.state.loading && this.context.state.showInlineMsg) ? 
      <Fragment>
        <Row className="clearfix content-right m-0 d-flex justify-content-center alert-p-b-1" xs={12} md={12} >
           <AlertMessage type={this.context.state.errorMsgObj.type} timer={6000} message={this.context.state.errorMsgObj.msg} setVisible={visibility =>  this.context.setChange(visibility, 'showInlineMsg') } />
         </Row><br />
      </Fragment>
      : null;

    return (
      <Fragment>
        {errorMsg}
        <Col className="content-right pad-rgt-lef reports p-2 border-1px" xs={12} md={12}>          
          <ReportsComponent />
        </Col>
      </Fragment>
    );
  }
}


export default ReportsContainer;
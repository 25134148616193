import React from 'react';
import { Link } from 'react-router-dom';
import { Button, FormControl } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import CustomImgIcon from '../../hoc/customImgIcon';
import AlertMessage from '../../hoc/alert';
import Loader from '../../hoc/loader';

const ResetPasswordForm = (props) => {

  const addFormControl=(field, placeholder)=>{
       return <FormattedMessage id={placeholder}>
                {placeholder=>  
                    <FormControl
                        type="password"
                        size={'sm'}
                        onChange={(evt) => props.onChangePassword(evt.target.value, field)}
                        placeholder={placeholder}
                    />
                }
            </FormattedMessage>
    }

    return (
        <React.Fragment>
            {props.stateObj.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
            <div className="text-right logo"><CustomImgIcon icon="login_logo.png" width='12rem' height='8.5rem' /></div>
            <h3 className="login-title"><FormattedMessage id='login.gridanalytics' /></h3>
            <p className="h4"><FormattedMessage id='login.resetpassword' /></p>
            {props.stateObj.forceResetMessage ? <AlertMessage type={'warning'} message={props.stateObj.forceResetMessage} /> : ""}
            {props.stateObj.validationMessage ? <AlertMessage type={'warning'} message={props.stateObj.validationMessage} /> : ""}
            {!props.stateObj.validatingToken && !props.stateObj.validationMessage ?
                <React.Fragment>
                    {props.stateObj.statusMessage?.message &&  <div><AlertMessage type={props.stateObj.statusMessage.type} message={props.stateObj.statusMessage.message} /></div>}
                    {props.stateObj.resetPasswordSuccess ? <div>
                        {props.stateObj.forceReset ? <React.Fragment>
                            <p>
                                {addFormControl('currentPassword', 'resetpassword.placeholder.currentPassword')}
                            </p>
                            {props.stateObj.submited && props.stateObj.userInfoObj.currentPassword.length === 0 ? <p className="ample-error-message"><FormattedMessage id='resetpassword.currentpwd.required' /></p> : ""}
                        </React.Fragment> : ""}

                        <p>
                            {addFormControl('newPassword', 'db.common.password')}
                        </p>
                        {props.stateObj.submited && props.stateObj.userInfoObj.newPassword.length === 0 ? <p className="ample-error-message"><FormattedMessage id='resetpassword.newpwd.required' /></p> :
                         props.stateObj.submited && (props.stateObj.userInfoObj.newPassword.length < 8 || props.stateObj.userInfoObj.newPassword.length > 20) ? <p className="ample-error-message"><FormattedMessage id='resetpassword.mincharacter' /></p> : 
                         props.stateObj.submited && !props.testValidation(/^[a-zA-Z0-9]*(?=.*[!@#$%^&+=]).*$/, props.stateObj.userInfoObj.newPassword) ? <p className="ample-error-message"><FormattedMessage id='resetpassword.splcharacter' /></p> : ""}
                        <p>
                            {addFormControl('confirmPassword', 'db.common.confirmPassword')}
                        </p>
                        {props.stateObj.submited && props.stateObj.userInfoObj.confirmPassword.length === 0 ? <p className="ample-error-message"><FormattedMessage id='resetpassword.confirmpwd.required' /></p> : props.stateObj.submited && props.stateObj.passwordsNotMatch ? <p className="ample-error-message">Passwords should match.</p> :""}
                        <div>
                            <p><Button onClick={props.onResetPassword} block variant="warning"><FormattedMessage id='common.button.reset' /></Button></p>
                            <Button  variant="link" className="forgot-password"><Link to="/"><FormattedMessage id='login.form.login' /></Link></Button>
                        </div>
                    </div> : <Button variant="link" className="pull-right"><Link to="/"><FormattedMessage id='common.button.ok' /></Link></Button>}

                </React.Fragment>
                : ""}
        </React.Fragment>
    )
}

export default ResetPasswordForm;